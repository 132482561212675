import moment from "moment-timezone";

export function isDateRangeValid(startDate: string, endDate: string, timezone: string): boolean {
    if (startDate && endDate) {
        try {
            const nowTZ = moment.tz(moment.now(), timezone);
            const startTZ = startDate ? moment.tz(startDate, timezone) : nowTZ;
            const endTZ = moment.tz(endDate, timezone);
            return startTZ < endTZ && endTZ <= nowTZ;
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
}

export function toDateString(d: Date): string {
    // getMonth() starts from 0
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
}
