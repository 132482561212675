import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";
import Health from './health/Health';
import {IssueManager} from "./issueManager/IssueManager";
import {AuthReceiver} from "@amzn/csphoenix-react-client";
import {OutboundCustomerNotificationPage} from "./sentToCustomer/OutboundCustomerNotificationPage";

export default (
    <Switch>
            <Route exact path="/:locale" component={Home} />
            <Route path="/:locale/health" component={Health} />
            <Route path="/:locale/issueManager" component={IssueManager} />
            <Route path="/:locale/sentToCustomer" component={OutboundCustomerNotificationPage} />
            <Route path="/:locale/auth/:cookieName" component={AuthReceiver} />
            {/* Disable following routes for security risk on papyrus page is externally accessible  */}
            {/* <Route path="/:locale/explorer" component={Explorer} /> */}
            {/* <Route path="/:locale/authtester" component={AuthorizerTester} /> */}
            {/* <Route path="/:locale/eventbrowser" component={EventBrowser} /> */}
    </Switch>
);