import {i18n} from "../i18n/IntlManager";

export enum Purpose {
    u = "u",
    lc = "lc",
    roc = "roc",
    rml = "rml",
    ech = "ech",
    c = "c",
    a = "a",
    cu = "cu",
    bow = "bow",
    psi = "psi",
    ci = "ci",
}

export function longForm(purpose: Purpose): string {
    switch (purpose) {
        case Purpose.c:
            return 'COACHING';
        case Purpose.u:
            return 'UNKNOWN';
    }
    return 'UNKNOWN';
}

export function purposeName(purpose: Purpose): string {
    if (purpose === Purpose.u) {
        return i18n('PURPOSE_UNKNOWN');
    }
    if (purpose === Purpose.lc) {
        return i18n('PURPOSE_LIVE_CONTACT');
    }
    if (purpose === Purpose.roc) {
        return i18n('PURPOSE_REVIEW_OWN_CONTACT');
    }
    if (purpose === Purpose.rml) {
        return i18n('PURPOSE_REVIEW_ML_ACCURACY');
    }
    if (purpose === Purpose.ech) {
        return i18n('PURPOSE_ESCALATION_CONTACT_HANDLING');
    }
    if (purpose === Purpose.c) {
        return i18n('PURPOSE_COACHING');
    }
    if (purpose === Purpose.a) {
        return i18n('PURPOSE_AUDITING');
    }
    if (purpose === Purpose.cu) {
        return i18n('PURPOSE_CURATION');
    }
    if (purpose === Purpose.bow) {
        return i18n('PURPOSE_BACK_OFFICE_WORKFLOW');
    }
    if (purpose === Purpose.psi) {
        return i18n('PURPOSE_PURGE_SENSITIVE_INFO');
    }
    if (purpose === Purpose.ci) {
        return i18n('PURPOSE_COMPLIANCE_INVESTIGATION');
    }
    return "Error";
}

export function purposeDescription(purpose: Purpose): string {
    if (purpose === Purpose.u) {
        return i18n('PURPOSE_DESCRIPTION_UNKNOWN');
    }
    if (purpose === Purpose.lc) {
        return i18n('PURPOSE_DESCRIPTION_LIVE_CONTACT');
    }
    if (purpose === Purpose.roc) {
        return i18n('PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT');
    }
    if (purpose === Purpose.rml) {
        return i18n('PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY');
    }
    if (purpose === Purpose.ech) {
        return i18n('PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING');
    }
    if (purpose === Purpose.c) {
        return i18n('PURPOSE_DESCRIPTION_COACHING');
    }
    if (purpose === Purpose.a) {
        return i18n('PURPOSE_DESCRIPTION_AUDITING');
    }
    if (purpose === Purpose.cu) {
        return i18n('PURPOSE_CURATION_DESCRIPTION');
    }
    if (purpose === Purpose.bow) {
        return i18n('PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION');
    }
    if (purpose === Purpose.psi) {
        return i18n('PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO');
    }
    if (purpose === Purpose.ci) {
        return i18n('PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION');
    }

    return "Description missing";
}

export enum CustomerAccessLevel {
    CONFIDENTIAL = "CONFIDENTIAL",
    RESTRICTED = "RESTRICTED",
    RESTRICTED_PII = "RESTRICTED_PII"
}

export interface Contact {
    contactId: string;
    creationDate: string;
    medium: ContactMedium;
    status: ContactStatus;
    owner?: CSAgent;
    orderIds: string[];
    obfuscatedCustomerIdList: string[];
    customerSelectedIssue?: Issue;
    agentSelectedIssue?: Issue;
    agentAnnotations: AgentAnnotation[];
    autoAnnotations: AutoAnnotation[];
    replies: ReplyContact[];
    followUps: FollowUp[] | null;
    screenPop?: ContactScreenPop;
    blurbList: Blurb[] | null;
    workCategory: WorkCategory | null;
    transferredFromContact?: Contact;
    dartEscalationContactList: Contact[];
    stateTransitionList: ContactStateTransition[];
    retailContactUsAlertList?: ContextualAlert[];
    emailQueue?: EmailQueue;
    isSelected?: boolean;
    workRequestParentContact?: Contact;
    workRequestContactList?: Contact[];
    shipperAccountId?: string;
    marketplaceId?: string;
}

export interface ReplyContact {
    contactId: string;
    creationDate: string;
    medium: ContactMedium;
}

export enum ReplyByType {
    EMAIL = "EMAIL",
    PHONE = "PHONE"
}

export enum ContactMediumType {
    MAIL = "MAIL",
    EMAIL = "EMAIL",
    CHAT = "CHAT",
    PHONE = "PHONE"
}

export interface ContactParticipant {
    emailAddress: string;
}

export interface ContactAttachment {
    fileName: string;
    fileSize: bigint;
    fileType: string;
    attachmentId?: string;
}

export interface ContactMedium {
    type: ContactMediumType;
    emailSubject?: string;
    emailHeader?: string;
    emailBody?: string;
    emailBodyHTMLSafe?: string;
    emailBodyPreTranslationCSAText?: string;
    sender?: ContactParticipant;
    receiver?: ContactParticipant;
    chat?: Chat;
    attachments?: ContactAttachment[];
    phoneRecordList? : GACDPhoneRecord[];
    rosettaTranslatedInboundEmail?: RosettaTranslatedEmail;
    connectPhoneRecordList: ConnectPhoneRecord[];
}

export interface ConnectPhoneRecord {
    cscRecordingAccessStatus?: string;
    connectContactId: string;
    agent: CSAgent;
    callRecordingPart: CallRecordingPart;
}

export enum ChatParticipantStateChangeType {
    CONNECTED = "CONNECTED",
    DISCONNECTED = "DISCONNECTED",
    ON_HOLD = "ON_HOLD",
    OFF_HOLD = "OFF_HOLD"
}

export enum ChatDisconnectReason {
    PUSH_SERVICE_ERROR = "PUSH_SERVICE_ERROR",
    MESSAGE_TRANSPORT_ERROR = "MESSAGE_TRANSPORT_ERROR",
    USER_HANGUP = "USER_HANGUP",
    AGENT_HUNGUP = "AGENT_HUNGUP",
    AGENT_PARTICIPANT_CHANGE_TIMEOUT = "AGENT_PARTICIPANT_CHANGE_TIMEOUT",
    KEEP_ALIVE_TIMEOUT = "KEEP_ALIVE_TIMEOUT",
    GACD_HANGUP = "GACD_HANGUP",
    DISCONNECT = "DISCONNECT",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    AGENT_PARKED = "AGENT_PARKED",
    BOT_GAVEUP = "BOT_GAVEUP",
    BOT_PARKED = "BOT_PARKED",
    BOT_DISCONNECT = "BOT_DISCONNECT",
    OVER_CAPACITY = "OVER_CAPACITY",
    ATTRIBUTES_LIMIT_EXCEEDED = "ATTRIBUTES_LIMIT_EXCEEDED"
}

export enum ChatMessageType {
    MESSAGE = "MESSAGE",
    CHAT_TRANSFERRED = "CHAT_TRANSFERRED",
    CHAT_PARKED = "CHAT_PARKED",
    PARTICIPANT_CHANGE = "PARTICIPANT_CHANGE"
}

export enum ChatParticipantType {
    CUSTOMER = "CustomerChatParticipant",
    CSA = "CSAChatParticipant",
    BOT = "BotChatParticipant"
}

export interface ChatText {
    text: string;
    language?: string;
}

export interface ChatParticipant {
    __typename: ChatParticipantType;
    name?: string;
    customerName?: string;
    preferredLanguage: string;
}
export interface ChatMessage {
    type: ChatMessageType;
    timestamp: string;
    participant?: ChatParticipant; 
    chatText?: ChatText;
    translationList?: ChatText[];
    stateChangeType?: ChatParticipantStateChangeType;
    disconnectReason?: ChatDisconnectReason;
}

export interface Chat {
    messages?: ChatMessage[];
}

export interface CallRecordingPart {
    base64?: string;
    nextPart?: string;
}

export interface GACDPhoneRecord {
    cscRecordingAccessStatus?: string;
    agentMediaLegId: string;
    agent: CSAgent;
    gacdSiteId?: string;
    gacdAgentOwner?: string;
    presignedUrl?: string;
    skillName?: string;
    callDurationSeconds?: string;
    callRecordingPart?: CallRecordingPart;
    mediaStatus?: string;
}

export enum ContactStatus {
    UNLOCKED = "UNLOCKED",
    LOCKED = "LOCKED",
    RESOLVED = "RESOLVED"
}

export interface Issue {
    descriptors: string[];
}

export interface AgentAnnotation {
    agent?: CSAgent;
    message: string;
    date: string;
}

export interface AutoAnnotation {
    agent?: CSAgent;
    description: string;
    date: string;
}

export interface CSAgent {
    agentId: string;
    agentLogin: string;
    agentOwner?: string;
}

export interface FollowUp {
    followUpId: string;
    dueDate: string;
    note: string;
    resolved: boolean;
    owner: CSAgent;
    ticketId: string;
    contactId?: string;
    contactMethod: string;
}

export interface ContactScreenPop {
    type: ContactScreenPopType;
    usedByAgent: boolean;
}

export enum ContactScreenPopType {
    AUTHENTICATING_AVAIL = "AUTHENTICATING_AVAIL",
    AUTHENTICATING = "AUTHENTICATING",
    CALLER_ID_AVAIL = "CALLER_ID_AVAIL",
    CALLER_ID = "CALLER_ID",
    CUSTOMER_HUNCH_AVAIL = "CUSTOMER_HUNCH_AVAIL",
    CUSTOMER_HUNCH = "CUSTOMER_HUNCH",
    CUSTOMER_UNKNOWN = "CUSTOMER_UNKNOWN",
    OTHER = "OTHER"
}

export interface Blurb {
    displayableName: string;
    displayableContent: string;
}

export interface WorkCategory {
    workCategoryId: string;
    workCategoryName: string;
}

export interface ContactStateTransition {
    type: ContactStateTransitionType;
    displayableDestination?: string;
    date: string;
    agent: CSAgent;
}

enum ContactStateTransitionType {
    INSERTED = "INSERTED",
    ANALYZED = "ANALYZED",
    LOCKED = "LOCKED",
    TRANSFERRED = "TRANSFERRED",
    ABANDONED = "ABANDONED",
    REASSIGNED = "REASSIGNED",
    REPLIED = "REPLIED",
    RESOLVED = "RESOLVED"
}

// RecordingState is stored in the ContactDisplay level because we want to
// maintain state between show/hide details.
export interface RecordingState {
    // Indicate whether the user is allowed to listen to this recording
    allowListen: boolean;

    // GACD data
    agentLogin: string;
    callStart: string;
    callEnd: string;
    callDuration: string;
    skillName: string;
    recordingLocation: string;
    recordingStatus: string;
    agentCallLegId: string;

    // Flag that indicates loading status
    loading?: boolean;
    // The Mp3Buffer used to play while the file is still downloading
    mp3Buffer: Mp3Buffer;
    // Download progress (percentage)
    progress: number;

    // After the whole recording is downloaded we use this blobUrl
    blobUrl?: string;

    // Status of the audio player
    currentTime: number | undefined;
    playing: boolean;

    // element id of the audio player
    id: string;

    // Flag that indicates the download loading status
    loadingDownload?: boolean;

    // stores the download url
    downloadUrl?: string;
}

export interface SessionCustomer {
    primary?: boolean;
    customerAccounts: Record<string, string[]>;
}

export interface UsecaseSession {
    usecaseSessionId: string;

    initialCommId?: string;

    authenticatedCustomers: string[];

    sessionCustomers: SessionCustomer[];
}

function updateendListener(mp3Buffer: Mp3Buffer): (event: Event) => void {
    return (event: Event): void => {
        mp3Buffer.appendToSourceBuffer();
    };
}

function sourceopenListener(mp3Buffer: Mp3Buffer): (event: Event) => void {
    return (event: Event): void => {
        const mediaSource: MediaSource = (event.target as MediaSource);
        const sourceBuffer: SourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
        mp3Buffer.setSourceBuffer(sourceBuffer);
        sourceBuffer.addEventListener("updateend", updateendListener(mp3Buffer));
    };
}

export class Mp3Buffer {

    private buffers: ArrayBuffer[] = [];
    private iter = 0;
    private mediaSource: MediaSource = new MediaSource();
    private sourceBuffer?: SourceBuffer;
    private url: string;

    constructor() {
        const ms: MediaSource = this.mediaSource;

        ms.addEventListener("sourceopen", sourceopenListener(this));

        this.url = URL.createObjectURL(this.mediaSource);
    }

    public appendToSourceBuffer(): void {
        if (this.mediaSource.readyState === "open"
            && this.sourceBuffer
            && this.sourceBuffer.updating === false) {
            if (this.iter < this.buffers.length) {
                const nextBuffer: ArrayBuffer = this.buffers[this.iter];
                ++this.iter;
                this.sourceBuffer.appendBuffer(nextBuffer);
            }
        }
    }

    public getNewUrl(): string {
        this.url = URL.createObjectURL(this.mediaSource);
        return this.url;
    }

    public reset(): void {
        this.iter = 0;
        this.mediaSource = new MediaSource();
        const ms: MediaSource = this.mediaSource;

        ms.addEventListener("sourceopen", sourceopenListener(this));

        this.url = URL.createObjectURL(this.mediaSource);
    }

    public addData(arrayBuffer: ArrayBuffer): void {
        this.buffers.push(arrayBuffer);
        this.appendToSourceBuffer();
    }

    public setSourceBuffer(sourceBuffer: SourceBuffer): void {
        this.sourceBuffer = sourceBuffer;
    }
}

export interface ContactTransferDestinations {
    inMarketplaceTransferDestinationList: ContactTransferDestination[];
    crossMarketplaceTransferDestinationList: ContactTransferDestination[];
}

export interface ContactTransferDestination {
    emailQueue: EmailQueue;
}

export interface EmailQueue {
    id: string;
    name: string;
}

export interface StringDescriptor {
    stringId: string;
}

export interface ContactTransferError {
    type: string;
    errorMessageDescriptor: StringDescriptor;
}

export interface ContactTransferResponse {
    error?: ContactTransferError;
}

export interface ContextualAlert {
    contextualAlertId: string;
    displayableAlertTitle: string;
    displayableAlertBody: string;
    alertPriority: ContextualAlertPriority;
}

export enum ContextualAlertPriority {
    NORMAL = "NORMAL",
    URGENT = "URGENT"
}

export enum PurgeReasons {
    PII = "PURGE_REASON_PII",
    FINANCIAL_INFO = "PURGE_REASON_FINANCIAL_INFO",
    OTHER = "PURGE_REASON_OTHER"
}

export interface RosettaTranslatedEmail {
    emailBodyHTMLSafe: KMEncryptedContents;
}

export interface KMEncryptedContents {
    encryptedString: string;
}

export interface ReassignContactResponse {
    failure?: ReassignContactFailure;
}

export interface ReassignContactFailure {
    errorType: string;
    errorMessageDescriptor: StringDescriptor;
}