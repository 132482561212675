import React from "react";
import PropTypes from "prop-types";
import IntlManager from "../i18n/IntlManager";

/**
 * Translation
 *
 * React component provided to render the translated string
 *
 * @public
 * @param props Props supported by the component
 * @returns {React.DOM}
 */

const Translation = (props) => {
    const {
        stringId,
        component: componentProp,
        default: defaultString = "String not found",
        params = null,
        contactId = null,
    } = props;
    const Component = componentProp || "span";
    const translated = IntlManager.sharedManager.formatMessage(stringId, {values: params, default: defaultString, contactId: contactId});
    return <Component>{translated}</Component>;
};

Translation.propTypes = {
    /**
     *
     * Identifier to search in the object map containing translated strings
     */
    stringId: PropTypes.string.isRequired,
    /**
     * Override this property to change the container of HTML to be rendered by
     * Translation component. Default: "span"
     */
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ]),
    /**
     * Default value of string to be used if the stringId is not found in the
     * list. Default: "String not found"
     */
    default: PropTypes.string,
    /**
     * Object map containing the mapping of keys which are present in the
     * provided string. E.g. "my name is ${name}" then params will be like:
     * ```
     * { name: "John Doe" }
     * ```
     */
    params: PropTypes.object,

    contactId: PropTypes.string,
};

export default Translation;
