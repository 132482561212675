import React from 'react';
import emdash from '../img/emdash.png';

interface EmdashImageProps {
    height?: string;
    width?: string;
    ariaLabel: string;
    alt?: string;
}

/**
 * https://code.amazon.com/packages/CSC-UI-Components-Primitives/blobs/mainline/--/src/emdashImage/EmdashImage.tsx
 */
const EmdashImage: React.FC<EmdashImageProps> = ({height = '10', width = '10', ariaLabel, alt = ''}) => {
    return (
        <img
            src={emdash}
            height={height}
            width={width}
            aria-label={ariaLabel}
            alt={alt}
        />
    );
};

export default EmdashImage;