import {CustomerAccessLevel, FollowUp} from "./Contact.types";
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import {Timestamp} from "./Timestamp";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Button from "@amzn/awsui-components-react/polaris/button";
import React, { useContext } from 'react';
import {AllHtmlEntities} from 'html-entities';
import moment from "moment";
import {getUserLogin} from "./UserDetailsUtil";
import {IssueManagerContext} from "../issueManager/IssueManager";

const followUpStatusComponent = (followUp: FollowUp): React.ReactNode => {
    if (followUp.resolved) {
        return (
            <span>
                <Icon name="status-positive" variant="disabled"/> <Translation stringId="FOLLOW_UP_STATUS_RESOLVED"/>
            </span>
        );
    } else {
        const daysLeftUntilDueDate: number = moment
            .duration(moment(followUp.dueDate).diff(moment())).asDays();
        return (
            <span>
                <Icon name="status-pending" variant={daysLeftUntilDueDate < 1 ? "warning" : "success"}/> <Translation
                stringId="FOLLOW_UP_STATUS_OPEN"/>
            </span>
        );
    }
};

const getFollowUpColumnDefinitions = (
    customerAccessLevel: CustomerAccessLevel,
    customerTimezone: string,
    agentLogin: string,
    openTransferModal?: (followUp: FollowUp) => void
): Array<TableProps.ColumnDefinition<React.ReactNode>> => {

    function constructContactMethod(item: FollowUp): React.ReactNode  {
        if (customerAccessLevel !== CustomerAccessLevel.RESTRICTED_PII) {
            return <Alert><Translation stringId={'FOLLOW_UP_CONTACT_METHOD_RESTRICTED'}/></Alert>;
        }
        return item.contactMethod ? AllHtmlEntities.decode(item.contactMethod) :
            <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("FOLLOW_UP_NO_CONTACT_METHOD")}/>;
    }

    const baseColumns = [
        {
            id: "owner",
            minWidth: '100px',
            header: <Translation stringId="FOLLOW_UP_OWNER"/>,
            cell: (item: FollowUp): React.ReactNode => item.owner.agentLogin
        },
        {
            id: "status",
            minWidth: '100px',
            header: <Translation stringId="FOLLOW_UP_STATUS"/>,
            cell: (item: FollowUp): React.ReactNode => followUpStatusComponent(item)
        },
        {
            id: "dueDate",
            minWidth: '100px',
            header: <Translation stringId="FOLLOW_UP_DUE_DATE"/>,
            cell: (item: FollowUp): React.ReactNode => <Timestamp date={item.dueDate} customerTimezone={customerTimezone}/>
        },
        {
            id: "note",
            header: <Translation stringId="FOLLOW_UP_NOTE"/>,
            cell: (item: FollowUp): React.ReactNode => item.note ?
                AllHtmlEntities.decode(item.note) :
                <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("FOLLOW_UP_NO_NOTE")}/>
        },
        {
            id: "ticket",
            minWidth: '100px',
            header: <Translation stringId="FOLLOW_UP_TICKET"/>,
            cell: (item: FollowUp): React.ReactNode => item.ticketId ? <div><a target="_blank" rel="noopener noreferrer"
                                                                          href={"https://t.corp.amazon.com/" + item.ticketId}>{item.ticketId}<Icon
                    name="external"/></a></div> :
                <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("FOLLOW_UP_NO_TICKET")}/>
        },
        {
            id: "contactMethod",
            minWidth: '150px',
            header: <Translation stringId="FOLLOW_UP_CONTACT_METHOD"/>,
            cell: (item: FollowUp): React.ReactNode => constructContactMethod(item)
        }
    ];

    return openTransferModal ?
        [
            ...baseColumns,
            {
                id: "transferFollwUp",
                minWidth: '150px',
                header: <Translation stringId="TRANSFER_FOLLOW_UP"/>,
                cell: (item: FollowUp): React.ReactNode => <Button disabled={item.owner.agentLogin !== agentLogin}
                    onClick={(): void => openTransferModal(item)}><Translation
                    stringId="TRANSFER"/></Button>
            },
        ] :
        baseColumns;
};

interface FollowUpsTableProps {
    followUps: FollowUp[];
    customerAccessLevel: CustomerAccessLevel;
    customerTimezone: string;
    openTransferModal?: (followUp: FollowUp) => void;
}

const FollowUpsTable: React.FC<FollowUpsTableProps> = ({followUps, customerAccessLevel, customerTimezone, openTransferModal}) => {
    const { getSubject } = useContext(IssueManagerContext);
    const agentLogin = getUserLogin(getSubject());

    return (
        <Table
            wrapLines={true}
            columnDefinitions={getFollowUpColumnDefinitions(customerAccessLevel, customerTimezone, agentLogin, openTransferModal)}
            items={followUps}
            header={null}
        />);
};

export default FollowUpsTable;