import React, {useEffect, useState, useContext} from "react";
import {Contact, ContactMediumType, ContactStatus, Purpose} from "./Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import TransferContactForm from "./transfer/TransferContactForm";
import {i18n} from "../i18n/IntlManager";
import {WorkRequestForm} from "./workrequest/WorkRequestForm";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {AccessRestrictedContentRequestForm} from "../authorizer/AccessRestrictedContentRequestForm";
import CSCentralURL from "../issueManager/CSCentralURL";
import { Weblab } from "@amzn/csphoenix-react-client";
import Phoenix from "../api/Phoenix";
import { CREATE_CASE_ALLOWED_WORK_CATEGORY_IDS } from "../util/CreateCaseAllowedWorkCategoryIds";
import {CreateTicketForm} from "./tickets/CreateTicketForm";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {IsContactLockedByAgent, isPhoneContact} from "./ContactUtil";
import { IssueManagerContext } from "../issueManager/IssueManager";
import {getUserLogin } from "./UserDetailsUtil";
import PurgeSensitiveInfoForm from "./purge/PurgeSensitiveInfoForm";
import LockContact from "./lock/LockContact";
import DeleteAttachments from "./purge/DeleteAttachments";
import {ReassignContactForm} from "./reassign/ReassignContactForm";

interface ContactActionsProps {
    contact: Contact;
    userId: string;
    marketplaceId: string;
    customerId?: string;
}

type FlashBarItemType = FlashbarProps.MessageDefinition & { itemId: number };


export type PushNotificationFunction = (msg: JSX.Element, type: FlashbarProps.Type) => void;

enum CaseWeblabs {
    PACE_GRIEVANCE_MANAGEMENT_PAPYRUS_INGRESS_LAUNCH_350162 = 'PACE_GRIEVANCE_MANAGEMENT_PAPYRUS_INGRESS_LAUNCH_350162',
    PACE_GRIEVANCE_MANAGEMENT_LAUNCH_328759 = 'PACE_GRIEVANCE_MANAGEMENT_LAUNCH_328759'
}
const caseWeblab: Weblab = new Weblab('paceGrievancePapyrusIngressLaunch', Object.keys(CaseWeblabs), true);

const ContactActions: React.FC<ContactActionsProps> = ({contact, userId, marketplaceId, customerId}) => {
    const [showTransferContactForm, setShowTransferContactForm] = useState<boolean>(false);
    const [showReassignContactForm, setShowReassignContactForm] = useState<boolean>(false);
    const [showWorkRequestForm, setShowWorkRequestForm] = useState<boolean>(false);
    const [showCreateTicketForm, setShowCreateTicketForm] = useState<boolean>(false);
    const [showCreateCaseButton, setShowCreateCaseButton] = useState<boolean>(false);
    const [isPaceGrievanceManagementLaunched, setIsPaceGrievanceManagementLaunched] = useState<boolean>(false);
    const [flashBarItems, setFlashBarItems] = useState<ReadonlyArray<FlashBarItemType>>([]);
    const [flashBarMsgId, setFlashBarMsgId] = useState<number>(0);
    const [showOverrideRequestForm, setShowOverrideRequestFrom] = useState<boolean>(false);
    const [showPurgeSensitiveInfoForm, setShowPurgeSensitiveInfoForm] = useState<boolean>(false);
    const {purpose, clientStateToken} = useContext(IssueManagerContext);

    useEffect(() => {
        caseWeblab.getTreatment(Phoenix.getInstance(), marketplaceId, CaseWeblabs.PACE_GRIEVANCE_MANAGEMENT_LAUNCH_328759.valueOf())
            .then(treatment => {
                setIsPaceGrievanceManagementLaunched(treatment === 'T1');
            });
    }, [marketplaceId]);

    useEffect(() => {
        caseWeblab.getTreatment(Phoenix.getInstance(), marketplaceId, CaseWeblabs.PACE_GRIEVANCE_MANAGEMENT_PAPYRUS_INGRESS_LAUNCH_350162.valueOf())
            .then(treatment => {
                if (treatment === 'T1' && isPaceGrievanceManagementLaunched && contact?.workCategory?.workCategoryId &&
                    CREATE_CASE_ALLOWED_WORK_CATEGORY_IDS.has(contact.workCategory.workCategoryId)) {
                    setShowCreateCaseButton(true);
                } else {
                    setShowCreateCaseButton(false);
                }
                return true;
            });
    }, [marketplaceId, isPaceGrievanceManagementLaunched, contact]);

    const { getSubject } = useContext(IssueManagerContext);
    const agentLogin = getUserLogin(getSubject());

    // For now, from the UI show transfer if you have a Contact Locked
    function canTransfer(): boolean {
        return IsContactLockedByAgent(contact, userId, agentLogin);
    }

    function canPurgeSensitiveInfo(): boolean {
        // TODO: Remove psi purpose once phoenix change is in Prod: https://code.amazon.com/reviews/CR-56469183
        return Purpose.psi === Purpose[purpose] || clientStateToken?.getContent()["psi"];
    }

    function canReassign(): boolean {
        return !isPhoneContact(contact) && IsContactLockedByAgent(contact, userId, agentLogin);
    }

    function constructCreateCaseURL(): string {
        const queryString = "contactId=" + contact.contactId + "&customerId=" + (customerId ? customerId : "");
        const url = CSCentralURL.sharedCSCentralURL.getBaseUrl() + "gp/communications/case-manager/create-case?" + queryString;
        return url;
    }
    const pushNotification = (msg: JSX.Element, type: FlashbarProps.Type): void => {
        const msgToAdd: FlashBarItemType = {
            content: msg,
            itemId: flashBarMsgId as number,
            onDismiss: () => setFlashBarItems(items =>
                items.filter(item => item.itemId !== flashBarMsgId)
            ),
            dismissible: true,
            type: type,
        };
        setFlashBarItems([...flashBarItems, msgToAdd]);
        setFlashBarMsgId(flashBarMsgId + 1);
    };

    async function requestViewRestrictedContent(): Promise<void> {
        setShowOverrideRequestFrom(true);
    }

    return (<div>
        <div>
            <SpaceBetween direction={"horizontal"} size={'xs'}>
                {canTransfer() &&
                    <Button onClick={(): void => setShowTransferContactForm(!showTransferContactForm)}>
                        {i18n("TRANSFER_CONTACT")}
                    </Button>}
                {canReassign() &&
                    <Button onClick={(): void => setShowReassignContactForm(!showReassignContactForm)}>
                    {i18n("REASSIGN_TO_AGENT")}
                </Button>}
                <Button onClick={(): void => setShowWorkRequestForm(!showWorkRequestForm)}>
                    {i18n("WORK_REQUEST")}
                </Button>
                <Button onClick={(): void => setShowCreateTicketForm(!showCreateTicketForm)}>
                    {i18n("CONTACT_TICKET")}
                </Button>
                {showCreateCaseButton &&
                    <Button target={"_blank"} href={constructCreateCaseURL()}>
                        {i18n("CREATE_CASE")}
                    </Button>}
                {contact.status === ContactStatus.UNLOCKED ?
                    <LockContact
                        contact={contact}
                        userId={userId}
                        pushNotification={pushNotification}/>
                    : null}
                <Button onClick={(): Promise<void> => requestViewRestrictedContent()}>
                    {i18n("DISABLE_CONTENT_FILTER")}
                </Button>
                {canPurgeSensitiveInfo() &&
                <Button onClick={(): void => setShowPurgeSensitiveInfoForm(!showPurgeSensitiveInfoForm)}>
                    {i18n("PURGE_SENSITIVE_INFO")}
                </Button>}
                {canPurgeSensitiveInfo() && contact.medium.type === ContactMediumType.EMAIL && contact.medium.attachments?.length !== 0 &&
                <DeleteAttachments
                    contactId={contact.contactId}
                    pushNotification={pushNotification} />}
            </SpaceBetween>
            <Flashbar items={flashBarItems}/>
        </div>
        {showTransferContactForm &&
            <div><TransferContactForm marketplaceId={marketplaceId} contact={contact}/></div>}
        {showReassignContactForm && <ReassignContactForm
            contact={contact}
            deleteMe={(): void => setShowReassignContactForm(false)}
            pushNotification={pushNotification}
        />}
        {showWorkRequestForm && <WorkRequestForm
            marketplaceId={marketplaceId}
            contact={contact}
            deleteMe={(): void => setShowWorkRequestForm(false)}
            pushNotification={pushNotification}
        />}
        {showCreateTicketForm && <CreateTicketForm
            customerId={customerId}
            contact={contact}
            deleteMe={(): void => setShowCreateTicketForm(false)}
            pushNotification={pushNotification}
        />}
        {showOverrideRequestForm && <AccessRestrictedContentRequestForm
            contactId={contact.contactId}
            closeModal={(): void => setShowOverrideRequestFrom(false)}
        />}
        {showPurgeSensitiveInfoForm && <PurgeSensitiveInfoForm originalContact={contact} pushNotification={pushNotification}/>}
    </div>);
};

export default ContactActions;