import React, {MutableRefObject, useCallback, useEffect, useRef} from "react";
import peccy from "./Peccy.png";
import {SampledAudio} from "./CSALTAudioPlayer";
import "./CSALTAudioPlayer.css";

const peccyImage = new Image();
peccyImage.src = peccy;

interface CSALTWaveCanvasProps {
    id?: string;
    sampledAudio?: SampledAudio;
    currentTime: number;
    setRatio: (number) => void;
    canvasState: CanvasState;
}

interface Point {
    x: number;
    y: number;
}

export enum CanvasState {
    NO_DATA,
    PREPARING,
    LOADING,
    PROCESSING,
    READY,
    ERROR
}

// Global With Animation State
// Because the way animations kind of escape from their
// starting React component I use these globals to update state.
// Maybe there is a hook I should have used for something like this?
interface AnimationState {
    desiredActive: boolean;
    currentActive: boolean;
    label: string;
}

const csaltCanvasAnimationState: Record<string, AnimationState> = {};

export function getAnimationState(id: string | undefined): AnimationState | null {
    if (!id) {
        return null;
    }
    let state = csaltCanvasAnimationState[id];
    if (!state) {
        state = {
            desiredActive: false,
            currentActive: false,
            label: ''
        };
        csaltCanvasAnimationState[id] = state;
    }
    return state;
}


export const CSALTWaveCanvas: React.FC<CSALTWaveCanvasProps> = (
    {
        sampledAudio,
        id,
        currentTime,
        canvasState,
        setRatio}) => {

    const canvasRef = useRef() as MutableRefObject<HTMLCanvasElement>;

    function getMousePos(canvas, event): Point {
        const rect = canvas.getBoundingClientRect();
        return {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
        };
    }

    function onClickHandler(event): void {
        const canvas = canvasRef.current;
        const pos = getMousePos(canvas, event);
        const ratio = pos.x / canvas.getBoundingClientRect().width;
        setRatio(ratio);
    }

    // This is the main animation function (Peccy back and forth)
    const loadingAnimation = useCallback((position: number, direction: number, lastTime: number): void => {
        const canvas = canvasRef.current;
        const state: AnimationState|null = getAnimationState(id);

        const nextTime = Date.now();
        const step = Date.now() - lastTime;
        const update = step * direction / 50;
        let nextPosition = position + update;
        let nextDirection = direction;
        if (canvas) {
            if (nextPosition > canvas.width - 40) {
                nextPosition = canvas.width - 40;
                nextDirection = -1;
            }
            if (nextPosition < 0) {
                nextPosition = 0;
                nextDirection = 1;
            }
            if (canvas && state && (state.desiredActive)) {
                const context = canvasRef.current.getContext('2d');
                if (context) {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.strokeText(state.label, 20, 20);
                    context.drawImage(peccyImage, position, 10);
                    context.strokeRect(0, 0, canvas.width, canvas.height);
                }
            }
        }

        if (state && id)  {
            if (state.desiredActive === false) {
                state.currentActive = false;
            }
            else {
                requestAnimationFrame(() => loadingAnimation(nextPosition, nextDirection, nextTime));
            }
        }
    }, [canvasRef, id]);

    const shouldAnimate = canvasState === CanvasState.PREPARING || canvasState === CanvasState.LOADING || canvasState === CanvasState.PROCESSING;

    useEffect(() => {
        if (shouldAnimate) {
            const state = getAnimationState(id);
            if (state) {
                if (!state.currentActive) {
                    // start animation
                    state.currentActive = true;
                    state.desiredActive = true;
                    requestAnimationFrame(() => loadingAnimation(0, 1, Date.now()));
                }
            }
        } else {
            const state = getAnimationState(id);
            if (state) {
                if (state.currentActive) {
                    state.desiredActive = false;
                }
            }
        }
    }, [id, shouldAnimate, loadingAnimation]);

    // This is the main Canvas painting function
    useEffect(() => {
        function drawSingleDataPoint(
            canvas: HTMLCanvasElement,
            context: CanvasRenderingContext2D,
            audio: SampledAudio,
            px: number): void {

            if (sampledAudio) {
                const samples = sampledAudio.leftSamples.length;
                const width = canvas.width;

                const firstSample = Math.floor((px * samples) / width);
                let endSample = Math.ceil(((px) * samples) / width);
                if (endSample === firstSample) {
                    endSample = endSample + 1;
                }

                const left: number = Math.max(...sampledAudio.leftSamples.slice(firstSample, endSample));
                const right: number = Math.max(...sampledAudio.rightSamples.slice(firstSample, endSample));

                const leftHeight = (40 * left) / sampledAudio.leftMax;
                const rightHeight = (40 * right) / sampledAudio.rightMax;
                context.fillRect(px, (canvas.height / 2) - leftHeight, 1, leftHeight);
                context.fillRect(px, (canvas.height / 2), 1, rightHeight);

                if (sampledAudio.gapsArray[firstSample] &&
                    sampledAudio.gapsArray[firstSample] !== -1) {
                    context.fillStyle = "#0000FF";
                    context.fillRect(px, (canvas.height) - 3, 1, 3);
                    context.fillStyle = "#000000";
                }
            }
        }

        const canvas = canvasRef.current;
        if (canvas) {
            if (canvas.width !== canvas.getBoundingClientRect().width) {
                // Make canvas internal dimensions match external dimensions.
                // Silly it does not no that automatically.
                canvas.width = canvas.getBoundingClientRect().width;
                canvas.height = canvas.getBoundingClientRect().height;
            }
            if (canvasState === CanvasState.READY) {
                const context = canvasRef.current.getContext('2d');
                if (context) {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.strokeRect(0, 0, canvas.width, canvas.height);
                    if (sampledAudio) {
                        for (let i = 0; i < canvas.width; ++i) {
                            drawSingleDataPoint(canvas, context, sampledAudio, i);
                        }

                        const ratio = currentTime / sampledAudio.duration;
                        const currentX = ratio * canvas.width;

                        context.fillStyle = "#FF0000";
                        context.fillRect(currentX, 0, 1, canvas.height);
                        context.fillStyle = "#000000";
                    }
                }
            }
        }
    }, [canvasRef, currentTime, sampledAudio, loadingAnimation, canvasState, id]);

    return (<canvas ref={canvasRef}
                    className={'AudioPlayerCanvas'}
                    onClick={(event): void =>
                        onClickHandler(event)}/>);
};

