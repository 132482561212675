import {LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {i18n} from "../../i18n/IntlManager";
import React, {useContext, useState} from "react";
import {Contact} from "../Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import {Copyable} from "../../util/Copyable";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {buildAddAgentAnnotationMutation} from "../ContactTimelineDisplay";
import {IssueManagerContext} from "../../issueManager/IssueManager";

interface ImageUploadLinkActionsProps {
    marketplaceId: string;
    contact: Contact;
    customerId: string | undefined;
}

interface SlapshotContract {
    projectName: string;
    contractId: string;
    fulfillmentArguments: Record<string, string>;
}

const ImageUploadLinkActions: React.FC<ImageUploadLinkActionsProps> = ({marketplaceId, contact, customerId}) => {
    const [isGeneratingLink, setIsGeneratingLink] = useState<boolean>(false);
    const [isSendingLink, setIsSendingLink] = useState<boolean>(false);
    const [modalViewVisibility, setModalViewVisibility] = useState<boolean>(false);
    const [tinyUrl, setTinyUrl] = useState<string>("");
    const {reloadSingleContact, callPhoenix} = useContext(IssueManagerContext);

    async function generateImageUploadLink(): Promise<void> {
        setIsGeneratingLink(true);
        try {
            const contractArgs = {};
            contractArgs["marketplaceId"] = marketplaceId;
            contractArgs["contactId"] = contact.contactId;
            const contract = getSlapshotContract("ImageAttachmentTinyURLGenerator", "ImageAttachment", contractArgs);
            const result = await Phoenix.getInstance().callPhoenixSlapshot(contract);
            if (result?.response?.ok) {
                const uploadLink = result.json.ImageAttachmentTinyURLGenerator?.data[0]?.imageAttachmentURL;
                if (uploadLink && uploadLink.length > 0) {
                    setTinyUrl(uploadLink);
                    Phoenix.getInstance().addMetric("ImageUploadLinkGenerated.Success", 1, MetricType.COUNT);
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate the image attachment link for contact: " + contact.contactId);
                Phoenix.getInstance().addMetric("ImageUploadLinkGenerated.Failure." + result.response.status, 1, MetricType.COUNT);
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate the image attachment link for contact: " + contact.contactId + " " + error);
            Phoenix.getInstance().addMetric("ImageUploadLinkGenerated.Failure", 1, MetricType.COUNT);
        } finally {
            setIsGeneratingLink(false);
        }
    }

    async function sendImageUploadLink(): Promise<void> {
        setIsSendingLink(true);
        try {
            const contractArgs = {};
            contractArgs["marketplaceId"] = marketplaceId;
            contractArgs["contactId"] = contact.contactId;
            contractArgs["customerId"] = customerId;

            const sendImageUploadLinkContract = getSlapshotContract("SendImageAttachmentLink", "ImageAttachment", contractArgs);
            const result = await Phoenix.getInstance().callPhoenixSlapshot(sendImageUploadLinkContract);

            if (result.response.ok) {
                setModalViewVisibility(false);
                const annotationMutation = buildAddAgentAnnotationMutation(contact.contactId, i18n("IMAGE_UPLOAD_LINK_ANNOTATION"));
                const annotationResponse = await callPhoenix(annotationMutation);
                if (annotationResponse?.callResult?.resultJson) {
                    const parsed = JSON.parse(annotationResponse.callResult.resultJson);
                    if (parsed?.data?.addAgentAnnotation?.isSuccess) {
                        await reloadSingleContact(contact.contactId);
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to send the image attachment link as SMS to customerID: " + customerId);
                Phoenix.getInstance().addMetric("SendImageUploadLink.Failure." + result.response.status, 1, MetricType.COUNT);
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to send the image attachment link as SMS to customerID:" + customerId + error);
            Phoenix.getInstance().addMetric("SendImageUploadLink.Failure", 1, MetricType.COUNT);
        } finally {
            setIsSendingLink(false);
            setModalViewVisibility(false);
        }
    }

    function getSlapshotContract(contractId: string, projectName: string, fulfillmentArguments: Record<string, string>): SlapshotContract {
        return {
            contractId: contractId,
            projectName: projectName,
            fulfillmentArguments: fulfillmentArguments
        };
    }

    return (
        <div>
            <SpaceBetween direction={"horizontal"} size={"xs"}>
                <Box margin={{top: "s"}} textAlign="right">
                    <Button onClick={(): void => setModalViewVisibility(true)} disabled={isSendingLink}>
                        {i18n("SMS_LINK")}
                    </Button>
                </Box>
                <Box margin={{top: "s"}} textAlign="right">
                    <Button onClick={(): Promise<void> => generateImageUploadLink()} disabled={isGeneratingLink}>
                        {i18n("GENERATE_LINK")}
                    </Button>
                </Box>
                {tinyUrl && tinyUrl.length > 0 ?
                    <Box margin={{top: "s"}} textAlign="right">
                        <Copyable actionTranslationId="COPY_LINK"
                                  successTranslationId="LINK_COPIED" content={<Link>{tinyUrl}</Link>}
                                  valueToCopy={tinyUrl}/>
                    </Box> : null}
            </SpaceBetween>
            <Modal
                onDismiss={(): void => setModalViewVisibility(false)}
                visible={modalViewVisibility}
                closeAriaLabel={i18n("CLOSE_MODAL_BUTTON_TEXT")}
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={(): void => setModalViewVisibility(false)}>{i18n("MODAL_NO_BUTTON_TEXT")}</Button>
                            <Button variant="primary" onClick={(): Promise<void> => sendImageUploadLink()}
                                    disabled={isSendingLink}>{i18n("MODAL_YES_BUTTON_TEXT")}</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={i18n("WARNING")}
            >
                {i18n("SEND_IMAGE_UPLOAD_LINK_WARNING")}
            </Modal>
        </div>
    );
};

export default ImageUploadLinkActions;

