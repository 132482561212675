import React, {useContext, useState} from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import {i18n} from "../../i18n/IntlManager";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {Contact, ContactStatus} from "../Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Translation from "../../i18n/Translate";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import TimeInput from "@amzn/awsui-components-react/polaris/time-input";
import Form from "@amzn/awsui-components-react/polaris/form";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {resolveStringToISO} from "../../util/FollowUpUtils";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import {buildAddAgentAnnotationMutation} from "../ContactTimelineDisplay";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import Phoenix from "../../api/Phoenix";
import {MetricType} from "@amzn/csphoenix-react-client";
import moment from "moment-timezone";

interface CreateFollowUpFormProps {
    contact: Contact;
    emailToken: string;
    timezone: string;
    customerId?: string;
    marketplaceId: string;
    pushNotification: (msg: JSX.Element, type: FlashbarProps.Type) => void;
}

/**
 * Enum to represent the state for creating a new follow-up
 * `SUCCESS` - 200 response code and response has follow-up
 * `BAD_CONTACT` - 200 response code and response has bad contact error
 * `BAD_DATE` - 200 response code and response has bad date error
 * `ERROR` - 400 response code
 * `FAIL` - Any other response code
 * `ANNOTATION_FAILED` - The follow up was successfully created but not the annotation
 * `NONE` - No request made
 */
enum CreateContactFollowUpStatus {
    SUCCESS,
    BAD_CONTACT,
    BAD_DATE,
    ERROR,
    FAIL,
    ANNOTATION_FAILED,
    NONE
}

const statusToResponseStringIdMap: { [key: string]: string } = {
    [CreateContactFollowUpStatus.SUCCESS]: "CREATE_FOLLOW_UP_SUCCESS",
    [CreateContactFollowUpStatus.BAD_CONTACT]: "INVALID_CONTACT",
    [CreateContactFollowUpStatus.BAD_DATE]: "CREATE_FOLLOW_UP_DATE_IN_THE_PAST",
    [CreateContactFollowUpStatus.ERROR]: "CREATE_FOLLOW_UP_INVALID",
    [CreateContactFollowUpStatus.FAIL]: "CREATE_FOLLOW_UP_FAILURE",
    [CreateContactFollowUpStatus.ANNOTATION_FAILED]: "CREATE_FOLLOW_UP_ANNOTATION_ERROR",
};

const statusToMetricNameMap: { [key: string]: string } = {
    [CreateContactFollowUpStatus.SUCCESS]: "SUCCESS",
    [CreateContactFollowUpStatus.BAD_CONTACT]: "BAD_CONTACT",
    [CreateContactFollowUpStatus.BAD_DATE]: "BAD_DATE",
    [CreateContactFollowUpStatus.ERROR]: "ERROR",
    [CreateContactFollowUpStatus.FAIL]: "FAIL",
    [CreateContactFollowUpStatus.ANNOTATION_FAILED]: "ANNOTATION_FAILED",
};

// https://tiny.amazon.com/asnsq800/codeamazpackCSFoblobrelesrc
// https://tiny.amazon.com/18qb698kx/codeamazpackCSFoblobrelesrc
// Also considering for UTF-8 characters (max 4 bytes per character), we will use the "safe" bound(treat all characters
// as 3 bytes). 4 byte characters are too rare in our use case. (https://design215.com/toolbox/utf8-4byte-characters.php)
const MAX_NOTE_LENGTH = 979; // (4000 * 0.75 - 63) / 3
const MAX_CONTACT_METHOD_LENGTH = 354; // (1500 * 0.75 - 63) / 3

function buildCreateFollowUpMutation(
    contactId: string, dueDate: string, emailToken: string,
    note: string, preferredContact: string, customerId?: string | undefined): string {
    // The reason we stringify user input here is in case there are things like double quote. That will break our
    // mutation request once inserted.
    const stringifiedNote = JSON.stringify(note);
    const stringifiedPreferredContact = JSON.stringify(preferredContact);

    return `mutation {
          createFollowUpForContact(
        associatedContactId: "${contactId}",
        ${customerId ? `customerId:"${customerId}",` : ""}
        dueDate: "${dueDate}",
        customerEmailAddressEncrypted: "${emailToken}",
        followUpNoteEncrypted: ${stringifiedNote},
        preferredContactMethodEncrypted: ${stringifiedPreferredContact}
        )
         {
            error {
                type
                errorMessageDescriptor {
                    stringId
                }
            }
            followUp {
                followUpId
                dueDate
                note
                resolved
                ticketId
                owner {
                    agentId
                    agentLogin
                }
            }    
         }
    }`;
}

const CreateFollowUpForm: React.FC<CreateFollowUpFormProps> = ({contact, emailToken, timezone, pushNotification, customerId, marketplaceId}) => {

    const [dueDate, setDueDate] = useState<string>("");
    const [dueTime, setDueTime] = useState<string>("00:00");
    const [note, setNote] = useState<string>("");
    const [methodOfContact, setMethodOfContact] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {reloadSingleContact, callPhoenix} = useContext(IssueManagerContext);

    // For this function to get called, dueDate, dueTime and note are guaranteed to be non-empty
    async function createFollowUp(): Promise<void> {
        let status: CreateContactFollowUpStatus = CreateContactFollowUpStatus.NONE;
        setIsLoading(true);
        try {
            const isoDate = resolveStringToISO(dueDate, dueTime, timezone);
            const mutation = buildCreateFollowUpMutation(
                contact.contactId,
                isoDate,
                emailToken,
                note,
                methodOfContact,
                customerId
            );
            const response = await callPhoenix(mutation);
            // Status code 200
            if (response?.callResult?.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed?.data?.createContactFollowUp?.followUp || parsed?.data?.createFollowUpForContact?.followUp) {
                    // Create an annotation if successful
                    const annotationMutation = buildAddAgentAnnotationMutation(contact.contactId, buildAnnotationMessage(note));
                    const annotationResponse = await callPhoenix(annotationMutation);
                    // Update the current contact if follow up is created successfully
                    await reloadSingleContact(contact.contactId);
                    setDueDate("");
                    setDueTime("00:00");
                    setNote("");
                    setMethodOfContact("");
                    if (annotationResponse?.callResult?.resultJson) {
                        const parsed = JSON.parse(annotationResponse.callResult.resultJson);
                        if (parsed?.data?.addAgentAnnotation?.isSuccess) {
                            status = CreateContactFollowUpStatus.SUCCESS;
                        } else {
                            status = CreateContactFollowUpStatus.ANNOTATION_FAILED;
                        }
                    } else {
                        status = CreateContactFollowUpStatus.ANNOTATION_FAILED;
                    }
                } else if (parsed?.data?.createContactFollowUp?.error?.type) {
                    if (parsed.data.createContactFollowUp.error.type === "CONTACT_NOT_FOUND") {
                        status = CreateContactFollowUpStatus.BAD_CONTACT;
                    } else if (parsed.data.createContactFollowUp.error.type === "INCORRECT_DUE_DATE_FORMAT") {
                        status = CreateContactFollowUpStatus.BAD_DATE;
                    }
                } else {
                    // Some other kind of error
                    status = CreateContactFollowUpStatus.FAIL;
                }
            }
            // Restricted data present
            else if (response.httpStatus === 400) {
                status = CreateContactFollowUpStatus.ERROR;
            }
            // Some other kind of error
            else {
                status = CreateContactFollowUpStatus.FAIL;
            }
        } catch (e) {
            status = CreateContactFollowUpStatus.FAIL;
        } finally {
            setIsLoading(false);
        }
        pushNotification(createFollowUpResponseMessage(status), status === CreateContactFollowUpStatus.SUCCESS ? "success" : "error");
        Phoenix.getInstance().addMetric(createFollowUpMetricName(status), 1, MetricType.COUNT);
        Phoenix.getInstance().addMetric("CreateContactFollowUp", 1, MetricType.COUNT);

        if (status === CreateContactFollowUpStatus.SUCCESS) {
            Phoenix.getInstance().addMetric("CreateContactFollowUp.LockedState", (contact.status === ContactStatus.LOCKED) ? 1 : 0, MetricType.COUNT);
            Phoenix.getInstance().addMetric("CreateContactFollowUp.ResolvedState", (contact.status === ContactStatus.RESOLVED) ? 1 : 0, MetricType.COUNT);
        }
    }

    function buildAnnotationMessage(note: string): string {
        return "Scheduled to follow-up: " +
            "\n" +
            note;
    }

    function createFollowUpResponseMessage(status: CreateContactFollowUpStatus): JSX.Element {
        return status === CreateContactFollowUpStatus.NONE ?
            <></> :
            <Translation stringId={statusToResponseStringIdMap[status]} />;
    }

    function createFollowUpMetricName(status: CreateContactFollowUpStatus): string {
        const metricName = "CreateContactFollowUp.";
        return status === CreateContactFollowUpStatus.NONE ?
            "" :
            metricName + statusToMetricNameMap[status];
    }

    function dueTimeIsInThePast(): boolean {
        const pickedTime = moment.tz(`${dueDate} ${dueTime}`, "YYYY/MM/DD HH:mm", timezone);
        const currentTime = moment.tz(timezone);
        return pickedTime < currentTime;
    }

    function cannotSubmit(): boolean {
        return !dueDate || !dueTime || !note || !methodOfContact || dueTimeIsInThePast();
    }

    return (
        <Container header={<Header variant={"h2"}><Translation stringId={"CREATE_FOLLOW_UP"}/></Header>}>
            <Form
                actions={
                    <Button
                        variant="primary"
                        disabled={cannotSubmit()}
                        loading={isLoading}
                        onClick={(): void => {
                            createFollowUp();
                        }}
                    >
                        {i18n("CONFIRM")}</Button>
                }
            >
                <FormField
                    description={
                        `${i18n("CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION")} (${i18n("CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE")}: ${timezone})`
                    }
                    label={i18n("FOLLOW_UP_DUE_DATE")}
                >
                    <SpaceBetween direction="horizontal" size="xs">
                        <FormField>
                            <DatePicker
                                disabled={isLoading}
                                onChange={({detail}): void => setDueDate(detail.value)}
                                value={dueDate}
                                isDateEnabled={(datePickerDate): boolean => {
                                    const currentDate = new Date();
                                    currentDate.setHours(0, 0, 0, 0);
                                    return datePickerDate >= currentDate;
                                }}
                                nextMonthAriaLabel={i18n("DATE_PICKER_NEXT_MONTH")}
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel={i18n("DATE_PICKER_PREVIOUS_MONTH")}
                                todayAriaLabel={i18n("DATE_PICKER_TODAY")}
                            />
                        </FormField>
                        <FormField
                            constraintText={i18n("TIME_TEXT_FORMAT_CONSTRAINT")}
                        >
                            <TimeInput
                                disabled={isLoading}
                                onChange={({detail}): void => setDueTime(detail.value)}
                                value={dueTime}
                                format="hh:mm"
                            />
                        </FormField>
                    </SpaceBetween>
                    <Alert
                        dismissible={false}
                        visible={dueTimeIsInThePast()}
                        type="warning"
                    >
                        <Translation stringId={"CREATE_FOLLOW_UP_DATE_IN_THE_PAST"} />
                    </Alert>
                </FormField>
                <FormField
                    description={
                        i18n("CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION")+
                            " " +
                            i18n("INPUT_CHARACTERS_REMAINING", {
                                values: {
                                    "char_max": MAX_CONTACT_METHOD_LENGTH,
                                    "char_remain":(MAX_CONTACT_METHOD_LENGTH - methodOfContact.length).toString()
                                }
                            })
                    }
                    label={i18n("FOLLOW_UP_CONTACT_METHOD")}
                >
                    <Input
                        disabled={isLoading}
                        onChange={({detail}): void => {
                            if (detail.value.length <= MAX_CONTACT_METHOD_LENGTH) {
                                setMethodOfContact(detail.value);
                            }
                        }}
                        value={methodOfContact}
                    />
                </FormField>
                <FormField
                    description={
                        i18n("CREATE_FOLLOW_UP_NOTE_DESCRIPTION") +
                            " " +
                            i18n("INPUT_CHARACTERS_REMAINING", {
                                values: {
                                    "char_max": MAX_NOTE_LENGTH,
                                    "char_remain":(MAX_NOTE_LENGTH - note.length).toString()
                                }
                            })
                    }
                    label={i18n("FOLLOW_UP_NOTE")}
                >
                    <Textarea
                        disabled={isLoading}
                        rows={2}
                        value={note}
                        onChange={({detail}): void => {
                            if (detail.value.length <= MAX_NOTE_LENGTH) {
                                setNote(detail.value);
                            }
                        }}
                    />
                </FormField>
            </Form>
        </Container>
    );
};

export default CreateFollowUpForm;