/* eslint-disable jsx-a11y/media-has-caption */
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import {RouteComponentProps} from "react-router-dom";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Button from "@amzn/awsui-components-react/polaris/button";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import {Contact, CustomerAccessLevel, Purpose, UsecaseSession} from "../contact/Contact.types";
import ContactDisplay, {ContactDisplayContext} from "../contact/ContactDisplay";
import Translation from "../i18n/Translate";
import IntlManager, {i18n} from "../i18n/IntlManager";
import './IssueManagerStyle.css';
import EmdashImage from "../util/EmdashImage";
import Phoenix from '../api/Phoenix';
import {
    CallResponse,
    JwtToken,
    LogLevel,
    MetricDimension,
    MetricType,
    PhoenixRequest, Weblab
} from "@amzn/csphoenix-react-client";
import moment from "moment-timezone";
import {Copyable} from "../util/Copyable";
import {RunLocal} from "../devtools/RunLocal";
import {AuthorizerJwtToken, extractEntityIds, mergeTokens} from "../authorizer/AuthorizerJwtToken";
import {TokenStatus} from "../devtools/TokenStatus";
import {fetchCustomerTimezone} from "../customerTimezone/customerTimezone";
import {CookieDisabledAlert} from "../components/CookieDisabledAlert";
import {parseParams} from "../util/Params";
import {TimeZoneInfo} from "../customerTimezone/TimeZoneInfo";
import InternalSyskaDisplay from "../contact/InternalSyskaDisplay";
import SpamAndDuplicate from "../contact/spam/SpamAndDuplicate";
import FollowUpsForCustomerDisplay from "../contact/FollowUpsForCustomerDisplay";
import {ContactFinder} from "../contactFinder/ContactFinder";
import {AppLayout} from "@amzn/awsui-components-react";
import {PurposeSelection} from "../authorizer/PurposeSelection";
import {UsecaseSessions} from "../devtools/UsecaseSessions";

// TODO: Do not include startKey in the query if it's the first page.
const bulkContactFields =
    `       contacts { ` +
    `         contactId ` +
    `         marketplaceId ` +
    `         creationDate ` +
    `         status ` +
    `         owner { ` +
    `           agentId` +
    `           agentLogin` +
    `         }` +
    `         medium { ` +
    `           type ` +
    `           emailSubject ` +
    `           emailHeader ` +
    `           emailBodyHTMLSafe` +
    `           rosettaTranslatedInboundEmail { ` +
    `             sourceLanguage ` +
    `             emailBodyHTMLSafe { ` +
    `               encryptedString ` +
    `              } ` +
    `           } ` +
    `           emailBodyPreTranslationCSAText` +
    `           sender {` +
    `             emailAddress` +
    `           }` +
    `           chat {` +
    `             messages {` +
    `               type ` +
    `               timestamp ` +
    `               participant { ` +
    `                 __typename ` +
    `                 preferredLanguage ` +
    `                 ... on CustomerChatParticipant { ` +
    `                     customerName ` +
    `                 } ` +
    `                 ... on CSAChatParticipant { ` +
    `                     name ` +
    `                 } ` +
    `                 ... on BotChatParticipant { ` +
    `                     name ` +
    `                 } ` +
    `               } ` +
    `               chatText { ` +
    `                 text ` +
    `                 language ` +
    `               } ` +
    `               translationList { ` +
    `                 text ` +
    `                 language ` +
    `               } ` +
    `               stateChangeType ` +
    `               disconnectReason ` +
    `             } ` +
    `           }` +
    `           phoneCustomerMediaLegId` +
    `           attachments {` +
    `             fileName` +
    `             fileSize` +
    `             fileType` +
    `             attachmentId` +
    `           }` +
    `           connectPhoneRecordList { ` +
`                 cscRecordingAccessStatus` +
    `             connectContactId` +
    `                 agent {` +
    `                  agentId` +
    `                  agentLogin` +
    `                 }` +
    `               }` +
    `         }` +
    `         agentAnnotations { ` +
    `           agent { ` +
    `             agentId` +
    `             agentLogin` +
    `           }` +
    `           message ` +
    `           date ` +
    `         }` +
    `         autoAnnotations { ` +
    `           agent { ` +
    `             agentId` +
    `             agentLogin` +
    `           }` +
    `           description ` +
    `           date ` +
    `         }` +
    `         orderIds ` +
    `         customerSelectedIssue { ` +
    `           descriptors ` +
    `         }` +
    `         agentSelectedIssue { ` +
    `           descriptors ` +
    `         }` +
    `         replies { ` +
    `           contactId ` +
    `           creationDate ` +
    `           medium { ` +
    `             type ` +
    `             emailSubject ` +
    `             emailBodyHTMLSafe` +
    `             emailBodyPreTranslationCSAText` +
    `             sender {` +
    `               emailAddress` +
    `             }` +
    `             receiver {` +
    `               emailAddress` +
    `             }` +
    `           }` +
    `         }` +
    `         followUps {` +
    `           followUpId ` +
    `           dueDate ` +
    `           note ` +
    `           contactMethod ` +
    `           ticketId ` +
    `           resolved ` +
    `           owner { ` +
    `             agentId ` +
    `             agentLogin` +
    `           }` +
    `         }` +
    `         workCategory { ` +
    `           workCategoryId` +
    `           workCategoryName` +
    `         }` +
    `         transferredFromContact { ` +
    `           medium { ` +
    `             type ` +
    `             chat {` +
    `               messages {` +
    `                 type ` +
    `                 timestamp ` +
    `                 participant { ` +
    `                   __typename ` +
    `                   preferredLanguage ` +
    `                   ... on CustomerChatParticipant { ` +
    `                       customerName ` +
    `                   } ` +
    `                   ... on CSAChatParticipant { ` +
    `                       name ` +
    `                   } ` +
    `                   ... on BotChatParticipant { ` +
    `                       name ` +
    `                   } ` +
    `                 } ` +
    `                 chatText { ` +
    `                   text ` +
    `                   language ` +
    `                 } ` +
    `                 translationList { ` +
    `                   text ` +
    `                   language ` +
    `                 } ` +
    `                 stateChangeType ` +
    `                 disconnectReason ` +
    `               } ` +
    `             }` +
    `             phoneCustomerMediaLegId` +
    `           }` +
    `         }` +
    `         dartEscalationContactList { ` +
    `           contactId ` +
    `           owner { ` +
    `               agentId` +
    `               agentLogin` +
    `           }` +
    `           medium { ` +
    `             type ` +
    `             chat {` +
    `               messages {` +
    `                 type ` +
    `                 timestamp ` +
    `                 participant { ` +
    `                   __typename ` +
    `                   preferredLanguage ` +
    `                   ... on CustomerChatParticipant { ` +
    `                       customerName ` +
    `                   } ` +
    `                   ... on CSAChatParticipant { ` +
    `                       name ` +
    `                   } ` +
    `                   ... on BotChatParticipant { ` +
    `                       name ` +
    `                   } ` +
    `                 } ` +
    `                 chatText { ` +
    `                   text ` +
    `                   language ` +
    `                 } ` +
    `                 translationList { ` +
    `                   text ` +
    `                   language ` +
    `                 } ` +
    `                 stateChangeType ` +
    `                 disconnectReason ` +
    `               } ` +
    `             }` +
    `             phoneCustomerMediaLegId` +
    `           }` +
    `         }` +
    `         stateTransitionList { ` +
    `           type ` +
    `           date ` +
    `           displayableDestination ` +
    `           agent { ` +
    `             agentId` +
    `             agentLogin` +
    `             agentOwner` +
    `           }` +
    `         }` +
    `         workRequestParentContact { ` +
    `           contactId` +
    `         }` +
    `       },` +
    `       nextKey`;



function buildQuery(customerId: string, marketplaceId: string, startKey: string, startDate: string, endDate: string, emailToken: string | null): string {
    return `query { ` +
        `   contactsForCustomer ( customerId: "${customerId}", ` +
        (emailToken ? `                      emailAddress: "${emailToken}", ` : '') +
        `                      marketplaceId: "${marketplaceId}", ` +
        `                      startDate: "${startDate}", ` +
        `                      endDate: "${endDate}", ` +
        `                      startKey: "${startKey}", ` +
        `                      pageSize: 5 ) { ` +
        bulkContactFields +
        `   }` +
        `}`;
}

function buildByEmailQuery(emailToken: string, marketplaceId: string, startKey: string, startDate: string, endDate: string): string {
    return `query { ` +
        `   contactsForEmail ( ` +
        `                      emailAddress: "${emailToken}", ` +
        `                      marketplaceId: "${marketplaceId}", ` +
        `                      startDate: "${startDate}", ` +
        `                      endDate: "${endDate}", ` +
        (startKey ? `                      startKey: "${startKey}", ` : "") +
        `                      pageSize: 5 ) { ` +
        bulkContactFields +
        `   }` +
        `}`;
}

function buildSingleContactQuery(contactId: string): string {
    return `query { ` +
        `   contact ( contactId: "${contactId}") { ` +
        `       contact { ` +
        `         contactId ` +
        `         marketplaceId ` +
        `         creationDate ` +
        `         status ` +
        `         owner { ` +
        `           agentId` +
        `           agentLogin` +
        `         }` +
        `         medium { ` +
        `           type ` +
        `           emailSubject ` +
        `           emailHeader ` +
        `           emailBodyHTMLSafe` +
        `           rosettaTranslatedInboundEmail { ` +
        `             sourceLanguage ` +
        `             emailBodyHTMLSafe { ` +
        `               encryptedString ` +
        `              } ` +
        `           } ` +
        `           sender {` +
        `             emailAddress` +
        `           }` +
        `           chat {` +
        `             messages {` +
        `               type ` +
        `               timestamp ` +
        `               participant { ` +
        `                 __typename ` +
        `                 preferredLanguage ` +
        `                 ... on CustomerChatParticipant { ` +
        `                     customerName ` +
        `                 } ` +
        `                 ... on CSAChatParticipant { ` +
        `                     name ` +
        `                 } ` +
        `                 ... on BotChatParticipant { ` +
        `                     name ` +
        `                 } ` +
        `               } ` +
        `               chatText { ` +
        `                 text ` +
        `                 language ` +
        `               } ` +
        `               translationList { ` +
        `                 text ` +
        `                 language ` +
        `               } ` +
        `               stateChangeType ` +
        `               disconnectReason ` +
        `             } ` +
        `           }` +
        `           phoneCustomerMediaLegId` +
        `           attachments {` +
        `             fileName` +
        `             fileSize` +
        `             fileType` +
        `             attachmentId` +
        `           }` +
        `           connectPhoneRecordList { ` +
        `             cscRecordingAccessStatus` +
        `             connectContactId` +
        `                 agent {` +
        `                  agentId` +
        `                  agentLogin` +
        `                 }` +
        `               }` +
        `         }` +
        `         agentAnnotations { ` +
        `           agent { ` +
        `             agentId` +
        `             agentLogin` +
        `           }` +
        `           message ` +
        `           date ` +
        `         }` +
        `         autoAnnotations { ` +
        `           agent { ` +
        `             agentId` +
        `             agentLogin` +
        `           }` +
        `           description ` +
        `           date ` +
        `         }` +
        `         orderIds ` +
        `         customerSelectedIssue { ` +
        `           descriptors ` +
        `         }` +
        `         agentSelectedIssue { ` +
        `           descriptors ` +
        `         }` +
        `         replies { ` +
        `           contactId ` +
        `           creationDate ` +
        `           medium { ` +
        `             type ` +
        `             emailSubject ` +
        `             emailBodyHTMLSafe` +
        `             emailBodyPreTranslationCSAText` +
        `             sender {` +
        `               emailAddress` +
        `             }` +
        `             receiver {` +
        `               emailAddress` +
        `             }` +
        `           }` +
        `         }` +
        `         followUps {` +
        `           followUpId ` +
        `           dueDate ` +
        `           note ` +
        `           contactMethod ` +
        `           ticketId ` +
        `           resolved ` +
        `           owner { ` +
        `             agentId ` +
        `             agentLogin` +
        `           }` +
        `         }` +
        `         workCategory { ` +
        `           workCategoryId` +
        `           workCategoryName` +
        `         }` +
        `         transferredFromContact { ` +
        `           medium { ` +
        `             type ` +
        `             chat {` +
        `               messages {` +
        `                 type ` +
        `                 timestamp ` +
        `                 participant { ` +
        `                   __typename ` +
        `                   preferredLanguage ` +
        `                   ... on CustomerChatParticipant { ` +
        `                       customerName ` +
        `                   } ` +
        `                   ... on CSAChatParticipant { ` +
        `                       name ` +
        `                   } ` +
        `                   ... on BotChatParticipant { ` +
        `                       name ` +
        `                   } ` +
        `                 } ` +
        `                 chatText { ` +
        `                   text ` +
        `                   language ` +
        `                 } ` +
        `                 translationList { ` +
        `                   text ` +
        `                   language ` +
        `                 } ` +
        `                 stateChangeType ` +
        `                 disconnectReason ` +
        `               } ` +
        `             }` +
        `             phoneCustomerMediaLegId` +
        `           }` +
        `         }` +
        `         dartEscalationContactList { ` +
        `           contactId ` +
        `           owner { ` +
        `               agentId` +
        `               agentLogin` +
        `           }` +
        `           medium { ` +
        `             type ` +
        `             chat {` +
        `               messages {` +
        `                 type ` +
        `                 timestamp ` +
        `                 participant { ` +
        `                   __typename ` +
        `                   preferredLanguage ` +
        `                   ... on CustomerChatParticipant { ` +
        `                       customerName ` +
        `                   } ` +
        `                   ... on CSAChatParticipant { ` +
        `                       name ` +
        `                   } ` +
        `                   ... on BotChatParticipant { ` +
        `                       name ` +
        `                   } ` +
        `                 } ` +
        `                 chatText { ` +
        `                   text ` +
        `                   language ` +
        `                 } ` +
        `                 translationList { ` +
        `                   text ` +
        `                   language ` +
        `                 } ` +
        `                 stateChangeType ` +
        `                 disconnectReason ` +
        `               } ` +
        `             }` +
        `             phoneCustomerMediaLegId` +
        `           }` +
        `         }` +
        `         stateTransitionList { ` +
        `           type ` +
        `           date ` +
        `           displayableDestination ` +
        `           agent { ` +
        `             agentId` +
        `             agentLogin` +
        `             agentOwner` +
        `           }` +
        `         }` +
        `         workRequestParentContact { ` +
        `           contactId` +
        `         }` +
        `       }` +
        `   }` +
        `}`;
}



// Custom error for missing fields
export class MissingFieldsError extends Error {
    constructor(m?: string) {
        super(m);
        Object.setPrototypeOf(this, MissingFieldsError.prototype); 
    }
}

// Custom error for internal erorrs
export class InternalError extends Error {
    constructor(m?: string) {
        super(m);
        Object.setPrototypeOf(this, MissingFieldsError.prototype); 
    }
}

/**
 * We keep a global copy of the auth tokens here.
 * We do not generally want to trigger a React re-render of all
 * components when these change so by using this buildCSALTRequest
 * function that only takes a query string that can be avoided.
 */
let theJwtState: JwtToken | null;
let theAuthTokens: AuthorizerJwtToken[] = [];
export function buildCSALTRequest(query: string, jwtState: JwtToken | null | undefined, jwtAuthTokens: AuthorizerJwtToken[] | null | undefined): PhoenixRequest {
    let jwtString: string | undefined;
    if (jwtState) {
        jwtString = jwtState.jwtString;
    } else if (theJwtState) {
        jwtString = theJwtState.jwtString;
    } else {
        jwtString = undefined;
    }

    return {
        query: query,
        contractId: "CSALTGraphQL",
        projectName: "CSALT",
        jwtState: jwtString,
        jwtAuthTokens: jwtAuthTokens ? jwtAuthTokens.map(token => token.jwtString) : theAuthTokens.map(token => token.jwtString)
    };
}

export function getMaxAccessLevel(callResponse: CallResponse | undefined): CustomerAccessLevel {
    let result: CustomerAccessLevel = CustomerAccessLevel.CONFIDENTIAL;
    if (callResponse?.callResult?.rules) {
        if (callResponse.callResult.rules.filter(r => r.grants === "RESTRICTED" && r.allow).length > 0) {
            result = CustomerAccessLevel.RESTRICTED;
        }
        if (callResponse.callResult.rules.filter(r => r.grants === "RESTRICTED_PII" && r.allow).length > 0) {
            result = CustomerAccessLevel.RESTRICTED_PII;
        }
    }
    return result;
}

export function emitLatency(metricName: string, latency: number): void {
    const dimensions: MetricDimension[] = [];
    const browserTimezone = moment.tz.guess();
    if (browserTimezone) {
        dimensions.push({name: "Timezone", value: browserTimezone});
    }
    Phoenix.getInstance().addMetricWithDimensions(metricName, latency, MetricType.TIME, dimensions);
    Phoenix.getInstance().addMetric(metricName, latency, MetricType.TIME);
}

export interface LoadFullContactResponse {
    contact: Contact;
    customerAccessLevel: CustomerAccessLevel;
}

export async function loadFullContact(contactId: string): Promise<LoadFullContactResponse> {
    const query = buildSingleContactQuery(contactId);
    const response = await callPhoenixUsingGlobals(query);
    if (response.callResult) {
        if (response.callResult.resultJson) {
            const parsed = JSON.parse(response.callResult.resultJson);
            if (parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                const fetchedContact: Contact = parsed.data.contact.contact;
                return {
                    contact: fetchedContact,
                    customerAccessLevel: getMaxAccessLevel(response),
                };
            }
        }
    }
    throw new Error("Failed to fetch Contact: " + contactId);
}

async function callPhoenixUsingGlobals(request: string, newJwtState?: JwtToken | null, jwtAuthTokens?: AuthorizerJwtToken[] | null ): Promise<CallResponse> {
    const phoenixRequest = buildCSALTRequest(request, newJwtState, jwtAuthTokens);
    return Phoenix.getInstance().csalt(phoenixRequest);
}
export { callPhoenixUsingGlobals as callPhoenix };

interface IssueManagerContextType {
    reloadSingleContact: (contactId: string, newToken?: AuthorizerJwtToken) => Promise<void>;
    reloadSelectedContacts: (contactsToUpdate: string[]) => Promise<void>;
    callPhoenix: (request: string, newJwtState?: JwtToken | null, jwtAuthTokens?: AuthorizerJwtToken[] | null) => Promise<CallResponse>;
    getSubject: () => string | undefined;
    clientStateToken?: JwtToken;
    setClientStateToken: (JwtToken) => void;
    addAuthorizerJwtToken: (AuthorizerJwtToken) => void;
    authorizerJwtTokens: AuthorizerJwtToken[];
    purpose: string;
    hasEntityTokenForPurpose: boolean;
    weblab?: Weblab;
}

export const IssueManagerContext = React.createContext({} as IssueManagerContextType);

export const IssueManager: React.FC<RouteComponentProps> = ({location}) => {
    const {emailToken, initialCommId, customerIdList, marketplaceId, contactToken} = parseParams(location);

    const [useContactFinder, setUseContactFinderState] = useState<boolean>(false);
    const customerIdSelectionList: SelectProps.Option[] = customerIdList.map(cid => {
        return {value: cid, label: cid};
    });
    const [customerId, setCustomerId] = useState<string>(customerIdList.length ? customerIdList[0] : "");
    const [singleContactLoading, setSingleContactLoading] = useState<boolean>(false);
    const [contactHistoryLoading, setContactHistoryLoading] = useState<boolean>(false);
    const [customerAccessLevelSingleContact, setCustomerAccessLevelSingleContact] = useState<CustomerAccessLevel>(CustomerAccessLevel.CONFIDENTIAL);
    const [customerAccessLevelContactHistory, setCustomerAccessLevelContactHistory] = useState<CustomerAccessLevel>(CustomerAccessLevel.CONFIDENTIAL);
    const [customerTimezone, setCustomerTimezone] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [jwtState, setJwtState] = useState<JwtToken | null>(Phoenix.getInstance().jwtState);
    const [singleContact, setSingleContact] = useState<Contact | null>(null);
    const [contactHistoryList, setContactHistoryList] = useState<Contact[] | null>(null);
    const [startKey, setStartKey] = useState<string>("");
    const [activeRole, setActiveRole] = useState<SelectProps.Option>(jwtState ?
        {value: jwtState.getRole(), label: jwtState.getRole()}
        : {value: "CSA", label: "CSA"});
    const [availableRoles, setAvailableRoles] = useState<SelectProps.Option[]>([activeRole]);

    const [callResponse, setCallResponse] = useState<CallResponse>();
    const [authorizerJwtTokens, setAuthorizerJwtTokens] = useState<AuthorizerJwtToken[]>([]);

    // Keep this globals in sync with state
    theJwtState = jwtState;
    theAuthTokens = authorizerJwtTokens;

    // Moment Timezone uses the Internationalization API Intl.DateTimeFormat().resolvedOptions().timeZone
    // to determine the user's time zone.
    // https://momentjs.com/timezone/docs/#/using-timezones/
    const isCustomerTimezone = !!(customerTimezone && customerTimezone.trim().length > 0);
    const timezone = isCustomerTimezone ? customerTimezone : moment.tz.guess();

    const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;

    const purpose: Purpose = jwtState?.getContent().ap ? jwtState?.getContent().ap : Purpose.u;

    function hasEntityTokenForPurposeFn(): boolean {
        let result = false;
        extractEntityIds(purpose, authorizerJwtTokens).forEach((entityId) => {
            if (singleContact?.contactId) {
                if (entityId.type === 'CONTACT' &&
                    singleContact.contactId === entityId.id) {
                    result = true;
                }
            }
            if (customerId) {
                if (entityId.type === 'CUSTOMER' &&
                    customerId === entityId.id) {
                    result = true;
                }
            }
        });
        return result;
    }

    const hasEntityTokenForPurpose = hasEntityTokenForPurposeFn();

    const callPhoenix = async (request: string, newJwtState?: JwtToken | null, jwtAuthTokens?: AuthorizerJwtToken[] | null): Promise<CallResponse> => {
        const phoenixRequest = buildCSALTRequest(request, newJwtState ? newJwtState : jwtState, jwtAuthTokens ? jwtAuthTokens : authorizerJwtTokens);
        return Phoenix.getInstance().csalt(phoenixRequest);
    };

    // Context to be passed down to child components
    const issueManagerContext = {
        reloadSingleContact: (contactId: string, token?: AuthorizerJwtToken): Promise<void> => updateSingleContact(contactId, token),
        reloadSelectedContacts: (contactsToUpdate: string[]): Promise<void> => updateSelectedContacts(contactsToUpdate),
        callPhoenix,
        getSubject: (): string | undefined => {
            return jwtState?.getSubject();
        },
        clientStateToken: jwtState ? jwtState : undefined,
        setClientStateToken: (jwtState): void => {
            setJwtState(jwtState);
        },
        addAuthorizerJwtToken: (token: AuthorizerJwtToken): void => {
            setAuthorizerJwtTokens([...authorizerJwtTokens, token]);
        },
        authorizerJwtTokens: authorizerJwtTokens,
        purpose: purpose,
        hasEntityTokenForPurpose: hasEntityTokenForPurpose,
    };

    const endDate = new Date(); // now
    const startDate = new Date(endDate.getTime() - (400 * 24 * 3600 * 1000)); // 400 days before now

    function processCallResponse(response: CallResponse, isFirstPage: boolean): void {
        setContactHistoryLoading(false);
        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setError(error);
            });
            return;
        }
        setCallResponse(response);
        const customerAccessLevelForContactHistory: CustomerAccessLevel = getMaxAccessLevel(response);
        setCustomerAccessLevelContactHistory(customerAccessLevelForContactHistory);

        if (response.error) {
            setError(response.error);
        }
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                const responseContent = parsed?.data?.contactsForCustomer || parsed?.data?.contactsForEmail;
                if (responseContent) {
                    // If we get here then we did have a good call with results.
                    if (parsed?.data?.contactsForCustomer) {
                        emitLatency('LatencyContactsForCustomer', response.latency);
                    } else if (parsed?.data?.contactsForEmail) {
                        emitLatency('LatencyContactsForEmail', response.latency);
                    }
                    const fetchedContacts: Contact[] = responseContent?.contacts;
                    const contactListNew = (contactHistoryList && !isFirstPage) ? contactHistoryList.concat(fetchedContacts) : fetchedContacts;
                    setContactHistoryList(contactListNew.filter(contact => contact.contactId !== initialCommId));
                    setStartKey(responseContent.nextKey ? responseContent.nextKey : "");
                }
            }
            if (response.callResult.jwtState) {
                setJwtState(response.callResult.jwtState);
                setAvailableRoles(response.callResult.jwtState.getRoles().map((s: string) => ({
                    value: s,
                    label: s
                })));
            }
            if (response.callResult.jwtAuthTokens) {
                const tokens: AuthorizerJwtToken[] = response.callResult.jwtAuthTokens.map(token => new AuthorizerJwtToken(token));
                const mergedTokens: AuthorizerJwtToken[] = mergeTokens(tokens, authorizerJwtTokens);
                setAuthorizerJwtTokens(mergedTokens);
            }
        } else {
            setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
        }
    }

    function fetchContactsByCustomerId(customerId: string, startKey: string, jwtState: JwtToken | null, isFirstPage: boolean): void {
        if (customerId.trim().length > 0 && marketplaceId != null) {
            setContactHistoryLoading(true);
            const query = buildQuery(customerId, marketplaceId, startKey, startDate.toISOString(), endDate.toISOString(), emailToken);

            callPhoenix(query, jwtState)
                .then((response: CallResponse) => processCallResponse(response, isFirstPage))
                .catch(() => {
                    setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
                });
        }
    }

    function fetchContactsByCustomerEmail(startKey: string, jwtState: JwtToken | null, isFirstPage: boolean): void {
        if (emailToken && marketplaceId != null) {
            setContactHistoryLoading(true);
            const query = buildByEmailQuery(emailToken, marketplaceId, startKey, startDate.toISOString(), endDate.toISOString());

            callPhoenix(query, jwtState)
                .then((response: CallResponse) => processCallResponse(response, isFirstPage))
                .catch(() => {
                    setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
                });
        }
    }

    function processSingleContactResponse(response: CallResponse): void {
        setSingleContactLoading(false);
        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setError(error);
            });
            return;
        }
        setCallResponse(response);

        const customerAccessLevelForSingleContact: CustomerAccessLevel = getMaxAccessLevel(response);
        setCustomerAccessLevelSingleContact(customerAccessLevelForSingleContact);

        if (response.error) {
            setError(response.error);
        }
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                    // If we get here then we did have a good call with results.
                    emitLatency('LatencySingleContact', response.latency);
                    setSingleContact(parsed.data.contact.contact);
                }
            }
            if (response.callResult.jwtState) {
                setJwtState(response.callResult.jwtState);
                theJwtState = response.callResult.jwtState;
                setAvailableRoles(response.callResult.jwtState.getRoles().map((s: string) => ({
                    value: s,
                    label: s
                })));
            }
            if (response.callResult.jwtAuthTokens) {
                const tokens: AuthorizerJwtToken[] = response.callResult.jwtAuthTokens.map(token => new AuthorizerJwtToken(token));
                const mergedTokens: AuthorizerJwtToken[] = mergeTokens(tokens, authorizerJwtTokens);
                setAuthorizerJwtTokens(mergedTokens);
                theAuthTokens = mergedTokens;
            }
        } else {
            setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
        }
    }

    function fetchSingleContact(contactId: string, jwtState: JwtToken | null, newToken?: AuthorizerJwtToken): void {
        const query = buildSingleContactQuery(contactId);
        setSingleContactLoading(true);
        setStartKey("");
        setSingleContact(null);
        const jwtTokens = newToken ? [...authorizerJwtTokens, newToken] : authorizerJwtTokens;
        callPhoenix(query, jwtState, jwtTokens)
            .then((response: CallResponse) => processSingleContactResponse(response))
            .catch(() => {
                setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
            });
    }

    async function updateSingleContact(contactId: string, token?: AuthorizerJwtToken): Promise<void> {
        const query = buildSingleContactQuery(contactId);
        const jwtTokens = token ? [...authorizerJwtTokens, token] : authorizerJwtTokens;
        let response;
        
        try {
            response = await callPhoenix(query, jwtState, jwtTokens);
        } catch {
            setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
        }

        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setError(error);
            });
            return;
        }

        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);

                if (parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                    const fetchedContact = parsed.data.contact.contact as Contact;
                    if (fetchedContact.contactId === initialCommId) {
                        setSingleContact(fetchedContact);
                    } else {
                        let updatedContactList: Contact[] | null = null;
                        if (contactHistoryList != null) {
                            updatedContactList = [...(contactHistoryList as Contact[])]; // Creating a copy of the current contactList
                            for (let i = 0; i < updatedContactList.length; ++i) {
                                if (updatedContactList[i].contactId === fetchedContact.contactId) {
                                    updatedContactList[i] = fetchedContact;
                                    break;
                                }
                            }
                        }
                        setContactHistoryList(updatedContactList);
                    }
                }
            }
            if (response.callResult.jwtState) {
                setJwtState(response.callResult.jwtState);
                setAvailableRoles(response.callResult.jwtState.getRoles().map((s: string) => ({
                    value: s,
                    label: s
                })));
            }
        } else {
            setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
        }
    }

    async function updateSelectedContacts(contactsToUpdate: string[]): Promise<void> {
        const updatedContactList = [...(contactHistoryList as Contact[])];
        if(singleContact && contactsToUpdate.includes(singleContact.contactId)){
            await updateSingleContact(singleContact.contactId);
        }

        for (let i = 0; i < updatedContactList.length; i++) {
            if (!updatedContactList[i].isSelected || !contactsToUpdate.includes(updatedContactList[i].contactId)) {
                continue;
            }

            try {
                await getContactByContactId(updatedContactList[i].contactId)
                    .then(fetchedContact => {
                        if (fetchedContact) {
                            updatedContactList[i] = fetchedContact;
                        }
                    });
            } catch (error) {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to fetch contact: " + updatedContactList[i] + " " + error);
            }
        }

        setContactHistoryList(updatedContactList);
    }

    async function getContactByContactId(contactId: string, token?: AuthorizerJwtToken): Promise<Contact | undefined> {
        const query = buildSingleContactQuery(contactId);
        const jwtTokens = token ? [...authorizerJwtTokens, token] : authorizerJwtTokens;
        try {
            const response = await callPhoenix(query, jwtState, jwtTokens);

            if (response?.callResult) {
                if (response.callResult.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                        return parsed.data.contact.contact as Contact;
                    }
                }
            } else {
                setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
            }
        } catch {
            setError(i18n("INTERNAL_ERROR_DISPLAY_MESSAGE"));
        }
    }

    function fetch(startKey: string, jwtState: JwtToken | null, view: string): void {
        if (initialCommId) {
            fetchSingleContact(initialCommId, jwtState);
        }

        const cid = view;
        setCustomerId(cid);
        setCustomerTimezone("");
        fetchCustomerTimezone(cid, marketplaceId, jwtState, authorizerJwtTokens).then((timezone) => setCustomerTimezone(timezone));
        if (customerId) {
            fetchContactsByCustomerId(cid, startKey, jwtState, true);
        } else if (emailToken) {
            fetchContactsByCustomerEmail(startKey, jwtState, true);
        }
    }

    const [usecaseSessions, setUsecaseSessions] = useState<UsecaseSession[]> ();
    useEffect(() => {
            async function usecaseSession(): Promise<void> {
                try {
                    const payload = {
                        jwtState: jwtState?.jwtString,
                        contactToken: contactToken
                    };
                    const response = await Phoenix.getInstance().postCall("usecaseSessions", payload);

                    const responseContent = await response.json();

                    const usecaseSessions = responseContent.usecaseSessions as UsecaseSession[];

                    setUsecaseSessions(usecaseSessions);
                }
                catch (e) {
                    // Swallow any error but emit metrics and client side log here.
                    Phoenix.getInstance().addMetric("UsecaseSessions.Error", 1, MetricType.COUNT);
                    Phoenix.getInstance().log(LogLevel.ERROR, "UsecaseSessions.Error: " + e);
                }
            }

            if (!usecaseSessions) {
                // Only do this once...
                usecaseSession();
            }
        },
        [jwtState, usecaseSessions, contactToken]);

    useEffect(() => {
        // On mount, check first whether we have a valid rpToken. Otherwise, we would make a call, get a 401, and only
        // then start the authentication process.
        const rpToken = Phoenix.getInstance().getRpToken();
        if (rpToken) {
            // Initial fetch
            setContactHistoryList(null);
            fetch("", jwtState, customerId);
        } else {
            Phoenix.getInstance().startAuth(() => {
                setError(i18n("AUTHENTICATION_FAILURE"));
            });
        }
        // "React Hook useEffect has a missing dependency: 'fetchContactsByCustomerId'. Either include it or remove the dependency array."
        // ESLint is giving the above warning. However, including it in the array or removing the array would trigger an infinite loop.
        // One option is to duplicate the logic of the function and place it inside useEffect. Since we are only calling this
        // for the initial render, I am choosing to disable the warning instead.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwtState?.getContent().ap]);

    const customerContentIsRestricted = (customerAccessLevelContactHistory !== CustomerAccessLevel.RESTRICTED_PII) ||
        (customerAccessLevelSingleContact !== CustomerAccessLevel.RESTRICTED_PII);

    const changeCustomerId = (id: string, label: string): void => {
        if (id !== customerId) {
            setContactHistoryList(null);
            setStartKey("");
            setCustomerTimezone("");
            fetchCustomerTimezone(id, marketplaceId, jwtState, authorizerJwtTokens).then((timezone) => setCustomerTimezone(timezone));
            setCustomerId(id);
            fetchContactsByCustomerId(id, "", jwtState, true);
        }
    };

    const assumeRole = (id: string, label: string): void => {
        if (activeRole.value !== id) {
            Phoenix.getInstance().assumeRole(jwtState, id)
                .then((newJwtToken: JwtToken) => processAssumeRoleResponse(newJwtToken))
                .catch(error => setError(error.toString()));
        }
    };

    const processAssumeRoleResponse = (jwtState: JwtToken): void => {
        setJwtState(jwtState);
        setSingleContact(null);
        setContactHistoryList(null);
        setStartKey("");
        setActiveRole({value: jwtState.getRole(), label: jwtState.getRole()});
        fetch("", jwtState, customerId);
    };

    const loadMoreContacts = (): void => {
        if (customerId) {
            fetchContactsByCustomerId(customerId, startKey, jwtState, false);
        } else if (emailToken) {
            fetchContactsByCustomerEmail(startKey, jwtState, false);
        }
    };

    const contactDisplayContext: ContactDisplayContext = {
        marketplaceId: marketplaceId,
        userId: jwtState?.content.id,
        customerTimezone: timezone,
        emailToken: emailToken,
        initExpanded: false,
        isDeveloper: availableRoles.some(role => role.value === 'DEVELOPER'),
        purpose: purpose,
    };

    const context = {...contactDisplayContext};
    context.initExpanded = true;

    const contactFinder = (<ContactFinder
        visible={true}
        customerId={customerId}
        marketplaceId={marketplaceId}
        emailToken={emailToken}
        contactDisplayContext={context}
        closeContactFinder={(): void => {setUseContactFinder(false);}}
    />);

    function setUseContactFinder(value: boolean): void {
        if (value === true) {
            Phoenix.getInstance().addMetric("UseContactFinder", 1.0, MetricType.COUNT);
        }
        setUseContactFinderState(value);
    }

    const issueManager = (
        <SpaceBetween size="s">
            {error && <CookieDisabledAlert />}
            {callResponse?.httpStatus === 401 ? <div><Translation stringId="AUTHENTICATING"/></div> : ''}
            <PurposeSelection disableSelectPurpose={contactHistoryLoading} clientStateToken={jwtState || undefined} setClientStateToken={(jwtState): void => {setJwtState(jwtState);}}/>
            <SpaceBetween direction={"vertical"} size={"s"}>
                <ColumnLayout columns={3}>
                    <div>
                        <Box variant="h3" fontSize="heading-xs">
                            <Translation stringId="CUSTOMER"/>
                        </Box>
                        {customerIdSelectionList.length > 1 ?
                            <div>
                                <Copyable
                                    actionTranslationId="COPY_CUSTOMER_ID"
                                    successTranslationId="CUSTOMER_ID_COPIED"
                                    content={
                                        <Box display="inline-block">
                                            <FormField>
                                                <Select options={customerIdSelectionList}
                                                        selectedOption={customerIdSelectionList.find(v => v.value === customerId) || null}
                                                        disabled={contactHistoryLoading}
                                                        onChange={(event): void => {
                                                            if (event.detail.selectedOption.value && event.detail.selectedOption.label) {
                                                                changeCustomerId(event.detail.selectedOption.value, event.detail.selectedOption.label);
                                                            }
                                                        }}
                                                />
                                            </FormField>
                                        </Box>}
                                    valueToCopy={customerId}/>

                            </div>
                            :
                            customerIdSelectionList.length === 1 ?
                                <div>
                                    <Copyable
                                        actionTranslationId="COPY_CUSTOMER_ID"
                                        successTranslationId="CUSTOMER_ID_COPIED"
                                        content={customerId}
                                        valueToCopy={customerId}/>
                                </div>
                                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_VIEW")} />
                        }
                        {customerContentIsRestricted ? <Alert><Translation stringId={'CUSTOMER_RESTRICTED_NO_LOCKED'}/></Alert> : null}
                    </div>
                    {availableRoles.some(role => role.value === 'DEVELOPER') ?
                        <div>

                            <Box variant="h3" fontSize="heading-xs">
                                <Translation stringId="ROLE"/>
                            </Box>
                            <Box display="inline-block">
                                <FormField>
                                    <Select options={availableRoles}
                                            selectedOption={activeRole}
                                            disabled={singleContactLoading || contactHistoryLoading}
                                            onChange={(event): void =>
                                            {
                                                if(event.detail.selectedOption.value && event.detail.selectedOption.label) {
                                                    assumeRole(event.detail.selectedOption.value, event.detail.selectedOption.label);
                                                }
                                            }}/>
                                </FormField>
                                <TokenStatus/>
                                <UsecaseSessions usecaseSessions={usecaseSessions}/>
                                <RunLocal/>
                            </Box>
                        </div>
                        : null
                    }
                </ColumnLayout>
                    <div hidden={singleContact === null && (contactHistoryList === null || contactHistoryList.length === 0)}>
                        <SpamAndDuplicate
                            contactList={contactHistoryList}
                            userId={jwtState?.content.id}
                            singleContact={singleContact}
                        />
                    </div>
                </SpaceBetween>
                {error ?
                    <Alert header="Error" type="error">{JSON.stringify(error)}</Alert>
                    : null
                }
                <div>
                    <SpaceBetween size="s">
                        <FollowUpsForCustomerDisplay
                            customerEmail={emailToken || undefined}
                            customerId={customerId}
                            marketplaceId={marketplaceId}
                        />

                        <InternalSyskaDisplay
                            customerEmail={emailToken || undefined}
                            customerId={customerId}
                            marketplaceId={marketplaceId}
                        />

                        {initialCommId ?
                            <div>
                                <Box variant="h3" fontSize="heading-xs">
                                <span>
                                    <Translation stringId="CURRENT_CONTACT"/>
                                </span>
                                    <TimeZoneInfo isCustomerTimezone={isCustomerTimezone}/>
                                </Box>
                                {singleContactLoading ?
                                    <Box textAlign="center" aria-label={IntlManager.sharedManager.formatMessage("LOADING")}>
                                        <Spinner size="large"/>
                                    </Box>
                                    : singleContact ?
                                        <ContactDisplay key={singleContact.contactId}
                                                        customerId={customerId}
                                                        context={{...contactDisplayContext, initExpanded: true}}
                                                        contact={singleContact}
                                                        customerAccessLevel={customerAccessLevelSingleContact}/>

                                    : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CONTACT")}/>
                                }
                            </div> : null
                        }

                        <div>
                            <Box variant="h3" fontSize="heading-xs">
                                <span>
                                    <Translation stringId="CONTACT_HISTORY"/>
                                </span>
                                <TimeZoneInfo isCustomerTimezone={isCustomerTimezone}/>
                            </Box>
                            <SpaceBetween size="s" direction="vertical">
                                {contactHistoryList?.length ?
                                    <div role="list">
                                        <SpaceBetween size="s" direction="vertical">
                                            {contactHistoryList.map(contact => {
                                                return (
                                                    <div key={contact.contactId} role="listitem">
                                                        <ContactDisplay key={contact.contactId}
                                                            customerId={customerId}
                                                            context={contactDisplayContext}
                                                            contact={contact}
                                                            customerAccessLevel={customerAccessLevelContactHistory}/>
                                                    </div>
                                                );
                                            })}
                                        </SpaceBetween>
                                    </div>
                                    : null
                                }
                                {contactHistoryLoading ?
                                    <Box textAlign="center" aria-label={IntlManager.sharedManager.formatMessage("LOADING")}>
                                        <Spinner size="large"/>
                                    </Box>
                                    :
                                    <TextContent>
                                        <Translation stringId="FETCHED_NUMBER_CONTACTS" params={{"number" : "" + (contactHistoryList != null ? contactHistoryList.length : 0)}}/>
                                    </TextContent>
                                }
                                {(startKey) ?
                                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                                        <Button onClick={(): void => loadMoreContacts()}
                                                disabled={contactHistoryLoading}
                                                ariaLabel={IntlManager.sharedManager.formatMessage("LOAD_MORE_CONTACTS_BUTTON_LABEL")}>
                                            <Translation stringId={"LOAD_MORE"}/>
                                        </Button>
                                        <Button onClick={(): void => setUseContactFinder(true)}
                                                disabled={contactHistoryLoading}
                                                ariaLabel={i18n("USE_CONTACT_FINDER")}>
                                            {i18n('FINDER')}
                                        </Button>
                                    </SpaceBetween>
                                    : null
                                }
                            </SpaceBetween>
                        </div>
                    </SpaceBetween>
                </div>
        </SpaceBetween>
    );

    const issueManagerApp = (<AppLayout
        toolsHide={true}
        navigationHide={true}
        content={issueManager}
    />);

    return (
        <IssueManagerContext.Provider value={issueManagerContext}>
            <audio ref={audioRef}/>
            {useContactFinder ? contactFinder : issueManagerApp}
        </IssueManagerContext.Provider>
    );
};
