export const nl_NL = {
    "ACTION": "Actie",
    "ADD": "Toevoegen",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Aanvullende contactgegevens",
    "ADD_ANNOTATION_FAILURE": "Toevoegen van aantekening is mislukt",
    "ADD_ANNOTATION_INVALID": "Ongeldige aantekening. Zorg ervoor dat er geen afgeschermde gegevens in de aantekening staan.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nieuwe aantekening",
    "ADD_ANNOTATION_SUCCESS": "Aantekening toegevoegd.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Door de agent geselecteerd probleem",
    "AGENT_SELECTED_ISSUE_COPIED": "Door de agent geselecteerd probleem gekopieerd.",
    "AMAZON_CONNECT_CALL_INFO": "Oproepgegevens van Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "Contact-ID van Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Contact-ID van Amazon Connect gekopieerd",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANNOTATED_BY": "Aantekeningen gemaakt door {agentLogin}.",
    "ANNOTATIONS": "Aantekeningen",
    "ANNOTATION_WARNING": "Annotaties kunnen door klanten worden beoordeeld op grond van de privacyvoorschriften. Voeg geen persoonlijke informatie of meningen toe aan het annotatieveld.",
    "ATTACHMENTS_RESTRICTED": "Bijlagen zijn afgeschermd.",
    "ATTACHMENTS_TITLE": "Bijlagen",
    "ATTACHMENT_DELETION_FAILED": "Het verwijderen van bijlagen is mislukt. Probeer het nog eens.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Het verwijderen van bijlagen is geslaagd.",
    "AUTHENTICATING": "Verificatie gestart. Hierdoor wordt mogelijk een nieuw venster geopend.",
    "AUTHENTICATION_FAILURE": "Er is iets misgegaan met de authenticatie.",
    "AUTHENTICATION_MESSAGE": "Nieuwe referenties ophalen. Dit venster wordt over een paar seconden automatisch gesloten.",
    "AUTO_ANNOTATION_BADGE": "Automatisch",
    "BACK": "Terug",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Gebruikte blurbs",
    "CALL_LEG": "Gesprekstraject-ID",
    "CALL_LEG_ID_COPIED": "Gesprekstraject-ID gekopieerd.",
    "CALL_RESTRICTED": "Gesprekscontent is afgeschermd.",
    "CALL_RETENTION_DELETED": "De bewaarperiode van de opname is verstreken en de opname is verwijderd.",
    "CALL_TITLE": "Bellen met Amazon-klantenservice",
    "CANCEL": "Annuleren",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Deze klant heeft een groot aantal contacten en ze zijn niet allemaal geladen. Contacten worden altijd geladen in de volgorde 'nieuw naar oud' vanaf de einddatum. Wijzig de einddatum om vanaf een andere periode te beginnen met laden.",
    "CHANNELS": "Kanalen",
    "CHAT_AGENT_CONNECTED": "{name} heeft de chat geaccepteerd.",
    "CHAT_CONTACT": "Chatcontact.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "De verbinding van {name} met het netwerk is verbroken.",
    "CHAT_DISCONNECT_GENERIC": "{name} is niet langer verbonden.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "De verbinding van {name} met het netwerk is verbroken.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "De verbinding van {name} met het netwerk is verbroken.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} neemt niet meer deel aan het gesprek.",
    "CHAT_PARKED": "Deze chat was gepauzeerd",
    "CHAT_PARKED_NOTES": "Pauze-notities",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} staat niet meer in de wachtrij.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} staat in de wachtrij.",
    "CHAT_RESTRICTED": "Chatcontent is afgeschermd.",
    "CHAT_TITLE": "Chatten met Amazon-klantenservice",
    "CHAT_TRANSFER_NOTES": "Notities overdragen",
    "CHILD_WORK_REQUESTS": "Werkaanvragen die vanuit dit contact zijn gemaakt",
    "CHOOSE_OPTIONS": "Opties kiezen",
    "CLOSE": "Sluiten",
    "CLOSE_ALERT": "Waarschuwing sluiten",
    "CLOSE_MODAL_BUTTON_TEXT": "Sluiten",
    "COLLAPSED": "Samengevouwen",
    "CONFIRM": "Bevestigen",
    "CONTACTS": "Contacten",
    "CONTACT_COLLAPSED": "Contactgegevens zijn samengevouwen.",
    "CONTACT_CREATION_FAILURE": "Aanmaken nieuwe contacten is mislukt",
    "CONTACT_EXPANDED": "Contactgegevens uitgevouwen.",
    "CONTACT_HISTORY": "Contactgeschiedenis",
    "CONTACT_ID": "Contact-ID",
    "CONTACT_ID_COPIED": "Contact-ID gekopieerd.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contact",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalaties",
    "CONTACT_REASSIGNED_TO_AGENT": "Contact is opnieuw toegewezen aan agent.",
    "CONTACT_SELECT_CHECKBOX": "Selectievakje om een vergrendeld contactpersoon te selecteren",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalatie",
    "CONTACT_TICKET": "Ticket voor contact",
    "CONTACT_TICKET_CONTENT": "Content van ticket",
    "CONTACT_TICKET_CREATE": "Ticket voor contact aanmaken",
    "CONTACT_TICKET_CREATION_FAILURE": "Aanmaken van ticket voor contact mislukt",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket voor contact aangemaakt",
    "CONTACT_TICKET_RESOLVER_GROUP": "Groep met oplossers",
    "CONTACT_TICKET_SEVERITY": "Ernst van ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID van SIM-map",
    "CONTACT_TICKET_SUBJECT": "Onderwerp van ticket",
    "CONTACT_TIMELINE": "Tijdlijn van contacten",
    "CONTACT_TYPE": "Type contact",
    "CONTACT_WAS_TRANSFERRED": "Het contact is overgedragen.",
    "CONTENT": "Content",
    "CONTENT_RESTRICTED": "De inhoud is beperkt.",
    "COPY_AGENT_SELECTED_ISSUE": "Door de agent geselecteerd probleem kopiëren: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Contact-ID van Amazon Connect kopiëren",
    "COPY_CALL_LEG_ID": "Gesprekstraject-ID kopiëren: {value}",
    "COPY_CONTACT_ID": "Contact-ID kopiëren: {value}",
    "COPY_CUSTOMER_ID": "Klant-ID kopiëren",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Door de klant geselecteerd probleem kopiëren: {value}",
    "COPY_FAILURE": "Kopiëren is mislukt.",
    "COPY_ID": "ID kopiëren",
    "COPY_LINK": "Kopieer de link voor het uploaden van afbeeldingen",
    "COPY_ORDER_ID": "Bestel-ID kopiëren: {value}.",
    "CREATE_CASE": "Case maken",
    "CREATE_FOLLOW_UP": "Opvolging aanmaken",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Opvolging aangemaakt, maar kon geen bijbehorende annotatie toevoegen. Probeer opnieuw met toevoeging van een handmatige annotatie.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Geef de gewenste contactmethode van de klant op.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Kan geen opvolging aanmaken met een vervaldatum en tijd in het verleden.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Geef de datum en tijd op waarop de opvolgingsactie moet plaatsvinden.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Tijdzone",
    "CREATE_FOLLOW_UP_FAILURE": "Opvolging aanmaken mislukt.",
    "CREATE_FOLLOW_UP_INVALID": "Ongeldige opvolging. Controleer dat er geen beperkte gegevens zijn.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Voer de opvolgingsactie in.",
    "CREATE_FOLLOW_UP_SUCCESS": "Opvolging aangemaakt.",
    "CREATE_WORK_REQUEST": "Aanvraag voor werk aanmaken",
    "CROSS_MARKETPLACE_QUEUES": "Wachtrijen in andere marketplace",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Kan het contact niet overdragen omdat het al is opgelost.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "De bewerking is mislukt omdat het contact ongeldig is.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Bewerking mislukt omdat de contactpersoon niet is gevonden",
    "CSALT_ERROR_INTERNAL_ERROR": "Kan contact niet vergrendelen",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Kan een contact niet vergrendelen vanwege ongeldige argumenten",
    "CSALT_ERROR_INVALID_INPUT": "De bewerking is mislukt vanwege ongeldige invoer.",
    "CSALT_ERROR_INVALID_QUEUE": "Kan het contact niet overdragen omdat de geselecteerde wachtrij ongeldig is.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Bewerking mislukt omdat contactpersoon aan een andere medewerker is gekoppeld.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Kan contact niet vergrendelen vanwege afhankelijkheidsfout",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Kan het contact niet overdragen omdat de wachtrij niet is gevonden in de back-endservice.",
    "CSALT_ERROR_UNKNOWN": "De bewerking is mislukt vanwege een onverwachte fout.",
    "CURRENT_CONTACT": "Huidig contact",
    "CUSTOMER": "Klant",
    "CUSTOMER_FOLLOWUPS": "Opvolgingen voor klant",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Kan opvolgingen voor klant niet ophalen",
    "CUSTOMER_ID_COPIED": "Klant-ID gekopieerd",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Content van klanten is afgeschermd. (Geen contacten vergrendeld.)",
    "CUSTOMER_SELECTED_ISSUE": "Door klant geselecteerd probleem",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Het door de klant geselecteerde probleem is gekopieerd.",
    "CUSTOMER_TIMEZONE": "Tijdstempels weergegeven in de tijdzone van klant",
    "DATE": "Datum",
    "DATE_PICKER_NEXT_MONTH": "Volgende maand",
    "DATE_PICKER_PREVIOUS_MONTH": "Vorige maand",
    "DATE_PICKER_TODAY": "Vandaag",
    "DECODING_AUDIO": "Audio decoderen",
    "DELETE_ATTACHMENTS": "Contactbijlagen verwijderen",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Bijlagen verwijderen",
    "DELIVERY_STATUS": "Bezorgstatus",
    "DISABLE_CONTENT_FILTER": "Filter voor content uitschakelen",
    "DOWNLOAD": "Downloaden",
    "DURATION": "Duur",
    "EMAIL": "E-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "E-mailadres(sen) van de ontvanger niet toegestaan.",
    "EMAIL_BODY": "E-mailtekst",
    "EMAIL_CONTACT": "E-mailcontact.",
    "EMAIL_HEADER": "Kop",
    "EMAIL_QUEUE_TITLE": "E-mailwachtrij",
    "EMAIL_RESTRICTED": "E-mailcontent is afgeschermd.",
    "EMAIL_SENDING_FAILURE": "Het versturen van uitgaande e-mail is mislukt.",
    "EMAIL_SUBJECT": "E-mailonderwerp",
    "EMAIL_TITLE": "E-mailen met Amazon-klantenservice",
    "END": "Einde",
    "END_DATE": "Einddatum",
    "ERROR": "Fout",
    "ERROR_FAILED_TO_LOAD_DATA": "Kan gegevens niet laden.",
    "ESCALATION_CONTACT_HANDLING": "Contact met escalatie afhandelen",
    "ESCALATION_TAB_LEAD_LOGIN": "Inloggen door leiding: {agentLogin}",
    "EXPANDED": "Uitgebreid",
    "FAILED_TO_CREATE_WORK_REQUEST": "Aanmaken van aanvraag voor werk is mislukt",
    "FAILED_TO_FETCH_SYSKA": "SYSKA-waarschuwingen kunnen niet worden opgehaald",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Kon contactpersoon niet opnieuw toewijzen aan agent.",
    "FETCHED_NUMBER_CONTACTS": "{number} contacten opgehaald.",
    "FILTER": "Filter",
    "FINDER": "Zoeken",
    "FOLLOW_UPS_COLLAPSED": "Opvolgingen samengevouwen",
    "FOLLOW_UPS_EXPANDED": "Opvolgingen uitgevouwen",
    "FOLLOW_UP_ALERT": "heeft een opvolging op {dueDate} die is gemaakt door {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Contactmethode",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Contactmethode is afgeschermd.",
    "FOLLOW_UP_DUE": "Deadline opvolging",
    "FOLLOW_UP_DUE_DATE": "Vervaldatum",
    "FOLLOW_UP_NOTE": "Notitie",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Geen contactmethode",
    "FOLLOW_UP_NO_NOTE": "Notitie is leeg",
    "FOLLOW_UP_NO_TICKET": "Geen ticket",
    "FOLLOW_UP_OWNER": "Eigenaar",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_OPEN": "Openstaand",
    "FOLLOW_UP_STATUS_RESOLVED": "Opgelost",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Opvolgingen",
    "FROM": "Van",
    "GENERATE_LINK": "Link genereren",
    "HAS_OVERRIDE_TRY_AGAIN": "Overschrijving van filter aanwezig. Probeer het nog eens.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "link voor afbeeldingenbijlage gedeeld met klant",
    "INPUT_CHARACTERS_REMAINING": "Maximum {char_max} tekens ({char_remain} resterend)",
    "INTERNAL_ERROR": "Interne fout",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Er is een interne fout opgetreden. Als de fout zich blijft voordoen, maak dan een ticket aan waarin je het probleem uitlegt.",
    "INVALID_CONTACT": "Ongeldig contact.",
    "INVALID_TIMESTAMP": "Ongeldige tijdstempel.",
    "IN_MARKETPLACE_QUEUES": "Wachtrijen in marketplace",
    "ISSUE": "Probleem",
    "ISSUE_CODE": "Probleemcode",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Al besproken en opgelost tijdens eerder contact",
    "ISSUE_CODE_DESCRIPTION": "Selecteer de reden om de geselecteerde contacten als duplicaat te maken",
    "ISSUE_CODE_EXACTLY_SAME": "Precies hetzelfde als een eerder contact",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Per chat Verwerkt en opgelost door een andere CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Per e-mail Verwerkt en opgelost door een andere CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Per telefoon verwerkt en opgelost door een andere CSA",
    "ITEMS_CONTACTED_ABOUT": "Contact opgenomen over item(s)",
    "I_UNDERSTAND": "Ik begrijp het",
    "KNOWN_ISSUES": "Bekende problemen",
    "LEARN_MORE": "Meer informatie",
    "LINK_COPIED": "Link gekopieerd",
    "LOADING": "Gegevens laden.",
    "LOADING_PREVIEW_TEXT": "Voorbeeldweergave laden",
    "LOADING_RECORDING": "Opname wordt geladen",
    "LOAD_MORE": "Meer laden",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Meer aantekeningen laden",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Meer contacten laden voor deze klant.",
    "LOCKED": "Vergrendeld",
    "LOCKED_BY": "Vergrendeld door",
    "LOCK_CONTACT": "Vergrendelen",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Kan het contact niet vergrendelen, de gebruiker is niet geautoriseerd",
    "LOCK_CONTACT_SUCCESSFUL": "Contact vergrendeld om",
    "MISSING_ORIGINAL_CONTACT_ID": "Voer een geldig origineel contact-ID in",
    "MODAL_NO_BUTTON_TEXT": "Nee",
    "MODAL_YES_BUTTON_TEXT": "Ja",
    "NEXT": "Volgende",
    "NORMAL": "Normaal",
    "NO_AGENT_SELECTED_ISSUE": "Geen door de agent geselecteerd probleem.",
    "NO_ANNOTATED_BY": "Aantekeningen gemaakt door onbekende gebruiker.",
    "NO_ANNOTATIONS": "Geen aantekeningen beschikbaar.",
    "NO_ATTACHMENTS": "Er zijn geen bijlagen.",
    "NO_CHAT": "Chat is leeg.",
    "NO_CHILD_WORK_REQUESTS": "Er zijn geen werkaanvragen vanuit dit contact gemaakt",
    "NO_CONTACT": "Geen contact beschikbaar",
    "NO_CONTACTS_SELECTED": "Selecteer contacten om ze als spam te verwerken",
    "NO_CONTENT": "Geen content",
    "NO_CUSTOMER_SELECTED_ISSUE": "Door de klant geselecteerd probleem.",
    "NO_DELIVERY_STATUS": "Geen bezorgstatus",
    "NO_EMAIL_BODY": "E-mailadres is leeg.",
    "NO_EMAIL_HEADER": "Geen e-mailkop.",
    "NO_EMAIL_QUEUE": "Er is geen e-mailwachtrij gekoppeld aan dit contact.",
    "NO_ITEMS_CONTACTED_ABOUT": "Geen contact opgenomen over items.",
    "NO_RESULTS": "Geen resultaten gevonden.",
    "NO_SCREEN_POP": "Geen ScreenPop-informatie",
    "NO_SENT_FROM": "Geen adres voor verstuurd vanaf",
    "NO_SENT_TO": "Geen adres voor verstuurd naar",
    "NO_SUBJECT": "Onderwerpregel is leeg.",
    "NO_SYSKA_ALERTS": "Geen SYSKA-waarschuwingen",
    "NO_USED_BLURBS": "Geen blurbs gebruikt voor dit contact.",
    "NO_VIEW": "Geen contact of klant om weer te geven.",
    "NO_WORK_CATEGORY": "Er is geen werkcategorie gekoppeld aan dit contact.",
    "NUMBER_OF_CONTACTS": "Geladen contacten",
    "OLDEST_LOADED_CONTACT": "Oudste geladen contact",
    "OPEN": "Openen",
    "OPENS_IN_A_NEW_TAB": "Opent in een nieuw tabblad",
    "ORDER_ID_COPIED": "Bestel-ID gekopieerd.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Originele contact-ID invoeren",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Tekstvak voor invoeren van originele contact-ID",
    "OUTBOUND_EMAIL_BCC": "en bcc-en -",
    "OUTBOUND_EMAIL_CC": "bij het cc-en -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Content van meldingen is samengevouwen.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Content van meldingen is opengevouwen.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Maximum aantal resultaten bereikt. Verkort het datumbereik en probeer het opnieuw.",
    "OVERRIDE_REASON_PROMPT": "Aantekeningen mogen geen persoonsgegevens van de klant bevatten, zoals telefoonnummers en e-mailadressen. Ons geautomatiseerde systeem probeert dat af te dwingen, maar ziet soms niet het verschil tussen persoonsgegevens en andere ID's. Geef op waarom een overschrijving nodig is.",
    "PAPYRUS_SESSION_EXPLANATION": "Geef je Papyrus sessietype op, zodat we de juiste beveiligingsbeperkingen kunnen toepassen en de UX kunnen optimaliseren voor jouw behoeften.",
    "PAPYRUS_SESSION_TYPE": "Papyrus sessietype",
    "PAUSE": "Pauzeren",
    "PHONE_CONTACT": "Telefonisch contact.",
    "PLAY": "Afspelen",
    "PLAYBACK_SPEED": "Afspeelsnelheid",
    "POSTAL_MAIL_TITLE": "Post met Amazon-klantenservice",
    "PREPARING_RECORDING": "Opname wordt voorbereid",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Kan geen voorbeeldweergave voor contact genereren",
    "PREVIOUS": "Vorige",
    "PRE_TRANSLATED_REPLY_EMAIL": "Voorvertaalde antwoordmail",
    "PRE_TRANSLATION_REPLY_TEXT": "Antwoord getypt door CSA voorafgaand aan Rosetta-vertaling",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Acties voor verwerken",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Verwerken als duplicaat",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Verwerken als spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Geselecteerde contacten als duplicaat oplossen",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Weet je zeker dat je de geselecteerde contacten als spam wilt verwerken?",
    "PROCESS_DUPLICATE_FAILURE": "Kan geselecteerde contacten niet als duplicaat oplossen",
    "PROCESS_DUPLICATE_SUCCESS": "Geselecteerde contacten als duplicaat opgelost",
    "PROCESS_SPAM_FAILURE": "Kan geselecteerde contacten niet naar spam verplaatsen",
    "PROCESS_SPAM_SUCCESS": "Geselecteerde contacten verplaatst naar spam",
    "PROVIDE_ADDITIONAL_INFORMATION": "Verstrek aanvullende informatie die relevant is voor dit contact",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Gevoelige informatie van contact opschonen:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Woord toevoegen aan lijst voor opschonen",
    "PURGE_BUTTON_TEXT": "Opschonen",
    "PURGE_CONFIRMATION_CHECK": "Ik heb de bovenstaande velden geverifieerd en bevestig dat de tekst inderdaad gevoelige informatie bevat.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Ik bevestig dat de bijlage inderdaad klantgevoelige informatie bevat.",
    "PURGE_CONFIRMATION_UNCHECKED": "Bevestig het selectievakje.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Kan contact niet opschonen",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Informatie van contact opgeschoond",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} is geen geldige reden voor opschonen",
    "PURGE_REASON_FINANCIAL_INFO": "Financiële gegevens",
    "PURGE_REASON_OTHER": "Andere gevoelige informatie",
    "PURGE_REASON_PII": "Persoonlijk identificeerbare informatie",
    "PURGE_REASON_PLACEHOLDER": "Geef de reden voor het opschonen op",
    "PURGE_REASON_TEXT": "Reden voor opschonen",
    "PURGE_REMOVE_LABEL": "Verwijderen",
    "PURGE_SELECTOR_PLACEHOLDER": "Selecteer reden voor het opschonen van gevoelige informatie",
    "PURGE_SENSITIVE_INFO": "Gevoelige informatie opschonen",
    "PURGE_WORD_NOT_FOUND": "Woord niet gevonden in contacttekst",
    "PURPOSE_AUDITING": "Kwaliteitscontrole",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Backoffice-workflow",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Dit is de sessie voor het verkrijgen van contactgegevens voor backoffice-workflows. Bijvoorbeeld bijlagen die moeten worden opgenomen in tickets of SIM.",
    "PURPOSE_COACHING": "Coaching contact controleren",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Nalevingsonderzoek",
    "PURPOSE_CURATION": "Contact curatie",
    "PURPOSE_CURATION_DESCRIPTION": "Dit is de sessie voor C2CS/VOC contact curatie.",
    "PURPOSE_DESCRIPTION_AUDITING": "Dit is de sessie voor het controleren van contacten en het evalueren van hun kwaliteit.",
    "PURPOSE_DESCRIPTION_COACHING": "Dit is de sessie voor het controleren van contacten van agenten waarvan je een Performance Portal-coach bent.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Dit is de onderzoekssessie voor de bewaking van verdachte activiteiten.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Dit is de sessie voor het afhandelen van contact met escalatie. Dit maakt het mogelijk om toegang te krijgen tot de contactgegevens van de klant.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Dit is de sessie voor live contact afhandeling.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Het doel is om gevoelige informatie uit een contact te verwijderen",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Dit is de sessie voor het controleren van de nauwkeurigheid van het machine learning-model.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Dit is de sessie voor het bekijken van je eigen eerdere contacten.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Dit is de standaard sessie voor algemeen gebruik.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Contact met escalatie afhandelen",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Gevoelige informatie opschonen",
    "REASON_FOR_PURGE": "Reden voor opschonen",
    "REASON_FOR_PURGE_DESCRIPTION": "Reden voor het verwijderen van bijlagen",
    "REASON_FOR_PURGE_MISSING": "Geef de reden op voor het verwijderen van bijlagen.",
    "REASSIGN_TO_AGENT": "Opnieuw toewijzen aan de agent",
    "RECORDING": "Opname",
    "RELOAD_LIST": "Lijst opnieuw laden",
    "REPLY_BY": "Uiterste antwoorddatum",
    "REPLY_BY_EMAIL": "Antwoorden per e-mail",
    "REPLY_BY_PHONE": "Antwoorden per telefoon",
    "REPLY_RESTRICTED": "Content van antwoorden is afgeschermd.",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Toegang tot gefilterde content aanvragen",
    "RESEND": "Opnieuw versturen",
    "RESEND_FAILED": "E-mail opnieuw verzenden is mislukt.",
    "RESEND_SUCCESS": "E-mail is opnieuw verzonden.",
    "RESEND_WAIT_WARNING": "Je moet {wait_mins} minuten wachten om opnieuw te verzenden.",
    "RESEND_WARNING": "Weet je zeker dat je de e-mail opnieuw naar de klant wilt sturen?",
    "RESET": "Resetten",
    "RESOLVED": "Opgelost",
    "RESOLVED_BY": "Opgelost door",
    "RESTRICTED_DATA_MATCH": "{match} overeenkomend type {type}",
    "RESTRICTED_INFORMATION": "VERTROUWELIJKE INFORMATIE",
    "RETRY": "Opnieuw proberen",
    "ROLE": "Rol",
    "SCREEN_POP_DESCRIPTION_TITLE": "Beschrijving",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Nee",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Ja",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Weergegeven aan agent",
    "SCREEN_POP_TITLE": "ScreenPop-informatie",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Screenpop voor verificatie is weergegeven aan de agent en gebruikt.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Screenpop voor verificatie is weergegeven aan de agent.",
    "SCREEN_POP_TYPE_CALLER_ID": "Screenpop voor nummerherkenning is weergegeven aan de agent en gebruikt.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Screenpop voor nummerherkenning is weergegeven aan de agent.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Niet-geverifieerde screenpop is weergegeven en gebruikt.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Niet-geverifieerde screenpop is weergegeven aan de agent.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Klant is onbekend, geen screenpop weergegeven.",
    "SCREEN_POP_TYPE_OTHER": "Andere",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Nee",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Ja",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Gebruikt door agent",
    "SDS_TOOLS": "Hulpmiddel(en) voor ondersteuning bij verzending en bezorging",
    "SEARCH": "Zoeken",
    "SELECTED_ROLE": "Geselecteerde rol",
    "SELECTED_VIEW": "Geselecteerde weergave",
    "SELECT_CHANNELS": "Kanalen selecteren",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Probleemcode selecteren om geselecteerde contacten als duplicaat te markeren",
    "SELECT_TRANSFER_DESTINATION": "Selecteer een bestemming voor de overdracht",
    "SELECT_WORK_REQUEST_QUEUE": "Selecteer wachtrij voor aanvraag voor werk",
    "SENDER_EMAIL_ADDRESS": "Afzender",
    "SENDING_EMAIL": "E-mail wordt verstuurd",
    "SEND_EMAIL": "E-mail versturen",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Weet je zeker dat je de link voor het uploaden van afbeeldingen naar de klant wilt sturen?",
    "SEND_OUTBOUND_EMAIL": "Uitgaande e-mail versturen",
    "SEND_OUTBOUND_EMAIL_WARNING": "Weet je zeker dat je uitgaande e-mail wilt versturen aan",
    "SENT_FROM": "Verstuurd vanaf",
    "SENT_OUTBOUND_EMAIL": "De uitgaande e-mail is verstuurd. Er is een nieuw contact aangemaakt",
    "SENT_TO": "Verstuurd naar",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal transporteur",
    "SKILL": "Vaardigheid",
    "SKIP_SILENCES": "Stiltes overslaan",
    "SMS": "SMS",
    "SMS_LINK": "Link in sms",
    "SOURCE_TEXT": "Brontekst",
    "SP_BOTTOM": "Onderkant",
    "SP_POSITION": "Positie van gesplitst paneel",
    "SP_PREFERENCES": "Voorkeuren voor gesplitst paneel",
    "SP_RESIZE": "Formaat van gesplitst paneel wijzigen",
    "SP_SIDE": "Zijkant",
    "START": "Starten",
    "STATE_TRANSITIONS": "Overgangen tussen staten",
    "STATUS": "Status",
    "SUBJECT": "Onderwerp",
    "SYSKA_ALERTS": "SYSKA-waarschuwing",
    "TEXT_TO_PURGE": "Tekst om op te schonen",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Cookies van derden moeten zijn ingeschakeld.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "PII-gegevens van klanten zoals telefoonnummers en e-mailadressen zijn niet toegestaan bij het aanmaken van een ticket. Ons geautomatiseerde systeem probeert dat af te dwingen, maar ziet soms niet het verschil tussen persoonsgegevens en andere ID's. Geef op waarom een overschrijving nodig is.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Time-out: opname laden is mislukt",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Gebruik 24-uursindeling.",
    "TO": "Naar",
    "TO_EMAIL_ADDRESS": "Aan",
    "TRANSFER": "Overdragen",
    "TRANSFERRED_CHAT_TITLE": "Chat overgedragen aan Amazon-klantenservice",
    "TRANSFERRING": "Overdragen...",
    "TRANSFER_CONTACT": "Contact overdragen",
    "TRANSFER_CONTACT_COMMENTS": "Optionele annotatie",
    "TRANSFER_FAILED": "Overdracht mislukt.",
    "TRANSFER_FOLLOW_UP": "Opvolging overdragen",
    "TRANSFER_FOLLOW_UP_FAILED": "Opvolging overdragen mislukt.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Opvolging overdragen onmogelijk door ongeldige gebruikersnaam van nieuwe eigenaar.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Je hebt geen toestemming om deze opvolging over te dragen.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Voer de gebruikersnaam van de nieuwe eigenaar in. Voorbeeld: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Draag de bovenstaande opvolging over aan:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Opvolging overgedragen.",
    "TRANSLATED_TEXT": "Vertaalde tekst",
    "TRANSLATION": "Vertaling",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} is geen geldige wachtrijnaam.",
    "TYPE_AGENT_LOGIN": "Type agent-login",
    "TYPE_EMAIL_ADDRESS": "Typ het e-mailadres",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Typ meerdere e-mailadressen gescheiden door een komma",
    "TYPE_QUEUE_NAME": "Typ de wachtrijnaam",
    "UNKNOWN_CONTACT_TYPE": "Onbekend type contact.",
    "UNLOCKED": "Ontgrendeld",
    "UNRESOLVED": "Onopgelost",
    "URGENT_SYSKA_ALERTS": "Dringende SYSKA-waarschuwingen",
    "USE": "Gebruiken",
    "USER_TIMEZONE": "Tijdstempels weergegeven in je lokale tijdzone",
    "USE_CONTACT_FINDER": "Contactzoeker gebruiken",
    "VIEW": "Weergeven",
    "WARNING": "Waarschuwing",
    "WELCOME_MESSAGE": "Welkom op de statische Papyrus-website!",
    "WHAT_IS_PAPYRUS": "Wat is Papyrus?",
    "WITH_THE_ANNOTATION": "met de annotatie",
    "WORK_CATEGORY_TITLE": "Werkcategorie",
    "WORK_REQUEST": "Aanvraag voor werk",
    "WORK_REQUEST_CONTENT": "Content van aanvraag voor werk",
    "WORK_REQUEST_CREATED": "Aanvraag voor werk aangemaakt",
    "WORK_REQUEST_FOR_CONTACT": "Werkaanvraag voor contact",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "PII-gegevens van klanten zoals telefoonnummers en e-mailadressen zijn niet toegestaan tijdens het maken van een werkaanvraag. Ons geautomatiseerde systeem probeert dat af te dwingen, maar ziet soms niet het verschil tussen persoonsgegevens en andere ID's. Geef op waarom een overschrijving nodig is.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Je staat op het punt om het contact over te dragen aan de wachtrij"
}