export const de_DE = {
    "contact_event_GC_added": "Geschenkgutschein #{gcId} zum Konto hinzugefügt.",
    "contact_event_GC_added_batched": "Geschenkgutschein zu Konto hinzugefügt  ({count}).",
    "contact_event_GC_added_purchaser": "Geschenkgutschein #{gcId} zu Käuferkonto hinzugefügt.",
    "contact_event_GC_added_purchaser_batched": "Geschenkgutschein zum Käufer-Konto hinzugefügt ({count}).",
    "contact_event_GC_added_recipient": "Geschenkgutschein #{gcId} zum Empfängerkonto hinzugefügt.",
    "contact_event_GC_added_recipient_batched": "Geschenkgutschein zum Empfängerkonto  hinzugefügt ({count}).",
    "contact_event_GC_added_scratchedCode": "GC #{gcId} über Tool für zerkratzten Einlösungscode zum Konto hinzugefügt.",
    "contact_event_GC_added_scratchedCode_batched": "GC über Tool für zerkratzten Einlösungscode zum Konto hinzugefügt ({gcId}).",
    "contact_event_GC_cancelled": "Geschenkgutschein #{gcId} abgebrochen.",
    "contact_event_GC_cancelled_batched": "Geschenkgutschein entsperrt ({count}).",
    "contact_event_GC_changed_expiry": "Ablaufdatum des Geschenkgutscheins  #{gcId} wurde geändert.",
    "contact_event_GC_changed_expiry_batched": "Ablaufdatum des Geschenkgutscheins  {gcId} wurde geändert.",
    "contact_event_GC_locked": "Geschenkgutschein #{gcId} gesperrt.",
    "contact_event_GC_locked_batched": "Geschenkgutschein gesperrt ({count}).",
    "contact_event_GC_removed": "Geschenkgutschein #{gcId} vom Konto entfernt.",
    "contact_event_GC_removed_batched": "Geschenkgutschein vom Konto entfernt ({count}).",
    "contact_event_GC_resent_email": "Geschenkgutschein #{gcId} erneut an E-Mail-Adresse {deliveryEmail} gesendet.",
    "contact_event_GC_resent_email_batched": "Geschenkgutschein ${} erneut an E-Mail-Adresse {deliveryEmail} gesendet.",
    "contact_event_GC_resent_post": "Geschenkgutschein #{gcId} per Post zurückgesendet.",
    "contact_event_GC_resent_post_batched": "Geschenkgutschein erneut per Post gesendet ({count}).",
    "contact_event_GC_temporary_locked": "Geschenkgutschein #{gcId} vorübergehend gesperrt.",
    "contact_event_GC_temporary_locked_batched": "Geschenkgutschein vorübergehend gesperrt ({count}).",
    "contact_event_GC_transferred": "Geschenkgutschein {gcId} von Kunde {customerID} zu {toAccountCustomerID} übertragen.",
    "contact_event_GC_transferred_batched": "Geschenkgutschein entsperrt ({count}).",
    "contact_event_GC_unlocked": "Geschenkgutschein #{gcId} entsperrt.",
    "contact_event_GC_unlocked_batched": "Geschenkgutschein entsperrt ({count}).",
    "contact_event_PV_added": "PV #{pvId} zum Konto hinzugefügt.",
    "contact_event_PV_locked": "PV #{pvId} gesperrt.",
    "contact_event_PV_temporary_locked": "PV #{pvId} vorübergehend gesperrt.",
    "contact_event_PV_unlocked": "PV #{pvId} entsperrt.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "A-bis-Z-Antrag für Bestellungen storniert ({count}).",
    "contact_event_a_to_z_guarantee_requested": "A-bis-Z-Antrag für Bestellung {order_id} eingereicht.",
    "contact_event_a_to_z_guarantee_requested_batched": "A-bis-Z-Antrag für Bestellungen eingesendet ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "A-bis-Z-Garantieantrag für Bestellung {order_id} eingereicht.",
    "contact_event_access_code_added": "Zugangscode hinzugefügt",
    "contact_event_access_code_added_batched": "Zugangscode hinzugefügt ({count})",
    "contact_event_account_closed": "Konto geschlossen.",
    "contact_event_account_closed_batched": "Konten geschlossen ({count}).",
    "contact_event_account_reopened": "Konto wieder geöffnet",
    "contact_event_account_reopened_batched": "Wieder geöffnete Konten ({count})",
    "contact_event_account_temporarily_disabled": "Konto vorübergehend deaktiviert",
    "contact_event_account_temporarily_disabled_batched": "Vorübergehend deaktivierte Konten ({count})",
    "contact_event_account_whitelisted": "Konto auf weiße Liste gesetzt.",
    "contact_event_account_whitelisted_batched": "Konto auf weiße Liste gesetzt ({count}).",
    "contact_event_address_changed": "{address_type} Adresse für Bestellung {order_id} aktualisiert.",
    "contact_event_address_changed_batched": "Adressen für Bestellungen aktualisiert({count}).",
    "contact_event_address_deactivated": "Adresse deaktiviert.",
    "contact_event_address_deactivated_batched": "Adresse deaktiviert ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video-Abonnement automatisch verlängert",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video-Abonnement storniert",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Eingereichtes Formular: DSL-Eskalationsformular\nAnwendungsfall Sitzungs-ID: {usecaseSessionID}\nZustellstation: {deliveryStationField}\nAnforderungstyp: {requestTypeField}\nKontakt-ID: {contactIdField}\nBestellnummer: {orderIdField}\nE-Mail-Adresse des Kunden: {customerEmailField}\nTelefonnummer des Kunden: {customerPhoneNumberField}\nTBA(s): {tbasField}\nZugangscode (falls zutreffend): {accessCodeField}\nZusammenfassung übermitteln: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Übermittelte DSL-Eskalationsformulare: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Eingereichtes Formular: DSP DA Feedback-Formular\nAnwendungsfall Sitzungs-ID: {usecaseSessionID}\nCode der Zustellstation: {deliveryStationField}\nKontakt-ID: {contactIdField}\nKundenkonto-ID: {customerIdField}\nDA-Transporter-ID: {driverIdField}\nDatum des Vorfalls (TT-MM-JJJJ): {dateOfOccurrenceField}\nUhrzeit des Vorfalls (HH:MM): {timeofOccurrenceField}\nTBAs: {relatedTrackingIdsField}\nFeedback-Quelle: {sourceOfFeedbackField}\nFeedbackdetails: {issueField}\nWeitere Details: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Eingereichte DSP-DA-Feedback-Formulare: ({count})",
    "contact_event_andon_cord_pulled": "Reißleine für {asin} gezogen.",
    "contact_event_andon_cord_pulled_batched": "Reißleine für Artikel gezogen ({count}).",
    "contact_event_apply_adrive_music_trial": "Cloud Player Premium für Kunden {customer_id} angewandt. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Konto migriert nach {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Audible-Konten migriert ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "Für {title} wurden {cashAmount} erstattet. Außerdem wurde dem Konto für diese Transaktion ein Rabattgutschein über {couponAmount} gutgeschrieben.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "Für {title} wurden {cashAmount} erstattet. Außerdem wurde dem Konto für diese Transaktion ein Rabattgutschein über {couponAmount} gutgeschrieben.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "Für {title} wurden {cashAmount} erstattet.{counter}",
    "contact_event_audible_cash_only_refund_batched": "Für {title} wurden {cashAmount} erstattet.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} wurde erstattet und dem Konto wurde ein Rabattgutschein über {couponAmount} gutgeschrieben.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} wurde erstattet und dem Konto wurde ein Rabattgutschein über {couponAmount} gutgeschrieben.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} Gutschrift(en) wurde(n) aus dem Konto gelöscht.",
    "contact_event_audible_credit_issued": "{numCredit} Gutschrift(en) wurden dem Konto hinzugefügt.",
    "contact_event_audible_credit_issued_batched": "Konto ({count}) wurden Gutschriften hinzugefügt.",
    "contact_event_audible_credit_refund": "{title} wurde erstattet und auf dem Konto wurde eine Gutschrift über {couponAmount} verbucht.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} wurde erstattet und auf dem Konto wurde eine Gutschrift über {couponAmount} verbucht.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} Gutschrift(en) wurde(n) auf dem Konto wiederhergestellt.",
    "contact_event_audible_credit_restored_batched": "{amount} Gutschrift(en) wurde(n) auf dem Konto wiederhergestellt. ({count})",
    "contact_event_audible_extra_credit_purchased": "Es wurden 3 zusätzliche Gutschriften gekauft.",
    "contact_event_audible_extra_credit_purchased_batched": "Es wurden 3 zusätzliche Gutschriften gekauft.({count})",
    "contact_event_audible_hiatus_cancelled": "Die Unterbrechung wurde vorzeitig beendet und die Mitgliedschaft wieder aufgenommen.",
    "contact_event_audible_hiatus_cancelled_batched": "Die Unterbrechung wurde vorzeitig beendet und die Mitgliedschaft wieder aufgenommen. ({count})",
    "contact_event_audible_hiatus_started": "Die Mitgliedschaft wurde für {duration} Monate pausiert.",
    "contact_event_audible_hiatus_started_batched": "Die Mitgliedschaft wurde für {duration} Monate pausiert. ({count})",
    "contact_event_audible_membership_enrollment": "Registriert im  \n{membershipPlan}-Mitgliedschaftsplan.",
    "contact_event_audible_membership_enrollment_batched": "Registriert in den Audible-Mitgliedschaftsplänen ({count}).",
    "contact_event_audible_membership_plan_refund": "Die Mitgliedsgebühr in Höhe von {cashAmount} wurde erstattet.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Die Mitgliedsgebühr in Höhe von {cashAmount} wurde erstattet.{counter} ({count})",
    "contact_event_audible_membership_switched": "Geändert vom {oldMembershipPlan} zum {newMembershipPlan}-Mitgliedschaftsplan.",
    "contact_event_audible_membership_switched_batched": "Geänderte Audible-Mitgliedschaftspläne ({count}).",
    "contact_event_audible_romance_package_enrollment": "Registriert im {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Registriert im {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "Auf {wizardName} wurde zugegriffen.",
    "contact_event_audible_wizard_invoked_batched": "Auf {wizardName} wurde zugegriffen.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "Das {membership} abonnement ist erfogreich gekündigt worden.",
    "contact_event_blacklist_kindle": "Kindle {serial_number} auf schwarze Liste gesetzt.",
    "contact_event_blacklist_kindle_batched": "Kindles auf schwarze Liste gesetzt ({count}).",
    "contact_event_bound_warranty": "Garantie-ID {warranty_id} verknüpft mit Gerät {dsn}.",
    "contact_event_bound_warranty_batched": "Garantien mit Geräten verbunden ({count}).",
    "contact_event_call_was_flagged": "Dieser Kontakt wurde als {callFlagSubject}: {callFlagDescription} markiert",
    "contact_event_call_was_flagged_batch": "Dieser Kontakt wurde als {callFlagSubject}: {callFlagDescription} ({count}) markiert",
    "contact_event_cancel_audible_membership": "Das {membership} abonnement ist erfogreich gekündigt worden.",
    "contact_event_cancel_audible_membership_batched": "Das {membership} abonnement ist erfogreich gekündigt worden.({count})",
    "contact_event_cancel_audible_romance_package_batched": "Der {membership}-Plan wurde erfolgreich storniert. ({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Erfolgreiche Stornierung von Prime von PrimeCancelException. Stornierungsgrund: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Ausnahme für Prime stornieren ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "Die zuvor eingerichtige Kündigung wurde storniert.",
    "contact_event_cancelled_sns_reactivated": "ASIN {asin}für Abonnement reaktiviert am {reactivation_date} (Monat) von {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Gekündigte Abonnieren-und-Sparen-Abonnements reaktiviert ({count}).",
    "contact_event_captcha_bypass_key_generated": "Schlüssel zum Umgehen von Captcha erstellt.",
    "contact_event_captcha_bypass_key_generated_batched": "Schlüssel zum Umgehen von Captcha erstellt ({count}).",
    "contact_event_change_sns_quantity": "ASIN {asin} für Abonnieren und Sparen - alte Menge {current_quantity} auf neue Menge {new_quantity} einstellen.",
    "contact_event_change_sns_quantity_batched": "Abonnieren-und-Sparen-Mengen aktualisiert ({count}).",
    "contact_event_channels_auto_renew_off": "CS hat die automatische Verlängerung für das PV Channels Abonnement {subscriptionid} deaktiviert.",
    "contact_event_channels_auto_renew_off_static": "CS hat die automatische Verlängerung über Astro deaktiviert.",
    "contact_event_channels_full_refund": "CS hat das PV Channels Abonnement {subscriptionid} mit voller Erstattung storniert.",
    "contact_event_channels_full_refund_static": "CS hat das Abonnement mit voller Erstattung über Astro gekündigt.",
    "contact_event_channels_no_refund": "CS hat das PV Channels Abonnement {subscriptionid} ohne Erstattung storniert.",
    "contact_event_channels_no_refund_static": "CS hat das Abonnement ohne Erstattung über Astro storniert.",
    "contact_event_channels_workflow_launch": "CS hat den Astro Stornierungs-Workflow für PV Channels -Abonnement {subscriptionid} gestartet.",
    "contact_event_channels_workflow_launch_static": "CS hat den  Astro Stornierungs-Workflow gestartet.",
    "contact_event_coins_adjust": "Anpassungstyp: {type} <br />\nGrund: {reason} <br />\nBetrag: {amount} <br />\nAnmerkungen: {comment} <br />",
    "contact_event_coins_adjust_batched": "Angepasstes Coins-Guthaben ({count}).",
    "contact_event_concessions_abuse": "HMD wurde nicht gesendet, da der Kunde {risk_status}-Nutzer ist.",
    "contact_event_concessions_abuse_batched": "HMD wurde nicht an Kunden versendet ({count}).",
    "contact_event_contact_seller": "Händler wegen Bestellung {order_id} kontaktiert.",
    "contact_event_contact_seller_batched": "Verkäufer wegen Bestellungen ({count}) kontaktiert.",
    "contact_event_contact_was_initiated_from": "Kontakt wurde von Produkt-ASIN {ProductAsin} Produktbeschreibung {ProductDescription} Produkt-URL {ProductUrl} initiiert.",
    "contact_event_content_update": "ASIN {asin} Inhalt in Kindle-Bibliothek aktualisiert.",
    "contact_event_content_update_batched": "Inhalt in Kindle-Bibliothek aktualisiert ({count}).",
    "contact_event_copilot_started": "Kopilot-Sitzung gestartet",
    "contact_event_copilot_started_batched": "Kopilot-Sitzung gestartet ({count})",
    "contact_event_credit_card_disabled": "Kreditkarte deaktiviert.",
    "contact_event_credit_card_disabled_batched": "Kreditkarten deaktiviert ({count}).",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Override Geofence-Anfrage für Sendungsverfolgungsnummer {trackingId} übermittelt. Grund: {overrideReason} Seite:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Override Geofence-Anfrage für Sendungsverfolgungsnummer {trackingId} übermittelt. Grund: {overrideReason} Seite:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocode aktualisiert. GeocodeTyp: {geocodeType}, trackingId: {trackingId}, Adress-ID: {addressID}, overriderEason: {overriderEason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocode aktualisiert. GeoCode Typ: {geocodeType}, OverriderEason: {overriderEason}, trackingID: {trackingID}, AdressID: {addressID} und pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "Kundenauthentifizierung bestanden",
    "contact_event_customer_authentication_passed_batched": "Kundenauthentifizierung bestanden ({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Kundenname aktualisiert.",
    "contact_event_customer_name_updated_batched": "Kundenname aktualisiert ({count}).",
    "contact_event_customer_password_changed": "Kundenpasswort geändert.",
    "contact_event_customer_password_changed_batched": "Kundenpasswort geändert ({count}).",
    "contact_event_delete_alexa_voice_profile": "Alexa Sprachprofil für speakerId : {speakerId} ,  customerID : {customerID} und Status {status} gelöscht.",
    "contact_event_delete_data_alexa_comms": "Alexa Comms-Profil für commsID: {commsId},  customerID : {customerID} und Status {status} gelöscht.",
    "contact_event_deprovision_alexa_comms": "Alexa Comms-Profil mit zurückgenommener Bereitstellung für commsID {commsId}, Kundennr. {customerID} und Status {status}",
    "contact_event_deregister_kindle": "Registrierung für Kindle {serial_number} aufgehoben.",
    "contact_event_deregister_kindle_batched": "Registrierung von Kindles aufgehoben ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Assistent zur Fehlerbehebung aufgerufen für Gerät mit DSN {dsn}.",
    "contact_event_disable_mayday_failed": "Die Mayday-Funktion für das Gerät DSN {dsn} konnte nicht deaktiviert werden.",
    "contact_event_disable_mayday_success": "Die Mayday-Funktion für das Gerät DSN {dsn} wurde erfolgreich deaktiviert.",
    "contact_event_dunning_pause": "Mahnverfahren pausiert - Weitere Informationen finden Sie im Zahlungen-und-Transaktionen-Verlauf",
    "contact_event_dvbypost_extra_dispatch": "{amount} zusätzliche Lieferung(en) für diesen Kunden angefordert.",
    "contact_event_dvbypost_extra_envelope": "Ein zusätzlicher Umschlag wurde für diesen Kunden ausgelöst.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 Formular angefordert",
    "contact_event_dvbypost_problem_code_change": "Disk {disc_id} wird auf {problem_code} gesetzt",
    "contact_event_email_address_updated": "E-Mail-Adresse aktualisiert.",
    "contact_event_email_address_updated_batched": "E-Mail-Adresse aktualisiert ({count}).",
    "contact_event_email_preferences_changed": "E-Mail-Einstellungen geändert.",
    "contact_event_email_unsubscribe": "E-Mail-Abonnement storniert.",
    "contact_event_email_unsubscribe_batched": "Abonnement der E-Mail storniert ({count}).",
    "contact_event_enable_mayday_failed": "Die Mayday-Funktion für das Gerät DSN {dsn} konnte nicht reaktiviert werden.",
    "contact_event_enable_mayday_success": "Die Mayday-Funktion für das Gerät DSN {dsn} wurde erfolgreich reaktiviert.",
    "contact_event_first_time_user_experience_set": "Erste Benutzererfahrung festlegen",
    "contact_event_first_time_user_experience_set_batched": "Erste Benutzererfahrung festlegen ({count}).",
    "contact_event_form_submitted": "Formular {form_name} eingesendet.",
    "contact_event_form_submitted_batched": "Formulare eingesendet ({count}).",
    "contact_event_free_time_create_pin": "New FreeTime PIN generated successfully.",
    "contact_event_free_time_unlimited_cancelled": "Das Free Time Unlimited Abonnent wurde für den folgenden Kunden storniert: {customerId}, DSN: {deviceSerialNumber} von {agentId}, Begründung: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Free Time Unlimited Abonnement storniert ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Amazon Prime Gratiszeitraum wegen {cancel_reason] erfolgreich storniert.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime erfolgreich gekündigt ({count}).",
    "contact_event_future_cancel_audible_membership": "Für das {membership} abonnement ist ein Kündigunsdatum am {date} eingerichtet worden.",
    "contact_event_future_cancel_audible_membership_batched": "Für das {membership} abonnement ist ein Kündigunsdatum am {date} eingerichtet worden.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} wurde dauerhaft aus Ihrer Videobibliothek entfernt.",
    "contact_event_header": "Ereignisprotokoll",
    "contact_event_hmd_suppression": "HMD wurde nicht gesendet, weil: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Verbindung des inaktiven Chats getrennt",
    "contact_event_idle_chat_disconnect_batched": "Verbindung des inaktiven Chats getrennt ({count})",
    "contact_event_item_price_reduced": "Preis für {asin} hat sich von {old_price} auf {new_price} geändert.",
    "contact_event_item_price_reduced_batched": "Artikelpreise für Bestellungen geändert ({count}).",
    "contact_event_item_quantity_changed": "Artikelmenge von {asin} in Bestellung {order_id} von {old_quantity} auf {new_quantity} geändert.",
    "contact_event_item_quantity_changed_batched": "Artikelmengen für Bestellungen geändert ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Ersatz erstellt für DSN {dsn} von {old_order_id}. Neue Bestellung: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Kindle-Geräte ersetzt ({count}).",
    "contact_event_kindle_email_address_edits": "E-Mail-Adresse auf Kindle (DSN # {dsn} ) aktualisiert.",
    "contact_event_kindle_email_address_edits_batched": "E-Mail-Adressen für Kindles aktualisiert ({count}).",
    "contact_event_kindle_email_edits": "Die E-Mail-Lieferadresse {dsn} wurde wurde von {original_email} auf {new_email} geändert.",
    "contact_event_kindle_name_changed": "Kindle mit DSN {dsn} Name geändert von {original_name} auf {new_name}.",
    "contact_event_kindle_name_changed_batched": "Kindle-Namen geändert ({count}).",
    "contact_event_last_page_read_deleted": "Letzte gelesene Seite für ASIN {asin} gelöscht.",
    "contact_event_last_page_read_deleted_batched": "Zuletzt gelesene Seite gelöscht ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing für DSN gestartet:  {dsn}, kcsID: {kcsId}, Kundenreaktion: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing für Geräte ({count}) gestartet.",
    "contact_event_lock_dash_order": "Die folgenden ASINs für Gerät {dsn}  wurden gesperrt: {asins}.",
    "contact_event_music_auto_renew_off": "Der Kundenservice hat die automatische Verlängerung für Musikabonnement {subscriptionid} deaktiviert.",
    "contact_event_music_auto_renew_off_static": "Der Kundenservice hat die automatische Verlängerung für das Musikabonnement über Astro deaktiviert.",
    "contact_event_music_full_refund": "CS hat das Musikabonnement {subscriptionid} mit voller Erstattung gekündigt",
    "contact_event_music_full_refund_static": "CS hat das Abonnement mit voller Erstattung über Astro gekündigt.",
    "contact_event_music_no_refund": "CS hat Musikabonnement {subscriptionid} ohne Erstattung storniert.",
    "contact_event_music_no_refund_static": "CS hat das Musikabonnement ohne Erstattung über Astro storniert.",
    "contact_event_music_unlimited_auto_renew_off": "CS hat die automatische Verlängerung für das Amazon Music Unlimited-Abonnement {subscriptionid} deaktiviert.",
    "contact_event_music_unlimited_full_refund": "CS hat das Amazon Music Unlimited-Abonnement {subscriptionid} mit voller Rückerstattung storniert.",
    "contact_event_music_unlimited_no_refund": "CS hat das Amazon Music Unlimited-Abonnement {subscriptionid} ohne Rückerstattung storniert.",
    "contact_event_music_unlimited_partial_refund": "CS hat das Amazon Music Unlimited-Abonnement {subscriptionid} mit teilweiser Rückerstattung storniert.",
    "contact_event_music_unlimited_workflow_launch": "CS hat den Astro-Stornierungs-Workflow für das Amazon Music Unlimited-Abonnement {subscriptionid} gestartet.",
    "contact_event_music_workflow_launch": "CS hat den Astro-Stornierungsworkflow für Musikabonnement {subscriptionid} gestartet.",
    "contact_event_music_workflow_launch_static": "CS hat den Astro-Stornierungsworkflow für Music unlimited gestartet.",
    "contact_event_non_dsv_refund_created": "Für die digitale Bestellung {order_id} wurde {refund_amount} erstattet.",
    "contact_event_non_dsv_refund_created_batched": "Erstattete digitale Bestellungen ({count}).",
    "contact_event_one_click_address_updated": "1-Click-Adresse aktualisiert.",
    "contact_event_one_click_address_updated_batched": "1-Click-Adresse aktualisiert ({count}).",
    "contact_event_one_click_disabled": "1-Click deaktiviert.\n        .",
    "contact_event_one_click_disabled_batched": "1-Click deaktiviert ({count}).",
    "contact_event_one_click_enabled": "1-Click aktiviert.\n        .",
    "contact_event_one_click_payment_updated": "1-Click-Zahlung aktualisiert.",
    "contact_event_one_click_payment_updated_batched": "1-Click-Zahlung aktualisiert ({count}).",
    "contact_event_order_cancelled": "Stornierte Artikel in Bestellung {order_id}.",
    "contact_event_order_cancelled_batched": "Stornierte Bestellungen ({count}).",
    "contact_event_order_placed": "Bestellung aufgegeben {order_id}.",
    "contact_event_order_placed_batched": "Bestellungen aufgegeben ({count}).",
    "contact_event_payment_method_added": "Zahlungsweise {payment_type} zu Konto hinzugefügt.",
    "contact_event_payment_method_added_batched": "Zahlungsmethoden zu Konto hinzugefügt ({count}).",
    "contact_event_payment_method_changed": "Zahlungsmethode für Bestellung {order_id} geändert.",
    "contact_event_payment_method_changed_batched": "Zahlungsmethode für Bestellungen ({count}) geändert.",
    "contact_event_pdocs_max_price_edits": "Einstellungen für persönliche Dokumente - max. Umtauschgebühr geändert auf {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Einstellungen für max. Umtauschgebühr persönlicher Dokumente aktualisiert ({count}).",
    "contact_event_pennywise_promo_applied": "Pennywise-Werbeaktion für DSN angewendet: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Pennywise-Werbeaktion für DSNs angewendet: ({count})",
    "contact_event_ping_device": "Gerät mit DSN {dsn} gepinged.",
    "contact_event_ping_device_batched": "Gepingte Geräte ({count}).",
    "contact_event_prime_auto_renew_settings": "Fortführung von Prime nach dem Ende des aktuellen Zeitraums: {auto_renew_state}",
    "contact_event_prime_auto_revew_settings": "Prime wird zum Ende des aktuellen Zeitraums fortgeführt: {auto_renew_state}",
    "contact_event_prime_cancel_exception": "Erfolgreiche Stornierung von Prime von PrimeCancelException. Stornierungsgrund: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime storniert.",
    "contact_event_prime_cancelled_batched": "Prime storniert ({count}).",
    "contact_event_prime_concession": "Prime-Konzession für Bestellung {order_id} erstellt.",
    "contact_event_prime_concession_batched": "Prime-Konzessionen für Aufträge erteilt ({count}).",
    "contact_event_prime_refund": "Prime-Erstattung {amount}.",
    "contact_event_prime_refund_batched": "Prime erstattet ({count}).",
    "contact_event_prime_revoke_and_transfer_met": "Prime Mitgliedschaft Verlängerungs-Token übertragen",
    "contact_event_promo_added_to_account": "{amount} Werbeaktion zu Konto hinzugefügt.",
    "contact_event_promo_added_to_account_batched": "Promos zu Konto  hinzugefügt  ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} Werbeaktion nicht eingelöst.",
    "contact_event_promo_unredeemed_batched": "Nicht eingelöste Werbeaktionen ({count}).",
    "contact_event_refund_cancelled": "RMA für Bestellung {order_id} storniert.",
    "contact_event_refund_created": "{refund_amount} für Bestellung {order_id} erstattet.",
    "contact_event_refund_created_batched": "Bestellungen erstattet ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} erstattet von PrimeCancelException. Erstattungsgrund: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Erstattung Prime Stornierung Ausnahme ({count}).",
    "contact_event_register_kindle": "Kindle {serial_number} registriert.",
    "contact_event_register_kindle_batched": "Kindles registriert ({count}).",
    "contact_event_registry_deleted": "Registry gelöscht  (RegistryID Nr. {registry_id}).",
    "contact_event_registry_deleted_batched": "Register gelöscht ({count}).",
    "contact_event_release_aiv_license": "Lizenz von AIV {asin} wurde auf Gerät-DSN {dsn} freigegeben.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} Anzahl von Artikeln, die von Gerät mit DSN {dsn} verlegt wurden.",
    "contact_event_remove_content_from_todo_list_batched": "Artikel von Geräten entfernt ({count}).",
    "contact_event_remove_from_yvl": "ASIN {asin} wurde aus Ihrer Videobibliothek entfernt und zu Gelöschte Artikel übertragen.",
    "contact_event_remove_kindle_from_blacklist": "Kindle für DSN {dsn} wurde aus dem Status \"Verloren\" oder \"Gestohlen\" entfernt, Grund: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Geräte aus Status verloren oder gestohlen entfernt ({count}).",
    "contact_event_replacement_created": "Ersatz erstellt für Bestellung {old_order_id}. Neue Bestellung:  {new_order_id}.",
    "contact_event_replacement_created_batched": "Ersatz für Bestellungen erstellt ({count}).",
    "contact_event_request_invoice": "Rechnungsanforderung für Bestellung {order_id} an den Händler gesendet.",
    "contact_event_request_invoice_batched": "Rechnungsanforderung für Bestellungen {order_id} gesendet.",
    "contact_event_resend_kindle_content": "Digitaler Inhalt {title} wurde erneut an DSN {dsn} mit Gerätetyp {devicetype} gesendet.",
    "contact_event_resend_kindle_content_batched": "Digitale Inhalte erneut gesendet ({count}).",
    "contact_event_resent_gifted_content": "ASIN {asin} für Bestell-ID {order_id} Empfänger-E-Mail-Adresse wurde geändert.",
    "contact_event_resent_gifted_content_batched": "Empfänger-E-Mail-Adresse für Geschenkbestellung aktualisiert ({count}).",
    "contact_event_reset_password_email_sent": "E-Mail mit dem Passwort zum Zurücksetzen wurde gesendet.",
    "contact_event_reset_password_email_sent_batched": "Gesendete E-Mails mit dem Passwort zum Zurücksetzen ({count}).",
    "contact_event_residence_address_changed": "Wohnadresse wurde geändert.",
    "contact_event_residence_address_changed_batched": "Wohnadresse aktualisiert ({count}).",
    "contact_event_retrocharge_disarmed": "RMA für Bestellnr. {orderId} deaktiviert - ASIN {asin}. Grund für Deaktivierung: {disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "RMA für Bestellung {order_id} storniert.",
    "contact_event_rma_created": "RMA für Bestellung {order_id} erstellt.",
    "contact_event_rma_created_batched": "RMAs für Bestellungen erstellt  ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_assigned_package_to_driver": "Tracking-ID {trackingId} zur Reiseroute hinzugefügt",
    "contact_event_sds_assigned_package_to_driver_batched": "Tracking-ID {trackingId} zur Reiseroute hinzugefügt",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP-Name: {driverName}, \nStationsname: {stationName}, \nSendungsverfolgungsnummer: {trackingId} und \nBestell-ID: {orderId}\nUsecaseSession-ID: {usecaseSessionID}",
    "contact_event_sds_dlp_loaded_for_tracking_order_id": "Tracking-ID {trackingId} und Bestell-ID {orderId} geladen.",
    "contact_event_sds_dlp_loaded_for_tracking_order_id_batched": "Tracking-ID {trackingId} und Bestellnummer {orderId} geladen.",
    "contact_event_sds_map-widget-opened": "Karten-Widget für Sendungsverfolgungsnummer geöffnet {trackingId}",
    "contact_event_sds_package_action_status_update": "Tracking-ID {trackingId} und Status geändert zu {state}.",
    "contact_event_sds_package_repick": "Vom Partner initiierte erneute Auswahl für die Sendungsverfolgungsnummer {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} wurde zur Videobibliothek des Kunden {customer_id} versendet.",
    "contact_event_ship_charge_reduce": "Versandgebühr der Bestellung {order_id} wurde von {old_amount} auf {new_amount} geändert.",
    "contact_event_ship_charge_reduce_batched": "Versandgebühren geändert ({count}).",
    "contact_event_ship_method_upgraded": "Versandmethode für Bestellung {order_id} aktualisiert.",
    "contact_event_ship_method_upgraded_batched": "Versandmethoden für Bestellungen geändert ({count}).",
    "contact_event_shipping_address_changed": "Adresse auf Bestellung {new_order_id} geändert.",
    "contact_event_sim_ticket_created": "SIM-Ticket erstellt",
    "contact_event_special_offers_enrollment": "Kindle mit DSN {dsn} Sonderangebote {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Sonderangebot-Registrierungsstatus aktualisiert ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Sparabo für Bestellung {order_id} storniert.",
    "contact_event_subscribe_and_save_cancelled_batched": "Abonnieren und Sparen für Abonnements storniert ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Spar-Abo-Lieferung für Bestellung {order_id} geändert.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Abonnieren und Sparen-Versandtermine für Abonnements geändert ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS hat den Astro-Stornierungsworkflow für das {subscriptionType} Abonnement {subscriptionId} gestartet.",
    "contact_event_subscription_auto_renew": "Fortführung von {plan_type} zum Ende des aktuellen Zeitraums: {renew_status}",
    "contact_event_subscription_auto_renew_batched": "Einstellungen für das Beenden oder Fortsetzen von Prime aktualisiert ({count}).",
    "contact_event_subscription_auto_renew_off": "CS hat die automatische Verlängerung für das {subscriptionType} Abonnement {subscriptionId} deaktiviert.",
    "contact_event_subscription_cancel_full_refund": "CS hat das {subscriptionType} Abonnement {subscriptionId} mit voller Erstattung gekündigt.",
    "contact_event_subscription_cancel_no_refund": "CS hat das {subscriptionType} Abonnement {subscriptionId} ohne Erstattung gekündigt.",
    "contact_event_subscription_cancel_partial_refund": "CS hat das {subscriptionType} Abonnement {subscriptionId} mit teilweiser Erstattung gekündigt.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} Abonnement ist am Ende der laufenden Periode zur Verlängerung auf {new_plan_name} von {old_plan_name} vorgesehen.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Aktualisierte Einstellungen des Prime Rollover-Plans ({count}).",
    "contact_event_troubleshooting_wizard": "Assistent zur Fehlerbehebung aufgerufen für Gerät mit DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Assistent zur Fehlerbehebung für Geräte aufgerufen ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} für Gerät mit DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Assistent zur Fehlerbehebung für Geräte aufgerufen ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Schritte zur Fehlersuche für digitale Bestellung mit BESTELLNUMMER {digitalOrderID} aktiviert.",
    "contact_event_unblock_international_purchase": "Internationaler Kaufstatus von blockiert auf {new_status} geändert",
    "contact_event_unblock_international_purchase_batched": "Status internationaler Einkäufe aktualisiert ({count}).",
    "contact_event_unlock_dash_order": "Die folgenden ASINs für Gerät {dsn}  wurden entsperrt: {asins}.",
    "contact_event_update_locking_status": "Sperrstatus des Oft-Gerätes {dsn} wurde aktualisiert auf: {newLockingStatus}",
    "contact_event_verify_email": "Bestätigungs-E-Mail wurde an den Kunden gesendet.",
    "contact_event_verify_email_email_sent": "E-Mail-Bestätigung gesendet.",
    "contact_event_verify_email_email_sent_batched": "E-Mail-Bestätigung gesendet ({count}).",
    "contact_event_weekend_delivery_preference_changed": "Einstellungen zur Wochenendlieferung aktualisiert",
    "contact_event_weekend_delivery_preference_changed_batched": "Einstellungen zur Wochenendlieferung aktualisiert {order_id}"
}