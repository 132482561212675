export const ko_KR = {
    "contact_event_GC_added_scratchedCode": "GC #{gcId}이(가) 긁힌 청구 코드 도구를 통해 계정에 추가되었습니다.",
    "contact_event_GC_added_scratchedCode_batched": "긁힌 청구 코드 도구를 통해 계정에 추가된 GC({count}).",
    "contact_event_PV_added": "PV #{pvId}이(가) 계정에 추가되었습니다.",
    "contact_event_PV_locked": "PV #{pvId}(이)가 잠겼습니다.",
    "contact_event_PV_temporary_locked": "PV #{pvId}(이)가 임시 잠금 해제되었습니다.",
    "contact_event_PV_unlocked": "PV #{pvId}(이)가 잠금 해제되었습니다.",
    "contact_event_a_to_z_guarantee_requested_gwp": "{order_id} 주문에 대한 A-Z 보증 클레임이 제출되었습니다.",
    "contact_event_access_code_added": "액세스 코드 추가됨",
    "contact_event_account_reopened": "계정이 재개되었습니다",
    "contact_event_account_reopened_batched": "재개된 계정({count}개)",
    "contact_event_account_temporarily_disabled": "계정이 일시적으로 비활성화되었습니다.",
    "contact_event_account_temporarily_disabled_batched": "일시적으로 비활성화된 계정({count}개)",
    "contact_event_account_whitelisted": "계정이 화이트리스트에 추가되었습니다.",
    "contact_event_amazon_video_subscription_auto_renew_settings": "아마존 비디오 구독 자동 갱신",
    "contact_event_amazon_video_subscription_cancelled": "아마존 비디오 구독이 취소됨",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "제출된 양식: DSL 에스컬레이션 양식\n사용 사례 세션 ID: {usecaseSessionID}\n배송 집하장: {deliveryStationField}\n요청 유형: {requestTypeField}\n연락처 ID: {contactIdField}\n주문 ID: {orderIdField}\n고객의 이메일 주소: {customerEmailField}\n고객의 전화번호: {customerPhoneNumberField}\nTBA: {tbasField}\n액세스 코드(해당하는 경우): {accessCodeField}\n문제 요약: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "제출된 DSL 에스컬레이션 양식: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "제출된 양식: DSP DA 피드백 양식\n사용 사례 세션 ID: {usecaseSessionID}\n배송 집하장 코드: {deliveryStationField}\n연락처 ID: {contactIdField}\n고객 계정 ID: {customerIdField}\nDA 운송업체 ID: {driverIdField}\n사고 날짜(YYYY-MM-DD): {dateOfOccurrenceField}\n사고 시간(HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\n피드백 출처: {sourceOfFeedbackField}\n피드백 세부정보: {issueField}\n추가 세부정보: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "제출된 DSP DA 피드백 양식: ({count})",
    "contact_event_audible_cash_and_coupon_refund": "{title}은(는) {cashAmount}의 금액에 대해 환불되었습니다. 또한 이 거래는 {couponAmount} 쿠폰을 계정으로 복원했습니다.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title}은(는) {cashAmount}의 금액에 대해 환불되었습니다. 또한 이 거래는 {couponAmount} 쿠폰을 계정으로 복원했습니다.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title}은(는) {cashAmount}의 금액에 대해 환불되었습니다.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title}은(는) {cashAmount}의 금액에 대해 환불되었습니다.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title}이(가) 환불되었고 {couponAmount} 쿠폰이 계정으로 복원되었습니다.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title}이(가) 환불되었고 {couponAmount} 쿠폰이 계정으로 복원되었습니다.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} 크레딧이 계정에서 제거되었습니다.",
    "contact_event_audible_credit_deleted_batched": "{amount} 크레딧이 계정에서 제거되었습니다.({count})",
    "contact_event_audible_credit_refund": "{title}이(가) 환불되었고 {creditAmount} 크레딧이 계정으로 복원되었습니다.{counter}",
    "contact_event_audible_credit_refund_batched": "{title}이(가) 환불되었고 {creditAmount} 크레딧이 계정으로 복원되었습니다.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} 크레딧이 계정에 복원되었습니다.",
    "contact_event_audible_credit_restored_batched": "{amount} 크레딧이 계정에 복원되었습니다.({count})",
    "contact_event_audible_extra_credit_purchased": "3개의 추가 크레딧을 구입했습니다.",
    "contact_event_audible_extra_credit_purchased_batched": "3개의 추가 크레딧을 구입했습니다.({count})",
    "contact_event_audible_hiatus_cancelled": "중단 기간이 조기에 종료되었으며 회원 자격이 재개되었습니다.",
    "contact_event_audible_hiatus_cancelled_batched": "중단 기간이 조기에 종료되었으며 회원 자격이 재개되었습니다.({count})",
    "contact_event_audible_hiatus_started": "회원 자격이 {duration}개월 간 중단되었습니다.",
    "contact_event_audible_hiatus_started_batched": "회원 자격이 {duration}개월 간 중단되었습니다.({count})",
    "contact_event_audible_membership_plan_refund": "회비는 {cashAmount}의 금액에 환불되었습니다.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "회비는 {cashAmount}의 금액에 환불되었습니다.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "{packageName}에 등록되었습니다.",
    "contact_event_audible_romance_package_enrollment_batched": "{packageName}에 등록되었습니다.({count})",
    "contact_event_audible_wizard_invoked": "{wizardName}을(를) 액세스했습니다.",
    "contact_event_audible_wizard_invoked_batched": "{wizardName}을(를) 액세스했습니다.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "{membership} 계획이 취소되었습니다.",
    "contact_event_blacklist_kindle": "블랙 리스트에 등재된 장치 {serial_number}.",
    "contact_event_cancel_audible_membership": "{membership} 계획이 취소되었습니다.",
    "contact_event_cancel_audible_membership_batched": "{membership} 계획이 취소되었습니다.({count})",
    "contact_event_cancel_audible_romance_package_batched": "{membership} 계획이 취소되었습니다.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "이전에 설정된 향후 취소 날짜가 취소되었습니다.",
    "contact_event_channels_auto_renew_off": "CS가 PV 채널 구독{subscriptionid}에 대한 자동 갱신을 해제했습니다.",
    "contact_event_channels_auto_renew_off_static": "CS가 Astro를 통해 자동 갱신을 해제하였습니다.",
    "contact_event_channels_full_refund": "CS가 전액 환불과 함께 PV 채널 구독{subscriptionid}을 취소했습니다.",
    "contact_event_channels_full_refund_static": "CS가 Astro를 통해 전액 환불과 함께 구독을 취소했습니다.",
    "contact_event_channels_no_refund": "CS가 환불없이 PV 채널 구독 {subscriptionid}을 취소하였습니다.",
    "contact_event_channels_no_refund_static": "CS가 Astro를 통해 환불없이 구독을 취소하였습니다.",
    "contact_event_channels_workflow_launch": "CS가 PV 채널 구독 {subscriptionid}을 위한 Astro 취소 워크플로를 시작했습니다.",
    "contact_event_channels_workflow_launch_static": "CS가 Astro 취소 워크플로를 시작했습니다.",
    "contact_event_coins_adjust": "조정 유형: {type} <br />\n사유: {reason} <br />\n금액: {amount} <br />\n의견: {comment} <br />",
    "contact_event_coins_adjust_batched": "조정된 코인 잔고({count}).",
    "contact_event_concessions_abuse": "고객이 {risk_status} 사용자이기 때문에 HMD를 전송하지 못했습니다.",
    "contact_event_contact_seller_gwp": "주문 {order_id}에 대해 문의받은 판매자",
    "contact_event_contact_was_initiated_from": "ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}에서 연락이 개시되었습니다.",
    "contact_event_csc_sds_override_geo_fence_submit": "추적 ID {trackingId}에 대해 제출된 지오펜스 요청 재정의 이유: {overrideRason} 페이지:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "추적 ID {trackingId}에 대해 제출된 지오펜스 요청 재정의 이유: {overrideRason} 페이지:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "지오코드가 업데이트되었습니다. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "지오코드가 업데이트되었습니다. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} 및 pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "고객 인증 통과",
    "contact_event_customer_authentication_passed_batched": "고객 인증 통과({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_delete_alexa_voice_profile": "speakerId : {speakerId}, customerID : {customerID} 및 상태 : {status}에 대한 Alexa 음성 프로필 삭제됨",
    "contact_event_delete_data_alexa_comms": "commsID : {commsId}, customerID : {customerID} 및 상태 : {status}에 대한 Alexa Comms 프로필 삭제됨",
    "contact_event_deprovision_alexa_comms": "commsID에 대해 프로비저닝되지 않은 Alexa Comms 프로필: $ {commsId}, 고객 ID: $ {customerID} 및 상태: $ {status}",
    "contact_event_disable_mayday_failed": "장치 DSN {dsn}에 대한 Mayday 함수를 비활성화하지 못했습니다.",
    "contact_event_disable_mayday_success": "장치 DSN {dsn}에 대한 Mayday 함수가 비활성화되었습니다.",
    "contact_event_dunning_halt": "더닝 프로세스 중단됨 - 자세한 사항은 지불 및 거래 내역을 참조하십시오",
    "contact_event_dunning_pause": "더닝 프로세스 일시 중지됨 - 자세한 사항은 지불 및 거래 내역을 참조하십시오",
    "contact_event_enable_mayday_failed": "장치 DSN {dsn}에 대한 Mayday 함수를 다시 활성화하지 못했습니다.",
    "contact_event_enable_mayday_success": "장치 DSN {dsn}에 대한 Mayday 함수가 다시 활성화되었습니다.",
    "contact_event_first_time_user_experience_set": "최초의 사용자 경험 설정",
    "contact_event_first_time_user_experience_set_batched": "최초의 사용자 경험을 설정합니다({count}).",
    "contact_event_free_time_unlimited_cancelled": "고객({customerId}), DSN({deviceSerialNumber})에 대한 무료 무제한 구독이 {reason}을(를) 이유로 {agentId}에 의해 취소되었습니다.",
    "contact_event_free_time_unlimited_cancelled_batched": "무료 무제한 구독이 취소되었습니다({count}).",
    "contact_event_future_cancel_audible_membership": "{membership} 계획이 향후 {date}에 취소로 설정되었습니다.",
    "contact_event_future_cancel_audible_membership_batched": "{membership} 계획이 향후 {date}에 취소로 설정되었습니다.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin}이(가) 비디오 라이브러리에서 영구적으로 제거되었습니다.",
    "contact_event_idle_chat_disconnect": "유휴 채팅 연결 해제됨",
    "contact_event_idle_chat_disconnect_batched": "유휴 채팅 연결 해제됨({count})",
    "contact_event_live_assist_launched": "DSN에 대한 Mayday Screen Sharing 시작됨: {dsn}, kcsID: {kcsId}, 고객 반응: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "기기에 대한 Mayday Screen Sharing가 시작되었습니다({count}).",
    "contact_event_lock_dash_order": "장치 {dsn}에 대한 다음 ASIN이 잠겼습니다: {asins}.",
    "contact_event_music_auto_renew_off": "CS가 음악 구독 {subscriptionid}에 대한 자동 갱신을 해제했습니다.",
    "contact_event_music_auto_renew_off_static": "CS가 Astro를 통해 음악 구독에 대한 자동 갱신을 해제했습니다.",
    "contact_event_music_full_refund": "CS가 전액 환불과 함께 음악 구독 {subscriptionid}을(를) 취소했습니다.",
    "contact_event_music_full_refund_static": "CS가 Astro를 통해 전액 환불과 함께 음악 구독을 취소했습니다.",
    "contact_event_music_no_refund": "CS가 환불 없이 음악 구독 {subscriptionid}을(를) 취소했습니다.",
    "contact_event_music_no_refund_static": "CS가 Astro를 통해 환불 없이 음악 구독을 취소했습니다.",
    "contact_event_music_unlimited_auto_renew_off": "CS는 아마존 Music Unlimited 구독 {subscriptionid}에 대한 자동 갱신을 해제했습니다.",
    "contact_event_music_unlimited_full_refund": "CS는 전액 환불을 통해 아마존 Music Unlimited {subscriptionid} 구독을 취소했습니다.",
    "contact_event_music_unlimited_no_refund": "CS는 환불 없이 아마존 Music Unlimited 구독 {subscriptionid}을 취소했습니다.",
    "contact_event_music_unlimited_partial_refund": "CS는 부분 환불을 통해 아마존 Music Unlimited 구독 {subscriptionid}을 취소했습니다.",
    "contact_event_music_unlimited_workflow_launch": "CS는 아마존 Music Unlimited 구독 {subscriptionid}을 위한 Astro 취소 워크플로를 시작했습니다.",
    "contact_event_music_workflow_launch": "CS가 음악 구독 {subscriptionid}을(를) 위한 Astro 취소 워크플로를 시작했습니다.",
    "contact_event_music_workflow_launch_static": "CS가 음악 무제한을 위한 Astro 취소 워크플로를 시작했습니다.",
    "contact_event_pennywise_promo_applied": "DSN에 적용된 Pennywise 프로모션: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "DSN에 적용된 Pennywise 프로모션: ({count})",
    "contact_event_refund_cancelled": "주문({order_id})에 대한 환불이 취소되었습니다",
    "contact_event_release_aiv_license": "장치 DSN {dsn}에서 AIV {asin} 라이센스가 릴리스되었습니다.",
    "contact_event_remove_from_yvl": "ASIN {asin}이(가) 비디오 라이브러리에서 제거되어 삭제된 품목으로 이동했습니다.",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP 이름: {driverName}, \n역 이름: {stationName}, \n추적 ID: {trackingId} 및 \n주문 ID: {orderId}\nUsecaseSession ID: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "추적 ID {trackingId}에 대해 열린 지도 위젯",
    "contact_event_sds_package_repick": "제휴 파트너가 추적 ID {trackingId}을(를) 위해 다시 선택을 시작했습니다.",
    "contact_event_send_aiv_asin": "ASIN {asin}이(가) 고객 {customer_id}의 비디오 라이브러리로 전송되었습니다.",
    "contact_event_sim_ticket_created": "SIM 티켓이 생성됨",
    "contact_event_subscription_astro_workflow_launch": "CS가 Astro {subscriptionType} 구독 {subscriptionId} 취소 워크플로를 시작했습니다.",
    "contact_event_subscription_auto_renew_off": "CS가 {subscriptionType} 구독 {subscriptionId} 자동 갱신을 해제했습니다.",
    "contact_event_subscription_cancel_full_refund": "CS가 {subscriptionType} 구독을 {subscriptionId} 전액 환불과 함께 취소했습니다.",
    "contact_event_subscription_cancel_no_refund": "CS가 {subscriptionType} 구독을 {subscriptionId} 환불 없이 취소했습니다.",
    "contact_event_subscription_cancel_partial_refund": "CS가 {subscriptionId} 구독을 {subscriptionType} 부분 환불과 함께 취소했습니다.",
    "contact_event_unlock_dash_order": "장치 {dsn}에 대한 다음 ASIN 잠금이 해제되었습니다: {asins}.",
    "contact_event_update_locking_status": "Oft 장치 {dsn}의 잠금 상태가 다음으로 업데이트되었습니다: {newLockingStatus}",
    "contact_event_weekend_delivery_preference_changed": "주말 배송 기본 설정 업데이트됨"
}