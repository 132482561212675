export const ja_JP = {
    "ACCESS_RESTRICTED_CONTENT": "制限されたコンテンツへのアクセス",
    "ACTION": "アクション",
    "ADD": "追加",
    "ADDITIONAL_CONTACT_INFO_TITLE": "その他のお問い合わせ情報",
    "ADD_ANNOTATION_FAILURE": "注記を追加できませんでした。",
    "ADD_ANNOTATION_INVALID": "注記は無効です。注記内に制限されたデータがないことを確認してください。",
    "ADD_ANNOTATION_PLACEHOLDER": "新しい注記",
    "ADD_ANNOTATION_SUCCESS": "注記が追加されました。",
    "AGENT": "エージェント",
    "AGENT_ANNOTATION_BADGE": "エージェント",
    "AGENT_SELECTED_ISSUE": "CSAが選択したIssuecode",
    "AGENT_SELECTED_ISSUE_COPIED": "エージェントが選択した問題がコピーされました。",
    "AMAZON_CONNECT_CALL_INFO": "Amazon Connectの呼び出し情報",
    "AMAZON_CONNECT_CONTACT_ID": "Amazon Connectの連絡先ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Amazon Connectの連絡先IDをコピーしました",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazonロジスティックス",
    "ANNOTATED_BY": "{agentLogin}によって注記が付けられました。",
    "ANNOTATIONS": "注記",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "注記には、電話番号やEメールアドレスなどの購入者PIIデータを含めることはできません。Amazonの自動システムはそれの実行を試みますが、PII他のIDの違いがわからないことがあります。上書きが必要な理由を入力します。",
    "ANNOTATION_WARNING": "注釈は、プライバシー規制に基づいてお客様によるレビューの対象となる場合があります。注釈フィールドには個人情報や意見を含めないでください。",
    "ATTACHMENTS_RESTRICTED": "添付ファイルは制限されています。",
    "ATTACHMENTS_TITLE": "添付ファイル",
    "ATTACHMENT_DELETION_FAILED": "添付ファイルが削除できませんでした。後でもう一度お試しください。",
    "ATTACHMENT_DELETION_SUCCESSFUL": "添付ファイルは削除されました。",
    "AUTHENTICATING": "認証が開始されました。これにより、新しいウィンドウが開きます。",
    "AUTHENTICATION_FAILURE": "認証中に問題が発生しました。",
    "AUTHENTICATION_MESSAGE": "新しい認証情報を取得しています。このウィンドウは数秒で自動的に閉じます。",
    "AUTO_ANNOTATION_BADGE": "自動",
    "BACK": "戻る",
    "BCC_EMAIL_ADDRESS": "BCC:",
    "BLURBS_USED_TITLE": "使用したブラーブ",
    "CALL_LEG": "コールレグID",
    "CALL_LEG_ID_COPIED": "コールレグIDがコピーされました。",
    "CALL_RESTRICTED": "通話の内容は制限されています。",
    "CALL_RETENTION_DELETED": "記録の保存期間が経過し、削除されました。",
    "CALL_TITLE": "Amazonカスタマーサービスとの電話",
    "CANCEL": "キャンセル",
    "CC_EMAIL_ADDRESS": "CC",
    "CF_LARGE_NUMBER_ALERT": "このお客様には非常に多数のコンタクトがあり、すべてのコンタクトが読み込まれているわけではありません。連絡先は常に、終了日から「新しい順」に読み込まれます。終了日を変更して、別の時点から読み込みを開始します。",
    "CHANNELS": "チャンネル",
    "CHAT_AGENT_CONNECTED": "{name}はチャットを受け入れました。",
    "CHAT_CONTACT": "チャットのお問い合わせ",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name}がネットワークから接続解除されました。",
    "CHAT_DISCONNECT_GENERIC": "{name}はもう接続されていません。",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name}がネットワークから接続解除されました。",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name}がネットワークから接続解除されました。",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name}は会話から退席しました。",
    "CHAT_PARKED": "このチャットは中断されました",
    "CHAT_PARKED_NOTES": "パークに関する注意",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name}は保留中ではありません。",
    "CHAT_PARTICIPANT_ON_HOLD": "{name}は保留中です。",
    "CHAT_RESTRICTED": "チャットの内容は制限されています。",
    "CHAT_TITLE": "Amazonカスタマーサービスとのチャット",
    "CHAT_TRANSFER_NOTES": "転送メモ",
    "CHILD_WORK_REQUESTS": "このコンタクトから作成された作業リクエスト",
    "CHOOSE_OPTIONS": "オプションを選択してください",
    "CLOSE": "閉じる",
    "CLOSE_ALERT": "アラートを閉じる",
    "CLOSE_MODAL_BUTTON_TEXT": "閉じる",
    "COLLAPSED": "部分表示",
    "CONFIRM": "確認する",
    "CONTACTS": "お問い合わせ",
    "CONTACT_COLLAPSED": "お問い合わせの詳細は部分表示されています。",
    "CONTACT_CREATION_FAILURE": "新しい連絡先の作成に失敗しました",
    "CONTACT_EXPANDED": "お問い合わせの詳細が展開されました。",
    "CONTACT_HISTORY": "問い合わせ履歴",
    "CONTACT_ID": "コンタクトID",
    "CONTACT_ID_COPIED": "コンタクトIDがコピーされました。",
    "CONTACT_MEDIUM_DEFAULT_TAB": "お問い合わせ",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "エスカレーション",
    "CONTACT_REASSIGNED_TO_AGENT": "連絡先はエージェントに再割り当てされました。",
    "CONTACT_SELECT_CHECKBOX": "ロックされた連絡先を選択するためのチェックボックス",
    "CONTACT_STATUS_ESCALATION_BADGE": "エスカレーション",
    "CONTACT_TICKET": "SIM-TT作成",
    "CONTACT_TICKET_CONTENT": "チケット内容",
    "CONTACT_TICKET_CREATE": "お問い合わせチケットの作成",
    "CONTACT_TICKET_CREATION_FAILURE": "お問い合わせチケットが作成できませんでした",
    "CONTACT_TICKET_CREATION_SUCCESS": "お問い合わせチケットが作成されました",
    "CONTACT_TICKET_RESOLVER_GROUP": "解決者グループ",
    "CONTACT_TICKET_SEVERITY": "チケット重大度",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIMフォルダーID",
    "CONTACT_TICKET_SUBJECT": "チケット件名",
    "CONTACT_TIMELINE": "お問い合わせタイムライン",
    "CONTACT_TYPE": "コンタクトタイプ",
    "CONTACT_WAS_TRANSFERRED": "このお問い合わせは転送されました。",
    "CONTENT": "コンテンツ",
    "CONTENT_RESTRICTED": "コンテンツは制限されています。",
    "COPY_AGENT_SELECTED_ISSUE": "エージェントが選択した問題をコピー: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Amazon Connectの連絡先IDをコピーする",
    "COPY_CALL_LEG_ID": "コールレグIDをコピー: {value}",
    "COPY_CONTACT_ID": "コンタクトIDをコピー: {value}",
    "COPY_CUSTOMER_ID": "カスタマーIDをコピーする",
    "COPY_CUSTOMER_SELECTED_ISSUE": "購入者が選択した問題をコピー: {value}",
    "COPY_FAILURE": "コピーに失敗しました。",
    "COPY_ID": "IDをコピー",
    "COPY_LINK": "画像アップロードリンクのコピー",
    "COPY_ORDER_ID": "注文番号をコピー: {value}。",
    "CREATE_CASE": "ケースを作成",
    "CREATE_FOLLOW_UP": "フォローアップを作成する",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "フォローアップが正常に作成されましたが、対応する注記を追加できませんでした。注記を手動で追加し直してください。",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "お客様のご希望の連絡方法を指定してください。",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "過去の期限や日時でフォローアップを作成することはできません。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "フォローアップアクションの期限の日時を指定してください。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "タイムゾーン",
    "CREATE_FOLLOW_UP_FAILURE": "フォローアップの作成に失敗しました",
    "CREATE_FOLLOW_UP_INVALID": "フォローアップが無効です。制限されたデータがないことを確認してください。",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "フォローアップアクションを入力してください。",
    "CREATE_FOLLOW_UP_SUCCESS": "フォローアップが正常に作成されました。",
    "CREATE_WORK_REQUEST": "作業リクエストの作成",
    "CROSS_MARKETPLACE_QUEUES": "クロスマーケットプレイスのキュー",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "コンタクトは既に解決されているため、転送できませんでした。",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "コンタクトが無効な状態にあるため、操作が失敗しました。",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "コンタクトが見つからなかったため、操作が失敗しました",
    "CSALT_ERROR_INTERNAL_ERROR": "コンタクトをロックできませんでした",
    "CSALT_ERROR_INVALID_ARGUMENTS": "無効な引数が原因でコンタクトをロックできませんでした",
    "CSALT_ERROR_INVALID_INPUT": "無効な入力が原因で、操作が失敗しました。",
    "CSALT_ERROR_INVALID_QUEUE": "選択したキューが無効なため、コンタクトを転送できませんでした。",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "コンタクトが別のエージェントにロックされているため、操作が失敗しました。",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "依存関係エラーのため、連絡先をロックできませんでした",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "バックエンドサービスでキューが見つからないため、コンタクトを転送できませんでした。",
    "CSALT_ERROR_UNKNOWN": "予期しないエラーのため、操作が失敗しました。",
    "CURRENT_CONTACT": "現在のお問い合わせ",
    "CUSTOMER": "購入者",
    "CUSTOMER_FOLLOWUPS": "購入者へのFU",
    "CUSTOMER_FOLLOWUPS_FAILURE": "購入者へのFUが取得できませんでした",
    "CUSTOMER_ID_COPIED": "カスタマーIDがコピーされました",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "購入者の内容は制限されています。(ロックされたお問い合わせはありません。)",
    "CUSTOMER_SELECTED_ISSUE": "お客様が選択したお問い合わせ内容",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "購入者が選択した問題をコピーしました。",
    "CUSTOMER_TIMEZONE": "購入者のタイムゾーンに表示されるタイムスタンプ",
    "DATE": "日付",
    "DATE_PICKER_NEXT_MONTH": "翌月",
    "DATE_PICKER_PREVIOUS_MONTH": "先月",
    "DATE_PICKER_TODAY": "本日",
    "DECODING_AUDIO": "オーディオをデコード中",
    "DELETE_ATTACHMENTS": "コンタクトの添付ファイルを削除する",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "添付ファイルを削除する",
    "DELIVERY_STATUS": "配送ステータス",
    "DISABLE_CONTENT_FILTER": "機密情報のフィルターを無効にする",
    "DOWNLOAD": "ダウンロード",
    "DURATION": "期間",
    "EMAIL": "Eメール",
    "EMAIL_ADDRESS_NOT_ALLOWED": "受信者のEメールアドレスは無効です。",
    "EMAIL_BODY": "メール本文",
    "EMAIL_CONTACT": "Eメールのお問い合わせ",
    "EMAIL_HEADER": "ヘッダー",
    "EMAIL_QUEUE_TITLE": "Eメールのキュー",
    "EMAIL_RESTRICTED": "Eメールの内容は制限されています。",
    "EMAIL_SENDING_FAILURE": "アウトバウンドEメールの送信に失敗しました。",
    "EMAIL_SUBJECT": "Eメールの件名",
    "EMAIL_TITLE": "AmazonカスタマーサービスとのEメール",
    "END": "終了",
    "END_DATE": "終了日",
    "ERROR": "エラー",
    "ERROR_FAILED_TO_LOAD_DATA": "データが読み込めませんでした。",
    "ESCALATION_CONTACT_HANDLING": "エスカレーションに該当する問い合わせの処理",
    "ESCALATION_TAB_LEAD_LOGIN": "リードのログイン: {agentLogin}",
    "EXPANDED": "全体表示",
    "FAILED_TO_CREATE_WORK_REQUEST": "作業リクエストが作成できませんでした",
    "FAILED_TO_FETCH_SYSKA": "SYSKAアラートの取得に失敗しました",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "連絡先をエージェントに再割り当てできませんでした。",
    "FETCHED_NUMBER_CONTACTS": "{number}件のお問い合わせを取得しました。",
    "FILTER": "絞り込み",
    "FINDER": "ファインダー",
    "FOLLOW_UPS_COLLAPSED": "FUを部分表示",
    "FOLLOW_UPS_EXPANDED": "FUを展開",
    "FOLLOW_UP_ALERT": "には、{agentLogin}によって作成された、{dueDate}に予定されているFUがあります",
    "FOLLOW_UP_CONTACT_METHOD": "連絡方法",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "連絡方法は制限されています。",
    "FOLLOW_UP_DUE": "FU期日",
    "FOLLOW_UP_DUE_DATE": "期限",
    "FOLLOW_UP_NOTE": "注意",
    "FOLLOW_UP_NO_CONTACT_METHOD": "連絡方法なし",
    "FOLLOW_UP_NO_NOTE": "ノートは空です",
    "FOLLOW_UP_NO_TICKET": "チケットなし",
    "FOLLOW_UP_OWNER": "所有者",
    "FOLLOW_UP_STATUS": "ステータス",
    "FOLLOW_UP_STATUS_OPEN": "開く",
    "FOLLOW_UP_STATUS_RESOLVED": "リゾルプ済み",
    "FOLLOW_UP_TICKET": "チケット",
    "FOLLOW_UP_TITLE": "FU",
    "FROM": "開始日",
    "GENERATE_LINK": "リンクを作成",
    "HAS_OVERRIDE_TRY_AGAIN": "フィルターが上書きされています。もう一度お試しください。",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "購入者と共有する画像添付リンク",
    "INPUT_CHARACTERS_REMAINING": "最大{char_max}文字(残り{char_remain}文字)",
    "INTERNAL_ERROR": "内部エラー",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "内部エラーが発生しました。エラーが続く場合は、問題を説明するチケットを切ってください。",
    "INVALID_CONTACT": "連絡先が無効です。",
    "INVALID_DATA_MATCH": "システムは、データの無効な分類を実行しました。",
    "INVALID_TIMESTAMP": "タイムスタンプが無効です。",
    "IN_MARKETPLACE_QUEUES": "マーケットプレイスのキュー内",
    "ISSUE": "問題",
    "ISSUE_CODE": "発行コード",
    "ISSUE_CODE_ALREADY_ADDRESSED": "以前の連絡先ですでに対処し、解決済み",
    "ISSUE_CODE_DESCRIPTION": "選択した連絡先を重複する理由を選択する",
    "ISSUE_CODE_EXACTLY_SAME": "前の連絡先とまったく同じ",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "別のチャットCSAによる処理と解決",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "別の電子メールCSAによって処理され、解決されました。",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "別の電話機CSAによって処理され、解決された",
    "ITEMS_CONTACTED_ABOUT": "お問い合わせの注文",
    "I_UNDERSTAND": "理解しました",
    "KNOWN_ISSUES": "既知の問題",
    "LEARN_MORE": "詳細はこちら",
    "LINK_COPIED": "リンクがコピーされました",
    "LOADING": "データの読み込み中...",
    "LOADING_PREVIEW_TEXT": "プレビューを読み込み中",
    "LOADING_RECORDING": "録音を読み込み中",
    "LOAD_MORE": "もっと読み込む",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "注記をさらに読み込みます。",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "この購入者のお問い合わせをさらに読み込みます。",
    "LOCKED": "ロック済み",
    "LOCKED_BY": "ロックした者",
    "LOCK_CONTACT": "ロックする",
    "LOCK_CONTACT_NOT_AUTHORIZED": "コンタクトのロックに失敗しました。ユーザーは許可されていません",
    "LOCK_CONTACT_SUCCESSFUL": "コンタクトが次にロックされました:",
    "MISSING_ORIGINAL_CONTACT_ID": "有効な元の連絡先IDを入力してください",
    "MODAL_NO_BUTTON_TEXT": "いいえ",
    "MODAL_YES_BUTTON_TEXT": "はい",
    "NEXT": "次へ",
    "NORMAL": "標準",
    "NO_AGENT_SELECTED_ISSUE": "エージェントが選択した問題はありません。",
    "NO_ANNOTATED_BY": "不明なユーザーによって注記が付けられました。",
    "NO_ANNOTATIONS": "使用可能な注記がありません。",
    "NO_ATTACHMENTS": "添付ファイルがありません。",
    "NO_CHAT": "チャットは空です。",
    "NO_CHILD_WORK_REQUESTS": "このコンタクトから作業リクエストは作成されていません",
    "NO_CONTACT": "利用可能なお問い合わせはありません",
    "NO_CONTACTS_SELECTED": "コンタクトを選択してスパムとして処理してください",
    "NO_CONTENT": "コンテンツなし",
    "NO_CUSTOMER_SELECTED_ISSUE": "購入者が選択した問題はありません。",
    "NO_DELIVERY_STATUS": "配送ステータスなし",
    "NO_EMAIL_BODY": "Eメールが空です。",
    "NO_EMAIL_HEADER": "Eメールにヘッダーがありません。",
    "NO_EMAIL_QUEUE": "この連絡先に関連付けられたEメールのキューはありません。",
    "NO_ITEMS_CONTACTED_ABOUT": "連絡済みのアイテムはありません。",
    "NO_RESULTS": "検索結果がありません。",
    "NO_SCREEN_POP": "スクリーンポップ情報がありません",
    "NO_SENT_FROM": "送信元アドレスはありません。",
    "NO_SENT_TO": "送信先住所はありません",
    "NO_SUBJECT": "件名が空です。",
    "NO_SYSKA_ALERTS": "SYSKAアラートがありません",
    "NO_USED_BLURBS": "このお問い合わせにはブラーブが使用されていません。",
    "NO_VIEW": "表示するお問い合わせまたはカスターはありません。",
    "NO_WORK_CATEGORY": "このお問い合わせに関連付けられたワークカテゴリはありません。",
    "NUMBER_OF_CONTACTS": "読み込み済みのコンタクト",
    "OLDEST_LOADED_CONTACT": "最も古い読み込み済みコンタクト",
    "OPEN": "開く",
    "OPENS_IN_A_NEW_TAB": "新しいタブで開く",
    "ORDER_ID_COPIED": "注文番号をコピーしました。",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "元の連絡先IDを入力してください",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "元の連絡先IDを入力するためのテキストボックス",
    "OTHER": "その他",
    "OUTBOUND_EMAIL_BCC": "以下にBCCする:",
    "OUTBOUND_EMAIL_CC": "以下にCC中:",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "通知内容は部分表示されています。",
    "OUTBOUND_NOTIFICATION_EXPANDED": "通知内容が展開されました。",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "結果の最大数に達しました。時間の範囲を狭くして、もう一度試してください。",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "現在のプロセスにはPIIコンテンツが必要です。",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "電話番号、Eメールアドレス、またはその他のPIIとしての一致が無効です。",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "クレジットカードまたはIBANとしての一致が無効です。",
    "OVERRIDE_REASON_PROMPT": "注記には、電話番号やEメールアドレスなどの購入者PIIデータを含めることはできません。Amazonの自動システムはそれを実行しようとしていますが、PIIと他のIDの違いがわからないことがあります。上書きが必要な理由を入力してください。",
    "PAPYRUS_SESSION_EXPLANATION": "適切なセキュリティ制限を適用し、ニーズに合わせてUXを最適化できるように、Papyrusセッションの種類を指定してください。",
    "PAPYRUS_SESSION_TYPE": "Papyrusセッションタイプ",
    "PAUSE": "一時停止",
    "PHONE_CONTACT": "電話での問い合わせ。",
    "PLAY": "再生",
    "PLAYBACK_SPEED": "再生速度",
    "POSTAL_MAIL_TITLE": "Amazonカスタマーサービスとの郵便",
    "PREPARING_RECORDING": "レコーディングの準備中",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "連絡先のプレビューを生成できませんでした",
    "PREVIOUS": "前へ",
    "PRE_TRANSLATED_REPLY_EMAIL": "事前翻訳された返信メール",
    "PRE_TRANSLATION_REPLY_TEXT": "ロゼッタ翻訳前のCSAタイプの返信",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "処理アクション",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "複製処理",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "スパムとして処理",
    "PROCESS_CONTACT_AS_DUPLICATE": "選択した連絡先を重複として解決",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "選択した連絡先をスパムとして処理してもよろしいですか?",
    "PROCESS_DUPLICATE_FAILURE": "選択した連絡先を重複として解決できませんでした",
    "PROCESS_DUPLICATE_SUCCESS": "選択した連絡先を重複として正常に解決しました",
    "PROCESS_SPAM_FAILURE": "選択したコンタクトをスパムに転送できませんでした",
    "PROCESS_SPAM_SUCCESS": "選択したコンタクトをスパムに転送しました",
    "PROVIDE_ADDITIONAL_INFORMATION": "このコンタクトに関連する追加情報を提供してください",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "コンタクトから機密情報を消去しました:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "単語を消去リストに追加する",
    "PURGE_BUTTON_TEXT": "消去",
    "PURGE_CONFIRMATION_CHECK": "上の欄を確認し、テキストに機密情報が実際に含まれていることを認めます。",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "添付ファイルに購入者の機密情報が含まれていることを確認します。",
    "PURGE_CONFIRMATION_UNCHECKED": "チェックボックスを確認してください。",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "連絡先からの消去に失敗しました",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "連絡先から情報を正常に消去しました",
    "PURGE_INVALID_REASON": "{invalid_purge_selection}は、消去の正当な理由になりません",
    "PURGE_REASON_FINANCIAL_INFO": "財務情報",
    "PURGE_REASON_OTHER": "その他の機密情報",
    "PURGE_REASON_PII": "個人を特定できる情報",
    "PURGE_REASON_PLACEHOLDER": "消去の理由を入力",
    "PURGE_REASON_TEXT": "消去の理由",
    "PURGE_REMOVE_LABEL": "削除",
    "PURGE_SELECTOR_PLACEHOLDER": "機密情報を消去する理由を選択",
    "PURGE_SENSITIVE_INFO": "機密情報のフィルターを有効にする",
    "PURGE_WORD_NOT_FOUND": "連絡先テキストに単語が見つかりません",
    "PURPOSE_AUDITING": "品質管理のための監査",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Back Officeワークフロー",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "これは、Back Officeワークフローの問い合わせデータを取得するためのセッションです。たとえば、チケットやSIMに含める添付ファイルなどです。",
    "PURPOSE_COACHING": "問い合わせレビューのコーチング",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "コンプライアンス調査",
    "PURPOSE_CURATION": "問い合わせキュレーション",
    "PURPOSE_CURATION_DESCRIPTION": "これは、C2CS/VoC問い合わせキュレーションのセッションです。",
    "PURPOSE_DESCRIPTION_AUDITING": "これは、問い合わせを監査し、その品質を評価するためのセッションです。",
    "PURPOSE_DESCRIPTION_COACHING": "これは、パフォーマンスポータルのコーチとして、エージェントの問い合わせを確認するためのセッションです。",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "これは、不審なアクティビティ監視調査のセッションです。",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "これはエスカレーションに該当する問い合わせを処理するセッションです。これにより、任意の顧客の問い合わせデータにアクセスできます。",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "これはライブの問い合わせ処理のためのセッションです。",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "コンタクトから機密情報を消去するのが目的です。",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "これは、機械学習モデルの精度を見直すためのセッションです。",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "これはご自身の過去の問い合わせを確認するためのセッションです。",
    "PURPOSE_DESCRIPTION_UNKNOWN": "これはデフォルトの汎用的なセッションです。",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "エスカレーションに該当する問い合わせの処理",
    "PURPOSE_PURGE_SENSITIVE_INFO": "機密情報を消去する",
    "REASON_FOR_PURGE": "消去の理由",
    "REASON_FOR_PURGE_DESCRIPTION": "添付ファイルを消去する理由",
    "REASON_FOR_PURGE_MISSING": "添付ファイルを削除する理由を入力してください。",
    "REASSIGN_TO_AGENT": "エージェントに再割り当て",
    "RECORDING": "記録",
    "RELOADING_CONTENT": "コンテンツを再読み込みしています。",
    "RELOAD_LIST": "リストを再読み込み",
    "REPLY_BY": "回答者",
    "REPLY_BY_EMAIL": "Eメールで返信",
    "REPLY_BY_PHONE": "電話で返信",
    "REPLY_RESTRICTED": "返信の内容は制限されています。",
    "REQUEST": "リクエスト",
    "REQUEST_ACCESS_INSTRUCTIONS": "制限されたコンテンツへのアクセスが必要な理由を入力してください。(これらのリクエストは監視されており、乱用すると重大な結果を招く可能性があります。)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "フィルターされたコンテンツへのアクセスをリクエストする",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "制限されたコンテンツへのアクセスのリクエスト",
    "REQUEST_NOT_GRANTED": "リクエストは承認されませんでした。",
    "REQUEST_OVERRIDE": "上書きのリクエスト",
    "RESEND": "再送信",
    "RESEND_FAILED": "Eメールの再送信ができませんでした。",
    "RESEND_SUCCESS": "Eメールが再送信されました。",
    "RESEND_WAIT_WARNING": "再送信するには{wait_mins}分待たなければなりません。",
    "RESEND_WARNING": "購入者にメールを再送信してもよろしいですか。",
    "RESET": "リセット",
    "RESOLVED": "解決済み",
    "RESOLVED_BY": "解決者",
    "RESTRICTED_DATA_MATCH": "{match}マッチするタイプ {type}",
    "RESTRICTED_INFORMATION": "制限された情報",
    "RETRY": "再試行",
    "ROLE": "権限",
    "SCREEN_POP_DESCRIPTION_TITLE": "説明",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "いいえ",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "はい",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "エージェントに表示",
    "SCREEN_POP_TITLE": "スクリーンポップ情報",
    "SCREEN_POP_TYPE_AUTHENTICATING": "認証スクリーンポップがエージェントに表示され、使用されました。",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "認証スクリーンポップがエージェントに表示されました。",
    "SCREEN_POP_TYPE_CALLER_ID": "発信者IDのスクリーンポップがエージェントに表示され、使用されました。",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "発信者IDのスクリーンポップがエージェントに表示されました。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "認証されていないスクリーンポップが表示され、使用されました。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "認証されていないスクリーンポップがエージェントに表示されました。",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "購入者は不明です。スクリーンポップはありません。",
    "SCREEN_POP_TYPE_OTHER": "その他",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "いいえ",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "はい",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "エージェントが使用",
    "SDS_TOOLS": "発送および配達サポートツール",
    "SEARCH": "検索",
    "SELECTED_ROLE": "選択された権限",
    "SELECTED_VIEW": "選択したビュー",
    "SELECT_CHANNELS": "チャネルを選択",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "選択した連絡先を重複としてマークする課題コードを選択するには",
    "SELECT_TRANSFER_DESTINATION": "転送先を選択",
    "SELECT_WORK_REQUEST_QUEUE": "作業リクエストキューを選択してください",
    "SENDER_EMAIL_ADDRESS": "送信者",
    "SENDING_EMAIL": "Eメールの送信",
    "SEND_EMAIL": "Eメールを送信する",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "画像アップロードリンクを購入者に送信してもよろしいですか?",
    "SEND_OUTBOUND_EMAIL": "Eメールを送信",
    "SEND_OUTBOUND_EMAIL_WARNING": "アウトバウンドEメールを以下に送信してよろしいですか",
    "SENT_FROM": "送信元",
    "SENT_OUTBOUND_EMAIL": "アウトバウンドEメールが送信されました。新しい連絡先が作成されました",
    "SENT_TO": "送信先",
    "SHIPPER_PORTAL_BUTTON_TEXT": "配送業者ポータル",
    "SKILL": "スキル",
    "SKIP_SILENCES": "無音部分をスキップする",
    "SMS": "SMS",
    "SMS_LINK": "SMSリンク",
    "SOURCE_TEXT": "原文テキスト",
    "SP_BOTTOM": "下部",
    "SP_POSITION": "パネル位置の分割",
    "SP_PREFERENCES": "分割パネルの環境設定",
    "SP_RESIZE": "分割パネルのサイズ変更",
    "SP_SIDE": "側部",
    "START": "開始",
    "STATE_TRANSITIONS": "コンタクトステータスの遷移",
    "STATUS": "ステータス",
    "SUBJECT": "件名",
    "SYSKA_ALERTS": "SYSKAアラート",
    "TEXT_TO_PURGE": "消去するテキスト",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "サードパーティのCookieを有効にする必要があります。",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "電話番号やEメールアドレスなどの購入者PIIデータは、チケットの作成中は許可されません。Amazonの自動システムはそれを実行しようとしていますが、PIIと他のIDの違いがわからないことがあります。上書きが必要な理由を入力してください。",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "タイムアウト: レコーディングの読み込みに失敗しました",
    "TIME_TEXT_FORMAT_CONSTRAINT": "24時間形式を使用してください。",
    "TO": "終了日",
    "TO_EMAIL_ADDRESS": "宛先",
    "TRANSFER": "転送",
    "TRANSFERRED_CHAT_TITLE": "Amazonカスタマーサービスとの転送済みチャット",
    "TRANSFERRING": "転送中...",
    "TRANSFER_CONTACT": "お問い合わせを転送",
    "TRANSFER_CONTACT_COMMENTS": "オプションの注記",
    "TRANSFER_FAILED": "転送できませんでした。",
    "TRANSFER_FOLLOW_UP": "フォローアップを転送する",
    "TRANSFER_FOLLOW_UP_FAILED": "フォローアップの転送に失敗しました。",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "新しい所有者のログインが無効なため、フォローアップを転送できませんでした。",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "お客様はこのフォローアップを転送する権限がありません。",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "新しい所有者のログイン名を入力してください。例: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "上記のフォローアップを次に転送してください。",
    "TRANSFER_FOLLOW_UP_SUCCESS": "フォローアップが正常に転送されました。",
    "TRANSLATED_TEXT": "翻訳済みテキスト",
    "TRANSLATION": "翻訳",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue}は有効なキュー名ではありません。",
    "TYPE_AGENT_LOGIN": "エージェントログインを入力",
    "TYPE_EMAIL_ADDRESS": "Eメールアドレスを入力します",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "複数のEメールアドレスをカンマで区切って入力します",
    "TYPE_QUEUE_NAME": "キュー名を入力",
    "UNKNOWN_CONTACT_TYPE": "不明なお問い合わせタイプです。",
    "UNLOCKED": "ロック解除済み",
    "UNRESOLVED": "未解決",
    "URGENT_SYSKA_ALERTS": "緊急SYSKAアラート",
    "USE": "使用",
    "USER_TIMEZONE": "現地のタイムゾーンに表示されるタイムスタンプ",
    "USE_CONTACT_FINDER": "コンタクトファインダーを使う",
    "VIEW": "表示",
    "WARNING": "警告",
    "WELCOME_MESSAGE": "Papyrus Staticウェブサイトへようこそ！",
    "WHAT_IS_PAPYRUS": "Papyrusとは何ですか?",
    "WITH_THE_ANNOTATION": "注記で",
    "WORK_CATEGORY_TITLE": "作業カテゴリ",
    "WORK_REQUEST": "対応依頼",
    "WORK_REQUEST_CONTENT": "作業リクエストの内容",
    "WORK_REQUEST_CREATED": "作業リクエストが作成されました",
    "WORK_REQUEST_FOR_CONTACT": "コンタクトの作業リクエスト",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "電話番号やEメールアドレスなどの購入者PIIデータは、作業リクエストの作成中は許可されません。Amazonの自動システムはそれを実行しようとしていますが、PIIと他のIDの違いがわからないことがあります。上書きが必要な理由を入力してください。",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "キューに転送しようとしています"
}