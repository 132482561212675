import React from "react";
import {Copyable} from "../util/Copyable";

interface CustomerIdDisplayProps {
    customerId: string;
}

const CustomerIdDisplay: React.FC<CustomerIdDisplayProps> = ({customerId}) => {
    return (
        <div>
            <div>
                <Copyable
                    actionTranslationId="COPY_CUSTOMER_ID"
                    successTranslationId="CUSTOMER_ID_COPIED"
                    content={customerId}
                    valueToCopy={customerId}/>
            </div>
        </div>
    );
};

export default CustomerIdDisplay;