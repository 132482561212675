import React from "react";
import OrderLink from "./OrderLink";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";

interface ContactOrdersProps {
    orderIds: string[];
}

const ContactOrders: React.FC<ContactOrdersProps> = ({orderIds}) => {
    return (
        <div>
            {orderIds.length > 0 ?
                <div>{orderIds.map((orderId) => <OrderLink key={orderId} orderId={orderId}/>) }</div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_ITEMS_CONTACTED_ABOUT")}/>}
        </div>
    );
};

export default ContactOrders;