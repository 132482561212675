import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";

import App from "../components/App";
import IntlManager from "../i18n/IntlManager";

export default function Root({ store }) {
  return (
    <Provider store={store}>
      <div>
        <Router>
          <Route path="/:locale" render={props =>
              IntlManager.isValidLocale(props.match.params.locale) ? (
                  <App {...props} />
              ) : (
                  <Redirect to={`/en${props.location.pathname}${props.location.search}`} />
              )
          } />
            <Route exact path="/" render={props =>
                <Redirect to={`/en${props.location.search}`} />
            } />
        </Router>
      </div>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
