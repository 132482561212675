import React, {useContext, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input from "@amzn/awsui-components-react/polaris/input";
import {i18n} from "../../i18n/IntlManager";
import {Contact} from "../Contact.types";
import {
    LogLevel,
    Match,
    MatchRestrictedDataResponse,
    MetricType
} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {AddText, AddTextStatus} from "../AddText";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {CreateTicketForContactOverrideRequestForm} from "../../authorizer/CreateTicketForContactOverrideRequestForm";

interface CreateTicketFormProps {
    customerId?: string;
    contact: Contact;
    deleteMe: () => void;
    pushNotification: (msg: JSX.Element, type: FlashbarProps.Type) => void;
}

export const CreateTicketForm: React.FC<CreateTicketFormProps> = ({customerId, contact, deleteMe, pushNotification}) => {
    const ticketSeverityList: SelectProps.Option[] = [
        {value: 'SEV_3', label: '3'},
        {value: 'SEV_4', label: '4'},
        {value: 'SEV_5', label: '5'},
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [simFolderId, setSimFolderId] = useState<string>("");
    const [resolverGroup, setResolverGroup] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<string|undefined>(ticketSeverityList[ticketSeverityList.length - 1].value);

    const { reloadSingleContact, authorizerJwtTokens, callPhoenix } = useContext(IssueManagerContext);

    // Restricted data matching
    const [subjectTextStatus, setSubjectTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [subjectRestrictedDataMatchList, setSubjectRestrictedDataMatchList] = useState<Match[]>([]);
    const [addTextStatus, setAddTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [restrictedDataMatchList, setRestrictedDataMatchList] = useState<Match[]>([]);
    const [showOverrideRequestForm, setShowOverrideRequestForm] = useState<boolean>(false);

    const filteredToken = authorizerJwtTokens.find(token => token.getAuth().find(item => item.e === 'CREATE_TICKET_FOR_CONTACT_WITHOUT_FILTER'));
    const hasFilterOverride: boolean = filteredToken !== undefined;

    /*
                TODO: current solution does not work for quotes
                 https://sim.amazon.com/issues/CSPUMA-1327
    */
    const mutation = `
        mutation {
            createTicketForContact(input: {
                obfuscatedContactId: "${contact.contactId}",
                ` + (customerId ? `obfuscatedCustomerId: "${customerId}",` : '') + `
                resolverGroup: ${JSON.stringify(resolverGroup)},
                simFolderId: ${JSON.stringify(simFolderId)},
                ticketSubject: ${JSON.stringify(subject)},
                ticketMessage: ${(JSON.stringify(message)).replaceAll("\\","\\\\")},
                severity: ${severity}
            }) {
                ... on CreateTicketForContactResultSuccess {
                    ticketId
                }
            }
        }`;

    async function onClickCreateTicket(): Promise<void> {
        setIsLoading(true);
        if (hasFilterOverride) {
            await createTicket();
        } else {
            const response: MatchRestrictedDataResponse = await Phoenix.getInstance().matchRestrictedData([
                subject,
                message
            ]);

            const subjectMatches: Match[] = response.matches[0];
            const messageBodyMatches: Match[] = response.matches[1];

            if (subjectMatches.length > 0) {
                setSubjectTextStatus(AddTextStatus.ERROR);
                setSubjectRestrictedDataMatchList(subjectMatches);
            }
            if (messageBodyMatches.length > 0) {
                setAddTextStatus(AddTextStatus.ERROR);
                setRestrictedDataMatchList(messageBodyMatches);
            }
            if (subjectMatches.length === 0 && messageBodyMatches.length === 0) {
                await createTicket();
            }
        }
        setIsLoading(false);
    }

    async function createTicket(): Promise<void> {
        let success = false;
        try {
            const response = await callPhoenix(mutation);
            if (response.httpStatus === 200) {
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data.createTicketForContact.ticketId) {
                        const ticketId = parsed.data.createTicketForContact.ticketId;
                        pushNotification((<div>{i18n('CONTACT_TICKET_CREATION_SUCCESS')}{" - " + ticketId}</div>), 'success');
                        await reloadSingleContact(contact.contactId);
                        success = true;
                        deleteMe();
                    }
                } else {
                    Phoenix.getInstance().log(LogLevel.ERROR, "Failed to create contact ticket: " + response);
                    pushNotification((<div>{i18n('CONTACT_TICKET_CREATION_FAILURE')}</div>), 'error');
                }
            }
            else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to create contact ticket: " + response);
                pushNotification((<div>{i18n('CONTACT_TICKET_CREATION_FAILURE')}</div>), 'error');
            }
        } catch(error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to create contact ticket: " + error);
            pushNotification((<div>{i18n('CONTACT_TICKET_CREATION_FAILURE') + ": " + error}</div>), 'error');
        } finally {
            Phoenix.getInstance().addMetric("createContactTicket.SUCCESS",
                success ? 1 : 0, MetricType.COUNT);
                setIsLoading(false);
        }
    }

    function cancel(): void {
        deleteMe();
    }

    const createTicketDisabled =
        subject.length === 0
        || message.length === 0;

    return (<Form actions={
    <SpaceBetween direction="horizontal" size="xs">
        <Button variant="link" disabled={isLoading} onClick={(): void => cancel()}>{i18n('CANCEL')}</Button>
        {(addTextStatus === AddTextStatus.ERROR || subjectTextStatus === AddTextStatus.ERROR) ?
            <Button onClick={(): void => setShowOverrideRequestForm(true)}>
                {i18n('REQUEST_OVERRIDE')}
            </Button> : null
        }
        <Button
            variant="primary"
            disabled={createTicketDisabled}
            onClick={(event): Promise<void> => onClickCreateTicket()}
        >{i18n('CONTACT_TICKET_CREATE')}</Button>
    </SpaceBetween>
    }>
        <ColumnLayout columns={2}>
            <FormField
                label={i18n('CONTACT_TICKET_SIM_FOLDER_ID')}
            >
                <Input
                    disabled={isLoading}
                    ariaLabel={i18n('CONTACT_TICKET_SIM_FOLDER_ID')}
                    value={simFolderId}
                    onChange={({detail}): void => setSimFolderId(detail.value)}
                />
            </FormField>
            <FormField
                label={i18n('CONTACT_TICKET_RESOLVER_GROUP')}
            >
                <Input
                    disabled={isLoading}
                    ariaLabel={i18n('CONTACT_TICKET_RESOLVER_GROUP')}
                    value={resolverGroup}
                    onChange={({detail}): void => setResolverGroup(detail.value)}
                />
            </FormField>
        </ColumnLayout>
        <FormField
            label={i18n('CONTACT_TICKET_SUBJECT')}
        >
            <AddText
                rows={1}
                disabled={isLoading}
                newText={subject}
                setNewText={setSubject}
                textAriaLabel={i18n('CONTACT_TICKET_SUBJECT')}
                addTextStatus={subjectTextStatus}
                setAddTextStatus={setSubjectTextStatus}
                restrictedDataMatchList={subjectRestrictedDataMatchList}
            />
        </FormField>
        <FormField
            label={i18n('CONTACT_TICKET_CONTENT')}
        >
            <AddText
                rows={10}
                disabled={isLoading}
                newText={message}
                setNewText={setMessage}
                textAriaLabel={i18n('CONTACT_TICKET_CONTENT')}
                addTextStatus={addTextStatus}
                setAddTextStatus={setAddTextStatus}
                restrictedDataMatchList={restrictedDataMatchList}
            />
        </FormField>
        {hasFilterOverride ? i18n('HAS_OVERRIDE_TRY_AGAIN') : null}
        <FormField
            label={i18n('CONTACT_TICKET_SEVERITY')}
        >
            <Select options={ticketSeverityList}
                    selectedOption={ticketSeverityList.find(v => v.value === severity) || null}
                    disabled={isLoading}
                    onChange={(event): void => {
                        setSeverity(event.detail.selectedOption.value);
                    }}
            />
        </FormField>
        {showOverrideRequestForm ? <CreateTicketForContactOverrideRequestForm contactId={contact.contactId}
                                    clearMatchErrors={(): void => {setAddTextStatus(AddTextStatus.NONE);
                                    setSubjectTextStatus(AddTextStatus.NONE);
                                    setSubjectRestrictedDataMatchList([]);
                                    setRestrictedDataMatchList([]);}}
                                    closeModal={(): void => setShowOverrideRequestForm(false)}/> : null}
    </Form>);
};