import React, { Fragment } from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import {ChatDisconnectReason, ChatMessage, ChatMessageType, ChatParticipantStateChangeType, ChatParticipantType} from "./Contact.types";
import Translation from "../i18n/Translate";
import './ContactChatMediumStyle.css';
import {Text} from "../util/Text";
import {i18n} from "../i18n/IntlManager";
import {Timestamp} from "./Timestamp";

interface ChatMessageDisplayProps {
    chatMessage: ChatMessage;
    prevMessage?: ChatMessage;
    customerTimezone: string;
}

const DISCONNECT_REASON_STRING_ID_MAP: Map<ChatDisconnectReason, string> = new Map([
    [ChatDisconnectReason.PUSH_SERVICE_ERROR, "CHAT_DISCONNECT_PUSH_SERVICE_ERROR"],
    [ChatDisconnectReason.GACD_HANGUP, "CHAT_DISCONNECT_GACD_HANG_UP"],
    [ChatDisconnectReason.USER_HANGUP, "CHAT_DISCONNECT_USER_HANG_UP"],
    [ChatDisconnectReason.MESSAGE_TRANSPORT_ERROR, "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR"]
]);

const DEFAULT_CHAT_DISCONNECT_STRING_ID = "CHAT_DISCONNECT_GENERIC";

function getParticipantName(message: ChatMessage | undefined): string | undefined {
    const isCustomer: boolean = message?.participant?.__typename === ChatParticipantType.CUSTOMER;
    return isCustomer ? (message?.participant?.customerName) : (message?.participant?.name);
}

export const constructMessageText = (message: ChatMessage): JSX.Element => {
    if (message.type === ChatMessageType.MESSAGE) {
        if ((message.participant?.__typename === ChatParticipantType.CUSTOMER || message.participant?.__typename === ChatParticipantType.BOT) && message.chatText) {
            const sourceText = <Text keepNewline text={message.chatText.text} />;
            let translatedText;
            if (message.translationList?.[0]) {
                translatedText = <Text keepNewline text={"["+ i18n("TRANSLATED_TEXT") + "] " + message.translationList[0].text} tag={"i"} />;
            }
            return <span>
                {sourceText}
                {translatedText}
            </span>;
        }
        else if (message.participant?.__typename === ChatParticipantType.CSA && message.chatText) {
            let sourceText;
            let translatedText;
            if (message.translationList?.[0]) {
                sourceText = <Text keepNewline text={"["+ i18n("SOURCE_TEXT") + "] " + message.chatText.text} tag={"i"} />;
                translatedText = <Text keepNewline text={message.translationList[0].text} />;
            } else {
                sourceText = <Text keepNewline text={message.chatText.text} />;
            }
            return <span>
                {sourceText}
                {translatedText}
            </span>;
        }
        else {
            return <span/>;
        }
    } else if (message.type === ChatMessageType.CHAT_PARKED) {
        if (message.chatText) {
            return <span>
                {i18n("CHAT_PARKED")}
                <br/>
                <Text keepNewline text={i18n("CHAT_PARKED_NOTES") + ": " + message.chatText.text} tag={"span"} />
            </span>;
        } else {
            return <Translation stringId="CHAT_PARKED"/>;
        }
    } else if (message.type === ChatMessageType.CHAT_TRANSFERRED) {
        if (message.chatText) {
            return <Text keepNewline text={i18n("CHAT_TRANSFER_NOTES") + ": " + message.chatText.text} tag={"span"} />;
        } else {
            return <span>{i18n("CHAT_TRANSFER_NOTES")}: </span>;
        }
    } else {
        const participantName = getParticipantName(message);

        if (message.stateChangeType === ChatParticipantStateChangeType.CONNECTED) {
            return <Translation stringId="CHAT_AGENT_CONNECTED" params={{"name" : participantName}}/>;
        } else if (message.stateChangeType === ChatParticipantStateChangeType.ON_HOLD) {
            return <Translation stringId="CHAT_PARTICIPANT_ON_HOLD" params={{"name" : participantName}}/>;
        } else if (message.stateChangeType === ChatParticipantStateChangeType.DISCONNECTED) {
            let stringId = message.disconnectReason ? DISCONNECT_REASON_STRING_ID_MAP.get(message.disconnectReason) : null;
            if (!stringId) {
                stringId = DEFAULT_CHAT_DISCONNECT_STRING_ID;
            }
            return <Translation stringId={stringId} params={{"name" : participantName}}/>;
        } else {
            return <Translation stringId="CHAT_PARTICIPANT_OFF_HOLD" params={{"name" : participantName}}/>;
        }
    }
};

const ChatMessageDisplay: React.FC<ChatMessageDisplayProps> = ({chatMessage, prevMessage,customerTimezone}) => {
    const sameParticipant: boolean = getParticipantName(chatMessage) === getParticipantName(prevMessage);
    const isCustomer: boolean = chatMessage.participant?.__typename === ChatParticipantType.CUSTOMER;
    return (
        <Fragment>
            <div className={isCustomer ? "customer-message" : "amazon-message"}>
                {sameParticipant ? '' :
                    <div className={isCustomer ? '' : "amazon-message-name"}>
                        <Box variant="strong" color="inherit">{getParticipantName(chatMessage)}</Box>
                    </div>
                }
                <div>{constructMessageText(chatMessage)}</div>
            </div>
            <div className={isCustomer ? "customer-message-timestamp" : "amazon-message-timestamp"}>
                <Timestamp date={chatMessage.timestamp} customerTimezone={customerTimezone} format={"LTS"} withoutTimezone={true}/>
            </div>
        </Fragment>
    );
};

export default ChatMessageDisplay;
