import {LogLevel, MetricType, PhoenixRequest} from "@amzn/csphoenix-react-client";
import Phoenix from "../api/Phoenix";
import {Contact} from "../contact/Contact.types";
import {PhoneMediumType} from "./CSALTAudioPlayer";

export interface RecordingPartResult {
    buffer: Buffer;
    nextPart: number| undefined;
}

export class CSALTCallLoader {
    private contactId: string;
    private phoneMediumType: PhoneMediumType;
    private id?: string;

    constructor(contactId: string, phoneMediumType: PhoneMediumType, id?: string) {
        this.contactId = contactId;
        this.phoneMediumType = phoneMediumType;
        this.id = id;
    }

    // PhoenixGateway requires agent and gacdAgentOwner fields for phone recording authorization
    getGACDQuery(): string {
        return `query { ` +
            `   contact ( contactId: "${this.contactId}") { ` +
            `       contact { ` +
            `         contactId ` +
            `           medium { ` +
            `             phoneRecordList {` +
            `               agentMediaLegId` +
            `               agent {` +
            `                 agentId` +
            `                 agentLogin` +
            `               }` +
            `               gacdAgentOwner` +
            `               callRecordingPart {` +
            `                 base64 nextPart }` +
            `             }` +
            `           }` +
            `         }` +
            `       }` +
            `   }`;
    }

    getGACDPartQuery(index: number): string {
        return `query { ` +
            `   contact ( contactId: "${this.contactId}") { ` +
            `       contact { ` +
            `         contactId ` +
            `           medium { ` +
            `             phoneRecordList {` +
            `               agentMediaLegId` +
            `               agent {` +
            `                 agentId` +
            `                 agentLogin` +
            `               }` +
            `               gacdAgentOwner` +
            `               callRecordingPart(part: "${index}") {` +
            `                 base64 nextPart }` +
            `             }` +
            `           }` +
            `         }` +
            `       }` +
            `   }`;
    }

    getConnectQuery(): string {
        return `query { ` +
            `   contact ( contactId: "${this.contactId}") { ` +
            `       contact { ` +
            `         contactId ` +
            `           medium { ` +
            `             connectPhoneRecordList ( connectPhoneRecordFilterInput: { connectContactId: "${this.id}" }) {` +
            `               connectContactId` +
            `               agent {` +
            `                 agentId` +
            `                 agentLogin` +
            `               }` +
            `               callRecordingPart {` +
            `                 base64 nextPart }` +
            `             }` +
            `           }` +
            `         }` +
            `       }` +
            `   }`;
    }

    getConnectPartQuery(index: number): string {
        return `query { ` +
            `   contact ( contactId: "${this.contactId}") { ` +
            `       contact { ` +
            `         contactId ` +
            `           medium { ` +
            `             connectPhoneRecordList ( connectPhoneRecordFilterInput: { connectContactId: "${this.id}" }) {` +
            `               connectContactId` +
            `               agent {` +
            `                 agentId` +
            `                 agentLogin` +
            `               }` +
            `               callRecordingPart(part: "${index}") {` +
            `                 base64 nextPart }` +
            `             }` +
            `           }` +
            `         }` +
            `       }` +
            `   }`;
    }

    getQuery(): string {
        if (this.phoneMediumType === PhoneMediumType.AwsConnect) {
            return this.getConnectQuery();
        } else {
            return this.getGACDQuery();
        }
    }

    getPartQuery(part: number): string {
        if (this.phoneMediumType === PhoneMediumType.AwsConnect) {
            return this.getConnectPartQuery(part);
        } else {
            return this.getGACDPartQuery(part);
        }
    }

    buildRequest(query: string): PhoenixRequest {
        return {
            query: query,
            contractId: "CSALTGraphQL",
            projectName: "CSALT",
            jwtState: undefined
        };
    }

    async pullPart(part?: number): Promise<RecordingPartResult|null> {
        const metricPrefix = `CSALTCallLoader.${PhoneMediumType[this.phoneMediumType]}`;

        const query = part ? this.getPartQuery(part) : this.getQuery();

        const request = this.buildRequest(query);

        const response = await Phoenix.getInstance().csalt(request);

        if (response.ok && response.callResult) {
            const json = response.callResult?.resultJson;
            const parsed = JSON.parse(json);
            const contact = parsed.data.contact.contact as Contact;
            if (contact.medium.phoneRecordList) {
                const phoneRecord = contact.medium.phoneRecordList
                    .filter((r) => r.agentMediaLegId === this.id)
                    .shift();
                if (phoneRecord && phoneRecord.callRecordingPart) {
                    const base64 = phoneRecord.callRecordingPart.base64;
                    const nextPart = Number(phoneRecord.callRecordingPart.nextPart);

                    if (base64) {
                        const bytes = Buffer.from(base64, 'base64');
                        Phoenix.getInstance().addMetric(`${metricPrefix}.Success`, 1, MetricType.COUNT);
                        return {buffer: bytes, nextPart: nextPart};
                    }
                }
            }
            if (contact.medium.connectPhoneRecordList) {
                const phoneRecord = contact.medium.connectPhoneRecordList
                    .filter((r) => r.connectContactId === this.id)
                    .shift();
                if (phoneRecord && phoneRecord.callRecordingPart) {
                    const base64 = phoneRecord.callRecordingPart.base64;
                    const nextPart = Number(phoneRecord.callRecordingPart.nextPart);

                    if (base64) {
                        const bytes = Buffer.from(base64, 'base64');
                        Phoenix.getInstance().addMetric(`${metricPrefix}.Success`, 1, MetricType.COUNT);
                        return {buffer: bytes, nextPart: nextPart};
                    }
                }
            }
        }

        if (!response.ok) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Response error from query CallRecordingContactPart for contactId "
                + this.contactId +  ": " + response.error);
            Phoenix.getInstance().addMetric(`${metricPrefix}.Error`, 1, MetricType.COUNT);
        }

        if (!response.callResult) {
            Phoenix.getInstance().addMetric(`${metricPrefix}.Empty`, 1, MetricType.COUNT);
        }
        return null;
    }

    async loadRecording(): Promise<Blob|null> {
        try {
            const buffers: Buffer[] = [];
            let offset = 0;

            let recordingPartResult;
            do {
                offset = recordingPartResult?.nextPart;
                recordingPartResult = await this.pullPart(offset);
                if (recordingPartResult?.buffer) {
                    buffers.push(recordingPartResult?.buffer);
                }
            } while (recordingPartResult?.nextPart);
            if (!buffers.length) {
                // Data is not there yet
                return null;
            }

            const blobProps: BlobPropertyBag = {
                type: this.phoneMediumType === PhoneMediumType.GACD ? 'audio/mpeg' : 'audio/wav'
            };
            const blob = new Blob(buffers, blobProps);
            return blob;
        }
        catch (e) {
            Phoenix.getInstance().addMetric("LoadRecording.Error", 1, MetricType.COUNT);
            console.error('error', e);
            throw (e);
        }
    }
}