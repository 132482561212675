/* eslint-disable */
import {cs_CZ} from "./cs_CZ";
import {de_DE} from "./de_DE";
import {en_AU} from "./en_AU";
import {en_CA} from "./en_CA";
import {en_GB} from "./en_GB";
import {en_IN} from "./en_IN";
import {es_ES} from "./es_ES";
import {es_MX} from "./es_MX";
import {es_US} from "./es_US";
import {fr_CA} from "./fr_CA";
import {fr_FR} from "./fr_FR";
import {it_IT} from "./it_IT";
import {ja_JP} from "./ja_JP";
import {ko_KR} from "./ko_KR";
import {nl_NL} from "./nl_NL";
import {pl_PL} from "./pl_PL";
import {pt_BR} from "./pt_BR";
import {pt_PT} from "./pt_PT";
import {tr_TR} from "./tr_TR";
import {zh_CN} from "./zh_CN";
import {zh_TW} from "./zh_TW";
import {ar_AE} from "./ar_AE";

export const papyrusMessages = {
    ar_AE,
    cs_CZ,
    de_DE,
    en_AU,
    en_CA,
    en_GB,
    en_IN,
    es_ES,
    es_MX,
    es_US,
    fr_CA,
    fr_FR,
    it_IT,
    ja_JP,
    ko_KR,
    nl_NL,
    pl_PL,
    pt_BR,
    pt_PT,
    tr_TR,
    zh_CN,
    zh_TW
};
