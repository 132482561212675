import React from "react";
import Translation from "../i18n/Translate";
import {ContactAttachment} from "./Contact.types";
import AttachmentDisplay from "./AttachmentDisplay";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {SpaceBetween} from "@amzn/awsui-components-react";

interface ContactMediumAttachmentsDisplayProps {
    attachments?: ContactAttachment[];
    customerContentIsRestricted: boolean;
    marketplaceId: string;
}

const ContactMediumAttachmentsDisplay: React.FC<ContactMediumAttachmentsDisplayProps> = ({
                                                                                             attachments,
                                                                                             customerContentIsRestricted,
                                                                                             marketplaceId
                                                                                         }) => {
    const displayableAttachments = attachments?.filter((attachment) => !!attachment.attachmentId) || [];

    // We want to show the attachment section when:
    // 1. attachment is restricted and showing the alert when there are attachments to be hidden.
    // 2. attachment is not restricted and the displayable list is actually populated.
    // We don't want to show the attachment section when:
    // 1. attachment is restricted but there are no attachments.
    // 2. attachment is not restricted but there are no displayable attachments.
    const showAttachmentSection = (customerContentIsRestricted && attachments && attachments?.length > 0) || displayableAttachments.length > 0;

    const body = customerContentIsRestricted ?
        <Alert><Translation stringId={'ATTACHMENTS_RESTRICTED'}/></Alert> :
        <SpaceBetween
            size={"s"}
            direction={"vertical"}>
            <div role="list" style={{
                color: "#708090",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start"
            }}>
                {displayableAttachments.map((attachment) =>
                    <AttachmentDisplay
                        key={displayableAttachments.indexOf(attachment)}
                        attachment={attachment}
                        marketplaceId={marketplaceId}
                    />
                )}
            </div>
        </SpaceBetween>;

    return (
        <div>
            {showAttachmentSection &&
            <div>
                <h4 className="contact-details-subheader"><Translation stringId="ATTACHMENTS_TITLE"/></h4>
                {body}
            </div>
            }
        </div>
    );
};

export default ContactMediumAttachmentsDisplay;