import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Translation from "../i18n/Translate";
import {Copyable} from "../util/Copyable";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import Alert from "@amzn/awsui-components-react/polaris/alert";

interface CustomerIdSelectProps {
    customerIdSelectionList: SelectProps.Option[];
    selectedCustomerId: string;
    disabled: boolean;
    customerContentIsRestricted: boolean;
    onCustomerIdChange: (id: string, label: string) => void;

}

export const CustomerIdSelect: React.FC<CustomerIdSelectProps> = (
    {
        customerIdSelectionList, selectedCustomerId, disabled,
        customerContentIsRestricted, onCustomerIdChange
    }
) => {
    return (
        <div>
            <Box variant="h3" fontSize="heading-xs">
                <Translation stringId="CUSTOMER"/>
            </Box>
            {customerIdSelectionList.length > 1 ?
                <div>
                    <Copyable
                        actionTranslationId="COPY_CUSTOMER_ID"
                        successTranslationId="CUSTOMER_ID_COPIED"
                        content={
                            <Box display="inline-block">
                                <FormField>
                                    <Select options={customerIdSelectionList}
                                            selectedOption={customerIdSelectionList.find(v => v.value === selectedCustomerId) || null}
                                            disabled={disabled}
                                            onChange={(event): void => {
                                                if (event.detail.selectedOption.value && event.detail.selectedOption.label) {
                                                    onCustomerIdChange(event.detail.selectedOption.value, event.detail.selectedOption.label);
                                                }
                                            }}
                                    />
                                </FormField>
                            </Box>}
                        valueToCopy={selectedCustomerId}/>

                </div>
                :
                customerIdSelectionList.length === 1 ?
                    <div>
                        <Copyable
                            actionTranslationId="COPY_CUSTOMER_ID"
                            successTranslationId="CUSTOMER_ID_COPIED"
                            content={selectedCustomerId}
                            valueToCopy={selectedCustomerId}/>
                    </div>
                    : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_VIEW")}/>
            }
            {customerContentIsRestricted ?
                <Alert><Translation stringId={'CUSTOMER_RESTRICTED_NO_LOCKED'}/></Alert> : null}
        </div>
    );
};