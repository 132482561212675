import React from "react";
import CustomerIdDisplay from "./CustomerIdDisplay";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import Translation from "../i18n/Translate";

interface ContactCustomerIdsProps {
    obfuscatedCustomerIdList: string[];
}

const ContactCustomerIds: React.FC<ContactCustomerIdsProps> = ({obfuscatedCustomerIdList}) => {
    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId={"CUSTOMER_IDS"}/></h4>
            {obfuscatedCustomerIdList.length > 0 ?
                <div>{obfuscatedCustomerIdList.map((customerId) => <CustomerIdDisplay key={customerId}
                customerId={customerId}/>) }</div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CUSTOMER_ID_FOUND")}/>}
        </div>
    );
};

export default ContactCustomerIds;