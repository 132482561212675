import {Contact, ContactMediumType, ContactStatus} from "./Contact.types";


export function IsContactLockedByAgent(contact: Contact, userId: string, agentLogin: string): boolean {

    return (contact.status === ContactStatus.LOCKED && userId === contact.owner?.agentId) ||
           (contact.status === ContactStatus.LOCKED && agentLogin === contact.owner?.agentLogin);
}

export function HasFollowUpsOwnedByAgent(contact: Contact, userId: string, agentLogin: string): boolean {
    return !!contact?.followUps?.find((f): boolean => {
       return !f.resolved && (userId === f?.owner?.agentId || agentLogin === f?.owner?.agentLogin);
    });
}

export function isPhoneContact(contact: Contact): boolean {
    return contact?.medium?.type === ContactMediumType.PHONE;
}