/**
 * CSCentralURL stores information regarding the root CSCentral URL that Papyrus is running in.
 * It will follow the pattern: baseURL/sessionId?baseQueryString
 *
 * Example: https://dev-dsk-tanolive-2a-98d5d4b0.us-west-2.amazon.com:8011/gp/stores/www.amazon.com
 * /130-2208712-0249952?ie=UTF8&usecaseSessionID=60739951691
 */
export default class CSCentralURL {
    static sharedCSCentralURL: CSCentralURL;

    private readonly baseURL: string;
    private readonly sessionId: string;
    private readonly baseQueryString: string;

    constructor(url: string|null) {
        if (url) {
            const parts: string[] = url.split('?', 2);
            parts.length === 2 ? this.baseQueryString = parts[1] : this.baseQueryString = '';
            this.baseURL = parts[0].substring(0, parts[0].lastIndexOf("/") + 1);
            this.sessionId = parts[0].substring(parts[0].lastIndexOf("/") + 1, parts[0].length);
        } else {
            this.baseURL = "";
            this.sessionId = "";
            this.baseQueryString = "";
        }
    }

    /**
     * Returns a CSCentral URL string constructed with the passed in parameters if the base CSCentral URL is valid.
     */
    public getURL(path: string, queryParams: string): string {
        if (this.baseURL) {
            const queryString: string = this.baseQueryString ? this.baseQueryString + '&' + queryParams : queryParams;
            return this.baseURL + path + this.sessionId + '?' + queryString;
        } else {
            return '';
        }
    }

    /**
      * Returns  https://cscentral.amazon.com/ with the passed in path and query string parameters for
      a valid CSCentral URL like https://cscentral.amazon.com/gp/stores/www.amazon.com/gp/communications/issue-manager
      this.baseURL.split("/",3).join("/") returns "https://cscentral.amazon.com"
      */
    public getURLWithCustomPath(path: string, queryParams: string): string {
        if (this.baseURL) {
            const queryString: string = this.baseQueryString ? this.baseQueryString + '&' + queryParams : queryParams;
            return this.baseURL.split("/",3).join("/") + path  + '?' + queryString;
        } else {
            return '';
        }
    }

    /**
     * return the CSC baseUrl.
     * The baseUrl is of form "https://cscentral.amazon.com/gp/stores/www.amazon.com/" it includes the '/' at the end.;
     */
    public getBaseUrl(): string {
        return this.baseURL;
    }

    /**
     * Returns cscentral.amazon.com for CSCentral URL is https://cscentral.amazon.com/gp/stores/www.amazon.com/gp/communications/issue-manager,
     */
    get domain(): string {
        if (this.baseURL) {
            // Get everything after https://
            const noProtocol = this.baseURL.split("//")[1];
            return noProtocol.split("/")[0];
        } else {
            return '';
        }
    }
}