import {Contact, CustomerAccessLevel, FollowUp} from "./Contact.types";
import React, { useState} from "react";
import Translation from "../i18n/Translate";
import Button from "@amzn/awsui-components-react/polaris/button";
import CreateFollowUpForm from "./createFollowUp/CreateFollowUpForm";
import TransferFollowUpModal from "./TransferFollowUpModal";
import FollowUpsTable from "./FollowUpsTable";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";

interface FollowUpsDisplayProps {
    contact: Contact;
    followUps: FollowUp[];
    customerAccessLevel: CustomerAccessLevel;
    customerTimezone: string;
    customerId?: string;
    marketplaceId: string;
    emailToken: string | null;
}

type FlashBarItemType = FlashbarProps.MessageDefinition & { itemId: number };
const FollowUpsDisplay: React.FC<FollowUpsDisplayProps> = ({followUps, customerAccessLevel, customerTimezone, contact, emailToken, customerId, marketplaceId}) => {

    const [createFollowUpFormIsVisible, setCreateFollowUpFormIsVisible] = useState<boolean>(false);
    const [followUpInTransfer, setFollowUpInTransfer] = useState<FollowUp>();
    const [flashBarItems, setFlashBarItems] = useState<ReadonlyArray<FlashBarItemType>>([]);
    const [flashBarMsgId, setFlashBarMsgId] = useState<number>(0);
    const [transferFollowUpModalIsVisible, setTransferFollowUpModalIsVisible] = useState<boolean>(false);

    const pushNotification = (msg: JSX.Element, type: FlashbarProps.Type): void => {
        const msgToAdd: FlashBarItemType = {
            content: msg,
            itemId: flashBarMsgId,
            onDismiss: () => setFlashBarItems(items =>
                items.filter(item => item.itemId !== flashBarMsgId)
            ),
            dismissible: true,
            type: type,
        };
        setFlashBarItems( [...flashBarItems, msgToAdd]);
        setFlashBarMsgId(flashBarMsgId + 1);
    };

    return <div>
        <h4 className="contact-details-header"><Translation stringId={"FOLLOW_UP_TITLE"}/></h4>
        <Flashbar items={flashBarItems}/>
        {emailToken &&
            <Button onClick={(): void => setCreateFollowUpFormIsVisible(!createFollowUpFormIsVisible)}>
                <Translation stringId={"CREATE_FOLLOW_UP"}/>
            </Button>
        }
        {createFollowUpFormIsVisible && emailToken &&
                <CreateFollowUpForm
                contact={contact}
                emailToken={emailToken}
                timezone={customerTimezone}
                customerId={customerId}
                pushNotification={pushNotification}
                marketplaceId={marketplaceId}
                />}
        {followUps.length > 0 ?
            <><FollowUpsTable
                followUps={followUps}
                customerAccessLevel={customerAccessLevel}
                customerTimezone={customerTimezone}
                openTransferModal={(followUp): void => {
                    setFollowUpInTransfer(followUp);
                    setTransferFollowUpModalIsVisible(true);
                }}
            />
            {followUpInTransfer && <TransferFollowUpModal
                visible={transferFollowUpModalIsVisible}
                followUp={followUpInTransfer}
                dismissCallback={(): void => {
                    setFollowUpInTransfer(undefined);
                    setTransferFollowUpModalIsVisible(false);
                }}
                customerAccessLevel={customerAccessLevel}
                customerTimezone={customerTimezone}
                pushNotification={pushNotification}
                contactId={contact.contactId}
            />
            }</> : null
        }
    </div>;
};

export default FollowUpsDisplay;