import React from "react";
import '@amzn/awsui-global-styles/polaris.css';
import "font-awesome/css/font-awesome.min.css";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Routes from "../routes";
import {RouteComponentProps, withRouter} from "react-router-dom";
import IntlManager from "../i18n/IntlManager";
import CSCentralURL from "../issueManager/CSCentralURL";
import Phoenix from "../api/Phoenix";
import {en_US} from "../i18n/en_US";
import {papyrusMessages} from "../i18n/papyrus/papyrus";
import {eventMessages} from "../i18n/events/events";

const SUPPORTED_LOCALES = [
    'ar_AE',
    'cs_CZ',
    'de_DE',
    'en_AU',
    'en_CA',
    'en_GB',
    'en_IN',
    'en_US',
    'es_ES',
    'es_MX',
    'es_US',
    'fr_CA',
    'fr_FR',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'nl_NL',
    'pl_PL',
    'pt_BR',
    'pt_PT',
    'tr_TR',
    'zh_CN',
    'zh_TW'
];
const DEFAULT_LOCALE = 'en_US';

enum QueryStringParams {
    cscURL = "cscURL",
    phoenixGatewayQualifier = "pgw",
}

interface MatchParams {
    locale: string;
}

function getMessages(locale: string): Record<string, unknown> {
    if (locale === 'en_US' || !SUPPORTED_LOCALES.includes(locale)) {
        return en_US;
    }
    // Merge the event translations into the papyrus translations
    Object.assign(papyrusMessages[locale], eventMessages[locale]);
    return papyrusMessages[locale];
}

const App: React.FC<RouteComponentProps<MatchParams>> = ({location, match}) => {
    let locale = match.params.locale;
    if (locale === undefined) {
        console.warn("No locale found in the URL, using default locale: " + DEFAULT_LOCALE);
        locale = DEFAULT_LOCALE;
    }
    if (SUPPORTED_LOCALES.indexOf(locale) === -1) {
        console.warn("Unsupported locale: " + locale + ". Using default locale: " + DEFAULT_LOCALE);
    }
    const messages = getMessages(locale);
    IntlManager.sharedManager = new IntlManager(locale, messages, en_US);

    const params = new URLSearchParams(location.search);
    const cscURLString = params.get(QueryStringParams.cscURL);
    CSCentralURL.sharedCSCentralURL = new CSCentralURL(cscURLString);

    const pgwQualifier = params.get(QueryStringParams.phoenixGatewayQualifier);
    Phoenix.init(pgwQualifier? pgwQualifier : 'local', locale);

    return (
        <div className="awsui">
            <AppLayout
                disableContentPaddings={true}
                content={Routes}
                toolsHide={true}
                navigationHide={true}
            />
        </div>
    );
};

export default withRouter(App);
