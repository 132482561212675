import React from "react";
import IntlManager from "../../i18n/IntlManager";
import Box from "@amzn/awsui-components-react/polaris/box";
import {Timestamp} from "../../contact/Timestamp";
import Translation from "../../i18n/Translate";
import EmdashImage from "../../util/EmdashImage";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

interface NotificationHeaderProps {
    sentTo?: string;
    sentFrom?: string;
    sentDate: string;
    customerTimezone: string;
}

export const NotificationHeader: React.FC<NotificationHeaderProps> = ({sentTo, sentFrom, sentDate, customerTimezone}) => {

    return (
        <div>
            <Box padding={{"horizontal": "xxs"}} display="inline-block">
                <Timestamp date={sentDate} customerTimezone={customerTimezone}/>
            </Box>
            <Box float="right" display="inline-block">
                <SpaceBetween direction="horizontal" size="s">
                    <Box
                        variant="span"
                        float="right"
                        aria-label={IntlManager.sharedManager.formatMessage("SENT_FROM")}>
                        <Translation stringId={"SENT_FROM"}/> {": "}
                        {sentFrom ?
                            sentFrom :
                            <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SENT_FROM")}/>
                        }
                    </Box>
                    <Box
                        variant="span"
                        float="right"
                        aria-label={IntlManager.sharedManager.formatMessage("SENT_TO")}>
                        <Translation stringId={"SENT_TO"}/> {": "}
                        {sentTo ?
                            sentTo :
                            <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SENT_TO")}/>
                        }
                    </Box>
                </SpaceBetween>
            </Box>
        </div>
    );
};