export const es_MX = {
    "ABANDONED": "ABANDONADO por",
    "ABANDONED_BY_AGENT": "ABANDONADO por {agent}",
    "ACCESS_RESTRICTED_CONTENT": "Acceso a contenido restringido",
    "ACTION": "Acción",
    "ADD": "Agregar",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Información de contacto adicional",
    "ADD_ANNOTATION_FAILURE": "No se pudo agregar la anotación.",
    "ADD_ANNOTATION_INVALID": "La anotación no es válida. Asegúrate de que no haya datos restringidos en la anotación.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nueva anotación",
    "ADD_ANNOTATION_SUCCESS": "Se agregó con éxito una anotación.",
    "AGENT": "Agente",
    "AGENT_ANNOTATION_BADGE": "Agente",
    "AGENT_SELECTED_ISSUE": "Asunto seleccionado por agente",
    "AGENT_SELECTED_ISSUE_COPIED": "Asunto seleccionado por agente copiado.",
    "AMAZON_CONNECT_CALL_INFO": "Información de llamadas de Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID de contacto de Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID de contacto de Amazon Connect copiado",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANALYZED": "ANALIZADO por",
    "ANALYZED_BY_AGENT": "ANALIZADO por {agent}",
    "ANNOTATED_BY": "Anotado por {agentLogin}.",
    "ANNOTATIONS": "Anotaciones",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Se supone que las anotaciones no contienen datos de PII del cliente, como números de teléfono y direcciones de email. Nuestro sistema automatizado intenta hacer cumplir eso, pero a veces no distingue la diferencia entre PII y otros ID. Proporcione la razón por la que se necesita una anulación.",
    "ANNOTATION_WARNING": "Las anotaciones pueden estar sujetas a revisión por parte de los clientes según lo dispuesto por las regulaciones de privacidad. No incluyas información personal u opiniones dentro del campo de anotaciones.",
    "ATTACHMENTS_RESTRICTED": "Los archivos adjuntos están restringidos.",
    "ATTACHMENTS_TITLE": "Anexos",
    "ATTACHMENT_DELETION_FAILED": "Error en la eliminación de archivos adjuntos, inténtalo de nuevo más tarde.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Eliminación de archivos adjuntos exitosa.",
    "AUTHENTICATING": "Autenticación iniciada. Esto puede abrir una nueva ventana.",
    "AUTHENTICATION_FAILURE": "Ocurrió un problema al autenticar.",
    "AUTHENTICATION_MESSAGE": "Buscando nuevas credenciales. Esta ventana va a cerrarse en un par de segundos.",
    "AUTO_ANNOTATION_BADGE": "Automático",
    "BACK": "Atrás",
    "BCC_EMAIL_ADDRESS": "CCO",
    "BLURBS_USED_TITLE": "Notas publicitarias usadas",
    "CALL_LEG": "ID de sección de llamada",
    "CALL_LEG_ID_COPIED": "ID de sección de llamada copiado.",
    "CALL_RESTRICTED": "El contenido de la convocatoria está restringido",
    "CALL_RETENTION_DELETED": "El registro pasó su periodo de retención y se eliminó.",
    "CALL_TITLE": "Habla con tu Servicio de atención al cliente de Amazon",
    "CANCEL": "Cancelar",
    "CC_EMAIL_ADDRESS": "CC",
    "CF_LARGE_NUMBER_ALERT": "Este cliente tiene un número muy grande de contactos y no todos se cargaron. Los contactos siempre se cargan de “más reciente a anterior” a partir de la fecha de finalización. Cambia la fecha de finalización para comenzar a cargar desde otro período.",
    "CHANNELS": "Channels",
    "CHAT_AGENT_CONNECTED": "{name} aceptó el chat.",
    "CHAT_CONTACT": "Contacto de chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} perdió conexión con la red.",
    "CHAT_DISCONNECT_GENERIC": "{name} ya no está conectado.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} perdió conexión con la red.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} perdió conexión con la red.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} salió de la conversación.",
    "CHAT_PARKED": "Este chat se detuvo",
    "CHAT_PARKED_NOTES": "Notas de Park",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} ya no está en espera.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} está en espera.",
    "CHAT_RESTRICTED": "El contenido del chat está Restringido.",
    "CHAT_TITLE": "Chat con el servicio de atención al cliente de Amazon",
    "CHAT_TRANSFER_NOTES": "Transferir notas",
    "CHILD_WORK_REQUESTS": "Solicitudes de trabajo creadas a partir de este contacto",
    "CHOOSE_OPTIONS": "Seleccionar opciones",
    "CLOSE": "Cerrar",
    "CLOSE_ALERT": "Cerrar aviso",
    "CLOSE_MODAL_BUTTON_TEXT": "Cerrar",
    "COLLAPSED": "Colapsó",
    "CONFIRM": "Confirmar",
    "CONTACTS": "Contactos",
    "CONTACT_COLLAPSED": "Datos de contacto colapsados.",
    "CONTACT_CREATION_FAILURE": "Falló la creación de nuevo contacto",
    "CONTACT_EXPANDED": "Detalles de contacto ampliados.",
    "CONTACT_HISTORY": "Historial de contacto",
    "CONTACT_ID": "ID de contacto",
    "CONTACT_ID_COPIED": "ID de contacto copiado.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contacto",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalaciones",
    "CONTACT_REASSIGNED_TO_AGENT": "El contacto ha sido reasignado al agente.",
    "CONTACT_SELECT_CHECKBOX": "Casilla de verificación para seleccionar un contacto bloqueado",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalada",
    "CONTACT_TICKET": "Ticket de contacto",
    "CONTACT_TICKET_CONTENT": "Contenido del ticket",
    "CONTACT_TICKET_CREATE": "Crear ticket de contacto",
    "CONTACT_TICKET_CREATION_FAILURE": "No se pudo crear el ticket de contacto",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket de contacto creado",
    "CONTACT_TICKET_RESOLVER_GROUP": "Grupo de solucionador",
    "CONTACT_TICKET_SEVERITY": "Gravedad del ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID de la carpeta SIM",
    "CONTACT_TICKET_SUBJECT": "Asunto del ticket",
    "CONTACT_TIMELINE": "Línea del tiempo del contacto",
    "CONTACT_TYPE": "Tipo de contacto",
    "CONTACT_WAS_TRANSFERRED": "El Contacto se ha transferido.",
    "CONTENT": "Contenido",
    "CONTENT_RESTRICTED": "El contenido está restringido.",
    "COPY_AGENT_SELECTED_ISSUE": "Copiar asunto seleccionado por agente: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copiar ID de contacto de Amazon Connect",
    "COPY_CALL_LEG_ID": "Copiar ID de sección de llamada: {value}",
    "COPY_CONTACT_ID": "Copiar ID de contacto: {value}",
    "COPY_CUSTOMER_ID": "Copiar ID de cliente",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copiar asunto seleccionado por el cliente: {value}",
    "COPY_FAILURE": "No se logró copiar.",
    "COPY_ID": "Copiar ID",
    "COPY_LINK": "Copiar enlace de carga de imagen",
    "COPY_ORDER_ID": "Copiar orden de ID: {value}.",
    "CREATE_CASE": "Crear Caso",
    "CREATE_FOLLOW_UP": "Crear seguimiento",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "El seguimiento se creo correctamente, pero no pudo agregar la anotación correspondiente. Vuelve a intentar agregar la anotación manualmente.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Especifica el método de contacto que prefiere el cliente.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "No se puede dar un seguimiento a una fecha y hora de vencimiento en el pasado.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Especifica la fecha y hora en que vence la acción de seguimiento.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Zona horaria",
    "CREATE_FOLLOW_UP_FAILURE": "No se pudo crear el seguimiento.",
    "CREATE_FOLLOW_UP_INVALID": "El seguimiento no es válido. Asegúrate de que no haya datos restringidos.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Ingresa la acción de seguimiento.",
    "CREATE_FOLLOW_UP_SUCCESS": "Seguimiento creado correctamente.",
    "CREATE_WORK_REQUEST": "Crear solicitud de trabajo",
    "CROSS_MARKETPLACE_QUEUES": "Filas entre sitios web de Amazon",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "No se pudo transferir el Contacto porque ya está resuelto.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "No se pudo realizar la operación debido a que el estado del contacto no es válido.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "No se pudo realizar la operación porque no se encontró el contacto",
    "CSALT_ERROR_INTERNAL_ERROR": "Error al bloquear el contacto",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Error al bloquear un contacto debido a argumentos no válidos",
    "CSALT_ERROR_INVALID_INPUT": "No se pudo realizar la operación debido a que las entradas no son válidas.",
    "CSALT_ERROR_INVALID_QUEUE": "No se pudo transferir el Contacto porque la fila seleccionada no es válida.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "No se pudo realizar la operación porque el contacto está bloqueado para otro agente.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Error al bloquear el contacto debido a error de dependencia",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "No se pudo transferir el Contacto porque la fila no se encontró en el servicio de soporte.",
    "CSALT_ERROR_UNKNOWN": "No se pudo realizar la operación debido a un error inesperado.",
    "CURRENT_CONTACT": "Contacto actual",
    "CUSTOMER": "Cliente",
    "CUSTOMER_FOLLOWUPS": "Seguimientos para cliente",
    "CUSTOMER_FOLLOWUPS_FAILURE": "No se pudo buscar seguimientos para el cliente",
    "CUSTOMER_ID_COPIED": "ID de cliente copiado",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "El contenido del cliente está Restringido. (No hay contactos bloqueados.)",
    "CUSTOMER_SELECTED_ISSUE": "Asunto seleccionado por cliente",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Asunto seleccionado por el cliente copiado.",
    "CUSTOMER_TIMEZONE": "Las marcas de tiempo se muestran en la zona horaria del cliente",
    "DATE": "Fecha",
    "DATE_PICKER_NEXT_MONTH": "El siguiente mes",
    "DATE_PICKER_PREVIOUS_MONTH": "El mes anterior",
    "DATE_PICKER_TODAY": "Hoy",
    "DECODING_AUDIO": "Decodificación de audio",
    "DELETE_ATTACHMENTS": "Eliminar archivos adjuntos de contacto",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Eliminar archivos adjuntos",
    "DELIVERY_STATUS": "Estado de entrega",
    "DISABLE_CONTENT_FILTER": "Deshabilitar el filtro de contenido",
    "DOWNLOAD": "Descargar",
    "DURATION": "Duración",
    "EMAIL": "Email",
    "EMAIL_ADDRESS_NOT_ALLOWED": "No se permiten direcciones de correo electrónico del destinatario.",
    "EMAIL_BODY": "Cuerpo del correo electrónico",
    "EMAIL_CONTACT": "Contacto de correo electrónico.",
    "EMAIL_HEADER": "Encabezado",
    "EMAIL_QUEUE_TITLE": "Email en la fila",
    "EMAIL_RESTRICTED": "El contenido del correo electrónico está Restringido.",
    "EMAIL_SENDING_FAILURE": "Error al enviar correo electrónico saliente.",
    "EMAIL_SUBJECT": "Asunto del correo electrónico",
    "EMAIL_TITLE": "Correo electrónico con atención al cliente de Amazon",
    "END": "Finalizar",
    "END_DATE": "Fecha de finalización",
    "ERROR": "Error",
    "ERROR_FAILED_TO_LOAD_DATA": "No se pudieron cargar los datos.",
    "ESCALATION_CONTACT_HANDLING": "Manejo de contacto de escalación",
    "ESCALATION_TAB_LEAD_LOGIN": "Inicio de sesión principal:{agentLogin}",
    "EXPANDED": "Ampliado",
    "FAILED_TO_CREATE_WORK_REQUEST": "Ocurrió un error al crear la solicitud de trabajo",
    "FAILED_TO_FETCH_SYSKA": "Error al buscar alertas SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "No se pudo reasignar el contacto al agente.",
    "FETCHED_NUMBER_CONTACTS": "{number} contactos buscados.",
    "FILTER": "Filtrar",
    "FINDER": "Buscador",
    "FOLLOW_UPS_COLLAPSED": "Seguimientos colapsados",
    "FOLLOW_UPS_EXPANDED": "Seguimientos ampliados",
    "FOLLOW_UP_ALERT": "tiene un seguimiento que vence en {dueDate} creado por {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Método de contacto",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "El método de contacto está Restringido.",
    "FOLLOW_UP_DUE": "Seguimiento pendiente",
    "FOLLOW_UP_DUE_DATE": "Fecha límite",
    "FOLLOW_UP_NOTE": "Nota",
    "FOLLOW_UP_NO_CONTACT_METHOD": "No hay método de contacto",
    "FOLLOW_UP_NO_NOTE": "La nota está vacía",
    "FOLLOW_UP_NO_TICKET": "No hay ticket",
    "FOLLOW_UP_OWNER": "Propietario",
    "FOLLOW_UP_STATUS": "Estado",
    "FOLLOW_UP_STATUS_OPEN": "Abrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Resuelto",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Seguimientos",
    "FROM": "Del",
    "GENERATE_LINK": "Generar enlace",
    "HAS_OVERRIDE_TRY_AGAIN": "Anulación del filtro presente. Inténtalo de nuevo.",
    "HISTORY": "Historial",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "enlace adjunto de imagen compartido con el cliente",
    "INPUT_CHARACTERS_REMAINING": "Máximo {char_max} caracteres ({char_remain} restantes)",
    "INSERTED": "INSERTADO por",
    "INSERTED_BY_AGENT": "INSERTADO por {agent}",
    "INTERNAL_ERROR": "Error interno",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Se encontró un error interno. Si el error persiste, crea un ticket explicando el problema.",
    "INVALID_CONTACT": "El contacto no es válido.",
    "INVALID_DATA_MATCH": "El sistema hizo una clasificación de datos no válida.",
    "INVALID_TIMESTAMP": "La marca de tiempo no es válida.",
    "IN_MARKETPLACE_QUEUES": "En las filas de un sitio web de Amazon",
    "ISSUE": "Problema",
    "ISSUE_CODE": "Código de procesamiento",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Ya se abordó y resolvió en un contacto anterior",
    "ISSUE_CODE_DESCRIPTION": "Selecciona el motivo para convertir los contactos seleccionados en duplicados",
    "ISSUE_CODE_EXACTLY_SAME": "Exactamente lo mismo que un contacto anterior",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Manejado y resuelto por otro agente de servicio al cliente por chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Manejado y resuelto por otro agente de servicio al cliente por correo electrónico",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Manejado y resuelto por otro agente de servicio al cliente por teléfono",
    "ITEMS_CONTACTED_ABOUT": "Artículos contactados acerca de",
    "I_UNDERSTAND": "Comprendo",
    "KNOWN_ISSUES": "Problemas conocidos",
    "LEARN_MORE": "Más información",
    "LINK_COPIED": "Se copió el enlace",
    "LOADING": "Cargando datos.",
    "LOADING_PREVIEW_TEXT": "Cargando vista previa",
    "LOADING_RECORDING": "Cargando grabación",
    "LOAD_MORE": "Cargar más",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Cargar más anotaciones.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Cargar más contactos para este cliente.",
    "LOCKED": "Bloqueado",
    "LOCKED_BY": "Bloqueado por",
    "LOCKED_BY_AGENT": "BLOQUEADO por {agent}",
    "LOCK_CONTACT": "Bloquear",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Error al bloquear el contacto, usuario no autorizado",
    "LOCK_CONTACT_SUCCESSFUL": "Contacto bloqueado para",
    "MISSING_ORIGINAL_CONTACT_ID": "Ingrese un ID de contacto original válido",
    "MODAL_NO_BUTTON_TEXT": "No",
    "MODAL_YES_BUTTON_TEXT": "Sí",
    "NEXT": "Siguiente",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "No hay asunto seleccionado por agente.",
    "NO_ANNOTATED_BY": "Anotado por usuario desconocido.",
    "NO_ANNOTATIONS": "No hay anotaciones disponibles.",
    "NO_ATTACHMENTS": "No hay archivos adjuntos.",
    "NO_CHAT": "El chat está vacío.",
    "NO_CHILD_WORK_REQUESTS": "No se crearon solicitudes de trabajo a partir de este contacto",
    "NO_CONTACT": "No hay ningún contacto disponible",
    "NO_CONTACTS_SELECTED": "Selecciona los contactos para procesarlos como correo no deseado",
    "NO_CONTENT": "Sin contenido",
    "NO_CUSTOMER_SELECTED_ISSUE": "No hay ningún asunto seleccionado por el cliente.",
    "NO_DELIVERY_STATUS": "Sin estado de entrega",
    "NO_EMAIL_BODY": "El campo Correo electrónico está vacío.",
    "NO_EMAIL_HEADER": "Sin encabezado de correo electrónico.",
    "NO_EMAIL_QUEUE": "No hay un email asociado a este contacto en la fila.",
    "NO_ITEMS_CONTACTED_ABOUT": "No hay artículos sobre los que se haya contactado.",
    "NO_RESULTS": "No se encontraron resultados.",
    "NO_SCREEN_POP": "No hay información de pantalla emergente",
    "NO_SENT_FROM": "No se envía desde la dirección.",
    "NO_SENT_TO": "No se envió a la dirección",
    "NO_SUBJECT": "La línea de asunto está vacía.",
    "NO_SYSKA_ALERTS": "No hay alertas SYSKA",
    "NO_USED_BLURBS": "No se utilizan notas publicitarias para este contacto.",
    "NO_VIEW": "No hay contacto ni cliente que ver.",
    "NO_WORK_CATEGORY": "No hay una categoría de trabajo asociada a este contacto.",
    "NUMBER_OF_CONTACTS": "Contactos cargados",
    "OLDEST_LOADED_CONTACT": "Contacto más antiguo cargado",
    "OPEN": "Abrir",
    "OPENS_IN_A_NEW_TAB": "Se abre en una nueva pestaña",
    "ORDER_ID_COPIED": "Orden de ID copiada.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Ingresa el ID de contacto original",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Cuadro de texto para ingresar el ID de contacto original",
    "OTHER": "Otro",
    "OUTBOUND_EMAIL_BCC": "y CCO -",
    "OUTBOUND_EMAIL_CC": "con CC -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "El contenido de la notificación colapsó.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Se amplió el contenido de la notificación.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Se alcanzó el número máximo de resultados. Reduce el intervalo de tiempo e inténtalo de nuevo.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "El proceso actual requiere contenido de PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Coincidencia no válida como número de teléfono, dirección de email u otra PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Coincidencia no válida como tarjeta de crédito o IBAN.",
    "OVERRIDE_REASON_PROMPT": "Se supone que las anotaciones no contienen datos de PII del cliente, como números de teléfono y direcciones de email. Nuestro sistema automatizado intenta hacer cumplir eso, pero a veces no distingue la diferencia entre PII y otros ID. Proporcione la razón por la que se necesita una anulación.",
    "PAPYRUS_SESSION_EXPLANATION": "Especifica tu tipo de sesión de Papyrus para que podamos aplicar las restricciones de seguridad adecuadas y optimizar la UX de acuerdo a tus necesidades.",
    "PAPYRUS_SESSION_TYPE": "Tipo de sesión de Papyrus",
    "PAUSE": "Pausar",
    "PHONE_CONTACT": "Contacto telefónico.",
    "PLAY": "Reproducir",
    "PLAYBACK_SPEED": "Velocidad de reproducción",
    "POSTAL_MAIL_TITLE": "Correo postal con servicio al cliente de Amazon",
    "PREPARING_RECORDING": "Preparando grabación",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "No se pudo generar la vista previa del contacto",
    "PREVIOUS": "Anterior",
    "PRE_TRANSLATED_REPLY_EMAIL": "Email de respuesta pretraducido",
    "PRE_TRANSLATION_REPLY_TEXT": "Respuesta del agente de servicio al cliente antes de la traducción de Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Procesar acciones",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Procesar como duplicado",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Procesar como correo no deseado",
    "PROCESS_CONTACT_AS_DUPLICATE": "Corregir los contactos seleccionados como duplicados",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "¿Estás seguro de que deseas procesar los contactos seleccionados como correo no deseado?",
    "PROCESS_DUPLICATE_FAILURE": "Error al corregir los contactos seleccionados como duplicados",
    "PROCESS_DUPLICATE_SUCCESS": "Se corrigieron con éxito los contactos seleccionados como duplicados",
    "PROCESS_SPAM_FAILURE": "No se pudieron transferir los contactos seleccionados al correo no deseado",
    "PROCESS_SPAM_SUCCESS": "Los contactos seleccionados se transfirieron correctamente al correo no deseado",
    "PROVIDE_ADDITIONAL_INFORMATION": "Proporciona información adicional relevante para este contacto",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Se depuró la información confidencial del contacto:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Agregar palabra a la lista para depurar",
    "PURGE_BUTTON_TEXT": "Depurar",
    "PURGE_CONFIRMATION_CHECK": "Verifiqué los campos anteriores y confirmé que el texto efectivamente contiene información confidencial.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Confirmo que el archivo adjunto realmente contiene información confidencial del cliente.",
    "PURGE_CONFIRMATION_UNCHECKED": "Confirme casilla de verificación.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "No se pudo depurar el contacto",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Se depuró correctamente la información del contacto",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} no es motivo válido para depurar",
    "PURGE_REASON_FINANCIAL_INFO": "Información financiera",
    "PURGE_REASON_OTHER": "Otra información confidencial",
    "PURGE_REASON_PII": "Información de identificación personal",
    "PURGE_REASON_PLACEHOLDER": "Ingresar motivo de purga",
    "PURGE_REASON_TEXT": "Motivo para depurar",
    "PURGE_REMOVE_LABEL": "Eliminar",
    "PURGE_SELECTOR_PLACEHOLDER": "Selecciona el motivo para depurar la información confidencial",
    "PURGE_SENSITIVE_INFO": "Depurar información confidencial",
    "PURGE_WORD_NOT_FOUND": "No se encuentra la palabra en el texto del contacto",
    "PURPOSE_AUDITING": "Auditoría para el control de calidad",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Flujo de trabajo de Back Office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Esta es la sesión para obtener datos de contacto para los flujos de trabajo de Back Office. Por ejemplo, archivos adjuntos para incluir en tickets o SIM.",
    "PURPOSE_COACHING": "Revisión de contacto de tutor",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Investigación de cumplimiento",
    "PURPOSE_CURATION": "Curación de contacto",
    "PURPOSE_CURATION_DESCRIPTION": "Esta es la sesión de curación de contacto C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Esta es la sesión para auditar contactos y evaluar su calidad.",
    "PURPOSE_DESCRIPTION_COACHING": "Esta es la sesión para revisar los contactos de los agentes de los que eres tutor en el Portal de desempeño.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Esta es la sesión para las investigaciones de monitoreo de actividad sospechosa.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Esta es la sesión para el manejo de contactos de escalación. Esto permite acceder a los datos de contacto de cualquier cliente.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Esta es la sesión para el manejo de contactos en vivo.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "El propósito es eliminar información confidencial de un contacto",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Esta es la sesión para revisar la precisión del modelo de aprendizaje automático.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Esta es la sesión para la revisión de tus propios contactos anteriores.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Esta es la sesión de propósito general predeterminada.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Manejo de contacto de escalación",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Depurar información confidencial",
    "REASON_FOR_PURGE": "Motivo para depurar",
    "REASON_FOR_PURGE_DESCRIPTION": "Motivo para purgar archivos adjuntos",
    "REASON_FOR_PURGE_MISSING": "Ingresa el motivo para eliminar los archivos adjuntos.",
    "REASSIGN": "REAFIRMADO por",
    "REASSIGNED_BY_AGENT": "REASIGNADO por {agent}",
    "REASSIGN_TO_AGENT": "Reasignar al agente",
    "RECORDING": "Grabación",
    "RELOADING_CONTENT": "Volver a cargar contenido.",
    "RELOAD_LIST": "Volver a cargar lista",
    "REPLIED": "RESPONDIDO por",
    "REPLIED_BY_AGENT": "RESPONDIDO por {agent}",
    "REPLY_BY": "Responder antes del",
    "REPLY_BY_EMAIL": "Responder por correo electrónico",
    "REPLY_BY_PHONE": "Responder por teléfono",
    "REPLY_RESTRICTED": "El contenido de la respuesta está Restringido.",
    "REQUEST": "Solicitar",
    "REQUEST_ACCESS_INSTRUCTIONS": "Proporciona la razón por la que se requiere acceso a contenido restringido. (Estas solicitudes se supervisan y el abuso puede tener consecuencias graves).",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Solicitar acceso a contenido filtrado",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Solicitar acceso a contenido restringido",
    "REQUEST_NOT_GRANTED": "La solicitud no fue concedida.",
    "REQUEST_OVERRIDE": "Solicitar anulación",
    "RESEND": "Reenviar",
    "RESEND_FAILED": "No se pudo reenviar el correo electrónico.",
    "RESEND_SUCCESS": "El correo electrónico se reenvió correctamente.",
    "RESEND_WAIT_WARNING": "Debes esperar {wait_mins} minutos para volver a reenviarlo.",
    "RESEND_WARNING": "¿Estás seguro de que quieres reenviar el correo electrónico al cliente?",
    "RESET": "Restablecer",
    "RESOLVED": "Resuelto",
    "RESOLVED_BY": "Resuelto por",
    "RESOLVED_BY_AGENT": "RESUELTO por {agent}",
    "RESTRICTED_DATA_MATCH": "{match} tipo de coincidencia {type}",
    "RESTRICTED_INFORMATION": "INFORMACIÓN RESTRINGIDA",
    "RETRY": "Volver a intentar",
    "ROLE": "Función",
    "SCREEN_POP_DESCRIPTION_TITLE": "Descripción",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "No",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Sí",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Mostrado al agente",
    "SCREEN_POP_TITLE": "Información de pantalla emergente",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Se mostró al agente la pantalla emergente autenticada, y se utilizó.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Se mostró al agente pantalla emergente de autenticación.",
    "SCREEN_POP_TYPE_CALLER_ID": "La pantalla emergente de identificación de llamadas se mostró al agente, y se utilizó.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Se mostró al agente pantalla emergente de identificador de llamadas.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Se mostró un pantalla emergente sin autenticar, y se utilizó.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Se mostró al agente una pantalla emergente no autenticada.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "El cliente es desconocido; no se proporciona ninguna pantalla emergente.",
    "SCREEN_POP_TYPE_OTHER": "Otro",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "No",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Sí",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Usado por el agente",
    "SDS_TOOLS": "Herramientas de soporte para envíos y entregas",
    "SEARCH": "Busca",
    "SELECTED_ROLE": "Función seleccionada",
    "SELECTED_VIEW": "Vista seleccionada",
    "SELECT_CHANNELS": "Seleccionar canales",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Para seleccionar el código de procesamiento para marcar los contactos seleccionados como duplicados",
    "SELECT_TRANSFER_DESTINATION": "Seleccionar destino de transferencia",
    "SELECT_WORK_REQUEST_QUEUE": "Seleccionar fila de la solicitud de trabajo",
    "SENDER_EMAIL_ADDRESS": "Remitente",
    "SENDING_EMAIL": "Enviando correo electrónico",
    "SEND_EMAIL": "Enviar correo electrónico",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "¿Estás seguro de que deseas enviar el enlace de carga de imagen al cliente?",
    "SEND_OUTBOUND_EMAIL": "Enviar correo electrónico saliente",
    "SEND_OUTBOUND_EMAIL_WARNING": "¿Estás seguro de que deseas enviar correo electrónico saliente a",
    "SENT_FROM": "Enviado desde",
    "SENT_OUTBOUND_EMAIL": "Se envió un correo electrónico saliente. Se creó un nuevo contacto",
    "SENT_TO": "Enviado a",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal de transportista",
    "SKILL": "Habilidad",
    "SKIP_SILENCES": "Saltar silencios",
    "SMS": "SMS",
    "SMS_LINK": "Enlace SMS",
    "SOURCE_TEXT": "Texto fuente",
    "SP_BOTTOM": "Inferior",
    "SP_POSITION": "Posición del panel dividido",
    "SP_PREFERENCES": "Preferencias de panel dividido",
    "SP_RESIZE": "Redimensionar panel dividido",
    "SP_SIDE": "Lado",
    "START": "Empezar",
    "STATE_TRANSITIONS": "Transiciones del estado",
    "STATUS": "Estado",
    "SUBJECT": "Asunto",
    "SYSKA_ALERTS": "Alertas SYSKA",
    "TEXT_TO_PURGE": "Texto para depurar",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Las cookies de terceros deben estar habilitadas.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "No se permiten datos de PII del cliente como números de teléfono y direcciones de correo electrónico mientras se crea un ticket. Nuestro sistema automatizado intenta hacer cumplir eso, pero a veces no distingue la diferencia entre PII y otros ID. Proporcione la razón por la que se necesita invalidar.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Tiempo de espera agotado: fallo al cargar la grabación",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Utiliza el formato de 24 horas.",
    "TO": "Para",
    "TO_EMAIL_ADDRESS": "Para",
    "TRANSFER": "Transferir",
    "TRANSFERRED": "TRANSFERIDO por",
    "TRANSFERRED_BY_AGENT": "TRANSFERIDO por {agent}",
    "TRANSFERRED_CHAT_TITLE": "Se transfirió el chat con el servicio de atención al cliente de Amazon",
    "TRANSFERRING": "Transferir...",
    "TRANSFER_CONTACT": "Transferir contacto",
    "TRANSFER_CONTACT_COMMENTS": "Anotación opcional",
    "TRANSFER_FAILED": "La transferencia falló",
    "TRANSFER_FOLLOW_UP": "Transferir seguimiento",
    "TRANSFER_FOLLOW_UP_FAILED": "No se pudo transferir el seguimiento.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "No se pudo transferir el seguimiento debido a que el inicio de sesión del nuevo propietario no es válido.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "No tienes permiso para transferir este seguimiento.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Ingresa el nombre de inicio de sesión del nuevo propietario. Ejemplo: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transferir el seguimiento anterior a:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Seguimiento transferido correctamente.",
    "TRANSLATED_TEXT": "Texto traducido",
    "TRANSLATION": "Traducción",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} no es un nombre de fila válido.",
    "TYPE_AGENT_LOGIN": "Tipo de inicio de sesión de agente",
    "TYPE_EMAIL_ADDRESS": "Ingresa dirección de correo electrónico",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Ingresa varias direcciones de correo electrónico separadas por comas",
    "TYPE_QUEUE_NAME": "Tipo nombre de fila",
    "UNKNOWN_CONTACT_TYPE": "Tipo de contacto desconocido.",
    "UNLOCKED": "Desbloqueado",
    "UNRESOLVED": "No resuelto",
    "URGENT_SYSKA_ALERTS": "Alerta SYSKA urgente",
    "USE": "Usar",
    "USER_TIMEZONE": "Las marcas de tiempo se muestran en tu zona horaria local",
    "USE_CONTACT_FINDER": "Utilizar buscador de contactos",
    "VIEW": "Vista",
    "WARNING": "Advertencia",
    "WELCOME_MESSAGE": "¡Bienvenido al sitio web estático de Papyrus!",
    "WHAT_IS_PAPYRUS": "¿Qué es Papyrus?",
    "WITH_THE_ANNOTATION": "con la anotación",
    "WORK_CATEGORY_TITLE": "Categoría de trabajo",
    "WORK_REQUEST": "Solicitud de trabajo",
    "WORK_REQUEST_CONTENT": "Contenido de la solicitud de trabajo",
    "WORK_REQUEST_CREATED": "Solicitud de trabajo creada",
    "WORK_REQUEST_FOR_CONTACT": "Solicitud de trabajo para contacto",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "No se permiten datos de PII del cliente como números de teléfono y direcciones de correo electrónico mientras se crea una solicitud de trabajo. Nuestro sistema automatizado intenta hacer cumplir eso, pero a veces no distingue la diferencia entre PII y otros ID. Proporcione la razón por la que se necesita invalidar.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Estás a punto de transferir a la fila",
    "copy_of_cs_papyrus_type_agent_login": "Tipo de inicio de sesión de agente"
}