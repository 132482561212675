import React, {useContext, useEffect, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {i18n} from "../../i18n/IntlManager";
import Autosuggest, {AutosuggestProps} from "@amzn/awsui-components-react/polaris/autosuggest";
import {InputProps} from "@amzn/awsui-components-react/polaris/input";
import {Contact, ContactTransferDestination, ContactTransferDestinations} from "../Contact.types";
import {
    LogLevel,
    Match,
    MatchRestrictedDataResponse,
    MetricType,
} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import {AddText, addTextAndSubmit, AddTextStatus} from "../AddText";
import {PushNotificationFunction} from "../ContactActions";
import {AllHtmlEntities} from 'html-entities';
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {CreateWorkRequestOverrideRequestForm} from "../../authorizer/CreateWorkRequestOverrideRequestForm";
import Translation from "../../i18n/Translate";
import Container from "@amzn/awsui-components-react/polaris/container";
import {populateOrderIds} from "../../util/OrderIdsStringBuilder";

interface WorkRequestFormProps {
    contact: Contact;
    marketplaceId: string;
    deleteMe: () => void;
    pushNotification: PushNotificationFunction;
}

export const WorkRequestForm: React.FC<WorkRequestFormProps> = ({contact, marketplaceId, deleteMe, pushNotification}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingQueues, setLoadingQueues] = useState<boolean>(false);
    const [options, setOptions] = useState<AutosuggestProps.Option[]>([]);
    const [selectedQueueName, setSelectedQueueName] = useState<string>("");
    const [contactTransferDestinations, setContactTransferDestinations] = useState<ContactTransferDestinations|null>(null);
    const [contactTransferDestination, setContactTransferDestination] = useState<ContactTransferDestination|undefined>(undefined);
    const [subjectValue, setSubjectValue] = useState<string>("");
    const [messageBody, setMessageBody] = useState<string>("");
    const { reloadSingleContact, authorizerJwtTokens, callPhoenix } = useContext(IssueManagerContext);

    const [subjectTextStatus, setSubjectTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [subjectRestrictedDataMatchList, setSubjectRestrictedDataMatchList] = useState<Match[]>([]);

    const [addTextStatus, setAddTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [restrictedDataMatchList, setRestrictedDataMatchList] = useState<Match[]>([]);
    const [showOverrideRequestForm, setShowOverrideRequestFrom] = useState<boolean>(false);
    const filteredToken = authorizerJwtTokens.find(token => token.getAuth().find(item => item.e === 'CREATE_WORK_REQUEST_WITHOUT_FILTER'));
    const hasFilterOverride: boolean = filteredToken !== undefined;

    function toOption(destination: ContactTransferDestination): AutosuggestProps.Option {
        return {label: destination.emailQueue.name, value: destination.emailQueue.name};
    }

    function onChange(detail: InputProps.ChangeDetail): void {
        const destination: ContactTransferDestination|undefined = contactTransferDestinations?.inMarketplaceTransferDestinationList
            .find(t => t.emailQueue.name === detail.value);
        setContactTransferDestination(destination);
        setSelectedQueueName(detail.value);
    }

    function getEnteredTextLabel(): string {
        if (contactTransferDestination === undefined) {
            return i18n('TYPED_QUEUE_NAME_IS_INVALID', {values: {queue: selectedQueueName}});
        } else {
            return i18n('USE') + ": " + selectedQueueName;
        }
    }

    const query: string = `query {` +
        `  contactTransferDestinations(marketplaceId: "${marketplaceId}") {` +
        `    inMarketplaceTransferDestinationList {` +
        `      emailQueue { ` +
        `        id ` +
        `        name ` +
        `      }` +
        `    }` +
        `  }` +
        `}`;

    useEffect(() => {
        setLoadingQueues(true);

        setMessageBody(contact.orderIds.length ? populateOrderIds(contact.orderIds) : "");

        callPhoenix(query)
            .then(cr => {
                setLoadingQueues(false);
                if (cr.callResult?.resultJson) {
                    const result = JSON.parse(cr.callResult.resultJson);
                    const contactTransferDestinations: ContactTransferDestinations = result.data.contactTransferDestinations;
                    setContactTransferDestinations(contactTransferDestinations);
                    const options: AutosuggestProps.Option[] = contactTransferDestinations.inMarketplaceTransferDestinationList.map(toOption);
                    setOptions(options);
                }
            })
            .catch(() => {
                Phoenix.getInstance().log(LogLevel.ERROR, `Failed to get contact transfer destinations for contact ID ${contact.contactId}`);
                setLoadingQueues(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const stringifiedMessageBody = JSON.stringify(AllHtmlEntities.encode(messageBody));
    const mutation = `mutation {
    createWorkRequestContact(input: {
        originalContactId: "${contact.contactId}",
        queueName: "${selectedQueueName}",
        marketplaceId: "${marketplaceId}",
        subjectEncrypted: "${subjectValue}",
        messageEncrypted: ${stringifiedMessageBody}
    }) {
        createdContactId
        error {
            type
            errorMessageDescriptor {
                stringId
            }
        }
    }
}`;

    async function onClickCreateWorkRequest(): Promise<void> {
        const response: MatchRestrictedDataResponse =
            await Phoenix.getInstance().matchRestrictedData([subjectValue, messageBody]);

        const subjectMatches: Match[] = response.matches[0];
        const messageBodyMatches: Match[] = response.matches[1];
        if(!hasFilterOverride){
            if (subjectMatches.length > 0) {
                setSubjectTextStatus(AddTextStatus.ERROR);
                setSubjectRestrictedDataMatchList(subjectMatches);
            }
            if (messageBodyMatches.length > 0) {
                setAddTextStatus(AddTextStatus.ERROR);
                setRestrictedDataMatchList(messageBodyMatches);
            }
            if (subjectMatches.length === 0 && messageBodyMatches.length === 0) {
                await createWorkRequestContact();
            }
        }
        else{
            await createWorkRequestContact();
        }
        setIsLoading(false);
    }

    async function onClickCreateWorkRequestFailure(): Promise<void> {
        setAddTextStatus(AddTextStatus.FAIL);
        setIsLoading(false);
    }

    async function createWorkRequestContact(): Promise<void> {
        setRestrictedDataMatchList([]);
        let success = false;
        try {
            const response = await callPhoenix(mutation);
            if (response.httpStatus === 200) {
                let contactId;
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data.createWorkRequestContact.createdContactId) {
                        contactId = parsed.data.createWorkRequestContact.createdContactId;
                    }
                }
                pushNotification((<div>{i18n('WORK_REQUEST_CREATED')}{contactId ? " - " + contactId : null}</div>), 'success');
                await reloadSingleContact(contact.contactId);
                success = true;
                setIsLoading(false);
                deleteMe();
            }
            else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to create Work Request: " + response.error);
                pushNotification((<div>{i18n('FAILED_TO_CREATE_WORK_REQUEST')}</div>), 'error');
                setIsLoading(false);
            }
        } catch(error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to create Work Request: " + error);
            pushNotification((<div>{i18n('FAILED_TO_CREATE_WORK_REQUEST') + ": " + error}</div>), 'error');
            setIsLoading(false);
        } finally {
            Phoenix.getInstance().addMetric("createWorkRequest.SUCCESS",
                success ? 1 : 0, MetricType.COUNT);
        }
    }

    function cancel(): void {
        deleteMe();
    }

    const createWorkRequestDisabled = contactTransferDestination === undefined ||
        subjectValue.length === 0 || messageBody.length === 0 || isLoading;

    return (
    <Container header={<h4><Translation stringId={"CREATE_WORK_REQUEST"}/></h4>}>
        <Form actions=
            {<SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" disabled={isLoading} onClick={(): void => cancel()}>{i18n('CANCEL')}</Button>
                {(addTextStatus === AddTextStatus.ERROR || subjectTextStatus === AddTextStatus.ERROR) ?
                    <Button onClick={(): void => setShowOverrideRequestFrom(true)} >
                        {i18n('REQUEST_OVERRIDE')}
                    </Button> : null }
                    <Button
                        disabled={createWorkRequestDisabled}
                        loading={isLoading}
                        variant="primary"
                        formAction="none"
                        onClick={(): void => {
                            setIsLoading(true);
                            addTextAndSubmit(messageBody,
                                contact.contactId,
                                authorizerJwtTokens,
                                (matches) => {
                                    setAddTextStatus(AddTextStatus.ERROR);
                                    setRestrictedDataMatchList(matches);
                                    setIsLoading(false); },
                                () => onClickCreateWorkRequest(),
                                () => onClickCreateWorkRequestFailure(),
                            );
                        }}
                        ariaLabel={i18n('CREATE_WORK_REQUEST')}>
                        <Translation stringId={"CREATE_WORK_REQUEST"}/>
                    </Button>
            </SpaceBetween>}>

            <FormField label={loadingQueues ? i18n('LOADING') : i18n('SELECT_WORK_REQUEST_QUEUE')}>
                <Autosuggest
                    disabled={isLoading}
                    placeholder={i18n('TYPE_QUEUE_NAME')}
                    options={options}
                    onChange={({detail}): void => onChange(detail)}
                    value={selectedQueueName}
                    virtualScroll={true}
                    enteredTextLabel={(): string => getEnteredTextLabel()}/>
            </FormField>

            <FormField label={i18n('SUBJECT')}>
                <AddText rows={1}
                         disabled={isLoading}
                         newText={subjectValue}
                         setNewText={setSubjectValue}
                         textAriaLabel={i18n('SUBJECT')}
                         addTextStatus={subjectTextStatus}
                         setAddTextStatus={setSubjectTextStatus}
                         restrictedDataMatchList={subjectRestrictedDataMatchList}/>
            </FormField>

            <FormField label={i18n('WORK_REQUEST_CONTENT')}>
                <AddText rows={10}
                         disabled={isLoading}
                         newText={messageBody}
                         setNewText={setMessageBody}
                         textAriaLabel={i18n('WORK_REQUEST_CONTENT')}
                         addTextStatus={addTextStatus}
                         setAddTextStatus={setAddTextStatus}
                         restrictedDataMatchList={restrictedDataMatchList}/>
            </FormField>
             {hasFilterOverride ? i18n('HAS_OVERRIDE_TRY_AGAIN') : null}
        </Form>
        {showOverrideRequestForm ? <CreateWorkRequestOverrideRequestForm contactId={contact.contactId}
                                    clearMatchErrors={(): void => {setAddTextStatus(AddTextStatus.NONE);
                                    setSubjectTextStatus(AddTextStatus.NONE);
                                    setSubjectRestrictedDataMatchList([]);
                                    setRestrictedDataMatchList([]);}}
                                    closeModal={(): void => setShowOverrideRequestFrom(false)}/> : null}
    </Container>

    );
};