export const tr_TR = {
    "ACCESS_RESTRICTED_CONTENT": "Kısıtlanmış içeriğe erişim",
    "ACTION": "Eylem",
    "ADD": "Ekle",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Ek kontak bilgileri",
    "ADD_ANNOTATION_FAILURE": "Açıklama eklenemedi.",
    "ADD_ANNOTATION_INVALID": "Geçersiz açıklama. Lütfen açıklamada erişimi kısıtlı veri olmadığından emin olun.",
    "ADD_ANNOTATION_PLACEHOLDER": "Yeni açıklama",
    "ADD_ANNOTATION_SUCCESS": "Açıklama başarıyla eklendi.",
    "AGENT": "Temsilci",
    "AGENT_ANNOTATION_BADGE": "Temsilci",
    "AGENT_SELECTED_ISSUE": "Temsilci tarafından seçilen sorun",
    "AGENT_SELECTED_ISSUE_COPIED": "Temsilci tarafından seçilen sorun kopyalandı.",
    "AMAZON_CONNECT_CALL_INFO": "Amazon Connect Arama Bilgileri",
    "AMAZON_CONNECT_CONTACT_ID": "Amazon Connect İletişim Kimliği",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Amazon Connect İletişim Kimliği kopyalandı",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Lojistik",
    "ANNOTATED_BY": "{agentLogin} tarafından açıklama eklendi.",
    "ANNOTATIONS": "Açıklamalar",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Açıklamalar, telefon numaraları ve e-posta adresleri gibi Müşteri PII verilerini içermemelidir. Otomatik sistemimiz bunu uygulamaya çalışır, ancak bazen PII ile diğer kimlik bilgileri arasındaki farkı algılayamaz. Geçersiz kılmanın neden gerekli olduğunu belirtin.",
    "ANNOTATION_WARNING": "Ek açıklamalar, gizlilik düzenlemeleri kapsamında müşterilerin incelemesine tabi olabilir. Ek açıklamalar alanına kişisel bilgi veya görüş eklemeyin.",
    "ATTACHMENTS_RESTRICTED": "Eklere erişim kısıtlı.",
    "ATTACHMENTS_TITLE": "Ekler",
    "ATTACHMENT_DELETION_FAILED": "Eklerin silinmesi başarısız oldu, lütfen daha sonra tekrar deneyin.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Eklerin silinmesi başarılı.",
    "AUTHENTICATING": "Kimlik doğrulama başlatıldı. Bu işlem yeni bir pencere açabilir.",
    "AUTHENTICATION_FAILURE": "Kimlik doğrulanırken bir sorun oluştu.",
    "AUTHENTICATION_MESSAGE": "Yeni kimlik bilgileri alınıyor. Bu pencere birkaç saniye içinde kendi kendine kapanacaktır.",
    "AUTO_ANNOTATION_BADGE": "Otomatik",
    "BACK": "Geri",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Kullanılan hazır metinler",
    "CALL_LEG": "Arama leg ID:",
    "CALL_LEG_ID_COPIED": "Arama leg ID kopyalandı.",
    "CALL_RESTRICTED": "Arama içeriğine erişim kısıtlı.",
    "CALL_RETENTION_DELETED": "Kayıt, saklama süresini aştı ve silindi.",
    "CALL_TITLE": "Amazon Müşteri Hizmetlerini ile olan arama",
    "CANCEL": "İptal et",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Bu müşterinin çok sayıda kişisi var ve hepsi yüklenmemiş. Kişiler her zaman bitiş tarihinden itibaren “yeniden eskiye” yönde yüklenir. Farklı bir zaman noktasından yüklemeye başlamak için bitiş tarihini değiştirin.",
    "CHANNELS": "Kanallar",
    "CHAT_AGENT_CONNECTED": "{name} sohbeti kabul etti.",
    "CHAT_CONTACT": "Sohbet kontağı.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} ile ağ bağlantısı kesildi.",
    "CHAT_DISCONNECT_GENERIC": "{name} artık bağlı değil.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} ile ağ bağlantısı kesildi.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} ile ağ bağlantısı kesildi.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} sohbetten ayrıldı.",
    "CHAT_PARKED": "Bu sohbet park edildi",
    "CHAT_PARKED_NOTES": "Park notları",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} beklemeden alındı.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} beklemede.",
    "CHAT_RESTRICTED": "Sohbet içeriğine erişim kısıtlı.",
    "CHAT_TITLE": "Amazon Müşteri Hizmetleri ile sohbet etme",
    "CHAT_TRANSFER_NOTES": "Transfer notları",
    "CHILD_WORK_REQUESTS": "Bu kişiden oluşturulan iş istekleri",
    "CHOOSE_OPTIONS": "Seçenekleri belirleyin",
    "CLOSE": "Kapat",
    "CLOSE_ALERT": "Uyarıyı kapat",
    "CLOSE_MODAL_BUTTON_TEXT": "Kapat",
    "COLLAPSED": "Daraltıldı",
    "CONFIRM": "Onayla",
    "CONTACTS": "Kontaklar",
    "CONTACT_COLLAPSED": "Kontak detayları daraltıldı.",
    "CONTACT_CREATION_FAILURE": "Yeni kişi oluşturma başarısız oldu",
    "CONTACT_EXPANDED": "Kontak detayları genişletildi.",
    "CONTACT_HISTORY": "İletişim Geçmişi",
    "CONTACT_ID": "Kontak numarası",
    "CONTACT_ID_COPIED": "Kontak Numarası kopyalandı.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Kontak",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Lead Ekibine Aktarmalar",
    "CONTACT_REASSIGNED_TO_AGENT": "Kontak temsilciye yeniden atandı.",
    "CONTACT_SELECT_CHECKBOX": "Kilitli bir kontağı seçmek için onay kutusunu işaretleyin",
    "CONTACT_STATUS_ESCALATION_BADGE": "Lead Ekibine Aktarıldı",
    "CONTACT_TICKET": "Kontak Ticket'ı",
    "CONTACT_TICKET_CONTENT": "Ticket İçeriği",
    "CONTACT_TICKET_CREATE": "Kontak Ticket'ı Oluştur",
    "CONTACT_TICKET_CREATION_FAILURE": "Kontak ticket'ı oluşturulamadı",
    "CONTACT_TICKET_CREATION_SUCCESS": "Kontak ticket'ı oluşturuldu",
    "CONTACT_TICKET_RESOLVER_GROUP": "Çözüm Grubu",
    "CONTACT_TICKET_SEVERITY": "Ticket Önem Derecesi",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM Klasör Kimliği",
    "CONTACT_TICKET_SUBJECT": "Ticket Konusu",
    "CONTACT_TIMELINE": "Kontak Zaman Çizelgesi",
    "CONTACT_TYPE": "Kontak türü",
    "CONTACT_WAS_TRANSFERRED": "Kontak aktarıldı.",
    "CONTENT": "İçerik",
    "CONTENT_RESTRICTED": "İçerik kısıtlıdır.",
    "COPY_AGENT_SELECTED_ISSUE": "Temsilci tarafından seçilen sorunu kopyala: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Amazon Connect İletişim Kimliğini Kopyala",
    "COPY_CALL_LEG_ID": "Çağrı ayağı kimliğini kopyala: {value}",
    "COPY_CONTACT_ID": "Kontak numarasını kopyala: {value}",
    "COPY_CUSTOMER_ID": "Müşteri numarasını kopyala",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Müşteri tarafından seçilen sorunu kopyala: {value}",
    "COPY_FAILURE": "Kopyalama başarısız.",
    "COPY_ID": "Numarayı kopyala",
    "COPY_LINK": "Görsel yükleme bağlantısını kopyala",
    "COPY_ORDER_ID": "Sipariş numarasını kopyala: {value}.",
    "CREATE_CASE": "Olay Oluştur",
    "CREATE_FOLLOW_UP": "Takip oluştur",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Takip başarıyla oluşturuldu, ancak ilgili açıklama eklenemedi. Açıklama manuel eklemeyi yeniden edin.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Müşterinin tercih ettiği iletişim yöntemini belirtin.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Geçmişteki bir son tarih ve saati içeren bir takip oluşturulamıyor.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Takip işlemi yapılması gereken tarihi ve saati belirtin.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Saat dilimi",
    "CREATE_FOLLOW_UP_FAILURE": "Takip oluşturulamadı.",
    "CREATE_FOLLOW_UP_INVALID": "Geçersiz takip. Lütfen erişimi kısıtlanmış veri içermediğinden emin olun.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Takip işlemini girin.",
    "CREATE_FOLLOW_UP_SUCCESS": "Takip başarıyla oluşturuldu.",
    "CREATE_WORK_REQUEST": "İş Talebi Oluştur",
    "CROSS_MARKETPLACE_QUEUES": "Amazon pazaryerleri arası kontak adresleri",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Zaten çözüme ulaştırıldığı için Kontak aktarılamadı.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "Kontak geçersiz bir bölgede olduğundan işlem başarısız oldu.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Kontak bulunamadığı için işlem başarısız oldu",
    "CSALT_ERROR_INTERNAL_ERROR": "Kontak kilitlenemedi",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Geçersiz değişkenler nedeniyle kontak kilitlenemedi",
    "CSALT_ERROR_INVALID_INPUT": "Geçersiz girişler nedeniyle işlem başarısız oldu.",
    "CSALT_ERROR_INVALID_QUEUE": "Seçili kontak adresi geçersiz olduğu için Kontak aktarılamadı.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Kontak farklı bir Temsilciye kilitlendiği için işlem başarısız oldu.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Bağımlılık hatası nedeniyle kontak kilitlenemedi",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Kontak adresi arka uç hizmetinde bulunamadığı için Kontak aktarılamadı.",
    "CSALT_ERROR_UNKNOWN": "Beklenmeyen bir hata nedeniyle işlem başarısız oldu.",
    "CURRENT_CONTACT": "Geçerli Kontak",
    "CUSTOMER": "Müşteri",
    "CUSTOMER_FOLLOWUPS": "Müşteri takipleri",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Müşteri için takip alınamadı",
    "CUSTOMER_ID_COPIED": "Müşteri numarası kopyalandı",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Müşteri içeriğine erişim Kısıtlı. (Kilitli Kontak Yok.)",
    "CUSTOMER_SELECTED_ISSUE": "Müşterini tarafından seçilen sorun",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Müşteri tarafından seçilen sorun kopyalandı.",
    "CUSTOMER_TIMEZONE": "Müşterinin saat diliminde gösterilen zaman damgaları",
    "DATE": "Tarih",
    "DATE_PICKER_NEXT_MONTH": "Gelecek ay",
    "DATE_PICKER_PREVIOUS_MONTH": "Önceki ay",
    "DATE_PICKER_TODAY": "Bugün",
    "DECODING_AUDIO": "Ses kodu deşifre ediliyor.",
    "DELETE_ATTACHMENTS": "Kişi eklerini sil",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Ekleri Sil",
    "DELIVERY_STATUS": "Teslimat durumu",
    "DISABLE_CONTENT_FILTER": "İçerik filtresini devre dışı bırak",
    "DOWNLOAD": "İndir",
    "DURATION": "Süre",
    "EMAIL": "E-posta",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Alıcı e-posta adres(ler)ine izin verilmez.",
    "EMAIL_BODY": "E-posta içeriği",
    "EMAIL_CONTACT": "E-posta kontağı.",
    "EMAIL_HEADER": "Başlık",
    "EMAIL_QUEUE_TITLE": "E-posta kontak adresi",
    "EMAIL_RESTRICTED": "E-posta içeriğine erişim kısıtlı.",
    "EMAIL_SENDING_FAILURE": "Giden e-posta gönderme başarısız oldu.",
    "EMAIL_SUBJECT": "E-posta Konusu",
    "EMAIL_TITLE": "Amazon müşteri hizmetlerine e-posta gönder",
    "END": "Sonlandır",
    "END_DATE": "Bitiş tarihi",
    "ERROR": "Hata",
    "ERROR_FAILED_TO_LOAD_DATA": "Veriler yüklenemedi.",
    "ESCALATION_CONTACT_HANDLING": "Lead ekibine aktarılan kontak işlenmesi",
    "ESCALATION_TAB_LEAD_LOGIN": "Yönetici oturumu: {agentLogin}",
    "EXPANDED": "Genişletildi",
    "FAILED_TO_CREATE_WORK_REQUEST": "İş Talebi oluşturulamadı",
    "FAILED_TO_FETCH_SYSKA": "SYSKA Uyarıları alınamadı",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Kontak temsilciye yeniden atanamadı.",
    "FETCHED_NUMBER_CONTACTS": "{number} Kontak Getirildi.",
    "FILTER": "Filtre",
    "FINDER": "Bulucu",
    "FOLLOW_UPS_COLLAPSED": "Takipler daraltıldı",
    "FOLLOW_UPS_EXPANDED": "Takipler genişletildi",
    "FOLLOW_UP_ALERT": "{agentLogin} tarafından oluşturulan {dueDate} son tarihli takip bulunuyor",
    "FOLLOW_UP_CONTACT_METHOD": "İletişim yöntemi",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "İletişim yöntemine erişim kısıtlı.",
    "FOLLOW_UP_DUE": "Takip bitiş tarihi",
    "FOLLOW_UP_DUE_DATE": "Bitiş tarihi",
    "FOLLOW_UP_NOTE": "Not",
    "FOLLOW_UP_NO_CONTACT_METHOD": "İletişim yöntemi yok",
    "FOLLOW_UP_NO_NOTE": "Not boş",
    "FOLLOW_UP_NO_TICKET": "Ticket yok",
    "FOLLOW_UP_OWNER": "Sahibi",
    "FOLLOW_UP_STATUS": "Durum",
    "FOLLOW_UP_STATUS_OPEN": "Açık",
    "FOLLOW_UP_STATUS_RESOLVED": "Çözüme ulaştırıldı",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Takipler",
    "FROM": "Kimden",
    "GENERATE_LINK": "Bağlantı Oluştur",
    "HAS_OVERRIDE_TRY_AGAIN": "Filtre geçersiz kılındı. Tekrar deneyin.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "müşteriyle paylaşılan görsel eki bağlantısı",
    "INPUT_CHARACTERS_REMAINING": "Maksimum {char_max} karakter ({char_remain} kaldı)",
    "INTERNAL_ERROR": "Dahili Hata",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Bir iç hatayla karşılaştık. Hata devam ederse, lütfen bize sorunu açıklayan bir bilet kesin.",
    "INVALID_CONTACT": "Geçersiz kontak.",
    "INVALID_DATA_MATCH": "Sistem geçersiz bir veri sınıflandırması yaptı.",
    "INVALID_TIMESTAMP": "Geçersiz zaman damgası.",
    "IN_MARKETPLACE_QUEUES": "Amazon pazaryerleri arası kontak adreslerinde",
    "ISSUE": "Sorun",
    "ISSUE_CODE": "Sorun Kodu",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Önceki bir kontakta ele alındı ve çözüme ulaştırıldı",
    "ISSUE_CODE_DESCRIPTION": "Seçili kontakları kopya olarak işaretleme nedeninizi seçin",
    "ISSUE_CODE_EXACTLY_SAME": "Önceki bir kontakla tamamen aynı",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Başka bir Sohbet CSA'sı tarafından ele alındı ve çözüme ulaştırıldı",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Başka bir E-posta CSA'sı tarafından ele alındı ve çözüme ulaştırıldı",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Başka bir Telefon CSA'sı tarafından ele alındı ve çözüme ulaştırıldı",
    "ITEMS_CONTACTED_ABOUT": "Hakkında iletişime geçilen ürünler",
    "I_UNDERSTAND": "Anladım",
    "KNOWN_ISSUES": "Bilinen Sorunlar",
    "LEARN_MORE": "Daha fazla bilgi al",
    "LINK_COPIED": "Bağlantı kopyalandı",
    "LOADING": "Veriler yükleniyor.",
    "LOADING_PREVIEW_TEXT": "Ön izleme yükleniyor",
    "LOADING_RECORDING": "Kayıt yükleniyor",
    "LOAD_MORE": "Daha fazla yükle",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Daha fazla açıklama yükle.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Bu müşteri için daha fazla kontak yükle.",
    "LOCKED": "Kilitli",
    "LOCKED_BY": "Kilitleyen",
    "LOCK_CONTACT": "Kilitle",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Kontak kilitlenemedi, kullanıcı yetkili değil",
    "LOCK_CONTACT_SUCCESSFUL": "Kontak kilitlendi",
    "MISSING_ORIGINAL_CONTACT_ID": "Lütfen geçerli orijinal kontak numarasını girin",
    "MODAL_NO_BUTTON_TEXT": "Hayır",
    "MODAL_YES_BUTTON_TEXT": "Evet",
    "NEXT": "Sonraki",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Temsilci tarafından seçilen sorun yok.",
    "NO_ANNOTATED_BY": "Bilinmeyen kullanıcı tarafından ek açıklama yapıldı.",
    "NO_ANNOTATIONS": "Açıklama mevcut değil.",
    "NO_ATTACHMENTS": "Ek yok.",
    "NO_CHAT": "Sohbet boş.",
    "NO_CHILD_WORK_REQUESTS": "Bu kişiden hiçbir iş isteği oluşturulmadı",
    "NO_CONTACT": "Mevcut kontak yok",
    "NO_CONTACTS_SELECTED": "Lütfen istenmeyen posta olarak işlenmesini istediğiniz kontakları seçin",
    "NO_CONTENT": "İçerik yok",
    "NO_CUSTOMER_SELECTED_ISSUE": "Müşteri tarafından seçilen sorun yok.",
    "NO_DELIVERY_STATUS": "Teslimat durumu yok",
    "NO_EMAIL_BODY": "E-posta boş.",
    "NO_EMAIL_HEADER": "E-posta başlığı yok.",
    "NO_EMAIL_QUEUE": "Bu kişiyle ilişkili bir e-posta sırası yok.",
    "NO_ITEMS_CONTACTED_ABOUT": "Hakkında iletişime geçilen bir ürün yok.",
    "NO_RESULTS": "Sonuç bulunamadı.",
    "NO_SCREEN_POP": "Açılır ekran bilgisi yok",
    "NO_SENT_FROM": "Gönderim adresi yok.",
    "NO_SENT_TO": "Alıcı adresi yok",
    "NO_SUBJECT": "Konu satırı boş.",
    "NO_SYSKA_ALERTS": "SYSKA Uyarısı yok",
    "NO_USED_BLURBS": "Bu kontak için herhangi bir hazır metin kullanılmadı.",
    "NO_VIEW": "Görüntülenecek kontak veya müşteri yok.",
    "NO_WORK_CATEGORY": "Bu kontak ile ilişkili bir iş kategorisi yok.",
    "NUMBER_OF_CONTACTS": "Yüklü kişiler",
    "OLDEST_LOADED_CONTACT": "En eski yüklenen kişi",
    "OPEN": "Açık",
    "OPENS_IN_A_NEW_TAB": "Yeni bir sekmede açılır",
    "ORDER_ID_COPIED": "Sipariş Numarası kopyalandı.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Orijinal kontak numarasını girin",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Orijinal kontak numarasını girmek için metin kutusu",
    "OTHER": "Diğer",
    "OUTBOUND_EMAIL_BCC": "ve bcc’de -",
    "OUTBOUND_EMAIL_CC": "cc’deyken -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Bildirim içeriği daraltıldı.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Bildirim içeriği genişletildi.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Maksimum sonuç sayısına ulaşıldı. Lütfen tarih aralığını daraltıp yeniden deneyin.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Mevcut işlem için PII içeriği gerekiyor.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Telefon Numarası, e-posta adresi veya diğer PII ile geçersiz eşleşme.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Kredi Kartı veya IBAN ile geçersiz eşleşme.",
    "OVERRIDE_REASON_PROMPT": "Açıklamalar, telefon numaraları ve e-posta adresleri gibi Müşteri PII verilerini içermemelidir. Otomatik sistemimiz bunu uygulamaya çalışır ancak bazen PII ile diğer kimlikler bilgileri arasındaki farkı algılayamaz. Geçersiz kılmanın neden gerekli olduğunu belirtin.",
    "PAPYRUS_SESSION_EXPLANATION": "Doğru güvenlik kısıtlamalarını uygulayabilmemiz ve UX'i ihtiyaçlarınıza göre optimize edebilmemiz için lütfen Papirüs oturum türünüzü belirtin.",
    "PAPYRUS_SESSION_TYPE": "Papyrus oturum türü",
    "PAUSE": "Duraklat",
    "PHONE_CONTACT": "Telefon kontağı.",
    "PLAY": "Oynat",
    "PLAYBACK_SPEED": "Yeniden oynatma hızı",
    "POSTAL_MAIL_TITLE": "Amazon müşteri hizmetleri ile normal posta",
    "PREPARING_RECORDING": "Kayıt yapmaya hazırlanıyor",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Kontak için ön izleme oluşturulamadı",
    "PREVIOUS": "Önceki",
    "PRE_TRANSLATED_REPLY_EMAIL": "Önceden çevrilmiş yanıt e-postası",
    "PRE_TRANSLATION_REPLY_TEXT": "Rosetta çevirisi öncesi CSA tarafından yazılan yanıt",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "İşlem Eylemleri",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Kopya olarak işle",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "İstenmeyen Posta Olarak İşle",
    "PROCESS_CONTACT_AS_DUPLICATE": "Seçili Kontakları Kopya Olarak Çözüme Ulaştır",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Seçili kontakları istenmeyen posta olarak işlemek istediğinizden emin misiniz?",
    "PROCESS_DUPLICATE_FAILURE": "Seçili kontaklar kopya olarak çözüme ulaştırılamadı",
    "PROCESS_DUPLICATE_SUCCESS": "Seçili kontaklar kopya olarak başarıyla çözüme ulaştırıldı",
    "PROCESS_SPAM_FAILURE": "Seçilen kontaklar istenmeyen posta klasörüne aktarılamadı",
    "PROCESS_SPAM_SUCCESS": "Seçilen kontaklar başarıyla istenmeyen posta klasörüne aktarıldı",
    "PROVIDE_ADDITIONAL_INFORMATION": "Bu kontakla ilgili ek bilgi ver",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Kontaktan temizlenen hassas bilgiler:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Temizleme listesine kelime ekle",
    "PURGE_BUTTON_TEXT": "Temizle",
    "PURGE_CONFIRMATION_CHECK": "Yukarıdaki alanları doğruladım ve metnin gerçekten hassas bilgiler içerdiğini onaylıyorum.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Ekin müşteri hassas bilgileri içerdiğini onaylıyorum.",
    "PURGE_CONFIRMATION_UNCHECKED": "Lütfen onay kutusunu onaylayın.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Kontaktan temizlenemedi",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Bilgi, kontaktan başarıyla temizlendi",
    "PURGE_INVALID_REASON": "{invalid_purge_selection}, temizleme için geçerli bir neden değil",
    "PURGE_REASON_FINANCIAL_INFO": "Finansal bilgiler",
    "PURGE_REASON_OTHER": "Diğer hassas bilgiler",
    "PURGE_REASON_PII": "Kişisel tanımlayıcı bilgi",
    "PURGE_REASON_PLACEHOLDER": "Temizleme sebebini girin",
    "PURGE_REASON_TEXT": "Temizleme nedeni",
    "PURGE_REMOVE_LABEL": "Kaldır",
    "PURGE_SELECTOR_PLACEHOLDER": "Hassas bilgilerin temizlenme nedenini seçin",
    "PURGE_SENSITIVE_INFO": "Hassas Bilgileri Temizle",
    "PURGE_WORD_NOT_FOUND": "Kelime, kontak metninde bulunamadı",
    "PURPOSE_AUDITING": "Kalite kontrol denetimi",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Arka Ofis iş akışı",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Bu, Arka Ofis iş akışları için kişi verilerini almak için kullanılan oturumdur. Örneğin, biletlere veya SIM'e eklenecek ekler.",
    "PURPOSE_COACHING": "Koçluk için kontak yorumu",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Uygunluk araştırması",
    "PURPOSE_CURATION": "Kontak iyileştirme",
    "PURPOSE_CURATION_DESCRIPTION": "Bu, C2CS/VoC kontak iyileştirme oturumudur.",
    "PURPOSE_DESCRIPTION_AUDITING": "Bu oturum kontakları denetlemek ve kalite değerlendirmesi yapmak içindir.",
    "PURPOSE_DESCRIPTION_COACHING": "Bu, Performans Portalı koçu olduğunuz temsilcilerin kontaklarını incelemek için kullanılan oturumdur.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Bu, Şüpheli Aktivite İzleme araştırmaları için olan oturumdur.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Bu, eskalasyon kontraklarını işlemek için kullanılan oturumdur. Bu, herhangi bir müşterinin irtibat verilerine erişmenizi sağlar.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Bu, canlı kontak işleme almak için kullanılan oturumdur.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Bu, bir kontaktan hassas bilgileri kaldırmayı amaçlar",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Bu, makine öğrenimi modelinin doğruluğunu incelemek için kullanılan bir oturumdur.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Bu, kendi geçmiş kontaklarınızı incelemek için kullanılan oturumdur.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Bu, varsayılan genel amaçlı oturumdur.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Lead ekibine aktarılan kontak işleme alınıyor",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Hassas bilgileri temizle",
    "REASON_FOR_PURGE": "Temizleme Nedeni",
    "REASON_FOR_PURGE_DESCRIPTION": "Ekleri temizleme nedeni",
    "REASON_FOR_PURGE_MISSING": "Lütfen ekleri silmek için neden girin.",
    "REASSIGN_TO_AGENT": "Temsilciye yeniden ata",
    "RECORDING": "Kaydediliyor",
    "RELOADING_CONTENT": "İçerik yeniden yükleniyor.",
    "RELOAD_LIST": "Listeyi yeniden yükle",
    "REPLY_BY": "Yanıtlayan:",
    "REPLY_BY_EMAIL": "E-posta ile Yanıtla",
    "REPLY_BY_PHONE": "Telefon ile Yanıtla",
    "REPLY_RESTRICTED": "Yanıt içeriği Kısıtlı.",
    "REQUEST": "Talep",
    "REQUEST_ACCESS_INSTRUCTIONS": "Lütfen kısıtlanmış içeriğe erişimin neden gerekli olduğunu belirtin. (Bu talepler izlenir ve kötüye kullanımın ciddi sonuçları olabilir.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Filtrelenmiş içeriğe erişim talep et",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Kısıtlanmış içeriğe erişim talep et",
    "REQUEST_NOT_GRANTED": "Talebe izin verilmedi.",
    "REQUEST_OVERRIDE": "Geçersiz Kılma Talebi",
    "RESEND": "Yeniden gönder",
    "RESEND_FAILED": "E-posta yeniden gönderilemedi.",
    "RESEND_SUCCESS": "E-posta başarıyla yeniden gönderildi.",
    "RESEND_WAIT_WARNING": "Yeniden göndermek için {wait_mins} dakika beklemelisiniz.",
    "RESEND_WARNING": "Müşteriye yeniden e-posta göndermek istediğinizden emin misiniz?",
    "RESET": "Sıfırla",
    "RESOLVED": "Çözüme ulaştırıldı",
    "RESOLVED_BY": "Çözüme ulaştıran",
    "RESTRICTED_DATA_MATCH": "{match} eşleştirme türü {type}",
    "RESTRICTED_INFORMATION": "KISITLI BİLGİ",
    "RETRY": "Tekrar Dene",
    "ROLE": "Rol",
    "SCREEN_POP_DESCRIPTION_TITLE": "Açıklama",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Hayır",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Evet",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Temsilciye gösterildi",
    "SCREEN_POP_TITLE": "Açılır Ekran Bilgisi",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Doğrulama için açılır ekran temsilciye gösterildi ve kullanıldı.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Doğrulama için açılır ekran temsilciye gösterildi.",
    "SCREEN_POP_TYPE_CALLER_ID": "Arayan Kimliği bilgi ekranı temsilciye gösterildi ve kullanıldı.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Arayan Kimliği bilgi ekranı temsilciye gösterildi.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Kimliği doğrulanmamış bilgi ekranı görüntülendi ve kullanıldı.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Temsilciye kimliği doğrulanmamış bilgi ekranı gösterildi.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Müşteri tanınmıyor; bilgi ekranı sağlanamadı.",
    "SCREEN_POP_TYPE_OTHER": "Diğer",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Hayır",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Evet",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Temsilci tarafından kullanıldı",
    "SDS_TOOLS": "Kargo ve Teslimat Destek Araçları",
    "SEARCH": "Ara",
    "SELECTED_ROLE": "Seçilen rol",
    "SELECTED_VIEW": "Seçili görünüm",
    "SELECT_CHANNELS": "Kanalları seç",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Seçili kontakları kopya olarak işaretlemek üzere sorun kodunu seçmek için",
    "SELECT_TRANSFER_DESTINATION": "Aktarma hedefi seçin",
    "SELECT_WORK_REQUEST_QUEUE": "İş Talebi kontak adresini seçin",
    "SENDER_EMAIL_ADDRESS": "Gönderen",
    "SENDING_EMAIL": "E-posta Gönderiliyor",
    "SEND_EMAIL": "E-posta Gönder",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Müşteriye görsel yükleme bağlantısı göndermek istediğinizden emin misiniz?",
    "SEND_OUTBOUND_EMAIL": "Giden E-posta Gönder",
    "SEND_OUTBOUND_EMAIL_WARNING": "Giden e-postayı şuraya göndermek istediğinizden emin misiniz:",
    "SENT_FROM": "Gönderen adres",
    "SENT_OUTBOUND_EMAIL": "Giden e-posta gönderildi. Yeni bir kişi oluşturuldu",
    "SENT_TO": "Alıcı",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Taşıyıcı Firma Portalı",
    "SKILL": "Beceri",
    "SKIP_SILENCES": "Sessizlikleri atla",
    "SMS": "SMS",
    "SMS_LINK": "SMS Bağlantısı",
    "SOURCE_TEXT": "Kaynak metin",
    "SP_BOTTOM": "Alt",
    "SP_POSITION": "Bölme paneli pozisyonu",
    "SP_PREFERENCES": "Bölme paneli tercihleri",
    "SP_RESIZE": "Bölme paneli boyutlandır",
    "SP_SIDE": "Yan",
    "START": "Başlat",
    "STATE_TRANSITIONS": "Durum geçişleri",
    "STATUS": "Durum",
    "SUBJECT": "Konu",
    "SYSKA_ALERTS": "SYSKA Uyarıları",
    "TEXT_TO_PURGE": "Temizlenecek metin",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Üçüncü taraf çerezleri etkinleştirilmelidir.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Bir ticket oluştururken telefon numaraları ve e-posta adresleri gibi müşteri PII verilerine izin verilmez. Otomatik sistemimiz bunu uygulamaya çalışır ancak bazen PII ile diğer kimlikler arasındaki farkı algılayamaz. Geçersiz kılmanın neden gerekli olduğunu belirtin.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Zaman aşımı: kaydı yükleyemedi",
    "TIME_TEXT_FORMAT_CONSTRAINT": "24 saat formatını kullanın.",
    "TO": "Kime",
    "TO_EMAIL_ADDRESS": "Alıcı",
    "TRANSFER": "Aktar",
    "TRANSFERRED_CHAT_TITLE": "Amazon müşteri hizmetleri ile yapılmış aktarılan sohbet",
    "TRANSFERRING": "Aktarılıyor...",
    "TRANSFER_CONTACT": "Kontağı aktar",
    "TRANSFER_CONTACT_COMMENTS": "İsteğe bağlı açıklama",
    "TRANSFER_FAILED": "Aktarma başarısız",
    "TRANSFER_FOLLOW_UP": "Transfer takibi",
    "TRANSFER_FOLLOW_UP_FAILED": "Takip aktarılamadı.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Yeni sahip için belirtilen oturum açma adı geçersiz olduğu için takip aktarılamadı.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Bu takibi aktarmak için izniniz yok.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Yeni sahibin oturum açma adını girin. Örnek: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Yukarıdaki takibi şuraya aktar:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Takip başarıyla aktarıldı.",
    "TRANSLATED_TEXT": "Çevrilmiş metin",
    "TRANSLATION": "Çeviri",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} geçerli bir kontak adresi değil.",
    "TYPE_AGENT_LOGIN": "Tür temsilci oturumu açma",
    "TYPE_EMAIL_ADDRESS": "E-posta adresini yazın",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Virgülle ayrılmış birden fazla e-posta adresi yazın",
    "TYPE_QUEUE_NAME": "Kontak adresini yazın",
    "UNKNOWN_CONTACT_TYPE": "Bilinmeyen kontak türü.",
    "UNLOCKED": "Kilidi açıldı",
    "UNRESOLVED": "Çözüme ulaştırılmadı",
    "URGENT_SYSKA_ALERTS": "Acil SYSKA Uyarıları",
    "USE": "Kullanım",
    "USER_TIMEZONE": "Yerel zaman diliminizde gösterilen zaman damgaları",
    "USE_CONTACT_FINDER": "Kişi bulucu kullan",
    "VIEW": "Görüntüle",
    "WARNING": "Uyarı",
    "WELCOME_MESSAGE": "Papyrus Statik Web sitesine hoş geldiniz!",
    "WHAT_IS_PAPYRUS": "Papyrus nedir?",
    "WITH_THE_ANNOTATION": "açıklama ile",
    "WORK_CATEGORY_TITLE": "İş Kategorisi",
    "WORK_REQUEST": "İş Talebi",
    "WORK_REQUEST_CONTENT": "İş Talebi içeriği",
    "WORK_REQUEST_CREATED": "İş Talebi oluşturuldu",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Bir iş isteği oluştururken telefon numaraları ve e-posta adresleri gibi müşteri PII verilerine izin verilmez. Otomatik sistemimiz bunu uygulamaya çalışır ancak bazen PII ile diğer kimlikler arasındaki farkı algılayamaz. Geçersiz kılmanın neden gerekli olduğunu belirtin.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Kontak adresine aktarmak üzeresiniz"
}