import React, { useContext } from 'react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {i18n} from "../../i18n/IntlManager";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {Contact, ContactTransferDestination, ContactTransferResponse} from "../Contact.types";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import {MetricType} from "@amzn/csphoenix-react-client";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {StatusIndicatorProps} from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import CSCentralURL from "../../issueManager/CSCentralURL";
import Phoenix from "../../api/Phoenix";
import {AllHtmlEntities} from 'html-entities';

interface ConfirmationModalProps {
    contactTransferDestination: ContactTransferDestination;
    comment: string;
    contact: Contact;
    marketplaceId: string;
    visible: boolean;
    dismissCallback: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({visible, contact, marketplaceId, dismissCallback, contactTransferDestination, comment}) => {
    const [statusType, setStatusType] = React.useState<StatusIndicatorProps.Type>();
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [inTransfer, setInTransfer] = React.useState<boolean>(false);
    const redirectUrl = CSCentralURL.sharedCSCentralURL.getURL("gp/session/resolve-session.html/", '');
    const stringifiedComment = JSON.stringify(AllHtmlEntities.encode(comment));

    const { callPhoenix } = useContext(IssueManagerContext);

    const requestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);

    const mutation =
        `mutation {` +
        `  transferContact(input: {contactId: "${contact.contactId}", ` +
        `  queueName: "${contactTransferDestination.emailQueue.name}", ` +
        `  expectedOwnerAgentId: "${contact.owner?.agentId}", ` +
        `  marketplaceId: "${marketplaceId}", ` +
        `  idempotencyKey: "${requestId}"` +
        (comment.length ? `, transferCommentEncrypted: ${stringifiedComment}}) {` : '}) {') +
        `    error { ` +
        `      type ` +
        `      errorMessageDescriptor { ` +
        `        stringId ` +
        `      } ` +
        `    } ` +
        `  } ` +
        `}`;

    async function doTransfer(): Promise<void> {
        setInTransfer(true);

        try {
            const response = await callPhoenix(mutation);

            Phoenix.getInstance().addMetric("TransferContact.COMMENT", comment === '' ? 0 : 1, MetricType.COUNT);

            if (response.httpStatus === 200) {
                if (response.callResult) {
                    const transferResponse = JSON.parse(response.callResult.resultJson).data.transferContact as ContactTransferResponse;
                    if (transferResponse.error) {
                        setStatusType('error');
                        setStatusMessage(i18n('TRANSFER_FAILED'));
                        setErrorMessage(i18n(transferResponse.error.errorMessageDescriptor.stringId));
                    } else {
                        setStatusType("success");
                        setStatusMessage(i18n('CONTACT_WAS_TRANSFERRED'));
                        setErrorMessage("");
                    }
                }
            } else {
                setStatusType('error');
                setStatusMessage(i18n('TRANSFER_FAILED'));
                setErrorMessage(response.error ? response.error : i18n('INTERNAL_ERROR'));
            }
        }
        catch (error) {
            setStatusType('error');
            setStatusMessage(i18n('TRANSFER_FAILED'));
            setErrorMessage(i18n('INTERNAL_ERROR'));
        }
        finally {
            setInTransfer(false);
        }
    }

    function close(): void {
        if (statusType === "success") {
            window.top.location.href = redirectUrl;
        }

        setStatusType(undefined);
        setStatusMessage("");
        setErrorMessage("");
        dismissCallback();
    }

    return (<Modal
        onDismiss={close}
        visible={visible}
        header={statusMessage ? <StatusIndicator type={statusType}>{statusMessage}</StatusIndicator> : null}
        footer={<Box float={"right"}><SpaceBetween direction="horizontal" size="xs">
            {!statusType ? <Button variant="link" onClick={close}>{i18n('CANCEL')}</Button> : null}
            {!statusType ? <Button variant="primary" disabled={inTransfer} onClick={(): Promise<void> => doTransfer()}>{i18n('TRANSFER_CONTACT')}</Button> : null}
            {statusType ? <Button variant={"primary"} onClick={close}>{i18n('CLOSE')}</Button>: null}
        </SpaceBetween>
        </Box>}>
        {!statusType ?
            comment ?
                <div>{i18n('YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE')}&nbsp;<b>{contactTransferDestination.emailQueue.name}</b> {i18n('WITH_THE_ANNOTATION')} "{comment}".</div>
                : <div>{i18n('YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE')}&nbsp;<b>{contactTransferDestination.emailQueue.name}</b>.</div>
            : null}
        <div>{inTransfer ? i18n('TRANSFERRING') : null }</div>
        <div>{errorMessage}</div>
    </Modal>);
};

export default ConfirmationModal;