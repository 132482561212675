import React from 'react';
import Button from "@amzn/awsui-components-react/polaris/button";
import Phoenix from "../api/Phoenix";

function exportRPToken(): void {
    // Send the Global Identity RP token to https://local.pgw.a2z.com:3000
    // After one can run the CSC page with the local version if Papyrus by adding &papyrus=local
    // to the main URL.
    // Or press just press the "Frame Local" button in order to run Papyrus standalone.
    const authUrl: string = 'https://local.pgw.cs.a2z.com:3000/us_US/auth/' +
        Phoenix.getInstance().rpTokenCookieName + '#rp_token=' + Phoenix.getInstance().getRpToken();

    const authWindow = window.open(authUrl);
    if (authWindow) {
        const timer = setInterval(() => {
            if (authWindow === null || authWindow === void 0 ? void 0 : authWindow.closed) {
                clearInterval(timer);

            }
        }, 1000);
    }
}

function openFrame(): void {
    // Open the iFrame in a new tab (GACD Audio will not work because it is pulled from parent)
    window.open(window.location.href);
}

function openLocalFrame(): void {
    // Open the iFrame in a new tab using Local Papyrus Code
    const uri: string = window.location.href.substring(window.origin.length);
    const newUrl = 'https://local.pgw.cs.a2z.com:3000' + uri;
    window.open(newUrl);
}

export const RunLocal: React.FC = () => {
    return (<div>
        <Button onClick={(): void => exportRPToken()}>Export Token</Button>
        <Button onClick={(): void => openFrame()}>Frame</Button>
        <Button onClick={(): void => openLocalFrame()}>Frame Local</Button>
    </div>);
};