import React from "react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {i18n} from "../i18n/IntlManager";
import Link from "@amzn/awsui-components-react/polaris/link";

export const CookieDisabledAlert: React.FC = () => {
    return (
        !navigator.cookieEnabled ?
            <Alert header={i18n('KNOWN_ISSUES')}>
                {i18n('THIRD_PARTY_COOKIES_MUST_BE_ENABLED')}&nbsp;
                <Link
                    external
                    externalIconAriaLabel={i18n('OPENS_IN_A_NEW_TAB')}
                    href="https://w.amazon.com/bin/view/PapyrusStaticWebsite/Authentication"
                >
                    {i18n('LEARN_MORE')}
                </Link>
            </Alert> : null
    );
};