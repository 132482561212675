import React, {useContext, useState} from "react";
import {Checkbox, FormField, SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import Box from "@amzn/awsui-components-react/polaris/box";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {i18n} from "../../i18n/IntlManager";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import Phoenix from "../../api/Phoenix";
import {getUserLogin, getUserOwner} from "../UserDetailsUtil";
import {Input} from "@amzn/awsui-components-react/polaris";
import {PushNotificationFunction} from "../ContactActions";

interface DeleteAttachmentsProps {
    contactId: string;
    pushNotification: PushNotificationFunction;
}

const DeleteAttachments: React.FC<DeleteAttachmentsProps> = ({
                                                                 contactId,
                                                                 pushNotification
                                                             }) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [modalViewVisibility, setModalViewVisibility] = useState<boolean>(false);
    const [reasonForPurge, setReasonForPurge] = useState<string>('');
    const [confirmCheck, setConfirmCheck] = useState(false);
    const [reasonForPurgeEmptyErrorText, setReasonForPurgeEmptyErrorText] = useState<string>('');
    const {getSubject, reloadSingleContact, callPhoenix} = useContext(IssueManagerContext);

    async function deleteAllAttachments(reasonForPurge: string, contactId: string): Promise<boolean> {
        setIsDeleting(true);
        try {
            const response = await callPhoenix(getDeleteAttachmentsMutation(contactId, reasonForPurge));
            if (response.httpStatus === 200) {
                if (response.callResult?.rules?.find(r => !r.allow)) {
                    pushNotification((<div>{i18n('DELETE_ATTACHMENT_NOT_AUTHORIZED')}</div>), 'error');
                } else if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (!parsed?.data?.deleteContactAttachments.failure) { //success
                        await reloadSingleContact(contactId);
                        pushNotification((<div>{i18n('ATTACHMENT_DELETION_SUCCESSFUL')}</div>), 'success');
                        return true;
                    } else {
                        pushNotification((<div>{i18n(parsed?.data?.deleteContactAttachments.failure?.errorMessageDescriptor.stringId)}</div>), 'error');
                        Phoenix.getInstance().log(LogLevel.WARN, "Failed to delete attachments for contact: " + contactId + " " + parsed?.data?.deleteAllAttachments.failure?.errorMessageDescriptor.stringId);
                        Phoenix.getInstance().addMetric("DeleteAttachments.Failure:" + parsed?.data?.deleteAllAttachments.failure?.errorMessageDescriptor.stringId, 1, MetricType.COUNT);
                    }
                }
            } else {
                pushNotification((<div>{i18n("ATTACHMENT_DELETION_FAILED")}</div>), 'error');
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to delete attachments for contact:" + contactId + " " + response.error);
            }
        } catch (error) {
            pushNotification((<div>{i18n("ATTACHMENT_DELETION_FAILED")}</div>), 'error');
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to delete attachments for contact:" + contactId + " " + error);
        } finally {
            setModalViewVisibility(false);
            setIsDeleting(false);
        }

        return false;
    }

    async function onDeleteAttachmentConfirmationClick(): Promise<void> {
        if (reasonForPurge.trim() !== "" && confirmCheck === true) {
            setReasonForPurgeEmptyErrorText("");
            await deleteAllAttachments(reasonForPurge, contactId);
        } else if (reasonForPurge.trim() === "") {
            setReasonForPurgeEmptyErrorText(i18n("REASON_FOR_PURGE_MISSING"));
        } else {
            setReasonForPurgeEmptyErrorText(i18n("PURGE_CONFIRMATION_UNCHECKED"));
        }
        setConfirmCheck(false);
    }

    function getDeleteAttachmentsMutation(contactId: string, reasonForPurge: string): string {
        return (
            `mutation {
                deleteContactAttachments(input:{
                    obfuscatedContactId: "${contactId}",
                    requestingCSAgent:{agentLogin:"${getUserLogin(getSubject())}", agentOwner:"${getUserOwner(getSubject())}"},
                    reasonForPurgeEncrypted:"${reasonForPurge.trim()}"
                }) {
                    failure {
                        errorType
                        errorMessageDescriptor {
                            stringId
                      }
                    }
                }
            }`);
    }

    return (
        <div>
            <SpaceBetween size={"xs"} direction={"vertical"}>
                <Button
                    variant={"normal"}
                    disabled={isDeleting}
                    onClick={(): void => setModalViewVisibility(true)}>
                    {i18n("DELETE_ATTACHMENTS_BUTTON_TEXT")}
                </Button>
            </SpaceBetween>
            <Modal
                onDismiss={(): void => setModalViewVisibility(false)}
                visible={modalViewVisibility}
                closeAriaLabel={i18n("CLOSE_MODAL_BUTTON_TEXT")}
                size="medium"
                header={
                    i18n("DELETE_ATTACHMENTS")
                }
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            {isDeleting ?
                                <Spinner size="normal"/> : null}
                            <Button variant="link"
                                    onClick={(): void => {
                                        setModalViewVisibility(false);
                                        setConfirmCheck(false);
                                    }}>{i18n("MODAL_NO_BUTTON_TEXT")}
                            </Button>
                            <Button variant="primary"
                                    onClick={async (): Promise<void> => {
                                     await onDeleteAttachmentConfirmationClick();
                                    }}
                                    disabled={isDeleting}>
                                {i18n("MODAL_YES_BUTTON_TEXT")}</Button>
                        </SpaceBetween>
                    </Box>
                }
            >{
                <SpaceBetween size={"l"} direction={"vertical"}>
                    <FormField
                        errorText={reasonForPurgeEmptyErrorText}>

                        <Input
                            value={reasonForPurge}
                            placeholder={i18n("PURGE_REASON_PLACEHOLDER")}
                            onChange={(event): void => setReasonForPurge(event.detail.value)}
                        />
                        <Checkbox
                            onChange={({detail}): void =>
                                setConfirmCheck(detail.checked)
                            }
                            checked={confirmCheck}>
                            {i18n('PURGE_CONFIRMATION_CHECK_ATTACHMENTS')}
                        </Checkbox>
                    </FormField>
                </SpaceBetween>
            }
            </Modal>
        </div>
    );
};

export default DeleteAttachments;