export const de_DE = {
    "ACCESS_RESTRICTED_CONTENT": "Zugriff auf eingeschränkte Inhalte",
    "ACTION": "Aktion",
    "ADD": "Hinzufügen",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Zusätzliche Kontaktinformationen",
    "ADD_ANNOTATION_FAILURE": "Fehler beim Hinzufügen der Anmerkung.",
    "ADD_ANNOTATION_INVALID": "Ungültige Anmerkung. Bitte stelle sicher, dass in der Anmerkung keine eingeschränkten Daten vorhanden sind.",
    "ADD_ANNOTATION_PLACEHOLDER": "Neue Anmerkung:",
    "ADD_ANNOTATION_SUCCESS": "Kommentar erfolgreich hinzugefügt.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Vom Agenten ausgewähltes Problem",
    "AGENT_SELECTED_ISSUE_COPIED": "Vom Agenten ausgewähltes Problem wurde kopiert.",
    "AMAZON_CONNECT_CALL_INFO": "Amazon Connect Anruf-Info",
    "AMAZON_CONNECT_CONTACT_ID": "Amazon Connect Kontakt-ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Amazon Connect Kontakt-ID kopiert",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANNOTATED_BY": "Kommentiert von {agentLogin}.",
    "ANNOTATIONS": "Anmerkungen",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Kommentare dürfen keine Kunden-PII-Daten wie Telefonnummern und E-Mail-Adressen enthalten. Unser automatisiertes System versucht dies durchzusetzen. Der Unterschied zwischen PII und anderen IDs wird dennoch nicht immer erkannt. Gib den Grund an, warum ein Überschreiben erforderlich ist.",
    "ANNOTATION_WARNING": "Anmerkungen können von Kunden gemäß den Datenschutzbestimmungen überprüft werden. Gib keine persönlichen Informationen oder Meinungen in das Feld Anmerkungen ein.",
    "ATTACHMENTS_RESTRICTED": "Anhänge sind eingeschränkt.",
    "ATTACHMENTS_TITLE": "Anlagen",
    "ATTACHMENT_DELETION_FAILED": "Das Löschen von Anhängen ist fehlgeschlagen, bitte versuchen Sie es später erneut.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Anhänge wurden erfolgreich gelöscht.",
    "AUTHENTICATING": "Authentifizierung begonnen. Dadurch wird möglicherweise ein neues Fenster geöffnet.",
    "AUTHENTICATION_FAILURE": "Bei der Authentifizierung ist ein Problem aufgetreten.",
    "AUTHENTICATION_MESSAGE": "Neue Anmeldedaten werden abgerufen. Dieses Fenster wird sich in wenigen Sekunden schließen.",
    "AUTO_ANNOTATION_BADGE": "Automatisch",
    "BACK": "Zurück",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Verwendete Autotexte",
    "CALL_LEG": "Leg.Nr. des Anrufes",
    "CALL_LEG_ID_COPIED": "Leg.Nr. des Anrufes wurde kopiert.",
    "CALL_RESTRICTED": "Anrufinhalte sind eingeschränkt.",
    "CALL_RETENTION_DELETED": "Die Aufzeichnung hat ihre Aufbewahrungsfrist überschritten und wurde gelöscht.",
    "CALL_TITLE": "Chat mit dem Amazon-Kundenservice",
    "CANCEL": "Abbrechen",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Dieser Kunde hat eine sehr große Anzahl von Kontakten und nicht alle wurden geladen. Kontakte werden immer ab dem Enddatum in der Richtung „neu nach älter“ geladen. Ändern Sie das Enddatum, um den Ladevorgang von einem anderen Zeitpunkt zu starten.",
    "CHANNELS": "Kanäle",
    "CHAT_AGENT_CONNECTED": "{name} hat den Chat akzeptiert.",
    "CHAT_CONTACT": "Chat-Kontakt",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} hat die Verbindung zum Netzwerk verloren.",
    "CHAT_DISCONNECT_GENERIC": "{name} ist nicht mehr verbunden.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} hat die Verbindung zum Netzwerk verloren.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} hat die Verbindung zum Netzwerk verloren.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} hat das Gespräch verlassen.",
    "CHAT_PARKED": "Dieser Chat wurde geparkt",
    "CHAT_PARKED_NOTES": "Pause-Anmerkungen",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} ist abgeschaltet.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} ist in der Warteschleife.",
    "CHAT_RESTRICTED": "Chat-Inhalte sind eingeschränkt.",
    "CHAT_TITLE": "Chat mit Amazon Kundendienst",
    "CHAT_TRANSFER_NOTES": "Übergabeschein",
    "CHILD_WORK_REQUESTS": "Von diesem Kontakt wurden Arbeitsanfragen erstellt",
    "CHOOSE_OPTIONS": "Optionen auswählen",
    "CLOSE": "Schließen",
    "CLOSE_ALERT": "Warnung schließen",
    "CLOSE_MODAL_BUTTON_TEXT": "Schließen",
    "COLLAPSED": "Reduziert",
    "CONFIRM": "Bestätigen",
    "CONTACTS": "Kontakte",
    "CONTACT_COLLAPSED": "Kontaktdetails minimiert.",
    "CONTACT_CREATION_FAILURE": "Erstellen eines neuen Kontakts fehlgeschlagen",
    "CONTACT_EXPANDED": "Kontaktdetails erweitert.",
    "CONTACT_HISTORY": "Kontaktverlauf",
    "CONTACT_ID": "Kontaktnummer",
    "CONTACT_ID_COPIED": "Kontakt-ID wurde kopiert.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Kontakt",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Eskalationen",
    "CONTACT_REASSIGNED_TO_AGENT": "Der Kontakt wurde einem anderen Agenten zugewiesen.",
    "CONTACT_SELECT_CHECKBOX": "Kontrollkästchen zur Auswahl eines gesperrten Kontakts",
    "CONTACT_STATUS_ESCALATION_BADGE": "Eskalation",
    "CONTACT_TICKET": "Kontaktticket",
    "CONTACT_TICKET_CONTENT": "Ticketinhalt",
    "CONTACT_TICKET_CREATE": "Kontaktticket erstellen",
    "CONTACT_TICKET_CREATION_FAILURE": "Kontaktticket konnte nicht erstellt werden",
    "CONTACT_TICKET_CREATION_SUCCESS": "Kontaktticket erstellt",
    "CONTACT_TICKET_RESOLVER_GROUP": "Resolver-Gruppe",
    "CONTACT_TICKET_SEVERITY": "Schweregrad des Tickets",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM-Ordner-ID",
    "CONTACT_TICKET_SUBJECT": "Ticketbetreff",
    "CONTACT_TIMELINE": "Kontaktzeitleiste",
    "CONTACT_TYPE": "Kontakttyp",
    "CONTACT_WAS_TRANSFERRED": "Der Kontakt wurde übertragen.",
    "CONTENT": "Inhalt",
    "CONTENT_RESTRICTED": "Der Inhalt ist eingeschränkt.",
    "COPY_AGENT_SELECTED_ISSUE": "Ausgewähltes Problem des Agenten kopieren: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Amazon Connect Kontakt-ID kopieren",
    "COPY_CALL_LEG_ID": "Leg.Nr. des Anrufes kopieren: {value}",
    "COPY_CONTACT_ID": "Kontaktnummer kopieren: {value}",
    "COPY_CUSTOMER_ID": "Kundennummer kopieren",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Kopieren des vom Kunden ausgewählten Problems: {value}",
    "COPY_FAILURE": "Fehler beim Kopieren.",
    "COPY_ID": "ID kopieren",
    "COPY_LINK": "Link zum Hochladen von Bildern kopieren",
    "COPY_ORDER_ID": "Bestellnummer kopieren: {value}.",
    "CREATE_CASE": "Fall erstellen",
    "CREATE_FOLLOW_UP": "Follow-Up erstellen",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Follow-up wurde erfolgreich erstellt, konnte aber keine entsprechenden Anmerkungen hinzufügen. Versuche erneut, die Anmerkungen manuell hinzuzufügen.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Gib die bevorzugte Kontaktart des Kunden an.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Es kann kein Follow-up mit einem Fälligkeitsdatum und einer Uhrzeit in der Vergangenheit erstellt werden.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Gib Datum und Uhrzeit an, an dem die Follow-up-Aktion fällig ist.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Zeitzone",
    "CREATE_FOLLOW_UP_FAILURE": "Follow-Up konnte nicht erstellt werden",
    "CREATE_FOLLOW_UP_INVALID": "Ungültiges Follow-up. Bitte stelle sicher, dass keine eingeschränkten Daten vorliegen.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Gib die Follow-up-Aktion ein.",
    "CREATE_FOLLOW_UP_SUCCESS": "Follow-up erfolgreich erstellt.",
    "CREATE_WORK_REQUEST": "Arbeitsanforderung erstellen",
    "CROSS_MARKETPLACE_QUEUES": "Über Marketplace-Warteschlangen hinweg",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Fehler beim Übertragen des Kontakts, da er bereits gelöst ist.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "Der Vorgang ist fehlgeschlagen, da sich der Kontakt in einem ungültigen Zustand befindet.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Fehlgeschlagener Vorgang, weil der Kontakt nicht gefunden wurde.",
    "CSALT_ERROR_INTERNAL_ERROR": "Kontakt konnte nicht gesperrt werden",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Kontakt konnte nicht gesperrt werden, da Argumente ungültig sind",
    "CSALT_ERROR_INVALID_INPUT": "Der Vorgang ist aufgrund ungültiger Eingaben fehlgeschlagen.",
    "CSALT_ERROR_INVALID_QUEUE": "Fehler beim Übertragen des Kontakts, da die ausgewählte Warteschlange ungültig ist.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Fehlgeschlagener Vorgang, da Kontakt an einen anderen Agenten gesperrt ist.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Kontakt konnte nicht gesperrt werden, da es einen Abhängigkeitsfehler gibt",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Fehler beim Übertragen des Kontakts, da die Warteschlange nicht im Backend-Dienst gefunden wurde.",
    "CSALT_ERROR_UNKNOWN": "Der Vorgang ist aufgrund eines unerwarteten Fehlers fehlgeschlagen.",
    "CURRENT_CONTACT": "Aktueller Kontakt",
    "CUSTOMER": "Kunde",
    "CUSTOMER_FOLLOWUPS": "Follow-ups für Kunden",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Follow-ups für Kunden konnten nicht abgerufen werden",
    "CUSTOMER_ID_COPIED": "Kundennummer wurde kopiert",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Kundeninhalte sind eingeschränkt. (Keine Kontakte gesperrt.)",
    "CUSTOMER_SELECTED_ISSUE": "Vom Kunden ausgewähltes Problem",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Vom Kunden ausgewähltes Problem wurde kopiert.",
    "CUSTOMER_TIMEZONE": "Zeitstempel, die im Kundenbereich angezeigt werden",
    "DATE": "Datum",
    "DATE_PICKER_NEXT_MONTH": "Nächster Monat",
    "DATE_PICKER_PREVIOUS_MONTH": "Vorheriger Monat",
    "DATE_PICKER_TODAY": "Heute",
    "DECODING_AUDIO": "Decodierung von Audio",
    "DELETE_ATTACHMENTS": "Löschen von Kontaktanhängen",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Anhänge löschen",
    "DELIVERY_STATUS": "Lieferstatus",
    "DISABLE_CONTENT_FILTER": "Inhaltsfilter deaktivieren",
    "DOWNLOAD": "Herunterladen",
    "DURATION": "Dauer",
    "EMAIL": "E-Mail-Adresse",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Die E-Mail-Adresse(n) des Empfängers ist nicht zulässig.",
    "EMAIL_BODY": "E-Mail-Text",
    "EMAIL_CONTACT": "E-Mail-Kontakt",
    "EMAIL_HEADER": "Überschrift",
    "EMAIL_QUEUE_TITLE": "E-Mail-Warteschlange",
    "EMAIL_RESTRICTED": "E-Mail-Inhalte sind eingeschränkt.",
    "EMAIL_SENDING_FAILURE": "Das Senden der ausgehenden E-Mail ist fehlgeschlagen.",
    "EMAIL_SUBJECT": "E-Mail-Betreff",
    "EMAIL_TITLE": "E-Mail mit dem Amazon-Kundenservice",
    "END": "Ende",
    "END_DATE": "Enddatum",
    "ERROR": "Fehler",
    "ERROR_FAILED_TO_LOAD_DATA": "Daten konnten nicht geladen werden.",
    "ESCALATION_CONTACT_HANDLING": "Bearbeitung von Weiterleitungskontakt",
    "ESCALATION_TAB_LEAD_LOGIN": "Anmeldung Vorgesetzte: {agentLogin}",
    "EXPANDED": "Erweitert",
    "FAILED_TO_CREATE_WORK_REQUEST": "Fehler beim Erstellen der Arbeitsanforderung",
    "FAILED_TO_FETCH_SYSKA": "SYSKA-Benachrichtigungen konnten nicht abgerufen werden",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Kontakt konnte einem anderen Agenten nicht zugewiesen werden.",
    "FETCHED_NUMBER_CONTACTS": "{number} Kontakte abgerufen.",
    "FILTER": "Filter",
    "FINDER": "Finder",
    "FOLLOW_UPS_COLLAPSED": "Follow-ups minimiert",
    "FOLLOW_UPS_EXPANDED": "Follow-ups erweitert",
    "FOLLOW_UP_ALERT": "ein Follow-up ist fällig am {dueDate} erstellt von {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Kontaktmethode",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Die Kontaktmethode ist eingeschränkt.",
    "FOLLOW_UP_DUE": "Follow-up fällig",
    "FOLLOW_UP_DUE_DATE": "Fälligkeitsdatum",
    "FOLLOW_UP_NOTE": "Hinweis",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Keine Kontaktmethode",
    "FOLLOW_UP_NO_NOTE": "Hinweis ist leer.",
    "FOLLOW_UP_NO_TICKET": "Kein Ticket",
    "FOLLOW_UP_OWNER": "Eigentümer",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_OPEN": "Öffnen",
    "FOLLOW_UP_STATUS_RESOLVED": "Gelöst",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Follow-Up-Vorgänge",
    "FROM": "Von",
    "GENERATE_LINK": "Link generieren",
    "HAS_OVERRIDE_TRY_AGAIN": "Filterüberschreibung vorhanden. Bitte versuche es erneut.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "Mit Kunden geteilter Bildanhang-Link",
    "INPUT_CHARACTERS_REMAINING": "Maximal {char_max} Zeichen ({char_remain} verbleibend)",
    "INTERNAL_ERROR": "Interner Fehler",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Ein interner Fehler ist aufgetreten. Wenn der Fehler weiterhin besteht, erstellen Sie bitte ein Ticket mit der Beschreibung des Problems.",
    "INVALID_CONTACT": "Ungültiger Kontakt",
    "INVALID_DATA_MATCH": "Das System hat eine ungültige Klassifizierung von Daten durchgeführt.",
    "INVALID_TIMESTAMP": "Ungültiger Zeitstempel.",
    "IN_MARKETPLACE_QUEUES": "In Marketplace-Warteschlangen",
    "ISSUE": "Problem",
    "ISSUE_CODE": "Problemcode",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Bereits in einem früheren Kontakt adressiert und gelöst",
    "ISSUE_CODE_DESCRIPTION": "Wählen Sie den Grund für die Markierung ausgewählter Kontakte als Duplikat.",
    "ISSUE_CODE_EXACTLY_SAME": "Genau das Gleiche wie bei einem früheren Kontakt",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Von einem anderen Chat-CSA behandelt und gelöst",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Von einem anderen E-Mail-CSA bearbeitet und gelöst",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Von einem anderen Telefon-CSA behandelt und gelöst",
    "ITEMS_CONTACTED_ABOUT": "Vom Kunden genannte(r) Artikel",
    "I_UNDERSTAND": "Ich habe dies verstanden.",
    "KNOWN_ISSUES": "Bekannte Probleme",
    "LEARN_MORE": "Weitere Informationen",
    "LINK_COPIED": "Link kopiert",
    "LOADING": "Daten werden geladen",
    "LOADING_PREVIEW_TEXT": "Vorschau wird geladen",
    "LOADING_RECORDING": "Aufnahme wird geladen",
    "LOAD_MORE": "Mehr laden",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Weitere Anmerkungen laden.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Weitere Kontakte für diesen Kunden laden.",
    "LOCKED": "Gesperrt",
    "LOCKED_BY": "Gesperrt von",
    "LOCK_CONTACT": "Sperren",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Kontakt konnte nicht gesperrt werden, da der Benutzer nicht autorisiert ist",
    "LOCK_CONTACT_SUCCESSFUL": "Kontakt gesperrt",
    "MISSING_ORIGINAL_CONTACT_ID": "Bitte geben Sie eine gültige ursprüngliche Kontakt-ID ein.",
    "MODAL_NO_BUTTON_TEXT": "Nein",
    "MODAL_YES_BUTTON_TEXT": "Ja",
    "NEXT": "Weiter",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Kein vom Agenten ausgewähltes Problem",
    "NO_ANNOTATED_BY": "Kommentiert von unbekanntem Benutzer.",
    "NO_ANNOTATIONS": "Keine Anmerkungen verfügbar.",
    "NO_ATTACHMENTS": "Es sind keine Anhänge vorhanden.",
    "NO_CHAT": "Chat ist leer.",
    "NO_CHILD_WORK_REQUESTS": "Von diesem Kontakt wurden keine Arbeitsanfragen erstellt",
    "NO_CONTACT": "Kein Kontakt verfügbar",
    "NO_CONTACTS_SELECTED": "Bitte wähle Kontakte aus, um sie als Spam zu verarbeiten.",
    "NO_CONTENT": "Kein Inhalt",
    "NO_CUSTOMER_SELECTED_ISSUE": "Kein vom Kunden ausgewähltes Problem",
    "NO_DELIVERY_STATUS": "Kein Lieferstatus",
    "NO_EMAIL_BODY": "E-Mail-Adresse fehlt",
    "NO_EMAIL_HEADER": "Keine E-Mail-Überschrift.",
    "NO_EMAIL_QUEUE": "Diesem Kontakt ist keine E-Mail-Warteschlange zugeordnet.",
    "NO_ITEMS_CONTACTED_ABOUT": "Über keine Artikel kontaktiert.",
    "NO_RESULTS": "Keine Ergebnisse gefunden.",
    "NO_SCREEN_POP": "Keine Screenpop-Informationen",
    "NO_SENT_FROM": "Keine Absenderadresse.",
    "NO_SENT_TO": "Keine Zieladresse gesendet",
    "NO_SUBJECT": "Betreffzeile ist leer.",
    "NO_SYSKA_ALERTS": "SYSKA-Benachrichtigungen",
    "NO_USED_BLURBS": "Keine Autotexte für diesen Kontakt verwendet.",
    "NO_VIEW": "Kein Kontakt oder Kunde zum Anzeigen.",
    "NO_WORK_CATEGORY": "Keine Arbeitskategorie ist diesem Kontakt zugeordnet.",
    "NUMBER_OF_CONTACTS": "Geladene Kontakte",
    "OLDEST_LOADED_CONTACT": "Ältester geladener Kontakt",
    "OPEN": "Öffnen",
    "OPENS_IN_A_NEW_TAB": "Wird in einem neuen Tab geöffnet",
    "ORDER_ID_COPIED": "Bestellnummer wurde kopiert.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Geben Sie die ursprüngliche Kontakt-ID ein.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Textfeld zur Eingabe der ursprünglichen Kontakt-ID",
    "OTHER": "Andere",
    "OUTBOUND_EMAIL_BCC": "und Bcc -",
    "OUTBOUND_EMAIL_CC": "beim Kopie senden -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Benachrichtigungsinhalt minimiert.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Benachrichtigungsinhalt erweitert.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Maximale Anzahl der Ergebnisse erreicht. Bitte reduziere den Zeitbereich und versuche es erneut.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Für den aktuellen Prozess sind PII-Inhalte erforderlich.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Ungültige Übereinstimmung der Telefonnummer, E-Mail-Adresse oder anderer PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Ungültige Übereinstimmung der Kreditkarte oder IBAN.",
    "OVERRIDE_REASON_PROMPT": "Kommentare dürfen keine Kunden-PII-Daten wie Telefonnummern und E-Mail-Adressen enthalten. Unser automatisiertes System versucht, dies durchzusetzen. Der Unterschied zwischen PII und anderen IDs wird jedoch nicht immer erkannt. Gib an, warum eine Überschreibung erforderlich ist.",
    "PAPYRUS_SESSION_EXPLANATION": "Bitte gib deinen Papyrus-Sitzungstyp an, damit wir die richtigen Sicherheitsbeschränkungen anwenden und die UX für deine Bedürfnisse optimieren können.",
    "PAPYRUS_SESSION_TYPE": "Papyrus-Sitzungstyp",
    "PAUSE": "Pause",
    "PHONE_CONTACT": "Telefonkontakt",
    "PLAY": "Abspielen",
    "PLAYBACK_SPEED": "Wiedergabegeschwindigkeit",
    "POSTAL_MAIL_TITLE": "Briefpost mit Amazon-Kundenservice",
    "PREPARING_RECORDING": "Aufzeichnung wird vorbereitet",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Vorschau für Kontakt konnte nicht erstellt werden",
    "PREVIOUS": "Zurück",
    "PRE_TRANSLATED_REPLY_EMAIL": "Vorübersetzte Antwort-E-Mail",
    "PRE_TRANSLATION_REPLY_TEXT": "Vom CSA-eingegebene Antwort vor der Übersetzung mit Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Verarbeitungsvorgänge",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Als Duplikat verarbeiten",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Als Spam verarbeiten",
    "PROCESS_CONTACT_AS_DUPLICATE": "Ausgewählte Kontakte als Duplikat auflösen.",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Sind Sie sicher, dass Sie ausgewählte Kontakte als Spam verarbeiten möchten?",
    "PROCESS_DUPLICATE_FAILURE": "Ausgewählte Kontakte konnten nicht als Duplikat aufgelöst werden.",
    "PROCESS_DUPLICATE_SUCCESS": "Ausgewählte Kontakte erfolgreich als Duplikat gelöst.",
    "PROCESS_SPAM_FAILURE": "Ausgewählte Kontakte konnten nicht in Spam übertragen werden.",
    "PROCESS_SPAM_SUCCESS": "Ausgewählte Kontakte erfolgreich in Spam übertragen.",
    "PROVIDE_ADDITIONAL_INFORMATION": "Gib zusätzliche Informationen an, die für diesen Kontakt relevant sind",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Vertrauliche Informationen aus Kontakt gelöscht:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Wort zur Löschliste hinzufügen",
    "PURGE_BUTTON_TEXT": "Löschen",
    "PURGE_CONFIRMATION_CHECK": "Ich habe die oben angezeigten Felder überprüft und bestätige, dass der Text vertrauliche Informationen enthält.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Ich bestätige, dass der Anhang tatsächlich vertrauliche Kundeninformationen enthält.",
    "PURGE_CONFIRMATION_UNCHECKED": "Bitte bestätigen Sie das Kontrollkästchen.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Fehler beim Löschen aus Kontakt",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Die Informationen wurden erfolgreich aus dem Kontakt gelöscht.",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} ist kein gültiger Grund für das Löschen",
    "PURGE_REASON_FINANCIAL_INFO": "Finanzielle Informationen",
    "PURGE_REASON_OTHER": "Weitere vertrauliche Informationen",
    "PURGE_REASON_PII": "Personenbezogene Daten",
    "PURGE_REASON_PLACEHOLDER": "Geben Sie den Grund für das Löschen ein",
    "PURGE_REASON_TEXT": "Grund für das Löschen",
    "PURGE_REMOVE_LABEL": "Entfernen",
    "PURGE_SELECTOR_PLACEHOLDER": "Grund für das Löschen vertraulicher Informationen auswählen",
    "PURGE_SENSITIVE_INFO": "Vertrauliche Informationen löschen",
    "PURGE_WORD_NOT_FOUND": "Wort wurde im Text des Kontakts nicht gefunden",
    "PURPOSE_AUDITING": "Überwachung zur Qualitätssicherung",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Back-Office-Workflows",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Dies ist die Sitzung zum Abrufen von Kontaktdaten für Back-Office-Workflows. Beispielsweise Anhänge, die in Tickets oder SIM aufgenommen werden sollen.",
    "PURPOSE_COACHING": "Überprüfung des Coaching-Kontakts",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Compliance-Untersuchung",
    "PURPOSE_CURATION": "Kuration von Kontakten",
    "PURPOSE_CURATION_DESCRIPTION": "Dies ist die Sitzung für die Kuration von C2CS/VoC-Kontakten.",
    "PURPOSE_DESCRIPTION_AUDITING": "Dies ist die Sitzung zur Überprüfung von Kontakten und zur Bewertung ihrer Qualität.",
    "PURPOSE_DESCRIPTION_COACHING": "Dies ist die Sitzung zur Überprüfung von Kontakten von Agenten, für die du ein Performance-Portal-Coach bist.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Dies ist die Sitzung für Untersuchungen zur Überwachung verdächtiger Aktivitäten.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Dies ist die Sitzung zur Bearbeitung von Weiterleitungskontakten. Dies ermöglicht den Zugriff auf die Kontaktdaten eines Kunden.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Dies ist die Sitzung für die Bearbeitung von Live-Kontakten.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Mit dieser Aktion sollen vertrauliche Informationen aus einem Kontakt entfernt werden.",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Dies ist die Sitzung zur Überprüfung der Genauigkeit des Machine-Learning-Modells.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Dies ist die Sitzung zur Überprüfung Ihrer eigenen vergangenen Kontakte.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Dies ist die standardmäßige Allzweck-Sitzung.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Bearbeitung von Weiterleitungskontakt",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Vertrauliche Informationen löschen",
    "REASON_FOR_PURGE": "Grund für das Löschen",
    "REASON_FOR_PURGE_DESCRIPTION": "Grund für das Löschen von Anhängen",
    "REASON_FOR_PURGE_MISSING": "Bitte geben Sie den Grund für das Löschen von Anhängen ein.",
    "REASSIGN_TO_AGENT": "Einem andern Agenten zuweisen",
    "RECORDING": "Aufzeichnung",
    "RELOADING_CONTENT": "Inhalt wird neu geladen.",
    "RELOAD_LIST": "Liste neu laden",
    "REPLY_BY": "Antwort bis",
    "REPLY_BY_EMAIL": "Antwort per E-Mail",
    "REPLY_BY_PHONE": "Per Telefon antworten",
    "REPLY_RESTRICTED": "Antwortinhalte sind eingeschränkt.",
    "REQUEST": "Anfrage",
    "REQUEST_ACCESS_INSTRUCTIONS": "Gib den Grund an, warum der Zugriff auf eingeschränkte Inhalte erforderlich ist. (Diese Anfragen werden geprüft. Missbrauch kann ernsthafte Konsequenzen haben.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Zugriff auf gefilterte Inhalte anfordern",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Zugriff auf eingeschränkte Inhalte anfordern",
    "REQUEST_NOT_GRANTED": "Die Anfrage wurde nicht gewährt.",
    "REQUEST_OVERRIDE": "Überschreibung anfordern",
    "RESEND": "Erneut senden",
    "RESEND_FAILED": "Fehler beim erneuten Senden der E-Mail.",
    "RESEND_SUCCESS": "E-Mail erfolgreich erneut gesendet.",
    "RESEND_WAIT_WARNING": "Du musst {wait_mins} Minute(n) warten, um erneut senden zu können.",
    "RESEND_WARNING": "Möchtest du die E-Mail wirklich erneut an den Kunden senden?",
    "RESET": "Zurücksetzen",
    "RESOLVED": "Gelöst",
    "RESOLVED_BY": "Gelöst von",
    "RESTRICTED_DATA_MATCH": "{match} passender Typ {type}",
    "RESTRICTED_INFORMATION": "EINGESCHRÄNKTE INFORMATIONEN",
    "RETRY": "Erneut versuchen",
    "ROLE": "Rolle",
    "SCREEN_POP_DESCRIPTION_TITLE": "Beschreibung",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Nein",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Ja",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Dem Mitarbeiter angezeigt",
    "SCREEN_POP_TITLE": "ScreenPop-Informationen",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Der Authentifizierungs-Screenpop wurde dem Agenten angezeigt und er wurde verwendet.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Der Authentifizierungs-Screenpop wurde dem Agenten angezeigt.",
    "SCREEN_POP_TYPE_CALLER_ID": "Der Anrufer-ID-Screenpop wurde dem Agenten angezeigt und es wurde verwendet.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Der Anrufer-ID-Screenpop wurde dem Agenten angezeigt.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Nicht authentifizierter Screenpop wurde angezeigt und verwendet.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Nicht authentifizierter Authentifizierungs-Screenpop wurde dem Agenten angezeigt.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Der Kunde ist unbekannt. Kein Bildschirm bereitgestellt.",
    "SCREEN_POP_TYPE_OTHER": "Sonstiges",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Nein",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Ja",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Von Agent verwendet",
    "SDS_TOOLS": "Support-Tool(s) für Versand und Lieferung",
    "SEARCH": "Suchen",
    "SELECTED_ROLE": "Ausgewählte Rolle",
    "SELECTED_VIEW": "Ausgewählte Ansicht",
    "SELECT_CHANNELS": "Kanäle auswählen",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Um Problemcode auszuwählen, um ausgewählte Kontakt als Duplikat zu markieren.",
    "SELECT_TRANSFER_DESTINATION": "Weiterleitungsziel auswählen",
    "SELECT_WORK_REQUEST_QUEUE": "Arbeitsanforderungs-Warteschlange auswählen",
    "SENDER_EMAIL_ADDRESS": "Absender",
    "SENDING_EMAIL": "E-Mail wird gesendet.",
    "SEND_EMAIL": "E-Mail senden",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Bist du sicher, dass du den Link zum Hochladen von Bildern an den Kunden senden möchtest?",
    "SEND_OUTBOUND_EMAIL": "Ausgehende E-Mail senden",
    "SEND_OUTBOUND_EMAIL_WARNING": "Bist du sicher, dass du ausgehende E-Mails senden möchtest an",
    "SENT_FROM": "Gesendet von",
    "SENT_OUTBOUND_EMAIL": "Ausgehende E-Mail wurde gesendet. Ein neuer Kontakt wurde erstellt",
    "SENT_TO": "Gesendet an",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Versandportal",
    "SKILL": "Fähigkeit",
    "SKIP_SILENCES": "Stille Bereiche überspringen",
    "SMS": "SMS",
    "SMS_LINK": "SMS-Verbindung",
    "SOURCE_TEXT": "Quelltext",
    "SP_BOTTOM": "Unten",
    "SP_POSITION": "Geteilte Panelposition",
    "SP_PREFERENCES": "Einstellungen für geteilte Panels",
    "SP_RESIZE": "Größe des geteilten Panels ändern",
    "SP_SIDE": "Seite",
    "START": "Start",
    "STATE_TRANSITIONS": "Zustandswechsel",
    "STATUS": "Status",
    "SUBJECT": "Betreff",
    "SYSKA_ALERTS": "SYSKA-Benachrichtigungen",
    "TEXT_TO_PURGE": "Text, der gelöscht werden soll",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Cookies von Drittanbietern müssen aktiviert sein.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "PII-Kundendaten wie Telefonnummern und E-Mail-Adressen sind beim Erstellen eines Tickets nicht zulässig. Unser automatisiertes System versucht, dies durchzusetzen, kennt aber manchmal nicht den Unterschied zwischen PII und anderen IDs. Geben Sie an, warum eine Überschreibung erforderlich ist.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Zeitüberschreitung: Fehler beim Laden der Aufzeichnung",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Verwende das 24-Stunden-Format.",
    "TO": "Bis",
    "TO_EMAIL_ADDRESS": "An",
    "TRANSFER": "Weiterleitung",
    "TRANSFERRED_CHAT_TITLE": "Weitergeschickter Chat mit Amazon-Kundenservice",
    "TRANSFERRING": "Wird übertragen ...",
    "TRANSFER_CONTACT": "Kontakt weiterleiten",
    "TRANSFER_CONTACT_COMMENTS": "Optionale Anmerkung",
    "TRANSFER_FAILED": "Transfer fehlgeschlagen.",
    "TRANSFER_FOLLOW_UP": "Follow-Up weiterleiten",
    "TRANSFER_FOLLOW_UP_FAILED": "Fehler beim Weiterleiten des Follow-ups.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Die Weiterleitung des Follow-ups konnte aufgrund der ungültigen Anmeldung des neuen Besitzers nicht durchgeführt werden.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Du bist nicht berechtigt, dieses Follow-up weiterzuleiten.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Gib den Anmeldenamen des neuen Besitzers ein. Beispiel: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Obiges Follow-up weiterleiten zu:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Follow-up erfolgreich weitergeleitet.",
    "TRANSLATED_TEXT": "Übersetzter Text",
    "TRANSLATION": "Übersetzung",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} ist kein gültiger Warteschlangenname.",
    "TYPE_AGENT_LOGIN": "Agenten-Anmeldung angeben",
    "TYPE_EMAIL_ADDRESS": "E-Mail-Adresse eingeben",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Mehrere E-Mail-Adressen durch Komma getrennt eingeben",
    "TYPE_QUEUE_NAME": "Gib den Warteschlangennamen ein.",
    "UNKNOWN_CONTACT_TYPE": "Unbekannter Kontakttyp.",
    "UNLOCKED": "Entsperrt",
    "UNRESOLVED": "Nicht gelöst",
    "URGENT_SYSKA_ALERTS": "Dringende SYSKA-Warnungen",
    "USE": "Verwenden",
    "USER_TIMEZONE": "Zeitstempel, die in deiner lokalen Zeitzone angezeigt werden",
    "USE_CONTACT_FINDER": "Verwenden Sie den Kontaktfinder",
    "VIEW": "Anzeigen",
    "WARNING": "Warnung",
    "WELCOME_MESSAGE": "Willkommen auf der Website von Papyrus Static!",
    "WHAT_IS_PAPYRUS": "Was ist Papyrus?",
    "WITH_THE_ANNOTATION": "mit der Anmerkung",
    "WORK_CATEGORY_TITLE": "Arbeitskategorie",
    "WORK_REQUEST": "Arbeitsanforderung",
    "WORK_REQUEST_CONTENT": "Inhalt der Arbeitsanforderung",
    "WORK_REQUEST_CREATED": "Arbeitsanforderung erstellt",
    "WORK_REQUEST_FOR_CONTACT": "Arbeitsanfrage für Kontakt",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "PII-Kundendaten wie Telefonnummern und E-Mail-Adressen sind beim Erstellen einer Arbeitsanfrage nicht zulässig. Unser automatisiertes System versucht, dies durchzusetzen, kennt aber manchmal nicht den Unterschied zwischen PII und anderen IDs. Geben Sie an, warum eine Überschreibung erforderlich ist.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Du bist im Begriff, in die Warteschlange zu übertragen."
}