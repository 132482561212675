import React from "react";
import {Blurb} from "./Contact.types";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import Popover from "@amzn/awsui-components-react/polaris/popover";

interface BlurbDisplayProps {
    blurb: Blurb;
}

const BlurbDisplay: React.FC<BlurbDisplayProps> = ({blurb}) => {
    return (
        <div>
            <Popover
                content={blurb.displayableContent}
                header={blurb.displayableName}
            >{blurb.displayableName}
            </Popover>
        </div>
    );
};

interface BlurbsDisplayProps {
    blurbs: Blurb[] | null;
}

const BlurbsDisplay: React.FC<BlurbsDisplayProps> = ({blurbs}) => {

    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId={"BLURBS_USED_TITLE"}/></h4>
            {blurbs && blurbs.length > 0 ?
                <div>{blurbs.map((blurb) => <BlurbDisplay blurb={blurb}/>)}</div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_USED_BLURBS")}/>}
        </div>
    );
};

export default BlurbsDisplay;
