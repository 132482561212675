import React from "react";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import CSCentralURL from "../issueManager/CSCentralURL";
import {Copyable} from "../util/Copyable";

type ContactLinkProps = {
    contactId: string;
    message?: string;
};

const ContactLink: React.FC<ContactLinkProps> = ({ contactId, message}) => {
    const url = CSCentralURL.sharedCSCentralURL.getURL("gp/communications/issue-manager/", "initialCommId=" + contactId);
    const cscURL = url ? url : "# ";
    const target = cscURL ? "_blank" : "_self";

    return (
        <div>
            <Copyable
                actionTranslationId="COPY_CONTACT_ID"
                successTranslationId="CONTACT_ID_COPIED"
                content={(
                    <> <a target={target} href={url}>{contactId}<Icon name="external"/></a> {message ? message : null} </>

                )}
                valueToCopy={contactId}/>
        </div>
    );
};

export default ContactLink;
