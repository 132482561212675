import {LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {i18n} from "../../i18n/IntlManager";
import {getUserOwner} from "../UserDetailsUtil";
import React, {useContext, useState} from "react";
import {Contact} from "../Contact.types";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

interface SpamAndDuplicateProps {
    contactList: Contact[] | null;
    userId: string;
    singleContact: Contact | null;
}

const SpamAndDuplicate: React.FC<SpamAndDuplicateProps> = ({contactList, userId, singleContact}) => {

    const {reloadSelectedContacts, getSubject, callPhoenix} = useContext(IssueManagerContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [flashBarItems, setFlashBarItems] = useState<ReadonlyArray<FlashBarItemType>>([]);
    const [flashBarMsgId, setFlashBarMsgId] = useState<number>(0);
    const [processAction, setProcessAction] = useState<string>("");
    const [modalViewVisibility, setModalViewVisibility] = useState<boolean>(false);
    const [markDuplicateModalVisibility, setMarkDuplicateModalVisibility] = useState<boolean>(false);
    const [reasonForDuplicate, setReasonForDuplicate] = useState<string>(i18n('ISSUE_CODE_EXACTLY_SAME'));
    const [originalContactId, setOriginalContactId] = useState<string>("");
    const [invalidOriginalContactIdError, setInvalidOriginalContactIdError] = useState<string>("");
    type FlashBarItemType = FlashbarProps.MessageDefinition & { itemId: number };

    const bulkActionsButton = [
        {disabled: false, text: i18n("PROCESS_AS_SPAM_BUTTON_TEXT"), id: "SPAM"},
        {disabled: false, text: i18n("PROCESS_AS_DUPLICATE_BUTTON_TEXT"), id: "DUPLICATE"}
    ];

    let contactsToUpdate: string[] = [];

    const pushNotification = (msg: JSX.Element, type: FlashbarProps.Type): void => {
        const msgToAdd: FlashBarItemType = {
            content: msg,
            itemId: flashBarMsgId,
            onDismiss: () => setFlashBarItems(items =>
                items.filter(item => item.itemId !== flashBarMsgId)
            ),
            dismissible: true,
            type: type,
        };
        setFlashBarItems([...flashBarItems, msgToAdd]);
        setFlashBarMsgId(flashBarMsgId + 1);
    };

    async function processActions(action: string | undefined): Promise<void> {
        contactsToUpdate = [];
        if (!singleContact?.isSelected && (!contactList || contactList.filter(c => c.isSelected).length === 0)) {
            pushNotification((<div>{i18n("NO_CONTACTS_SELECTED")}</div>), 'info');
        } else {
            switch (action) {
                case "SPAM" :
                    await setModalViewVisibility(true);
                    break;
                case "DUPLICATE":
                    await setMarkDuplicateModalVisibility(true);
                    break;
                default:
                    break;
            }
        }
    }

    async function processContacts(action: string): Promise<void> {
        setIsLoading(true);
        let allContactsProcessed = true;

        if (singleContact?.isSelected) {
            allContactsProcessed = await processContact(action, singleContact);
        }

        if (contactList) {
            for (const currentContact of contactList) {
                if (currentContact.isSelected) {
                    allContactsProcessed = allContactsProcessed && await processContact(action, currentContact);
                }
            }
        }

        showPushNotification(allContactsProcessed, action);

        await reloadSelectedContacts(contactsToUpdate);
        setIsLoading(false);
    }

    async function processContact(action: string, currentContact: Contact): Promise<boolean> {
        if (action === "SPAM") {
            return await moveContactToSpam(currentContact);
        }

        return await resolveContactAsDuplicate(currentContact);
    }

    function showPushNotification(allContactsProcessed: boolean, action: string): void {
        if (allContactsProcessed) {
            if (action === "SPAM") {
                pushNotification((<div>{i18n("PROCESS_SPAM_SUCCESS")}</div>), 'success');
            } else if (action === "DUPLICATE") {
                pushNotification((<div>{i18n("PROCESS_DUPLICATE_SUCCESS")}</div>), 'success');
            }
        } else {
            if (action === "SPAM") {
                pushNotification((<div>{i18n("PROCESS_SPAM_FAILURE")}</div>), 'error');
            } else if (action === "DUPLICATE") {
                pushNotification((<div>{i18n("PROCESS_DUPLICATE_FAILURE")}</div>), 'error');
            }
        }
    }

    async function moveContactToSpam(contact: Contact): Promise<boolean> {
        try {
            const response = await callPhoenix(getSpamMutation(contact));
            if (response.httpStatus === 200) {
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (!parsed?.data?.transferContactToSpam.failure) { //success
                        contactsToUpdate.push(contact.contactId);
                        return true;
                    } else {
                        Phoenix.getInstance().log(LogLevel.WARN, "Failed to transfer contact to spam for contact: " + contact.contactId + " " + parsed?.data?.transferContactToSpam.failure?.errorMessageDescriptor.stringId);
                        Phoenix.getInstance().addMetric("TransferContactToSpam.Failure:" + parsed?.data?.transferContactToSpam.failure?.errorMessageDescriptor.stringId, 1, MetricType.COUNT);
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to transfer contact to spam:" + contact.contactId + " " + response.error);
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to transfer contact to spam:" + contact.contactId + " " + error);
        } finally {
            setModalViewVisibility(false);
            setIsLoading(false);
        }

        return false;
    }

    async function resolveContactAsDuplicate(contact: Contact): Promise<boolean> {
        try {
            const response = await callPhoenix(getResolveAsDuplicateMutation(contact));
            if (response.httpStatus === 200) {
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (!parsed?.data?.resolveContactAsDuplicate.failure) { //success
                        contactsToUpdate.push(contact.contactId);
                        return true;
                    } else {
                        Phoenix.getInstance().log(LogLevel.WARN, "Failed to resolve contact as duplicate: " + contact.contactId + " " + parsed?.data?.resolveContactAsDuplicate.failure?.errorMessageDescriptor.stringId);
                        Phoenix.getInstance().addMetric("ResolveContactAsDuplicate.Failure:" + parsed?.data?.resolveContactAsDuplicate.failure?.errorMessageDescriptor.stringId, 1, MetricType.COUNT);
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to resolve contact as duplicate:" + contact.contactId + " " + response.error);
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to resolve contact duplicate:" + contact.contactId + " " + error);
        } finally {
            setMarkDuplicateModalVisibility(false);
            setOriginalContactId("");
            setIsLoading(false);
        }

        return false;
    }

    function getSpamMutation(contact: Contact): string {
        const requestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
        return (
            `mutation {
                transferContactToSpam(input:{
                    obfuscatedContactId: "${contact.contactId}",
                    expectedOwnerAgent:{agentLogin:"${userId}", agentOwner:"${getUserOwner(getSubject())}"},
                    idempotencyKey:"${requestId}"
                }) {
                    failure {
                        errorType
                        errorMessageDescriptor {
                            stringId
                      }
                    }
                }
            }`);
    }

    function getResolveAsDuplicateMutation(contact: Contact): string {
        const requestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
        return (
            `mutation {
                resolveContactAsDuplicate(input:{
                    obfuscatedContactId: "${contact.contactId}",
                    expectedOwnerAgent:{agentLogin:"${userId}", agentOwner:"${getUserOwner(getSubject())}"},
                    idempotencyKey:"${requestId}"
                    obfuscatedOriginalContactId: "${originalContactId.trim()}"
                    reason: "${reasonForDuplicate}"
                }) {
                    failure {
                        errorType
                        errorMessageDescriptor {
                            stringId
                      }
                    }
                }
            }`);
    }

    return (
        <div>
            <SpaceBetween size={"l"}>
                <div style={{float: "right"}}>
                    <SpaceBetween size={"xs"} direction={"horizontal"}>
                        <ButtonDropdown
                            items={bulkActionsButton}
                            onItemClick={async (event): Promise<void> => {
                                setProcessAction(event.detail.id);
                                await processActions(event.detail.id);
                            }}
                        > {i18n("PROCESS_ACTIONS_DROPDOWN_TEXT")}
                        </ButtonDropdown>
                    </SpaceBetween>
                </div>
                <Flashbar items={flashBarItems}/>
            </SpaceBetween>
            <Modal
                onDismiss={(): void => setModalViewVisibility(false)}
                visible={modalViewVisibility}
                closeAriaLabel={i18n("CLOSE_MODAL_BUTTON_TEXT")}
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            {isLoading ?
                                <Spinner size="normal"/> : null}
                            <Button variant="link"
                                    onClick={(): void => setModalViewVisibility(false)}>{i18n("MODAL_NO_BUTTON_TEXT")}</Button>
                            <Button variant="primary" onClick={(): Promise<void> => processContacts(processAction)}
                                    disabled={isLoading}>{i18n("MODAL_YES_BUTTON_TEXT")}</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={i18n("WARNING")}
            >
                {i18n("PROCESS_CONTACT_AS_SPAM_WARNING")}
            </Modal>
            <Modal
                onDismiss={(): void => setMarkDuplicateModalVisibility(false)}
                visible={markDuplicateModalVisibility}
                closeAriaLabel={i18n("CLOSE_MODAL_BUTTON_TEXT")}
                size="medium"
                header={
                    i18n("PROCESS_CONTACT_AS_DUPLICATE")
                }
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            {isLoading ?
                                <Spinner size="normal"/> : null}
                            <Button variant="link"
                                    onClick={(): void => setMarkDuplicateModalVisibility(false)}>{i18n("MODAL_NO_BUTTON_TEXT")}</Button>
                            <Button variant="primary" onClick={async (): Promise<void> => {
                                if (originalContactId.trim() !== "") {
                                    await processContacts(processAction);
                                } else {
                                    setInvalidOriginalContactIdError(i18n("MISSING_ORIGINAL_CONTACT_ID"));
                                }
                            }}
                                    disabled={isLoading}>{i18n("MODAL_YES_BUTTON_TEXT")}</Button>
                        </SpaceBetween>
                    </Box>
                }
            >{
                <SpaceBetween size={"l"} direction={"vertical"}>
                    <FormField
                        label={i18n('ISSUE_CODE')}
                        description={i18n("ISSUE_CODE_DESCRIPTION")}
                        errorText={invalidOriginalContactIdError}>
                        <SpaceBetween size={"l"} direction={"vertical"}>
                            <RadioGroup
                                onChange={({detail}): void => setReasonForDuplicate(detail.value)}
                                value={reasonForDuplicate}
                                ariaLabel={i18n("SELECT_ISSUE_CODE_RADIO_BUTTON")}
                                ariaDescribedby={i18n("ISSUE_CODE_DESCRIPTION")}
                                items={[
                                    {value: i18n('ISSUE_CODE_EXACTLY_SAME'), label: i18n('ISSUE_CODE_EXACTLY_SAME')},
                                    {
                                        value: i18n('ISSUE_CODE_ALREADY_ADDRESSED'),
                                        label: i18n('ISSUE_CODE_ALREADY_ADDRESSED')
                                    },
                                    {
                                        value: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA'),
                                        label: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA')
                                    },
                                    {
                                        value: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA'),
                                        label: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA')
                                    },
                                    {
                                        value: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA'),
                                        label: i18n('ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA')
                                    }
                                ]}
                            />
                            <Input ariaLabel={i18n("ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX")}
                                   ariaDescribedby={i18n("ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT")}
                                   type={"text"} value={originalContactId}
                                   placeholder={i18n("ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT")}
                                   onChange={
                                       ({detail}): void => {
                                           detail.value !== "" ? setInvalidOriginalContactIdError("") : setInvalidOriginalContactIdError(i18n("MISSING_ORIGINAL_CONTACT_ID"));
                                           setOriginalContactId(detail.value);
                                       }
                                   }/>
                        </SpaceBetween>
                    </FormField>
                </SpaceBetween>
            }
            </Modal>
        </div>
    );
};

export default SpamAndDuplicate;

