import React from "react";
import Moment from "react-moment";

interface TimestampProps {
    date: string;
    customerTimezone: string;
    format?: string;
    withoutTimezone?: boolean;
}

export const Timestamp: React.FC<TimestampProps> = ({date, customerTimezone, format, withoutTimezone}) => {
    return (
        <span>
            {/* Locale aware format:
                LLL - Month name, day of month, year, time

                https://momentjs.com/docs/#/parsing/string-format/
            */}
            <Moment tz={customerTimezone} format={format ? format : "LLL"}>{date}</Moment>&nbsp;{withoutTimezone ? null : (customerTimezone)}
        </span>
    );
};
