import {FollowUp} from "../contact/Contact.types";
import moment from "moment-timezone";

export function sortFollowUps(followUps: FollowUp[]): FollowUp[] {
    return followUps.sort((followUpLeft, followUpRight) => {
        return Date.parse(followUpLeft.dueDate) - Date.parse(followUpRight.dueDate);
    });
}

// date is in the format of YYYY/MM/DD
// timeStr is in the format of hh:mm
export function resolveStringToISO(dateStr: string, timeStr: string, timezone: string): string {
    const dateMoment = moment.tz(`${dateStr} ${timeStr}`, "YYYY/MM/DD HH:mm", timezone);
    return dateMoment.toISOString();
}