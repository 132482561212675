import React, {useContext, useEffect, useState} from "react";
import Translation from "../i18n/Translate";
import {AgentAnnotation, AutoAnnotation, ContactStateTransition, CSAgent, PurgeReasons} from "./Contact.types";
import {Timeline, TimelineEvent} from "react-event-timeline";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import IntlManager, {i18n} from "../i18n/IntlManager";
import {AddText, addTextAndSubmit, AddTextStatus} from "./AddText";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {Match} from "@amzn/csphoenix-react-client";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import {IssueManagerContext} from "../issueManager/IssueManager";
import {AllHtmlEntities} from 'html-entities';
import {Timestamp} from "./Timestamp";
import "./ContactTimelineDisplayStyle.css";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import {Text} from "../util/Text";
import {CreateAnnotationOverrideRequestForm} from "../authorizer/CreateAnnotationOverrideRequestForm";
import {MetricType} from "@amzn/csphoenix-react-client";
import Phoenix from "../api/Phoenix";

interface ContactTimelineDisplayProps {
    contactId: string;
    stateTransitionList: ContactStateTransition[];
    agentAnnotations: AgentAnnotation[];
    autoAnnotations: AutoAnnotation[];
    customerTimezone: string;
    // newAnnotation useState at Contact level
    newAnnotation: string;
    setNewAnnotation: (annotation: string) => void;
}

enum RenderedEntryType {
    AGENT_ANNOTATION,
    AUTO_ANNOTATION,
    STATE_TRANSITION
}

interface RenderedEntry {
    agent: CSAgent | undefined;
    date: string;
    text: string;
    type: RenderedEntryType;
}

export function buildAddAgentAnnotationMutation(contactId: string, text: string): string {
    // Generate a random string for request ID
    const requestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
    const escapedText = JSON.stringify(AllHtmlEntities.encode(text));
    return `mutation { 
        addAgentAnnotation ( input: { contactId: "${contactId}", idempotencyKey: "${requestId}", textEncrypted: ${escapedText} }) { 
            isSuccess 
        }
    }`;
}

const getAutoAnnotationMessage = (autoAnnotation: AutoAnnotation, contactId: string): string => {
    const parsedDescription = JSON.parse(autoAnnotation.description);
    return IntlManager.sharedManager.formatMessage(parsedDescription["stringId"],
        {
            values: parsedDescription["hashGetParams"],
            default: autoAnnotation.description,
            contactId: contactId
        });
};

const getStateTransitionText = (s: ContactStateTransition): string => {
    return s.displayableDestination ?
        `${s.type}: ${s.displayableDestination}` :
        s.type;
};

const keyValuesToReplace = Object.values(PurgeReasons).map(reason => {return reason.valueOf();});
keyValuesToReplace.push("PURGED_SENSITIVE_INFO_FROM_CONTACT");
function replaceKeyStringsWithTranslatedValue(message: string): string {
    for (const key of keyValuesToReplace) {
        if (message.includes(key)) {
            message = message.replace(key, i18n(key));
        }
    }
    return message;
}

const ContactTimelineDisplay: React.FC<ContactTimelineDisplayProps> = ({contactId,
                                                                           stateTransitionList,
                                                                           customerTimezone,
                                                                           agentAnnotations,
                                                                           autoAnnotations,
                                                                           newAnnotation,
                                                                           setNewAnnotation}) => {

    const [renderedEntries, setRenderedEntries] = useState<RenderedEntry[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addTextStatus, setAddTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [restrictedDataMatchList, setRestrictedDataMatchList] = useState<Match[]>([]);
    const { reloadSingleContact, callPhoenix } = useContext(IssueManagerContext);
    const [stateTransitionsChecked, setStateTransitionsChecked] = React.useState(false);
    const [annotationsChecked, setAnnotationsChecked] = React.useState(true);
    const [showOverrideRequestForm, setShowOverrideRequestFrom] = useState<boolean>(false);
    const [metricsEmitted, setMetricsEmitted] = useState<boolean>(false);

    useEffect(() => {
        let sortedEntries: RenderedEntry[] = [];

        if (stateTransitionsChecked) {
            const renderedStateTransitions: RenderedEntry[] = stateTransitionList.map(t => {
                return ({
                    agent: t.agent,
                    date: t.date,
                    text: getStateTransitionText(t),
                    type: RenderedEntryType.STATE_TRANSITION,
                } as RenderedEntry);
            });
            sortedEntries = sortedEntries.concat(renderedStateTransitions);
        }
        if (annotationsChecked) {
            const renderedAgentAnnotations: RenderedEntry[] = agentAnnotations.map(a => {
                return ({
                    agent: a.agent,
                    date: a.date,
                    text: replaceKeyStringsWithTranslatedValue(a.message),
                    type: RenderedEntryType.AGENT_ANNOTATION,
                } as RenderedEntry);
            });
            const renderedAutoAnnotations: RenderedEntry[] = autoAnnotations.map(a => {
                return ({
                    agent: a.agent,
                    date: a.date,
                    text: getAutoAnnotationMessage(a, contactId),
                    type: RenderedEntryType.AUTO_ANNOTATION,
                } as RenderedEntry);
            });
            sortedEntries = sortedEntries.concat(renderedAgentAnnotations)
                .concat(renderedAutoAnnotations);
        }

        if ((annotationsChecked && stateTransitionsChecked) && !metricsEmitted) {
            Phoenix.getInstance().addMetric("AnnotationsAndStateTransitions.Selected", 1, MetricType.COUNT);
            setMetricsEmitted(true);
        }

        sortedEntries = sortedEntries.sort((a, b) => {
            return a.date.localeCompare(b.date);
        });

        setRenderedEntries(sortedEntries);
    }, [agentAnnotations, autoAnnotations, contactId, stateTransitionList, stateTransitionsChecked, annotationsChecked, metricsEmitted]);

    function addAnnotationResponseMessage(): JSX.Element {
        switch (addTextStatus) {
            // Status code 200
            case AddTextStatus.SUCCESS: {
                return <Translation stringId={"ADD_ANNOTATION_SUCCESS"}/>;
            }
            // Status code 400
            case AddTextStatus.ERROR: {
                return <Translation stringId={"ADD_ANNOTATION_INVALID"}/>;
            }
            // Any other status code
            case AddTextStatus.FAIL: {
                return <Translation stringId={"ADD_ANNOTATION_FAILURE"}/>;
            }
            // null
            default: {
                return <></>;
            }
        }
    }

    async function addAgentAnnotation(newAnnotation: string): Promise<void> {
        setIsLoading(true);
        setRestrictedDataMatchList([]);
        try {
            const mutation = buildAddAgentAnnotationMutation(contactId, newAnnotation);
            const response = await callPhoenix(mutation);

            // Status code 200
            if (response.callResult && response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed.data && parsed.data.addAgentAnnotation) {
                    if (parsed.data.addAgentAnnotation.isSuccess as boolean) {
                        // Update the current contact if successful
                        await reloadSingleContact(contactId);
                        setNewAnnotation("");
                        setAddTextStatus(AddTextStatus.SUCCESS);
                    } else {
                        setAddTextStatus(AddTextStatus.FAIL);
                    }
                }
            }
            // Some other kind of error
            else {
                setAddTextStatus(AddTextStatus.FAIL);
            }
        } catch (e) {
            setAddTextStatus(AddTextStatus.FAIL);
        } finally {
            setIsLoading(false);
        }
    }

    function processEntryText(entryText: string): JSX.Element {
        return <Box>
            <Text keepNewline text={AllHtmlEntities.decode(entryText)} />
        </Box>;
    }

    const getAgentSubtitle = (agent?: CSAgent): React.ReactNode => {
        if (agent) {
            return <>
                    <Box fontSize="body-s">{i18n("AGENT_ANNOTATION_BADGE") + ": " + agent.agentLogin}</Box>
                    {
                        agent.agentOwner &&
                            <Box fontSize="body-s">{i18n("FOLLOW_UP_OWNER") + ": " + agent.agentOwner}</Box>
                    }
                </>;
        } else {
            return "";
        }
    };

    const {authorizerJwtTokens} = useContext(IssueManagerContext);
    const filteredToken = authorizerJwtTokens.find(token => token.getAuth().find(item => item.e === 'CREATE_ANNOTATION_WITHOUT_FILTER'));
    const hasFilterOverride: boolean = filteredToken !== undefined;

    const entriesContent: JSX.Element = (
        <SpaceBetween direction="vertical" size="xs">
            <div>
                <div style={{ paddingBottom: "8px" }}>
                    <div style={{display: "inline-block", paddingRight: "8px"}}>
                        <Checkbox
                            onChange={({ detail }): void =>
                                setStateTransitionsChecked(detail.checked)
                            }
                            checked={stateTransitionsChecked}
                        >
                            {i18n("STATE_TRANSITIONS")}
                        </Checkbox>
                    </div>
                    <div style={{display: "inline-block"}}>
                        <Checkbox
                            onChange={({ detail }): void =>
                                setAnnotationsChecked(detail.checked)
                            }
                            checked={annotationsChecked}
                        >
                            {i18n("ANNOTATIONS")}
                        </Checkbox>
                    </div>
                </div>
                { renderedEntries.length > 0 && <Timeline lineColor={"#ff9900"} style={{ padding: "0px"}}>
                    <div role="list" style={{overflowY: "auto", maxHeight: "450px", padding: "1px"}}
                         className="contact-timeline">
                        {renderedEntries.map((entry) =>
                            <TimelineEvent
                                key={renderedEntries.indexOf(entry)}
                                title={entry.type === RenderedEntryType.AUTO_ANNOTATION ? processEntryText("(" + i18n("AUTO_ANNOTATION_BADGE") + ") " + entry.text) : processEntryText(entry.text)}
                                subtitle={getAgentSubtitle(entry.agent)}
                                createdAt={<Box><Timestamp date={entry.date} customerTimezone={customerTimezone}/></Box>}
                                icon={<Icon variant="normal" name={entry.type === RenderedEntryType.STATE_TRANSITION ? "status-info" : "contact"} />}
                                iconColor={"#232f3e"}
                                iconStyle={{ cursor: "auto"}}
                            >
                            </TimelineEvent>)}
                    </div>
                </Timeline> }
            </div>

            <div>
                {hasFilterOverride ? i18n('HAS_OVERRIDE_TRY_AGAIN') : null}
                <AddText newText={newAnnotation}
                         setNewText={setNewAnnotation}
                         disabled={isLoading}
                         textAriaLabel={IntlManager.sharedManager.formatMessage("ADD_ANNOTATION_PLACEHOLDER")}
                         textPlaceholder={IntlManager.sharedManager.formatMessage("ADD_ANNOTATION_PLACEHOLDER")}
                         addTextStatus={addTextStatus}
                         setAddTextStatus={setAddTextStatus}
                         restrictedDataMatchList={restrictedDataMatchList}/>
                <p>{IntlManager.sharedManager.formatMessage("ANNOTATION_WARNING")}</p>
            </div>
            <ColumnLayout columns={2}>
                <Box
                    fontSize="body-s"
                    fontWeight="bold"
                    color={addTextStatus === AddTextStatus.SUCCESS ? "text-status-success" : "text-status-error"}
                >
                    <div role="status">
                        {addAnnotationResponseMessage()}
                    </div>
                </Box>
                <Box float="right">
                    <SpaceBetween direction={"horizontal"} size={"xs"}>
                        {(addTextStatus === AddTextStatus.ERROR) ? <Button onClick={
                            (): void => setShowOverrideRequestFrom(true)
                        }>{i18n('REQUEST_OVERRIDE')}</Button> : null}
                        <Button
                            loading={isLoading}
                            variant="primary"
                            formAction="none"
                            onClick={(): void => {
                                addTextAndSubmit(newAnnotation,
                                    contactId,
                                    authorizerJwtTokens,
                                    (matches) => {
                                        setAddTextStatus(AddTextStatus.ERROR);
                                        setRestrictedDataMatchList(matches); },
                                    () => addAgentAnnotation(newAnnotation),
                                    () => setAddTextStatus(AddTextStatus.FAIL),
                                );
                            }}
                            ariaLabel={IntlManager.sharedManager.formatMessage("ADD")}
                        >
                            <Translation stringId={"ADD"}/>
                        </Button>
                    </SpaceBetween>
                </Box>
            </ColumnLayout>
        </SpaceBetween>
    );

    return (
        <div>
            <h4 className="contact-details-header"><Translation stringId={"CONTACT_TIMELINE"}/></h4>
            {entriesContent}
            {showOverrideRequestForm ? <CreateAnnotationOverrideRequestForm contactId={contactId}
                clearMatchErrors={(): void => {setAddTextStatus(AddTextStatus.NONE); setRestrictedDataMatchList([]);}}
                closeModal={(): void => setShowOverrideRequestFrom(false)}/> : null}
        </div>
    );
};

export default ContactTimelineDisplay;
