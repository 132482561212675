import React, {useContext, useEffect, useState} from 'react';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Box, {BoxProps} from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Form from "@amzn/awsui-components-react/polaris/form";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import SplitPanel from "@amzn/awsui-components-react/polaris/split-panel";
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Phoenix from "../api/Phoenix";
import {Contact, ContactStatus} from "../contact/Contact.types";
import {CallResponse, MetricType} from "@amzn/csphoenix-react-client";
import {
    IssueManagerContext,
    loadFullContact,
    LoadFullContactResponse,
    callPhoenix
} from "../issueManager/IssueManager";
import {useCollection} from '@amzn/awsui-collection-hooks';
import ContactDisplay, {ContactDisplayContext} from "../contact/ContactDisplay";
import {i18n} from "../i18n/IntlManager";
import ContactIcon from '../contact/ContactIcon';

export interface ContactFinderProps {
    visible: boolean;
    customerId: string;
    marketplaceId: string;
    emailToken: string | null;
    contactDisplayContext: ContactDisplayContext;
    closeContactFinder: () => void;
}

const contactFields =
    "contacts { contactId creationDate status " +
    "owner { agentLogin } " +
    "medium { type emailSubject} " + 
    "agentSelectedIssue { descriptors } " +
    "agentAnnotations { message } " +
    "} nextKey";

function buildQuery(customerId: string, marketplaceId: string, startKey: string, startDate: string, endDate: string, emailToken: string | null): string {
    return `query { ` +
        `   contactsForCustomer ( customerId: "${customerId}", ` +
        (emailToken ? `                      emailAddress: "${emailToken}", ` : '') +
        `                      marketplaceId: "${marketplaceId}", ` +
        `                      startDate: "${startDate}", ` +
        `                      endDate: "${endDate}", ` +
        `                      startKey: "${startKey}", ` +
        `                      pageSize: 50 ) { ` +
        contactFields +
        `   }` +
        `}`;
}

function buildByEmailQuery(marketplaceId: string, startKey: string, startDate: string, endDate: string, emailToken: string | null): string {
    return `query { ` +
        `   contactsForEmail ( ` +
        `                      emailAddress: "${emailToken}", ` +
        `                      marketplaceId: "${marketplaceId}", ` +
        `                      startDate: "${startDate}", ` +
        `                      endDate: "${endDate}", ` +
        (startKey ? `          startKey: "${startKey}", ` : "") +
        `                      pageSize: 50 ) { ` +
        contactFields +
        `   }` +
        `}`;
}

interface ContactTableProps {
    contacts: Contact[];
    setContactId: (contactId: string) => void;
    pageSize: number;
}

const ContactTable: React.FC<ContactTableProps> = ({contacts, setContactId, pageSize}) => {

    const [selectedItems, setSelectedItems] = useState<Contact[]>([]);

    function getSearchables(contact: Contact): string {
        return contact.contactId +
            contact.owner?.agentLogin +
            contact.creationDate +
            contact.status +
            contact.agentAnnotations.map(a => a.message).join(" ");
    }

    const { items, collectionProps, filterProps, paginationProps } = useCollection(
        contacts,
        {
            filtering: {
                filteringFunction: (item, filteringText, filteringFields): boolean => {
                    const searchables = getSearchables(item);
                    return (searchables.indexOf(filteringText) !== -1);
                },
            },
            pagination: { pageSize: pageSize },
            sorting: {},
            selection: {}
        }
    );

    const contactStatus = (status: ContactStatus): JSX.Element => {
        let statusColor: BoxProps.Color;
        let statusString: string = status;
        if (status === ContactStatus.LOCKED) {
            statusColor = "text-body-secondary"; // Grey
        } else if (status === ContactStatus.UNLOCKED) {
            statusColor = "text-status-info"; // Blue
            statusString = "UNRESOLVED";
        } else {
            statusColor = "text-status-success"; // Green
        }

        return (
            <Box variant="p" color={statusColor}>
                {i18n(statusString)}
            </Box>
        );
    };

    const columnDefinitions = [
        {
            id: "type",
            header: i18n("CONTACT_TYPE"),
            cell: (e: Contact): JSX.Element => {
                return (
                    <Box textAlign="center">
                        <ContactIcon mediumType={e.medium.type}/>
                    </Box>
                );
            }
        },
        {
            id: "date",
            header: i18n("DATE"),
            cell: (e: Contact): string => e.creationDate.substring(0,10)
        },
        {   id: "status",
            header: i18n("STATUS"),
            cell: (e: Contact): JSX.Element => contactStatus(e.status)
        },
        {
            id: "subject",
            header: i18n("SUBJECT"),
            cell: (e: Contact): string => e.medium.emailSubject ? e.medium.emailSubject : ""
        },
        {
            id: "agent",
            header: i18n("AGENT"),
            cell: (e: Contact): string => e.owner ? e.owner.agentLogin : ""
        },
        {
            id: "annotation",
            header: i18n("ANNOTATIONS"),
            cell: (e: Contact): string => e.agentAnnotations
                .map(a => a.message)
                .join(", "),
            maxWidth: 250
        },
        {   id: "issue",
            header: i18n("ISSUE"),
            cell: (e: Contact): string => e.agentSelectedIssue ? e.agentSelectedIssue.descriptors[e.agentSelectedIssue.descriptors.length - 1] : ""
        }
    ];

    const filter = <TextFilter {...filterProps} filteringPlaceholder={i18n('FILTER')}/>;

    const pagination = (<Pagination {...paginationProps}/>);

    async function onSelectionChange(event: NonCancelableCustomEvent<TableProps.SelectionChangeDetail<Contact>>): Promise<void> {
        const contact: Contact = event.detail.selectedItems[0];

        setSelectedItems(event.detail.selectedItems);

        setContactId(contact.contactId);
    }

    return (<Table {...collectionProps}
                selectionType={"single"}
                   selectedItems={selectedItems}
                   onSelectionChange={onSelectionChange}
                   items={items}
                   columnDefinitions={columnDefinitions}
                   pagination={pagination}
                   filter={filter}
    />);
};

interface ContactSplitPanelProps {
    customerId: string;
    contactId?: string;
    contactDisplayContext: ContactDisplayContext;
}

export const ContactSplitPanel: React.FC<ContactSplitPanelProps> = ({ customerId,
                                                                        contactId,
                                                                        contactDisplayContext}) => {

    const [loadingContact, setLoadingContact] = useState<boolean>(false);
    const [contact, setContact] = useState<LoadFullContactResponse>();

    const issueManagerContext = useContext(IssueManagerContext);

    useEffect(() => {

        async function fullLoad(): Promise<void> {
            if (contactId) {
                const isContactAvailable = contact && (contactId === contact.contact.contactId);
                if (!isContactAvailable) {
                    try {
                        setLoadingContact(true);
                        const reloadedContact: LoadFullContactResponse = await loadFullContact(contactId);
                        setContact(reloadedContact);
                    } finally {
                        setLoadingContact(false);
                    }
                }
            }
        }

        fullLoad();

        }, [customerId, contactId, contact, contactDisplayContext, issueManagerContext.authorizerJwtTokens]);

    const paneli18nStrings = {
        preferencesTitle: i18n('SP_PREFERENCES'),
        preferencesPositionLabel: i18n('SP_POSITION'),
        preferencesPositionDescription: "",
        preferencesPositionSide: i18n('SP_SIDE'),
        preferencesPositionBottom: i18n('SP_BOTTOM'),
        preferencesConfirm: i18n('CONFIRM'),
        preferencesCancel: i18n('CANCEL'),
        closeButtonAriaLabel: i18n('CLOSE'),
        openButtonAriaLabel: i18n('OPEN'),
        resizeHandleAriaLabel: i18n('SP_RESIZE')
    };

    return (<SplitPanel
        header={contactId || ""}
        i18nStrings={paneli18nStrings}
    >
        {(contact && !loadingContact)  ?
            <ContactDisplay customerId={customerId}
                            context={contactDisplayContext}
                            contact={contact.contact}
                            customerAccessLevel={contact.customerAccessLevel}
            />
            :null}
    </SplitPanel>);
};

export const ContactFinder: React.FC<ContactFinderProps> = ({customerId,
                                                                marketplaceId,
                                                                emailToken,
                                                                visible,
                                                                contactDisplayContext,
                                                                closeContactFinder }) => {

    const contactLimit = 300;
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [contactId, setContactId] = useState<string>();
    const [hasManyContacts, setHasManyContacts] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<Date>(new Date());

    useEffect(() => {

        async function pagedFetch(pageNr: number, tempContacts: Contact[], startDate: Date, endDate: Date, startKey?: string): Promise<Contact[]> {

            const query = customerId ?
                buildQuery(customerId, marketplaceId, startKey ? startKey : "", startDate.toISOString(), endDate.toISOString(), emailToken) :
                buildByEmailQuery(marketplaceId, startKey ? startKey : "", startDate.toISOString(), endDate.toISOString(), emailToken);

            const response: CallResponse = await callPhoenix(query);

            if (response.httpStatus === 200 && response.callResult) {
                const parsed = JSON.parse(response.callResult.resultJson);
                const responseContent = parsed?.data?.contactsForCustomer || parsed?.data?.contactsForEmail;
                if (responseContent) {
                    const fetchedContacts: Contact[] = responseContent?.contacts;
                    tempContacts = tempContacts.concat(fetchedContacts);
                    setContacts(tempContacts);
                    if (responseContent.nextKey) {
                        if (tempContacts.length < contactLimit) {
                            tempContacts = await pagedFetch(pageNr + 1,tempContacts, startDate, endDate, responseContent.nextKey);
                        }
                        else {
                            setHasManyContacts(true);
                        }
                    }
                }
            }
            return tempContacts;
        }

        async function fetchForFinder(): Promise<void> {
            const fetchStart = window.performance.now();
            const startDate = new Date(2010,1,1);
            const loadingContacts: Contact[] = [];
            const loadedContacts: Contact[] = await pagedFetch(1, loadingContacts, startDate, endDate);
            setContacts(loadedContacts);
            Phoenix.getInstance().addMetric("ContactFinderContacts", loadedContacts.length, MetricType.COUNT);
            const elapsed = window.performance.now() - fetchStart;
            Phoenix.getInstance().addMetric("ContactFinderContactsLatency", elapsed, MetricType.TIME);
        }

        setContacts([]);
        fetchForFinder();

    }, [customerId, emailToken, endDate, marketplaceId]);

    const [panelPosition, setPanelPosition] = useState<"side" | "bottom">("side");
    const pageSize = panelPosition === "bottom" ? 6 : 15;

    const ValueWithLabel = ({ label, children }): JSX.Element => (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{children}</div>
        </div>
    );
    const largeContactNumbers = (hasManyContacts) ? (
        <Container>
            <SpaceBetween size={"xs"} direction={"vertical"}>
                <Alert key={"key0"}>{i18n("CF_LARGE_NUMBER_ALERT")}</Alert>
                <Form>
                    <SpaceBetween size={"l"} direction={"horizontal"}>
                        <ValueWithLabel label={i18n("NUMBER_OF_CONTACTS")}>{contacts.length}</ValueWithLabel>
                        {contacts.length > 0 ? <ValueWithLabel label={i18n("OLDEST_LOADED_CONTACT")}>{contacts[contacts.length-1].creationDate.slice(0,10)}</ValueWithLabel> : null}
                        <ValueWithLabel label={i18n("END_DATE")}>
                            <DatePicker value={endDate.toISOString()}
                                        onChange={({detail}): void => setEndDate(new Date(detail.value))}
                                        todayAriaLabel={i18n("DATE_PICKER_TODAY")}
                                        nextMonthAriaLabel={i18n("DATE_PICKER_NEXT_MONTH")}
                                        previousMonthAriaLabel={i18n("DATE_PICKER_PREVIOUS_MONTH")}/>
                        </ValueWithLabel>
                    </SpaceBetween>
                </Form>
            </SpaceBetween>
    </Container>) : null;

    const contactTable = (<ContactTable
        contacts={contacts}
        setContactId={setContactId}
        pageSize={pageSize}
    />);

    const content = (<SpaceBetween size={"xs"} direction={"vertical"}>
        <Button variant={"link"} onClick={closeContactFinder}>{'< '}{i18n('BACK')}</Button>
        {largeContactNumbers}
        {contactTable}
    </SpaceBetween>);

    return (visible ? <AppLayout
            toolsHide={true}
            navigationHide={true}
            contentType={"table"}
            splitPanelOpen={true}
            splitPanel={<ContactSplitPanel customerId={customerId}
                                           contactDisplayContext={contactDisplayContext}
                                           contactId={contactId}
            />}
            splitPanelPreferences={{position: panelPosition}}
            onSplitPanelPreferencesChange={(event): void => {
                setPanelPosition(event.detail.position);
            }}
            content={content}
    ></AppLayout> : <div/>);
};
