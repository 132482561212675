import React from "react";
import Translation from "../i18n/Translate";
import {ContactScreenPop, ContactScreenPopType} from "./Contact.types";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Box from "@amzn/awsui-components-react/polaris/box";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";


const SCREEN_POP_TYPE_TRANSLATION_MAP = new Map([

    // The ContactScreenPopType is never expected to be AUTHENTICATING_AVAIL, CALLER_ID_AVAIL etc.
    // because those are not screenpop types, but rather screenpop statuses used internally by Screenpop.
    // However since those were copied as Screenpop type enums to CSALT schema,
    // to avoid backwards incompatible changes to schema or resolver, resolve the changes in String description here.
    // String ending in _AVAIL omit information related to usage.
    // Since that information is more correctly identified in the other field: screenPop.usedByAgent
    // https://t.corp.amazon.com/P59535428/communication

    [ContactScreenPopType.AUTHENTICATING_AVAIL, "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL"],
    [ContactScreenPopType.AUTHENTICATING, "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL"],
    [ContactScreenPopType.CALLER_ID_AVAIL, "SCREEN_POP_TYPE_CALLER_ID_AVAIL"],
    [ContactScreenPopType.CALLER_ID, "SCREEN_POP_TYPE_CALLER_ID_AVAIL"],
    [ContactScreenPopType.CUSTOMER_HUNCH_AVAIL, "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL"],
    [ContactScreenPopType.CUSTOMER_HUNCH, "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL"],
    [ContactScreenPopType.CUSTOMER_UNKNOWN, "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN"],
    [ContactScreenPopType.OTHER, "SCREEN_POP_TYPE_OTHER"]
]);

const getScreenPopDescription = (type: ContactScreenPopType): JSX.Element => {
    let translationStringId = SCREEN_POP_TYPE_TRANSLATION_MAP.get(type);
    if (translationStringId === undefined) {
        translationStringId = "SCREEN_POP_TYPE_OTHER";
    }
    return <Translation stringId={translationStringId}/>;
};

interface ContactScreenPopDisplayProps {
    screenPop?: ContactScreenPop;
}

const ContactScreenPopDisplay: React.FC<ContactScreenPopDisplayProps> = ({screenPop}) => {
    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId="SCREEN_POP_TITLE"/></h4>
            {screenPop ?
                <ColumnLayout columns={2}>
                    <div>
                        <Box margin={{bottom: 'xxxs'}} color="text-label">
                            <em>
                                <Translation stringId="SCREEN_POP_DESCRIPTION_TITLE"/>
                            </em>
                        </Box>
                        <div>{getScreenPopDescription(screenPop.type)}</div>
                    </div>
                    <div>
                        <Box margin={{bottom: 'xxxs'}} color="text-label">
                            <em>
                                <Translation stringId="SCREEN_POP_USED_BY_AGENT_TITLE"/>
                            </em>
                        </Box>
                        <Translation
                            stringId={screenPop.usedByAgent ? "SCREEN_POP_USED_BY_AGENT_POSITIVE" : "SCREEN_POP_USED_BY_AGENT_NEGATIVE"}/>
                    </div>
                </ColumnLayout>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SCREEN_POP")}/>}
        </div>);
};


export default ContactScreenPopDisplay;