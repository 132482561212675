import React, {useContext} from "react";
import {i18n} from "../i18n/IntlManager";
import {Contact, ReplyByType} from "./Contact.types";
import Box from "@amzn/awsui-components-react/polaris/box";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Translation from "../i18n/Translate";
import {HasFollowUpsOwnedByAgent, IsContactLockedByAgent} from "./ContactUtil";
import CSCentralURL from "../issueManager/CSCentralURL";
import Phoenix from '../api/Phoenix';
import {MetricType} from "@amzn/csphoenix-react-client";
import { IssueManagerContext } from "../issueManager/IssueManager";
import {getUserId, getUserLogin} from "./UserDetailsUtil";

interface ReplyByActionsProps {
    contact: Contact;
}

const ReplyByActions: React.FC<ReplyByActionsProps> = ({contact}) => {

    const { clientStateToken, getSubject } = useContext(IssueManagerContext);
    const userId = getUserId(clientStateToken);
    const agentLogin = getUserLogin(getSubject());

    const isLockedByAgent = IsContactLockedByAgent(contact, userId, agentLogin);
    const isAssignedFollowUp = HasFollowUpsOwnedByAgent(contact, userId, agentLogin);

    const urlTypeForQueryString = {
      EMAIL : "create",
      PHONE : "create-phone"
    };

    /* Members in replyByOptions are required by ButtonDropdown Component*/
    const replyByOptions = [{
           text: i18n("REPLY_BY_EMAIL"),
           id: ReplyByType.EMAIL,
           disabled: false
        },
        {
           text: i18n("REPLY_BY_PHONE"),
           id: ReplyByType.PHONE,
           disabled: false
    }];

    function getReplyByTypeUrl(type): void {
        const queryString = "commType=" + type + "&email=" + contact.medium.sender?.emailAddress + "&initialCommID=" + contact.contactId ;
        const urlType = (type === ReplyByType.EMAIL) ? urlTypeForQueryString.EMAIL : urlTypeForQueryString.PHONE;
        const url = CSCentralURL.sharedCSCentralURL.getURL("gp/communications/email-composer/"+ urlType +"/", queryString);

        /* Metrics */
        Phoenix.getInstance().addMetric("ReplyBy_Email", (type === ReplyByType.EMAIL) ? 1 : 0, MetricType.COUNT);
        Phoenix.getInstance().addMetric("ReplyBy_Phone", (type === ReplyByType.PHONE) ? 1 : 0, MetricType.COUNT);

        window.open(url);
    }

    return (
        <div>
        {(isLockedByAgent || isAssignedFollowUp) &&
            <Box margin={{top:"s"}} textAlign="right" >
                <ButtonDropdown items= {replyByOptions}
                                onItemClick = { (clickedEvent): void => getReplyByTypeUrl(clickedEvent.detail.id)}
                                variant = "primary" >
                                <Translation stringId="REPLY_BY"/>
                </ButtonDropdown>
            </Box>
        }
        </div>
    );
};

export default ReplyByActions;

