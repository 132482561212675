import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import {ContactMedium, ContactMediumType, ReplyContact} from "./Contact.types";
import {Timestamp} from "./Timestamp";
import Translation from "../i18n/Translate";
import EmailBody from "./EmailBody";
import EmailBodyWithTranslation from "./EmailBodyWithTranslation";
import IntlManager from "../i18n/IntlManager";
import EmdashImage from "../util/EmdashImage";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

interface ContactDisplayProps {
    reply: ReplyContact;
    customerContentIsRestricted: boolean;
    customerTimezone: string;
}

const getReceiverAddress = (medium: ContactMedium): JSX.Element => {
    if (medium.receiver?.emailAddress) {
        return <span>{medium.receiver.emailAddress}</span>;
    } else {
        // Email replies should always have a receiver address. If it is not present, then that means
        // we have restricted access to PII data.
        if (medium.type === ContactMediumType.EMAIL) {
            return <Translation stringId="RESTRICTED_INFORMATION"/>;
        } else {
            return <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SENT_TO")}/>;
        }
    }
};

const ReplyContactDisplay: React.FC<ContactDisplayProps> = ({reply, customerContentIsRestricted, customerTimezone}) => {

    const emailContent: JSX.Element = (
        <div>
            <div>
                <Box margin={{"bottom":"xxxs"}} color="text-label"><Translation stringId="SENT_TO"/></Box>
                <div>{getReceiverAddress(reply.medium)}</div>
            </div>
            <div>
                <Box margin={{"bottom":"xxxs"}} color="text-label"><Translation stringId="SUBJECT"/></Box>
                {reply.medium.emailSubject ?
                    <div>{reply.medium.emailSubject}</div>
                    : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SUBJECT")}/>
                }
            </div>
            {reply.medium.emailBodyPreTranslationCSAText ?
                <EmailBodyWithTranslation htmlSafePreTranslationCSAText={reply.medium.emailBodyPreTranslationCSAText} htmlSafeEmailBody={reply.medium.emailBodyHTMLSafe}/>
                : <EmailBody htmlSafeEmailBody={reply.medium.emailBodyHTMLSafe}/> }
        </div>
    );

    return (
        <Box padding={{"left":"xxs"}}>
            <SpaceBetween direction="vertical" size="s">
            <Box fontSize="heading-xs">
                <Box variant="strong">
                    <Timestamp date={reply.creationDate} customerTimezone={customerTimezone}/>,&nbsp;from&nbsp;{reply.medium.sender ? reply.medium.sender.emailAddress : null}
                </Box>
            </Box>
            {customerContentIsRestricted ? <Alert><Translation stringId={'REPLY_RESTRICTED'}/></Alert> : emailContent}
            </SpaceBetween>
        </Box>
    );
};

export default ReplyContactDisplay;