export const zh_TW = {
    "contact_event_GC_added": "GC #{gcId} 被新增至帳戶。",
    "contact_event_GC_added_batched": "GC 已被新增到帳戶 ({count})。",
    "contact_event_GC_added_purchaser": "GC #{gcId} 被新增至購買者帳戶。",
    "contact_event_GC_added_purchaser_batched": "GC 已被新增到購買者帳戶 ({count})。",
    "contact_event_GC_added_recipient": "GC #{gcId} 被新增至收件人帳戶。",
    "contact_event_GC_added_recipient_batched": "GC 已被新增到收件人帳戶 ({count})。",
    "contact_event_GC_added_scratchedCode": "已透過磨損兌換代碼工具，將禮品卡 (編號：{gcId}) v新增至帳戶。",
    "contact_event_GC_added_scratchedCode_batched": "已透過磨損兌換代碼工具 ({count}) 將禮品卡新增至帳戶。",
    "contact_event_GC_cancelled": "GC #{gcId} 已取消。",
    "contact_event_GC_cancelled_batched": "GC 已取消 ({count})。",
    "contact_event_GC_changed_expiry": "變更了 GC #{gcId} 的截止日期。",
    "contact_event_GC_changed_expiry_batched": "變更了 GC {gcId} 的截止日期。",
    "contact_event_GC_locked": "GC #{gcId} 已鎖定。",
    "contact_event_GC_locked_batched": "GC 已鎖定 ({count})。",
    "contact_event_GC_removed": "GC #{gcId} 被從帳戶中刪除了。",
    "contact_event_GC_removed_batched": "GC 以從帳戶中移除 ({count})。",
    "contact_event_GC_resent_email": "GC #{gcId} 重新傳送至電子郵件 Id {deliveryEmail}。",
    "contact_event_GC_resent_email_batched": "GC 重新傳送至電子郵件 Id ({count})。",
    "contact_event_GC_resent_post": "GC #{gcId} 透過郵遞重新寄送。",
    "contact_event_GC_resent_post_batched": "GC 已透過郵寄重新寄送 ({count})。",
    "contact_event_GC_temporary_locked": "GC #{gcId}暫時鎖定。",
    "contact_event_GC_temporary_locked_batched": "GC 被暫時鎖定 ({count})。",
    "contact_event_GC_transferred": "GC {gcId} 從客戶處 {customerID} 轉發至 {toAccountCustomerID}。",
    "contact_event_GC_transferred_batched": "GC 已轉移 ({count})。",
    "contact_event_GC_unlocked": "GC #{gcId} 未鎖定。",
    "contact_event_GC_unlocked_batched": "GC 已解鎖 ({count})。",
    "contact_event_PV_added": "PV # {pvId} 已新增至帳戶。",
    "contact_event_PV_locked": "PV # {pvId} 已鎖定。",
    "contact_event_PV_temporary_locked": "PV # {pvId} 暫時鎖定。",
    "contact_event_PV_unlocked": "PV # {pvId} 解除鎖定。",
    "contact_event_a_to_z_guarantee_cancelled_batched": "取消的有關訂單的 A-to-Z 索賠 ({count})。",
    "contact_event_a_to_z_guarantee_requested": "提交的 {order_id} 訂單的 A-to-Z 索賠。",
    "contact_event_a_to_z_guarantee_requested_batched": "遞交的訂單的 A-to-Z 索賠 ({count})。",
    "contact_event_a_to_z_guarantee_requested_gwp": "已提交訂單 {order_id} 的亞馬遜商城交易保障索賠。",
    "contact_event_access_code_added": "已新增存取代碼",
    "contact_event_access_code_added_batched": "已新增存取代碼 ({count})",
    "contact_event_account_closed": "關閉的帳戶。",
    "contact_event_account_closed_batched": "關閉的帳戶 ({count})。",
    "contact_event_account_reopened": "帳戶重新開啟",
    "contact_event_account_reopened_batched": "帳戶重新開啟 ({count})",
    "contact_event_account_temporarily_disabled": "帳戶暫時停用。",
    "contact_event_account_temporarily_disabled_batched": "帳戶暫時停用 ({count})",
    "contact_event_account_whitelisted": "帳戶已列入允許清單。",
    "contact_event_account_whitelisted_batched": "帳戶已列入白名單 ({count})。",
    "contact_event_address_changed": "{order_id} 訂單中變更的 {address_type} 地址。",
    "contact_event_address_changed_batched": "地址更新的訂單 ({count})。",
    "contact_event_address_deactivated": "已停用的地址。",
    "contact_event_address_deactivated_batched": "已停用的地址 ({count})。",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video 訂閱方案已自動續訂",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video 訂閱方案已取消",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "提交表單：DSL 呈報表單\n使用案例工作階段 ID：{usecaseSessionID}\n配送站：{deliveryStationField}\n請求類型：{requestTypeField}\n聯絡人 ID：{contactIdField}\n訂單編號：{orderIdField}\n客戶的電子郵件地址：{customerEmailField}\n客戶的電話號碼：{customerPhoneNumberField}\nTBA：{tbasField}\n存取碼 (如果適用)：{accessCodeField}\n問題摘要：{issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "提交的 DSL 呈報表單：({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "提交表單：DSP DA 意見回饋表單\n使用案例工作階段 ID：{usecaseSessionID}\n配送站代碼：{deliveryStationField}\n聯絡人 ID：{contactIdField}\n客戶帳戶 ID：{customerIdField}\nDA 運送人員 ID：{driverIdField}\n事件日期 (YYYY-MM-DD)：{dateOfOccurrenceField}\n事件時間 (HH:MM)：{timeofOccurrenceField}\nTBA：{relatedTrackingIdsField}\n意見回饋來源：{sourceOfFeedbackField}\n意見回饋細節：{issueField}\n其他詳細資訊：{additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "提交的 DSP DA 意見回饋表單：({count})",
    "contact_event_andon_cord_pulled": "撥向 {asin} 的 Andon 軟線。",
    "contact_event_andon_cord_pulled_batched": "撥至商品({count})的 Andon 軟線。",
    "contact_event_apply_adrive_music_trial": "對客戶 {customer_id} 適用了 Cloud Player Premium{number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "帳戶已遷移至 {marketplaceName}。",
    "contact_event_audible_account_migrated_batched": "已遷移的 Audible 帳戶 ({count})。",
    "contact_event_audible_cash_and_coupon_refund": "已將 {cashAmount} 的款項退還給 {title}。 此外，本次交易亦已將 {couponAmount} 的優惠券歸還至帳戶。{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "已將 {cashAmount} 的款項退還給 {title}。 此外，本次交易亦已將 {couponAmount} 的優惠券歸還至帳戶。{counter} ({count})",
    "contact_event_audible_cash_only_refund": "已將 {cashAmount} 的款項退還給 {title}。{counter}",
    "contact_event_audible_cash_only_refund_batched": "已將 {cashAmount} 的款項退還給 {title}。{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "已退款給 {title}，{couponAmount} 的優惠券亦歸還至帳戶。{counter}",
    "contact_event_audible_coupon_only_refund_batched": "已退款給 {title}，{couponAmount} 的優惠券亦歸還至帳戶。{counter} ({count})",
    "contact_event_audible_credit_deleted": "從帳戶移除 {amount} 積分。",
    "contact_event_audible_credit_deleted_batched": "從帳戶移除 {amount} 積分。({count})",
    "contact_event_audible_credit_issued": "{numCredit} 積分已新增至帳戶。",
    "contact_event_audible_credit_issued_batched": "積分已新增至帳戶 ({count})。",
    "contact_event_audible_credit_refund": "已退款給 {title}，{creditAmount} 的積分亦歸還至帳戶。{counter}",
    "contact_event_audible_credit_refund_batched": "已退款給 {title}，{creditAmount} 的積分亦歸還至帳戶。{counter} ({count})",
    "contact_event_audible_credit_restored": "將 {amount} 積分還原至帳戶。",
    "contact_event_audible_credit_restored_batched": "將 {amount} 積分還原至帳戶 ({count})。",
    "contact_event_audible_extra_credit_purchased": "已購買 3 點額外積分。",
    "contact_event_audible_extra_credit_purchased_batched": "已購買 3 點額外積分。({count})",
    "contact_event_audible_hiatus_cancelled": "中斷期間已提早結束，已恢復會員資格。",
    "contact_event_audible_hiatus_cancelled_batched": "中斷期間已提早結束，已恢復會員資格。({count})",
    "contact_event_audible_hiatus_started": "會員資格已設為 {duration} 個月的中斷狀態。",
    "contact_event_audible_hiatus_started_batched": "會員資格已設為 {duration} 個月的中斷狀態。({count})",
    "contact_event_audible_membership_enrollment": "已註冊 \n{membershipPlan} 會員資格方案。",
    "contact_event_audible_membership_enrollment_batched": "已註冊 Audible 會員資格方案 ({count})。",
    "contact_event_audible_membership_plan_refund": "共 {cashAmount}.的會員費用已退款。{counter}",
    "contact_event_audible_membership_plan_refund_batched": "共 {cashAmount}.的會員費用已退款。{counter} ({count})",
    "contact_event_audible_membership_switched": "從 {oldMembershipPlan} 變更為 {newMembershipPlan} 會員資格方案。",
    "contact_event_audible_membership_switched_batched": "已變更 Audible 會員資格方案 ({count})。",
    "contact_event_audible_romance_package_enrollment": "已經在 {packageName} 註冊。",
    "contact_event_audible_romance_package_enrollment_batched": "已經在 {packageName} 註冊。({count})",
    "contact_event_audible_wizard_invoked": "已存取 {wizardName}。",
    "contact_event_audible_wizard_invoked_batched": "已存取 {wizardName} ({count})。",
    "contact_event_audiblecontact_event_cancel_audible_membership": "{membership} 計畫已成功取消。",
    "contact_event_blacklist_kindle": "列入黑名單的裝置 {serial_number} Kindle。",
    "contact_event_blacklist_kindle_batched": "列入黑名單的 Kindle ({count})。",
    "contact_event_bound_warranty": "與{dsn}設備綁定的保固 ID {warranty_id}。",
    "contact_event_bound_warranty_batched": "裝置 ({count}) 上綁定的保證。",
    "contact_event_call_was_flagged": "該聯絡方式已被標記為{callFlagSubject}： {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "該聯絡方式已被標記為{callFlagSubject}： {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "{membership} 計畫已成功取消。",
    "contact_event_cancel_audible_membership_batched": "{membership} 計畫已成功取消。({count})",
    "contact_event_cancel_audible_romance_package_batched": "{membership} 計畫已成功取消。({count})",
    "contact_event_cancel_from_prime_cancel_exception": "成功取消的 PrimeCancelException 優惠。取消理由：{cancel_reason}。",
    "contact_event_cancel_from_prime_cancel_exception_batched": "取消優惠異常({count})。",
    "contact_event_cancel_future_cancellation_audible_membership": "已取消先前所設的「預定取消日期」。",
    "contact_event_cancel_library_loan": "取消客戶 ({customer_id}) 的影片庫借閱。ASIN︰{asin}；借閱 ID︰{loan_id}；原因︰{cancel_reason}；",
    "contact_event_cancel_tv_pass": "已取消電視季票，訂單 ID 為 {order_id} ，原因為 {cancel_reason}。",
    "contact_event_cancelled_sns_reactivated": "訂閱的 ASIN  {asin} 於 {reactivation_date}（月） 由 {login}重新激活",
    "contact_event_cancelled_sns_reactivated_batched": "再次啟用取消的「啟用與儲存」訂閱 ({count})。",
    "contact_event_captcha_bypass_key_generated": "生成的 Captcha Bypass 金鑰。",
    "contact_event_captcha_bypass_key_generated_batched": "生成的 Captcha Bypass 金鑰 ({count})。",
    "contact_event_change_sns_quantity": "Subscribe and Save 的 ASIN {asin} 由舊數量 {current_quantity}設定為新數量 {new_quantity}。",
    "contact_event_change_sns_quantity_batched": "更新的「訂閱與存儲」數量 ({count})。",
    "contact_event_channels_auto_renew_off": "CS 關閉了 PV 頻道訂閱 {subscriptionid} 的自動續訂。",
    "contact_event_channels_auto_renew_off_static": "CS 透過 Astro 關閉了自動續訂。",
    "contact_event_channels_full_refund": "CS 取消了 PV 頻道訂閱 {subscriptionid}，並全額退款。",
    "contact_event_channels_full_refund_static": "CS 透過 Astro 取消了訂閱，並全額退款。",
    "contact_event_channels_no_refund": "CS 取消了PV 頻道訂閱 {subscriptionid}，不退款。",
    "contact_event_channels_no_refund_static": "CS 透過 Astro 取消了訂閱，不退款。",
    "contact_event_channels_workflow_launch": "CS 為 PV 頻道訂閱 {subscriptionid} 推出了 Astro 取消工作流程",
    "contact_event_channels_workflow_launch_static": "CS 推出了 Astro 取消工作流程。",
    "contact_event_check_in_book": "已結帳書籍，並附有客戶 {customer_id} 的貸款 ID {loan_id}。",
    "contact_event_coins_adjust": "調整類型：{type} <br />\n理由： {reason} <br />\n數量： {amount} <br />\n評論： {comment} <br />",
    "contact_event_coins_adjust_batched": "調整後的硬幣餘額 ({count})。",
    "contact_event_concessions_abuse": "HMD 未傳送，因為客戶是 {risk_status} 使用者。",
    "contact_event_concessions_abuse_batched": "HMD 未傳送給客戶 ({count})。",
    "contact_event_contact_seller": "訂單 {order_id} 的賣方聯繫人。",
    "contact_event_contact_seller_batched": "訂單的已取得聯絡的賣家 ({count})。",
    "contact_event_contact_seller_gwp": "已聯絡賣家關於訂單 {order_id} 的事宜。",
    "contact_event_contact_was_initiated_from": "從 ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl} 開始接觸。",
    "contact_event_content_update": "ASIN {asin} 內容已在 kindle 庫中更新。",
    "contact_event_content_update_batched": "Kindle 圖書館 ({count}) 中更新的內容。",
    "contact_event_credit_card_disabled": "無效的複寫副本。",
    "contact_event_credit_card_disabled_batched": "停用的信用卡({count})。",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "追蹤編號 {trackingId} 已提交覆蓋地理圍欄請求 原因：{overrideReason} 頁面：{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "追蹤編號 {trackingId} 已提交覆蓋地理圍欄請求 原因：{overrideReason} 頁面：{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "地理編碼已更新。地理編碼類型：{geocodeType}，追踪 ID：{trackingId}，地址 ID：{addressId}，覆蓋原因：{overrideReason}，分頁識別符：{pageIdentifier}，",
    "contact_event_csc_sds_updated_geocode": "地理編碼已更新。地理編碼類型：{geocodeType}，覆蓋原因：{overrideReason}，追踪 ID：{trackingId}，地址 ID：{addressId} 及分頁識別符：{pageIdentifier}",
    "contact_event_customer_authentication_passed": "客戶通過驗證",
    "contact_event_customer_authentication_passed_batched": "客戶通過驗證({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "已更新的客戶姓名。",
    "contact_event_customer_name_updated_batched": "更新的客戶姓名 ({count})。",
    "contact_event_customer_password_changed": "客戶密碼發生變更。",
    "contact_event_customer_password_changed_batched": "發生變更的客戶密碼 ({count})。",
    "contact_event_deauthorize_all_device": "為客戶 {customer_id} 取消授權所有裝置。",
    "contact_event_deauthorize_device": "為客戶 ({customer_id}) 取消授權裝置類型為 {device_type} 的 DSN {dsn}。",
    "contact_event_delete_alexa_voice_profile": "已刪除 speakerId 的 Alexa 語音設定檔：{speakerId}、customerID：{customerID} 及狀態：{status}",
    "contact_event_delete_data_alexa_comms": "已刪除 commsID 的 Alexa Comms 設定檔：{commsId}、customerID：{customerID} 及狀態：{status}",
    "contact_event_deprovision_alexa_comms": "取消佈建 commsID 的 Alexa Comms 設定檔：{commsId}，customerID：{customerID}，狀態：{status}",
    "contact_event_deregister_kindle": "撤銷註冊的 {serial_number} Kindle。",
    "contact_event_deregister_kindle_batched": "取消登記的 Kindle ({count})。",
    "contact_event_detailed_troubleshooting_wizard": "已為 DSN 為 {dsn} 的裝置叫用疑難排解精靈：{details}。",
    "contact_event_disable_mayday_failed": "裝置 DSN {dsn} 的 Mayday 功能停用失敗。",
    "contact_event_disable_mayday_success": "裝置 DSN {dsn} 的 Mayday 功能已被成功停用。",
    "contact_event_dunning_halt": "催款程序停止 - 詳情請見「付款與交易記錄」",
    "contact_event_dunning_pause": "催款程序暫停 - 詳情請見「付款與交易記錄」",
    "contact_event_dvbypost_extra_dispatch": "此客戶請求另外的 {amount} 急件。",
    "contact_event_dvbypost_extra_envelope": "此客戶需要一個額外的信封",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 格式 angefordert",
    "contact_event_dvbypost_problem_code_change": "將磁盤 {disc_id} 設定為 {problem_code}",
    "contact_event_email_address_updated": "已更新的電子郵件地址。",
    "contact_event_email_address_updated_batched": "更新的電子郵件地址 ({count})。",
    "contact_event_email_preferences_changed": "變更的電子郵件偏好。",
    "contact_event_email_unsubscribe": "取消訂閱的電子郵件。",
    "contact_event_email_unsubscribe_batched": "取消訂閱的電子郵件 ({count})。",
    "contact_event_enable_mayday_failed": "裝置 DSN {dsn} 的 Mayday 功能再次啟動失敗。",
    "contact_event_enable_mayday_success": "裝置 DSN {dsn} 的 Mayday 功能再次啟動成功。",
    "contact_event_external_transfer": "該聯絡人從外部轉至 {transferAddress}。",
    "contact_event_external_transfer_batched": "該聯絡人從外部轉至了 {transferAddress} ({count})",
    "contact_event_first_time_user_experience_set": "設定首次使用者體驗",
    "contact_event_first_time_user_experience_set_batched": "設定首次使用者體驗({count})。",
    "contact_event_form_submitted": "已提交的 {form_name} 表格。",
    "contact_event_form_submitted_batched": "遞交的 ({count}) 表格。",
    "contact_event_free_time_create_pin": "已成功產生新的 FreeTime PIN 碼。",
    "contact_event_free_time_unlimited_cancelled": "「無限童樂時光」(FreeTime Unlimited) 訂閱已取消；顧客：{customerId}，DSN: {deviceSerialNumber}，取消人：{agentId}，原因：{reason}。",
    "contact_event_free_time_unlimited_cancelled_batched": "「無限童樂時光」(FreeTime Unlimited) 訂閱已取消 ({count})。",
    "contact_event_free_trial_prime_cancel_exception": "由於{cancel_reason}而成功取消了「優惠免費試用」。",
    "contact_event_free_trial_prime_cancel_exception_batched": "成功取消的優惠 ({count})。",
    "contact_event_fulfill_kindle": "已配送的 Kindle.DSN {dsn}；裝置類型 {device_type}；ASIN {asin}；OrderID {order_id}；",
    "contact_event_future_cancel_audible_membership": "{membership} 計畫已設為「預定取消」，將於 {date} 執行。",
    "contact_event_future_cancel_audible_membership_batched": "{membership} 計畫已設為「預定取消」，將於{date} 執行。({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin}已經被永久地從您的視訊庫中刪除。",
    "contact_event_header": "事件記錄",
    "contact_event_hmd_suppression": "HMD 未傳送，原因為：{suppression_message}。",
    "contact_event_idle_chat_disconnect": "閒置聊天已中斷連線",
    "contact_event_idle_chat_disconnect_batched": "閒置聊天已中斷連線 ({count})",
    "contact_event_international_linking_account": "客戶 {customer_id} 帳戶 {to_customer_email} 的國際連結帳戶 {from_customer_email}。",
    "contact_event_item_price_reduced": "{order_id} 訂單的商品價格由 {old_price} 調整為 {new_price}。",
    "contact_event_item_price_reduced_batched": "發生商品價格變動的訂單 ({count})。",
    "contact_event_item_quantity_changed": "變更的 {order_id} 訂單的商品數量。",
    "contact_event_item_quantity_changed_batched": "訂單的商品數量發生變更 ({count})。",
    "contact_event_kindle_device_replacement": "建立的訂單 {old_order_id} 的 DSN {dsn} {reason} 更換。新訂單：{new_order_id}。",
    "contact_event_kindle_device_replacement_batched": "替換的 Kindle 裝置 ({count})。",
    "contact_event_kindle_email_address_edits": "在 Kindle 上更新的電子郵件地址 (DSN # {dsn} )。",
    "contact_event_kindle_email_address_edits_batched": "Kindle ({count}) 的更新的電子郵件地址。",
    "contact_event_kindle_email_edits": "{dsn} 的發送電子郵件地址由{original_email}調整為{new_email}。",
    "contact_event_kindle_name_changed": "{dsn}  Kindle 名稱由 {original_name} 變更為 {new_name}。",
    "contact_event_kindle_name_changed_batched": "變更的 Kindle 名稱 ({count})。",
    "contact_event_kindle_unlimited_refund": "Kindle 無限退款 {amount}，針對交易 ID {transaction_id}。",
    "contact_event_last_page_read_deleted": "ASIN {asin} 的最新頁面讀數已刪除。",
    "contact_event_last_page_read_deleted_batched": "刪除的已閱讀的最後一頁 ({count})。",
    "contact_event_live_assist_launched": "DSN：{dsn}、kcsID：{kcsId}、客戶反應：{customerResponse}已啟動實時協助。",
    "contact_event_live_assist_launched_batched": "({count})設備已啟動實時協助。",
    "contact_event_lock_dash_order": "{dsn} 裝置的以下 ASIN 已解瑣：{asins}。",
    "contact_event_mp3_download_to_adrive": "下載 mp3 至客戶 {customer_id} 的 ADrive。ASIN︰{asin}",
    "contact_event_music_auto_renew_off": "CS 已關閉音樂訂閱 {subscriptionid} 的自動續訂。",
    "contact_event_music_auto_renew_off_static": "CS 已透過 Astro 關閉音樂訂閱的自動續訂。",
    "contact_event_music_full_refund": "CS 已取消音樂訂閱 {subscriptionid}，並全額退款",
    "contact_event_music_full_refund_static": "CS 透過 Astro 取消了音樂訂閱，並全額退款。",
    "contact_event_music_no_refund": "CS 已取消音樂訂閱 {subscriptionid}，不退款。",
    "contact_event_music_no_refund_static": "CS 透過 Astro 取消了音樂訂閱，不退款。",
    "contact_event_music_unlimited_auto_renew_off": "CS 關閉了 Amazon Music 吃到飽訂閱 {subscriptionid} 的自動續訂。",
    "contact_event_music_unlimited_full_refund": "CS 取消了 Amazon Music 吃到飽訂閱 {subscriptionid} 並全額退款。",
    "contact_event_music_unlimited_no_refund": "CS 取消了 Amazon Music 吃到飽訂閱 {subscriptionid}，不退款。",
    "contact_event_music_unlimited_partial_refund": "CS 取消了 Amazon Music 吃到飽訂閱 {subscriptionid} 並提供部分退款。",
    "contact_event_music_unlimited_workflow_launch": "CS 推出了 Amazon Music 吃到飽訂閱 {subscriptionid} 的 Astro 取消工作流程。",
    "contact_event_music_workflow_launch": "CS 推出了音樂訂閱 {subscriptionid} 的 Astro 取消工作流程。",
    "contact_event_music_workflow_launch_static": "CS 推出了音樂無限制的 Astro 取消工作流程。",
    "contact_event_non_dsv_refund_created": "數位訂單{order_id}獲得{refund_amount}的退款。",
    "contact_event_non_dsv_refund_created_batched": "獲得退款的數位訂單({count})。",
    "contact_event_one_click_address_updated": "更新的 1-Click 地址。",
    "contact_event_one_click_address_updated_batched": "更新的「一按即可」地址 ({count})。",
    "contact_event_one_click_disabled": "禁用的 1-Click。",
    "contact_event_one_click_disabled_batched": "停用的「一按即可」({count})。",
    "contact_event_one_click_enabled": "啟用的 1-Click。",
    "contact_event_one_click_payment_updated": "更新的 1-Click 付款方式。",
    "contact_event_one_click_payment_updated_batched": "更新的 1-Click 付款方式 ({count})。",
    "contact_event_order_cancelled": "訂單{order_id}中取消的商品",
    "contact_event_order_cancelled_batched": "取消的訂單 ({count})。",
    "contact_event_order_placed": "取消的 {order_id} 訂單。",
    "contact_event_order_placed_batched": "退款的訂單 ({$hash-get count})。",
    "contact_event_payment_method_added": "添加至帳戶的 {payment_type} 支付方式。",
    "contact_event_payment_method_added_batched": "添加至帳戶的付款方式({count})。",
    "contact_event_payment_method_changed": "訂單 {order_id} 的付款方式發生了變更。",
    "contact_event_payment_method_changed_batched": "訂單的付款方式發生了變更 ({count})。",
    "contact_event_payment_plan_changed": "訂單 {order_id} 的付款計畫發生了變更。",
    "contact_event_payment_plan_changed_batched": "訂單的付款計畫發生了變更 ({count})。",
    "contact_event_pdocs_max_price_edits": "「個人文檔設定」的最高變更費用調整為 {new_max_charge}。",
    "contact_event_pdocs_max_price_edits_batched": "更新的最高兌換收費個人文檔設定 ({count})。",
    "contact_event_pennywise_promo_applied": "已套用 Pennywise 促銷至 DSN：{dsn}",
    "contact_event_pennywise_promo_applied_batched": "已套用 Pennywise 促銷至 DSN：({count})",
    "contact_event_ping_device": "DSN {dsn} 設備已清除。",
    "contact_event_ping_device_batched": "偵測到的裝置 ({count})。",
    "contact_event_prime_auto_renew_settings": "設定於 {auto_renew_state} 的最優惠自動續訂。",
    "contact_event_prime_auto_revew_settings": "設定於 {auto_renew_state} 的最優惠自動續訂。",
    "contact_event_prime_cancel_exception": "成功取消的 PrimeCancelException 優惠。取消理由：{cancel_reason}。",
    "contact_event_prime_cancelled": "取消的優惠。",
    "contact_event_prime_cancelled_batched": "取消的優惠 ({count})。",
    "contact_event_prime_concession": "為訂單 {order_id} 作出的大讓步。",
    "contact_event_prime_concession_batched": "訂單 ({count}) 的大優惠。",
    "contact_event_prime_refund": "{amount} 的優惠退款。",
    "contact_event_prime_refund_batched": "優惠退款 ({count})。",
    "contact_event_prime_revoke_and_transfer_met": "轉移的 Prime 會員資格延期權杖",
    "contact_event_prime_revoke_and_transfer_met_batched": "轉移的 Prime 會員資格延期權杖",
    "contact_event_prime_revoke_met_no_transfer": "撤銷 Prime 會員資格權杖已成功，但轉移失敗",
    "contact_event_prime_revoke_met_no_transfer_batched": "撤銷 Prime 會員資格權杖已成功，但轉移失敗",
    "contact_event_prime_transfer_met": "轉移的 Prime 會員資格延期權杖",
    "contact_event_prime_transfer_met_batched": "轉移的 Prime 會員資格延期權杖",
    "contact_event_promo_added_to_account": "添加至帳戶的 {amount} 個廣告。",
    "contact_event_promo_added_to_account_batched": "添加至帳戶的廣告 ({count})。",
    "contact_event_promo_unredeemed": "{promoBalance} ???????",
    "contact_event_promo_unredeemed_batched": "未兌換的的促銷 ({count})。",
    "contact_event_refund_cancelled": "訂單 {order_id} 的退款已取消。",
    "contact_event_refund_created": "{order_id} 訂單的 {refund_amount} 退款。",
    "contact_event_refund_created_batched": "退款的訂單 ({count})。",
    "contact_event_refund_from_prime_cancel_exception": "PrimeCancelException 的 {refund_amount} 的退款。退款理由：{refund_reason}。",
    "contact_event_refund_from_prime_cancel_exception_batched": "退款優惠取消異常 ({count})。",
    "contact_event_refund_in_digital_library": "為客戶 {customerId} 的數位商品退款。原因：{reason}；OrderID：{orderId}；",
    "contact_event_register_kindle": "註冊的 {serial_number} Kindle。",
    "contact_event_register_kindle_batched": "註冊的 Kindle ({count})。",
    "contact_event_registry_deleted": "刪除的登記項 (RegistryID # {registry_id})。",
    "contact_event_registry_deleted_batched": "被刪除的登記項 ({count})。",
    "contact_event_release_aiv_license": "AIV 許可證 {asin} 已經發布在裝置 DSN {dsn}。",
    "contact_event_release_licenses": "為客戶 ({customer_id}) 發行裝置類型為 {device_type} 的 DSN {dsn} 授權。",
    "contact_event_remove_content_from_todo_list": "從{dsn} 設備移走的商品數量{number_of_removed_items} 。",
    "contact_event_remove_content_from_todo_list_batched": "從裝置移除的商品 ({count})。",
    "contact_event_remove_from_yvl": "ASIN {asin}從您的視訊庫已被刪除到刪除物品。",
    "contact_event_remove_kindle_from_blacklist": "DSN {dsn} 的 Kindle 已從遺失或被盜狀態移除，理由：{removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "從遺失或遭竊狀態移除的裝置 ({count})。",
    "contact_event_replacement_created": "創建的 {old_order_id} 訂單的置換訂單。新訂單： {new_order_id}。",
    "contact_event_replacement_created_batched": "為訂單生成的替代品 ({count})。",
    "contact_event_report_seller": "賣家報告的訂單 {order_id}。",
    "contact_event_report_seller_batched": "賣家報告的訂單 ({count}).",
    "contact_event_request_invoice": "訂單 {order_id} 的已經寄送給客戶的發票請求。",
    "contact_event_request_invoice_batched": "訂單 ({count}) 的已發送發票請求",
    "contact_event_resend_kindle_content": "使用 {devicetype}型裝置重新將數位內容{title}發送至DSN {dsn}。",
    "contact_event_resend_kindle_content_batched": "再次發送數位內容({count})。",
    "contact_event_resent_gifted_content": "ASIN {asin} 訂單 ID {order_id} 收件人地址已變更。",
    "contact_event_resent_gifted_content_batched": "更新的禮品訂單收貨電子郵件 ({count})。",
    "contact_event_reset_aiv_pin": "為客戶 {customer_id} 重設 AIV PIN 碼。",
    "contact_event_reset_kindle_first_allowance": "為客戶 {customer_id} 重設 Amazon 搶先讀零用金。",
    "contact_event_reset_password_email_sent": "已發送重置密碼電子郵件。",
    "contact_event_reset_password_email_sent_batched": "已發送重置密碼電子郵件 ({count})。",
    "contact_event_reset_prime_lending_library_limit": "為客戶 {customer_id} 將 Prime 借閱影片庫限制重設為 {quota}。",
    "contact_event_residence_address_changed": "已變更的居住地址。",
    "contact_event_residence_address_changed_batched": "更新的居住地址 ({count})。",
    "contact_event_restore_content": "為客戶 {customer_id} 還原 OrderID {order_id} 中的內容 ASIN {asin}。",
    "contact_event_restore_rental": "為客戶 {customer_id} 還原 ASIN 為 {asin} 的租借",
    "contact_event_restore_video": "為客戶 {customer_id} 還原 ASIN 為 {asin} 的影片。",
    "contact_event_retrocharge_disarmed": "RMA 解除 OrderID {order_id} – ASIN {asin}。解除原因：{disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "取消的 {order_id} 訂單中的 RMA。",
    "contact_event_rma_created": "{order_id} 訂單中創建的 RMA。",
    "contact_event_rma_created_batched": "為訂單建立的 RMA ({count})。",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_rosetta_transparency": "#RosettaTransparency{treatment}",
    "contact_event_rosetta_transparency_batched": "#RosettaTransparency({count})",
    "contact_event_rosetta_transparency_c": "#RosettaTransparencyControl",
    "contact_event_rosetta_transparency_t1": "#RosettaTransparencyT1",
    "contact_event_rosetta_transparency_t2": "#RosettaTransparencyT2",
    "contact_event_sds_assigned_package_to_driver": "追蹤編號 {trackingId} 已新增至行程。",
    "contact_event_sds_demo_contact_event_string": "用於測試。",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP 名稱：{driverName}，\n站點名稱：{stationName}，\n追蹤編號：{trackingId} 及 \n訂單編號：{orderId}\nUsecaseSession 編號：{usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "已開啟追蹤編號 {trackingId} 的地圖小工具",
    "contact_event_sds_package_repick": "客服專員已啟動追蹤編號 {trackingId} 的重選。",
    "contact_event_send_aiv_asin": "ASIN {asin}已經將您的視訊庫送至客戶{customer_id}。",
    "contact_event_ship_charge_reduce": "訂單 {order_id} 的運費由 {old_amount} 調整為 {new_amount}。",
    "contact_event_ship_charge_reduce_batched": "變更的送貨費用 ({$hash-get count})。",
    "contact_event_ship_method_upgraded": "更新的訂單{order_id}的送貨方式。",
    "contact_event_ship_method_upgraded_batched": "送貨方式發生變更的訂單 ({count})。",
    "contact_event_shipping_address_changed": "{new_order_id}訂單上的地址已變更。",
    "contact_event_sim_ticket_created": "已建立 SIM 支援單",
    "contact_event_special_offers_cancel": "為客戶 {customer_id} 取消 DSN {dsn} 上裝置類型為 {device_type} 的特別優惠。",
    "contact_event_special_offers_enrollment": "DSN {dsn} Kindle 的特價優惠 {enrollment_status}。",
    "contact_event_special_offers_enrollment_batched": "更新的特價優惠登記狀態 ({count})。",
    "contact_event_subscribe_and_save_cancelled": "取消的 {subscription_id} 訂閱的 Subscribe and Save。",
    "contact_event_subscribe_and_save_cancelled_batched": "「訂閱」與「存儲」已取消的訂閱 ({count})。",
    "contact_event_subscribe_and_save_schedule_change": "變更的 {subscription_id} 訂閱 Subscribe and Save 送貨排程。",
    "contact_event_subscribe_and_save_schedule_change_batched": "發生變更的訂閱 ({count})「訂閱」與「存儲」送貨排程表。",
    "contact_event_subscription_astro_workflow_launch": "CS 推出了 {subscriptionType} 訂閱 {subscriptionId} 的 Astro 取消工作流程。",
    "contact_event_subscription_auto_renew": "{plan_type} 自動續訂設定為{renew_status}。",
    "contact_event_subscription_auto_renew_batched": "更新的優惠自動續訂設定 ({count})。",
    "contact_event_subscription_auto_renew_off": "CS 關閉了 {subscriptionType} 訂閱 {subscriptionId} 的自動續訂。",
    "contact_event_subscription_cancel_full_refund": "CS 取消了 {subscriptionType} 訂閱 {subscriptionId}，且全額退款。",
    "contact_event_subscription_cancel_no_refund": "CS 取消了 {subscriptionType} 訂閱 {subscriptionId}，且不退款。",
    "contact_event_subscription_cancel_partial_refund": "CS 取消了 {subscriptionType} 訂閱 {subscriptionId}，且部分退款。",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} 訂閱在目前期限結束時，設定為從 {old_plan_name} 續訂至 {new_plan_name}。",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "更新後的 Prime 延期計畫設定 ({count})。",
    "contact_event_troubleshooting_wizard": "DSN {dsn}裝置容錯精靈已調用。",
    "contact_event_troubleshooting_wizard_batched": "啟動的裝置 ({count}) 的疑難排解精靈。",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: DSN {dsn} 裝置的 {annotation}。",
    "contact_event_troubleshooting_wizard_detailed_batched": "啟動的裝置 ({count}) 的疑難排解精靈。",
    "contact_event_unblock_international_purchase": "國際購買狀態由封鎖變更為{new_status}",
    "contact_event_unblock_international_purchase_batched": "國際採購的更新狀態 ({count})。",
    "contact_event_unlink_good_reads_account": "為客戶 {customer_id} 取消良好讀物帳戶的連結。",
    "contact_event_unlink_kindle_account": "為客戶 {customer_id} 取消連結 Kindle 帳戶。",
    "contact_event_unlock_dash_order": "{dsn} 裝置的以下 ASIN 已解瑣：{asins}。",
    "contact_event_update_locking_status": "Oft 裝置 {dsn} 的鎖定狀態已更新為：{newLockingStatus}",
    "contact_event_verify_email": "驗證傳送至客戶的電子郵件。",
    "contact_event_verify_email_email_sent": "已傳送電子郵件驗證。",
    "contact_event_verify_email_email_sent_batched": "已傳送電子郵件驗證 ({count})。",
    "contact_event_weekend_delivery_preference_changed": "已更新週末配送偏好",
    "contact_event_weekend_delivery_preference_changed_batched": "已更新週末配送偏好 {order_id}"
}