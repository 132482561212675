import React, {useContext, useState} from "react";
import {OutboundNotification} from "./OutboundNotification.types";
import {ExpandableDisplay} from "../../components/ExpandableDisplay";
import {NotificationHeader} from "./NotificationHeader";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Translation from "../../i18n/Translate";
import EmdashImage from "../../util/EmdashImage";
import IntlManager, {i18n} from "../../i18n/IntlManager";
import Button from "@amzn/awsui-components-react/polaris/button";
import {CallResponse} from "@amzn/csphoenix-react-client";
import {emitLatency} from "../../issueManager/IssueManager";
import Phoenix from "../../api/Phoenix";
import {OutboundCustomerNotificationContext} from "../OutboundCustomerNotificationPage";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import {processHtml} from "../../util/Text";
import '../../contact/ContactBoxStyle.css';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Cookies from "universal-cookie/es6";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

interface OutboundNotificationDisplayProps {
    notification: OutboundNotification;
    customerTimezone: string;
}

function buildFetchContentQuery(rtnMessageId: string): string {
    return (
        `query {
            outboundCustomerNotificationContent(rtnMessageId:"${rtnMessageId}"){
                contentEncrypted {
                    encryptedString
                }
            }
        }`
    );
}

function buildResendQuery(rtnMessageId: string, idempotencyKey: string): string {
    return (
        `mutation {
            resendOutboundEmail(
                input:{
                    rtnMessageId: "${rtnMessageId}",
                    idempotencyKey: "${idempotencyKey}"
                }
            ){
                ... on ResendOutboundEmailResultSuccess {
                    messageId
                }
            }
        }`
    );
}

const RESEND_COOLDOWN_MINUTES = 5;

interface ResendStatus {
    status: "warning" | "error" | "success";
    text?: string;
}

export const OutboundNotificationDisplay: React.FC<OutboundNotificationDisplayProps> = ({notification, customerTimezone}) => {
    const sentFrom = notification.fromEmail || notification.sourcePhoneNumber || "";
    const sentTo = notification.toEmail || notification.customerPhoneNumber || "";
    const COOKIE_NAME = "resendEmailTime";

    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
    const [isLoadingResend, setIsLoadingResend] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [notificationContent, setNotificationContent] = useState<string>("");
    const [resendStatus, setResendStatus] = useState<ResendStatus | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);


    const {reloadList, callPhoenix} = useContext(OutboundCustomerNotificationContext);

    function fetchNotificationContent(rtnMessageId: string): void {
        setIsLoadingContent(true);
        const query = buildFetchContentQuery(rtnMessageId);

        callPhoenix(query)
            .then((response: CallResponse) => processFetchContentResponse(response))
            .catch(() => {
                setError("An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.");
            });
    }

    function resendEmail(rtnMessageId: string): void {
        // Check if the resend cooldown is over
        const cookies = new Cookies();
        const currentCookie = cookies.get(COOKIE_NAME);
        if (currentCookie) {
            const waitTime = Math.round(((Number.parseInt(currentCookie) - (new Date().getTime())) / 1000) / 60);
            setResendStatus({status:"warning", text:i18n("RESEND_WAIT_WARNING", {
                values:{
                    "wait_mins": waitTime < 1 ? "<1" : waitTime
                }})});
            return;
        }
        setIsLoadingResend(true);
        // generate a random request id as idempotency key
        const requestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
        const query = buildResendQuery(rtnMessageId, requestId);

        callPhoenix(query)
            .then((response: CallResponse) => processResendEmailResponse(response))
            .catch(() => {
                setError("An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.");
            });
    }

    function processFetchContentResponse(response: CallResponse): void {
        setIsLoadingContent(false);
        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setError(error);
            });
            return;
        }
        if (response.error) {
            setError(response.error);
        }
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed?.data?.outboundCustomerNotificationContent?.contentEncrypted?.encryptedString) {
                    // If we get here then we did have a good call with results.
                    emitLatency('LatencyOutboundNotificationContent', response.latency);
                    setNotificationContent(parsed.data.outboundCustomerNotificationContent.contentEncrypted.encryptedString);
                }
            }
        } else {
            setError("An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.");
        }
    }

    function processResendEmailResponse(response: CallResponse): void {
        setIsLoadingResend(false);
        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setResendStatus({status:"error", text:error});
            });
            return;
        }
        if (response.error) {
            setResendStatus({status:"error", text:response.error});
        }
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed?.data?.resendOutboundEmail?.messageId) {
                    // If we get here then we did have a good call with results.
                    emitLatency('LatencyResendOutboundEmail', response.latency);
                    const expiry = (new Date().getTime()) + (1000 * 60 * RESEND_COOLDOWN_MINUTES);
                    const cookies = new Cookies();
                    cookies.set(COOKIE_NAME, expiry.toString(), {
                        expires: new Date( expiry),
                        domain: window.location.hostname,
                        path: '/',
                        sameSite: "none",
                        secure: true});
                    setResendStatus({status:"success", text:i18n("RESEND_SUCCESS")});
                    return;
                }
            }
        }
        setResendStatus({status:"error", text:i18n("RESEND_FAILED")});
    }

    const notificationDetails: JSX.Element = (
        <ColumnLayout columns={4}>
                {/* First Column */}
                <div>
                    <div className="outbound-notification-subheader">
                        <Translation stringId="SUBJECT"/>
                    </div>
                    <div>
                        {notification.subject ? <div>{notification.subject}</div>
                            : <EmdashImage ariaLabel={i18n("NO_SUBJECT")}/>}
                    </div>
                </div>
                {/* Second Column*/}
                <div>
                    <div className="outbound-notification-subheader">
                        <Translation stringId="DELIVERY_STATUS"/>
                    </div>
                    <div>
                        {notification.status ? <div>{notification.status}</div>
                            : <EmdashImage ariaLabel={i18n("NO_DELIVERY_STATUS")}/>}
                    </div>
                </div>
                {/* Third Column*/}
                <div>
                    <div className="outbound-notification-subheader">
                        &nbsp;
                    </div>
                    {notification.toEmail &&
                    <div>
                        <Button
                            variant="normal"
                            loading={isLoadingResend}
                            onClick={(): void => setShowModal(true)}
                        >
                            {i18n("RESEND")}
                        </Button>
                        {resendStatus &&
                        <Box>
                            <StatusIndicator type={resendStatus?.status}>
                                {resendStatus?.text}
                            </StatusIndicator>
                        </Box>
                        }
                    </div>}
                    <Modal
                        onDismiss={(): void => setShowModal(false)}
                        visible={showModal}
                        closeAriaLabel={i18n("CLOSE_MODAL_BUTTON_TEXT")}
                        size="medium"
                        footer={
                            <Box float={"right"}>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="link" onClick={(): void => setShowModal(false)}>
                                        {i18n("MODAL_NO_BUTTON_TEXT")}</Button>
                                    <Button variant="primary" onClick={(): void => {resendEmail(notification.messageId); setShowModal(false);}}>
                                        {i18n("MODAL_YES_BUTTON_TEXT")}</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header={i18n("WARNING")}
                    >
                        {i18n('RESEND_WARNING')}
                    </Modal>
                </div>
                {/* Fourth Column */}
                {resendStatus?.status === "success" &&
                <div>
                    <div className="outbound-notification-subheader">
                        &nbsp;
                    </div>
                    <Button
                        variant="normal"
                        onClick={(): void => reloadList()}
                    >
                        {i18n("RELOAD_LIST")}
                    </Button>
                </div>
                }
        </ColumnLayout>
    );

    const notificationContentComponent: JSX.Element = (
        <div>
            {
                isLoadingContent ?
                    <LoadingSpinner />:
                    error ?
                        <Alert header={i18n("ERROR")} type="error">{JSON.stringify(error)}</Alert> :
                        notification.visibility !== "FULL" ?
                            <Alert type="error">{i18n("RESTRICTED_INFORMATION")}</Alert> :
                            <div className="contact-box">
                                <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="CONTENT"/></Box>
                                {notificationContent ?
                                        <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: processHtml(notificationContent)}}/>
                                        : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CONTENT")}/>
                                }
                            </div>
            }
        </div>
    );

    return (<ExpandableDisplay
        initExpanded={false}
        header={<NotificationHeader
            sentFrom={sentFrom}
            sentTo={sentTo}
            sentDate={notification.sentDate}
            customerTimezone={customerTimezone}
        />}
        onToggleExpand={(): void => fetchNotificationContent(notification.messageId)}
        pinnedContent={notificationDetails}
        expandableContent={notificationContentComponent}
    />);
};