export const fr_FR = {
    "ACCESS_RESTRICTED_CONTENT": "Accès au contenu restreint",
    "ACTION": "Action",
    "ADD": "Ajouter",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Coordonnées supplémentaires",
    "ADD_ANNOTATION_FAILURE": "Échec de l'ajout de l’annotation.",
    "ADD_ANNOTATION_INVALID": "Annotation non valide. N’incluez aucune donnée sensible dans l’annotation.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nouvelle annotation",
    "ADD_ANNOTATION_SUCCESS": "Annotation ajoutée avec succès.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Problème sélectionné par l'agent",
    "AGENT_SELECTED_ISSUE_COPIED": "Problème sélectionné par l'agent copié.",
    "AMAZON_CONNECT_CALL_INFO": "Informations sur l’appel Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "Identifiant de contact Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Identifiant de contact Amazon Connect copié",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANNOTATED_BY": "Annoté par {agentLogin}.",
    "ANNOTATIONS": "Annotations",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Les annotations ne sont pas censées contenir des données client PII, telles que les numéros de téléphone et les adresses e-mail. Notre système automatisé essaie de faire respecter cela, mais parfois ne connaît pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "ANNOTATION_WARNING": "Les annotations peuvent être examinées par les clients conformément aux règles de confidentialité. N'indiquez pas d'informations personnelles ou d'opinions dans le champ des annotations.",
    "ATTACHMENTS_RESTRICTED": "Les pièces jointes sont confidentielles.",
    "ATTACHMENTS_TITLE": "Pièces jointes",
    "ATTACHMENT_DELETION_FAILED": "La suppression des pièces jointes a échoué, réessayez ultérieurement.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "La suppression des pièces jointes a réussi.",
    "AUTHENTICATING": "Authentification démarrée. Cela peut ouvrir une nouvelle fenêtre.",
    "AUTHENTICATION_FAILURE": "Un problème d'authentification s’est produit.",
    "AUTHENTICATION_MESSAGE": "Recherche de nouveaux identifiants. Cette fenêtre se fermera dans quelques secondes.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Retour",
    "BCC_EMAIL_ADDRESS": "Cci",
    "BLURBS_USED_TITLE": "Descriptifs utilisés",
    "CALL_LEG": "N° de la partie de l’appel",
    "CALL_LEG_ID_COPIED": "N° de la partie de l’appel copié.",
    "CALL_RESTRICTED": "Le contenu de l'appel est confidentiel.",
    "CALL_RETENTION_DELETED": "L'enregistrement a dépassé sa durée de rétention, il a été supprimé.",
    "CALL_TITLE": "Appel au service client Amazon",
    "CANCEL": "Annuler",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Ce client a un très grand nombre de contacts et tous n'ont pas été chargés. Les contacts sont toujours présentés du plus récent au plus ancien à partir de la date de fin. Modifiez la date de fin pour lancer le chargement à partir d'une autre date.",
    "CHANNELS": "Canaux",
    "CHAT_AGENT_CONNECTED": "{name} a accepté le chat.",
    "CHAT_CONTACT": "Contact par chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} perte de connexion réseau.",
    "CHAT_DISCONNECT_GENERIC": "{name} n' est plus connecté.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} perte de connexion réseau.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} perte de connexion réseau.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} a quitté conversation.",
    "CHAT_PARKED": "Cette conversation a été suspendue",
    "CHAT_PARKED_NOTES": "Notes de mise en pause",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} n’est plus en attente.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} est en attente.",
    "CHAT_RESTRICTED": "Le contenu de la conversation est confidentiel.",
    "CHAT_TITLE": "Conversation avec le service client Amazon",
    "CHAT_TRANSFER_NOTES": "Transférer des notes",
    "CHILD_WORK_REQUESTS": "Demandes de travail créées à partir de ce contact",
    "CHOOSE_OPTIONS": "Choisir des options",
    "CLOSE": "Fermer",
    "CLOSE_ALERT": "Fermer l’alerte",
    "CLOSE_MODAL_BUTTON_TEXT": "Fermer",
    "COLLAPSED": "Replié",
    "CONFIRM": "Confirmer",
    "CONTACTS": "Contacts",
    "CONTACT_COLLAPSED": "Coordonnées réduites.",
    "CONTACT_CREATION_FAILURE": "La création du nouveau contact a échoué",
    "CONTACT_EXPANDED": "Coordonnées détaillées.",
    "CONTACT_HISTORY": "Historique des contacts",
    "CONTACT_ID": "Identifiant du contact",
    "CONTACT_ID_COPIED": "Identifiant du contact copié.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contact",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Remontées",
    "CONTACT_REASSIGNED_TO_AGENT": "Le contact a été réassigné à l'agent.",
    "CONTACT_SELECT_CHECKBOX": "Case à cocher pour sélectionner un contact verrouillé",
    "CONTACT_STATUS_ESCALATION_BADGE": "Remontée",
    "CONTACT_TICKET": "Ticket de contact",
    "CONTACT_TICKET_CONTENT": "Contenu du ticket",
    "CONTACT_TICKET_CREATE": "Créer un ticket de contact",
    "CONTACT_TICKET_CREATION_FAILURE": "Échec de la création du ticket de contact",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket de contact créé",
    "CONTACT_TICKET_RESOLVER_GROUP": "Groupe de résolution",
    "CONTACT_TICKET_SEVERITY": "Gravité du ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID du dossier SIM",
    "CONTACT_TICKET_SUBJECT": "Sujet du ticket",
    "CONTACT_TIMELINE": "Chronologie du contact",
    "CONTACT_TYPE": "Type de contact",
    "CONTACT_WAS_TRANSFERRED": "Le contact a été transféré.",
    "CONTENT": "Contenu",
    "CONTENT_RESTRICTED": "Le contenu est restreint.",
    "COPY_AGENT_SELECTED_ISSUE": "Copie du problème sélectionné de l'agent : {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copier l'Identifiant de contact Amazon Connect",
    "COPY_CALL_LEG_ID": "Copier le Nº de la partie de l’appel : {value}",
    "COPY_CONTACT_ID": "Copier l'identifiant du contact : {value}",
    "COPY_CUSTOMER_ID": "Copier l'ID client",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copier le problème sélectionné par le client : {value}",
    "COPY_FAILURE": "Échec de la copie.",
    "COPY_ID": "Copier l'ID",
    "COPY_LINK": "Copier le lien de téléchargement d'image",
    "COPY_ORDER_ID": "Copier le numéro de commande : {value}.",
    "CREATE_CASE": "Créer un cas",
    "CREATE_FOLLOW_UP": "Créer un suivi",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Suivi créé avec succès, mais il n'a pas été possible d’ajouter l'annotation correspondante. Réessayez d'ajouter les annotations manuellement.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Spécifiez la méthode de contact préférée du client.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Impossible de créer un suivi avec une date et une heure d'échéance dans le passé.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Indiquez la date et l'heure auxquelles l'action de suivi est prévue.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Fuseau horaire",
    "CREATE_FOLLOW_UP_FAILURE": "Échec de la création du suivi.",
    "CREATE_FOLLOW_UP_INVALID": "Suivi non valide. Assurez-vous qu'il n'y a pas de données restreintes.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Saisissez l'action de suivi.",
    "CREATE_FOLLOW_UP_SUCCESS": "Suivi créé avec succès.",
    "CREATE_WORK_REQUEST": "Créer une demande de travail",
    "CROSS_MARKETPLACE_QUEUES": "Files d'attente inter-sites de vente",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Impossible de transférer le contact, car il est déjà résolu.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "L'opération a échoué, car le contact est dans un état non valide.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "L'opération a échoué car le contact n'a pas été trouvé",
    "CSALT_ERROR_INTERNAL_ERROR": "Impossible de verrouiller le contact",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Impossible de verrouiller un contact en raison d'arguments non valides",
    "CSALT_ERROR_INVALID_INPUT": "L'opération a échoué en raison d'entrées non valides.",
    "CSALT_ERROR_INVALID_QUEUE": "Impossible de transférer le contact, car la file d'attente sélectionnée n'est pas valide.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "L'opération a échoué car le contact est verrouillé sur un autre agent.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Impossible de verrouiller le contact en raison d'une erreur de dépendance",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Impossible de transférer le contact, car la file d'attente n'a pas été trouvée dans le service de back-end.",
    "CSALT_ERROR_UNKNOWN": "L'opération a échoué en raison d'une erreur inattendue.",
    "CURRENT_CONTACT": "Contact actuel",
    "CUSTOMER": "Client",
    "CUSTOMER_FOLLOWUPS": "Suivis pour le clients",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Impossible de récupérer les suivis pour le client",
    "CUSTOMER_ID_COPIED": "ID client copié",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Le contenu du client est confidentiel. (Aucun contact déterminé.)",
    "CUSTOMER_SELECTED_ISSUE": "Problème sélectionné par le client",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Problème sélectionné par le client copié.",
    "CUSTOMER_TIMEZONE": "Horodatages affichés selon le fuseau horaire du client",
    "DATE": "Date",
    "DATE_PICKER_NEXT_MONTH": "Mois suivant",
    "DATE_PICKER_PREVIOUS_MONTH": "Mois précédent",
    "DATE_PICKER_TODAY": "Aujourd'hui",
    "DECODING_AUDIO": "Décodage audio",
    "DELETE_ATTACHMENTS": "Supprimez les pièces jointes relatives aux contacts",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Supprimer les pièces jointes",
    "DELIVERY_STATUS": "Statut de livraison",
    "DISABLE_CONTENT_FILTER": "Désactiver le filtre de contenu",
    "DOWNLOAD": "Télécharger",
    "DURATION": "Durée",
    "EMAIL": "Adresse e-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "L’adresse ou les adresses e-mail du destinataire ne sont pas autorisées.",
    "EMAIL_BODY": "Corps de l'e-mail",
    "EMAIL_CONTACT": "Contact par e-mail.",
    "EMAIL_HEADER": "En-tête",
    "EMAIL_QUEUE_TITLE": "File d'attente des e-mails",
    "EMAIL_RESTRICTED": "Le contenu de cet e-mail est confidentiel.",
    "EMAIL_SENDING_FAILURE": "L'envoi d'un e-mail sortant a échoué.",
    "EMAIL_SUBJECT": "Objet de l'e-mail",
    "EMAIL_TITLE": "E-mail au service client Amazon",
    "END": "Fin",
    "END_DATE": "Date de fin",
    "ERROR": "Erreur",
    "ERROR_FAILED_TO_LOAD_DATA": "Échec du chargement des données.",
    "ESCALATION_CONTACT_HANDLING": "Gestion du contact de remontée",
    "ESCALATION_TAB_LEAD_LOGIN": "Identifiant du/de la Lead : {agentLogin}",
    "EXPANDED": "Élargi",
    "FAILED_TO_CREATE_WORK_REQUEST": "Impossible de créer la demande de travail",
    "FAILED_TO_FETCH_SYSKA": "Impossible de récupérer les alertes SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Impossible de réassigner le contact à l'agent.",
    "FETCHED_NUMBER_CONTACTS": "{number} Contacts récupérés.",
    "FILTER": "Filtrer",
    "FINDER": "Outil de recherche",
    "FOLLOW_UPS_COLLAPSED": "Suivis réduits",
    "FOLLOW_UPS_EXPANDED": "Suivis détaillés",
    "FOLLOW_UP_ALERT": "dispose d'un suivi prévu pour le {dueDate} créé par {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Moyen de contact",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "La moyen de contact est confidentiel.",
    "FOLLOW_UP_DUE": "Suivi attendu",
    "FOLLOW_UP_DUE_DATE": "Date d'échéance",
    "FOLLOW_UP_NOTE": "Remarque",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Aucun moyen de contact",
    "FOLLOW_UP_NO_NOTE": "La note est vide",
    "FOLLOW_UP_NO_TICKET": "Aucun ticket",
    "FOLLOW_UP_OWNER": "Propriétaire",
    "FOLLOW_UP_STATUS": "Statut",
    "FOLLOW_UP_STATUS_OPEN": "Ouvrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Résolu",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Suivis",
    "FROM": "De",
    "GENERATE_LINK": "Générer un lien",
    "HAS_OVERRIDE_TRY_AGAIN": "Remplacer le filtre présent. Veuillez réessayer.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "lien d’ajout d'image partagé avec le client",
    "INPUT_CHARACTERS_REMAINING": "Pas plus de {char_max} caractères ({char_remain} restants)",
    "INTERNAL_ERROR": "Erreur interne",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Une erreur interne s'est produite. Si l'erreur persiste, envoyez-nous un ticket pour nous expliquer le problème.",
    "INVALID_CONTACT": "Contact non valide.",
    "INVALID_DATA_MATCH": "Le système a effectué une classification non valide des données.",
    "INVALID_TIMESTAMP": "Horodatage non valide.",
    "IN_MARKETPLACE_QUEUES": "Files d'attente dans le site de vente",
    "ISSUE": "Problème",
    "ISSUE_CODE": "Code de problème",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Déjà abordé et résolu dans un contact précédent",
    "ISSUE_CODE_DESCRIPTION": "Sélectionnez la raison pour laquelle les contacts sélectionnés sont des doublons",
    "ISSUE_CODE_EXACTLY_SAME": "Exactement identique à celui d'un contact précédent",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Pris en charge et résolu par un autre agent du Service à la clientèle via le chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Mis en charge et résolu par un autre e-mail CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Pris en charge et résolu par un autre agent du Service à la clientèle via téléphone",
    "ITEMS_CONTACTED_ABOUT": "Article(s) objet du contact",
    "I_UNDERSTAND": "Je comprends",
    "KNOWN_ISSUES": "Problèmes connus",
    "LEARN_MORE": "En savoir plus",
    "LINK_COPIED": "Lien copié",
    "LOADING": "Chargement des données.",
    "LOADING_PREVIEW_TEXT": "Chargement de l'aperçu",
    "LOADING_RECORDING": "Chargement de l'enregistrement",
    "LOAD_MORE": "Afficher plus",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Chargez plus d'annotations.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Chargez plus de contacts pour ce client.",
    "LOCKED": "Verrouillé",
    "LOCKED_BY": "Bloqué par",
    "LOCK_CONTACT": "Verrouiller",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Impossible de verrouiller le contact, l'utilisateur ne dispose pas des autorisations nécessaires",
    "LOCK_CONTACT_SUCCESSFUL": "Contact verrouillé à",
    "MISSING_ORIGINAL_CONTACT_ID": "Veuillez saisir un numéro de contact original valide",
    "MODAL_NO_BUTTON_TEXT": "Non",
    "MODAL_YES_BUTTON_TEXT": "Oui",
    "NEXT": "Suivant",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Aucun problème sélectionné par l'agent.",
    "NO_ANNOTATED_BY": "Note laissée par un utilisateur inconnu.",
    "NO_ANNOTATIONS": "Aucune annotation disponible.",
    "NO_ATTACHMENTS": "Il n'y a aucune pièce jointe.",
    "NO_CHAT": "La conversation est vide.",
    "NO_CHILD_WORK_REQUESTS": "Aucune demande de travail n'a été créée à partir de ce contact",
    "NO_CONTACT": "Aucun contact disponible",
    "NO_CONTACTS_SELECTED": "Veuillez sélectionner les contacts pour les traiter comme spam",
    "NO_CONTENT": "Pas de contenu",
    "NO_CUSTOMER_SELECTED_ISSUE": "Aucun problème sélectionné par le client.",
    "NO_DELIVERY_STATUS": "Pas de statut de livraison",
    "NO_EMAIL_BODY": "Le champ E-mail est vide.",
    "NO_EMAIL_HEADER": "Pas d'en-tête d'e-mail.",
    "NO_EMAIL_QUEUE": "Aucune file d'attente d'e-mail associée à ce contact.",
    "NO_ITEMS_CONTACTED_ABOUT": "Aucun contact lié a un article.",
    "NO_RESULTS": "Aucun résultat n'a été trouvé.",
    "NO_SCREEN_POP": "Aucune fenêtre d’information",
    "NO_SENT_FROM": "Aucune adresse d’expéditeur.",
    "NO_SENT_TO": "Aucune adresse de destinataire",
    "NO_SUBJECT": "La ligne d'objet est vide.",
    "NO_SYSKA_ALERTS": "Aucune alerte SYSKA",
    "NO_USED_BLURBS": "Aucun descriptif utilisé pour ce contact.",
    "NO_VIEW": "Aucun contact ou client à afficher.",
    "NO_WORK_CATEGORY": "Aucune catégorie de travail associée à ce contact.",
    "NUMBER_OF_CONTACTS": "Contacts chargés",
    "OLDEST_LOADED_CONTACT": "Contact chargé le plus ancien",
    "OPEN": "Ouvrir",
    "OPENS_IN_A_NEW_TAB": "S'ouvre dans un nouvel onglet",
    "ORDER_ID_COPIED": "Numéro de commande copié.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Saisissez l'ID de contact original",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Zone de texte pour saisir l'ID de contact d'origine",
    "OTHER": "Autres",
    "OUTBOUND_EMAIL_BCC": "et Cci -",
    "OUTBOUND_EMAIL_CC": "en Cc -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Contenu des notifications réduit.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Contenu des notifications élargi.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Nombre maximal de résultats atteint. Réduisez la plage de temps et réessayez.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Le processus actuel nécessite du contenu PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Correspondance non valide en tant que numéro de téléphone, adresse e-mail ou autre PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Correspondance non valide en tant que carte de crédit ou IBAN.",
    "OVERRIDE_REASON_PROMPT": "Les annotations ne sont pas censées contenir des données client PII, telles que les numéros de téléphone et les adresses e-mail. Notre système automatisé essaie de faire respecter cela, mais il ne connaît parfois pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "PAPYRUS_SESSION_EXPLANATION": "Veuillez spécifier votre type de session Papyrus afin que nous puissions appliquer les bonnes restrictions de sécurité et optimiser l'UX pour vos besoins.",
    "PAPYRUS_SESSION_TYPE": "Type de session Papyrus",
    "PAUSE": "Pause",
    "PHONE_CONTACT": "Contact téléphonique.",
    "PLAY": "Lecture",
    "PLAYBACK_SPEED": "Vitesse de lecture",
    "POSTAL_MAIL_TITLE": "Courrier postal avec le service client Amazon",
    "PREPARING_RECORDING": "Enregistrement en cours de préparation",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Impossible de générer l'aperçu pour le contact",
    "PREVIOUS": "Précédent",
    "PRE_TRANSLATED_REPLY_EMAIL": "E-mail de réponse pré-traduit",
    "PRE_TRANSLATION_REPLY_TEXT": "Réponse saisie par un CSA avant la traduction de Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Actions du processus",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Processus en tant que doublon",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Traiter comme spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Résolution des contacts sélectionnés en tant que doublons",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Voulez-vous vraiment traiter les contacts sélectionnés en tant que spam ?",
    "PROCESS_DUPLICATE_FAILURE": "Impossible de résoudre les contacts sélectionnés en tant que doublons",
    "PROCESS_DUPLICATE_SUCCESS": "Les contacts sélectionnés ont été résolus en tant que doublons",
    "PROCESS_SPAM_FAILURE": "Impossible de transférer les contacts sélectionnés vers spam",
    "PROCESS_SPAM_SUCCESS": "Transfert des contacts sélectionnés vers spam réussi",
    "PROVIDE_ADDITIONAL_INFORMATION": "Fournir des informations supplémentaires pertinentes à ce contact",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Informations sensibles supprimées du contact :",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Ajouter un mot à la liste de suppression",
    "PURGE_BUTTON_TEXT": "Suppression",
    "PURGE_CONFIRMATION_CHECK": "J'ai vérifié les champs ci-dessus et je confirme que le texte contient effectivement des informations sensibles.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Je confirme que la pièce jointe comprend en effet des informations sensibles sur les clients.",
    "PURGE_CONFIRMATION_UNCHECKED": "Confirmez la case à cocher.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Impossible de supprimer les informations du contact",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Les informations ont été supprimées du contact avec succès",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} n'est pas une raison valable pour supprimer",
    "PURGE_REASON_FINANCIAL_INFO": "Informations financières",
    "PURGE_REASON_OTHER": "Autres informations sensibles",
    "PURGE_REASON_PII": "Informations personnelles identifiables",
    "PURGE_REASON_PLACEHOLDER": "Saisissez le motif de la suppression",
    "PURGE_REASON_TEXT": "Motif de la suppression",
    "PURGE_REMOVE_LABEL": "Supprimer",
    "PURGE_SELECTOR_PLACEHOLDER": "Sélectionnez le motif de la suppression des informations sensibles",
    "PURGE_SENSITIVE_INFO": "Supprimer les informations sensibles",
    "PURGE_WORD_NOT_FOUND": "Le mot n'est pas trouvé dans le texte du contact",
    "PURPOSE_AUDITING": "Audit pour le contrôle de qualité",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Système Back Office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Cette session a pour but d’obtenir les données de contact pour les systèmes Back Office. Par exemple, les pièces jointes à inclure dans les tickets ou SIM.",
    "PURPOSE_COACHING": "Revue des contacts de coaching",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Enquête de conformité",
    "PURPOSE_CURATION": "Création de contacts",
    "PURPOSE_CURATION_DESCRIPTION": "Ceci est la session de création des contacts C2CS/COV.",
    "PURPOSE_DESCRIPTION_AUDITING": "Il s'agit de la session d'audit des contacts et d'évaluation de leur qualité.",
    "PURPOSE_DESCRIPTION_COACHING": "Il s'agit de la session permettant d'examiner les contacts des agents dont vous êtes coach Performance Portal.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Il s'agit de la session consacrée aux enquêtes de surveillance des activités suspectes.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Il s'agit de la session pour gérer les contacts de remontée. Cela permet d'accéder aux coordonnées de n'importe quel client.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Il s'agit de la session pour la gestion des contacts en direct.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Cette action a pour but de supprimer les informations sensibles d'un contact",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Il s'agit de la session d'examen de la précision du modèle d'apprentissage automatique.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Il s'agit de la session pour passer en revue vos propres contacts passés.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Il s'agit de la session à usage général par défaut.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Gestion du contact de remontée",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Supprimer les informations sensibles",
    "REASON_FOR_PURGE": "Motif de la suppression",
    "REASON_FOR_PURGE_DESCRIPTION": "Motif de suppression des pièces jointes",
    "REASON_FOR_PURGE_MISSING": "Saisissez le motif de suppression des pièces jointes.",
    "REASSIGN_TO_AGENT": "Réassigner à l'agent",
    "RECORDING": "Enregistrement en cours",
    "RELOADING_CONTENT": "Rechargement du contenu.",
    "RELOAD_LIST": "Recharger la liste",
    "REPLY_BY": "Répondre par",
    "REPLY_BY_EMAIL": "Répondre par e-mail",
    "REPLY_BY_PHONE": "Répondre par téléphone",
    "REPLY_RESTRICTED": "Le contenu de la réponse est confidentiel.",
    "REQUEST": "Demande",
    "REQUEST_ACCESS_INSTRUCTIONS": "Veuillez indiquer la raison pour laquelle l'accès au contenu restreint est requis. (Ces demandes font l'objet d'un suivi et les abus peuvent avoir de graves conséquences.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Demande d'accès au contenu filtré",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Demander l'accès à du contenu restreint",
    "REQUEST_NOT_GRANTED": "La demande n'a pas été accordée.",
    "REQUEST_OVERRIDE": "Remplacer la demande",
    "RESEND": "Renvoyer",
    "RESEND_FAILED": "Impossible de renvoyer l'e-mail.",
    "RESEND_SUCCESS": "Renvoi de l’e-mail effectué avec succès.",
    "RESEND_WAIT_WARNING": "Vous devez attendre {wait_mins} minutes pour renvoyer à nouveau.",
    "RESEND_WARNING": "Êtes-vous sûr(e) de vouloir renvoyer l'e-mail au client ?",
    "RESET": "Réinitialiser",
    "RESOLVED": "Résolu",
    "RESOLVED_BY": "Résolu par",
    "RESTRICTED_DATA_MATCH": "{match} type correspondant {type}",
    "RESTRICTED_INFORMATION": "INFORMATIONS CONFIDENTIELLES",
    "RETRY": "Réessayer",
    "ROLE": "Rôle",
    "SCREEN_POP_DESCRIPTION_TITLE": "Description",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Non",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Oui",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Visible par l'agent",
    "SCREEN_POP_TITLE": "Fenêtre d’information",
    "SCREEN_POP_TYPE_AUTHENTICATING": "La fenêtre d’authentification s’est affichée sur l’écran de l'agent et a été utilisée.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "La fenêtre d’authentification s’est affichée sur l’écran de l'agent.",
    "SCREEN_POP_TYPE_CALLER_ID": "La fenêtre de l’identifiant de l'appelant s’est affiché sur l’écran de l'agent et a été utilisé.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "L’écran de l'identifiant de l'appelant s’est affichée sur l’écran de l'agent.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Une fenêtre non authentifiée s’est affichée et a été utilisée.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Une fenêtre non authentifiée s’est affichée sur l’écran de l'agent.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Le client est inconnu; aucune fenêtre disponible.",
    "SCREEN_POP_TYPE_OTHER": "Autre",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Non",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Oui",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Utilisé par l'agent",
    "SDS_TOOLS": "Outil(s) d’assistance Expédition et livraison",
    "SEARCH": "Rechercher",
    "SELECTED_ROLE": "Rôle sélectionné",
    "SELECTED_VIEW": "Vue sélectionnée",
    "SELECT_CHANNELS": "Sélectionner les canaux",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Pour sélectionner un code de problème afin de marquer les contacts sélectionnés comme doublons",
    "SELECT_TRANSFER_DESTINATION": "Sélectionner la destination de transfert",
    "SELECT_WORK_REQUEST_QUEUE": "Sélectionner la file d'attente des demandes de travail",
    "SENDER_EMAIL_ADDRESS": "Expéditeur",
    "SENDING_EMAIL": "Envoi de l’e-mail en cours",
    "SEND_EMAIL": "Envoyez un e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Voulez-vous vraiment envoyer un lien de téléchargement d'image au client ?",
    "SEND_OUTBOUND_EMAIL": "Envoyez un e-mail sortant",
    "SEND_OUTBOUND_EMAIL_WARNING": "Êtes-vous sûr de vouloir envoyer un e-mail sortant à",
    "SENT_FROM": "De",
    "SENT_OUTBOUND_EMAIL": "Un e-mail sortant a été envoyé. Un nouveau contact a été créé",
    "SENT_TO": "Envoyé à",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portail transporteur",
    "SKILL": "Compétence",
    "SKIP_SILENCES": "Ignorer les silences",
    "SMS": "SMS",
    "SMS_LINK": "Lien SMS",
    "SOURCE_TEXT": "Texte source",
    "SP_BOTTOM": "Bas",
    "SP_POSITION": "Position du panneau divisé",
    "SP_PREFERENCES": "Préférences concernant le panneau divisé",
    "SP_RESIZE": "Redimensionner le panneau fractionné",
    "SP_SIDE": "Côté",
    "START": "Commencer",
    "STATE_TRANSITIONS": "Transitions d'état",
    "STATUS": "État",
    "SUBJECT": "Objet",
    "SYSKA_ALERTS": "Alertes Syska",
    "TEXT_TO_PURGE": "Texte à supprimer",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Les cookies tiers doivent être activés.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Les données PII des clients, telles que les numéros de téléphone et les adresses e-mail, ne sont pas autorisées lors de la création d'un ticket. Notre système automatisé essaie de faire respecter cette règle, mais il arrive qu'il ne fasse pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Délai d'attente : impossible de charger l'enregistrement",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Utilisez le format 00 h 00.",
    "TO": "À",
    "TO_EMAIL_ADDRESS": "À",
    "TRANSFER": "Transférer",
    "TRANSFERRED_CHAT_TITLE": "Chat transféré au service client Amazon",
    "TRANSFERRING": "Transfert...",
    "TRANSFER_CONTACT": "Transférer un contact",
    "TRANSFER_CONTACT_COMMENTS": "Annotation facultative",
    "TRANSFER_FAILED": "Échec du transfert",
    "TRANSFER_FOLLOW_UP": "Suivi des transferts",
    "TRANSFER_FOLLOW_UP_FAILED": "Impossible de transférer le suivi.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Impossible de transférer le suivi, car le nom d’utilisateur du nouveau propriétaire n’est pas valide.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Vous n'avez pas l'autorisation de transférer ce suivi.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Saisissez le nom d’utilisateur du nouveau propriétaire. Exemple : {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transférer le suivi ci-dessus à :",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Suivi transféré avec succès.",
    "TRANSLATED_TEXT": "Texte traduit",
    "TRANSLATION": "Traduction",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} n'est pas un nom de file d'attente valide.",
    "TYPE_AGENT_LOGIN": "Saisir l’identifiant de l’agent",
    "TYPE_EMAIL_ADDRESS": "Saisissez une adresse e-mail",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Saisissez plusieurs adresses e-mail séparées par une virgule",
    "TYPE_QUEUE_NAME": "Saisir le nom de file",
    "UNKNOWN_CONTACT_TYPE": "Type de contact inconnu.",
    "UNLOCKED": "Déverrouillé",
    "UNRESOLVED": "Non résolu",
    "URGENT_SYSKA_ALERTS": "Messages SYSKA urgents",
    "USE": "Utiliser",
    "USER_TIMEZONE": "Horodatages affichés selon votre fuseau horaire local",
    "USE_CONTACT_FINDER": "Utiliser l’outil de recherche de contacts",
    "VIEW": "Afficher",
    "WARNING": "Avertissement",
    "WELCOME_MESSAGE": "Bienvenue sur le site de Papyrus Static !",
    "WHAT_IS_PAPYRUS": "Qu'est-ce que Papyrus ?",
    "WITH_THE_ANNOTATION": "avec l'annotation",
    "WORK_CATEGORY_TITLE": "Catégorie de travail",
    "WORK_REQUEST": "Demande de travail",
    "WORK_REQUEST_CONTENT": "Contenu de la demande de travail",
    "WORK_REQUEST_CREATED": "Demande de travail créée",
    "WORK_REQUEST_FOR_CONTACT": "Demande professionnelle de contact",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Les données PII des clients, telles que les numéros de téléphone et les adresses e-mail, ne sont pas autorisées lors de la création d'une demande de travail. Notre système automatisé essaie de faire respecter cette règle, mais il arrive qu'il ne fasse pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Vous êtes sur le point de transférer vers la file d'attente",
    "copy_of_cs_papyrus_type_agent_login": "Saisir l’identifiant de l’agent"
}