import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Translation from "../i18n/Translate";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import {TokenStatus} from "../devtools/TokenStatus";
import {RunLocal} from "../devtools/RunLocal";

interface RoleSelecProps {
    availableRoles: SelectProps.Option[];
    activeRole: SelectProps.Option;
    disabled: boolean;
    onRoleChange: (role: string, label: string) => void;
}

export const RoleSelect: React.FC<RoleSelecProps> = (
    {
        availableRoles,
        activeRole,
        disabled,
        onRoleChange
    }
) => {
    return (
        availableRoles.some(role => role.value === 'DEVELOPER') ?
            <div>
                <Box variant="h3" fontSize="heading-xs">
                    <Translation stringId="ROLE"/>
                </Box>
                <Box display="inline-block">
                    <FormField>
                        <Select options={availableRoles}
                                selectedOption={activeRole}
                                disabled={disabled}
                                onChange={(event): void => {
                                    if (event.detail.selectedOption.value && event.detail.selectedOption.label) {
                                        onRoleChange(event.detail.selectedOption.value, event.detail.selectedOption.label);
                                    }
                                }}/>
                    </FormField>
                    <TokenStatus/>
                    <RunLocal/>
                </Box>
            </div>
            : null
    );
};