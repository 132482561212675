import React, {useState} from "react";
import {Contact, CustomerAccessLevel} from "./Contact.types";
import Box from "@amzn/awsui-components-react/polaris/box";
import ContactMediumDisplay from "./ContactMediumDisplay";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import IntlManager, {i18n} from "../i18n/IntlManager";
import {Copyable} from "../util/Copyable";
import EmdashImage from "../util/EmdashImage";


interface EscalationContactsDisplayProps {
    dartEscalationContactList: Contact[];
    customerTimezone: string;
    customerAccessLevel: CustomerAccessLevel;
    marketplaceId: string;
}

const EscalationContactsDisplay: React.FC<EscalationContactsDisplayProps> = ({dartEscalationContactList,
                                                                             customerTimezone,
                                                                             customerAccessLevel,
                                                                             marketplaceId,
                                                                             }) => {

    const [selectedContact, setSelectedContact] = useState<Contact>(dartEscalationContactList[0]);

    return (
        <div>
            {dartEscalationContactList.length > 1 ?
                <div>
                    <Copyable
                        actionTranslationId="COPY_CONTACT_ID"
                        successTranslationId="CONTACT_ID_COPIED"
                        content={
                            <Box display="inline-block">
                                <FormField>
                                    <Select
                                        selectedOption={{ label: selectedContact.contactId,
                                            value: selectedContact.contactId}}
                                        options={dartEscalationContactList.map(c => {
                                            return {label: c.contactId,
                                                value: c.contactId};
                                        })}
                                        onChange={(event): void => {
                                            const contact: Contact | undefined = dartEscalationContactList.find(c => c.contactId === event.detail.selectedOption.value);
                                            if (contact) {
                                                setSelectedContact(contact);
                                            }
                                        }}
                                    />
                                </FormField>
                            </Box>}
                        valueToCopy={selectedContact.contactId}/>
                </div>
                :
                dartEscalationContactList.length === 1 ?
                    <div>
                        <Copyable
                            actionTranslationId="COPY_CONTACT_ID"
                            successTranslationId="CONTACT_ID_COPIED"
                            content={selectedContact.contactId}
                            valueToCopy={selectedContact.contactId}/>
                    </div>
                    : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_VIEW")}/>
            }
            <div>{i18n('ESCALATION_TAB_LEAD_LOGIN', {values: {"agentLogin": selectedContact.owner?.agentLogin}})}</div>
            <ContactMediumDisplay contact={selectedContact}
                                  customerAccessLevel={customerAccessLevel}
                                  customerTimezone={customerTimezone}
                                  marketplaceId={marketplaceId}
            />
        </div>
    );
};

export default EscalationContactsDisplay;
