import {ContextualAlert} from "./Contact.types";
import React from "react";
import {i18n} from "../i18n/IntlManager";
import {processHtml} from "../util/Text";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Container from "@amzn/awsui-components-react/polaris/container";
import Button from "@amzn/awsui-components-react/polaris/button";
import shrink from "../img/shrink.svg";

interface ContextualAlertsDisplayProps {
    alerts: ContextualAlert[];

}

const ContextualAlertsDisplay: React.FC<ContextualAlertsDisplayProps> = ({alerts}) => {

    const [alertIndex, setAlertIndex] = React.useState<number>(1);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const selectedAlert = alerts[alertIndex - 1];

    const displayStyle: React.CSSProperties = isExpanded ? { minHeight: "100px" } : { height: "100px" };

    return (
        <div style={{border: "1px solid red"}}>
            <Container
                footer={
                    alerts.length > 1 &&
                    <Pagination
                        ariaLabels={{
                            nextPageLabel: i18n("NEXT"),
                            previousPageLabel: i18n("PREVIOUS"),
                            pageLabel: (pageNumber): string => `${pageNumber}`
                        }}
                        currentPageIndex={alertIndex}
                        onChange={({detail}): void =>
                            setAlertIndex(detail.currentPageIndex)
                        }
                        pagesCount={alerts.length}
                    />
                }
                header={
                    <Header
                        actions={
                            isExpanded ?
                                <Button
                                    ariaLabel={i18n("COLLAPSED")}
                                    variant="icon"
                                    iconUrl={shrink}
                                    onClick={(): void => setIsExpanded(false)} /> :
                                <Button
                                    ariaLabel={i18n("EXPANDED")}
                                    variant="icon"
                                    iconName={"expand"}
                                    onClick={(): void => setIsExpanded(true)} />
                        }
                    >
                        <StatusIndicator type="warning">
                            <span style={{fontSize: 16}}>
                                <b>
                                {selectedAlert.displayableAlertTitle}
                                </b>
                            </span>
                        </StatusIndicator>
                    </Header>
                }
            >
                <div style={{
                    ...{
                        whiteSpace: "pre-wrap",
                        overflowY: "auto",
                    },
                    ...displayStyle
                }} dangerouslySetInnerHTML={{__html: processHtml(selectedAlert.displayableAlertBody)}}/>
            </Container>
        </div>
    );
};

export default ContextualAlertsDisplay;

