export const es_MX = {
    "contact_event_GC_added": "Se agregó el GC {gcId} a la cuenta.",
    "contact_event_GC_added_batched": "Se agregó el GC a la cuenta ({count}).",
    "contact_event_GC_added_purchaser": "Se agregó el GC {gcId} a la cuenta del comprador.",
    "contact_event_GC_added_purchaser_batched": "Se agregó el GC a la cuenta del comprador ({count}).",
    "contact_event_GC_added_recipient": "Se agregó el GC {gcId} a la cuenta del destinatario.",
    "contact_event_GC_added_recipient_batched": "Se agregó el GC a la cuenta del destinatario ({count}).",
    "contact_event_GC_added_scratchedCode": "GC # {gcId} añadido a la cuenta a través de la herramienta de código de canje.",
    "contact_event_GC_added_scratchedCode_batched": "GC agregado a la cuenta por medio de la herramienta código de canje ({count}).",
    "contact_event_GC_cancelled": "Se canceló el GC {gcId}.",
    "contact_event_GC_cancelled_batched": "Se canceló el GC ({count}).",
    "contact_event_GC_changed_expiry": "Se cambió la fecha de vencimiento del GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Se cambió la fecha de vencimiento del ({count}).",
    "contact_event_GC_locked": "Se bloqueó el GC #{gcId}.",
    "contact_event_GC_locked_batched": "Se bloqueó el GC ({count}).",
    "contact_event_GC_removed": "Se quitó el GC #{gcId} de la cuenta.",
    "contact_event_GC_removed_batched": "Se quitó el GC de la cuenta ({count}).",
    "contact_event_GC_resent_email": "Se reenvió el GC #{gcId} al Id. de correo electrónico {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "Se envió el GC al Id. de correo electrónico ({count}).",
    "contact_event_GC_resent_post": "Se reenvió el GC #{gcId} por correo postal.",
    "contact_event_GC_resent_post_batched": "Se envió el GC por correo postal ({count}).",
    "contact_event_GC_temporary_locked": "Se bloqueó temporalmente el GC #{gcId}.",
    "contact_event_GC_temporary_locked_batched": "Se bloqueó temporalmente el GC ({count}).",
    "contact_event_GC_transferred": "Se transfirió el GC {gcId} del cliente {customerID} a {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "Se transfirió el GC ({count}).",
    "contact_event_GC_unlocked": "Se desbloqueó el GC #{gcId}.",
    "contact_event_GC_unlocked_batched": "Se desbloqueó el GC ({count}).",
    "contact_event_PV_added": "PV #{pvId} agregado a la cuenta.",
    "contact_event_PV_locked": "PV #{pvId} bloqueado.",
    "contact_event_PV_temporary_locked": "PV #{pvId} bloqueado temporalmente.",
    "contact_event_PV_unlocked": "PV #{pvId} desbloqueado.",
    "contact_event_a_to_z_guarantee_requested_gwp": "Reclamo de la garantía de la A a la Z enviada para el pedido {order_id}.",
    "contact_event_access_code_added": "Código de acceso agregado",
    "contact_event_account_closed": "Cuenta cerrada.",
    "contact_event_account_closed_batched": "Cuenta cerrada ({count}).",
    "contact_event_account_reopened": "Cuenta reabierta",
    "contact_event_account_reopened_batched": "Cuentas reabiertas ({count})",
    "contact_event_account_temporarily_disabled": "Cuenta temporalmente desactivada.",
    "contact_event_account_temporarily_disabled_batched": "Cuentas temporalmente desactivadas ({count})",
    "contact_event_account_whitelisted": "Cuenta en la lista de admisión.",
    "contact_event_address_deactivated": "Dirección desactivada.",
    "contact_event_address_deactivated_batched": "Dirección desactivada ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Suscripción a Amazon Video renovada automáticamente",
    "contact_event_amazon_video_subscription_cancelled": "Suscripción a Amazon Video cancelada",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulario enviado: formulario de escalación DSL\nID de sesión de caso de uso: {usecaseSessionID}\nEstación de entrega: {deliveryStationField}\nTipo de solicitud: {requestTypeField}\nID de contacto: {contactIdField}\nIdentificador de pedido: {orderIdField}\nDirección de correo electrónico del cliente: {customerEmailField}\nNúmero de teléfono del cliente: {customerPhoneNumberField}\nTBA: {tbasField}\nCódigo de acceso (según aplique): {accessCodeField}\nResumen del problema: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formularios de escalación DSL presentados: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulario enviado: formulario de comentarios DSP DA\nID de sesión de caso de uso: {usecaseSessionID}\nCódigo de estación de entrega: {deliveryStationField}\nID de contacto: {contactIdField}\nID de cuenta del cliente: {customerIdField}\nID del transportador de DA: {driverIdField}\nFecha del incidente (AAAA-MM-DD): {dateOfOccurrenceField}\nHora del incidente (HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nFuente de comentarios: {sourceOfFeedbackField}\nDetalles de los comentarios: {issueField}\nDetalles adicionales: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formularios de comentarios DSP DA presentados: ({count})",
    "contact_event_apply_adrive_music_trial": "Cloud Player Premium aplicado para cliente {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Cuenta migrada a {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Las cuentas de Audible migraron ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} fue reembolsado por el monto de {cashAmount}. Adicionalmente, esta transacción restauró un {couponAmount} cupón a la cuenta.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} fue reembolsado por el monto de {cashAmount}. Adicionalmente, esta transacción restauró un {couponAmount} cupón a la cuenta.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} fue reembolsado por el monto de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} fue reembolsado por el monto de {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} fue reembolsado y restaurado un {couponAmount} cupón a la cuenta.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} fue reembolsado y restaurado un {couponAmount} cupón a la cuenta.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} créditos se han eliminado de la cuenta.",
    "contact_event_audible_credit_issued": "Se agregaron {numCredit} créditos a la cuenta.",
    "contact_event_audible_credit_issued_batched": "Se agregaron créditos a la cuenta ({count}).",
    "contact_event_audible_credit_refund": "{title} fue reembolsado y restaurado {creditAmount} crédito(s) a la cuenta.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} fue reembolsado y restaurado {creditAmount} crédito(s) a la cuenta.{counter} ({count})",
    "contact_event_audible_credit_restored": "Se restauraron {amount} créditos a la cuenta.",
    "contact_event_audible_credit_restored_batched": "Se restauraron {amount} créditos a la cuenta.({count})",
    "contact_event_audible_extra_credit_purchased": "Se compraron 3 créditos extra.",
    "contact_event_audible_extra_credit_purchased_batched": "Se compraron 3 créditos extra.({count})",
    "contact_event_audible_hiatus_cancelled": "El período de interrupción terminó antes, y la membresía se ha reanudado.",
    "contact_event_audible_hiatus_cancelled_batched": "El período de interrupción terminó antes, y la membresía se ha reanudado.({count})",
    "contact_event_audible_hiatus_started": "La membresía se colocó en una interrupción de {duration}- meses.",
    "contact_event_audible_hiatus_started_batched": "La membresía se colocó en una interrupción de {duration}- meses. ({count})",
    "contact_event_audible_membership_enrollment": "Registrado en el \nplan de membresía {membershipPlan}.",
    "contact_event_audible_membership_enrollment_batched": "Registrado en los planes de membresía de Audible ({count}).",
    "contact_event_audible_membership_plan_refund": "Se reembolsó la tarifa de la membresía por el monto de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Se reembolsó la tarifa de la membresía por el monto de {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Se cambió de {oldMembershipPlan} al plan de membresía {newMembershipPlan}.",
    "contact_event_audible_membership_switched_batched": "Se cambiaron los planes de membresía de Audible ({count}).",
    "contact_event_audible_romance_package_enrollment": "Inscrito en el {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Inscrito en el {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "Se accedió a {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Se accedió a {wizardName}. ({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "El plan de {membership} se canceló correctamente.",
    "contact_event_blacklist_kindle": "Dispositivo colocado en la lista negra {serial_number}.",
    "contact_event_call_was_flagged": "Este contacto se marcó como {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Este contacto se marcó como {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "El plan de {membership} se canceló correctamente.",
    "contact_event_cancel_audible_membership_batched": "El plan de {membership} se canceló correctamente.({count})",
    "contact_event_cancel_audible_romance_package_batched": "El plan de {membership} se canceló correctamente.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "Se canceló la Fecha de Cancelación Futura previamente establecida.",
    "contact_event_captcha_bypass_key_generated": "Se generó la clave de derivación de Captcha.",
    "contact_event_captcha_bypass_key_generated_batched": "Se generó la clave de derivación de Captcha ({count}).",
    "contact_event_channels_auto_renew_off": "CS desactivó la renovación automática para la suscripción a Canales PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS desactivó la renovación automática a través de Astro.",
    "contact_event_channels_full_refund": "CS canceló la suscripción a Canales PV {subscriptionid} con reembolso completo.",
    "contact_event_channels_full_refund_static": "CS canceló la suscripción con reembolso completo a través de Astro.",
    "contact_event_channels_no_refund": "CS canceló la suscripción a Canales PV {subscriptionid} sin reembolso.",
    "contact_event_channels_no_refund_static": "CS canceló la suscripción sin reembolso a través de Astro.",
    "contact_event_channels_workflow_launch": "CS lanzó un flujo de trabajo de cancelación de Astro para la suscripción a Canales PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS lanzó un flujo de trabajo de cancelación de Astro.",
    "contact_event_coins_adjust": "Tipo de ajuste: {type} <br />\nMotivo: {reason} <br />\nImporte: {amount} <br />\nComentarios: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo de monedas ajustado ({count}).",
    "contact_event_concessions_abuse": "No se envió HDM, porque el cliente es un usuario {risk_status}.",
    "contact_event_contact_seller": "Se contactó al vendedor para el pedido {order_id}.",
    "contact_event_contact_seller_batched": "Se contactó al vendedor por los pedidos ({count}).",
    "contact_event_contact_was_initiated_from": "Se inició el contacto desde ASIN de producto {ProductAsin} descripción de producto {ProductDescription} Url de producto {ProductUrl}.",
    "contact_event_csc_sds_override_geo_fence_submit": "Anular la solicitud de geocerca enviada para el número de seguimiento {trackingId} Motivo: {overrideReason} Página:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Anular la solicitud de geocerca enviada para el número de seguimiento {trackingId} Motivo: {overrideReason} Página:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocodificación actualizada. Tipo de geocodificación: {geocodeType}, número de seguimiento: {trackingId}, identificador de dirección: {addressId}, motivo de anulación: {overrideReason}, identificador de página: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocodificación actualizada. Tipo de geocodificación: {geocodeType}, motivo de anulación: {overrideReason}, número de seguimiento: {trackingId}, identificador de dirección: {addressId} e identificador de página: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "autenticación del cliente aprobada",
    "contact_event_customer_authentication_passed_batched": "autenticación del cliente aprobada({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_delete_alexa_voice_profile": "Perfil de voz de Alexa eliminado para speakerId: {speakerId}, customerID: {customerID} y estado: {status}",
    "contact_event_delete_data_alexa_comms": "Perfil de Alexa Comms eliminado para commsID: {commsId}, CustomerID: {customerID} y estado: {status}",
    "contact_event_deprovision_alexa_comms": "Perfil desaprovisionado de Alexa Comms para commsID: {commsId}, ID de cliente: {customerID} y estado: {status}",
    "contact_event_disable_mayday_failed": "No se pudo desactivar la función Mayday para el DSN de dispositivo {dsn}.",
    "contact_event_disable_mayday_success": "Se desactivó correctamente la función Mayday para el DSN de dispositivo {dsn}.",
    "contact_event_dunning_pause": "Proceso de reclamación de pago pausado. Consulta el historial de pagos y transacciones para obtener más detalles.",
    "contact_event_dvbypost_extra_dispatch": "{amount} envíos adicionales solicitados para este cliente.",
    "contact_event_dvbypost_extra_envelope": "Se activó un sobre adicional para este cliente.",
    "contact_event_dvbypost_fsk18_form_request": "Formulario FSK18 angefordert",
    "contact_event_dvbypost_problem_code_change": "Configuración de disco {disc_id} en {problem_code}",
    "contact_event_enable_mayday_failed": "No se pudo reactivar la función Mayday para el DSN de dispositivo {dsn}.",
    "contact_event_enable_mayday_success": "Se reactivó correctamente la función Mayday para el DSN de dispositivo {dsn}.",
    "contact_event_first_time_user_experience_set": "Establecer experiencia de usuario de primera vez",
    "contact_event_first_time_user_experience_set_batched": "Establecer experiencia de usuario de primera vez({count}).",
    "contact_event_free_time_unlimited_cancelled": "Se canceló la suscripción a Free Time Unlimited para el cliente: {customerId}, DSN: {deviceSerialNumber}, cancelación realizada por: {agentId}, motivo: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Se canceló la suscripción a Free Time Unlimited ({count}).",
    "contact_event_future_cancel_audible_membership": "El plan de {membership} se ha configurado para una Cancelación Futura el {date}.",
    "contact_event_future_cancel_audible_membership_batched": "El plan de {membership} se ha configurado para una Cancelación Futura el {date}.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} se eliminó de forma permanente de tu Biblioteca de videos.",
    "contact_event_hmd_suppression": "No se envió HDM porque: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Chat inactivo desconectado",
    "contact_event_idle_chat_disconnect_batched": "Chat inactivo desconectado ({count})",
    "contact_event_live_assist_launched": "Se abrió Mayday Screen Sharing para el DSN: {dsn}, kcsID: {number} respuesta del cliente: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Se abrió Mayday Screen Sharing para los dispositivos ({count}).",
    "contact_event_lock_dash_order": "Los siguientes ASIN del dispositivo {dsn} fueron bloqueados: {asins}.",
    "contact_event_music_auto_renew_off": "CS desactivó la renovación automática para {subscriptionid} de la suscripción de Music.",
    "contact_event_music_auto_renew_off_static": "CS desactivó la renovación automática para suscripción de Music a través de Astro.",
    "contact_event_music_full_refund": "CS canceló {subscriptionid} de la suscripción de Music con reembolso completo",
    "contact_event_music_full_refund_static": "CS canceló la suscripción de Music con reembolso completo a través de Astro.",
    "contact_event_music_no_refund": "CS canceló {subscriptionid} de la suscripción de Music sin reembolso.",
    "contact_event_music_no_refund_static": "CS canceló la suscripción de Music sin reembolso a través de Astro.",
    "contact_event_music_unlimited_auto_renew_off": "Servicio al cliente desactivó la renovación automática para la suscripción a Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "Servicio al cliente canceló la suscripción a Amazon Music Unlimited {subscriptionid} con reembolso completo.",
    "contact_event_music_unlimited_no_refund": "Servicio al cliente canceló la suscripción a Amazon Music Unlimited {subscriptionid} sin reembolso.",
    "contact_event_music_unlimited_partial_refund": "Servicio al cliente canceló la suscripción a Amazon Music Unlimited {subscriptionid} con un reembolso parcial.",
    "contact_event_music_unlimited_workflow_launch": "Servicio al cliente lanzó un flujo de trabajo de cancelación de Astro para la suscripción a Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS lanzó un flujo de trabajo de cancelación de Astro para {subscriptionid} de la suscripción de Music.",
    "contact_event_music_workflow_launch_static": "CS lanzó un flujo de trabajo de cancelación de Astro para Music Unlimited.",
    "contact_event_one_click_payment_updated": "Se actualizó el pago con un solo clic.",
    "contact_event_one_click_payment_updated_batched": "Se actualizó el pago con un solo clic ({count}).",
    "contact_event_payment_method_changed": "Se cambió el método de pago para el pedido {order_id}.",
    "contact_event_payment_method_changed_batched": "Se cambió el método de pago para los pedidos ({count}).",
    "contact_event_pennywise_promo_applied": "Promoción Pennywise aplicada para DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promoción Pennywise aplicada para DSN: ({count})",
    "contact_event_promo_unredeemed": "No se canjeó la promoción de {promoBalance}.",
    "contact_event_promo_unredeemed_batched": "Promociones sin canjear ({count})",
    "contact_event_refund_cancelled": "Se canceló el reembolso para el pedido {order_id}.",
    "contact_event_registry_deleted": "Registro eliminado (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Registros eliminados ({count}).",
    "contact_event_release_aiv_license": "La licencia de AIV {asin} se liberó en el dispositivo DSN {dsn}.",
    "contact_event_remove_from_yvl": "ASIN {asin} se eliminó de tu Biblioteca de videos y pasó a Artículos eliminados.",
    "contact_event_request_invoice": "Solicitud de factura enviada al vendedor para el pedido {order_id}.",
    "contact_event_request_invoice_batched": "Solicitud de factura enviada para los pedidos ({count}).",
    "contact_event_reset_password_email_sent": "Se envió un correo electrónico de restablecimiento de contraseña.",
    "contact_event_reset_password_email_sent_batched": "Se enviaron correos electrónicos de restablecimiento de contraseña ({count}).",
    "contact_event_rosetta_chat": "#ChatDeRosetta",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nombre DP: {driverName} , \nNombre de la estación: {stationName} , \nNúmero de rastreo: {trackingId} y \nID de pedido: {orderId}\nUsar ID de la sesión del caso: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Se abrió el widget de mapa para número de rastreo {trackingId}",
    "contact_event_sds_package_repick": "El afiliado inició el proceso para volver a recolectar para el número de seguimiento {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} se envió a la Biblioteca de videos del cliente {customer_id}.",
    "contact_event_shipping_address_changed": "Se cambió la dirección en el pedido {new_order_id}.",
    "contact_event_sim_ticket_created": "Ticket de SIM creado",
    "contact_event_subscription_astro_workflow_launch": "CS lanzó un flujo de trabajo de cancelación de Astro para la {subscriptionType} suscripción {subscriptionId}.",
    "contact_event_subscription_auto_renew_off": "CS desactivó la renovación automática para la {subscriptionType} suscripción {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS canceló la {subscriptionType} suscripción {subscriptionId} con reembolso completo.",
    "contact_event_subscription_cancel_no_refund": "CS canceló la {subscriptionType} suscripción {subscriptionId} sin reembolso.",
    "contact_event_subscription_cancel_partial_refund": "CS canceló la {subscriptionType} suscripción {subscriptionId} con reembolso parcial.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "La suscripción {plan_type} se configuró para ser renovada como {new_plan_name}, en lugar de {old_plan_name}, al final del período actual.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Configuración actualizada del plan de refinanciación de Prime ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Se ha invocado el asistente de resolución de problemas para el pedido digital con ORDERID {digitalOrderID}.",
    "contact_event_unlock_dash_order": "Los siguientes ASIN del dispositivo {dsn} fueron desbloqueados: {asins}.",
    "contact_event_update_locking_status": "El estado de bloqueo del dispositivo Oft {dsn} se actualizó a: {newLockingStatus}",
    "contact_event_verify_email": "Se envió un correo electrónico de verificación al cliente.",
    "contact_event_weekend_delivery_preference_changed": "Preferencia de entrega en fin de semana actualizada"
}