export const zh_CN = {
    "ACCESS_RESTRICTED_CONTENT": "访问受限内容",
    "ACTION": "操作",
    "ADD": "添加",
    "ADDITIONAL_CONTACT_INFO_TITLE": "其他联系信息",
    "ADD_ANNOTATION_FAILURE": "添加注释失败。",
    "ADD_ANNOTATION_INVALID": "无效的注释。请确保注释中没有受限数据。",
    "ADD_ANNOTATION_PLACEHOLDER": "新注释",
    "ADD_ANNOTATION_SUCCESS": "已成功添加注释。",
    "AGENT": "代理",
    "AGENT_ANNOTATION_BADGE": "代理",
    "AGENT_SELECTED_ISSUE": "代理选择的问题",
    "AGENT_SELECTED_ISSUE_COPIED": "已复制代理选择的问题。",
    "AMAZON_CONNECT_CALL_INFO": "亚马逊 Connect 呼叫信息",
    "AMAZON_CONNECT_CONTACT_ID": "亚马逊 Connect 联系人 ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "已复制亚马逊 Connect 联系人 ID",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "亚马逊物流",
    "ANNOTATED_BY": "由 {agentLogin} 注释。",
    "ANNOTATIONS": "注释",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "注释不应包含买家 PII 数据，如电话号码和电子邮件地址。我们的自动化系统试图强制执行此项，但有时不知道 PII 和其他 ID 之间的区别。提供需要覆盖的原因。",
    "ANNOTATION_WARNING": "根据隐私法规，注释可能需要经客户审查。请勿在注释字段中包含个人信息或意见。",
    "ATTACHMENTS_RESTRICTED": "附件受限。",
    "ATTACHMENTS_TITLE": "附件",
    "ATTACHMENT_DELETION_FAILED": "附件删除失败，请稍后重试。",
    "ATTACHMENT_DELETION_SUCCESSFUL": "附件删除成功。",
    "AUTHENTICATING": "已开始身份验证。这可能会打开一个新窗口。",
    "AUTHENTICATION_FAILURE": "身份验证时出现问题。",
    "AUTHENTICATION_MESSAGE": "获取新凭证。此窗口将在几秒钟后关闭。",
    "AUTO_ANNOTATION_BADGE": "自动",
    "BACK": "返回",
    "BCC_EMAIL_ADDRESS": "密件抄送",
    "BLURBS_USED_TITLE": "已用模板",
    "CALL_LEG": "呼叫法定编号",
    "CALL_LEG_ID_COPIED": "呼叫段 ID 已复制。",
    "CALL_RESTRICTED": "通话内容受限。",
    "CALL_RETENTION_DELETED": "录音已过其保留期限，并且已被删除。",
    "CALL_TITLE": "联系亚马逊客户服务",
    "CANCEL": "取消",
    "CC_EMAIL_ADDRESS": "抄送",
    "CF_LARGE_NUMBER_ALERT": "该客户有大量的联系人，但并非所有联系人都已加载。从结束日期开始，联系人始终按照 “最近到最早” 的顺序加载。更改结束日期以从其他时间点开始加载。",
    "CHANNELS": "频道",
    "CHAT_AGENT_CONNECTED": "{name} 已接受聊天。",
    "CHAT_CONTACT": "聊天联系人。",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} 与网络的连接已断开。",
    "CHAT_DISCONNECT_GENERIC": "{name} 已断开连接。",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} 与网络的连接已断开。",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} 与网络的连接已断开。",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} 已离开对话。",
    "CHAT_PARKED": "此聊天已停放",
    "CHAT_PARKED_NOTES": "公园笔记",
    "CHAT_PARTICIPANT_OFF_HOLD": "已不再暂管 {name}。",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} 已暂停。",
    "CHAT_RESTRICTED": "聊天内容受限。",
    "CHAT_TITLE": "与亚马逊客户服务聊天",
    "CHAT_TRANSFER_NOTES": "传输注释",
    "CHILD_WORK_REQUESTS": "已从此联系中创建工作请求",
    "CHOOSE_OPTIONS": "选择选项",
    "CLOSE": "关闭",
    "CLOSE_ALERT": "关闭提醒",
    "CLOSE_MODAL_BUTTON_TEXT": "关闭",
    "COLLAPSED": "已折叠",
    "CONFIRM": "确认",
    "CONTACTS": "联系人",
    "CONTACT_COLLAPSED": "联系人详情已折叠。",
    "CONTACT_CREATION_FAILURE": "创建新联系人失败",
    "CONTACT_EXPANDED": "联系人详情已展开。",
    "CONTACT_HISTORY": "联系历史记录",
    "CONTACT_ID": "联系人 ID",
    "CONTACT_ID_COPIED": "已复制联系人 ID。",
    "CONTACT_MEDIUM_DEFAULT_TAB": "联系人",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "升级",
    "CONTACT_REASSIGNED_TO_AGENT": "已将通话重新分配给坐席。",
    "CONTACT_SELECT_CHECKBOX": "用以选择已锁定联系的勾选框",
    "CONTACT_STATUS_ESCALATION_BADGE": "升级",
    "CONTACT_TICKET": "联系 TT",
    "CONTACT_TICKET_CONTENT": "TT 内容",
    "CONTACT_TICKET_CREATE": "创建联系 TT",
    "CONTACT_TICKET_CREATION_FAILURE": "创建联系 TT 失败",
    "CONTACT_TICKET_CREATION_SUCCESS": "已创建联系 TT",
    "CONTACT_TICKET_RESOLVER_GROUP": "解决者组",
    "CONTACT_TICKET_SEVERITY": "TT 严重程度",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM 文件夹 ID",
    "CONTACT_TICKET_SUBJECT": "TT 主题",
    "CONTACT_TIMELINE": "联系时间表",
    "CONTACT_TYPE": "联系人类型",
    "CONTACT_WAS_TRANSFERRED": "联系人已转移。",
    "CONTENT": "内容",
    "CONTENT_RESTRICTED": "内容受限制。",
    "COPY_AGENT_SELECTED_ISSUE": "复制代理所选择的问题：{value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "复制亚马逊 Connect 联系人 ID",
    "COPY_CALL_LEG_ID": "复制呼叫段 ID：{value}",
    "COPY_CONTACT_ID": "复制联系人 ID：{value}",
    "COPY_CUSTOMER_ID": "复制买家编号",
    "COPY_CUSTOMER_SELECTED_ISSUE": "复制客户选择的问题：{value}",
    "COPY_FAILURE": "无法复制。",
    "COPY_ID": "复制 ID",
    "COPY_LINK": "复制图片上传链接",
    "COPY_ORDER_ID": "复制订单编号：{value}。",
    "CREATE_CASE": "创建案例",
    "CREATE_FOLLOW_UP": "创建跟进",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "已成功创建跟进，但无法添加相应的注释。重试手动添加注释。",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "指定客户的首选联系方式。",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "无法创建具有过去截止日期和时间的跟进。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "指定跟进操作到期的日期和时间。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "时区",
    "CREATE_FOLLOW_UP_FAILURE": "无法创建跟进。",
    "CREATE_FOLLOW_UP_INVALID": "跟进无效。请确保没有受限的数据。",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "输入跟进操作。",
    "CREATE_FOLLOW_UP_SUCCESS": "已成功创建跟进。",
    "CREATE_WORK_REQUEST": "创建工作请求",
    "CROSS_MARKETPLACE_QUEUES": "跨商城队列",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "无法转移联系人，因为已解决。",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "因通话处于无效状态，操作失败。",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "操作失败，因为找不到联系人",
    "CSALT_ERROR_INTERNAL_ERROR": "无法锁定联系人",
    "CSALT_ERROR_INVALID_ARGUMENTS": "由于参数无效，无法锁定联系人",
    "CSALT_ERROR_INVALID_INPUT": "因输入无效，操作失败。",
    "CSALT_ERROR_INVALID_QUEUE": "无法转移联系人，因为选定的队列无效。",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "操作失败，因为联系人被锁定到另一个坐席。",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "由于依赖性错误，无法锁定联系人",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "无法转移联系人，因为在后端服务中找不到队列。",
    "CSALT_ERROR_UNKNOWN": "因出现意外错误，操作失败。",
    "CURRENT_CONTACT": "当前联系人",
    "CUSTOMER": "买家",
    "CUSTOMER_FOLLOWUPS": "为买家提供跟进",
    "CUSTOMER_FOLLOWUPS_FAILURE": "无法为买家获取跟进信息",
    "CUSTOMER_ID_COPIED": "已复制买家编号",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "客户内容受限。（无联系人锁定。）",
    "CUSTOMER_SELECTED_ISSUE": "客户所选择的问题",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "已复制客户选择的问题。",
    "CUSTOMER_TIMEZONE": "以买家所在时区显示时间戳",
    "DATE": "日期",
    "DATE_PICKER_NEXT_MONTH": "下个月",
    "DATE_PICKER_PREVIOUS_MONTH": "上月",
    "DATE_PICKER_TODAY": "今天",
    "DECODING_AUDIO": "正在解码音频",
    "DELETE_ATTACHMENTS": "删除联系人附件",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "删除附件",
    "DELIVERY_STATUS": "配送状态",
    "DISABLE_CONTENT_FILTER": "禁用内容筛选器",
    "DOWNLOAD": "下载",
    "DURATION": "持续时间",
    "EMAIL": "电子邮件",
    "EMAIL_ADDRESS_NOT_ALLOWED": "收件人电子邮件地址不支持。",
    "EMAIL_BODY": "电子邮件正文",
    "EMAIL_CONTACT": "电子邮件联系人。",
    "EMAIL_HEADER": "标题",
    "EMAIL_QUEUE_TITLE": "电子邮件队列",
    "EMAIL_RESTRICTED": "电子邮件内容受限。",
    "EMAIL_SENDING_FAILURE": "发送出站电子邮件失败。",
    "EMAIL_SUBJECT": "电子邮件主题",
    "EMAIL_TITLE": "向亚马逊客户服务发送电子邮件",
    "END": "结束",
    "END_DATE": "结束日期",
    "ERROR": "错误",
    "ERROR_FAILED_TO_LOAD_DATA": "加载数据失败。",
    "ESCALATION_CONTACT_HANDLING": "处理上报联系人",
    "ESCALATION_TAB_LEAD_LOGIN": "引导登录：{agentLogin}",
    "EXPANDED": "已展开",
    "FAILED_TO_CREATE_WORK_REQUEST": "无法创建工作请求",
    "FAILED_TO_FETCH_SYSKA": "无法获取 SYSKA 提示",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "无法将通话重新分配给坐席。",
    "FETCHED_NUMBER_CONTACTS": "已获取的 {number} 联系人。",
    "FILTER": "筛选条件",
    "FINDER": "查找器",
    "FOLLOW_UPS_COLLAPSED": "跟进已折叠",
    "FOLLOW_UPS_EXPANDED": "跟进已展开",
    "FOLLOW_UP_ALERT": "有 {agentLogin} 创建的 {dueDate} 到期的跟进",
    "FOLLOW_UP_CONTACT_METHOD": "联系方式",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "联系方式受限。",
    "FOLLOW_UP_DUE": "跟进到期",
    "FOLLOW_UP_DUE_DATE": "到期日期",
    "FOLLOW_UP_NOTE": "注意",
    "FOLLOW_UP_NO_CONTACT_METHOD": "没有联系方式",
    "FOLLOW_UP_NO_NOTE": "注意为空",
    "FOLLOW_UP_NO_TICKET": "无票据",
    "FOLLOW_UP_OWNER": "所有者",
    "FOLLOW_UP_STATUS": "状态",
    "FOLLOW_UP_STATUS_OPEN": "打开",
    "FOLLOW_UP_STATUS_RESOLVED": "已解决",
    "FOLLOW_UP_TICKET": "票据",
    "FOLLOW_UP_TITLE": "跟进",
    "FROM": "自",
    "GENERATE_LINK": "生成链接",
    "HAS_OVERRIDE_TRY_AGAIN": "存在筛选器覆盖。请重试。",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "与买家共享的图片附件链接",
    "INPUT_CHARACTERS_REMAINING": "最多 {char_max} 个字符（剩余 {char_remain} 个）",
    "INTERNAL_ERROR": "内部错误",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "出现内部错误。如果错误仍然存在，请向我们开一个 TT 解释问题。",
    "INVALID_CONTACT": "无效的联系人。",
    "INVALID_DATA_MATCH": "系统对数据进行了无效的分类。",
    "INVALID_TIMESTAMP": "时间戳无效。",
    "IN_MARKETPLACE_QUEUES": "在商城队列中",
    "ISSUE": "问题",
    "ISSUE_CODE": "问题代码",
    "ISSUE_CODE_ALREADY_ADDRESSED": "已在上一次联系中处理并解决",
    "ISSUE_CODE_DESCRIPTION": "选择将选定联系设为重复的原因",
    "ISSUE_CODE_EXACTLY_SAME": "与上一次联系完全相同",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "已由另一位聊天客服专员处理并解决",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "已由另一位电子邮件客服专员处理并解决",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "已由另一位电话客服专员处理并解决",
    "ITEMS_CONTACTED_ABOUT": "已联系的项",
    "I_UNDERSTAND": "我已了解",
    "KNOWN_ISSUES": "已知问题",
    "LEARN_MORE": "了解详情",
    "LINK_COPIED": "已复制链接",
    "LOADING": "加载数据。",
    "LOADING_PREVIEW_TEXT": "正在加载预览",
    "LOADING_RECORDING": "正在加载录音",
    "LOAD_MORE": "加载更多",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "加载更多注释。",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "为此客户加载更多联系人。",
    "LOCKED": "已锁定",
    "LOCKED_BY": "锁定方式",
    "LOCK_CONTACT": "锁定",
    "LOCK_CONTACT_NOT_AUTHORIZED": "无法锁定联系人，用户未获得授权",
    "LOCK_CONTACT_SUCCESSFUL": "联系人已锁定至",
    "MISSING_ORIGINAL_CONTACT_ID": "请输入有效的原联系 ID",
    "MODAL_NO_BUTTON_TEXT": "否",
    "MODAL_YES_BUTTON_TEXT": "是",
    "NEXT": "下一页",
    "NORMAL": "正常",
    "NO_AGENT_SELECTED_ISSUE": "无代理选择的问题。",
    "NO_ANNOTATED_BY": "由未知用户注释。",
    "NO_ANNOTATIONS": "无可用的注释。",
    "NO_ATTACHMENTS": "没有附件。",
    "NO_CHAT": "聊天为空。",
    "NO_CHILD_WORK_REQUESTS": "没有从此联系中创建任何工作请求",
    "NO_CONTACT": "无可用联系人",
    "NO_CONTACTS_SELECTED": "请选择联系人以将其邮件作为垃圾邮件处理",
    "NO_CONTENT": "无内容",
    "NO_CUSTOMER_SELECTED_ISSUE": "无客户所选择的问题。",
    "NO_DELIVERY_STATUS": "无配送状态",
    "NO_EMAIL_BODY": "电子邮件为空。",
    "NO_EMAIL_HEADER": "无电子邮件标题。",
    "NO_EMAIL_QUEUE": "没有与此联系人关联的电子邮件队列。",
    "NO_ITEMS_CONTACTED_ABOUT": "未联系任何项。",
    "NO_RESULTS": "未找到任何结果。",
    "NO_SCREEN_POP": "无屏幕弹出窗口信息",
    "NO_SENT_FROM": "无“发送自”地址。",
    "NO_SENT_TO": "无“发送至”地址",
    "NO_SUBJECT": "主题行为空。",
    "NO_SYSKA_ALERTS": "无 SYSKA 提示",
    "NO_USED_BLURBS": "此联系人未使用模板。",
    "NO_VIEW": "没有联系人或客户可供查看。",
    "NO_WORK_CATEGORY": "没有与此联系人关联的工作类别。",
    "NUMBER_OF_CONTACTS": "加载的联系人",
    "OLDEST_LOADED_CONTACT": "最早加载的联系人",
    "OPEN": "未完成",
    "OPENS_IN_A_NEW_TAB": "在新标签页中打开",
    "ORDER_ID_COPIED": "订单编号已复制。",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "输入原联系 ID",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "用于输入原联系 ID 的文本框",
    "OTHER": "其他",
    "OUTBOUND_EMAIL_BCC": "且密件抄送 -",
    "OUTBOUND_EMAIL_CC": "同时抄送 -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "通知内容已折叠。",
    "OUTBOUND_NOTIFICATION_EXPANDED": "通知内容已展开。",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "已达到最大结果数。请缩小时间范围并重试。",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "当前流程需要 PII 内容。",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "与电话号码、电子邮件地址或其他 PII 无效的匹配。",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "与信用卡或 IBAN 无效的匹配。",
    "OVERRIDE_REASON_PROMPT": "注释不应包含买家 PII 数据，如电话号码和电子邮件地址。我们的自动化系统试图强制执行此项，但有时不知道 PII 和其他 ID 之间的区别。提供需要覆盖的原因。",
    "PAPYRUS_SESSION_EXPLANATION": "请指定您的 Papyrus 会话类型，以便我们可以应用正确的安全限制并根据您的需求优化 UX。",
    "PAPYRUS_SESSION_TYPE": "Papyrus 会话类型",
    "PAUSE": "暂停",
    "PHONE_CONTACT": "电话联系人。",
    "PLAY": "播放",
    "PLAYBACK_SPEED": "播放速度",
    "POSTAL_MAIL_TITLE": "亚马逊客户服务邮政邮件",
    "PREPARING_RECORDING": "准备记录",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "无法为联系人生成预览",
    "PREVIOUS": "上一个",
    "PRE_TRANSLATED_REPLY_EMAIL": "预翻译的回复电子邮件",
    "PRE_TRANSLATION_REPLY_TEXT": "客服专员输入的未经 Rosetta 翻译的回复",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "处理操作",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "按重复处理",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "作为垃圾邮件处理",
    "PROCESS_CONTACT_AS_DUPLICATE": "将选定联系按重复解决",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "您确定要将选定联系作为垃圾信息处理吗？",
    "PROCESS_DUPLICATE_FAILURE": "未能将选定联系按重复解决",
    "PROCESS_DUPLICATE_SUCCESS": "已成功将选定联系按重复解决",
    "PROCESS_SPAM_FAILURE": "未能将选定的联系人转移到垃圾邮件",
    "PROCESS_SPAM_SUCCESS": "已成功将选定的联系人转移到垃圾邮件",
    "PROVIDE_ADDITIONAL_INFORMATION": "提供与此联系人相关的其他信息",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "从联系人中清除敏感信息：",
    "PURGE_ADD_WORD_BUTTON_TEXT": "将留言添加到清除列表",
    "PURGE_BUTTON_TEXT": "清除",
    "PURGE_CONFIRMATION_CHECK": "我已验证以上字段，并确认文本确实包含敏感信息。",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "我确认附件确实包含买家敏感信息。",
    "PURGE_CONFIRMATION_UNCHECKED": "请确认勾选框。",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "无法从联系人中清除",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "成功从联系人中清除信息",
    "PURGE_INVALID_REASON": "{invalid_purge_selection}不是清除信息的正当理由",
    "PURGE_REASON_FINANCIAL_INFO": "财务信息",
    "PURGE_REASON_OTHER": "其他敏感信息",
    "PURGE_REASON_PII": "个人识别信息",
    "PURGE_REASON_PLACEHOLDER": "输入清除原因",
    "PURGE_REASON_TEXT": "清除的原因",
    "PURGE_REMOVE_LABEL": "删除",
    "PURGE_SELECTOR_PLACEHOLDER": "选择清除敏感信息的原因",
    "PURGE_SENSITIVE_INFO": "清除敏感信息",
    "PURGE_WORD_NOT_FOUND": "在联系人文本中找不到留言",
    "PURPOSE_AUDITING": "质量控制审计",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "后台工作流程",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "这是获取后台工作流程联系人数据的会话。例如，要在 TT 或 SIM 中添加的附件。",
    "PURPOSE_COACHING": "训练联系人审查",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "合规性调查",
    "PURPOSE_CURATION": "联络策展",
    "PURPOSE_CURATION_DESCRIPTION": "这是 C2CS/VoC 联络策展会议。",
    "PURPOSE_DESCRIPTION_AUDITING": "这是审计联系人和评估其质量的会议。",
    "PURPOSE_DESCRIPTION_COACHING": "这是审查您作为其绩效门户指导的代理联系人的会话。",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "这是 Suspicious Activity Monitoring 调查会议。",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "这是处理升级联系人的会话。这允许访问任何客户的联系人数据。",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "这是实时联系人处理的会话。",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "此目的是从联系人中删除敏感信息",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "这是审查机器学习模型准确性的会话。",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "这是审查您自己过去的联系人的会话。",
    "PURPOSE_DESCRIPTION_UNKNOWN": "这是默认的通用会话。",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "处理升级联系人",
    "PURPOSE_PURGE_SENSITIVE_INFO": "清除敏感信息",
    "REASON_FOR_PURGE": "清除原因",
    "REASON_FOR_PURGE_DESCRIPTION": "清除附件的原因",
    "REASON_FOR_PURGE_MISSING": "请输入删除附件的原因。",
    "REASSIGN_TO_AGENT": "重新分配给坐席",
    "RECORDING": "录音",
    "RELOADING_CONTENT": "重新加载内容。",
    "RELOAD_LIST": "重新加载列表",
    "REPLY_BY": "回复人",
    "REPLY_BY_EMAIL": "通过电子邮件回复",
    "REPLY_BY_PHONE": "通过电话回复",
    "REPLY_RESTRICTED": "回复内容受限。",
    "REQUEST": "请求",
    "REQUEST_ACCESS_INSTRUCTIONS": "请提供需要访问受限内容的原因。（这些请求受到监控，滥用可能会造成严重后果。）",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "请求访问筛选后的内容",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "请求访问受限内容",
    "REQUEST_NOT_GRANTED": "请求未获批准。",
    "REQUEST_OVERRIDE": "请求覆盖",
    "RESEND": "重新发送",
    "RESEND_FAILED": "重新发送电子邮件失败。",
    "RESEND_SUCCESS": "成功重新发送电子邮件。",
    "RESEND_WAIT_WARNING": "您必须等待 {wait_mins} 分钟才能重新发送。",
    "RESEND_WARNING": "您确定要重新发送电子邮件给买家吗？",
    "RESET": "重置",
    "RESOLVED": "已解决",
    "RESOLVED_BY": "解决者",
    "RESTRICTED_DATA_MATCH": "{match} 匹配类型 {type}",
    "RESTRICTED_INFORMATION": "受限信息",
    "RETRY": "重试",
    "ROLE": "角色",
    "SCREEN_POP_DESCRIPTION_TITLE": "描述",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "否",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "是",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "已向代理显示",
    "SCREEN_POP_TITLE": "屏幕弹出窗口信息",
    "SCREEN_POP_TYPE_AUTHENTICATING": "身份验证屏幕弹出窗口已向代理显示，并且已使用。",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "身份验证屏幕弹出窗口已向代理显示。",
    "SCREEN_POP_TYPE_CALLER_ID": "发起人 ID 弹出窗口已向代理显示，并且已使用。",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "发起人 ID 弹出窗口已向代理显示。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "显示了未经身份验证的突起式屏幕弹出窗口，并已使用。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "向代理显示了未经身份验证的突起式屏幕弹出窗口。",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "客户未知；未提供屏幕弹出窗口。",
    "SCREEN_POP_TYPE_OTHER": "其他",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "否",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "是",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "由代理使用",
    "SDS_TOOLS": "配送和交货支持工具",
    "SEARCH": "搜索",
    "SELECTED_ROLE": "选定的角色",
    "SELECTED_VIEW": "所选视图",
    "SELECT_CHANNELS": "选择频道",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "选择用于将选定联系标记为重复的问题代码",
    "SELECT_TRANSFER_DESTINATION": "选择转移目的地",
    "SELECT_WORK_REQUEST_QUEUE": "选择工作请求队列",
    "SENDER_EMAIL_ADDRESS": "发件人",
    "SENDING_EMAIL": "正在发送电子邮件",
    "SEND_EMAIL": "发送电子邮件",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "您确定要将图片上传链接发送给买家吗？",
    "SEND_OUTBOUND_EMAIL": "发送出站电子邮件",
    "SEND_OUTBOUND_EMAIL_WARNING": "您确定要发送出站电子邮件至",
    "SENT_FROM": "发送自",
    "SENT_OUTBOUND_EMAIL": "已发送出站电子邮件。已创建新联系人",
    "SENT_TO": "发送至",
    "SHIPPER_PORTAL_BUTTON_TEXT": "承运人门户",
    "SKILL": "技能",
    "SKIP_SILENCES": "跳过静音",
    "SMS": "SMS",
    "SMS_LINK": "短信链接",
    "SOURCE_TEXT": "源文本",
    "SP_BOTTOM": "底部",
    "SP_POSITION": "拆分面板位置",
    "SP_PREFERENCES": "拆分面板首选项",
    "SP_RESIZE": "调整拆分面板大小",
    "SP_SIDE": "侧面",
    "START": "开始",
    "STATE_TRANSITIONS": "状态转换",
    "STATUS": "状态",
    "SUBJECT": "主题",
    "SYSKA_ALERTS": "SYSKA 提示",
    "TEXT_TO_PURGE": "要清除的文本",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "必须启用第三方 Cookie。",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "TT 请求时不允许使用电话号码和电子邮件地址等买家 PII 数据。我们的自动化系统试图强制执行此项，但有时不知道 PII 和其他 ID 之间的区别。提供需要覆盖的原因。",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "超时：未能加载记录",
    "TIME_TEXT_FORMAT_CONSTRAINT": "使用 24 小时格式。",
    "TO": "截止时间",
    "TO_EMAIL_ADDRESS": "至",
    "TRANSFER": "转接",
    "TRANSFERRED_CHAT_TITLE": "已转接与亚马逊客户服务的聊天",
    "TRANSFERRING": "转移中...",
    "TRANSFER_CONTACT": "转移联系人",
    "TRANSFER_CONTACT_COMMENTS": "可选注释",
    "TRANSFER_FAILED": "无法转移",
    "TRANSFER_FOLLOW_UP": "转接跟进",
    "TRANSFER_FOLLOW_UP_FAILED": "未能转接跟进。",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "由于新所有者登录无效，无法转接跟进。",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "您没有权限转接此跟进。",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "输入新所有者的登录名。示例：{example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "将以上跟进转接至：",
    "TRANSFER_FOLLOW_UP_SUCCESS": "已成功转接跟进。",
    "TRANSLATED_TEXT": "翻译文本",
    "TRANSLATION": "翻译",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} 不是有效的队列名称。",
    "TYPE_AGENT_LOGIN": "键入坐席登录",
    "TYPE_EMAIL_ADDRESS": "输入电子邮件地址",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "输入多个电子邮件地址，并以逗号分隔",
    "TYPE_QUEUE_NAME": "键入队列名称",
    "UNKNOWN_CONTACT_TYPE": "未知联系人类型。",
    "UNLOCKED": "已解锁",
    "UNRESOLVED": "未解决",
    "URGENT_SYSKA_ALERTS": "紧急 SYSKA 提示",
    "USE": "使用",
    "USER_TIMEZONE": "以您的当地时区显示时间戳",
    "USE_CONTACT_FINDER": "使用联系人查找器",
    "VIEW": "查看",
    "WARNING": "警告",
    "WELCOME_MESSAGE": "欢迎来到 Papyrus 静态网站！",
    "WHAT_IS_PAPYRUS": "什么是 Papyrus？",
    "WITH_THE_ANNOTATION": "带注释",
    "WORK_CATEGORY_TITLE": "工作类别",
    "WORK_REQUEST": "工作请求",
    "WORK_REQUEST_CONTENT": "工作请求内容",
    "WORK_REQUEST_CREATED": "已创建工作请求",
    "WORK_REQUEST_FOR_CONTACT": "联系人工作请求",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "创建工作请求时不允许使用电话号码和电子邮件地址等买家 PII 数据。我们的自动化系统试图强制执行此项，但有时不知道 PII 和其他 ID 之间的区别。提供需要覆盖的原因。",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "您即将转移至队列"
}