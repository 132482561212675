export const ar_AE = {
    "ABANDONED": "تم الإلغاء بواسطة",
    "ABANDONED_BY_AGENT": "تم الإلغاء بواسطة {agent}",
    "ACCESS_RESTRICTED_CONTENT": "محتوى مقيد الوصول إليه",
    "ACTION": "الإجراء",
    "ADD": "إضافة",
    "ADDITIONAL_CONTACT_INFO_TITLE": "معلومات الاتصال الإضافية",
    "ADD_ANNOTATION_FAILURE": "فشلت إضافة ملاحظة.",
    "ADD_ANNOTATION_INVALID": "ملاحظة غير صالحة. يرجى التأكد من عدم وجود بيانات مقيدة في الملاحظة.",
    "ADD_ANNOTATION_PLACEHOLDER": "ملاحظة جديدة",
    "ADD_ANNOTATION_SUCCESS": "تمت إضافة الملاحظة بنجاح.",
    "AGENT": "الوكيل",
    "AGENT_ANNOTATION_BADGE": "الوكيل",
    "AGENT_SELECTED_ISSUE": "حدد الوكيل المشكلة",
    "AGENT_SELECTED_ISSUE_COPIED": "تم نسخ المشكلة التي حددها الوكيل.",
    "AMAZON_CONNECT_CALL_INFO": "معلومات اتصال أمازون Connect",
    "AMAZON_CONNECT_CONTACT_ID": "معرف جهة اتصال أمازون Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "تم نسخ معرف جهة اتصال أمازون Connect",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "الخدمات اللوجستية في أمازون",
    "ANALYZED": "تم التحليل بواسطة",
    "ANALYZED_BY_AGENT": "تم تحليلها بواسطة {agent}",
    "ANNOTATED_BY": "تمت كتابة الملاحظات بواسطة {agentLogin}.",
    "ANNOTATIONS": "ملاحظات",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "ليس من المفترض أن تحتوي الملاحظات على بيانات PII الخاصة بالعميل مثل أرقام الهواتف وعناوين البريد الإلكتروني. يحاول نظامنا الآلي فرض أنه حتى الآن في بعض الأحيان لا يُعرف الفرق بين PII وغيرها من المعرفات. قم بتقديم سبب الحاجة إلى تجاوز.",
    "ANNOTATION_WARNING": "قد تخضع التعليقات التوضيحية للمراجعة من قبل العملاء بموجب لوائح الخصوصية. لا تقم بتضمين معلومات شخصية أو آراء في حقل التعليقات التوضيحية.",
    "ATTACHMENTS_RESTRICTED": "المرفقات مقيدة.",
    "ATTACHMENTS_TITLE": "المرفقات",
    "ATTACHMENT_DELETION_FAILED": "فشل حذف المرفقات، يرجى المحاولة مرة أخرى لاحقًا.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "تم حذف المرفقات بنجاح.",
    "AUTHENTICATING": "بدأت المصادقة. قد يفتح هذا نافذة جديدة.",
    "AUTHENTICATION_FAILURE": "كانت هناك مشكلة في المصادقة.",
    "AUTHENTICATION_MESSAGE": "جارٍ إحضار بيانات اعتماد جديدة. هذه النافذة ستُغلق نفسها خلال بضع ثوانٍ.",
    "AUTO_ANNOTATION_BADGE": "تلقائي",
    "BACK": "العودة",
    "BCC_EMAIL_ADDRESS": "نسخة مماثلة مخفية",
    "BLURBS_USED_TITLE": "نماذج البريد الإلكتروني المستخدمة",
    "CALL_LEG": "معرف ساق الاتصال",
    "CALL_LEG_ID_COPIED": "تم نسخ رقم مرحلة المكالمة.",
    "CALL_RESTRICTED": "محتوى المكالمة مقيد.",
    "CALL_RETENTION_DELETED": "تجاوز التسجيل المدة الزمنية للاحتفاظ به وتم حذفه.",
    "CALL_TITLE": "اتصل بخدمة عملاء أمازون",
    "CANCEL": "إلغاء",
    "CC_EMAIL_ADDRESS": "نسخة إلى",
    "CF_LARGE_NUMBER_ALERT": "لدى هذا العميل عدد كبير جدًا من جهات الاتصال لم يتم تحميلها جميعًا. يتم تحميل جهات الاتصال دائمًا بترتيب «الحديث إلى الأقدم» بدءًا من تاريخ الانتهاء. غيّر تاريخ الانتهاء لبدء التحميل من نقطة زمنية مختلفة.",
    "CHANNELS": "القنوات",
    "CHAT_AGENT_CONNECTED": "{name} قبل المحادثة.",
    "CHAT_CONTACT": "العميل الذي يتواصل عبر المحادثة.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "فقد {name} الاتصال بالشبكة.",
    "CHAT_DISCONNECT_GENERIC": "لم يعد {name} متصلاً.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "فقد {name} الاتصال بالشبكة.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "فقد {name} الاتصال بالشبكة.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} غادر المحادثة.",
    "CHAT_PARKED": "هذه المحادثة كانت متوقفة",
    "CHAT_PARKED_NOTES": "توقف الملاحظات",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} قيد التعليق.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} في الانتظار.",
    "CHAT_RESTRICTED": "محتوى المحادثة مقيد.",
    "CHAT_TITLE": "التحدث مع خدمة عملاء أمازون",
    "CHAT_TRANSFER_NOTES": "ملاحظات النقل",
    "CHILD_WORK_REQUESTS": "طلبات العمل التي تم إنشاؤها من جهة الاتصال هذه",
    "CHOOSE_OPTIONS": "اختَر الخيارات",
    "CLOSE": "إغلاق",
    "CLOSE_ALERT": "إغلاق التنبيه",
    "CLOSE_MODAL_BUTTON_TEXT": "إغلاق",
    "COLLAPSED": "تم التقليص",
    "CONFIRM": "تأكيد",
    "CONTACTS": "جهات الاتصال",
    "CONTACT_COLLAPSED": "تم تقليص تفاصيل جهة الاتصال.",
    "CONTACT_CREATION_FAILURE": "تعذر إنشاء جهة اتصال جديدة",
    "CONTACT_EXPANDED": "تم تمديد تفاصيل جهة الاتصال.",
    "CONTACT_HISTORY": "سجلّ الاتصال",
    "CONTACT_ID": "رقم جهة الاتصال",
    "CONTACT_ID_COPIED": "تم نسخ رقم جهة الاتصال.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "جهة اتصال",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "تصعيدات",
    "CONTACT_REASSIGNED_TO_AGENT": "تمت إعادة تعيين جهة الاتصال إلى الوكيل.",
    "CONTACT_SELECT_CHECKBOX": "خانة اختيار لتحديد جهة اتصال مقيدة",
    "CONTACT_STATUS_ESCALATION_BADGE": "التصعيد",
    "CONTACT_TICKET": "جهة اتصال بطاقة التبليغ عن المشكلة",
    "CONTACT_TICKET_CONTENT": "محتوى بطاقة التبليغ عن المشكلة",
    "CONTACT_TICKET_CREATE": "إنشاء بطاقة تبليغ عن مشكلة",
    "CONTACT_TICKET_CREATION_FAILURE": "فشل إنشاء بطاقة التبليغ عن المشكلة",
    "CONTACT_TICKET_CREATION_SUCCESS": "تم إنشاء بطاقة التبليغ عن المشكلة",
    "CONTACT_TICKET_RESOLVER_GROUP": "مجموعة المحلل",
    "CONTACT_TICKET_SEVERITY": "مستوى خطورة بطاقة التبليغ عن المشكلة",
    "CONTACT_TICKET_SIM_FOLDER_ID": "معرف مجلد SIM",
    "CONTACT_TICKET_SUBJECT": "موضوع بطاقة التبليغ عن المشكلة",
    "CONTACT_TIMELINE": "الجدول الزمني لجهة الاتصال",
    "CONTACT_TYPE": "نوع جهة الاتصال",
    "CONTACT_WAS_TRANSFERRED": "تم نقل جهة الاتصال.",
    "CONTENT": "المحتوى",
    "CONTENT_RESTRICTED": "المحتوى مُقيَّد.",
    "COPY_AGENT_SELECTED_ISSUE": "نسخ المشكلة التي حددها العميل: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "نسخ معرف جهة اتصال أمازون Connect",
    "COPY_CALL_LEG_ID": "نسخ رقم مرحلة المكالمة: {value}",
    "COPY_CONTACT_ID": "نسخ رقم جهة الاتصال: {value}",
    "COPY_CUSTOMER_ID": "نسخ رقم العميل",
    "COPY_CUSTOMER_SELECTED_ISSUE": "نسخ المشكلة المحددة بواسطة العميل: {value}",
    "COPY_FAILURE": "فشل النسخ.",
    "COPY_ID": "نسخ ID",
    "COPY_LINK": "نسخ رابط تحميل الصورة",
    "COPY_ORDER_ID": "نسخ رقم الطلب: {value}.",
    "CREATE_CASE": "إنشاء حالة",
    "CREATE_FOLLOW_UP": "إنشاء متابعة",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "تم إنشاء المتابعة بنجاح، لكن تعذر إضافة الملاحظة المقابلة. أعد محاولة إضافة ملاحظة يدويًا.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "حدد طريقة الاتصال المفضلة للعميل.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "لا يمكن إنشاء متابعة مع تاريخ ووقت استحقاق في الماضي.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "حدد تاريخ ووقت استحقاق إجراء المتابعة.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "المنطقة الزمنية",
    "CREATE_FOLLOW_UP_FAILURE": "فشل في إنشاء المتابعة.",
    "CREATE_FOLLOW_UP_INVALID": "متابعة غير صالحة. يرجى التأكد من عدم وجود بيانات مقيدة.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "أدخل إجراء المتابعة.",
    "CREATE_FOLLOW_UP_SUCCESS": "تم إنشاء متابعة بنجاح.",
    "CREATE_WORK_REQUEST": "إنشاء طلب عمل",
    "CROSS_MARKETPLACE_QUEUES": "قوائم انتظار أسواق البيع المجتازة",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "فشل نقل جهة الاتصال لأنه تم حل مشكلتها بالفعل.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "فشلت العملية لأن جهة الاتصال بحالة غير صالحة.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "فشلت العملية لأنه لم يتم العثور على جهة الاتصال",
    "CSALT_ERROR_INTERNAL_ERROR": "فشل تقييد الوصول إلى جهة الاتصال",
    "CSALT_ERROR_INVALID_ARGUMENTS": "فشل تقييد الوصول إلى جهة اتصال بسبب الوسيطات غير الصالحة",
    "CSALT_ERROR_INVALID_INPUT": "فشلت العملية بسبب المدخلات غير الصالحة.",
    "CSALT_ERROR_INVALID_QUEUE": "فشل نقل جهة الاتصال لأن قائمة الانتظار المحددة غير صالحة.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "فشلت العملية لأنه تم تقييد الوصول لجهة الاتصال بواسطة وكيل مختلف.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "فشل تقييد الوصول إلى جهة الاتصال بسبب خطأ التبعية",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "فشل نقل جهة الاتصال بسبب عدم العثور على قائمة الانتظار في خدمة الواجهة الخلفية.",
    "CSALT_ERROR_UNKNOWN": "فشلت العملية بسبب خطأ غير متوقع.",
    "CURRENT_CONTACT": "جهة الاتصال الحالية",
    "CUSTOMER": "العميل",
    "CUSTOMER_FOLLOWUPS": "متابعة العملاء",
    "CUSTOMER_FOLLOWUPS_FAILURE": "فشل جلب المتابعة للعميل",
    "CUSTOMER_ID_COPIED": "تم نسخ رقم العميل",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "محتوى العميل مقيد. (لا توجد جهات اتصال مقيدة.)",
    "CUSTOMER_SELECTED_ISSUE": "حدد العميل المشكلة",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "تم نسخ المشكلة المحددة بواسطة العميل.",
    "CUSTOMER_TIMEZONE": "الفترات الزمنية الموضحة في المنطقة الزمنية للعميل",
    "DATE": "التاريخ",
    "DATE_PICKER_NEXT_MONTH": "الشهر القادم",
    "DATE_PICKER_PREVIOUS_MONTH": "الشهر السابق",
    "DATE_PICKER_TODAY": "اليوم",
    "DECODING_AUDIO": "فك تشفير الصوت",
    "DELETE_ATTACHMENTS": "حذف مرفقات جهات الاتصال",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "حذف المرفقات",
    "DELIVERY_STATUS": "حالة التوصيل",
    "DISABLE_CONTENT_FILTER": "تعطيل فلتر المحتوى",
    "DOWNLOAD": "تحميل",
    "DURATION": "المدة",
    "EMAIL": "البريد الإلكتروني",
    "EMAIL_ADDRESS_NOT_ALLOWED": "عنوان (عناوين) البريد الإلكتروني للمستلم غير مسموح به.",
    "EMAIL_BODY": "نص الرسالة الإلكترونية",
    "EMAIL_CONTACT": "أرسل رسالة إلكترونية لجهة الاتصال.",
    "EMAIL_HEADER": "العنوان الرئيسي",
    "EMAIL_QUEUE_TITLE": "إرسال قائمة الانتظار بالبريد الإلكتروني",
    "EMAIL_RESTRICTED": "محتوى البريد الإلكتروني مقيد.",
    "EMAIL_SENDING_FAILURE": "تعذر إرسال البريد الإلكتروني الصادر.",
    "EMAIL_SUBJECT": "موضوع البريد الإلكتروني",
    "EMAIL_TITLE": "إرسال رسالة إلكترونية لخدمة عملاء أمازون",
    "END": "إنهاء",
    "END_DATE": "تاريخ الانتهاء",
    "ERROR": "خطأ",
    "ERROR_FAILED_TO_LOAD_DATA": "تعذر تحميل البيانات.",
    "ESCALATION_CONTACT_HANDLING": "جارٍ التعامل مع جهة اتصال التصعيد",
    "ESCALATION_TAB_LEAD_LOGIN": "تسجيل دخول المسؤول: {agentLogin}",
    "EXPANDED": "تم التمديد",
    "FAILED_TO_CREATE_WORK_REQUEST": "فشل إنشاء طلب عمل",
    "FAILED_TO_FETCH_SYSKA": "فشل في إحضار التنبيهات الخاصة بأشياء عليك معرفتها",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "فشلت إعادة تعيين جهة الاتصال إلى الوكيل.",
    "FETCHED_NUMBER_CONTACTS": "تم إحضار {number} جهات الاتصال.",
    "FILTER": "عامل التصفية",
    "FINDER": "المكتشف",
    "FOLLOW_UPS_COLLAPSED": "تم تقليص المتابعات",
    "FOLLOW_UPS_EXPANDED": "تم تمديد المتابعات",
    "FOLLOW_UP_ALERT": "لديه متابعة مستحقة في {dueDate} تم إنشاؤها بواسطة {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "طريقة الاتصال",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "طريقة الاتصال مقيدة.",
    "FOLLOW_UP_DUE": "موعد المتابعة",
    "FOLLOW_UP_DUE_DATE": "تاريخ الاستحقاق",
    "FOLLOW_UP_NOTE": "ملاحظة",
    "FOLLOW_UP_NO_CONTACT_METHOD": "لا توجد طريقة اتصال",
    "FOLLOW_UP_NO_NOTE": "ملاحظة فارغة",
    "FOLLOW_UP_NO_TICKET": "لا توجد ورقة",
    "FOLLOW_UP_OWNER": "المالك",
    "FOLLOW_UP_STATUS": "الحالة",
    "FOLLOW_UP_STATUS_OPEN": "فتح",
    "FOLLOW_UP_STATUS_RESOLVED": "تم الحل",
    "FOLLOW_UP_TICKET": "مذكرة",
    "FOLLOW_UP_TITLE": "المتابعات",
    "FROM": "من",
    "GENERATE_LINK": "إنشاء رابط",
    "HAS_OVERRIDE_TRY_AGAIN": "تجاوز الفلتر موجود. حاول مرة أخرى.",
    "HISTORY": "السجل",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "تمت مشاركة رابط مرفق الصورة مع العميل",
    "INPUT_CHARACTERS_REMAINING": "الحد الأقصى {char_max} رموز ({char_remain} متبقية)",
    "INSERTED": "تم الإدراج بواسطة",
    "INSERTED_BY_AGENT": "تم الإدراج بواسطة {agent}",
    "INTERNAL_ERROR": "خطأ داخلي",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "تمت مصادفة خطأ داخلي. إذا استمر الخطأ، يرجى إرسال مذكرة لنا تشرح المشكلة.",
    "INVALID_CONTACT": "جهة اتصال غير صالحة.",
    "INVALID_DATA_MATCH": "قام النظام بتصنيف غير صالح للبيانات.",
    "INVALID_TIMESTAMP": "الفترة الزمنية غير صالحة.",
    "IN_MARKETPLACE_QUEUES": "في قوائم انتظار أسواق البيع",
    "ISSUE": "المشكلة",
    "ISSUE_CODE": "رمز المشكلة",
    "ISSUE_CODE_ALREADY_ADDRESSED": "تمت معالجتها بالفعل وحلها في جهة اتصال سابقة",
    "ISSUE_CODE_DESCRIPTION": "حدد سبب جعل جهات الاتصال المحددة مكررة",
    "ISSUE_CODE_EXACTLY_SAME": "نفس جهة الاتصال السابقة",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "تم التعامل معها وحلها بواسطة موظف خدمة عملاء آخر عبر المحادثة",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "تم التعامل معها وحلها بواسطة موظف خدمة عملاء آخر عبر البريد إلكتروني",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "تم التعامل معها وحلها بواسطة موظف خدمة عملاء آخر عبر الهاتف",
    "ITEMS_CONTACTED_ABOUT": "السلعة(السلع) التي تم الاتصال بشأنها",
    "I_UNDERSTAND": "أتفهم",
    "KNOWN_ISSUES": "المشكلات المعروفة",
    "LEARN_MORE": "اعرف المزيد",
    "LINK_COPIED": "تم نسخ الرابط",
    "LOADING": "جارٍ تحميل البيانات.",
    "LOADING_PREVIEW_TEXT": "جارٍ تحميل المعاينة",
    "LOADING_RECORDING": "جارٍ تحميل التسجيل",
    "LOAD_MORE": "حمّل المزيد",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "حمّل مزيدًا من الملاحظات.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "قم بتحميل المزيد من جهات الاتصال لهذا العميل.",
    "LOCKED": "مغلق",
    "LOCKED_BY": "تم تقييد الوصول بواسطة",
    "LOCKED_BY_AGENT": "تم تقييد الوصول بواسطة {agent}",
    "LOCK_CONTACT": "تقييد الوصول",
    "LOCK_CONTACT_NOT_AUTHORIZED": "فشل تقييد الوصول إلى جهة الاتصال، المستخدم غير مخوّل بذلك",
    "LOCK_CONTACT_SUCCESSFUL": "تم تقييد الوصول إلى جهة اتصال",
    "MISSING_ORIGINAL_CONTACT_ID": "الرجاء إدخال معرف جهة اتصال أصلية صالحة",
    "MODAL_NO_BUTTON_TEXT": "لا",
    "MODAL_YES_BUTTON_TEXT": "نعم",
    "NEXT": "التالي",
    "NORMAL": "عادي",
    "NO_AGENT_SELECTED_ISSUE": "لا توجد مشكلة محددة بواسطة العميل.",
    "NO_ANNOTATED_BY": "كتب الملاحظات مستخدم غير معروف.",
    "NO_ANNOTATIONS": "لا توجد ملاحظات متاحة.",
    "NO_ATTACHMENTS": "لا توجد مرفقات.",
    "NO_CHAT": "المحادثة فارغة.",
    "NO_CHILD_WORK_REQUESTS": "لم يتم إنشاء طلبات عمل من جهة الاتصال هذه",
    "NO_CONTACT": "لا توجد جهة اتصال متاحة",
    "NO_CONTACTS_SELECTED": "يرجى تحديد جهات الاتصال لمعالجتها كغير مرغوب فيها",
    "NO_CONTENT": "لا يوجد محتوى",
    "NO_CUSTOMER_SELECTED_ISSUE": "لم يحدد أي عميل مشكلة.",
    "NO_DELIVERY_STATUS": "بلا حالة توصيل",
    "NO_EMAIL_BODY": "الرسالة الإلكترونية فارغة.",
    "NO_EMAIL_HEADER": "لا يوجد عنوان للبريد الإلكتروني.",
    "NO_EMAIL_QUEUE": "لا توجد قائمة انتظار بريد إلكتروني مرتبطة بجهة الاتصال هذه.",
    "NO_ITEMS_CONTACTED_ABOUT": "لم يتم الاتصال بخصوص أي سلع.",
    "NO_RESULTS": "لم يتم العثور على أي نتائج.",
    "NO_SCREEN_POP": "لا توجد معلومات نافذة منبثقة",
    "NO_SENT_FROM": "لم يتم إرساله من عنوان.",
    "NO_SENT_TO": "لم يتم الإرسال إلى العنوان",
    "NO_SUBJECT": "سطر الموضوع فارغ.",
    "NO_SYSKA_ALERTS": "بلا تنبيهات خاصة بأشياء عليك معرفتها",
    "NO_USED_BLURBS": "لا توجد نماذج بريد إلكتروني مستخدمة لجهة الاتصال هذه.",
    "NO_VIEW": "لا توجد جهة اتصال أو عميل للعرض.",
    "NO_WORK_CATEGORY": "لا توجد فئة عمل مرتبطة بجهة الاتصال هذه.",
    "NUMBER_OF_CONTACTS": "جهات اتصال تم تحميلها",
    "OLDEST_LOADED_CONTACT": "أقدم جهة اتصال تم تحميلها",
    "OPEN": "فتح",
    "OPENS_IN_A_NEW_TAB": "يتم الفتح في تبويب جديد",
    "ORDER_ID_COPIED": "تم نسخ رقم الطلب.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "أدخل معرف جهة الاتصال الأصلية",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "مربع نص لإدخال معرف جهة الاتصال الأصلية",
    "OTHER": "أخرى",
    "OUTBOUND_EMAIL_BCC": "وإرسال نسخة مماثلة مخفية -",
    "OUTBOUND_EMAIL_CC": "عند إرسال نسخة إلى -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "تم تقليص محتوى الإشعار.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "تم تمديد محتوى الإشعار.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "الحد الأقصى لعدد النتائج التي تم التوصل إليها. يُرجى تقليل نطاق تاريخ والمحاولة مرة أخرى.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "تتطلب العملية الحالية محتوى PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "تطابق غير صالح كرقم هاتف أو عنوان بريد إلكتروني أو PII أخرى.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "تطابق غير صالح كبطاقة ائتمان أو رقم الحساب المصرفي الدولي (IBAN).",
    "OVERRIDE_REASON_PROMPT": "ليس من المفترض أن تحتوي الملاحظات على بيانات PII الخاصة بالعميل مثل أرقام الهواتف وعناوين البريد الإلكتروني. يحاول نظامنا الآلي فرض أنه حتى الآن في بعض الأحيان لا يُعرف الفرق بين PII وغيرها من المعرفات. قم بتقديم سبب الحاجة إلى تجاوز.",
    "PAPYRUS_SESSION_EXPLANATION": "يرجى تحديد نوع جلسة Papyrus الخاص بك حتى نتمكن من تطبيق قيود الأمان الصحيحة وتحسين UX لاحتياجاتك.",
    "PAPYRUS_SESSION_TYPE": "نوع جلسة Papyrus",
    "PAUSE": "إيقاف مؤقت",
    "PHONE_CONTACT": "العميل الذي يتواصل مع خدمة العملاء عبر الهاتف.",
    "PLAY": "عرض",
    "PLAYBACK_SPEED": "سرعة التشغيل",
    "POSTAL_MAIL_TITLE": "البريد العادي مع خدمة عملاء أمازون",
    "PREPARING_RECORDING": "إعداد التسجيل",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "فشل إنشاء معاينة لجهة الاتصال",
    "PREVIOUS": "السابق",
    "PRE_TRANSLATED_REPLY_EMAIL": "رسالة الرد الإلكترونية المترجمة مسبقا",
    "PRE_TRANSLATION_REPLY_TEXT": "رد مكتوب بنسق CSA قبل ترجمة Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "خيارات العملية",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "معالجة كتكرار",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "المعالجة كغير مرغوب فيها",
    "PROCESS_CONTACT_AS_DUPLICATE": "حل جهات الاتصال المحددة كمكررة",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "هل تريد بالتأكيد معالجة جهات الاتصال المحددة كرسائل غير مرغوب بها؟",
    "PROCESS_DUPLICATE_FAILURE": "فشل حل جهات الاتصال المحددة كمكررة",
    "PROCESS_DUPLICATE_SUCCESS": "تم حل جهات الاتصال المحددة كمكررة بنجاح",
    "PROCESS_SPAM_FAILURE": "فشل نقل جهات الاتصال المحددة إلى غير المرغوب فيه",
    "PROCESS_SPAM_SUCCESS": "تم نقل جهات الاتصال المحددة بنجاح إلى غير المرغوب فيه",
    "PROVIDE_ADDITIONAL_INFORMATION": "تقديم معلومات إضافية ذات صلة بجهة الاتصال هذه",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "معلومات حساسة تم حذفها من جهة الاتصال:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "إضافة كلمة إلى قائمة الحذف",
    "PURGE_BUTTON_TEXT": "حذف",
    "PURGE_CONFIRMATION_CHECK": "لقد تحققت من الحقول المذكورة أعلاه، وأؤكد أن النص يحتوي بالفعل على معلومات حساسة.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "أؤكد أن المرفق يحتوي بالفعل على معلومات حساسة للعميل.",
    "PURGE_CONFIRMATION_UNCHECKED": "يرجى تأكيد تحديد صندوق الاختيار.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "فشل الحذف من جهة الاتصال",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "تم حذف المعلومات بنجاح من جهة الاتصال",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} ليس سببًا سليمًا للحذف",
    "PURGE_REASON_FINANCIAL_INFO": "معلومات مالية",
    "PURGE_REASON_OTHER": "معلومات حساسة أخرى",
    "PURGE_REASON_PII": "معلومات تعريف شخصية",
    "PURGE_REASON_PLACEHOLDER": "أدخل سبب الحذف",
    "PURGE_REASON_TEXT": "سبب الحذف",
    "PURGE_REMOVE_LABEL": "إزالة",
    "PURGE_SELECTOR_PLACEHOLDER": "حدد سببًا لحذف المعلومات الحساسة",
    "PURGE_SENSITIVE_INFO": "حذف معلومات حساسة",
    "PURGE_WORD_NOT_FOUND": "لم يتم العثور على الكلمة في نص جهة الاتصال",
    "PURPOSE_AUDITING": "التدقيق لمراقبة الجودة",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "سير عمل المكتب الخلفي",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "هذه هي الجلسة للحصول على بيانات الاتصال الخاصة بسير عمل المكتب الخلفي. على سبيل المثال، المرفقات لتضمينها في التذاكر أو بطاقة SIM.",
    "PURPOSE_COACHING": "مراجعة جهات اتصال التدريب",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "التحقق من الامتثال",
    "PURPOSE_CURATION": "تنسيق الاتصال",
    "PURPOSE_CURATION_DESCRIPTION": "هذه هي الجلسة لتنسيق اتصال C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "هذه هي جلسة تدقيق جهات الاتصال وتقييم جودتها.",
    "PURPOSE_DESCRIPTION_COACHING": "هذه هي الجلسة لمراجعة جهات اتصال الوكلاء الذين كنت لهم مدرب بوابة الأداء.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "هذه هي جلسة التحقيقات الخاصة بمراقبة الأنشطة المشبوهة.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "هذه هي جلسة التعامل مع جهات اتصال التصعيد. هذا يسمح بالوصول إلى بيانات جهة اتصال أي عميل.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "هذه هي جلسة للتعامل مع جهة الاتصال المباشرة.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "هذا الغرض يتعلق بإزالة معلومات حساسة من جهة اتصال",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "هذه هي جلسة مراجعة دقة نموذج التعلم الآلي.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "هذه هي الجلسة لمراجعة جهات الاتصال السابقين الخاصين بك.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "هذه هي جلسة الأغراض العامة الافتراضية.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "جارٍ التعامل مع جهة اتصال التصعيد",
    "PURPOSE_LIVE_CONTACT": "جار التعامل مع جهة اتصال العميل المباشرة",
    "PURPOSE_PURGE_SENSITIVE_INFO": "حذف معلومات حساسة",
    "PURPOSE_REVIEW_ML_ACCURACY": "جارٍ مراجعة دقة نموذج ML",
    "PURPOSE_REVIEW_OWN_CONTACT": "جارٍ مراجعة جهة الاتصال السابقة الخاصة بك",
    "PURPOSE_UNKNOWN": "غير مُحدَّد",
    "REASON_FOR_PURGE": "سبب الحذف",
    "REASON_FOR_PURGE_DESCRIPTION": "سبب حذف المرفقات",
    "REASON_FOR_PURGE_MISSING": "الرجاء إدخال سبب حذف المرفقات.",
    "REASSIGN": "إعادة تعيين بواسطة",
    "REASSIGNED_BY_AGENT": "تمت إعادة التعيين بواسطة {agent}",
    "REASSIGN_TO_AGENT": "إعادة التعيين إلى وكيل",
    "RECORDING": "جارٍ التسجيل",
    "RELOADING_CONTENT": "جارٍ إعادة تحميل المحتوى.",
    "RELOAD_LIST": "إعادة تحميل القائمة",
    "REPLIED": "تم الرد بواسطة",
    "REPLIED_BY_AGENT": "تم الرد بواسطة {agent}",
    "REPLY_BY": "الرد من خلال",
    "REPLY_BY_EMAIL": "الرد عبر البريد الإلكتروني",
    "REPLY_BY_PHONE": "الرد عن طريق الهاتف",
    "REPLY_RESTRICTED": "محتوى الرد مقيد.",
    "REQUEST": "طلب",
    "REQUEST_ACCESS_INSTRUCTIONS": "يرجى تقديم السبب الذي يجعل الوصول إلى المحتوى المقيد مطلوبًا. (يتم رصد هذه الطلبات وقد يكون لإساءة الاستخدام عواقب وخيمة).",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "طلب الوصول إلى محتوى تمت تصفيته",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "طلب الوصول إلى المحتوى المقيد",
    "REQUEST_NOT_GRANTED": "لم يتم منح الطلب.",
    "REQUEST_OVERRIDE": "تجاوز الطلب",
    "RESEND": "إعادة إرسال",
    "RESEND_FAILED": "فشل إعادة إرسال البريد الإلكتروني.",
    "RESEND_SUCCESS": "نجح إعادة إرسال البريد الإلكتروني.",
    "RESEND_WAIT_WARNING": "يجب أن تنتظر لمدة {wait_mins} دقائق من أجل إعادة الإرسال مرة أخرى.",
    "RESEND_WARNING": "هل تريد بالتأكيد إعادة إرسال البريد الإلكتروني إلى العميل؟",
    "RESET": "إعادة تعيين",
    "RESOLVED": "تم الحل",
    "RESOLVED_BY": "تم حل المشكلة بواسطة",
    "RESOLVED_BY_AGENT": "تم الحل بواسطة {agent}",
    "RESTRICTED_DATA_MATCH": "{match} تطابق النوع {type}",
    "RESTRICTED_INFORMATION": "معلومات مقيدة",
    "RETRY": "إعادة المحاولة",
    "ROLE": "الدور",
    "SCREEN_POP_DESCRIPTION_TITLE": "الوصف",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "لا",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "نعم",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "تم العرض للوكيل",
    "SCREEN_POP_TITLE": "معلومات نافذة منبثقة",
    "SCREEN_POP_TYPE_AUTHENTICATING": "تم عرض نافذة منبثقة للمصادقة للوكيل، وتم استخدامها.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "تم عرض النافذة المنبثقة للمصادقة للوكيل.",
    "SCREEN_POP_TYPE_CALLER_ID": "تم عرض النافذة المنبثقة لرقم المتصل للوكيل، وتم استخدامها.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "تم عرض نافذة رقم المتصل المنبثقة للوكيل.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "تم عرض نافذة منبثقة محسوسة لم تتم مصادقتها، وتم استخدامها.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "تم عرض نافذة منبثقة محسوسة لم تتم مصادقتها للوكيل.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "العميل غير معروف؛ لم يتم توفير أي شاشة منبثقة.",
    "SCREEN_POP_TYPE_OTHER": "أخرى",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "لا",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "نعم",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "مُستخدَم بواسطة الوكيل",
    "SDS_TOOLS": "أداة (أدوات) دعم الشحن والتوصيل",
    "SEARCH": "بحث",
    "SELECTED_ROLE": "الدور المحدد",
    "SELECTED_VIEW": "العرض المحدد",
    "SELECT_CHANNELS": "حدد القنوات",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "لتحديد رمز المشكلة لوضع علامة على جهات الاتصال المحددة كمكررة",
    "SELECT_TRANSFER_DESTINATION": "حدد وجهة النقل",
    "SELECT_WORK_REQUEST_QUEUE": "حدد قائمة انتظار طلب العمل",
    "SENDER_EMAIL_ADDRESS": "المرسل",
    "SENDING_EMAIL": "إرسال رسالة بريد إلكتروني",
    "SEND_EMAIL": "إرسال رسالة بريد إلكتروني",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "هل تريد بالتأكيد إرسال رابط تحميل الصورة إلى العميل؟",
    "SEND_OUTBOUND_EMAIL": "إرسال بريد إلكتروني صادر",
    "SEND_OUTBOUND_EMAIL_WARNING": "هل تريد بالتأكيد إرسال بريد إلكتروني صادر إلى",
    "SENT_FROM": "تم الإرسال من",
    "SENT_OUTBOUND_EMAIL": "تم إرسال البريد الإلكتروني الصادر. تم إنشاء جهة اتصال جديدة",
    "SENT_TO": "مرسل إلى",
    "SHIPPER_PORTAL_BUTTON_TEXT": "بوابة شركة الشحن",
    "SKILL": "المهارة",
    "SKIP_SILENCES": "تخطي الصمت",
    "SMS": "SMS",
    "SMS_LINK": "رابط الرسائل النصية القصيرة SMS",
    "SOURCE_TEXT": "النص الأصلي",
    "SP_BOTTOM": "أسفل",
    "SP_POSITION": "موضع لوحة التقسيم",
    "SP_PREFERENCES": "إعداد تفضيلات لوحة التقسيم",
    "SP_RESIZE": "تغيير حجم لوحة التقسيم",
    "SP_SIDE": "جانب",
    "START": "البدء",
    "STATE_TRANSITIONS": "تحولات الولاية",
    "STATUS": "الحالة",
    "SUBJECT": "الموضوع",
    "SYSKA_ALERTS": "التنبيهات الخاصة بأشياء عليك معرفتها",
    "TEXT_TO_PURGE": "نص للحذف",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "يجب تمكين ملفات تعريف ارتباط الأطراف الثالثة.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "لا يُسمح ببيانات تحديد الهوية الشخصية (PII) للعملاء مثل أرقام الهواتف وعناوين البريد الإلكتروني أثناء إنشاء مذكرة. يحاول نظامنا الآلي فرض أنه حتى الآن في بعض الأحيان أنك لا تعرف الفرق بين PII وغيرها من بيانات الهوية الشخصية. أدخل سبب الحاجة إلى التجاوز.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "انتهت المهلة: فشل تحميل التسجيل",
    "TIME_TEXT_FORMAT_CONSTRAINT": "استخدام صيغة 24 ساعة.",
    "TO": "إلى",
    "TO_EMAIL_ADDRESS": "إلى",
    "TRANSFER": "نقل",
    "TRANSFERRED": "تم النقل بواسطة",
    "TRANSFERRED_BY_AGENT": "تم النقل بواسطة {agent}",
    "TRANSFERRED_CHAT_TITLE": "تم نقل المحادثة مع خدمة عملاء أمازون",
    "TRANSFERRING": "جارٍ نقل...",
    "TRANSFER_CONTACT": "نقل جهة الاتصال",
    "TRANSFER_CONTACT_COMMENTS": "ملاحظة اختيارية",
    "TRANSFER_FAILED": "فشل النقل",
    "TRANSFER_FOLLOW_UP": "متابعة النقل",
    "TRANSFER_FOLLOW_UP_FAILED": "فشل في نقل المتابعة.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "فشل نقل المتابعة بسبب تسجيل دخول المالك الجديد غير صالح.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "ليس لديك إذن لنقل هذه المتابعة.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "أدخل اسم تسجيل الدخول للمالك الجديد. مثال: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "نقل المتابعة المذكورة أعلاه إلى:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "تم تحويل المتابعة بنجاح.",
    "TRANSLATED_TEXT": "النص المترجم",
    "TRANSLATION": "الترجمة",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} ليس اسمًا صالحًا لقائمة انتظار.",
    "TYPE_AGENT_LOGIN": "اكتب تسجيل دخول الوكيل",
    "TYPE_EMAIL_ADDRESS": "اكتب عنوان البريد الإلكتروني",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "اكتب عناوين بريد إلكتروني متعددة مفصولة بفواصل",
    "TYPE_QUEUE_NAME": "اكتب اسم قائمة الانتظار",
    "UNKNOWN_CONTACT_TYPE": "نوع جهة اتصال غير معروفة.",
    "UNLOCKED": "غير مغلق",
    "UNRESOLVED": "لم يتم الحل",
    "URGENT_SYSKA_ALERTS": "التنبيه العاجل الخاص بأشياء عليك معرفتها",
    "USE": "استخدام",
    "USER_TIMEZONE": "الفترات الزمنية الموضحة في منطقتك الزمنية المحلية",
    "USE_CONTACT_FINDER": "استخدام مكتشف جهات الاتصال",
    "VIEW": "عرض",
    "WARNING": "تحذير",
    "WELCOME_MESSAGE": "مرحبًا في موقع Papyrus Static الإلكتروني!",
    "WHAT_IS_PAPYRUS": "ما هو Papyrus؟",
    "WITH_THE_ANNOTATION": "مع الملاحظة",
    "WORK_CATEGORY_TITLE": "فئة العمل",
    "WORK_REQUEST": "طلب عمل",
    "WORK_REQUEST_CONTENT": "محتوى طلب العمل",
    "WORK_REQUEST_CREATED": "تم إنشاء طلب العمل",
    "WORK_REQUEST_FOR_CONTACT": "طلب عمل لجهة اتصال",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "لا يُسمح ببيانات PII مثل أرقام الهواتف وعناوين البريد الإلكتروني أثناء إنشاء طلب عمل. يحاول نظامنا الآلي فرض أنه حتى الآن في بعض الأحيان أنك لا تعرف الفرق بين PII وغيرها من بيانات الهوية الشخصية. أدخل سبب الحاجة إلى التجاوز.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "أنت على وشك الانتقال إلى قائمة الانتظار"
}