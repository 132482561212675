export const pt_PT = {
    "contact_event_GC_added": "GC n.º {gcId} adicionado à conta.",
    "contact_event_GC_added_batched": "GC adicionado à conta ({count}).",
    "contact_event_GC_added_purchaser": "GC n.º {gcId} adicionado à conta do comprador.",
    "contact_event_GC_added_purchaser_batched": "GC adicionado à conta do comprador ({count}).",
    "contact_event_GC_added_recipient": "GC n.º {gcId} adicionado à conta de destinatário.",
    "contact_event_GC_added_recipient_batched": "GC adicionado à conta de destinatário ({count}).",
    "contact_event_GC_added_scratchedCode": "GC n.º{gcId} adicionado à conta através da ferramenta de código de oferta raspado.",
    "contact_event_GC_added_scratchedCode_batched": "GC adicionado à conta através da ferramenta de código de oferta raspado ({count}).",
    "contact_event_GC_cancelled": "GC n.º {gcId} cancelado.",
    "contact_event_GC_cancelled_batched": "GC cancelado ({count}).",
    "contact_event_GC_changed_expiry": "Data de expiração do GC n.º {gcId} alterada.",
    "contact_event_GC_changed_expiry_batched": "Data de validade do GC alterada ({count}).",
    "contact_event_GC_locked": "GC n.º {gcId} bloqueado.",
    "contact_event_GC_locked_batched": "GC bloqueado ({count}).",
    "contact_event_GC_removed": "GC n.º {gcId} removido da conta.",
    "contact_event_GC_removed_batched": "GC removido da conta ({count}).",
    "contact_event_GC_resent_email": "GC n.º {gcId} reenviado para a ID de e-mail {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC reenviado para a ID de e-mail ({count}).",
    "contact_event_GC_resent_post": "GC n.º {gcId} reenviado por correio.",
    "contact_event_GC_resent_post_batched": "GC reenviado por correio ({count}).",
    "contact_event_GC_temporary_locked": "GC n.º{gcId} temporariamente bloqueado.",
    "contact_event_GC_temporary_locked_batched": "GC temporariamente bloqueado ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transferido do cliente {customerID} para {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC transferido ({count}).",
    "contact_event_GC_unlocked": "GC n.º {gcId} desbloqueado.",
    "contact_event_GC_unlocked_batched": "GC desbloqueado ({count}).",
    "contact_event_PV_added": "PV n.º{pvId} adicionado à conta.",
    "contact_event_PV_locked": "PV n.º{pvId} bloqueado.",
    "contact_event_PV_temporary_locked": "PV n.º{pvId} temporariamente bloqueado.",
    "contact_event_PV_unlocked": "PV n.º{pvId} desbloqueado.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Pedido ao abrigo da Garantia de A a Z cancelado para os pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Pedido ao abrigo da Garantia de A a Z enviado para o pedido {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Pedido ao abrigo da Garantia de A a Z enviado para os pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Pedido ao abrigo da Garantia de A a Z enviado para o pedido {order_id}.",
    "contact_event_access_code_added": "Código de acesso adicionado",
    "contact_event_account_closed": "Conta encerrada.",
    "contact_event_account_closed_batched": "Contas encerradas ({count}).",
    "contact_event_account_reopened": "Conta reaberta.",
    "contact_event_account_reopened_batched": "Contas reabertas (£{count})",
    "contact_event_account_temporarily_disabled": "Conta temporariamente desativada.",
    "contact_event_account_temporarily_disabled_batched": "Contas temporariamente desativadas (£{count})",
    "contact_event_account_whitelisted": "Conta na lista de permissões.",
    "contact_event_address_changed": "Morada {address_type} atualizada para o pedido {order_id}.",
    "contact_event_address_changed_batched": "Moradas atualizadas para os pedidos ({count}).",
    "contact_event_address_deactivated": "Morada desativada.",
    "contact_event_address_deactivated_batched": "Morada desativada ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Subscrição Amazon Video renovada automaticamente",
    "contact_event_amazon_video_subscription_cancelled": "Subscrição Amazon Video cancelada",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulário envido: formulário de encaminhamento de DSL\nID da sessão do caso de utilização: {usecaseSessionID}\nEstação de entrega: {deliveryStationField}\nTipo de pedido: {requestTypeField}\nID de contacto: {contactIdField}\nID do pedido: {orderIdField}\nEndereço de e-mail do cliente: {customerEmailField}\nNúmero de telefone do cliente: {customerPhoneNumberField}\nTBA(s): {tbasField}\nCódigo de acesso (se aplicável): {accessCodeField}\nResumo do problema: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formulários de encaminhamento DSL enviados: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulário enviado: formulário de comentários DSP DA\nID da sessão do caso de utilização: {usecaseSessionID}\nCódigo da estação de entrega: {deliveryStationField}\nID de contacto: {contactIdField}\nID da conta do cliente: {customerIdField}\nID da transportadora DA: {driverIdField}\nData do incidente (AAAA-MM-DD): {dateOfOccurrenceField}\nHora do incidente (HH:MM): {timeofOccurrenceField}\nTBAs: {relatedTrackingIdsField}\nFonte dos comentários: {sourceOfFeedbackField}\nDetalhes dos comentários: {issueField}\nDetalhes adicionais: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formulários de comentários do DSP DA enviados: ({count})",
    "contact_event_andon_cord_pulled": "Andon Cord puxado para {asin}.",
    "contact_event_andon_cord_pulled_batched": "Andon Cord puxado para artigos ({count}).",
    "contact_event_apply_adrive_music_trial": "aplicou o Cloud Player Premium para o cliente {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_cash_and_coupon_refund": "{title} foi reembolsado pelo valor de {cashAmount}. Além disso, esta transação restaurou um cupão de {couponAmount} de volta para a conta.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} foi reembolsado pelo valor de {cashAmount}. Além disso, esta transação restaurou um cupão de {couponAmount} de volta para a conta.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} foi reembolsado pelo valor de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} foi reembolsado pelo valor de {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} foi reembolsado e foi restaurado um cupão de {couponAmount} de volta para a conta.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} foi reembolsado e restaurou um cupão de {couponAmount} de volta para a conta.{counter} ({count})",
    "contact_event_audible_credit_deleted": "Removeram-se {amount} créditos da conta.",
    "contact_event_audible_credit_issued": "Foram adicionados {numCredit} créditos à conta.",
    "contact_event_audible_credit_issued_batched": "Foram adicionados créditos à conta ({count}).",
    "contact_event_audible_credit_refund": "{title} foi reembolsado e foi restaurado um crédito de {creditAmount} de volta para a conta.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} foi reembolsado e foi restaurado um crédito de {creditAmount} de volta para a conta.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} créditos foram restaurados na conta.",
    "contact_event_audible_credit_restored_batched": "Restauraram-se {amount} créditos para a conta.({count})",
    "contact_event_audible_extra_credit_purchased": "Foram comprados 3 créditos extra.",
    "contact_event_audible_extra_credit_purchased_batched": "Foram comprados 3 créditos extra.({count})",
    "contact_event_audible_hiatus_cancelled": "O período de hiato foi encerrado mais cedo e a adesão foi retomada.",
    "contact_event_audible_hiatus_cancelled_batched": "O período de hiato foi encerrado mais cedo e a adesão foi retomada.({count})",
    "contact_event_audible_hiatus_started": "A adesão foi colocada num período de hiato de {duration} mês.",
    "contact_event_audible_hiatus_started_batched": "A adesão foi feita num período de {duration} meses de hiato.({count})",
    "contact_event_audible_membership_plan_refund": "A taxa de adesão foi reembolsada no valor de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "A taxa de adesão foi reembolsada no valor de {cashAmount}.$ {counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Registado no {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Registado no {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "Acedeu-se a {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Acedeu-se a {wizardName}.({count})",
    "contact_event_blacklist_kindle": "Dispositivo na lista negra {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Kindles na lista negra ({count}).",
    "contact_event_bound_warranty": "ID da garantia {warranty_id} vinculado ao dispositivo {dsn}.",
    "contact_event_bound_warranty_batched": "Garantias vinculadas a dispositivos ({count}).",
    "contact_event_call_was_flagged": "Este contacto foi sinalizado como {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Este contacto foi sinalizado como {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "O plano {membership} foi cancelado com sucesso.",
    "contact_event_cancel_audible_membership_batched": "O plano {membership} foi cancelado com sucesso. ({count})",
    "contact_event_cancel_audible_romance_package_batched": "O plano {membership} foi cancelado com sucesso. ({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Prime cancelado com sucesso de PrimeCancelException. Motivo do cancelamento: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Exceção de cancelamento para o Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "A data de cancelamento futuro definida anteriormente foi cancelada.",
    "contact_event_cancelled_sns_reactivated": "ASIN {asin} para subscrição reativada a {reactivation_date}(mês) por {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Subscrições Subscreva e poupe canceladas reativadas ({count}).",
    "contact_event_captcha_bypass_key_generated": "Chave de omissão de Captcha gerada.",
    "contact_event_captcha_bypass_key_generated_batched": "Chave de omissão de Captcha gerada ({count}).",
    "contact_event_change_sns_quantity": "ASIN {asin} para Subscreva e poupe define a quantidade antiga {current_quantity} para nova quantidade {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Quantidades de Subscreva e poupe atualizadas ({count}).",
    "contact_event_channels_auto_renew_off": "Renovação automática de subscrição de Canais PV desativada pelo CS {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "Renovação automática via Astro desativada pelo CS.",
    "contact_event_channels_full_refund": "Subscrição de Canais PV cancelada pelo CS {subscriptionid} com reembolso total.",
    "contact_event_channels_full_refund_static": "Subscrição cancelada pelo CS com reembolso total via Astro.",
    "contact_event_channels_no_refund": "Subscrição de canais PV cancelada pelo CS {subscriptionid} sem reembolso.",
    "contact_event_channels_no_refund_static": "Subscrição cancelada pelo CS sem reembolso via Astro.",
    "contact_event_channels_workflow_launch": "Fluxo de trabalho de cancelamento Astro iniciado pelo CS para subscrição de Canais PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "Fluxo de trabalho de cancelamento Astro iniciado pelo CS.",
    "contact_event_coins_adjust": "Tipo de ajuste: {type} <br />\nMotivo: {reason} <br />\nMontante: {amount} <br />\nComentários: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo de moedas ajustado ({count}).",
    "contact_event_concessions_abuse": "O HMD não foi enviado porque o cliente é utilizador {risk_status}.",
    "contact_event_contact_seller": "Vendedor contactado para o pedido {order_id}.",
    "contact_event_contact_seller_batched": "Vendedor contactado para os pedidos ({count}).",
    "contact_event_contact_seller_gwp": "Vendedor contactado para o pedido {order_id}.",
    "contact_event_contact_was_initiated_from": "O contacto foi iniciado a partir do Asin do produto {ProductAsin} Descrição do produto {ProductDescription} URL do produto{ProductUrl}.",
    "contact_event_content_update": "Conteúdo ASIN {asin} atualizado na biblioteca do Kindle.",
    "contact_event_content_update_batched": "Conteúdo atualizado na biblioteca Kindle ({count}).",
    "contact_event_copilot_started": "Co-pilot iniciado",
    "contact_event_copilot_started_batched": "Co-pilot iniciado ({count})",
    "contact_event_credit_card_disabled": "CC desativado.",
    "contact_event_credit_card_disabled_batched": "Cartões de crédito desativados ({count}).",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Substituir a solicitação de geofence enviada para a ID de acompanhamento {trackingId} Motivo: {overrideReason} Página:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Substituir a solicitação de geofence enviada para a ID de acompanhamento {trackingId} Motivo: {overrideReason} Página:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocódigo atualizado. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocódigo atualizado. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} e pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "autenticação do cliente aprovada",
    "contact_event_customer_authentication_passed_batched": "autenticação do cliente aprovada ({count})",
    "contact_event_customer_consent_not_received_for_diode": "Consentimento do cliente não recebido para diagnóstico do dispositivo.",
    "contact_event_customer_consent_received_for_diode": "Consentimento do cliente recebido para diagnóstico do dispositivo.",
    "contact_event_customer_name_updated": "Nome do cliente atualizado.",
    "contact_event_customer_name_updated_batched": "Nome do cliente atualizado ({count}).",
    "contact_event_customer_password_changed": "Palavra-passe do cliente alterada.",
    "contact_event_customer_password_changed_batched": "Palavra-passe do cliente alterada ({count}).",
    "contact_event_delete_alexa_voice_profile": "Perfil por voz Alexa eliminado para ID do orador: {speakerID}, ID do cliente : {customerID} e estado : {status}",
    "contact_event_delete_data_alexa_comms": "Perfil Comunicações Alexa eliminado para ID de comunicações : {commsId} ,  ID de cliente : {customerID}  e estado : {status}",
    "contact_event_deprovision_alexa_comms": "Perfil Comunicações Alexa com aprovisionamento cancelado para a ID de comunicações: {commsId}, ID de cliente: {customerID} e estado:{status}",
    "contact_event_deregister_kindle": "Kindle com registo cancelado {serial_number}.",
    "contact_event_deregister_kindle_batched": "Kindles com registo cancelado ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Assistente de resolução de problemas invocado para dispositivo com DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "A função Mayday para DSN do dispositivo {dsn} não foi desativada.",
    "contact_event_disable_mayday_success": "A função Mayday para DSN do dispositivo {dsn} foi desativada com êxito.",
    "contact_event_dunning_pause": "Processo de cobrança interrompido - Consulte Histórico de pagamentos e transações para obter mais detalhes",
    "contact_event_dvbypost_extra_dispatch": "{amount} despachos extra pedidos para este cliente.",
    "contact_event_dvbypost_extra_envelope": "Foi acionado um envelope extra para este cliente.",
    "contact_event_dvbypost_fsk18_form_request": "",
    "contact_event_dvbypost_problem_code_change": "A configurar o disco {disc_id} para {problem_code}",
    "contact_event_email_address_updated": "Endereço de e-mail atualizado.",
    "contact_event_email_address_updated_batched": "Endereço de e-mail atualizado ({count}).",
    "contact_event_email_preferences_changed": "Preferências de e-mail alteradas.",
    "contact_event_email_unsubscribe": "Subscrição cancelada para o e-mail.",
    "contact_event_email_unsubscribe_batched": "Subscrição cancelada para o e-mail ({count}).",
    "contact_event_enable_mayday_failed": "A função Mayday para DSN do dispositivo {dsn} não foi reativada.",
    "contact_event_enable_mayday_success": "A função Mayday para DSN do dispositivo {dsn} foi reativada com êxito.",
    "contact_event_first_time_user_experience_set": "Definir a experiência de utilizador pela primeira vez",
    "contact_event_first_time_user_experience_set_batched": "Definir a experiência de utilizador pela primeira vez({count}).",
    "contact_event_form_submitted": "Formulário {form_name} enviado.",
    "contact_event_form_submitted_batched": "Formulários enviados ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Subscrição Free Time Unlimited cancelada para o cliente: {customerId}, DSN: {deviceSerialNumber} por {agentId} com o motivo: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Subscrição Free Time Unlimited cancelada ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Teste gratuito do Prime cancelado com sucesso por {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime cancelado com sucesso ({count}).",
    "contact_event_future_cancel_audible_membership": "O plano {membership} foi definido como Cancelamento futuro em {date}.",
    "contact_event_future_cancel_audible_membership_batched": "O plano {membership} foi definido como Cancelamento futuro em {date}. ({count})",
    "contact_event_hard_delete_from_yvl": "O ASIN {asin} foi removido permanentemente de A minha biblioteca de vídeos.",
    "contact_event_header": "Registo de eventos",
    "contact_event_hmd_suppression": "O HMD não foi enviado porque: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Chat inativo desligado",
    "contact_event_idle_chat_disconnect_batched": "Chat inativo desligado ({count})",
    "contact_event_item_price_reduced": "Preço do produto alterado de {old_price} para {new_price} para o pedido {order_id}.",
    "contact_event_item_price_reduced_batched": "Preços dos produtos alterados para as encomendas ({count}).",
    "contact_event_item_quantity_changed": "Quantidade do produto alterada no pedido {order_id}.",
    "contact_event_item_quantity_changed_batched": "Quantidades de produto alteradas para os pedidos ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Troca criada para o DSN {dsn} do pedido {old_order_id}. Novo pedido: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Dispositivos Kindle substituídos ({count}).",
    "contact_event_kindle_email_address_edits": "Endereço de e-mail atualizado no Kindle (DSN n.º {dsn}).",
    "contact_event_kindle_email_address_edits_batched": "Endereços de e-mail atualizados para os Kindles ({count}).",
    "contact_event_kindle_email_edits": "O endereço de e-mail para envio {dsn} foi alterado de {original_email} para {new_email}.",
    "contact_event_kindle_name_changed": "Kindle com nome DSN {dsn} alterado de {original_name} para {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nome do Kindle alterado ({count}).",
    "contact_event_last_page_read_deleted": "Última página lida para ASIN {asin} eliminada.",
    "contact_event_last_page_read_deleted_batched": "Última página lida eliminada ({count}).",
    "contact_event_live_assist_launched": "Partilha de ecrã do Mayday para DSN: {dsn}, kcsID: {kcsID}, resposta do cliente: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Partilha de ecrã do Mayday lançada para dispositivos ({count}).",
    "contact_event_lock_dash_order": "Os seguintes ASIN para o dispositivo {dsn} foram bloqueados: {asins}.",
    "contact_event_music_auto_renew_off": "O CS desativou a renovação automática da subscrição de Música {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "O CS desativou a renovação automática da subscrição de Música via Astro.",
    "contact_event_music_full_refund": "O CS cancelou a subscrição de Música {subscriptionid} com reembolso total",
    "contact_event_music_full_refund_static": "O CS cancelou a subscrição de Música com reembolso total via Astro.",
    "contact_event_music_no_refund": "O CS cancelou a subscrição de Música {subscriptionid} sem reembolso.",
    "contact_event_music_no_refund_static": "O CS cancelou a subscrição de Música sem reembolso via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "O CS desativou a renovação automática para a subscrição do Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "O CS cancelou a subscrição do Amazon Music Unlimited {subscriptionid} com reembolso total.",
    "contact_event_music_unlimited_no_refund": "O CS cancelou a subscrição do Amazon Music Unlimited {subscriptionid} sem reembolso.",
    "contact_event_music_unlimited_partial_refund": "O CS cancelou a subscrição do Amazon Music Unlimited {subscriptionid} com reembolso parcial.",
    "contact_event_music_unlimited_workflow_launch": "O CS iniciou o fluxo de trabalho de cancelamento do Astro para a subscrição do Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "O CS iniciou o fluxo de trabalho de cancelamento de Astro para a subscrição de Música {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "O CS iniciou o fluxo de trabalho de cancelamento de Astro para Música ilimitada.",
    "contact_event_non_dsv_refund_created": "O pedido digital {order_id} foi reembolsado {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Encomendas digitais reembolsadas ({count}).",
    "contact_event_one_click_address_updated": "Morada de 1-Clique atualizada.",
    "contact_event_one_click_address_updated_batched": "Morada de 1-Clique atualizada ({count}).",
    "contact_event_one_click_disabled": "1-Clique desativado.",
    "contact_event_one_click_disabled_batched": "1-Clique desativado ({count}).",
    "contact_event_one_click_enabled": "1-Clique ativado.",
    "contact_event_one_click_payment_updated": "Pagamento com 1-Clique atualizado.",
    "contact_event_one_click_payment_updated_batched": "Pagamento com 1-Clique atualizado ({count}).",
    "contact_event_order_cancelled": "Produtos cancelados no pedido {order_id}.",
    "contact_event_order_cancelled_batched": "Encomendas canceladas ({count}).",
    "contact_event_order_placed": "Foi feita a encomenda {order_id}.",
    "contact_event_order_placed_batched": "Encomendas efetuadas ({count}).",
    "contact_event_payment_method_added": "Forma de pagamento {payment_type} adicionada à conta.",
    "contact_event_payment_method_added_batched": "Formas de pagamento adicionadas à conta ({count}).",
    "contact_event_payment_method_changed": "Forma de pagamento alterada para o pedido {order_id}.",
    "contact_event_payment_method_changed_batched": "Forma de pagamento alterada para os pedidos ({count}).",
    "contact_event_pdocs_max_price_edits": "Cobrança de conversão máxima das definições de documentos pessoais alterada para {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Cobrança máxima de conversão para definições de documentos pessoais atualizada ({count}).",
    "contact_event_pennywise_promo_applied": "Promoção Pennywise aplicada para DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promoção Pennywise aplicada para DSN: ({count})",
    "contact_event_ping_device": "Dispositivo com DSN {dsn} contactado.",
    "contact_event_ping_device_batched": "Dispositivos contactados ({count}).",
    "contact_event_prime_auto_renew_settings": "Renovação automática do Prime definida como {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Renovação automática do Prime definida como {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Prime cancelado com sucesso. Motivo do cancelamento: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime cancelado.",
    "contact_event_prime_cancelled_batched": "Prime cancelado ({count}).",
    "contact_event_prime_concession": "Concessão Prime emitida para a encomenda {order_id}.",
    "contact_event_prime_concession_batched": "Concessões Prime emitidas para pedidos ({count}).",
    "contact_event_prime_refund": "O Prime reembolsou {amount}.",
    "contact_event_prime_refund_batched": "Prime reembolsado ({count}).",
    "contact_event_promo_added_to_account": "Promoção {amount} adicionada à conta.",
    "contact_event_promo_added_to_account_batched": "Promoções adicionadas à conta ({count}).",
    "contact_event_promo_unredeemed": "Promoção {promoBalance} não resgatada.",
    "contact_event_promo_unredeemed_batched": "Promoções não resgatadas ({count}).",
    "contact_event_refund_cancelled": "Reembolso cancelado para a encomenda {order_id}.",
    "contact_event_refund_created": "Reembolso de {refund_amount} para o pedido {order_id}.",
    "contact_event_refund_created_batched": "Encomendas reembolsadas ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} reembolsado de PrimeCancelException. Motivo do reembolso: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Reembolsar Exceção de Cancelamento Prime ({count}).",
    "contact_event_register_kindle": "Kindle registado {serial_number}.",
    "contact_event_register_kindle_batched": "Kindles registados ({count}).",
    "contact_event_registry_deleted": "Registo eliminado (ID de registo n.º {registry_id}).",
    "contact_event_registry_deleted_batched": "Registos eliminados ({count}).",
    "contact_event_release_aiv_license": "A licença do AIV {asin} foi lançada no DSN do dispositivo {dsn}.",
    "contact_event_release_licenses": "Lançar licenças no DSN {dsn} com o tipo de dispositivo {device_type} para o cliente {customer_id}.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} número de artigos retirados do dispositivo com DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Artigos removidos dos dispositivos ({count}).",
    "contact_event_remove_from_yvl": "O ASIN {asin} foi removido da sua biblioteca de vídeos para produtos eliminados.",
    "contact_event_remove_kindle_from_blacklist": "Kindle para DSN {dsn} removido do estado perdido ou roubado, motivo: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Dispositivos removidos do estado perdido ou roubado ({count}).",
    "contact_event_replacement_created": "Troca criada para o pedido {old_order_id}. Novo pedido: {new_order_id}.",
    "contact_event_replacement_created_batched": "Trocas criadas para os pedidos ({count}).",
    "contact_event_report_seller": "Vendedor denunciado para o pedido {order_id}.",
    "contact_event_report_seller_batched": "Vendedor denunciado para os pedidos ({count}).",
    "contact_event_request_invoice": "Pedido de fatura enviado ao vendedor para o pedido {order_id}.",
    "contact_event_request_invoice_batched": "Pedido de fatura enviado para os pedidos ({count}).",
    "contact_event_resend_kindle_content": "Reenvie o conteúdo digital {title} para o DSN {dsn} com o tipo de dispositivo {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Reenviar conteúdo digital ({count}).",
    "contact_event_resent_gifted_content": "O código ASIN {asin} para o e-mail do destinatário da ID do pedido {order_id} foi alterado.",
    "contact_event_resent_gifted_content_batched": "E-mail do destinatário para pedido de presente atualizado ({count}).",
    "contact_event_reset_password_email_sent": "E-mail de reposição de palavra-passe enviado.",
    "contact_event_reset_password_email_sent_batched": "E-mails de reposição de palavra-passe enviados ({count}).",
    "contact_event_residence_address_changed": "A morada residencial foi alterada",
    "contact_event_residence_address_changed_batched": "Morada da residência atualizada ({count}).",
    "contact_event_rma_cancelled": "RMA cancelada para o pedido {order_id}.",
    "contact_event_rma_created": "RMA criada para o pedido {order_id}.",
    "contact_event_rma_created_batched": "RMA criadas para os pedidos ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nome DP: {driverName}, \nNome da estação: {stationName}, \nNúmero de referência: {trackingId} e \nID do pedido: {orderId}\nID de UsecaseSession: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget de mapa aberto para Número de referência {trackingId}",
    "contact_event_sds_package_repick": "Nova recolha iniciada pelo associado para ID de acompanhamento £{trackingId}",
    "contact_event_send_aiv_asin": "O ASIN {asin} foi enviado para a Minha Biblioteca de vídeos do cliente {customer_id}.",
    "contact_event_ship_charge_reduce": "Portes de envio do pedido {order_id} alterados de {old_amount} para {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Cobranças de entrega alteradas ({$hash-get count}).",
    "contact_event_ship_method_upgraded": "Método de envio para o pedido {order_id} atualizado.",
    "contact_event_ship_method_upgraded_batched": "Métodos de envio alterados para os pedidos ({count}).",
    "contact_event_shipping_address_changed": "Morada alterada na encomenda {new_order_id}.",
    "contact_event_sim_ticket_created": "SIM - Ticket criado",
    "contact_event_special_offers_enrollment": "Ofertas especiais do Kindle com DSN $ {dsn} {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Estado de inscrição de ofertas especiais atualizado ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Subscreva e poupe cancelado para a encomenda {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "Subscreva e poupe cancelado para subscrições ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "A agenda de entrega de Subscreva e poupe foi alterada para a encomenda {order_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Agendas de entrega Subscreva e poupe alteradas para subscrições ({count}).",
    "contact_event_subscription_astro_workflow_launch": "O CS iniciou o fluxo de trabalho de cancelamento de Astro para a subscrição {subscriptionType} de {subscriptionId}.",
    "contact_event_subscription_auto_renew": "Renovação automática {plan_type} definida para {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Definições de renovação automática do Prime atualizadas ({count}).",
    "contact_event_subscription_auto_renew_off": "O CS desativou a renovação automática da subscrição {subscriptionType} de {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "Subscrição {subscriptionType} de {subscriptionId} cancelada pelo CS com reembolso total.",
    "contact_event_subscription_cancel_no_refund": "Subscrição {subscriptionType} de {subscriptionId} cancelada pelo CS sem reembolso.",
    "contact_event_subscription_cancel_partial_refund": "Subscrição {subscriptionType} de {subscriptionId} cancelada pelo CS com reembolso parcial.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "Subscrição {plan_type} definida para renovar para {new_plan_name} a partir de {old_plan_name} no final do período atual.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Atualizou as definições do plano de rollover do Prime ({count}).",
    "contact_event_troubleshooting_wizard": "Assistente de resolução de problemas invocado para dispositivo com DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Assistente de resolução de problemas invocado para dispositivos ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} para dispositivo com DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Assistente de resolução de problemas invocado para dispositivos ({count}).",
    "contact_event_unblock_international_purchase": "O estado de compra internacional foi alterado de bloqueado para {new_status}",
    "contact_event_unblock_international_purchase_batched": "Estado de compras internacionais atualizado ({count}).",
    "contact_event_unlock_dash_order": "Os seguintes ASINs para o dispositivo {dsn} foram desbloqueados: {asins}.",
    "contact_event_update_locking_status": "O estado de bloqueio do dispositivo Oft {dsn} foi atualizado para: {newLockingStatus}",
    "contact_event_verify_email": "O e-mail de verificação foi enviado ao cliente.",
    "contact_event_verify_email_email_sent": "Verificação de e-mail enviada.",
    "contact_event_weekend_delivery_preference_changed": "Preferência de entrega ao fim de semana atualizada"
}