import {FollowUp} from "./Contact.types";
import React, {useContext, useEffect, useState} from "react";
import {i18n} from "../i18n/IntlManager";
import {CallResponse, LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Phoenix from "../api/Phoenix";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ContactLink from "./ContactLink";

interface FollowUpsForCustomerDisplayProps {
    customerId?: string;
    customerEmail?: string;
    marketplaceId: string;
}

function buildQuery(marketplaceId: string, startDate: Date, endDate: Date, startKey?: string, customerId?: string, customerEmail?: string): string {
    return `
    query {    
        followUpsForCustomer(
            obfuscatedMarketplaceId: "${marketplaceId}"
            ${customerId ? `obfuscatedCustomerId:"${customerId}",` : ""}
            ${customerEmail ? `encryptedEmailAddress:"${customerEmail}",` : ""}
            isResolved: false,
            first: 50,
            ${startKey ? `after:"${startKey}",` : ""}
            startDate: "${startDate.toISOString()}",
            endDate: "${endDate.toISOString()}",
        ) {
            followUpList {
                followUpId
                contactId
                dueDate
                owner {
                    agentId
                    agentLogin
                }
                
            }
            pagination {
                key
            }
        }
    }
`;
}

const FollowUpsForCustomerDisplay: React.FC<FollowUpsForCustomerDisplayProps> = ({customerId, customerEmail, marketplaceId}) => {
    const [followUpList, setFollowUpList] = useState<FollowUp[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);
    const { callPhoenix } = useContext(IssueManagerContext);

    useEffect(() => {
        function resetStates(): void {
            setFollowUpList([]);
            setHasError(false);
        }

        resetStates();
        if (customerEmail || customerId) {
            fetchFollowUpsForCustomer(marketplaceId, customerEmail, customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplaceId, customerEmail, customerId]);

    async function fetchFollowUpsForCustomer( marketplaceId: string, customerEmail?: string, customerId?: string ): Promise<void> {
        let followUps: FollowUp[] = [];
        let startKey: string | undefined = undefined;
        const creationEndDate = new Date(); // now
        const creationStartDate = new Date(creationEndDate.getTime() - (400 * 24 * 3600 * 1000)); // 13 months before now
        const timeOutCall = new Date(new Date().getTime() + (5 * 1000));// Timeout after 5 seconds
        let numPages = 0;
        try {
            do {
                const query = buildQuery(marketplaceId, creationStartDate, creationEndDate, startKey, customerId, customerEmail);
                const response = await callPhoenix(query);
                const {customerFollowUps, nextKey} = processCallResponse(response);
                followUps = followUps.concat(customerFollowUps);
                startKey = nextKey;
                numPages = numPages + 1;
            } while (startKey && new Date().getTime() < timeOutCall.getTime());
        } catch (_error) {
            setHasError(true);
        } finally {
            Phoenix.getInstance().addMetric("FollowUpsForCustomer.Timeout", new Date().getTime() < timeOutCall.getTime() ? 0 : 1, MetricType.COUNT);
            Phoenix.getInstance().addMetric("FollowUpsForCustomer.numberOfPages", numPages, MetricType.COUNT);
        }
        setFollowUpList(followUps);
    }

    function processCallResponse(response: CallResponse): {customerFollowUps: FollowUp[]; nextKey?: string} {
        let error = false;
        try {
            if (response.ok && response.callResult?.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (!parsed.data?.followUpsForCustomer) {
                    error = true;
                    throw new Error("Failed to fetch customer follow ups");
                }

                const customerFollowUps: FollowUp[] = parsed.data.followUpsForCustomer.followUpList || [];
                const nextKey: string | undefined = parsed.data.followUpsForCustomer.pagination?.key;
                return {customerFollowUps, nextKey};
            } else {
                error = true;
                throw new Error("Failed to fetch customer follow ups");
            }
        } finally {
            Phoenix.getInstance().addMetric("FollowUpsForCustomer.Success", error ? 0 : 1, MetricType.COUNT);
            if (error) {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to fetch customer follow ups: " + JSON.stringify(response));
            }
        }
    }

    return (
        <div>
            {showAlert &&
                <div style={{width: "100%", height: "50%"}}>
                    {hasError ?
                        <Alert header={i18n("ERROR")} type="info"
                               dismissible={true}
                               onDismiss={(): void => setShowAlert(false)}
                               >
                            {i18n("CUSTOMER_FOLLOWUPS_FAILURE")}
                        </Alert>
                        : (followUpList.length > 0) ?
                            <Alert header={i18n("CUSTOMER_FOLLOWUPS")} type="info" dismissible={true} onDismiss={(): void => setShowAlert(false)}>
                                {followUpList.map((followUp: FollowUp) => (
                                    <div key = {followUpList.indexOf(followUp)}>
                                        {
                                            <div>
                                                {followUp.contactId ?
                                                    <div>
                                                        <ContactLink contactId={followUp.contactId}
                                                                     message={
                                                                         i18n('FOLLOW_UP_ALERT',
                                                                             {values: {
                                                                                    "dueDate": new Date(followUp.dueDate).toDateString(),
                                                                                    "agentLogin": followUp.owner.agentLogin
                                                                             }})
                                                                     }
                                                        >

                                                        </ContactLink>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        }
                                    </div>
                                ))}
                            </Alert>
                            : <></>
                    }
                </div>
            }
        </div>
        );
};

export default FollowUpsForCustomerDisplay;