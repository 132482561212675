import React, {useContext, useState} from 'react';
import {i18n} from "../i18n/IntlManager";
import Phoenix from "../api/Phoenix";
import {JwtToken} from "@amzn/csphoenix-react-client";
import {AuthorizerJwtToken} from "./AuthorizerJwtToken";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Form from "@amzn/awsui-components-react/polaris/form";
import Container from "@amzn/awsui-components-react/polaris/container";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import RadioGroup, {RadioGroupProps} from "@amzn/awsui-components-react/polaris/radio-group";
import Input from "@amzn/awsui-components-react/polaris/input";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";

interface OverrideRequestFormProps {
    contactId: string;
    clearMatchErrors: () => void;
    closeModal: () => void;
}

export const CreateWorkRequestOverrideRequestForm: React.FC<OverrideRequestFormProps> = ({contactId, clearMatchErrors, closeModal}) => {
    const radioItems: RadioGroupProps.RadioButtonDefinition[] = [
            {value: 'INVALID_DATA_MATCH', label: i18n('INVALID_DATA_MATCH')},
            {value: 'CURRENT_PROCESS_REQUIRES_PII', label: i18n('OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII')},
            {value: 'OTHER', label: i18n('OTHER')}
        ];

    const [flashBarItems, setFlashBarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [reason, setReason] = useState<string>(radioItems[0].value);
    const [reasonText, setReasonText] = useState<string>("");

    const {clientStateToken, setClientStateToken, addAuthorizerJwtToken, purpose} = useContext(IssueManagerContext);

    async function getAuthToken(): Promise<void> {

        const payload = {
            entityId: {id: contactId, type: 'CONTACT'},
            purpose: purpose,
            authorization: 'CREATE_WORK_REQUEST_WITHOUT_FILTER',
            reason: reason === 'OTHER' ? reasonText : reason,
        };
        if (clientStateToken) {
            payload['jwtState'] = clientStateToken.jwtString;
        }
        const response = await Phoenix.getInstance().postCall('authorize', payload);

        if (response.status === 200) {
            const jsonResult = await response.json();
            const token = new AuthorizerJwtToken(jsonResult.jwtAuthToken);
            const clientJwt = new JwtToken(jsonResult.jwtState);
            setClientStateToken(clientJwt);
            await addAuthorizerJwtToken(token);
            clearMatchErrors();
            closeModal();
        } else {
            const newErrorItem: FlashbarProps.MessageDefinition = {
                content: i18n('REQUEST_FAILED') + ' status: ' + response.status,
                dismissible: true,
                type: "error"
            };
            setFlashBarItems([...flashBarItems, newErrorItem]);
        }
    }

    const theForm = (<Container>
        <Flashbar items={flashBarItems}/>
        <Form>
        <TextContent>{i18n("WORK_REQUEST_OVERRIDE_REASON_PROMPT")}</TextContent>
        <FormField>
        <RadioGroup value={reason} onChange={({detail}): void => setReason(detail.value)} items={radioItems}/>
        </FormField>
        <FormField><Input disabled={reason !== 'OTHER'} value={reasonText} onChange={({detail}): void => setReasonText(detail.value)}/>
        </FormField>
    </Form></Container>);

    return (<Modal visible={true} header={i18n('REQUEST_OVERRIDE')} onDismiss={(): void => closeModal()} footer={
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(): void => closeModal()}>{i18n('CANCEL')}</Button>
                <Button variant="primary" onClick={(): Promise<void> => getAuthToken()}>{i18n('REQUEST')}</Button>
            </SpaceBetween>
        </Box>
    }>{theForm}</Modal>);
};