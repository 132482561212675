import {ContextualAlert, ContextualAlertPriority} from "./Contact.types";
import React, {useContext, useEffect, useState} from "react";
import Translation from "../i18n/Translate";
import ContextualAlertsDisplay from "./ContextualAlertDisplay";
import EmdashImage from "../util/EmdashImage";
import IntlManager, {i18n} from "../i18n/IntlManager";
import Box from "@amzn/awsui-components-react/polaris/box";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import {CallResponse, JwtToken, LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Phoenix from "../api/Phoenix";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Button from "@amzn/awsui-components-react/polaris/button";

interface InternalSyskaDisplayProps {
    customerId?: string;
    customerEmail?: string;
    marketplaceId: string;
}

function buildQuery(marketplaceId: string, customerId?: string,  customerEmail?: string, startKey?: string): string {
    return (
        `query {
              contextualAlertsForCustomerContacts(
                        input: {
                            ${customerEmail ? `emailAddressEncrypted:"${customerEmail}",` : ""}
                            ${customerId ? `obfuscatedCustomerId:"${customerId}",` : ""}
                            obfuscatedMarketplaceId: "${marketplaceId}",
                            contextualAlertsLocation: "CSCentral Communications Manager"
                        },                        
                        ${startKey ? `after:"${startKey}",` : ""}            
                        first: 10           
                    ){
                    contextualAlertList {
                        contextualAlertId
                        displayableAlertBody
                        displayableAlertTitle
                        alertPriority
                    }
                    pagination {
                        key
                    }
              }
        }
`
    );
}

const InternalSyskaDisplay: React.FC<InternalSyskaDisplayProps> = ({customerId, customerEmail, marketplaceId}) => {
    const [syskaLoading, setSyskaLoading] = useState<boolean>(false);
    const [syskaAlerts, setSyskaAlerts] = useState<ContextualAlert[]>([]);
    const [urgentAlerts, setUrgentAlerts] = useState<ContextualAlert[]>([]);
    const [urgentAlertModalVisible, setUrgentAlertModalVisible] = React.useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const {clientStateToken, callPhoenix} = useContext(IssueManagerContext);
    useEffect(() => {
        async function fetchAlertList( marketplaceId: string, jwtState: JwtToken | null, customerEmail?: string, customerId?: string ): Promise<void> {
            setSyskaLoading(true);
            let allAlerts: ContextualAlert[] = [];
            let startKey: string | undefined = undefined;
            try {
                do {
                    const query = buildQuery(marketplaceId, customerId, customerEmail, startKey);
                    const response = await callPhoenix(query);
                    const {newAlerts, nextKey} = processCallResponse(response);
                    allAlerts = allAlerts.concat(newAlerts);
                    startKey = nextKey;
                } while (startKey);
            } catch (_error) {
                setHasError(true);
            }
            const sortedAlerts = sortAlertsBasedOnPriority(dedupeAlerts(allAlerts));
            setSyskaAlerts(sortedAlerts);
            const urgentAlerts = sortedAlerts.filter((alert) => alert.alertPriority === ContextualAlertPriority.URGENT);
            if (urgentAlerts.length > 0) {
                setUrgentAlerts(urgentAlerts);
                setUrgentAlertModalVisible(true);
            }
            setSyskaLoading(false);
        }

        function processCallResponse(response: CallResponse): {newAlerts: ContextualAlert[]; nextKey?: string} {
            if (response.ok && response.callResult?.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                const newAlerts = parsed.data?.contextualAlertsForCustomerContacts?.contextualAlertList || [];
                const nextKey = parsed.data?.contextualAlertsForCustomerContacts?.pagination?.key;
                Phoenix.getInstance().addMetric("InternalSyska.Success", 1, MetricType.COUNT);
                if (newAlerts.length > 0) {
                  Phoenix.getInstance().addMetric("InternalSyska.HasContent", 1, MetricType.COUNT);
                } else {
                  Phoenix.getInstance().addMetric("InternalSyska.NoContent", 1, MetricType.COUNT);
                }
                return { newAlerts, nextKey };
            } else {
                Phoenix.getInstance().addMetric("InternalSyska.Failure", 1, MetricType.COUNT);
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to fetch internal SYSKA: " + JSON.stringify(response));
                throw new Error("Failed to process SYSKA call response");
            }
        }

        function dedupeAlerts(contextualAlerts: ContextualAlert[]): ContextualAlert[] {
            const uniqueAlerts: ContextualAlert[] = [];
            const seenIds: Set<string> = new Set();
            for (const contextualAlert of contextualAlerts) {
                if (!seenIds.has(contextualAlert.contextualAlertId)) {
                    uniqueAlerts.push(contextualAlert);
                    seenIds.add(contextualAlert.contextualAlertId);
                }
            }
            return uniqueAlerts;
        }

        function sortAlertsBasedOnPriority(contextualAlert: ContextualAlert[]): ContextualAlert[] {
            return contextualAlert.sort((alert1, alert2) => {
                if ( alert1.alertPriority === alert2.alertPriority) {
                    return 0;
                } else if (alert1.alertPriority === ContextualAlertPriority.NORMAL) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        function resetStates(): void {
            setSyskaAlerts([]);
            setSyskaLoading(false);
            setHasError(false);
        }

        resetStates();
        fetchAlertList(marketplaceId, clientStateToken || null, customerEmail, customerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplaceId, customerEmail, customerId]);

    return (
        <>
            <Modal
                onDismiss={(): void => setUrgentAlertModalVisible(false)}
                visible={urgentAlertModalVisible}
                size="medium"
                header={i18n("URGENT_SYSKA_ALERTS")}
                footer={
                    <Box float={"right"}>
                        <Button variant="primary" onClick={(): void => setUrgentAlertModalVisible(false)}>
                            {i18n("I_UNDERSTAND")}
                        </Button>
                    </Box> }
            >
                {urgentAlertModalVisible && <ContextualAlertsDisplay alerts={urgentAlerts}/>}
            </Modal>
            <div style={{width: "25%"}}>
                <Box variant="h3" fontSize="heading-xs">
                    <span>
                        <Translation stringId="SYSKA_ALERTS"/>
                    </span>
                </Box>
                {hasError ?
                    <Alert header={i18n("ERROR")} type="error">
                        {i18n("FAILED_TO_FETCH_SYSKA")}
                    </Alert>
                    : syskaLoading ?
                        <Box textAlign="center" aria-label={IntlManager.sharedManager.formatMessage("LOADING")}>
                            <Spinner size="large"/>
                        </Box>
                        : (syskaAlerts && syskaAlerts.length > 0) ?
                            <ContextualAlertsDisplay
                                alerts={syskaAlerts}
                            />
                            : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SYSKA_ALERTS")}/>
                }
            </div>
        </>);
};

export default InternalSyskaDisplay;