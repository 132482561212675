import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {CallResponse, JwtToken} from "@amzn/csphoenix-react-client";
import Phoenix from "../api/Phoenix";
import {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import {emitLatency, getMaxAccessLevel, buildCSALTRequest} from "../issueManager/IssueManager";
import {AuthorizerJwtToken} from "../authorizer/AuthorizerJwtToken";
import './OutboundCustomerNotification.css';
import IntlManager, {i18n} from "../i18n/IntlManager";
import {OutboundNotification} from "./outboundNotifications/OutboundNotification.types";
import {OutboundNotificationDisplay} from "./outboundNotifications/OutboundNotificationDisplay";
import moment from "moment-timezone";
import {fetchCustomerTimezone} from "../customerTimezone/customerTimezone";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {LoadingSpinner} from "../components/LoadingSpinner";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {CookieDisabledAlert} from "../components/CookieDisabledAlert";
import Translation from "../i18n/Translate";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import {CustomerIdSelect} from "../components/CustomerIdSelect";
import {RoleSelect} from "../components/RoleSelect";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Button from "@amzn/awsui-components-react/polaris/button";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {parseParams} from "../util/Params";
import {isDateRangeValid, toDateString} from "./outboundNotifications/DateUtils";
import {TimeZoneInfo} from "../customerTimezone/TimeZoneInfo";
import {PurposeSelection} from "../authorizer/PurposeSelection";
import {CustomerAccessLevel} from "../contact/Contact.types";

interface OutboundCustomerNotificationContextType {
    getSubject: () => string | undefined;
    callPhoenix: (request: string, newJwtState?: JwtToken | null, jwtAuthTokens?: AuthorizerJwtToken[] | null) => Promise<CallResponse>;
    clientStateToken?: JwtToken;
    setClientStateToken: (JwtToken) => void;
    addAuthorizerJwtToken: (AuthorizerJwtToken) => void;
    authorizerJwtTokens: AuthorizerJwtToken[];
    reloadList: () => void;
}

export const OutboundCustomerNotificationContext = React.createContext({} as OutboundCustomerNotificationContextType);

function buildQuery(customerId: string, marketplaceId: string, startKey: string, startDate: string, endDate: string, emailToken: string | null, notificationTypes: string[]): string {
    return (
        `query {
        outboundCustomerNotificationMetadata(
            input:{
                ${customerId ? `obfuscatedCustomerId:"${customerId}",` : ""}
                obfuscatedMarketplaceId:"${marketplaceId}",                
                ${emailToken ? `emailAddressEncrypted:"${emailToken}",` : ""}
                startDate:"${startDate}",
                endDate:"${endDate}",
                notificationTypes:[${notificationTypes.join(",")}]
        },
        after:"${startKey}"
    ){
            notificationMetadataList {
                messageId
                fromEmail
                toEmail
                subject
                customerPhoneNumber
                sourcePhoneNumber
                sentDate
                status
                visibility
            }
            pagination {
                key
            }
        }
    }`
    );
}

export const OutboundCustomerNotificationPage: React.FC<RouteComponentProps> = ({location}) => {
    const PAGE_SIZE = 5;
    const MAX_PAGE_SIZE = 200;
    const DEFAULT_TIME_RANGE_DAYS = 30;
    const {emailToken, customerIdList, marketplaceId} = parseParams(location);
    const customerIdSelectionList: SelectProps.Option[] = customerIdList.map(cid => {
        return {value: cid, label: cid};
    });
    const [customerId, setCustomerId] = useState<string>(customerIdList.length ? customerIdList[0] : "");
    const [error, setError] = useState<string>("");
    const [jwtState, setJwtState] = useState<JwtToken | null>(Phoenix.getInstance().jwtState);
    const [authorizerJwtTokens, setAuthorizerJwtTokens] = useState<AuthorizerJwtToken[]>([]);
    const [activeRole, setActiveRole] = useState<SelectProps.Option>(jwtState ?
        {value: jwtState.getRole(), label: jwtState.getRole()}
        : {value: "CSA", label: "CSA"});
    const [availableRoles, setAvailableRoles] = useState<SelectProps.Option[]>([activeRole]);
    const [isNotificationListLoading, setIsNotificationListLoading] = useState<boolean>(false);
    const [callResponse, setCallResponse] = useState<CallResponse>();
    const currentTime = new Date();
    const [endDate, setEndDate] = useState<string>(toDateString(currentTime));
    const [startDate, setStartDate] = useState<string>(toDateString(new Date(currentTime.getTime() - (DEFAULT_TIME_RANGE_DAYS * 24 * 3600 * 1000))));
    const [outboundNotifications, setOutboundNotifications] = useState<OutboundNotification[] | null>(null);
    const [startKey, setStartKey] = useState<string>("");
    const [customerTimezone, setCustomerTimezone] = useState<string>("");
    // Moment Timezone uses the Internationalization API Intl.DateTimeFormat().resolvedOptions().timeZone
    // to determine the user's time zone.
    // https://momentjs.com/timezone/docs/#/using-timezones/
    const isCustomerTimezone = !!(customerTimezone && customerTimezone.trim().length > 0);
    const timezone = isCustomerTimezone ? customerTimezone : moment.tz.guess();
    const [showEmail, setShowEmail] = useState<boolean>(true);
    const [showSMS, setShowSMS] = useState<boolean>(false);

    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const customerAccessLevel: CustomerAccessLevel = getMaxAccessLevel(callResponse);
    const customerContentIsRestricted: boolean = customerAccessLevel !== CustomerAccessLevel.RESTRICTED_PII;

    const callPhoenix = async (request: string, newJwtState?: JwtToken | null, jwtAuthTokens?: AuthorizerJwtToken[] | null): Promise<CallResponse> => {
        const phoenixRequest = buildCSALTRequest(request, newJwtState ? newJwtState : jwtState, jwtAuthTokens ? jwtAuthTokens : authorizerJwtTokens);
        return Phoenix.getInstance().csalt(phoenixRequest);
    };

    // Context to be passed down to child components
    const outboundCustomerNotificationContext = {
        getSubject: (): string | undefined => {
            return jwtState?.getSubject();
        },
        callPhoenix,
        clientStateToken: jwtState ? jwtState : undefined,
        setClientStateToken: (jwtState): void => {
            setJwtState(jwtState);
        },
        addAuthorizerJwtToken: (token: AuthorizerJwtToken): void => {
            setAuthorizerJwtTokens([...authorizerJwtTokens, token]);
        },
        authorizerJwtTokens: authorizerJwtTokens,
        reloadList: (): void => {
            resetTime();
            refetch();
        }
    };

    function resetTime(): void {
        const currentTime = new Date();
        setEndDate(toDateString(currentTime));
        setStartDate(toDateString(new Date(currentTime.getTime() - (DEFAULT_TIME_RANGE_DAYS * 24 * 3600 * 1000))));
    }

    function fetchNotificationList(
        customerId: string, startKey: string, jwtState: JwtToken | null,
        isFirstPage: boolean, startD: string = startDate, endD: string = endDate,
    ): void {
        if ((customerId.trim() || emailToken) && marketplaceId != null && isDateRangeValid(startD, endD, timezone)) {
            const notificationTypes: string[] = [];
            if ( showEmail ) notificationTypes.push("EMAIL");
            if ( showSMS ) notificationTypes.push("SMS");
            if (notificationTypes.length > 0) {
                setIsNotificationListLoading(true);
                const startTZ = moment.tz(startD, timezone).startOf("day");
                const endTZ = moment.tz(endD, timezone).endOf("day");
                const query = buildQuery(customerId, marketplaceId, startKey, startTZ.toISOString(), endTZ.toISOString(), emailToken, notificationTypes);
                callPhoenix(query, jwtState)
                    .then((response: CallResponse) => processCallResponse(response, isFirstPage))
                    .catch(() => {
                        setError("An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.");
                    });
            }
        }
    }

    function processCallResponse(response: CallResponse, isFirstPage: boolean): void {
        setIsNotificationListLoading(false);
        if (response.httpStatus === 401) {
            Phoenix.getInstance().startAuth((error) => {
                setError(error);
            });
            return;
        }
        setCallResponse(response);
        if (response.error) {
            setError(response.error);
        }
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed?.data?.outboundCustomerNotificationMetadata?.notificationMetadataList) {
                    // If we get here then we did have a good call with results.
                    emitLatency('LatencyOutboundNotificationList', response.latency);
                    const outboundNotificationsFetched: OutboundNotification[] = parsed.data.outboundCustomerNotificationMetadata.notificationMetadataList;
                    const outboundNotificationsNew = (outboundNotifications && !isFirstPage) ?
                        outboundNotifications.concat(outboundNotificationsFetched) :
                        outboundNotificationsFetched;
                    setOutboundNotifications(outboundNotificationsNew);
                    setStartKey(parsed.data.outboundCustomerNotificationMetadata.pagination.key || "");
                }
            }
            if (response.callResult.jwtState) {
                setJwtState(response.callResult.jwtState);
                setAvailableRoles(response.callResult.jwtState.getRoles().map((s: string) => ({
                    value: s,
                    label: s
                })));
            }
        } else {
            setError("An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.");
        }
    }

    const changeCustomerId = (id: string, label: string): void => {
        if (id !== customerId) {
            resetNotifications();
            fetchForCustomer(jwtState, id);
        }
    };

    function fetchForCustomer(jwtState: JwtToken | null, cid: string): void {
        setCustomerId(cid);
        setCustomerTimezone("");
        fetchCustomerTimezone(cid, marketplaceId, jwtState, authorizerJwtTokens).then((timezone) => setCustomerTimezone(timezone));
        fetchNotificationList(cid, "", jwtState, true);
    }

    const assumeRole = (id: string, label: string): void => {
        if (activeRole.value !== id) {
            Phoenix.getInstance().assumeRole(jwtState, id)
                .then((newJwtToken: JwtToken) => processAssumeRoleResponse(newJwtToken))
                .catch(error => setError(error.toString()));
        }
    };

    const processAssumeRoleResponse = (jwtState: JwtToken): void => {
        setJwtState(jwtState);
        resetNotifications();
        setActiveRole({value: jwtState.getRole(), label: jwtState.getRole()});
        fetchForCustomer(jwtState, customerId);
    };

    const hasNextPage = (): boolean => {
        const nextStartIndex = currentIndex + PAGE_SIZE;
        return (
            !!outboundNotifications &&
            currentIndex <= MAX_PAGE_SIZE &&
            (nextStartIndex < outboundNotifications.length || !!startKey));
    };

    const nextPage = (): void => {
        const nextStartIndex = currentIndex + PAGE_SIZE;
        if (outboundNotifications && (outboundNotifications.length - nextStartIndex) < PAGE_SIZE && startKey) {
            fetchNotificationList(customerId, startKey, jwtState, false);
        }
        setCurrentIndex(nextStartIndex);
    };

    const hasPreviousPage = (): boolean => {
        return currentIndex > 0;
    };

    const previousPage = (): void => {
        setCurrentIndex(currentIndex - PAGE_SIZE);
    };

    const refetch = (): void => {
        resetNotifications();
        fetchNotificationList(customerId, "", jwtState, true, startDate, endDate);
    };

    const resetNotifications = (): void => {
        setOutboundNotifications(null);
        setStartKey("");
        setCurrentIndex(0);
    };

    useEffect(() => {
        // On mount, check first whether we have a valid rpToken. Otherwise, we would make a call, get a 401, and only
        // then start the authentication process.
        const rpToken = Phoenix.getInstance().getRpToken();
        if (rpToken !== undefined) {
            // Initial fetch
            resetNotifications();
            fetchForCustomer(jwtState, customerId);
        } else {
            Phoenix.getInstance().startAuth(() => {
                setError(i18n("AUTHENTICATION_FAILURE"));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwtState?.getContent().ap]);

    return (
        <OutboundCustomerNotificationContext.Provider value={outboundCustomerNotificationContext}>
            <div>
                <SpaceBetween direction="vertical" size="s">
                    {error && CookieDisabledAlert}
                    {callResponse?.httpStatus === 401 ? <div><Translation stringId="AUTHENTICATING"/></div> : ''}
                    <PurposeSelection
                        clientStateToken={jwtState || undefined}
                        setClientStateToken={(jwtState): void => {
                            setJwtState(jwtState);
                        }}
                        disableSelectPurpose={isNotificationListLoading}
                    />
                    <ColumnLayout columns={2}>
                        <CustomerIdSelect
                            disabled={isNotificationListLoading}
                            customerContentIsRestricted={customerContentIsRestricted}
                            onCustomerIdChange={changeCustomerId}
                            selectedCustomerId={customerId}
                            customerIdSelectionList={customerIdSelectionList}/>
                        <RoleSelect
                            activeRole={activeRole}
                            disabled={isNotificationListLoading}
                            availableRoles={availableRoles}
                            onRoleChange={assumeRole}
                        />
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                        <div>
                            <FormField
                                label={i18n("CHANNELS")}
                            >
                                <Checkbox
                                    disabled={isNotificationListLoading}
                                    onChange={({detail}): void => setShowEmail(detail.checked)}
                                    checked={showEmail}
                                >
                                    <Translation stringId="EMAIL"/>
                                </Checkbox>
                                <Checkbox
                                    disabled={isNotificationListLoading}
                                    onChange={({detail}): void => setShowSMS(detail.checked)}
                                    checked={showSMS}
                                >
                                    <Translation stringId="SMS"/>
                                </Checkbox>
                            </FormField>
                        </div>
                        <div>
                            <FormField>
                                <SpaceBetween  direction="horizontal" size="m">
                                    <FormField
                                        label={i18n("FROM")}
                                    >
                                    <DatePicker
                                        disabled={isNotificationListLoading}
                                        onChange={({detail}): void => {
                                            setStartDate(detail.value);
                                        }}
                                        value={startDate}
                                        isDateEnabled={(d): boolean => {
                                            try {
                                                const startTZ = moment.tz(d, timezone);
                                                const nowTZ = moment.tz(moment.now(), timezone);
                                                if (endDate) {
                                                    const endTZ = moment.tz(endDate, "YYYY/MM/DD", timezone);
                                                    return startTZ < endTZ;
                                                } else {
                                                    return startTZ < nowTZ;
                                                }
                                            } catch (e) {
                                                return false;
                                            }
                                        }}
                                        nextMonthAriaLabel={i18n("DATE_PICKER_NEXT_MONTH")}
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel={i18n("DATE_PICKER_PREVIOUS_MONTH")}
                                        todayAriaLabel={i18n("DATE_PICKER_TODAY")}
                                    />
                                    </FormField>
                                    <FormField
                                        label={i18n("TO")}
                                    >
                                    <DatePicker
                                        disabled={isNotificationListLoading}
                                        onChange={({detail}): void => {
                                            setEndDate(detail.value);
                                        }}
                                        value={endDate}
                                        isDateEnabled={(d): boolean => {
                                            const endTZ = moment.tz(d, timezone);
                                            const nowTZ = moment.tz(moment.now(), timezone);
                                            if (startDate) {
                                                const startTZ = moment.tz(startDate, "YYYY/MM/DD", timezone);
                                                return startTZ < endTZ && endTZ <= nowTZ;
                                            } else {
                                                return endTZ <= nowTZ;
                                            }
                                        }}
                                        nextMonthAriaLabel={i18n("DATE_PICKER_NEXT_MONTH")}
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel={i18n("DATE_PICKER_PREVIOUS_MONTH")}
                                        todayAriaLabel={i18n("DATE_PICKER_TODAY")}
                                    />
                                    </FormField>
                                </SpaceBetween>
                                <FormField>
                                    <Button onClick={(): void => refetch()}
                                            disabled={isNotificationListLoading || !isDateRangeValid(startDate, endDate, timezone)}
                                            ariaLabel={IntlManager.sharedManager.formatMessage("SEARCH")}
                                            iconName="search"
                                            iconAlign="right"
                                            variant="primary"
                                    >
                                        <Translation stringId={"SEARCH"}/>
                                    </Button>
                                </FormField>
                            </FormField>
                        </div>
                    </ColumnLayout>
                    <TimeZoneInfo isCustomerTimezone={isCustomerTimezone}/>
                    {isNotificationListLoading ?
                        <LoadingSpinner/> :
                        currentIndex > MAX_PAGE_SIZE ?
                            <Alert header={i18n("WARNING")}
                                   type="warning">{i18n("OUTBOUND_NOTIFICATION_MAX_RESULTS")}</Alert> :
                            outboundNotifications && outboundNotifications?.length > 0 ?
                                <SpaceBetween direction={"vertical"} size={"s"}>
                                    {outboundNotifications?.slice(currentIndex, currentIndex + PAGE_SIZE)
                                        .map((notification) => {
                                            return (<OutboundNotificationDisplay
                                                key={notification.messageId}
                                                notification={notification}
                                                customerTimezone={timezone}
                                            />);
                                        })}
                                </SpaceBetween> :
                                <Alert header={i18n("WARNING")} type="warning">{i18n("NO_RESULTS")}</Alert>
                    }
                    <ColumnLayout columns={2}>
                        <span style={{float: "left"}}>
                            {hasPreviousPage() ?
                                <Button onClick={previousPage}
                                        disabled={isNotificationListLoading}
                                        ariaLabel={IntlManager.sharedManager.formatMessage("PREVIOUS")}
                                        iconName="angle-left"
                                        iconAlign="left"
                                >
                                    <Translation stringId={"PREVIOUS"}/>
                                </Button>
                                : null
                            }
                        </span>
                        <span style={{float: "right"}}>
                        {hasNextPage() ?
                            <Button onClick={nextPage}
                                    disabled={isNotificationListLoading}
                                    ariaLabel={IntlManager.sharedManager.formatMessage("NEXT")}
                                    iconName="angle-right"
                                    iconAlign="right"
                            >
                                <Translation stringId={"NEXT"}/>
                            </Button>
                            : null
                        }
                        </span>
                    </ColumnLayout>
                </SpaceBetween>
            </div>
        </OutboundCustomerNotificationContext.Provider>);
};