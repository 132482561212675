import React, {MutableRefObject, SyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import {Button, Checkbox, SegmentedControl, SpaceBetween} from "@amzn/awsui-components-react";
import {CSALTCallLoader, RecordingPartResult} from "./CSALTCallLoader";
import {CanvasState, CSALTWaveCanvas, getAnimationState} from "./CSALTWaveCanvas";
import {CSALTAudioCache} from "./CSALTAudioCache";

export enum PhoneMediumType {
    GACD,
    AwsConnect
}

export interface CSALTAudioPlayerProps {
    playLabel: string;
    pauseLabel: string;
    preparingRecordingLabel: string;
    decodingAudioLabel: string;
    timeoutLabel: string;
    skipSilencesLabel: string;
    speedLabel: string;
    contactId: string;
    phoneMediumType: PhoneMediumType;
    id?: string;
}

export interface Gap {
    start: number;
    endx: number;
}

export interface SampledAudio {
    duration: number;
    gaps: Gap[];
    leftSamples: number[];
    leftMax: number;
    rightSamples: number[];
    rightMax: number;
    // -1 if not in gap
    // otherwise index where the gap ends.
    gapsArray: number[];
}

// Just global cache
const csaltAudioCache: CSALTAudioCache = new CSALTAudioCache();

/**
 * Function to turn AudioBuffer into a graph for canvas widget and detection of
 * silences for the auto-skip feature.
 * @param audioBuffer
 */
export function processBuffer(audioBuffer: AudioBuffer): SampledAudio {
    const leftChannel = audioBuffer.getChannelData(0);
    const rightChannel = audioBuffer.getChannelData(1);
    const durationInSec = audioBuffer.duration;
    const sampleRate = audioBuffer.sampleRate;

    const leftSamples: number[] = [];
    const rightSamples: number[] = [];
    const gaps: Gap[] = [];

    // 1 sample per second
    const samples = Math.ceil(durationInSec);

    let inSilence = false;
    let silenceStart = 0;

    let leftMax = 0;
    let rightMax = 0;
    for (let i = 0; i < samples; ++i) {
        const start = sampleRate * i;
        let sumLeft = 0;
        let sumRight = 0;
        for (let j = 0; j < sampleRate; ++j) {
            const index = start + j;
            sumLeft += Math.abs(leftChannel[index]);
            sumRight += Math.abs(rightChannel[index]);
        }
        if (sumLeft > leftMax) {
            leftMax = sumLeft;
        }
        if (sumRight > rightMax) {
            rightMax = sumRight;
        }
        leftSamples.push(sumLeft);
        rightSamples.push(sumRight);
        if ((sumLeft + sumRight) < 200.0) {
            if (!inSilence) {
                inSilence = true;
                silenceStart = i;
            }
        }
        else {
            if (inSilence) {
                inSilence = false;
                const silenceDuration = i - silenceStart;
                if (silenceDuration > 10) {
                    gaps.push({start: silenceStart, endx: i});
                }
            }
        }
    }
    const gapsArray: number[] = [];
    let gapNr = 0;
    if (gaps.length > 0) {
        let gap = gaps[0];
        for (let i = 0; i < leftSamples.length; ++i) {
            if (i < gap.start) {
                // before the next gap
                gapsArray.push(-1);
            } else {
                if (i < gap.endx) {
                    // in the gap
                    gapsArray.push(gap.endx);
                } else {
                    // just at finished a gap or beyond all the gaps
                    gapsArray.push(-1);
                    if (i === gap.endx) {
                        gapNr += 1;
                        if (gapNr < gaps.length) {
                            gap = gaps[gapNr];
                        }
                    }
                }
            }
        }
    }
    return {
        duration: durationInSec,
        gaps: gaps,
        leftSamples: leftSamples,
        leftMax: leftMax,
        rightSamples: rightSamples,
        rightMax: rightMax,
        gapsArray: gapsArray
    };
}

/**
 * There are different Audio API used here...
 */
export const CSALTAudioPlayer: React.FC<CSALTAudioPlayerProps> = ({contactId,
                                                                      phoneMediumType,
                                                                      id,
                                                                      playLabel,
                                                                      pauseLabel,
                                                                      skipSilencesLabel,
                                                                      speedLabel,
                                                                      preparingRecordingLabel,
                                                                      decodingAudioLabel}) => {
    const [duration, setDuration] = useState<number>();
    const [sampledAudio, setSampledAudio] = useState<SampledAudio>();
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [canvasState, setCanvasState] = useState<CanvasState>(CanvasState.NO_DATA);
    const [actionButtonLabel, setActionButtonLabel] = useState<string>(playLabel);
    const [skipSilence, setSkipSilence] = useState<boolean>(true);
    const [selectedSpeedId, setSelectedSpeedId] = React.useState("1");
    const [blobUrl, setBlobUrl] = React.useState<string>();
    const [audioBuffer, setAudioBuffer] = React.useState<AudioBuffer>();

    const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;

    const hasMedia = canvasState === CanvasState.LOADING || canvasState === CanvasState.PROCESSING || canvasState === CanvasState.READY;

    function setRatio(ratio): void {
        if (sampledAudio) {
            const time = ratio * sampledAudio.duration;
            audioRef.current.currentTime = time;
        }
    }

    function formatAsDuration(seconds: number): string {
        const minute = Math.floor(seconds / 60);
        const remainder = Math.floor(seconds) % 60;
        return (minute < 10 ? " " : "") + minute + ":" + (remainder < 10 ? "0" : "") + remainder;
    }

    function locationAsString(): string {
        return formatAsDuration(currentTime) + " /" + formatAsDuration(duration || 0);
    }

    function buttonClick(_: CustomEvent): void {
        // Now using audio element directlu
        if (actionButtonLabel === pauseLabel) {
            audioRef.current.pause();
            setActionButtonLabel(playLabel);
        } else {
            audioRef.current.play();
            setActionButtonLabel(pauseLabel);
        }
    }

    function onTimeUpdate(event: SyntheticEvent<HTMLAudioElement>): void {
        setCurrentTime(event.currentTarget.currentTime);

        // Skip if in silence gap
        if (sampledAudio && skipSilence) {
            const second = Math.floor(currentTime);
            if (sampledAudio.gapsArray.length > 0 && sampledAudio.gapsArray[second] !== -1) {
                audioRef.current.currentTime = sampledAudio.gapsArray[second];
            }
        }
    }

    const pullFromCSALT = useCallback(async (
        id: string) => {

        const cachedBuffers = csaltAudioCache.getBuffers(id);
        if (cachedBuffers) {
            const audioContext = new AudioContext();
            const ab = await audioContext.decodeAudioData(Buffer.concat(cachedBuffers).buffer);
            setAudioBuffer(ab);

            const blob = new Blob([...cachedBuffers], {type: phoneMediumType === PhoneMediumType.AwsConnect ? "audio/wav" : "audio/mpeg" });
            const blobUrl = URL.createObjectURL(blob);
            setBlobUrl(blobUrl);

            setCanvasState(CanvasState.READY);
            return;
        }

        const loader = new CSALTCallLoader(contactId, phoneMediumType, id);

        const animationState = getAnimationState(id);
        if (animationState) {
            animationState.desiredActive = true;
            animationState.label = preparingRecordingLabel;
        }

        setCanvasState(CanvasState.PREPARING);
        setBlobUrl(undefined);
        setDuration(undefined);

        let attempts = 1;
        while (attempts < 15) {
            const first = await loader.pullPart(undefined);

            if (first) {
                if (animationState) {
                    animationState.desiredActive = true;
                    animationState.label = 'Loading';
                }
                setCanvasState(CanvasState.LOADING);

                const firstBlob = new Blob([first.buffer], {type: phoneMediumType === PhoneMediumType.AwsConnect ? "audio/wav" : "audio/mpeg" });
                const fistBlobUrl = URL.createObjectURL(firstBlob);
                setBlobUrl(fistBlobUrl);

                let offset = first.buffer.length;
                let part: RecordingPartResult | null = first;
                const buffers = [first.buffer];
                while (part && part.nextPart) {
                    part = await loader.pullPart(offset);
                    if (part) {
                        buffers.push(part.buffer);
                        offset += part.buffer.length;
                    }
                }

                // finally got all the parts!
                csaltAudioCache.storeBuffers(id, buffers);

                const audioContext = new AudioContext();
                const ab = await audioContext.decodeAudioData(Buffer.concat(buffers).buffer);
                setAudioBuffer(ab);

                const blob = new Blob([...buffers], {type: phoneMediumType === PhoneMediumType.AwsConnect ? "audio/wav" : "audio/mpeg" });
                const blobUrl = URL.createObjectURL(blob);
                setBlobUrl(blobUrl);

                setCanvasState(CanvasState.READY);
                return;
            }

            ++attempts;
        }
    }, [contactId, phoneMediumType, preparingRecordingLabel]);

    useEffect(() => {
        if (blobUrl && audioRef && audioRef.current) {
            audioRef.current.src = blobUrl;
            audioRef.current.currentTime = currentTime;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blobUrl]);

    useEffect(() => {
        if (audioBuffer) {
            const processesSampledAudio: SampledAudio = processBuffer(audioBuffer);
            setSampledAudio(processesSampledAudio);
            setDuration(audioBuffer.duration);
        }
    }, [audioBuffer]);

    useEffect(() => {
        async function streamAndPlay(id: string): Promise<void> {
            await pullFromCSALT(id);
        }

        if (id) {
            streamAndPlay(id);
        }
    }, [id, pullFromCSALT]);

    return (
        <SpaceBetween size={'xxxs'} direction={'vertical'}>
            <CSALTWaveCanvas
                id={id}
                sampledAudio={sampledAudio}
                currentTime={currentTime}
                canvasState={canvasState}
                setRatio={(ratio): void => setRatio(ratio)}
            />
            <SpaceBetween direction={'horizontal'} size={'s'}>
                <Button disabled={!hasMedia} onClick={(event): void => buttonClick(event)}>{actionButtonLabel}</Button>
                {locationAsString()}
                <Checkbox checked={skipSilence}
                          onChange={(event): void => setSkipSilence(event.detail.checked)}
                >{skipSilencesLabel}</Checkbox>
                <SegmentedControl
                    selectedId={selectedSpeedId}
                    onChange={({detail}): void => {
                        audioRef.current.playbackRate = Number(detail.selectedId);
                        setSelectedSpeedId(detail.selectedId);
                    }}
                    label={speedLabel}
                    options={[
                        {text: "1", id: "1"},
                        {disabled: !blobUrl, text: "1.25", id: "1.25"},
                        {disabled: !blobUrl, text: "1.50", id: "1.50"},
                        {disabled: !blobUrl, text: "1.75", id: "1.75"},
                        {disabled: !blobUrl, text: "2", id: "2"}
                    ]}
                />
            </SpaceBetween>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef}
                   autoPlay={true}
                   onPlay={(event): void => setActionButtonLabel(pauseLabel)}
                   onPause={(event): void => setActionButtonLabel(playLabel)}
                   crossOrigin={'anonymous'}
                   onTimeUpdate={(event): void => onTimeUpdate(event)}
            />
        </SpaceBetween>
    );
};

