import React from "react";
import {Issue} from "./Contact.types";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import EmdashImage from "../util/EmdashImage";
import {Copyable} from "../util/Copyable";
import IntlManager from "../i18n/IntlManager";

export enum IssueSelector {
    Customer = "CUSTOMER",
    Agent = "AGENT"
}

interface IssuesComponentProps {
    issue?: Issue;
    selector: IssueSelector;
}

const constructIssuesView = (descriptors: string[], index: number): JSX.Element => {
    if (index === descriptors.length) {
        return (<ul/>);
    }
    return (<ul className="list-style-square">
        <li>
            {descriptors[index++]}
            {constructIssuesView(descriptors, index)}
        </li>
    </ul>);
};

const Issues: React.FC<IssuesComponentProps> = ({issue, selector}) => {
    const noIssueTranslationId = `NO_${selector}_SELECTED_ISSUE`;
    const actionTranslationId = `COPY_${selector}_SELECTED_ISSUE`;
    const successTranslationId = `${selector}_SELECTED_ISSUE_COPIED`;

    if (issue == null || issue.descriptors.length === 0) {
        // Don't need a copyable for an empty issue code
        return <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage(noIssueTranslationId)} />;
    }

    return (
        <div>
            <Copyable
                actionTranslationId={actionTranslationId}
                successTranslationId={successTranslationId}
                content={(
                    <Popover
                        content={constructIssuesView(issue.descriptors, 0)}
                        position={"top"}
                    >
                        {issue?.descriptors.slice(Math.max(issue?.descriptors.length - 2, 0)).join(' / ')}
                    </Popover>
                )}
                valueToCopy={issue?.descriptors.join(' / ')}/>
        </div>
    );
};

export default Issues;