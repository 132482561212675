/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Phoenix from '../api/Phoenix';

const Alive: React.FC = () => {

    const [aliveResponse, setAliveResponse] = useState<string>('');

    useEffect(() => {
            Phoenix.getInstance().isAliveCall()
                .then((response: Response) => response.text())
                .then((response: string) => setAliveResponse(response))
                .catch(error => setAliveResponse('Error: ' + error));
        }
    );

    return (
        <div>Is it Alive? {aliveResponse}</div>
    );
};

export default Alive;