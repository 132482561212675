import React from "react";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import CSCentralURL from "../issueManager/CSCentralURL";
import {Copyable} from "../util/Copyable";

type OrderLinkProps = {
    orderId: string;
};

const OrderLink: React.FC<OrderLinkProps> = ({ orderId}) => {
    const url = CSCentralURL.sharedCSCentralURL.getURL("gp/order/detail/", "orderID=" + orderId);
    const cscURL = url ? url : "# ";
    const target = cscURL ? "_blank" : "_self";

    return (
        <div>
            <Copyable
                actionTranslationId="COPY_ORDER_ID"
                successTranslationId="ORDER_ID_COPIED"
                content={(
                    <a target={target} href={url}>{orderId}<Icon name="external"/></a>
                )}
                valueToCopy={orderId}/>
        </div>
    );
};

export default OrderLink;
