import React from 'react';
import {UsecaseSession} from "../contact/Contact.types";
import {ExpandableSection} from "@amzn/awsui-components-react";

export interface UsecaseSessionsProps {
    usecaseSessions?: UsecaseSession[];
}

export const UsecaseSessions: React.FC<UsecaseSessionsProps> = ({usecaseSessions}) => {
    return (<ExpandableSection header={"UsecaseSessions"}><pre>{JSON.stringify(usecaseSessions, null, 2)}</pre></ExpandableSection>);
};