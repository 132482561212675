import React from 'react';
import {Purpose, purposeDescription, purposeName} from "../contact/Contact.types";
import Select, {SelectProps} from "@amzn/awsui-components-react/polaris/select";
import {i18n} from "../i18n/IntlManager";
import Phoenix from "../api/Phoenix";
import {JwtToken} from "@amzn/csphoenix-react-client";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {Box, Button, Form, Modal, SpaceBetween} from "@amzn/awsui-components-react";

interface PurposeSelectionProps {
    clientStateToken?: JwtToken;
    setClientStateToken: (JwtToken) => void;
    disableSelectPurpose: boolean;
}

export const PurposeSelection: React.FC<PurposeSelectionProps> = ({
                                                                      clientStateToken,
                                                                      setClientStateToken,
                                                                      disableSelectPurpose
                                                                  }) => {

    const pl = clientStateToken?.getContent().pl;

    const purposeList: Purpose[] = pl ? pl.map(v => Purpose[v]) : [];

    const activePurpose: Purpose = clientStateToken?.getContent().ap ? clientStateToken?.getContent().ap : Purpose.u;

    const purposeOptions: SelectProps.Options = purposeList.map(p => {
        return {label: purposeName(p), value: p};
    });

    const selectedPurpose = {label: purposeName(activePurpose), value: activePurpose};

    async function setActivePurpose(selectedOption: SelectProps.Option): Promise<void> {
        const payload = {
            purpose: selectedOption.value,
            jwtState: clientStateToken?.jwtString
        };
        const response = await Phoenix.getInstance().postCall('setActivePurpose', payload);
        if (response.status === 200) {
            const jsonResult = await response.json();
            const clientJwt = new JwtToken(jsonResult.jwtState);
            setClientStateToken(clientJwt);
        }
    }

    const [sessionSelectionVisible, setSessionSelectionVisible] = React.useState<boolean>(false);

    const sessionSelection: JSX.Element = (<Modal
        visible={sessionSelectionVisible}
        onDismiss={(): void => setSessionSelectionVisible(false)}
        header={i18n('PAPYRUS_SESSION_TYPE')}
        footer={<Box float={"right"}><SpaceBetween direction="horizontal" size="xs">
            {<Button variant="link" onClick={(): void => setSessionSelectionVisible(false)}>{i18n('CLOSE')}</Button>}
        </SpaceBetween>
        </Box>}
    >
        <Form>
            <p>{i18n('PAPYRUS_SESSION_EXPLANATION')}</p>
            <FormField label={i18n('PAPYRUS_SESSION_TYPE')}>
                <Select selectedOption={selectedPurpose}
                        options={purposeOptions}
                        onChange={({detail}): Promise<void> => setActivePurpose(detail.selectedOption)}
                        disabled={disableSelectPurpose}
                ></Select>
                <p>{purposeDescription(selectedPurpose.value)}</p>
            </FormField>
        </Form>
    </Modal>);

    return (
        (purposeList.length > 1) ? 
        <div>
            {sessionSelection}
            <Box variant="span">
                {i18n('PAPYRUS_SESSION_TYPE')}
                <Button variant={'link'}
                        onClick={(): void => setSessionSelectionVisible(true)}>
                            {selectedPurpose.label}
                </Button>
            </Box>
        </div> : null);
};

