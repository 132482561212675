import React, {useContext, useState} from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Translation from "../i18n/Translate";
import {CustomerAccessLevel, FollowUp} from "./Contact.types";
import FollowUpsTable from "./FollowUpsTable";
import {i18n} from "../i18n/IntlManager";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Phoenix from "../api/Phoenix";
import {MetricType} from "@amzn/csphoenix-react-client";
import {getUserOwner} from "./UserDetailsUtil";
/**
 * Enum to represent the state for transferring a new follow-up
 * `SUCCESS` - 200 response code and response has follow-up
 * `INVALID_NEW_OWNER` - 200 response code and the destination agent id is invalid
 * `INVALID_REQUESTER` - 200 response code and the requester agent has no right to transfer
 * `FAIL` - Any other response code
 * `NONE` - No request made
 */
enum TransferFollowUpStatus {
    SUCCESS,
    INVALID_NEW_OWNER,
    INVALID_REQUESTER,
    FAIL,
    NONE
}

const statusToResponseStringIdMap: { [key: string]: string } = {
    [TransferFollowUpStatus.SUCCESS]: "TRANSFER_FOLLOW_UP_SUCCESS",
    [TransferFollowUpStatus.INVALID_NEW_OWNER]: "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER",
    [TransferFollowUpStatus.INVALID_REQUESTER]: "TRANSFER_FOLLOW_UP_INVALID_REQUESTER",
    [TransferFollowUpStatus.FAIL]: "TRANSFER_FOLLOW_UP_FAILED",
};

const statusToMetricNameMap: { [key: string]: string } = {
    [TransferFollowUpStatus.SUCCESS]: "SUCCESS",
    [TransferFollowUpStatus.INVALID_NEW_OWNER]: "INVALID_NEW_OWNER",
    [TransferFollowUpStatus.INVALID_REQUESTER]: "INVALID_REQUESTER",
    [TransferFollowUpStatus.FAIL]: "FAIL",
};
const MAX_USER_NAME_LENGTH = 30;

function buildTransferFollowUpMutation(followUpId: string, newOwnerLogin: string, newOwnerOwnerName: string): string {
    return `mutation {
      transferFollowUp(
        input: {
            followUpId: "${followUpId}",
            newOwnerAgent: {
				agentLogin: "${newOwnerLogin}",
				agentOwner: "${newOwnerOwnerName}"
            }
        })
         {
            error {
                type
                errorMessageDescriptor {
                    stringId
                }
            }
         }
    }`;
}

interface TransferFollowUpModalProps {
    visible: boolean;
    followUp: FollowUp;
    dismissCallback: () => void;
    customerAccessLevel: CustomerAccessLevel;
    customerTimezone: string;
    pushNotification: (msg: JSX.Element, type: FlashbarProps.Type) => void;
    contactId: string;
}

const TransferFollowUpModal: React.FC<TransferFollowUpModalProps> = (
    {visible, followUp, dismissCallback, customerAccessLevel, customerTimezone, pushNotification, contactId},
) => {
    const [newOwnerLogin, setNewOwnerLogin] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {reloadSingleContact, getSubject, callPhoenix} = useContext(IssueManagerContext);
    const newOwnerOwnerName = getUserOwner(getSubject());

    async function transferFollowUp(): Promise<void> {
        let status: TransferFollowUpStatus = TransferFollowUpStatus.NONE;
        setIsLoading(true);
        try {
            const mutation = buildTransferFollowUpMutation(
                followUp.followUpId,
                newOwnerLogin,
                newOwnerOwnerName,
            );

            const response = await callPhoenix(mutation);

            // Status code 200
            if (response?.callResult?.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed?.data?.transferFollowUp?.error?.type) {
                    if (parsed.data.transferFollowUp.error.type === "INVALID_NEW_OWNER") {
                        status = TransferFollowUpStatus.INVALID_NEW_OWNER;
                    } else if (parsed.data.transferFollowUp.error.type === "INVALID_REQUESTER") {
                        status = TransferFollowUpStatus.INVALID_REQUESTER;
                    }
                } else {
                    // No errors mean success
                    status = TransferFollowUpStatus.SUCCESS;
                    // Reload the contact to reflect change
                    await reloadSingleContact(contactId);
                }
            }
            // Some other kind of error
            else {
                status = TransferFollowUpStatus.FAIL;
            }
        } catch (e) {
            status = TransferFollowUpStatus.FAIL;
        } finally {
            setIsLoading(false);
            closeModal();
        }
        pushNotification(transferFollowUpResponseMessage(status), status === TransferFollowUpStatus.SUCCESS ? "success" : "error");
        Phoenix.getInstance().addMetric(transferFollowUpMetricName(status), 1, MetricType.COUNT);
        Phoenix.getInstance().addMetric("TransferFollowUp", 1, MetricType.COUNT);
    }

    const closeModal = (): void => {
        if (!isLoading) {
            setNewOwnerLogin("");
            dismissCallback();
        }
    };

    function transferFollowUpResponseMessage(status: TransferFollowUpStatus): JSX.Element {
        return status === TransferFollowUpStatus.NONE ?
            <></> :
            <Translation stringId={statusToResponseStringIdMap[status]}/>;
    }

    function transferFollowUpMetricName(status: TransferFollowUpStatus): string {
        const metricName = "TransferFollowUp.";
        return status === TransferFollowUpStatus.NONE ?
            "" :
            metricName + statusToMetricNameMap[status];
    }

    return (
        <Modal
            onDismiss={closeModal}
            visible={visible}
            closeAriaLabel={i18n('CLOSE')}
            size="large"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                disabled={isLoading}
                                onClick={closeModal}>
                            {i18n('CANCEL')}
                        </Button>
                        <Button variant="primary"
                                loading={isLoading}
                                disabled={!newOwnerLogin}
                                onClick={(): void => {
                                    transferFollowUp();
                                }}>
                            {i18n('CONFIRM')}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={<Translation stringId={"TRANSFER_FOLLOW_UP"}/>}
        >
            <>
                <FollowUpsTable followUps={[followUp]} customerAccessLevel={customerAccessLevel}
                                customerTimezone={customerTimezone}/>
                <FormField
                    label={i18n("TRANSFER_FOLLOW_UP_MODAL_LABEL")}
                    description={
                        i18n("TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION", {values: {"example_login": followUp.owner.agentLogin}}) +
                        " " +
                        i18n("INPUT_CHARACTERS_REMAINING", {
                            values: {
                                "char_max": MAX_USER_NAME_LENGTH,
                                "char_remain": (MAX_USER_NAME_LENGTH - newOwnerLogin.length).toString()
                            }
                        })
                    }
                >
                    <Input
                        onChange={({detail}): void => {
                            if (detail.value.length <= MAX_USER_NAME_LENGTH) {
                                setNewOwnerLogin(detail.value);
                            }
                        }}
                        value={newOwnerLogin}
                    />
                </FormField>
            </>
        </Modal>
    );
};

export default TransferFollowUpModal;