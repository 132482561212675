import React, {useContext, useState} from "react";
import {ConnectPhoneRecord} from "./Contact.types";
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {Copyable} from "../util/Copyable";
import {i18n} from "../i18n/IntlManager";
import {CSALTAudioPlayer, PhoneMediumType} from "../audioplayer/CSALTAudioPlayer";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Button from "@amzn/awsui-components-react/polaris/button";
import Translation from "../i18n/Translate";
import {CSALTCallLoader} from "../audioplayer/CSALTCallLoader";
import Phoenix from "../api/Phoenix";
import {LogLevel} from "@amzn/csphoenix-react-client";

interface AmazonConnectContactDetailProps {
    contactId: string;
    connectPhoneRecordList: ConnectPhoneRecord[];
}

const AmazonConnectContactDetail: React.FC<AmazonConnectContactDetailProps> = ({contactId, connectPhoneRecordList}) => {

    const [selectedItems, setSelectedItems] = useState<ConnectPhoneRecord[]>();
    const [downloading, setDownloading] = useState<string>();

    const {clientStateToken} = useContext(IssueManagerContext);

    const allowDownload = clientStateToken?.getContent()["dlrec"];

    async function loadToBlob(r: ConnectPhoneRecord): Promise<Blob|null> {
        const loader = new CSALTCallLoader(contactId, PhoneMediumType.AwsConnect, r.connectContactId);
        return await loader.loadRecording();
    }

    async function downloadRecording(r: ConnectPhoneRecord): Promise<void> {
        setDownloading(r.connectContactId);
        try {
            const data: Blob | null = await loadToBlob(r);
            if (data) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(data);
                // Filename to safe it as
                link.download = `${r.connectContactId}.wav`;
                // Similar silly stuff because Chrome tries to only execute href actions
                // on click events ....
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        finally {
            setDownloading(undefined);
        }
    }

    const baseColumns: ReadonlyArray<TableProps.ColumnDefinition<ConnectPhoneRecord>> = [
            {id: "agentLogin", header: i18n("AGENT"), cell: (connectPhoneRecord: ConnectPhoneRecord): React.ReactNode => connectPhoneRecord.agent.agentLogin},
            {id: "connectContactId", header: i18n("AMAZON_CONNECT_CONTACT_ID"), cell: (connectPhoneRecord: ConnectPhoneRecord): React.ReactNode =>
                    <Copyable
                        actionTranslationId="COPY_AMAZON_CONNECT_CONTACT_ID"
                        successTranslationId="AMAZON_CONNECT_CONTACT_ID_COPIED"
                        content={connectPhoneRecord.connectContactId}
                        valueToCopy={connectPhoneRecord.connectContactId}/>},
            ];

    const columns: ReadonlyArray<TableProps.ColumnDefinition<ConnectPhoneRecord>> = allowDownload ? [
        ...baseColumns,
        {id: "download", header: i18n("DOWNLOAD"), cell: (r: ConnectPhoneRecord): React.ReactNode =>
            <Button
                loading={downloading === r.connectContactId}
                disabled={downloading !== undefined}
                onClick={(): Promise<void> => downloadRecording(r)}
                wrapText={false}>
                <Translation stringId={"DOWNLOAD"}/>
            </Button>
        }
    ] : baseColumns;

    for (let i = 0; i < connectPhoneRecordList.length; i++) {
        const agentLog = connectPhoneRecordList.at(i)?.agent?.agentLogin;
        const agentOwner = connectPhoneRecordList.at(i)?.agent?.agentOwner;
        const connectContactId = connectPhoneRecordList.at(i)?.connectContactId;
        const accessLog = connectPhoneRecordList.at(i)?.cscRecordingAccessStatus;

        Phoenix.getInstance()
            .log(LogLevel.INFO, "Info log for call recording for CSC contactId " + contactId +
                " for connect contactId " + connectContactId +
                " for user:" + agentLog + " owner:" + agentOwner + " with cscRecordingAccessStatus " + accessLog);
    }

    return (
        <div>
            <CSALTAudioPlayer
                playLabel={i18n('PLAY')}
                pauseLabel={i18n('PAUSE')}
                preparingRecordingLabel={i18n('PREPARING_RECORDING')}
                decodingAudioLabel={i18n('DECODING_AUDIO')}
                timeoutLabel={i18n('TIMEOUT_FAILED_TO_LOAD_RECORDING')}
                skipSilencesLabel={i18n('SKIP_SILENCES')}
                speedLabel={i18n('PLAYBACK_SPEED')}
                contactId={contactId}
                phoneMediumType={PhoneMediumType.AwsConnect}
                id={selectedItems ? selectedItems[0].connectContactId : undefined}/>
            <Table selectionType={"single"}
                   selectedItems={selectedItems}
                   onSelectionChange={(event): void => {
                       setSelectedItems(event.detail.selectedItems);
                   }}
                   columnDefinitions={columns}
                   items={connectPhoneRecordList}
                   header={
                        <h4 className="contact-details-header">
                        { i18n("AMAZON_CONNECT_CALL_INFO") }
                        </h4>
                        }
                   // TODO - https://sim.amazon.com/issues/AC3CSPUMA-773
                   isItemDisabled={(r): boolean =>(r.cscRecordingAccessStatus === 'DENIED' || r.cscRecordingAccessStatus === undefined)}
            />
        </div>
    );
};

export default AmazonConnectContactDetail;