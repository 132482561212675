export const ALLOWED_DOMAINS = new Set([
    'cscentral.amazon.com',
    'cscentral-eu.amazon.com',
    'cscentral-fe.amazon.com',
    'cscentral-cn.amazon.com',
    'tt.amazon.com',
    't.corp.amazon.com',
    'www.imdb.com',
    'scarif.imdb.amazon.dev',
    'pro.imdb.com',
    'audible.com',
    'audible.co.uk',
    'audible.com.au',
    'audible.it',
    'audible.co.jp',
    'audible.de',
    'audible.fr',
    'audible.ca',
    'audible.es',
    'acx.com',
    'sim.amazon.com',
    'issues.amazon.com',
    'www.amazon.com',
    'www.amazon.co.uk',
    'www.amazon.de',
    'www.amazon.fr',
    'www.amazon.it',
    'www.amazon.es',
    'www.amazon.eg',
    'www.amazon.in',
    'www.amazon.co.jp',
    'www.amazon.ca',
    'www.amazon.cn',
    'www.amazon.com.br',
    'www.amazon.com.mx',
    'www.amazon.com.au',
    'www.amazon.ru',
    'www.amazon.nl',
    'www.amazon.sa',
    'www.amazon.ae',
    'www.amazon.com.tr',
    'www.amazon.sg',
    'www.amazon.pl',
    'www.amazon.se',
    'www.amazon.com.be',
    'www.amazon.com.co',
    'www.amazon.cl',
    'www.amazon.com.ng',
    'www.amazon.com.za'
    ]
);
