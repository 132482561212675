import React from "react";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import {EmailQueue} from "./Contact.types";

interface EmailQueueDisplayProps {
    emailQueue?: EmailQueue;
}

const EmailQueueDisplay: React.FC<EmailQueueDisplayProps> = ({emailQueue}) => {
    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId="EMAIL_QUEUE_TITLE"/></h4>
    {emailQueue ?
        <div>{emailQueue.name}</div>
        : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_EMAIL_QUEUE")}/>}
    </div>);
    };

export default EmailQueueDisplay;