import React, {useContext, useState} from "react";
import {Contact, ReassignContactResponse} from "../Contact.types";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {AddText, addTextAndSubmit, AddTextStatus} from "../AddText";
import Container from "@amzn/awsui-components-react/polaris/container";
import Translation from "../../i18n/Translate";
import Button from "@amzn/awsui-components-react/polaris/button";
import {i18n} from "../../i18n/IntlManager";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {LogLevel, Match} from "@amzn/csphoenix-react-client";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import {AllHtmlEntities} from 'html-entities';
import {PushNotificationFunction} from "../ContactActions";
import {getUserLogin, getUserOwner} from "../UserDetailsUtil";
import Phoenix from "../../api/Phoenix";


interface ReassignContactFormProps {
    contact: Contact;
    deleteMe: () => void;
    pushNotification: PushNotificationFunction;
}

export const ReassignContactForm: React.FC<ReassignContactFormProps> = ({contact, deleteMe, pushNotification}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toAgent, setToAgent] = useState<string>("");
    const [toAgentStatus, setToAgentStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [contactComment, setContactComment] = useState<string>("");
    const [contactCommentStatus, setContactCommentStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [restrictedDataMatchList, setRestrictedDataMatchList] = useState<Match[]>([]);

    const {authorizerJwtTokens, reloadSingleContact, getSubject, callPhoenix} = useContext(IssueManagerContext);

    const reassignDisabled = toAgent === undefined || toAgent.length === 0;
    const stringifiedContactComment = JSON.stringify(AllHtmlEntities.encode(contactComment));

    function getReassignContactCsalt(contact: Contact): string {
        const generatedRequestId = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
        return `
            mutation {
                reassignContact(
                    input: {
                        obfuscatedContactId: "${contact.contactId}",
                        expectedOwnerAgent: {agentLogin:"${getUserLogin(getSubject())}", agentOwner:"${getUserOwner(getSubject())}"},
                        newOwnerAgent: {agentLogin:"${toAgent}", agentOwner:"${getUserOwner(getSubject())}"},
                        idempotencyKey: "${generatedRequestId}"` +
                        (contactComment.length ? `, reassignCommentEncrypted: ${stringifiedContactComment}}) {` : '}) {') +
                `   failure {
                        errorType
                        errorMessageDescriptor {
                            stringId
                        }
                    }
                }
            }`;
    }

    function cancel(): void {
        deleteMe();
    }

    async function onClickRestrictedDataMatch(matches): Promise<void> {
        setContactCommentStatus(AddTextStatus.ERROR);
        setRestrictedDataMatchList(matches);
        setIsLoading(false);
    }

    async function reassignContact(): Promise<void> {
        try {
            setRestrictedDataMatchList([]);

            const response = await callPhoenix(getReassignContactCsalt(contact));

            if (response.httpStatus === 200) {
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data.reassignContact) {
                        const reassignContactResponse = parsed.data.reassignContact as ReassignContactResponse;
                        if (reassignContactResponse.failure) {
                            // TODO: Replace error notification with errorMessageDescriptor returned from call
                            //  once the csalt strings are fixed.
                            Phoenix.getInstance().log(LogLevel.ERROR, `Failed to reassign contact with ID ${contact.contactId} to agent`);
                            pushNotification((<div>{i18n('FAILED_TO_REASSIGN_CONTACT_TO_AGENT')}</div>), 'error');
                        } else {
                            pushNotification((<div>{i18n('CONTACT_REASSIGNED_TO_AGENT')}</div>), 'success');
                            await reloadSingleContact(contact.contactId);
                            setIsLoading(false);
                            deleteMe();
                        }
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, `Failed to reassign contact with ID ${contact.contactId} to agent`);
                pushNotification((<div>{i18n('FAILED_TO_REASSIGN_CONTACT_TO_AGENT')}</div>), 'error');
            }
        } catch(error) {
            Phoenix.getInstance().log(LogLevel.ERROR, `Failed to reassign contact with ID ${contact.contactId} to agent`);
            pushNotification((<div>{i18n('FAILED_TO_REASSIGN_CONTACT_TO_AGENT')}</div>), 'error');
        } finally {
            setIsLoading(false);
        }
    }

    async function onClickRestrictedDataValidationFailure(): Promise<void> {
        setContactCommentStatus(AddTextStatus.FAIL);
        setIsLoading(false);
    }

    return (
        <Container header={<h4><Translation stringId={"REASSIGN_TO_AGENT"} /></h4>}>
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        variant="link"
                        onClick={(): void => cancel()}
                    >{i18n('CANCEL')}</Button>
                    <Button
                        variant="primary"
                        disabled={reassignDisabled}
                        loading={isLoading}
                        onClick={(): void => {
                            setIsLoading(true);
                            contactComment.length ? addTextAndSubmit(contactComment,
                                contact.contactId,
                                authorizerJwtTokens,
                                (matches) => onClickRestrictedDataMatch(matches),
                                () => reassignContact(),
                                () => onClickRestrictedDataValidationFailure()) :
                                reassignContact();
                        }}
                    >{i18n('REASSIGN_TO_AGENT')}</Button>
                </SpaceBetween>
            }>
                <FormField label={i18n('AGENT')}>
                    <AddText rows={1}
                             newText={toAgent}
                             setNewText={setToAgent}
                             addTextStatus={toAgentStatus}
                             setAddTextStatus={setToAgentStatus}
                             textAriaLabel={i18n('AGENT')}
                             restrictedDataMatchList={[]}
                             textPlaceholder={i18n('TYPE_AGENT_LOGIN')}
                             disabled={isLoading}
                    />
                </FormField>
                <FormField label={i18n('TRANSFER_CONTACT_COMMENTS')}>
                    <AddText rows={2}
                             newText={contactComment}
                             setNewText={setContactComment}
                             addTextStatus={contactCommentStatus}
                             setAddTextStatus={setContactCommentStatus}
                             textAriaLabel={i18n('TRANSFER_CONTACT_COMMENTS')}
                             restrictedDataMatchList={restrictedDataMatchList}
                             textPlaceholder={i18n('PROVIDE_ADDITIONAL_INFORMATION')}
                             disabled={isLoading}
                    />
                    <p>{i18n('ANNOTATION_WARNING')}</p>
                </FormField>

            </Form>
        </Container>
    );
};