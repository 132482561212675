export const es_ES = {
    "ABANDONED": "ABANDONADO por",
    "ABANDONED_BY_AGENT": "ABANDONADO por {agent}",
    "ACCESS_RESTRICTED_CONTENT": "Acceso restringido al contenido",
    "ACTION": "Acción",
    "ADD": "Añadir",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Información de contacto adicional",
    "ADD_ANNOTATION_FAILURE": "Error al añadir la anotación.",
    "ADD_ANNOTATION_INVALID": "Nota no válida. Asegúrate de que no haya datos restringidos en la anotación.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nueva anotación",
    "ADD_ANNOTATION_SUCCESS": "Anotación añadida correctamente.",
    "AGENT": "Agente",
    "AGENT_ANNOTATION_BADGE": "Agente",
    "AGENT_SELECTED_ISSUE": "Problema seleccionado por el agente",
    "AGENT_SELECTED_ISSUE_COPIED": "Se ha copiado el problema seleccionado por el agente.",
    "AMAZON_CONNECT_CALL_INFO": "Información de llamadas de Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID de contacto de Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID de contacto de Amazon Connect copiado",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANALYZED": "ANALIZADO por",
    "ANALYZED_BY_AGENT": "ANALIZADO por {agent}",
    "ANNOTATED_BY": "Anotado por {agentLogin}.",
    "ANNOTATIONS": "Anotaciones",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Las anotaciones no deben contener datos PII del cliente, como números de teléfono y direcciones de correo electrónico. Nuestro sistema automatizado trata de aplicarlo, pero a veces no distingue entre PII y otros identificadores. Proporciona el motivo por el que se necesita una anulación.",
    "ANNOTATION_WARNING": "Las anotaciones pueden estar sujetas a revisión por parte de los clientes según las normas de privacidad. No incluyas información personal ni opiniones en el campo de anotaciones.",
    "ATTACHMENTS_RESTRICTED": "Los archivos adjuntos están restringidos.",
    "ATTACHMENTS_TITLE": "Archivos adjuntos",
    "ATTACHMENT_DELETION_FAILED": "Error al eliminar los archivos adjuntos. Inténtalo de nuevo más tarde.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Se han eliminado correctamente los archivos adjuntos.",
    "AUTHENTICATING": "Autenticación iniciada. Esto puede abrir una nueva ventana.",
    "AUTHENTICATION_FAILURE": "Se ha producido un problema en la autenticación.",
    "AUTHENTICATION_MESSAGE": "Obteniendo nuevas credenciales. Esta ventana se cerrará en un par de segundos.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Atrás",
    "BCC_EMAIL_ADDRESS": "CCO",
    "BLURBS_USED_TITLE": "Blurbs usados",
    "CALL_LEG": "ID de tramo de llamada",
    "CALL_LEG_ID_COPIED": "ID de tramo de llamada copiado.",
    "CALL_RESTRICTED": "El contenido de la llamada está restringido.",
    "CALL_RETENTION_DELETED": "La grabación ha superado su periodo de retención y se ha eliminado.",
    "CALL_TITLE": "Llama al servicio de atención al cliente de Amazon",
    "CANCEL": "Cancelar",
    "CC_EMAIL_ADDRESS": "CC",
    "CF_LARGE_NUMBER_ALERT": "Este cliente tiene un gran número de contactos y no todos se han cargado. Los contactos siempre se cargan en la dirección “reciente a anterior” a partir de la fecha de fin. Cambia la fecha de fin para empezar a cargar desde otro momento.",
    "CHANNELS": "Canales",
    "CHAT_AGENT_CONNECTED": "{name} ha aceptado el chat.",
    "CHAT_CONTACT": "Contacto por chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} ha perdido la conexión a la red.",
    "CHAT_DISCONNECT_GENERIC": "{name} ya no está conectado.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} ha perdido la conexión a la red.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} ha perdido la conexión a la red.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} ha abandonado la conversación.",
    "CHAT_PARKED": "Este chat se ha aparcado",
    "CHAT_PARKED_NOTES": "Notas de aparcamiento",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} no está retenido.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} está retenido.",
    "CHAT_RESTRICTED": "El contenido del chat está restringido.",
    "CHAT_TITLE": "Chatea con el servicio de atención al cliente de Amazon",
    "CHAT_TRANSFER_NOTES": "Transferir notas",
    "CHILD_WORK_REQUESTS": "Solicitudes de trabajo creadas a partir de este contacto",
    "CHOOSE_OPTIONS": "Elegir opciones",
    "CLOSE": "Cerrar",
    "CLOSE_ALERT": "Cerrar alerta",
    "CLOSE_MODAL_BUTTON_TEXT": "Cerrar",
    "COLLAPSED": "Contraído",
    "CONFIRM": "Confirmar",
    "CONTACTS": "Contactos",
    "CONTACT_COLLAPSED": "Los detalles del contacto se han plegado.",
    "CONTACT_CREATION_FAILURE": "No se ha podido crear un nuevo contacto",
    "CONTACT_EXPANDED": "Detalles del contacto desplegados.",
    "CONTACT_HISTORY": "Historial de contactos",
    "CONTACT_ID": "Identificador del contacto",
    "CONTACT_ID_COPIED": "Identificador de contacto copiado.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contacto",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalaciones",
    "CONTACT_REASSIGNED_TO_AGENT": "El contacto se ha reasignado al agente.",
    "CONTACT_SELECT_CHECKBOX": "Casilla de verificación para seleccionar un contacto bloqueado",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalación",
    "CONTACT_TICKET": "Ticket de contacto",
    "CONTACT_TICKET_CONTENT": "Contenido del ticket",
    "CONTACT_TICKET_CREATE": "Crear ticket de contacto",
    "CONTACT_TICKET_CREATION_FAILURE": "Error al crear el ticket de contacto",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket de contacto creado",
    "CONTACT_TICKET_RESOLVER_GROUP": "Grupo de Resolver",
    "CONTACT_TICKET_SEVERITY": "Gravedad del ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "Identificador de carpeta SIM",
    "CONTACT_TICKET_SUBJECT": "Asunto del ticket",
    "CONTACT_TIMELINE": "Plazo de contacto",
    "CONTACT_TYPE": "Tipo de contacto",
    "CONTACT_WAS_TRANSFERRED": "El contacto se ha transferido.",
    "CONTENT": "Contenido",
    "CONTENT_RESTRICTED": "El contenido está restringido.",
    "COPY_AGENT_SELECTED_ISSUE": "Copiar problema seleccionado por el agente: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copiar el ID de contacto de Amazon Connect",
    "COPY_CALL_LEG_ID": "Copiar ID de tramo de llamada: {value}",
    "COPY_CONTACT_ID": "Copiar identificador de contacto: {value}",
    "COPY_CUSTOMER_ID": "Copiar identificador de cliente",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copiar el problema seleccionado por el cliente: {value}",
    "COPY_FAILURE": "Error al copiar.",
    "COPY_ID": "Copiar ID",
    "COPY_LINK": "Copiar enlace de carga de imagen",
    "COPY_ORDER_ID": "Copiar identificador del pedido: {value}.",
    "CREATE_CASE": "Crear caso",
    "CREATE_FOLLOW_UP": "Crear seguimiento",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Se ha creado correctamente el seguimiento, pero no se ha podido añadir la anotación correspondiente. Intenta añadir la anotación manualmente.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Especifica el método de contacto preferido del cliente.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "No se puede crear ningún seguimiento con una fecha y hora de vencimiento anteriores.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Especifica la fecha y hora de vencimiento de la acción de seguimiento.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Zona horaria",
    "CREATE_FOLLOW_UP_FAILURE": "No se ha podido crear el seguimiento.",
    "CREATE_FOLLOW_UP_INVALID": "Seguimiento no válido. Asegúrate de que no haya datos restringidos.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Introduce la acción de seguimiento.",
    "CREATE_FOLLOW_UP_SUCCESS": "Se ha creado correctamente el seguimiento.",
    "CREATE_WORK_REQUEST": "Crear solicitud de trabajo",
    "CROSS_MARKETPLACE_QUEUES": "Colas de sitios web cruzadas",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Error al transferir el contacto porque ya está resuelto.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "La operación ha fallado porque el contacto no está en estado válido.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Error en la operación porque no se ha encontrado el contacto",
    "CSALT_ERROR_INTERNAL_ERROR": "Error al bloquear contacto",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Error al bloquear un contacto debido a argumentos no válidos",
    "CSALT_ERROR_INVALID_INPUT": "La operación ha fallado debido a entradas no válidas.",
    "CSALT_ERROR_INVALID_QUEUE": "Error al transferir el contacto porque la cola seleccionada no es válida.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Error en la operación porque el contacto está bloqueado para otro agente.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Error al bloquear el contacto debido a un error de dependencia",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Error al transferir el contacto porque no se encontró la cola en el servicio backend.",
    "CSALT_ERROR_UNKNOWN": "La operación ha fallado debido a un error inesperado.",
    "CURRENT_CONTACT": "Contacto actual",
    "CUSTOMER": "Cliente",
    "CUSTOMER_FOLLOWUPS": "Seguimientos del cliente",
    "CUSTOMER_FOLLOWUPS_FAILURE": "No se han podido obtener los seguimientos del cliente",
    "CUSTOMER_ID_COPIED": "Identificador de cliente copiado",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "El contenido del cliente está restringido. (No hay contactos bloqueados).",
    "CUSTOMER_SELECTED_ISSUE": "Problema seleccionado por el cliente",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "El problema seleccionado por el cliente se ha copiado.",
    "CUSTOMER_TIMEZONE": "Las marcas de tiempo se muestran en la zona horaria del cliente",
    "DATE": "Fecha",
    "DATE_PICKER_NEXT_MONTH": "Mes siguiente",
    "DATE_PICKER_PREVIOUS_MONTH": "Mes anterior",
    "DATE_PICKER_TODAY": "Hoy",
    "DECODING_AUDIO": "Descodificando audio",
    "DELETE_ATTACHMENTS": "Eliminar archivos adjuntos de contactos",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Eliminar archivos adjuntos",
    "DELIVERY_STATUS": "Estado de la entrega",
    "DISABLE_CONTENT_FILTER": "Desactivar el filtro de contenido",
    "DOWNLOAD": "Descargar",
    "DURATION": "Duración",
    "EMAIL": "Correo electrónico",
    "EMAIL_ADDRESS_NOT_ALLOWED": "La dirección de correo electrónico del destinatario no está permitida.",
    "EMAIL_BODY": "Cuerpo del correo electrónico",
    "EMAIL_CONTACT": "Contacto por correo electrónico.",
    "EMAIL_HEADER": "Encabezado",
    "EMAIL_QUEUE_TITLE": "Cola de correo electrónico",
    "EMAIL_RESTRICTED": "El contenido del correo electrónico está restringido.",
    "EMAIL_SENDING_FAILURE": "No se ha podido enviar el correo electrónico.",
    "EMAIL_SUBJECT": "Asunto del correo electrónico",
    "EMAIL_TITLE": "Envía un correo electrónico al servicio de atención al cliente de Amazon",
    "END": "Fin",
    "END_DATE": "Fecha de fin",
    "ERROR": "Error",
    "ERROR_FAILED_TO_LOAD_DATA": "Error al cargar datos.",
    "ESCALATION_CONTACT_HANDLING": "Manejo del contacto de escalación",
    "ESCALATION_TAB_LEAD_LOGIN": "Inicio de sesión de contacto: {agentLogin}",
    "EXPANDED": "Expandido",
    "FAILED_TO_CREATE_WORK_REQUEST": "Error al crear la solicitud de trabajo",
    "FAILED_TO_FETCH_SYSKA": "No se han podido obtener avisos SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "No se ha podido reasignar el contacto al agente.",
    "FETCHED_NUMBER_CONTACTS": "Se han obtenido {number} contactos.",
    "FILTER": "Filtrar",
    "FINDER": "Buscador",
    "FOLLOW_UPS_COLLAPSED": "Seguimientos plegados",
    "FOLLOW_UPS_EXPANDED": "Seguimientos desplegados",
    "FOLLOW_UP_ALERT": "tiene un seguimiento cuya fecha límite es el {dueDate} y que ha sido creado por {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Método de contacto",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "El método del contacto está restringido.",
    "FOLLOW_UP_DUE": "Seguimiento vencido",
    "FOLLOW_UP_DUE_DATE": "Fecha de vencimiento",
    "FOLLOW_UP_NOTE": "Nota",
    "FOLLOW_UP_NO_CONTACT_METHOD": "No hay método de contacto",
    "FOLLOW_UP_NO_NOTE": "La nota está vacía",
    "FOLLOW_UP_NO_TICKET": "Sin ticket",
    "FOLLOW_UP_OWNER": "Propietario",
    "FOLLOW_UP_STATUS": "Estado",
    "FOLLOW_UP_STATUS_OPEN": "Abrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Resuelto",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Seguimientos",
    "FROM": "De",
    "GENERATE_LINK": "Generar enlace",
    "HAS_OVERRIDE_TRY_AGAIN": "Anulación de filtro presente. Inténtalo de nuevo.",
    "HISTORY": "Historial",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "Enlace adjunto de imagen compartido con el cliente",
    "INPUT_CHARACTERS_REMAINING": "Máximo de {char_max} caracteres ({char_remain} restantes)",
    "INSERTED": "INSERTADO por",
    "INSERTED_BY_AGENT": "INSERTADO por {agent}",
    "INTERNAL_ERROR": "Error interno",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Se ha detectado un error interno. Si el error persiste, envíanos un parte explicando el problema.",
    "INVALID_CONTACT": "Contacto no válido.",
    "INVALID_DATA_MATCH": "El sistema ha hecho una clasificación de datos no válida.",
    "INVALID_TIMESTAMP": "Marca de tiempo no válida.",
    "IN_MARKETPLACE_QUEUES": "En colas de sitios web",
    "ISSUE": "Problema",
    "ISSUE_CODE": "Código de problema",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Ya se ha atendido y resuelto en un contacto anterior",
    "ISSUE_CODE_DESCRIPTION": "Selecciona el motivo por el que se duplican los contactos seleccionados",
    "ISSUE_CODE_EXACTLY_SAME": "Exactamente igual que un contacto anterior",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Gestionado y resuelto por otro agente de Servicio de atención al cliente por chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Gestionado y resuelto por otro agente de Servicio de atención al cliente por correo electrónico",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Gestionado y resuelto por otro agente de Servicio de atención al cliente telefónico",
    "ITEMS_CONTACTED_ABOUT": "Productos por los que se ha establecido contacto",
    "I_UNDERSTAND": "Entiendo",
    "KNOWN_ISSUES": "Problemas conocidos",
    "LEARN_MORE": "Más información",
    "LINK_COPIED": "Enlace copiado",
    "LOADING": "Cargando datos.",
    "LOADING_PREVIEW_TEXT": "Cargando vista previa",
    "LOADING_RECORDING": "Cargando grabación",
    "LOAD_MORE": "Cargar más",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Cargar más anotaciones.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Carga más contactos para este cliente.",
    "LOCKED": "Bloqueado",
    "LOCKED_BY": "Bloqueado por",
    "LOCKED_BY_AGENT": "BLOQUEADO por {agent}",
    "LOCK_CONTACT": "Bloquear",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Error al bloquear el contacto, el usuario no está autorizado",
    "LOCK_CONTACT_SUCCESSFUL": "Contacto bloqueado en",
    "MISSING_ORIGINAL_CONTACT_ID": "Introduce un ID de contacto original válido",
    "MODAL_NO_BUTTON_TEXT": "No",
    "MODAL_YES_BUTTON_TEXT": "Sí",
    "NEXT": "Siguiente",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "No hay ningún problema seleccionado por el agente.",
    "NO_ANNOTATED_BY": "Anotado por un usuario desconocido.",
    "NO_ANNOTATIONS": "No hay anotaciones disponibles.",
    "NO_ATTACHMENTS": "No hay archivos adjuntos.",
    "NO_CHAT": "El chat está vacío.",
    "NO_CHILD_WORK_REQUESTS": "No se han creado solicitudes de trabajo a partir de este contacto",
    "NO_CONTACT": "No hay ningún contacto disponible",
    "NO_CONTACTS_SELECTED": "Selecciona contactos para procesarlos como no deseados",
    "NO_CONTENT": "Sin contenido",
    "NO_CUSTOMER_SELECTED_ISSUE": "No hay ningún problema seleccionado por el cliente.",
    "NO_DELIVERY_STATUS": "No hay estado de la entrega",
    "NO_EMAIL_BODY": "El correo electrónico está vacío.",
    "NO_EMAIL_HEADER": "No hay encabezado de correo.",
    "NO_EMAIL_QUEUE": "No hay ninguna cola de correo electrónico asociada a este contacto.",
    "NO_ITEMS_CONTACTED_ABOUT": "No hay productos por los que se haya contactado.",
    "NO_RESULTS": "No se han encontrado resultados.",
    "NO_SCREEN_POP": "No hay información de pantalla emergente",
    "NO_SENT_FROM": "No hay dirección de origen.",
    "NO_SENT_TO": "No hay dirección de envío",
    "NO_SUBJECT": "La línea de asunto está vacía.",
    "NO_SYSKA_ALERTS": "No hay alertas SYSKA",
    "NO_USED_BLURBS": "No se han usado textos estándar para este contacto.",
    "NO_VIEW": "No hay contacto ni cliente que ver.",
    "NO_WORK_CATEGORY": "No hay ninguna categoría de trabajo asociada a este contacto.",
    "NUMBER_OF_CONTACTS": "Contactos cargados",
    "OLDEST_LOADED_CONTACT": "Contacto cargado más antiguo",
    "OPEN": "Abierto",
    "OPENS_IN_A_NEW_TAB": "Se abre en una nueva pestaña",
    "ORDER_ID_COPIED": "Se ha copiado el identificador del pedido.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Introduce el ID de contacto original",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Cuadro de texto para introducir el ID de contacto original",
    "OTHER": "Otros",
    "OUTBOUND_EMAIL_BCC": "y CCO",
    "OUTBOUND_EMAIL_CC": "con CC",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "El contenido de notificaciones se ha ocultado.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Se ha ampliado el contenido de notificaciones.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Se ha alcanzado el número máximo de resultados. Reduce el intervalo de fechas y vuelve a intentarlo.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "El proceso actual requiere contenido PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Coincidencia no válida como número de teléfono, dirección de correo electrónico u otra PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Coincidencia no válida como tarjeta de crédito o IBAN.",
    "OVERRIDE_REASON_PROMPT": "Las anotaciones no deben contener datos PII del cliente, como números de teléfono y direcciones de correo electrónico. Nuestro sistema automatizado trata de aplicarlo, pero a veces no distingue entre PII y otros identificadores. Proporciona el motivo por el que se necesita una anulación.",
    "PAPYRUS_SESSION_EXPLANATION": "Especifica tu tipo de sesión de Papiro para que podamos aplicar las restricciones de seguridad adecuadas y optimizar el UX para tus necesidades.",
    "PAPYRUS_SESSION_TYPE": "Tipo de sesión de Papyrus",
    "PAUSE": "Pausar",
    "PHONE_CONTACT": "Contacto por teléfono.",
    "PLAY": "Reproducir",
    "PLAYBACK_SPEED": "Velocidad de reproducción",
    "POSTAL_MAIL_TITLE": "Envía un correo postal al servicio de atención al cliente de Amazon",
    "PREPARING_RECORDING": "Preparando grabación",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Error al generar la vista previa del contacto",
    "PREVIOUS": "Anterior",
    "PRE_TRANSLATED_REPLY_EMAIL": "Correo electrónico de respuesta pretraducido",
    "PRE_TRANSLATION_REPLY_TEXT": "Respuesta escrita por CSA antes de la traducción de Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Procesar acciones",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Procesar como duplicado",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Procesar como no deseado",
    "PROCESS_CONTACT_AS_DUPLICATE": "Resolver contactos seleccionados como duplicados",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "¿Estás seguro de que deseas procesar los contactos seleccionados como spam?",
    "PROCESS_DUPLICATE_FAILURE": "Error al resolver los contactos seleccionados como duplicados",
    "PROCESS_DUPLICATE_SUCCESS": "Se han resuelto correctamente los contactos seleccionados como duplicados",
    "PROCESS_SPAM_FAILURE": "No se han podido transferir los contactos seleccionados al correo no deseado",
    "PROCESS_SPAM_SUCCESS": "Se han transferido correctamente los contactos seleccionados al correo no deseado",
    "PROVIDE_ADDITIONAL_INFORMATION": "Proporciona información adicional relevante para este contacto",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Información confidencial eliminada del contacto:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Añadir palabra a la lista de eliminación",
    "PURGE_BUTTON_TEXT": "Eliminar",
    "PURGE_CONFIRMATION_CHECK": "He comprobado los campos anteriores y confirmo que el texto contiene información confidencial.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Confirmo que el archivo adjunto contiene información confidencial del cliente.",
    "PURGE_CONFIRMATION_UNCHECKED": "Confirma la casilla de verificación.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Error al eliminar el contacto",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Información eliminada correctamente del contacto",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} no es un motivo válido para eliminar información",
    "PURGE_REASON_FINANCIAL_INFO": "Información financiera",
    "PURGE_REASON_OTHER": "Otra información confidencial",
    "PURGE_REASON_PII": "Información de identificación personal",
    "PURGE_REASON_PLACEHOLDER": "Introduce el motivo de la eliminación",
    "PURGE_REASON_TEXT": "Motivo de la eliminación",
    "PURGE_REMOVE_LABEL": "Eliminar",
    "PURGE_SELECTOR_PLACEHOLDER": "Selecciona el motivo para eliminar la información confidencial",
    "PURGE_SENSITIVE_INFO": "Eliminar información confidencial",
    "PURGE_WORD_NOT_FOUND": "No se ha encontrado la palabra en el texto del contacto",
    "PURPOSE_AUDITING": "Auditorías de control de calidad",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Flujo de trabajo de la oficina central",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Esta es la sesión para obtener datos de contacto de los flujos de trabajo de la oficina central. Por ejemplo, archivos adjuntos para incluir en tickets o SIM.",
    "PURPOSE_COACHING": "Coaching de la revisión de contactos",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Investigación de cumplimiento",
    "PURPOSE_CURATION": "Mantenimiento de contactos",
    "PURPOSE_CURATION_DESCRIPTION": "Esta es la sesión de la mantenimiento de contactos de C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Esta es la sesión para auditar los contactos y evaluar su calidad.",
    "PURPOSE_DESCRIPTION_COACHING": "Esta es la sesión para revisar los contactos de los agentes de los cuales eres coach del portal de rendimiento.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Esta es la sesión para las investigaciones de Supervisión de actividades sospechosas.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Esta es la sesión para manejar los contactos de escalación. Esto permite acceder a los datos de contacto de cualquier cliente.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Esta es la sesión para la gestión de contactos en directo.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "El objetivo es eliminar información confidencial de un contacto",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Esta es la sesión para revisar la precisión del modelo de aprendizaje automático.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Esta es la sesión para revisar tus propios contactos anteriores.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Esta es la sesión de propósito general predeterminada.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Manejo del contacto de escalación",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Eliminar información confidencial",
    "REASON_FOR_PURGE": "Motivo de la eliminación",
    "REASON_FOR_PURGE_DESCRIPTION": "Motivo para eliminar los archivos adjuntos",
    "REASON_FOR_PURGE_MISSING": "Introduce el motivo por el que se eliminan los archivos adjuntos.",
    "REASSIGN": "REASIGNAR por",
    "REASSIGNED_BY_AGENT": "REASIGNADO por {agent}",
    "REASSIGN_TO_AGENT": "Reasignar a agente",
    "RECORDING": "Grabación",
    "RELOADING_CONTENT": "Recargando el contenido.",
    "RELOAD_LIST": "Volver a cargar la lista",
    "REPLIED": "RESPONDIDO por",
    "REPLIED_BY_AGENT": "RESPONDIDO por {agent}",
    "REPLY_BY": "Responder antes del",
    "REPLY_BY_EMAIL": "Responder por correo electrónico",
    "REPLY_BY_PHONE": "Responder por teléfono",
    "REPLY_RESTRICTED": "El contenido de la respuesta está restringido.",
    "REQUEST": "Solicitar",
    "REQUEST_ACCESS_INSTRUCTIONS": "Proporciona el motivo por el que es necesario acceder al contenido restringido. (Estas solicitudes se supervisan y el uso indebido puede tener consecuencias graves).",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Solicitar acceso al contenido filtrado",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Solicitar acceso al contenido restringido",
    "REQUEST_NOT_GRANTED": "No se ha concedido la solicitud.",
    "REQUEST_OVERRIDE": "Solicitar anulación",
    "RESEND": "Volver a enviar",
    "RESEND_FAILED": "Error al reenviar el correo electrónico.",
    "RESEND_SUCCESS": "Se ha reenviado correctamente el correo electrónico.",
    "RESEND_WAIT_WARNING": "Tienes que esperar {wait_mins} minutos para volver a enviar.",
    "RESEND_WARNING": "¿Seguro que quieres volver a enviar el correo electrónico al cliente?",
    "RESET": "Restablecer",
    "RESOLVED": "Resuelto",
    "RESOLVED_BY": "Resuelto por",
    "RESOLVED_BY_AGENT": "RESUELTO por {agent}",
    "RESTRICTED_DATA_MATCH": "{match} tipo de coincidencia {type}",
    "RESTRICTED_INFORMATION": "INFORMACIÓN RESTRINGIDA",
    "RETRY": "Volver a intentar",
    "ROLE": "Rol",
    "SCREEN_POP_DESCRIPTION_TITLE": "Descripción",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "No",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Sí",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Mostrado al agente",
    "SCREEN_POP_TITLE": "Información de pantalla emergente",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Se ha mostrado al agente una pantalla emergente de autenticación y se ha usado.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Se ha mostrado una pantalla emergente de autenticación al agente.",
    "SCREEN_POP_TYPE_CALLER_ID": "Se ha mostrado al agente una pantalla emergente de identificador de llamada y se ha usado.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Se ha mostrado una pantalla emergente de identificador de llamada al agente.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Se ha mostrado una pantalla emergente no autenticada y se ha usado.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Se ha mostrado una pantalla emergente no autenticada al agente.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "El cliente es desconocido; no se ha proporcionado ninguna pantalla emergente.",
    "SCREEN_POP_TYPE_OTHER": "Otro",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "No",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Sí",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Usado por el agente",
    "SDS_TOOLS": "Herramientas de asistencia sobre envíos y entregas",
    "SEARCH": "Buscar",
    "SELECTED_ROLE": "Rol seleccionado",
    "SELECTED_VIEW": "Vista seleccionada",
    "SELECT_CHANNELS": "Seleccionar canales",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Para seleccionar el código de problema para marcar los contactos seleccionados como duplicados",
    "SELECT_TRANSFER_DESTINATION": "Seleccionar destino de la transferencia",
    "SELECT_WORK_REQUEST_QUEUE": "Seleccionar cola de solicitud de trabajo",
    "SENDER_EMAIL_ADDRESS": "Remitente",
    "SENDING_EMAIL": "Enviando correo electrónico",
    "SEND_EMAIL": "Enviar correo electrónico",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "¿Seguro que quieres enviar un enlace de carga de imagen al cliente?",
    "SEND_OUTBOUND_EMAIL": "Enviar correo electrónico",
    "SEND_OUTBOUND_EMAIL_WARNING": "¿Seguro que deseas enviar un correo electrónico a",
    "SENT_FROM": "Se ha enviado desde",
    "SENT_OUTBOUND_EMAIL": "Se ha enviado un correo electrónico. Se ha creado un contacto nuevo",
    "SENT_TO": "Enviado a",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal del transportista",
    "SKILL": "Skill",
    "SKIP_SILENCES": "Omitir silencios",
    "SMS": "SMS",
    "SMS_LINK": "Enlace a SMS",
    "SOURCE_TEXT": "Texto de origen",
    "SP_BOTTOM": "Inferior",
    "SP_POSITION": "Posición del panel dividido",
    "SP_PREFERENCES": "Preferencias de panel dividido",
    "SP_RESIZE": "Cambiar el tamaño del panel dividido",
    "SP_SIDE": "Lateral",
    "START": "Comenzar",
    "STATE_TRANSITIONS": "Transiciones de estado",
    "STATUS": "Estado",
    "SUBJECT": "Asunto",
    "SYSKA_ALERTS": "Alertas SYSKA",
    "TEXT_TO_PURGE": "Texto por eliminar",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Las cookies de terceros deben estar activadas.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "No se permiten datos PII de clientes como números de teléfono y direcciones de correo electrónico al crear un ticket. Nuestro sistema automatizado trata de aplicarlo, pero a veces no distingue entre PII y otros identificadores. Indica el motivo por el que se necesita una anulación.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Tiempo de espera: no se ha podido cargar la grabación",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Usa el formato de 24 horas.",
    "TO": "Hasta",
    "TO_EMAIL_ADDRESS": "Para",
    "TRANSFER": "Transferir",
    "TRANSFERRED": "TRANSFERIDO por",
    "TRANSFERRED_BY_AGENT": "TRANSFERIDO por {agent}",
    "TRANSFERRED_CHAT_TITLE": "Chat transferido al servicio de atención al cliente de Amazon",
    "TRANSFERRING": "Transfiriendo...",
    "TRANSFER_CONTACT": "Transferir contacto",
    "TRANSFER_CONTACT_COMMENTS": "Anotación opcional",
    "TRANSFER_FAILED": "Error en la transferencia",
    "TRANSFER_FOLLOW_UP": "Transferir seguimiento",
    "TRANSFER_FOLLOW_UP_FAILED": "No se ha podido transferir el seguimiento.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "No se ha podido transferir el seguimiento debido a un nuevo inicio de sesión no válido.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "No tienes permiso para transferir este seguimiento.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Introduce el nombre de inicio de sesión del nuevo propietario. Ejemplo: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transferir el seguimiento anterior a:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Se ha transferido correctamente el seguimiento.",
    "TRANSLATED_TEXT": "Texto traducido",
    "TRANSLATION": "Traducción",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} no es un nombre de cola válido.",
    "TYPE_AGENT_LOGIN": "Escribe el inicio de sesión del agente",
    "TYPE_EMAIL_ADDRESS": "Escribe la dirección de correo electrónico",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Escribe varias direcciones de correo electrónico separadas por comas",
    "TYPE_QUEUE_NAME": "Nombre de la cola de tipos",
    "UNKNOWN_CONTACT_TYPE": "Tipo de contacto desconocido.",
    "UNLOCKED": "Desbloqueado",
    "UNRESOLVED": "No resuelto",
    "URGENT_SYSKA_ALERTS": "Avisos SYSKA urgentes",
    "USE": "Usar",
    "USER_TIMEZONE": "Las marcas de tiempo se muestran en tu zona horaria local",
    "USE_CONTACT_FINDER": "Usar buscador de contactos",
    "VIEW": "Ver",
    "WARNING": "Atención",
    "WELCOME_MESSAGE": "Te damos la bienvenida al sitio web estático de Papiro.",
    "WHAT_IS_PAPYRUS": "¿Qué es Papiro?",
    "WITH_THE_ANNOTATION": "con la anotación",
    "WORK_CATEGORY_TITLE": "Categoría de trabajo",
    "WORK_REQUEST": "Solicitud de trabajo",
    "WORK_REQUEST_CONTENT": "Contenido de la solicitud de trabajo",
    "WORK_REQUEST_CREATED": "Solicitud de trabajo creada",
    "WORK_REQUEST_FOR_CONTACT": "Solicitud laboral de contacto",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "No se permiten datos PII de clientes, como números de teléfono y direcciones de correo electrónico, al crear una solicitud de trabajo. Nuestro sistema automatizado trata de aplicarlo, pero a veces no distingue entre PII y otros identificadores. Indica el motivo por el que se necesita una anulación.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Estás a punto de hacer la transferencia a la cola"
}