import React, {useEffect, useState} from "react";
import Icon, {IconProps} from "@amzn/awsui-components-react/polaris/icon";
import Box from "@amzn/awsui-components-react/polaris/box";
import IntlManager from "../i18n/IntlManager";
import {Container} from "@amzn/awsui-components-react/polaris";
import {colorBackgroundContainerHeader} from "@amzn/awsui-design-tokens/polaris";

interface OutboundNotificationDisplayProps {
    initExpanded: boolean;
    header: JSX.Element;
    pinnedContent: JSX.Element;
    expandableContent: JSX.Element;
    collapsedStringId?: string;
    expandedStringId?: string;
    onToggleExpand?: (expanded: boolean) => void;
}

export const ExpandableDisplay: React.FC<OutboundNotificationDisplayProps> = (
    {
        initExpanded, header, pinnedContent,
        expandableContent, collapsedStringId, expandedStringId,onToggleExpand
    }) => {

    const [isExpanded, setIsExpanded] = useState<boolean>(initExpanded);
    const [expandedStatusIcon, setExpandedStatusIcon] = useState<IconProps.Name>("caret-right-filled");
    const [expandedStatusLabel, setExpandedStatusLabel] = useState<string>(IntlManager.sharedManager.formatMessage(collapsedStringId || "COLLAPSED"));

    useEffect(() => {
        if (isExpanded) {
            setExpandedStatusIcon("caret-down-filled");
            setExpandedStatusLabel(IntlManager.sharedManager.formatMessage(expandedStringId || "EXPANDED"));
        } else {
            setExpandedStatusIcon("caret-right-filled");
            setExpandedStatusLabel(IntlManager.sharedManager.formatMessage(collapsedStringId || "COLLAPSED"));
        }
    }, [isExpanded, collapsedStringId,  expandedStringId]);

    const toggleExpansion = (): void => {
        setIsExpanded(!isExpanded);
        if(onToggleExpand) {
            onToggleExpand(!isExpanded);
        }

    };

    const handleKeyPress = (event): void => {
        if (event.key === 'Enter') {
            toggleExpansion();
        }
    };

    return (
        <Container
            disableContentPaddings
            disableHeaderPaddings
            header={
                <div role="button"
                     tabIndex={0}
                     onKeyPress={(e): void => handleKeyPress(e)}
                     onClick={(): void => toggleExpansion()}
                     style={{backgroundColor: "lightgrey", cursor: "pointer", paddingTop: "0.5rem", paddingRight:"0.8rem"}}
                     className="awsui-util-pl-n awsui-util-pr-s awsui-util-pv-n">
                    <Box float="left" display="inline-block">
                <span aria-label={expandedStatusLabel}>
                     <Icon name={expandedStatusIcon}/>
                </span>
                    </Box>
                    {header}
                </div>
            }
            footer={isExpanded ? expandableContent : null}
        >
            <div style={{backgroundColor: colorBackgroundContainerHeader, padding:"2rem"}}>
                {pinnedContent}
            </div>
        </Container>);
};