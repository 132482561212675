export const ar_AE = {
    "contact_event_PV_added": "تم إضافة القيمة الحالية #{pvId} إلى الحساب.",
    "contact_event_PV_locked": "تم قفل القيمة الحالية #{pvId}.",
    "contact_event_PV_temporary_locked": "تم قفل القيمة الحالية #{pvId} مؤقتًا.",
    "contact_event_PV_unlocked": "تم إلغاء قفل القيمة الحالية #{pvId}.",
    "contact_event_a_to_z_guarantee_requested_gwp": "المطالبة بضمان من أمازون للطلب {order_id}.",
    "contact_event_access_code_added": "تمت إضافة رمز الوصول",
    "contact_event_account_whitelisted": "تم إدراج الحساب في القائمة البيضاء.",
    "contact_event_amazon_video_subscription_auto_renew_settings": "الاشتراك في خدمة فيديو أمازون يُجدّد تلقائيًا",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "النموذج المرسل: نموذج تصعيد DSL\nمعرف جلسة حالة الاستخدام: {usecaseSessionID}\nمحطة التوصيل: {deliveryStationField}\nنوع الطلب: {requestTypeField}\nالرقم التعريفي لجهة الاتصال: {contactIdField}\nرقم الطلب: {orderIdField}\nعنوان البريد الإلكتروني للعميل: {customerEmailField}\nرقم هاتف العميل: {customerPhoneNumberField}\nTBA: {tbasField}\nرمز صلاحية الوصول (إذا كان قابلاً للتطبيق): {accessCodeField}\nملخص المشكلة: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "نماذج تصعيد DSL المرسلة: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "النموذج المرسل: نموذج تقييم DSP DA\nمعرف جلسة حالة الاستخدام: {usecaseSessionID}\nرمز محطة التوصيل: {deliveryStationField}\nالرقم التعريفي لجهة الاتصال: {contactIdField}\nالرقم التعريفي لحساب العميل: {customerIdField}\nالرقم التعريفي لناقل DA: {driverIdField}\nتاريخ الحدث (س س س س-ش ش-ي ي): {dateOfOccurrenceField}\nوقت الحدث (س س:د د): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nمصدر التقييم: {sourceOfFeedbackField}\nتفاصيل التقييم: {issueField}\nتفاصيل إضافية: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "نماذج تقييم DSP DA المرسلة: ({count})",
    "contact_event_audible_credit_deleted": "{amount} أزيل الاعتماد (الاعتمادات) من الحساب.",
    "contact_event_audible_credit_restored": "تمت استعادة {amount} رصيد(أرصدة) إلى الحساب.",
    "contact_event_audible_credit_restored_batched": "تمت استعادة {amount} رصيد(أرصدة) إلى الحساب.({count})",
    "contact_event_audible_extra_credit_purchased": "تم شراء 3 أرصدة إضافية.",
    "contact_event_audible_extra_credit_purchased_batched": "تم شراء 3 أرصدة إضافية.({count})",
    "contact_event_audible_wizard_invoked": "تم الوصول إلى {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "تم الوصول إلى {wizardName}. ({count})",
    "contact_event_channels_auto_renew_off": "قامت CS بإلغاء تفعيل التجديد التلقائي لاشتراك قنوات PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "قامت CS بإلغاء تفعيل التجديد التلقائي عبر Astro.",
    "contact_event_channels_full_refund": "قامت CS بإلغاء اشتراك قنوات PV {subscriptionid} مع استرداد المبلغ بالكامل.",
    "contact_event_channels_full_refund_static": "قامت CS بإلغاء الاشتراك مع استرداد المبلغ بالكامل عبر Astro.",
    "contact_event_channels_no_refund": "ألغت CS اشتراك قنوات PV {subscriptionid} مع عدم رد الأموال.",
    "contact_event_channels_no_refund_static": "ألغت CS الاشتراك مع عدم رد الأموال عبر Astro.",
    "contact_event_channels_workflow_launch": "أطلقت CS سير العمل إلغاء Astro لاشتراك قنوات PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "أطلقت CS سير عمل إلغاء Astro.",
    "contact_event_coins_adjust": "نوع التعديل: {type} <br />\nالسبب: {reason} <br />\nالمبلغ: {amount} <br />\nالتعليقات: {comment} <br />",
    "contact_event_contact_was_initiated_from": "تم بدء الاتصال من ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_csc_sds_override_geo_fence_submit": "تجاوز طلب السياجات الجغرافية المقدم لرقم التتبع {trackingId} السبب: {overrideReason} صفحة: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "تجاوز طلب السياجات الجغرافية المقدم لرقم التتبع {trackingId} السبب: {overrideReason} صفحة: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "تم تحديث الرمز الجغرافي. نوع الرمز الجغرافي : {geocodeType}، معرف التتبع: {trackingId}، معرف العنوان: {addressId}، سبب التجاوز: {overrideReason}، معرف الصفحة: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "تم تحديث الرمز الجغرافي. نوع الرمز الجغرافي : {geocodeType}، سبب التجاوز: {overrideReason}، معرف التتبع: {trackingId}، معرف العنوان: {addressId} ومعرف الصفحة: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "تم تمرير مصادقة العميل",
    "contact_event_delete_alexa_voice_profile": "تم حذف ملف تعريف اليكسا الصوتي لـ SpeakerID: {speakerId}، وcustomerID : {customerID} وحالة: {status}",
    "contact_event_delete_data_alexa_comms": "حذف ملف تعريف اتصالات اليكسا لـ commsID: {commsId}، وCustomerID: {CustomerID} الحالة: {status}",
    "contact_event_hard_delete_from_yvl": "تمت إزالة ASIM {asin} بشكل دائم من مكتبة الفيديو الخاصة بك.",
    "contact_event_idle_chat_disconnect": "تم قطع اتصال المحادثة غير النشطة",
    "contact_event_idle_chat_disconnect_batched": "تم قطع اتصال المحادثة غير النشطة ({count})",
    "contact_event_live_assist_launched_batched": "إطلاق مشاركة شاشة Mayday للأجهزة ({count}).",
    "contact_event_lock_dash_order": "تم تأمين أرقام تعريف أمازون القياسية التالية للجهاز {dsn}: {asins}.",
    "contact_event_music_auto_renew_off": "ألغت CS تفعيل التجديد التلقائي لاشتراك الموسيقى {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "ألغت CS تفعيل التجديد التلقائي لاشتراك الموسيقى عبر Astro.",
    "contact_event_music_full_refund": "ألغت CS اشتراك الموسيقى {subscriptionid} مع استرداد المبلغ بالكامل",
    "contact_event_music_full_refund_static": "ألغت CS اشتراك الموسيقى مع استرداد المبلغ بالكامل عبر Astro.",
    "contact_event_music_no_refund": "ألغت CS اشتراك الموسيقى {subscriptionid} بدون إرجاع المبلغ.",
    "contact_event_music_no_refund_static": "ألغت CS اشتراك الموسيقى بدون إرجاع المبلغ عبر Astro.",
    "contact_event_music_unlimited_auto_renew_off": "قامت خدمة العملاء بإيقاف التجديد التلقائي لاشتراك أمازون ميوزك انليميتد {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "قامت خدمة العملاء بإلغاء اشتراك أمازون ميوزك انليميتد {subscriptionid} مع استرداد المبلغ بالكامل.",
    "contact_event_music_unlimited_no_refund": "قامت خدمة العملاء بإلغاء اشتراك أمازون ميوزك انليميتد {subscriptionid} بدون استرداد الأموال.",
    "contact_event_music_unlimited_partial_refund": "قامت خدمة العملاء بإلغاء اشتراك أمازون ميوزك انليميتد {subscriptionid} مع استرداد المبلغ بشكل جزئي.",
    "contact_event_music_unlimited_workflow_launch": "أطلقت خدمة العملاء سير عمل إلغاء Astro لاشتراك أمازون ميوزك انليميتد {subscriptionid}.",
    "contact_event_music_workflow_launch": "أطلقت CS سير عمل إلغاء Astro لاشتراك الموسيقى {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "أطلقت CS سير عمل إلغاء Astro لميوزك انليميتد.",
    "contact_event_pennywise_promo_applied": "تم تطبيق عرض بيني وايز على DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "تم تطبيق عروض بيني وايز على DSN: ({count})",
    "contact_event_rosetta_chat": "‎#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "اسم DP: {driverName}، \nاسم المحطة: {stationName}، \nرقم التتبع {trackingId} و \nرقم الطلب: {orderId}\nمعرف UsecaseSession: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "تم فتح أداة الخريطة لرقم التتبع {trackingId}",
    "contact_event_sds_package_repick": "بدأ المشارك إعادة اختيار معرف التتبع {trackingId}",
    "contact_event_sim_ticket_created": "تم إنشاء SIM",
    "contact_event_subscription_astro_workflow_launch": "بدأت خدمة العملاء سير عمل إلغاء أسترو لاشتراك {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_auto_renew_off": "قامت خدمة العملاء بإلغاء تفعيل التجديد التلقائي لاشتراك {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "قامت خدمة العملاء بإلغاء اشتراك {subscriptionType} {subscriptionId} مع استرداد المبلغ بالكامل.",
    "contact_event_subscription_cancel_no_refund": "قامت خدمة العملاء بإلغاء اشتراك {subscriptionType} {subscriptionId} مع عدم إرجاع المبلغ.",
    "contact_event_subscription_cancel_partial_refund": "قامت خدمة العملاء بإلغاء اشتراك {subscriptionType} {subscriptionId} مع استرداد جزئي للمبلغ.",
    "contact_event_unlock_dash_order": "تم إلغاء تأمين أرقام تعريف أمازون القياسية التالية للجهاز {dsn}: {asins}.",
    "contact_event_weekend_delivery_preference_changed": "تم تحديث تفضيلات التسليم في عطلة نهاية الأسبوع"
}