import React, {useContext, useState} from 'react';
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {
    Contact,
    CustomerAccessLevel,
    Purpose,
} from "./Contact.types";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import ContactOrders from "./ContactOrders";
import Issues, {IssueSelector} from "./Issues";
import ContactStatusDisplay from "./ContactStatus";
import ContactMediumDisplay from "./ContactMediumDisplay";
import ReplyContactDisplay from "./ReplyContactDisplay";
import Translation from "../i18n/Translate";
import FollowUpsDisplay from "./FollowUpsDisplay";
import AdditionalContactInfoDisplay from "./AdditionalContactInfoDisplay";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import IntlManager from "../i18n/IntlManager";
import EscalationContactsDisplay from "./EscalationContactsDisplay";
import ContactActions from "./ContactActions";
import {sortFollowUps} from "../util/FollowUpUtils";
import ContactTimelineDisplay from "./ContactTimelineDisplay";
import {ExpandableDisplay} from "../components/ExpandableDisplay";
import ReplyByActions from "./ReplyByActions";
import {getUserLogin} from "./UserDetailsUtil";
import {IssueManagerContext} from "../issueManager/IssueManager";
import ImageUploadLinkActions from "./imageAttachment/ImageUploadLinkActions";
import { IsContactLockedByAgent } from "./ContactUtil";

export interface ContactDisplayContext {
    customerTimezone: string;
    initExpanded: boolean;
    userId: string;
    marketplaceId: string;
    emailToken: string | null;
    isDeveloper: boolean;
    purpose: Purpose;
}

export interface ContactDisplayProps {
    customerId: string;
    contact: Contact;
    context: ContactDisplayContext;
    customerAccessLevel: CustomerAccessLevel;
}

const ContactDisplay: React.FC<ContactDisplayProps> = ({
                                                           customerId,
                                                           contact,
                                                           context,
                                                           customerAccessLevel
                                                       }) => {
    const {
        customerTimezone,
        initExpanded,
        userId,
        marketplaceId,
        emailToken,
    } = context;

    const sortedFollowUps = (contact.followUps ? sortFollowUps(contact.followUps) : []);
    const [newAnnotation, setNewAnnotation] = useState<string>("");

    //sharing image upload link is only allowed in India marketplace.
    const allowedMarketplaceForSharingUploadLink = ["A21TJRUUN4KGV", "A2XZLSVIQ0F4JT"];

    const {getSubject} = useContext(IssueManagerContext);

    function canShareImageAttachmentLink(contact): boolean {
        return IsContactLockedByAgent(contact, userId, getUserLogin(getSubject()))
            && allowedMarketplaceForSharingUploadLink.includes(marketplaceId); //image attachment link is only required for IN
    }


    const contactHeader: JSX.Element = (
        <ColumnLayout columns={3}>
            {/* First Column */}
            <div>
                <div className="contact-details-subheader">
                    <Translation stringId="ITEMS_CONTACTED_ABOUT"/>
                </div>
                <ContactOrders orderIds={contact.orderIds}/>
            </div>
            {/* Second Column*/}
            <div>
                <div className="contact-details-subheader">
                    <Translation stringId="CUSTOMER_SELECTED_ISSUE"/>
                </div>
                <Issues issue={contact.customerSelectedIssue} selector={IssueSelector.Customer}/>
            </div>
            {/* Third Column*/}
            <div>
                <div className="contact-details-subheader">
                    <Translation stringId="AGENT_SELECTED_ISSUE"/>
                </div>
                <Issues issue={contact.agentSelectedIssue} selector={IssueSelector.Agent}/>
            </div>
        </ColumnLayout>
    );

    const DEFAULT_TAB = "Default";
    const ESCALATIONS_TAB = "Escalations";

    const [visibleTab, setVisibleTab] = useState<string>(DEFAULT_TAB);

    const contactMedium: JSX.Element = (
        <div hidden={visibleTab !== DEFAULT_TAB}><ContactMediumDisplay
            contact={contact}
            customerAccessLevel={customerAccessLevel}
            customerTimezone={customerTimezone}
            marketplaceId={marketplaceId}/></div>
    );

    const escalations: JSX.Element = (
        <div hidden={visibleTab !== ESCALATIONS_TAB}>
            <EscalationContactsDisplay
                dartEscalationContactList={contact.dartEscalationContactList}
                customerAccessLevel={customerAccessLevel}
                customerTimezone={customerTimezone}
                marketplaceId={marketplaceId}
            />
        </div>
    );

    const contactContent: JSX.Element = (
        <SpaceBetween size="s">
            <ContactActions contact={contact} userId={userId} marketplaceId={marketplaceId}
                            customerId={customerId}></ContactActions>
            <div>
                <FollowUpsDisplay contact={contact} followUps={sortedFollowUps}
                                  customerAccessLevel={customerAccessLevel} customerTimezone={customerTimezone}
                                  emailToken={emailToken} customerId={customerId} marketplaceId={marketplaceId}/>
            </div>
            <div>
                <Grid gridDefinition={[{"colspan": {"default": 4}}, {"colspan": {"default": 8}}]}>
                    <div>
                        <ContactTimelineDisplay contactId={contact.contactId}
                                                stateTransitionList={contact.stateTransitionList}
                                                customerTimezone={customerTimezone}
                                                agentAnnotations={contact.agentAnnotations}
                                                autoAnnotations={contact.autoAnnotations}
                                                newAnnotation={newAnnotation}
                                                setNewAnnotation={setNewAnnotation}
                        />
                    </div>
                    <div>
                        {(contact.dartEscalationContactList?.length > 0) ?
                            <div>
                                <Tabs
                                    onChange={(event): void => setVisibleTab(event.detail.activeTabId)}
                                    tabs={[
                                        {
                                            label: IntlManager.sharedManager.formatMessage("CONTACT_MEDIUM_DEFAULT_TAB"),
                                            id: DEFAULT_TAB,
                                            content: null
                                        },
                                        {
                                            label: `${IntlManager.sharedManager.formatMessage("CONTACT_MEDIUM_ESCALATIONS_TAB")} (${contact.dartEscalationContactList.length})`,
                                            id: ESCALATIONS_TAB,
                                            content: null
                                        }
                                    ]}
                                />
                                {contactMedium}{escalations}
                            </div>:
                            <ContactMediumDisplay contact={contact}
                                                  customerAccessLevel={customerAccessLevel}
                                                  customerTimezone={customerTimezone}
                                                  marketplaceId={marketplaceId}
                            />}
                        <SpaceBetween direction={"horizontal"} size={"xs"}>
                            <ReplyByActions contact={contact}/>
                            {canShareImageAttachmentLink(contact) ?
                                <ImageUploadLinkActions
                                    contact={contact}
                                    marketplaceId={marketplaceId}
                                    customerId={customerId}/>
                                : null}
                        </SpaceBetween>
                    </div>

                </Grid>

            </div>

            {contact.replies.length > 0 ?
                <div style={{backgroundColor: "#fafafa"}}>
                    {contact.replies.map(reply => {
                        return <ReplyContactDisplay key={reply.contactId} reply={reply}
                                                    customerContentIsRestricted={customerAccessLevel === CustomerAccessLevel.CONFIDENTIAL}
                                                    customerTimezone={customerTimezone}/>;
                    })}
                </div>
                : null}
            <div>
                <AdditionalContactInfoDisplay contactId={contact.contactId}
                                              customerId={customerId}/>
            </div>
        </SpaceBetween>
    );

    return (
        <ExpandableDisplay
            initExpanded={initExpanded}
            header={<ContactStatusDisplay creationDate={contact.creationDate}
                                          contactStatus={contact.status}
                                          owner={contact.owner}
                                          type={contact.medium.type}
                                          followUps={sortedFollowUps}
                                          contactId={contact.contactId}
                                          dartEscalationContactList={contact.dartEscalationContactList}
                                          customerTimezone={customerTimezone}
                                          contact={contact}
                                          userId={userId}/>}
            pinnedContent={contactHeader}
            expandableContent={contactContent}
            expandedStringId={"CONTACT_EXPANDED"}
            collapsedStringId={"CONTACT_COLLAPSED"}
        />
    );
};

export default ContactDisplay;