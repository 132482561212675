import React from "react";
import {Chat, ChatMessage, ChatMessageType, ChatParticipantStateChangeType, ChatParticipantType} from "./Contact.types";
import ChatMessageDisplay from "./ChatMessageDisplay";
import {Timestamp} from "./Timestamp";
import './ContactChatMediumStyle.css';
import IntlManager from "../i18n/IntlManager";
import EmdashImage from "../util/EmdashImage";
import Translation from "../i18n/Translate";
import Alert from "@amzn/awsui-components-react/polaris/alert";

interface ContactChatMediumProps {
    chat: Chat | undefined;
    customerTimezone: string;
    customerContentIsRestricted: boolean;
    isForwardedChat?: boolean;
}

function isSameDate(timestamp1: string, timestamp2: string | undefined): boolean {
    if (timestamp2) {
        return timestamp1.substring(0, 10) === timestamp2.substring(0, 10);
    }
    return false;
}

const renderMessages = (customerTimezone: string, messages: ChatMessage[]): JSX.Element[] => {
    let key = 0;
    let lastMessage: ChatMessage | undefined = undefined;

    const results: JSX.Element[] = [];

    for (const message of messages) {
        if (!isCustomerConnectedMessage(message)) {
            const sameDate = isSameDate(message.timestamp, lastMessage?.timestamp);

            if (!sameDate) {
                results.push(
                    <div className={"date-timezone-timestamp"}>
                        <Timestamp key={++key} date={message?.timestamp} customerTimezone={customerTimezone} format={"LL"}/>
                    </div>
                );
            }

            const messageDisplay = <ChatMessageDisplay key={++key} chatMessage={stripHtmlTagsFromMessage(message)} prevMessage={lastMessage} customerTimezone={customerTimezone}/>;
            results.push(messageDisplay);
            lastMessage = message;
        }
    }

    return results;
};

const stripHtmlTagsFromMessage = (message: ChatMessage): ChatMessage => {
    if (message?.chatText) {
        const strippedMessageText = message.chatText.text.replace(/<[^<>]+>/gi, "");
        message.chatText.text = strippedMessageText;
    }
    
    return message;
};

const isCustomerConnectedMessage = (message: ChatMessage): boolean => {
    return message.type === ChatMessageType.PARTICIPANT_CHANGE &&
        message.stateChangeType === ChatParticipantStateChangeType.CONNECTED &&
        message.participant?.__typename === ChatParticipantType.CUSTOMER;
};

const ContactChatMedium: React.FC<ContactChatMediumProps> = ({chat, customerTimezone, customerContentIsRestricted, isForwardedChat}) => {
    return (
        <div>
            <h4 className="contact-details-header">
                {isForwardedChat ? <Translation stringId="TRANSFERRED_CHAT_TITLE"/> : <Translation stringId="CHAT_TITLE"/>}
            </h4>
            {customerContentIsRestricted ? <Alert><Translation stringId={'CHAT_RESTRICTED'}/></Alert>
                : chat?.messages ?
                <div className="chat-medium">
                    {renderMessages(customerTimezone, chat.messages)}
                </div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CHAT")}/>
            }
        </div>
    );
};

export default ContactChatMedium;