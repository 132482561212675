export const pt_PT = {
    "ACCESS_RESTRICTED_CONTENT": "Aceder a conteúdos restritos",
    "ACTION": "Ação",
    "ADD": "Adicionar",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Informações de contacto adicionais",
    "ADD_ANNOTATION_FAILURE": "Falha ao adicionar a anotação.",
    "ADD_ANNOTATION_INVALID": "Anotação inválida. Certifique-se de que não há dados restritos na anotação.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nova anotação",
    "ADD_ANNOTATION_SUCCESS": "Anotação adicionada com sucesso.",
    "AGENT": "Agente",
    "AGENT_ANNOTATION_BADGE": "Agente",
    "AGENT_SELECTED_ISSUE": "Problema selecionado pelo agente",
    "AGENT_SELECTED_ISSUE_COPIED": "Problema selecionado do agente copiado.",
    "AMAZON_CONNECT_CALL_INFO": "Informações de chamada da Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID de contacto da Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID de contacto da Amazon Connect copiado",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Logística da Amazon",
    "ANNOTATED_BY": "Anotado por {agentLogin}.",
    "ANNOTATIONS": "Anotações",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "As anotações não devem conter dados PII do Cliente, como números de telefone e endereços de e-mail. O nosso sistema automatizado tenta impor esta medida, mas por vezes não reconhece a diferença entre PII e outros identificadores. Indique a razão pela qual é necessária uma substituição.",
    "ANNOTATION_WARNING": "As anotações podem estar sujeitas a revisão pelos clientes de acordo com os regulamentos de privacidade. Não inclua informações pessoais ou opiniões no campo de anotações.",
    "ATTACHMENTS_RESTRICTED": "Os anexos são restritos.",
    "ATTACHMENTS_TITLE": "Anexos",
    "ATTACHMENT_DELETION_FAILED": "A eliminação dos anexos falhou, tente novamente mais tarde.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Anexos eliminados com êxito.",
    "AUTHENTICATING": "Autenticação iniciada. Isto poderá abrir uma nova janela.",
    "AUTHENTICATION_FAILURE": "Ocorreu um problema de autenticação.",
    "AUTHENTICATION_MESSAGE": "A obter novas credenciais. Esta janela será fechada dentro de alguns segundos.",
    "AUTO_ANNOTATION_BADGE": "Automático",
    "BACK": "Voltar",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Blurbs utilizados",
    "CALL_LEG": "ID de chamada",
    "CALL_LEG_ID_COPIED": "ID de chamada copiada.",
    "CALL_RESTRICTED": "O conteúdo da chamada é restrito.",
    "CALL_RETENTION_DELETED": "O registo passou o respetivo período de retenção e foi eliminado.",
    "CALL_TITLE": "Contacte o Serviço de apoio ao cliente da Amazon por telefone",
    "CANCEL": "Cancelar",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Este cliente tem um número muito vasto de contactos e nem todos foram carregados. Os contactos são sempre carregados na ordem do \"mais recente para o mais antigo\" a partir da data de término. Altere a data de término para começar a carregar de um momento diferente no tempo.",
    "CHANNELS": "Canais",
    "CHAT_AGENT_CONNECTED": "{name} aceitou o chat.",
    "CHAT_CONTACT": "Contacto por chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} perdeu a ligação à rede.",
    "CHAT_DISCONNECT_GENERIC": "{name} já não está ligado.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} perdeu a ligação à rede.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} perdeu a ligação à rede.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} saiu da conversa.",
    "CHAT_PARKED": "Este chat estava em espera",
    "CHAT_PARKED_NOTES": "Notas de espera",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} já não está em espera.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} está em espera.",
    "CHAT_RESTRICTED": "O conteúdo do chat é restrito.",
    "CHAT_TITLE": "Fale com o Serviço de apoio ao cliente da Amazon através de chat",
    "CHAT_TRANSFER_NOTES": "Transferir notas",
    "CHILD_WORK_REQUESTS": "Pedidos de trabalho criados a partir deste contacto",
    "CHOOSE_OPTIONS": "Escolher opções",
    "CLOSE": "Fechar",
    "CLOSE_ALERT": "Fechar alerta",
    "CLOSE_MODAL_BUTTON_TEXT": "Fechar",
    "COLLAPSED": "Fechado",
    "CONFIRM": "Confirmar",
    "CONTACTS": "Contactos",
    "CONTACT_COLLAPSED": "Detalhes de contacto fechados.",
    "CONTACT_CREATION_FAILURE": "Ocorreu um erro ao criar o novo contacto",
    "CONTACT_EXPANDED": "Detalhes de contacto expandidos.",
    "CONTACT_HISTORY": "Histórico de contactos",
    "CONTACT_ID": "ID de contacto",
    "CONTACT_ID_COPIED": "ID de contacto copiada.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contacto",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Encaminhamentos para um superior",
    "CONTACT_REASSIGNED_TO_AGENT": "O contacto foi reatribuído ao agente.",
    "CONTACT_SELECT_CHECKBOX": "Caixa de verificação para selecionar um contacto bloqueado",
    "CONTACT_STATUS_ESCALATION_BADGE": "Encaminhamento para um superior",
    "CONTACT_TICKET": "Ticket de contacto",
    "CONTACT_TICKET_CONTENT": "Conteúdo do ticket",
    "CONTACT_TICKET_CREATE": "Criar ticket de contacto",
    "CONTACT_TICKET_CREATION_FAILURE": "A criação do ticket de contacto falhou",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket de contacto criado",
    "CONTACT_TICKET_RESOLVER_GROUP": "Responsável pela resolução do grupo",
    "CONTACT_TICKET_SEVERITY": "Gravidade do ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID da pasta SIM",
    "CONTACT_TICKET_SUBJECT": "Assunto do ticket",
    "CONTACT_TIMELINE": "Linha cronológica de contactos",
    "CONTACT_TYPE": "Tipo de contacto",
    "CONTACT_WAS_TRANSFERRED": "O Contacto foi transferido.",
    "CONTENT": "Conteúdo",
    "CONTENT_RESTRICTED": "O conteúdo é restrito.",
    "COPY_AGENT_SELECTED_ISSUE": "Copiar problema selecionado pelo agente: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copiar ID de contacto da Amazon Connect",
    "COPY_CALL_LEG_ID": "Copiar ID de chamada: {value}",
    "COPY_CONTACT_ID": "Copiar ID de contacto: {value}",
    "COPY_CUSTOMER_ID": "Copiar ID do cliente",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copiar problema selecionado pelo cliente: {value}",
    "COPY_FAILURE": "Falha ao copiar.",
    "COPY_ID": "Copiar ID",
    "COPY_LINK": "Copiar link de carregamento de imagens",
    "COPY_ORDER_ID": "Copiar ID do pedido: {value}.",
    "CREATE_CASE": "Criar caso",
    "CREATE_FOLLOW_UP": "Criar seguimento",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Seguimento criado com sucesso, mas não foi possível adicionar a anotação correspondente. Tente adicionar a anotação manualmente.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Especifique o método de contacto preferencial do cliente.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Não é possível criar um seguimento com uma data e hora de vencimento no passado.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Especifique a data e a hora de vencimento da ação de seguimento.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Fuso horário",
    "CREATE_FOLLOW_UP_FAILURE": "Falha ao criar seguimento.",
    "CREATE_FOLLOW_UP_INVALID": "Seguimento inválido. Certifique-se de que não existem dados restritos.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Introduza a ação de seguimento.",
    "CREATE_FOLLOW_UP_SUCCESS": "Seguimento criado com sucesso.",
    "CREATE_WORK_REQUEST": "Criar pedido de trabalho",
    "CROSS_MARKETPLACE_QUEUES": "Filas entre sites da Amazon",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Falha ao transferir o Contacto porque já foi resolvido.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "A operação falhou porque o contacto está num estado inválido.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Operação falhada porque o contacto não foi encontrado",
    "CSALT_ERROR_INTERNAL_ERROR": "Não foi possível bloquear o contacto",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Não foi possível bloquear o contacto devido a argumentos inválidos",
    "CSALT_ERROR_INVALID_INPUT": "A operação falhou devido a entradas inválidas.",
    "CSALT_ERROR_INVALID_QUEUE": "Falha ao transferir o Contacto porque a Fila selecionada é inválida.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Operação falhada porque o contacto está bloqueado para um agente diferente.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Não foi possível bloquear o contacto devido a um erro de dependência",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Falha ao transferir o Contacto porque a Fila não foi encontrada no serviço de backend.",
    "CSALT_ERROR_UNKNOWN": "A operação falhou devido a um erro inesperado.",
    "CURRENT_CONTACT": "Contacto atual",
    "CUSTOMER": "Cliente",
    "CUSTOMER_FOLLOWUPS": "Seguimento para o cliente",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Falhou ao obter seguimento para o cliente",
    "CUSTOMER_ID_COPIED": "ID do cliente copiada",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "O conteúdo do cliente é restrito. (Sem contactos bloqueados.)",
    "CUSTOMER_SELECTED_ISSUE": "Problema selecionado pelo cliente",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Problema selecionado pelo cliente copiado.",
    "CUSTOMER_TIMEZONE": "Carimbos de data/hora apresentados no fuso horário do cliente",
    "DATE": "Data",
    "DATE_PICKER_NEXT_MONTH": "Próximo mês",
    "DATE_PICKER_PREVIOUS_MONTH": "Mês anterior",
    "DATE_PICKER_TODAY": "Hoje",
    "DECODING_AUDIO": "A descodificar áudio",
    "DELETE_ATTACHMENTS": "Eliminar anexos do contacto",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Eliminar anexos",
    "DELIVERY_STATUS": "Estado da entrega",
    "DISABLE_CONTENT_FILTER": "Desativar filtro de conteúdos",
    "DOWNLOAD": "Descarregar",
    "DURATION": "Duração",
    "EMAIL": "E-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "O(s) endereço(s) de e-mail do destinatário não são permitido(s).",
    "EMAIL_BODY": "Corpo do e-mail",
    "EMAIL_CONTACT": "Contacto por e-mail.",
    "EMAIL_HEADER": "Cabeçalho",
    "EMAIL_QUEUE_TITLE": "Fila de e-mail",
    "EMAIL_RESTRICTED": "O conteúdo do e-mail é restrito.",
    "EMAIL_SENDING_FAILURE": "Ocorreu um erro ao enviar o e-mail de saída.",
    "EMAIL_SUBJECT": "Assunto do e-mail",
    "EMAIL_TITLE": "Contacte por e-mail com o Serviço de apoio ao cliente da Amazon",
    "END": "Fim",
    "END_DATE": "Data de término",
    "ERROR": "Erro",
    "ERROR_FAILED_TO_LOAD_DATA": "Falha ao carregar dados.",
    "ESCALATION_CONTACT_HANDLING": "A processar contacto de encaminhamento para um superior",
    "ESCALATION_TAB_LEAD_LOGIN": "Inicio de sessão do administrador: {agentLogin}",
    "EXPANDED": "Expandido",
    "FAILED_TO_CREATE_WORK_REQUEST": "Não foi possível criar o pedido de trabalho",
    "FAILED_TO_FETCH_SYSKA": "Falha ao obter alertas SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Falha ao reatribuir o contacto ao agente.",
    "FETCHED_NUMBER_CONTACTS": "{number} contactos obtidos.",
    "FILTER": "Filtrar",
    "FINDER": "Finder",
    "FOLLOW_UPS_COLLAPSED": "Seguimentos fechados",
    "FOLLOW_UPS_EXPANDED": "Seguimentos expandidos",
    "FOLLOW_UP_ALERT": "tem um seguimento devido a {dueDate} criado por {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Método de contacto",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "O método de contacto é restrito.",
    "FOLLOW_UP_DUE": "Seguimento em falta",
    "FOLLOW_UP_DUE_DATE": "Data de vencimento",
    "FOLLOW_UP_NOTE": "Nota",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Nenhum método de contacto",
    "FOLLOW_UP_NO_NOTE": "A nota está vazia",
    "FOLLOW_UP_NO_TICKET": "Nenhum ticket",
    "FOLLOW_UP_OWNER": "Proprietário",
    "FOLLOW_UP_STATUS": "Estado",
    "FOLLOW_UP_STATUS_OPEN": "Abrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Resolvido",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Seguimentos",
    "FROM": "De",
    "GENERATE_LINK": "Gerar link",
    "HAS_OVERRIDE_TRY_AGAIN": "Substituição de filtro presente. Tente novamente.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "Link de anexo de imagens partilhado com o cliente",
    "INPUT_CHARACTERS_REMAINING": "Máximo {char_max} caracteres ({char_remain} restantes)",
    "INTERNAL_ERROR": "Erro interno",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Foi encontrado um erro interno. Se o erro persistir, envie-nos um ticket a explicar o problema.",
    "INVALID_CONTACT": "Contacto inválido.",
    "INVALID_DATA_MATCH": "O sistema efetuou uma classificação de dados inválida.",
    "INVALID_TIMESTAMP": "Carimbo de hora inválido.",
    "IN_MARKETPLACE_QUEUES": "Filas do site da Amazon",
    "ISSUE": "Problema",
    "ISSUE_CODE": "Código do problema",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Já abordado e resolvido num contacto anterior",
    "ISSUE_CODE_DESCRIPTION": "Selecione a razão para tornar os contactos selecionados como duplicados",
    "ISSUE_CODE_EXACTLY_SAME": "Exatamente o mesmo de um contacto anterior",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Gerido e resolvido por outro agente de apoio ao cliente por chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Gerido e resolvido por outro agente de apoio ao cliente por e-mail",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Gerido e resolvido por outro agente de apoio ao cliente por telefone",
    "ITEMS_CONTACTED_ABOUT": "Produtos relacionados com o contacto",
    "I_UNDERSTAND": "Compreendo",
    "KNOWN_ISSUES": "Problemas conhecidos",
    "LEARN_MORE": "Saber mais",
    "LINK_COPIED": "Link copiado",
    "LOADING": "A carregar dados.",
    "LOADING_PREVIEW_TEXT": "A carregar pré-visualização",
    "LOADING_RECORDING": "A carregar gravação",
    "LOAD_MORE": "Carregar mais",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Carregar mais anotações.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Carregue mais contactos para este cliente.",
    "LOCKED": "Bloqueado",
    "LOCKED_BY": "Bloqueado por",
    "LOCK_CONTACT": "Bloquear",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Não foi possível bloquear o contacto, o utilizador não está autorizado",
    "LOCK_CONTACT_SUCCESSFUL": "Contacto bloqueado para",
    "MISSING_ORIGINAL_CONTACT_ID": "Introduza uma ID de contacto original válida",
    "MODAL_NO_BUTTON_TEXT": "Não",
    "MODAL_YES_BUTTON_TEXT": "Sim",
    "NEXT": "Seguinte",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Nenhum problema selecionado pelo agente.",
    "NO_ANNOTATED_BY": "Anotado por utilizador desconhecido.",
    "NO_ANNOTATIONS": "Não há anotações disponíveis.",
    "NO_ATTACHMENTS": "Não existem anexos.",
    "NO_CHAT": "O chat está vazio.",
    "NO_CHILD_WORK_REQUESTS": "Nenhum pedido de trabalho criado a partir deste contacto",
    "NO_CONTACT": "Nenhum contacto disponível",
    "NO_CONTACTS_SELECTED": "Selecione os contactos para os processar como spam",
    "NO_CONTENT": "Sem conteúdo",
    "NO_CUSTOMER_SELECTED_ISSUE": "Nenhum problema selecionado pelo cliente.",
    "NO_DELIVERY_STATUS": "Sem estado da entrega",
    "NO_EMAIL_BODY": "O e-mail está vazio.",
    "NO_EMAIL_HEADER": "Sem cabeçalho de e-mail.",
    "NO_EMAIL_QUEUE": "Não existe fila de e-mail associada a este contacto.",
    "NO_ITEMS_CONTACTED_ABOUT": "Nenhum produto relacionado com o contacto.",
    "NO_RESULTS": "Não foram encontrados resultados.",
    "NO_SCREEN_POP": "Sem informação ScreenPop",
    "NO_SENT_FROM": "Não foi enviado do endereço.",
    "NO_SENT_TO": "Não enviado para o endereço",
    "NO_SUBJECT": "A linha de assunto está vazia.",
    "NO_SYSKA_ALERTS": "Sem alertas SYSKA",
    "NO_USED_BLURBS": "Não há blurbs usados para este contacto.",
    "NO_VIEW": "Nenhum contacto ou cliente para visualizar.",
    "NO_WORK_CATEGORY": "Nenhuma categoria de trabalho associada a este contacto.",
    "NUMBER_OF_CONTACTS": "Contactos carregados",
    "OLDEST_LOADED_CONTACT": "Contacto carregado mais antigo",
    "OPEN": "Abrir",
    "OPENS_IN_A_NEW_TAB": "Abre num novo separador",
    "ORDER_ID_COPIED": "ID do pedido copiada.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Introduza a ID de contacto original",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Caixa de texto para introdução da ID de contacto original",
    "OTHER": "Outros",
    "OUTBOUND_EMAIL_BCC": "e colocar em Bcc -",
    "OUTBOUND_EMAIL_CC": "ao colocar em Cc -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Conteúdo da notificação fechado.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Conteúdo da notificação expandido.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Foi atingido o número máximo de resultados. Reduza o intervalo de horas e tente novamente.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "O processo atual requer conteúdo PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Correspondência inválida como número de telefone, endereço de e-mail ou outro PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Correspondência inválida como cartão de crédito ou IBAN.",
    "OVERRIDE_REASON_PROMPT": "As anotações não devem conter dados PII do Cliente, tais como números de telefone e endereços de e-mail. O nosso sistema automatizado tenta impor esta medida, mas por vezes não reconhece a diferença entre PII e outros identificadores. Indique a razão pela qual é necessária uma substituição.",
    "PAPYRUS_SESSION_EXPLANATION": "Especifique o seu tipo de sessão do Papyrus para que possamos aplicar as restrições de segurança certas e otimizar a UX de acordo com as suas necessidades.",
    "PAPYRUS_SESSION_TYPE": "Tipo de sessão do Papyrus",
    "PAUSE": "Colocar em pausa",
    "PHONE_CONTACT": "Contacto telefónico.",
    "PLAY": "Reproduzir",
    "PLAYBACK_SPEED": "Velocidade de reprodução",
    "POSTAL_MAIL_TITLE": "Correio postal com atendimento ao cliente Amazon",
    "PREPARING_RECORDING": "A preparar a gravação",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Falha na geração da pré-visualização para contacto",
    "PREVIOUS": "Anterior",
    "PRE_TRANSLATED_REPLY_EMAIL": "E-mail de resposta pré-traduzido",
    "PRE_TRANSLATION_REPLY_TEXT": "Resposta digitada pelo agente de apoio ao cliente antes da tradução Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Ações de processo",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Processar como duplicado",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Processar como spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Resolver contactos selecionados como duplicados",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Tem a certeza de que pretende processar os contactos selecionados como spam?",
    "PROCESS_DUPLICATE_FAILURE": "Não foi possível resolver contactos selecionados como duplicados",
    "PROCESS_DUPLICATE_SUCCESS": "Contactos selecionados resolvidos com sucesso como duplicados",
    "PROCESS_SPAM_FAILURE": "Não foi possível transferir os contactos selecionados para o spam",
    "PROCESS_SPAM_SUCCESS": "Os contactos selecionados foram transferidos com sucesso para o spam",
    "PROVIDE_ADDITIONAL_INFORMATION": "Fornecer informações adicionais relevantes para este contacto",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Informações sensíveis depuradas do contacto:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Adicionar palavra à lista de depuração",
    "PURGE_BUTTON_TEXT": "Depurar",
    "PURGE_CONFIRMATION_CHECK": "Verifiquei novamente os campos acima e confirmei que o texto contém, de facto, informações sensíveis.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Confirmo que o anexo contém, de facto, informações confidenciais do cliente.",
    "PURGE_CONFIRMATION_UNCHECKED": "Confirme a caixa de verificação.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Falha na depuração do contacto",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Informação depurada do contacto com sucesso",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} não é um motivo válido de depuração",
    "PURGE_REASON_FINANCIAL_INFO": "Informações financeiras",
    "PURGE_REASON_OTHER": "Outras informações sensíveis",
    "PURGE_REASON_PII": "Informações pessoais identificáveis",
    "PURGE_REASON_PLACEHOLDER": "Insira a razão para a purga",
    "PURGE_REASON_TEXT": "Motivo da depuração",
    "PURGE_REMOVE_LABEL": "Remover",
    "PURGE_SELECTOR_PLACEHOLDER": "Selecione o motivo para depurar informações sensíveis",
    "PURGE_SENSITIVE_INFO": "Depurar informações sensíveis",
    "PURGE_WORD_NOT_FOUND": "A palavra não foi encontrada no texto de contacto",
    "PURPOSE_AUDITING": "Auditoria de controlo de qualidade",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Fluxo de trabalho de Back-Office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Esta é a sessão para obter dados de contacto para fluxos de trabalho de Back-Office. Por exemplo, anexos a incluir em tickets ou SIM.",
    "PURPOSE_COACHING": "Avaliar contactos de formação",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Investigação de conformidade",
    "PURPOSE_CURATION": "Curadoria de contacto",
    "PURPOSE_CURATION_DESCRIPTION": "Trata-se da sessão de curadoria de contacto C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Esta sessão serve para efetuar a auditoria dos contactos e avaliar a respetiva qualidade.",
    "PURPOSE_DESCRIPTION_COACHING": "Esta é a sessão para avaliar os contactos de agentes dos quais é formador do Portal de desempenho.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Esta é a sessão para investigações de Monitorização de atividades suspeitas.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Esta é a sessão para lidar com os contactos de encaminhamento para um superior. Este processo permite aceder aos dados de contacto de qualquer cliente.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Esta é a sessão para lidar com contactos em direto.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "O objetivo é remover informações sensíveis de um contacto",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Esta é a sessão para avaliar a exatidão do modelo de aprendizagem automática.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Esta é a sessão para avaliar os seus próprios contactos anteriores.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Esta é a sessão predefinida para uso geral.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Lidar com contacto de encaminhamento para um superior",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Depurar informações sensíveis",
    "REASON_FOR_PURGE": "Motivo da purga",
    "REASON_FOR_PURGE_DESCRIPTION": "Razão para purgar anexos",
    "REASON_FOR_PURGE_MISSING": "Introduza a razão para apagar os anexos.",
    "REASSIGN_TO_AGENT": "Reatribuir ao agente",
    "RECORDING": "Gravação",
    "RELOADING_CONTENT": "A recarregar o conteúdo.",
    "RELOAD_LIST": "Recarregar lista",
    "REPLY_BY": "Responder por",
    "REPLY_BY_EMAIL": "Responder por e-mail",
    "REPLY_BY_PHONE": "Responder por telefone",
    "REPLY_RESTRICTED": "O conteúdo da resposta é restrito.",
    "REQUEST": "Solicitar",
    "REQUEST_ACCESS_INSTRUCTIONS": "Indique a razão pela qual é necessário o acesso a conteúdos restritos. (Estes pedidos são monitorizados e abusos podem ter consequências graves.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Solicitar acesso a conteúdos filtrados",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Solicitar acesso a conteúdos restritos",
    "REQUEST_NOT_GRANTED": "O pedido não foi concedido.",
    "REQUEST_OVERRIDE": "Solicitar substituição",
    "RESEND": "Reenviar",
    "RESEND_FAILED": "Falha ao reenviar e-mail.",
    "RESEND_SUCCESS": "E-mail reenviado com sucesso.",
    "RESEND_WAIT_WARNING": "Tem de aguardar {wait_mins} minutos para reenviar.",
    "RESEND_WARNING": "Tem a certeza de que pretende reenviar o e-mail para o cliente?",
    "RESET": "Repor",
    "RESOLVED": "Resolvido",
    "RESOLVED_BY": "Resolvido por",
    "RESTRICTED_DATA_MATCH": "{match} tipo de correspondência {type}",
    "RESTRICTED_INFORMATION": "INFORMAÇÕES RESTRITAS",
    "RETRY": "Tente novamente",
    "ROLE": "Função",
    "SCREEN_POP_DESCRIPTION_TITLE": "Descrição",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Não",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Sim",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Apresentado ao agente",
    "SCREEN_POP_TITLE": "Informações ScreenPop",
    "SCREEN_POP_TYPE_AUTHENTICATING": "A autenticação do screenpop foi apresentada ao agente e foi usada.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "A autenticação do screenpop foi apresentada ao agente.",
    "SCREEN_POP_TYPE_CALLER_ID": "O screenpop de ID do chamador foi apresentado ao agente e foi usado.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "O screenpop de ID do chamador foi apresentado ao agente.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Screenpop não autenticado foi apresentado e foi usado.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "O screenpop não autenticado foi apresentado ao agente.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "O cliente é desconhecido; nenhum screenpop fornecido.",
    "SCREEN_POP_TYPE_OTHER": "Outros",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Não",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Sim",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Usado pelo agente",
    "SDS_TOOLS": "Ferramentas de suporte ao envio e entrega",
    "SEARCH": "Pesquisar",
    "SELECTED_ROLE": "Função selecionada",
    "SELECTED_VIEW": "Vista selecionada",
    "SELECT_CHANNELS": "Selecionar canais",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Para selecionar o código do problema para marcação de contactos selecionados como duplicados",
    "SELECT_TRANSFER_DESTINATION": "Selecionar o destino da transferência",
    "SELECT_WORK_REQUEST_QUEUE": "Selecionar fila do pedido de trabalho",
    "SENDER_EMAIL_ADDRESS": "Remetente",
    "SENDING_EMAIL": "A enviar o e-mail",
    "SEND_EMAIL": "Enviar o e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Tem a certeza de que pretende enviar o link de carregamento de imagens para o cliente?",
    "SEND_OUTBOUND_EMAIL": "Enviar o e-mail de saída",
    "SEND_OUTBOUND_EMAIL_WARNING": "Tem a certeza de que deseja enviar o e-mail de saída para",
    "SENT_FROM": "Enviado de",
    "SENT_OUTBOUND_EMAIL": "O e-mail de saída foi enviado. Foi criado um novo contacto",
    "SENT_TO": "Enviado para",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal da transportadora",
    "SKILL": "Competência",
    "SKIP_SILENCES": "Saltar silêncios",
    "SMS": "SMS",
    "SMS_LINK": "Link para SMS",
    "SOURCE_TEXT": "Texto de origem",
    "SP_BOTTOM": "Fundo",
    "SP_POSITION": "Posição do painel dividido",
    "SP_PREFERENCES": "Preferências de painel dividido",
    "SP_RESIZE": "Redimensione o painel dividido",
    "SP_SIDE": "Lado",
    "START": "Iniciar",
    "STATE_TRANSITIONS": "Transições de estado",
    "STATUS": "Estado",
    "SUBJECT": "Assunto",
    "SYSKA_ALERTS": "Alertas SYSKA",
    "TEXT_TO_PURGE": "Texto para depurar",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Os cookies de terceiros têm de estar ativados.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Os dados PII do Cliente, como números de telefone e endereços de e-mail, não são permitidos durante a criação de um ticket. O nosso sistema automatizado tenta impor esta medida, mas por vezes não reconhece a diferença entre PII e outros identificadores. Indique a razão pela qual é necessária uma substituição.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Tempo limite excedido: ocorreu um erro ao carregar a gravação",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Utilize o formato de 24 horas.",
    "TO": "Para",
    "TO_EMAIL_ADDRESS": "Para",
    "TRANSFER": "Transferir",
    "TRANSFERRED_CHAT_TITLE": "Chat com o Serviço de apoio ao cliente da Amazon transferido",
    "TRANSFERRING": "A transferir...",
    "TRANSFER_CONTACT": "Transferir contacto",
    "TRANSFER_CONTACT_COMMENTS": "Anotação opcional",
    "TRANSFER_FAILED": "Falha na transferência",
    "TRANSFER_FOLLOW_UP": "Transferir seguimento",
    "TRANSFER_FOLLOW_UP_FAILED": "Falha ao transferir seguimento.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Falha ao transferir o seguimento devido a início de sessão de novo titular inválido.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Não tem permissão para transferir este seguimento.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Introduza o nome de início de sessão do novo titular. Exemplo: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transfira o seguimento acima para:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Seguimento transferido com sucesso.",
    "TRANSLATED_TEXT": "Texto traduzido",
    "TRANSLATION": "Tradução",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} não é um nome de fila válido.",
    "TYPE_AGENT_LOGIN": "Tipo de início de sessão do agente",
    "TYPE_EMAIL_ADDRESS": "Digite o endereço de e-mail",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Digite vários endereços de e-mail separados por uma vírgula",
    "TYPE_QUEUE_NAME": "Introduza o nome da fila",
    "UNKNOWN_CONTACT_TYPE": "Tipo de contacto desconhecido.",
    "UNLOCKED": "Desbloqueado",
    "UNRESOLVED": "Não resolvido",
    "URGENT_SYSKA_ALERTS": "Alertas SYSKA urgentes",
    "USE": "Utilizar",
    "USER_TIMEZONE": "Carimbos de data/hora apresentados no seu fuso horário",
    "USE_CONTACT_FINDER": "Utilize o contact finder",
    "VIEW": "Ver",
    "WARNING": "Aviso",
    "WELCOME_MESSAGE": "Damos-lhe as boas-vindas ao site Papyrus Static!",
    "WHAT_IS_PAPYRUS": "O que é o Papyrus?",
    "WITH_THE_ANNOTATION": "com a anotação",
    "WORK_CATEGORY_TITLE": "Categoria de trabalho",
    "WORK_REQUEST": "Pedido de trabalho",
    "WORK_REQUEST_CONTENT": "Conteúdo do pedido de trabalho",
    "WORK_REQUEST_CREATED": "Pedido de trabalho criado",
    "WORK_REQUEST_FOR_CONTACT": "Pedido de trabalho para contacto",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Os dados PII do Cliente, como números de telefone e endereços de e-mail, não são permitidos durante a criação de um pedido de trabalho. O nosso sistema automatizado tenta impor esta medida, mas por vezes não reconhece a diferença entre PII e outros identificadores. Indique a razão pela qual é necessária uma substituição.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Está prestes a transferir para a fila",
    "copy_of_cs_papyrus_type_agent_login": "Tipo de início de sessão do agente"
}