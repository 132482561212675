export const fr_CA = {
    "contact_event_GC_added": "GC #{gcId} ajouté au compte.",
    "contact_event_GC_added_batched": "GC ajouté au compte ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} ajout au compte de l'acheteur.",
    "contact_event_GC_added_purchaser_batched": "GC ajouté au compte de l'acheteur ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} ajouté au compte de destinataire.",
    "contact_event_GC_added_recipient_batched": "GC ajouté au compte du destinataire ({count}).",
    "contact_event_GC_added_scratchedCode": "Carte cadeau no. {gcId} $ ajoutée au compte à l’aide de l’outil de code de réclamation à gratter.",
    "contact_event_GC_added_scratchedCode_batched": "Carte cadeau ajoutée au compte à l’aide de l’outil de code de réclamation à gratter ({count} $).",
    "contact_event_GC_cancelled": "GC #{gcId} annulé.",
    "contact_event_GC_cancelled_batched": "GC annulé ({count}).",
    "contact_event_GC_changed_expiry": "Date d'expiration modifiée de GC #{gcId}",
    "contact_event_GC_changed_expiry_batched": "Date d'expiration modifiée de GC ({count}).",
    "contact_event_GC_locked": "GC #{gcId} bloqué.",
    "contact_event_GC_locked_batched": "GC bloqué ({count}).",
    "contact_event_GC_removed": "GC #{gcId} supprimé du compte",
    "contact_event_GC_removed_batched": "GC supprimé du compte ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} renvoyé à l'identifiant de courriel {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC renvoyé à l'identifiant de courriel ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} renvoyé par courrier postal.",
    "contact_event_GC_resent_post_batched": "GC renvoyé par courrier postal ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} temporairement bloqué.",
    "contact_event_GC_temporary_locked_batched": "GC temporairement bloqué ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transféré du client  {customerID} à {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC transféré ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} débloqué.",
    "contact_event_GC_unlocked_batched": "GC débloqué ({count}).",
    "contact_event_PV_added": "PV #{pvId} ajouté à votre compte.",
    "contact_event_PV_locked": "PV # {pvId} verrouillé.",
    "contact_event_PV_temporary_locked": "PV # {pvId} verrouillé temporairement.",
    "contact_event_PV_unlocked": "PV # {pvId} déverrouillé.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Réclamations A-à-Z annulées pour les commandes ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Revendication de A à Z soumise pour commande {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Réclamation A-à-Z soumise pour les commandes ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Réclamation en vertu de la Garantie A à Z soumise pour la commande {order_id}.",
    "contact_event_access_code_added": "Code d’accès ajouté",
    "contact_event_account_closed": "Compte fermé.",
    "contact_event_account_closed_batched": "Comptes fermés ({count}).",
    "contact_event_account_reopened": "Compte réactivé",
    "contact_event_account_reopened_batched": "Comptes réactivés ({count})",
    "contact_event_account_temporarily_disabled": "Compte temporairement désactivé.",
    "contact_event_account_temporarily_disabled_batched": "Comptes temporairement désactivés ({count})",
    "contact_event_account_whitelisted": "Compte en liste d’autorisation",
    "contact_event_address_changed": "Adresse {address_type} mise à jour pour la commande {order_id}.",
    "contact_event_address_changed_batched": "Adresses mises à jour pour les commandes ({count}).",
    "contact_event_address_deactivated": "Adresse désactivée.",
    "contact_event_address_deactivated_batched": "Adresse désactivée  ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Renouvellement automatique de l’abonnement Amazon Video",
    "contact_event_amazon_video_subscription_cancelled": "Abonnement Amazon Video annulé",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulaire soumis : formulaire d’escalade DSL\nIdentifiant de session de cas d’utilisation : {usecaseSessionID}\nStation de livraison : {deliveryStationField}\nType de demande : {requestTypeField}\nIdentifiant de contact : {contactIdField}\nNuméro de la commande : {orderIdField}\nAdresse courriel du client : {customerEmailField}\nNuméro de téléphone du client : {customerPhoneNumberField}\nTBA(s) :{tbasField}\nCode d’accès (le cas échéant) : {accessCodeField}\nSommaire du problème :",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formulaires d'escalade DSL soumis : ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulaire soumis : formulaire de commentaires DSP DA\nIdentifiant de session de cas d’utilisation : {usecaseSessionID}\nCode de la station de livraison : {deliveryStationField}\nIdentifiant de contact : {contactIdField}\nIdentifiant de compte du client : {customerIdField}\nIdentifiant du transporteur DA : {driverIdField}\nDate de l’incident (JJ-MM-AAAA) : {dateOfOccurrenceField}\nHeure de l’incident (HH:MM) : {timeofOccurrenceField}\nTBA : {relatedTrackingIdsField}\nSource des commentaires : {sourceOfFeedbackField}\nDétails des commentaires : {issueField}\nDétails supplémentaires : {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formulaires de commentaires soumis DSP DA : ({count})",
    "contact_event_andon_cord_pulled": "Corde Andon tirée pour {asin}.",
    "contact_event_andon_cord_pulled_batched": "Câble Andon tiré pour les articles ({count}).",
    "contact_event_apply_adrive_music_trial": "a appliqué Cloud Player Premium au client {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Compte déplacé vers {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Les comptes Audible déplacés ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} a été remboursé pour le montant de {cashAmount}. De plus, cette transaction a rendu un coupon de {couponAmount} au compte.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} a été remboursé pour le montant de {cashAmount}. De plus, cette transaction a rendu un coupon de {couponAmount} au compte.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} a été remboursé pour le montant de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} a été remboursé pour le montant de {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} a été remboursé et a rendu un coupon de {couponAmount} au compte.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} a été remboursé et a rendu un coupn de {couponAmount} au compte.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} de crédit(s) ont été retiré(s) du compte.",
    "contact_event_audible_credit_issued": "{numCredit} crédit(s) a/ont été ajouté(s) à votre compte.",
    "contact_event_audible_credit_issued_batched": "Un/Des crédit(s) a/ont été ajouté(s) au compte ({count}).",
    "contact_event_audible_credit_refund": "{title} a été remboursé et a rendu un crédit de {creditAmount} au compte.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} a été remboursé et a rendu un crédit de {creditAmount} au compte.{counter} ({count})",
    "contact_event_audible_credit_restored": "Un montant de {amount} en crédit a été remis dans le compte.",
    "contact_event_audible_credit_restored_batched": "Un montant de {amount} en crédit a été remis dans le compte.({count})",
    "contact_event_audible_extra_credit_purchased": "3 crédits supplémentaires ont été achetés.",
    "contact_event_audible_extra_credit_purchased_batched": "3 crédits supplémentaires ont été achetés.({count})",
    "contact_event_audible_hiatus_cancelled": "La pause a été terminée plus tôt que prévu, et l’abonnement a repris.",
    "contact_event_audible_hiatus_cancelled_batched": "La pause a été terminée plus tôt que prévu, et l’abonnement a repris. ({count})",
    "contact_event_audible_hiatus_started": "L'abonnement a été mis en pause pour {duration} mois.",
    "contact_event_audible_hiatus_started_batched": "L'abonnement a été mis en pause pour {duration} mois. ({count})",
    "contact_event_audible_membership_enrollment": "Inscrit au \nPlan d'adhésion {membershipPlan}.",
    "contact_event_audible_membership_enrollment_batched": "Inscrit aux plans d'adhésion Audible ({count}).",
    "contact_event_audible_membership_plan_refund": "Le frais de l'abonnement a été remboursé pour un montant de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Le frais de l'abonnement a été remboursé pour un montant de {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Passé de {oldMembershipPlan} au plan d'adhésion {newMembershipPlan}.",
    "contact_event_audible_membership_switched_batched": "Plans d'adhésion Audible échangés ({count}).",
    "contact_event_audible_romance_package_enrollment": "Inscrit au {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Inscrit au {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "{wizardName} a été consulté(s).",
    "contact_event_audible_wizard_invoked_batched": "{wizardName} a été consulté(s).({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "L’abonnement {membership} a été annulé avec succès.",
    "contact_event_blacklist_kindle": "Appareil mis sur la liste noire {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Kindles sur liste noire ({count}).",
    "contact_event_bound_warranty": "ID de garantie {warranty_id} lié à l'appareil {dsn}.",
    "contact_event_bound_warranty_batched": "Garanties liées aux appareils ({count}).",
    "contact_event_call_was_flagged": "Ce contact a été marqué comme {callFlagSubject} : {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Ce contact a été marqué comme {callFlagSubject} : {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "L’abonnement {membership} a été annulé avec succès.",
    "contact_event_cancel_audible_membership_batched": "L’abonnement {membership} a été annulé avec succès.({count})",
    "contact_event_cancel_audible_romance_package_batched": "L’abonnement {membership} a été annulé avec succès.({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Prime bien annulé de PrimeCancelException. Motif d'annulation : {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Annuler l'exception pour Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "La date d'Annulation future précédemment réglée a été annulée.",
    "contact_event_cancelled_sns_reactivated": "ASIN pour abonnement {subscription_id} réactivé le {reactivation_date}(mois) par {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Abonnements S'abonner et économiser annulés réactivés ({count}).",
    "contact_event_captcha_bypass_key_generated": "Clé de contournement Captcha générée.",
    "contact_event_captcha_bypass_key_generated_batched": "Clé de contournement Captcha générée ({count}).",
    "contact_event_change_sns_quantity": "L'ASIN {asin} pour S'abonner et économiser modifie l'ancienne quantité {current_quantity} pour la nouvelle quantité {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Quantités S'abonner et économiser mises à jour ({count}).",
    "contact_event_channels_auto_renew_off": "CS a désactivé le renouvellement automatique pour l’abonnement PV Channels {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS a désactivé le renouvellement automatique via Astro.",
    "contact_event_channels_full_refund": "CS a annulé l’abonnement PV Channels {subscriptionid} avec remboursement intégral.",
    "contact_event_channels_full_refund_static": "CA a annulé l’abonnement avec remboursement intégral",
    "contact_event_channels_no_refund": "CS a annulé l’abonnement PV Channels {subscriptionid} sans remboursement.",
    "contact_event_channels_no_refund_static": "CS a annulé l’abonnement sans remboursement via Astro.",
    "contact_event_channels_workflow_launch": "CS a lancé le flux de travail d’annulation Astro pour l’abonnement PV Channels {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS a lancé le flux de travail d’annulation Astro.",
    "contact_event_coins_adjust": "Type d'ajustement : {type} <br />\nRaison : {reason} <br />\nMontant : {amount} <br />\nCommentaires : {comment} <br />",
    "contact_event_coins_adjust_batched": "Solde de pièces ajusté ({count}).",
    "contact_event_concessions_abuse": "HMD non transmis parce que le client est un utilisateur {risk_status}.",
    "contact_event_contact_seller": "Vendeur contacté pour la commande {order_id}.",
    "contact_event_contact_seller_batched": "Le vendeur à reçu des commandes ({count}).",
    "contact_event_contact_was_initiated_from": "Un contact a été déclenché de l'Asin de produit {ProductAsin} description du produit {ProductDescription} URL du produit {ProductUrl}.",
    "contact_event_content_update": "Contenu de l'ASIN {asin} mis à jour dans la bibliothèque Kindle.",
    "contact_event_content_update_batched": "Contenu de la bibliothèque Kindle mis à jour ({count}).",
    "contact_event_copilot_started": "Copilote démarré",
    "contact_event_copilot_started_batched": "Début de session Copilote ({$hash-get count})",
    "contact_event_credit_card_disabled": "CC désactivé.",
    "contact_event_credit_card_disabled_batched": "Cartes de crédit désactivées ({count}).",
    "contact_event_csc_sds_override_geo_fence_submit": "Demande de dérogation de géorepérage soumise pour le numéro de suivi {trackingID} Raison : {overrideReason} Page : {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Annuler la demande de géorepérage soumise pour le numéro de suivi {trackingId} Raison : {overrideReason} Page : {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Géocode mis à jour. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Géocode mis à jour. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "réussite de l'authentification du client",
    "contact_event_customer_authentication_passed_batched": "réussite de l'authentification du client ({count})",
    "contact_event_customer_name_updated": "Nom de client mis à jour.",
    "contact_event_customer_name_updated_batched": "Nom du client mis à jour ({count}).",
    "contact_event_customer_password_changed": "Le mot de passe du client a été modifié.",
    "contact_event_customer_password_changed_batched": "Mot de passe du client modifié ({count}).",
    "contact_event_delete_alexa_voice_profile": "Profil Alexa Voice supprimé pour speakerId : {speakerId}, ID client : {customerID} et état : {status}",
    "contact_event_delete_data_alexa_comms": "Profil Alexa Comms supprimé pour commsID : {commsId} ,  customerID : {customerID}  et état : {status}",
    "contact_event_deprovision_alexa_comms": "Déprovisionner le profil de commandes d’Alexa pour l’identifiant de la commande :  {commsId};  l’identifiant du client : {customerID}; et le statut : {status}",
    "contact_event_deregister_kindle": "Kindle {serial_number} radié.",
    "contact_event_deregister_kindle_batched": "Kindles enregistrés ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Assistant de dépannage invoqué pour l'appareil avec le DSN {dsn} : {details}.",
    "contact_event_disable_mayday_failed": "La fonction Secours pour le DSN {dsn} de l'appareil n'a pas pu être désactivée.",
    "contact_event_disable_mayday_success": "La fonction Secours pour le DSN {dsn} de l'appareil a bien été désactivée.",
    "contact_event_dunning_pause": "Processus de relance mis en pause - consultez l'historique des paiements et transactions pour plus de détails",
    "contact_event_dvbypost_extra_dispatch": "{amount} envoi(s) supplémentaire(s) demandé(s) pour ce client.",
    "contact_event_dvbypost_extra_envelope": "Une enveloppe supplémentaire a été lancée pour ce client.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 de angefordert",
    "contact_event_dvbypost_problem_code_change": "Configiration du disque {disc_id} sur {problem_code}",
    "contact_event_email_address_updated": "Adresse courriel mise à jour.",
    "contact_event_email_address_updated_batched": "Adresse électronique mise à jour ({count}).",
    "contact_event_email_preferences_changed": "Préférences de courriel modifiées.",
    "contact_event_email_unsubscribe": "Courriel désabonné.",
    "contact_event_email_unsubscribe_batched": "Courriel désabonné ({count}).",
    "contact_event_enable_mayday_failed": "La fonction Secours pour le DSN {dsn} de l'appareil n'a pas pu être réactivée.",
    "contact_event_enable_mayday_success": "La fonction Secours pour le DSN {dsn} de l'appareil a été réactivée avec succès.",
    "contact_event_first_time_user_experience_set": "Régler l'expérience du nouvel utilisateur",
    "contact_event_first_time_user_experience_set_batched": "Régler l'expérience du nouvel utilisateur ({count}).",
    "contact_event_form_submitted": "Formulaire  {form_name} soumis.",
    "contact_event_form_submitted_batched": "Formulaires envoyés ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Abonnement Temps libre illimité annulé pour le client : {customerId}, DSN : {deviceSerialNumber} par {agentId} avec la raison : {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Abonnement Temps libre illimité annulé ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Essai gratuit Prime bien annulé pour {cancel_reason].",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime annulé avec succès ({count}).",
    "contact_event_future_cancel_audible_membership": "L’Annulation future de l’abonnement {membership} a été réglée pour le {date}.",
    "contact_event_future_cancel_audible_membership_batched": "L’Annulation future de l’abonnement {membership} a été réglée pour le {date}.({count})",
    "contact_event_hard_delete_from_yvl": "L'ASIN {asin} a été définitivement supprimé de votre vidéothèque.",
    "contact_event_header": "Registre des évènements",
    "contact_event_hmd_suppression": "HMD n'a pas été envoyé parce que : {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Conversation inactive déconnectée",
    "contact_event_idle_chat_disconnect_batched": "Chat inactif déconnecté ({count})",
    "contact_event_item_price_reduced": "Prix de {asin} modifié de {old_price} à {new_price}.",
    "contact_event_item_price_reduced_batched": "Prix des articles modifiés pour les commandes ({count}).",
    "contact_event_item_quantity_changed": "Quantité de {asin} dans la commande {order_id} modifiée de {old_quantity} à {new_quantity}.",
    "contact_event_item_quantity_changed_batched": "Quantités d'articles modifiées pour les commandes ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Remplacement créé pour DSN {dsn}  à partir de la commande {old_order_id}. Nouvelle commande : {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Appareils Kindle remplacés ({count}).",
    "contact_event_kindle_email_address_edits": "Adresse électronique mise à jour sur Kindle (DSN n° {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Adresses électroniques mises à jour pour les Kindles ({count}).",
    "contact_event_kindle_email_edits": "{DSN}envoyer à l'adresse électronique a été modifié de  {original_email} à {new_email}",
    "contact_event_kindle_name_changed": "Kindle avec nom de DSN {dsn} modifié de {original_name} à {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nom Kindle modifié ({count}).",
    "contact_event_last_page_read_deleted": "Dernière page de l'ASIN {asin} supprimée.",
    "contact_event_last_page_read_deleted_batched": "Dernière page lue supprimée ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing lancé pour DSN : {dsn}, kcsID: {kcsId}, réponse du client : {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing lancé pour les appareils ({count}).",
    "contact_event_lock_dash_order": "L'ASIN suivant pour l'appareil {dsn} a été verrouillé : {asins}",
    "contact_event_music_auto_renew_off": "CS a désactivé le renouvellement automatique de l’abonnement Music {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS a désactivé le renouvellement automatique de l’abonnement Music via Astro.",
    "contact_event_music_full_refund": "CA a annulé l’abonnement Music {subscriptionid} avec remboursement intégral",
    "contact_event_music_full_refund_static": "CS a annulé l’abonnement Music avec remboursement intégral via Astro.",
    "contact_event_music_no_refund": "CS a annulé l’abonnement Music {subscriptionid} sans remboursement.",
    "contact_event_music_no_refund_static": "CS a annulé l’abonnement Music sans remboursement via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS a désactivé le renouvellement automatique de l’abonnement à Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS a annulé l’abonnement à Amazon Music Unlimited {subscriptionid} avec remboursement complet.",
    "contact_event_music_unlimited_no_refund": "CS a annulé l’abonnement à Amazon Music Unlimited {subscriptionid} sans remboursement.",
    "contact_event_music_unlimited_partial_refund": "CS a annulé l’abonnement à Amazon Music Unlimited {subscriptionid} avec remboursement partiel.",
    "contact_event_music_unlimited_workflow_launch": "CS a lancé le processus d’annulation Astro pour l’abonnement à Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS a lancé le flux de travail d’annulation Astro pour l’abonnement Music {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS a lancé le flux de travail d’annulation Astro pour Music illimitée.",
    "contact_event_non_dsv_refund_created": "La commande numérique {order_id} a été remboursée à hauteur de {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Commandes numériques remboursées ({count}).",
    "contact_event_one_click_address_updated": "1-Cliquez sur l'adresse mise à jour.",
    "contact_event_one_click_address_updated_batched": "Adresse en un seul clic mise à jour ({count}).",
    "contact_event_one_click_disabled": "1-Cliquez sur désactivé.",
    "contact_event_one_click_disabled_batched": "En un seul clic désactivé ({count}).",
    "contact_event_one_click_enabled": "1-Cliquez sur activé.",
    "contact_event_one_click_payment_updated": "Paiement en un seul clic mis à jour.",
    "contact_event_one_click_payment_updated_batched": "Paiement en un seul clic mis à jour ({count}).",
    "contact_event_order_cancelled": "Articles appelés dans l'ordre {order_id}.",
    "contact_event_order_cancelled_batched": "Commandes annulées ({count}).",
    "contact_event_order_placed": "Commande passée {order_id}.",
    "contact_event_order_placed_batched": "Commandes passées ({count}).",
    "contact_event_payment_method_added": "Méthode de paiement {payment_type} ajouté au compte.",
    "contact_event_payment_method_added_batched": "Méthodes de paiement ajoutées au compte ({count}).",
    "contact_event_payment_method_changed": "La méthode de paiement a été modifiée pour la commande {order_id}.",
    "contact_event_payment_method_changed_batched": "La méthode de paiement a été modifiée pour les commandes ({count})",
    "contact_event_pdocs_max_price_edits": "Frais de conversion max. de documents personnels changés pour {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Frais de conversion maximaux des paramètres des documents personnels mis à jour ({count}).",
    "contact_event_pennywise_promo_applied": "Promotion Pennywise appliquée pour DSN : {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promotion Pennywise demandée pour les DSN : ({count})",
    "contact_event_ping_device": "Appareil avec le DSN {dsn} sondé.",
    "contact_event_ping_device_batched": "Appareils sondés ({count}).",
    "contact_event_prime_auto_renew_settings": "Renouvellement automatique de Prime défini sur {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Renouvellement automatique de Prime défini sur {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Prime bien annulé de PrimeCancelException. Motif d'annulation : {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime annulé.",
    "contact_event_prime_cancelled_batched": "Prime annulée ({count}).",
    "contact_event_prime_concession": "Première concession publiée pour la commande {order_id}.",
    "contact_event_prime_concession_batched": "Les concessions Prime émises pour les commandes ({count}).",
    "contact_event_prime_refund": "Prime remboursé {amount}.",
    "contact_event_prime_refund_batched": "Prime remboursé ({count}).",
    "contact_event_promo_added_to_account": "Promotion {amount} ajoutée à votre compte.",
    "contact_event_promo_added_to_account_batched": "Promotions ajoutées au compte ({count}).",
    "contact_event_promo_unredeemed": "Promo {promoBalance} non échangée.",
    "contact_event_promo_unredeemed_batched": "Promos non échangées ({count}).",
    "contact_event_refund_cancelled": "Remboursement annulé pour la commande {order_id}.",
    "contact_event_refund_created": "Remboursé {refund_amount} pour la commande {order_id}.",
    "contact_event_refund_created_batched": "Commandes remboursées ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} remboursé de PrimeCancelException. Motif de remboursement : {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Rembourser l'exception d'annulation de Prime ({count}).",
    "contact_event_register_kindle": "Kindle {serial_number} enregistré.",
    "contact_event_register_kindle_batched": "Kindles enregistrés ({count}).",
    "contact_event_registry_deleted": "Registre supprimé (Id de registre n° {registry_id}).",
    "contact_event_registry_deleted_batched": "Registres supprimés ({count}).",
    "contact_event_release_aiv_license": "La licence d'AIV {asin} a été envoyée sur le DSN {dsn} de l'appareil.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} nombre d'articles déplacés avec le DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Articles supprimés des appareils ({count}).",
    "contact_event_remove_from_yvl": "L'ASIN {asin} a été supprimé de votre vidéothèque et placé dans les articles supprimés.",
    "contact_event_remove_kindle_from_blacklist": "Kindle pour DSN {dsn} retiré du statut volé ou perdu, raison : {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Appareils retirés du statut perdu ou volé ({count}).",
    "contact_event_replacement_created": "Remplacement créé pour la commande {old_order_id}. Nouvelle commande : {new_order_id}.",
    "contact_event_replacement_created_batched": "Remplacements créés pour les commandes ({count}).",
    "contact_event_request_invoice": "Demande de facture envoyée au vendeur pour la commande {order_id}.",
    "contact_event_request_invoice_batched": "Demande de facture envoyée pour les commandes ({count}).",
    "contact_event_resend_kindle_content": "Renvoyer le contenu numérique {title} au DSN {dsn} avec le type d'appareil {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Contenu numérique renvoyé ({count}).",
    "contact_event_resent_gifted_content": "ASIN {asin} de l'email du destinataire de l'ID de commande {order_id} modifié.",
    "contact_event_resent_gifted_content_batched": "Courriel du récipiendaire pour la commande cadeau mis à jour ({count}).",
    "contact_event_reset_password_email_sent": "Courriel de réinitialisation de mot de passe envoyé.",
    "contact_event_reset_password_email_sent_batched": "Courriels de réinitialisation de mot de passe envoyés ({count}).",
    "contact_event_residence_address_changed": "L'adresse de résidence a changé.",
    "contact_event_residence_address_changed_batched": "Adresse de résidence mise à jour ({count}).",
    "contact_event_rma_cancelled": "RMA {rma_id} annulé pour la commande {order_id}.",
    "contact_event_rma_created": "RMA {rma_id} créé pour la commande {order_id}.",
    "contact_event_rma_created_batched": "RMA créés pour les commandes ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nom du DP : {driverName}, \nNom de la station : {stationName}, \nNuméro de suivi : {trackingId} et \nNuméro de la commande : {orderId}\nIdentifiant de la session du cas d’utilisation : {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget de cartographie ouvert pour le numéro de suivi {trackingId}",
    "contact_event_sds_package_repick": "L’agent du service a initié un nouveau ramassage pour numéro de suivi {trackingId}",
    "contact_event_send_aiv_asin": "L'ASIN {asin} a été envoyé à votre vidéothèque du client {customer_id}.",
    "contact_event_ship_charge_reduce": "Frais d'expédition de la commande{order_id} changé de {old_amount} à {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Frais d'expédition modifiés ({count}).",
    "contact_event_ship_method_upgraded": "La méthode d'expédition pour la commande {order_id} a été mise à jour.",
    "contact_event_ship_method_upgraded_batched": "Méthodes d'expédition modifiées pour les commandes ({count}).",
    "contact_event_shipping_address_changed": "Adresse modifiée sur la commande  {new_order_id}.",
    "contact_event_sim_ticket_created": "Ticket SIM créé",
    "contact_event_special_offers_enrollment": "Kindle avec les offres spéciales {enrollment_status} du DSN {dsn}.",
    "contact_event_special_offers_enrollment_batched": "Statut d'inscription aux offres spéciales mis à jour ({count}).",
    "contact_event_subscribe_and_save_cancelled": "S'abonner et enregistrer annulé pour la commande {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "S'abonner et économiser annulé pour les abonnements ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Calendrier d'expédition pour S'abonner et enregistrer modifié dans la commande {order_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Les calendriers d'expédition S'abonner et économiser modifiés pour les abonnements ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS a lancé le processus d’annulation Astro pour {subscriptionType} l’abonnement {subscriptionId}.",
    "contact_event_subscription_auto_renew": "Le renouvellement automatique {plan_type} est réglé à {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Paramètres de renouvellement automatique de Prime mis à jour ({count}).",
    "contact_event_subscription_auto_renew_off": "CS a désactivé le renouvellement automatique pour {subscriptionType} l’abonnement {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS a annulé {subscriptionType} l’abonnement {subscriptionId} avec remboursement complet.",
    "contact_event_subscription_cancel_no_refund": "CS a annulé {subscriptionType} l’abonnement {subscriptionId} sans remboursement.",
    "contact_event_subscription_cancel_partial_refund": "CS a annulé {subscriptionType} l’abonnement {subscriptionId} avec remboursement partiel.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "L'abonnement {plan_type} est configuré pour être renouvelé de {new_plan_name} de {old_plan_name} à la fin de période actuelle.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Paramètres du programme de retour Prime mis à jour ({count}).",
    "contact_event_troubleshooting_wizard": "Assistant de dépannage invoqué pour appareil avec le DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Assistant de dépannage invoqué pour les appareils ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO : {annotation} pour l'appareil avec le DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Assistant de dépannage invoqué pour les appareils ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Assistant de résolution des problèmes pour les commandes numériques avec ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "Le statut de l'achat international passé de bloqué à {new_status}",
    "contact_event_unblock_international_purchase_batched": "Statut des achats internationaux mis à jour ({count}).",
    "contact_event_unlock_dash_order": "L'ASIN suivant pour l'appareil {dsn} a été déverrouillé : {asins}",
    "contact_event_update_locking_status": "L'état de verrouillage de l'appareil Oft {dsn} a été mis à jour à : {newLockingStatus}",
    "contact_event_verify_email": "Courriel de vérification envoyé au client.",
    "contact_event_weekend_delivery_preference_changed": "Préférences de livraison la fin de semaine mises à jour"
}