import {ContextualAlert} from "./Contact.types";
import React from "react";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import ContextualAlertsDisplay from "./ContextualAlertDisplay";

interface ContactUsSyskaDisplayProps {
    alerts?: ContextualAlert[];
}

const ContactUsSyskaDisplay: React.FC<ContactUsSyskaDisplayProps> = ({alerts}) => {
    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId="CONTACT_US_ALERT_TITLE"/></h4>
            {(alerts && alerts.length) ?
                <ContextualAlertsDisplay
                    alerts={alerts}
                />
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CONTACT_US_ALERT")}/>}
        </div>);
};


export default ContactUsSyskaDisplay;