export const ja_JP = {
    "contact_event_GC_added": "GC #{gcId} がアカウントに追加されました。",
    "contact_event_GC_added_batched": "GC がアカウントに追加されました（{count}）。",
    "contact_event_GC_added_purchaser": "GC #{gcId} が購入者アカウントに追加されました。",
    "contact_event_GC_added_purchaser_batched": "GC が購入者アカウントに追加されました（{count}）。",
    "contact_event_GC_added_recipient": "GC #{gcId} が受取人アカウントに追加されました。",
    "contact_event_GC_added_recipient_batched": "GC が受取人アカウントに追加されました（{count}）。",
    "contact_event_GC_added_scratchedCode": "GC＃$ {gcId}は、スクラッチされたクレームコードツールを使用してアカウントに追加されました。",
    "contact_event_GC_added_scratchedCode_batched": "GCがスクラッチされたクレームコードツール（$ {count}）を介してアカウントに追加されました。",
    "contact_event_GC_cancelled": "GC #{gcId} がキャンセルされました。",
    "contact_event_GC_cancelled_batched": "GC がキャンセルされました（{count}）。",
    "contact_event_GC_changed_expiry": "GC #{gcId} の有効期限が変更されました。",
    "contact_event_GC_changed_expiry_batched": "GC（{count}）の有効期限が変更されました。",
    "contact_event_GC_locked": "GC #{gcId} がロックされました。",
    "contact_event_GC_locked_batched": "GC はロックされました（{count}）。",
    "contact_event_GC_removed": "GC #{gcId} がアカウントから削除されました。",
    "contact_event_GC_removed_batched": "GC がアカウントから削除されました（{count}）。",
    "contact_event_GC_resent_email": "GC #{gcId} がEメール Id {deliveryEmail} に再送信されました。",
    "contact_event_GC_resent_email_batched": "GC がEメール Id（{count}）に再送信されました。",
    "contact_event_GC_resent_post": "GC #{gcId} が再度郵送されました。",
    "contact_event_GC_resent_post_batched": "GC が再度郵送されました（{count}）。",
    "contact_event_GC_temporary_locked": "GC #{gcId} が一時的にロックされました。",
    "contact_event_GC_temporary_locked_batched": "GC は一時的にロックされました（{count}）。",
    "contact_event_GC_transferred": "GC {gcId} がカスタマー {customerID} から {toAccountCustomerID} に転送されました。",
    "contact_event_GC_transferred_batched": "GC が転送されました（{count}）。",
    "contact_event_GC_unlocked": "GC #{gcId} がロック解除されました。",
    "contact_event_GC_unlocked_batched": "GC はロック解除されました（{count}）。",
    "contact_event_PV_added": "PV#{pvId}がアカウントに追加されました。",
    "contact_event_PV_locked": "PV#{pvId}はロックされました。",
    "contact_event_PV_temporary_locked": "PV#{pvId}は一時的にロックされました。",
    "contact_event_PV_unlocked": "PV#{pvId}はロック解除されました。",
    "contact_event_a_to_z_guarantee_cancelled_batched": "注文の Amazon マーケットプレイス保証クレームがキャンセルされました（{count}）。",
    "contact_event_a_to_z_guarantee_requested": "注文 {order_id} の Amazon マーケットプレイス保証申請が送信されました。",
    "contact_event_a_to_z_guarantee_requested_batched": "注文の Amazon マーケットプレイス保証クレームが送信されました（{count}）。",
    "contact_event_a_to_z_guarantee_requested_gwp": "注文{order_id}に対してAmazonマーケットプレイス保証申請が提出されました 。",
    "contact_event_access_code_added": "アクセスコードが追加されました",
    "contact_event_account_closed": "口座は閉鎖されました。",
    "contact_event_account_closed_batched": "口座は閉鎖されました（{count}）。",
    "contact_event_account_reopened": "アカウントが再開されました",
    "contact_event_account_reopened_batched": "アカウントが再開されました ({count})",
    "contact_event_account_temporarily_disabled": "アカウントがを一時的に無効になっています。",
    "contact_event_account_temporarily_disabled_batched": "アカウントがを一時的に無効になっています ({count})",
    "contact_event_account_whitelisted": "アカウントは許可リストに登録されています。",
    "contact_event_address_changed": "注文 {order_id} の {address_type} の住所が更新されました。",
    "contact_event_address_changed_batched": "注文の住所が更新されました（{count}）。",
    "contact_event_address_deactivated": "アドレスが非アクティブ化されました。",
    "contact_event_address_deactivated_batched": "アドレスが非アクティブ化されました（{count}）。",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazonビデオの会員登録が自動更新されました",
    "contact_event_amazon_video_subscription_cancelled": "Amazonビデオの会員登録がキャンセルされました",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "送信されたフォーム: DSLエスカレーションフォーム\nユースケースセッションID: {usecaseSessionID}\n配送ステーション: {deliveryStationField}\n要求タイプ: {requestTypeField}\nコンタクトID: {contactIdField}\n注文番号: {orderIdField}\n購入者のEメールアドレス: {customerEmailField}\n購入者の電話番号: {customerPhoneNumberField}\nTBA: {tbasField}\nアクセスコード (該当する場合): {accessCodeField}\n問題の概要: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "送信済みのDSL エスカレーションフォーム: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "提出されたフォーム: DSP DAフィードバックフォーム\nユースケースセッションID: {usecaseSessionID}\n配送ステーションコード: {deliveryStationField}\nコンタクトID: {contactIdField}\n購入者アカウントID: {customerIdField}\nDA運送業者ID: {driverIdField}\n事象発生日 (YYYY-MM-DD): {dateOfOccurrenceField}\n事象発生時間 (HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nフィードバックの送信元: {sourceOfFeedbackField}\nフィードバックの詳細: {issueField}\nその他の詳細: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "送信済みのDSP DA フィードバックフォーム: ({count})",
    "contact_event_andon_cord_pulled": "{asin} にアンドンコードが発せられました。",
    "contact_event_andon_cord_pulled_batched": "商品にアンドンコードが発せられました（{count}）。",
    "contact_event_apply_adrive_music_trial": "カスタマー {customer_id} に Cloud Player プレミアムが適用されました。{number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "アカウントは{marketplaceName}に移行されました。",
    "contact_event_audible_account_migrated_batched": "Audibleアカウントは移行されました ({count})。",
    "contact_event_audible_cash_and_coupon_refund": "{title}は{cashAmount}の額で返金されました。 また、このトランザクションは、{couponAmount}のクーポンがアカウントに戻りました。{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title}は{cashAmount}の額で返金されました。 また、このトランザクションは、{couponAmount}のクーポンがアカウントに戻りました。{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title}は{cashAmount}の額で返金されました。{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title}は{cashAmount}の額で返金されました。{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title}は返金され、{couponAmount}のクーポンがアカウントに戻りました。{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title}は返金され、{couponAmount}のクーポンがアカウントに戻りました。{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount}のクレジットがアカウントから削除されました。",
    "contact_event_audible_credit_issued": "{numCredit}クレジットがアカウントに追加されました。",
    "contact_event_audible_credit_issued_batched": "クレジットがアカウントに追加されました（{count}）。",
    "contact_event_audible_credit_refund": "{title}は返金され、{creditAmount}のクレジットがアカウントに戻りました。{counter}",
    "contact_event_audible_credit_refund_batched": "{title}は返金され、{creditAmount}のクレジットがアカウントに戻りました。{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount}のクレジットがアカウントに復元されました。",
    "contact_event_audible_credit_restored_batched": "{amount}のクレジットがアカウントに復元されました。({count})",
    "contact_event_audible_extra_credit_purchased": "3クレジットを追加購入しました。",
    "contact_event_audible_extra_credit_purchased_batched": "3クレジットを追加購入しました。({count})",
    "contact_event_audible_hiatus_cancelled": "保留期間が早く終了し、メンバーシップが再開されました。",
    "contact_event_audible_hiatus_cancelled_batched": "保留期間が早く終了し、メンバーシップが再開されました。({count})",
    "contact_event_audible_hiatus_started": "メンバーシップは{duration}-月の保留で確定されました。",
    "contact_event_audible_hiatus_started_batched": "メンバーシップは{duration}-月の保留で確定されました。({count})",
    "contact_event_audible_membership_enrollment": "以下に登録済み \n{membershipPlan}メンバーシッププラン。",
    "contact_event_audible_membership_enrollment_batched": "Audibleメンバーシップに登録されました ({count})。",
    "contact_event_audible_membership_plan_refund": "メンバーシップ手数料は{cashAmount}の額で返金されました。{counter}",
    "contact_event_audible_membership_plan_refund_batched": "メンバーシップ手数料は{cashAmount}の額で返金されました。{counter} ({count})",
    "contact_event_audible_membership_switched": "{oldMembershipPlan}から{newMembershipPlan}のメンバーシッププランに変更されました。",
    "contact_event_audible_membership_switched_batched": "Audibleメンバーシッププランが変更されました ({count})。",
    "contact_event_audible_romance_package_enrollment": "{packageName}に登録されます。",
    "contact_event_audible_romance_package_enrollment_batched": "{packageName}に登録されます。({count})",
    "contact_event_audible_wizard_invoked": "{wizardName}にアクセスしました。",
    "contact_event_audible_wizard_invoked_batched": "{wizardName}にアクセスしました。({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "{membership} の退会手続きが完了しました。",
    "contact_event_blacklist_kindle": "端末 {serial_number} はブラックリストに入れられました。",
    "contact_event_blacklist_kindle_batched": "Kindle がブラックリストに載せられました（{count}）。",
    "contact_event_bound_warranty": "保証 ID {warranty_id} はデバイス {dsn} にバインドされました。",
    "contact_event_bound_warranty_batched": "保証がデバイスに付けられました（{count}）。",
    "contact_event_call_was_flagged": "この連絡先には{callFlagSubject}としてフラグが立てられました: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "この連絡先には{callFlagSubject}としてフラグが立てられました: {callFlagDescription}（{count}）",
    "contact_event_cancel_audible_membership": "{membership} の退会手続きが完了しました。",
    "contact_event_cancel_audible_membership_batched": "{membership} の退会手続きが完了しました。({count})",
    "contact_event_cancel_audible_romance_package_batched": "[MEMBERSHIP PLAN] の退会手続きが完了しました。",
    "contact_event_cancel_from_prime_cancel_exception": "PrimeCancelException からプライムが正常にキャンセルされました。キャンセルの理由: {cancel_reason}。",
    "contact_event_cancel_from_prime_cancel_exception_batched": "プライムの例外をキャンセルします（{count}）。",
    "contact_event_cancel_future_cancellation_audible_membership": "今後のキャンセル日以前に設定はキャンセルされました。",
    "contact_event_cancelled_sns_reactivated": "ASIN {asin} の購読登録は {login} によって{reactivation_date}（月）に再アクティブ化されました",
    "contact_event_cancelled_sns_reactivated_batched": "キャンセルされた「会員登録＆割引」購読登録が再アクティブ化されました（{count}）。",
    "contact_event_captcha_bypass_key_generated": "Captcha Bypass キーが生成されました。",
    "contact_event_captcha_bypass_key_generated_batched": "Captcha Bypass キーが生成されました（{count}）。",
    "contact_event_change_sns_quantity": "ASIN {asin} の定期おトク便は数量{current_quantity}から数量{new_quantity}に設定されました。",
    "contact_event_change_sns_quantity_batched": "「会員登録＆割引」の数量が更新されました（{count}）。",
    "contact_event_channels_auto_renew_off": "CSは PV チャンネルのサブスクリプション{subscriptionid}の自動更新をオフにしました。",
    "contact_event_channels_auto_renew_off_static": "CSはAstro経由で自動更新をオフにしました。",
    "contact_event_channels_full_refund": "CSはPVチャンネルのサブスクリプション{subscriptionid}をキャンセルし、全額返金されます。",
    "contact_event_channels_full_refund_static": "CSはAstro経由での全額返金でサブスクリプションをキャンセルしました",
    "contact_event_channels_no_refund": "CSは払い戻しなしでPVチャンネルのサブスクリプション{subscriptionid}をキャンセルしました。",
    "contact_event_channels_no_refund_static": "CSはAstro経由で払い戻しなしでサブスクリプションをキャンセルしました。",
    "contact_event_channels_workflow_launch": "CSは、PVチャンネルのサブスクリプションのAstroキャンセルワークフローを開始しました {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CSはAstroキャンセルワークフローを開始しました。",
    "contact_event_coins_adjust": "調整タイプ: {type} <br />\n理由: {reason} <br />\n金額: {amount} <br />\nコメント: {comment} <br />",
    "contact_event_coins_adjust_batched": "調整後のコイン残高（{count}）。",
    "contact_event_concessions_abuse": "お客様が{risk_status}ユーザーのため、HMDは送信されませんでした。",
    "contact_event_contact_seller": "出品者へ注文 {order_id} に関して連絡しました。",
    "contact_event_contact_seller_batched": "出品者が注文に関して連絡しました（{count}）。",
    "contact_event_contact_was_initiated_from": "連絡先は、ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}から開始されました",
    "contact_event_content_update": "ASIN {asin} のコンテンツは Kindle ライブラリで更新されました。",
    "contact_event_content_update_batched": "Kindle ライブラリのコンテンツが更新されました（{count}）。",
    "contact_event_copilot_started": "Co-Pilot が開始されました",
    "contact_event_copilot_started_batched": "Co-Pilotを開始しました ({count})",
    "contact_event_credit_card_disabled": "クレジットカードが無効化されました。",
    "contact_event_credit_card_disabled_batched": "クレジットカードが非アクティブ化されました（{count}）。",
    "contact_event_csc_sds_override_geo_fence_submit": "お問い合わせ伝票番号{trackingId}についてジオフェンスをオーバーライドするリクエストが送信されました。理由：{overrideReason} ページ:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "お問い合わせ伝票番号{trackingId}についてジオフェンスをオーバーライドするリクエストが送信されました。理由: {overrideReason}。ページ:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "ジオコードが更新されました。ジオコードタイプ：{geocodeType}、トラッキングID：{trackingId}、アドレスID：{addressId}、上書き理由：{overrideReason}、ページ識別子：{pageIdentifier}、",
    "contact_event_csc_sds_updated_geocode": "ジオコードが更新されました。ジオコードタイプ：{geocodeType}、上書き理由：{overrideReason}、トラッキングID：{trackingId}、アドレスID：{addressId}、およびページ識別子：{pageIdentifier}",
    "contact_event_customer_authentication_passed": "カスタマー認証済み",
    "contact_event_customer_authentication_passed_batched": "カスタマー認証済み（{count}）",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "カスタマー名が更新されました。",
    "contact_event_customer_name_updated_batched": "カスタマー名が更新されました（{count}）。",
    "contact_event_customer_password_changed": "パスワードが変更されました。",
    "contact_event_customer_password_changed_batched": "カスタマーのパスワードが変更されました（{count}）。",
    "contact_event_delete_alexa_voice_profile": "次のAlexa音声プロフィールは削除されました。speakerId : {speakerId}、customerID : {customerID}、status : {status}",
    "contact_event_delete_data_alexa_comms": "次のAlexa通信プロフィールは削除されました。commsID : {commsId}、customerID : {customerID}、status : {status}",
    "contact_event_deprovision_alexa_comms": "commsID : {commsId}、customerID : {customerID}およびステータス : {status}のAlexa通信プロファイルがプロビジョニング解除されました",
    "contact_event_deregister_kindle": "Kindle {serial_number} は再登録されました。",
    "contact_event_deregister_kindle_batched": "Kindle が登録解除されました（{count}）。",
    "contact_event_detailed_troubleshooting_wizard": "DSN {dsn}: {details} のデバイスのためにトラブルシューティングウィザードが呼び出されました。",
    "contact_event_disable_mayday_failed": "端末 DSN {dsn} の Mayday 機能の非アクティブ化に失敗しました。",
    "contact_event_disable_mayday_success": "端末 DSN {dsn} の Mayday 機能が正常に非アクティブ化されました。",
    "contact_event_dunning_pause": "督促プロセスが一時停止されました - 詳細については、「支払い＆取引履歴」をご覧ください",
    "contact_event_dvbypost_extra_dispatch": "このカスタマー宛てにその他{amount}件の発送がリクエストされました。",
    "contact_event_dvbypost_extra_envelope": "このカスタマー宛てにその他のエンベロープがトリガされました。",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 フォーム「angefordert」",
    "contact_event_dvbypost_problem_code_change": "{problem_code} にディスク {disc_id} を設定",
    "contact_event_email_address_updated": "Eメールアドレスが更新されました。",
    "contact_event_email_address_updated_batched": "Eメールアドレスが更新されました（{count}）。",
    "contact_event_email_preferences_changed": "Eメールのプレファレンスが変更されました。",
    "contact_event_email_unsubscribe": "Eメールが配信停止されました。",
    "contact_event_email_unsubscribe_batched": "Eメールが配信停止されました（{count}）。",
    "contact_event_enable_mayday_failed": "端末 DSN {dsn} の Mayday 機能の再アクティブ化に失敗しました。",
    "contact_event_enable_mayday_success": "端末 DSN {dsn} の Mayday 機能が正常に再アクティブ化されました。",
    "contact_event_first_time_user_experience_set": "初回のユーザー体験を設定",
    "contact_event_first_time_user_experience_set_batched": "初回のユーザー体験を設定（{count}）します。",
    "contact_event_form_submitted": "フォーム {form_name} が送信されました。",
    "contact_event_form_submitted_batched": "フォームが送信されました（{count}）。",
    "contact_event_free_time_unlimited_cancelled": "顧客: {customerId}、DSN: {deviceSerialNumber} の Free Time Unlimited の購読が、{reason}を理由に {agentId} によりキャンセルされました。",
    "contact_event_free_time_unlimited_cancelled_batched": "Free Time Unlimited の購読がキャンセルされました（{count}）",
    "contact_event_free_trial_prime_cancel_exception": "{cancel_reason}により、プライム無料体験が正常にキャンセルされました。",
    "contact_event_free_trial_prime_cancel_exception_batched": "プライムが正常にキャンセルされました（{count}）。",
    "contact_event_future_cancel_audible_membership": "{membership}プランは{date}にキャンセルに設定されています。",
    "contact_event_future_cancel_audible_membership_batched": "{membership}プランは{date}にキャンセルに設定されています。({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} は動画ライブラリから永久に削除されました。",
    "contact_event_header": "イベントログ",
    "contact_event_hmd_suppression": "HMDは送信されませんでした。理由：{suppression_message}",
    "contact_event_idle_chat_disconnect": "アイドル状態のチャットが切断されました",
    "contact_event_idle_chat_disconnect_batched": "アイドル状態のチャットが切断されました ({count})",
    "contact_event_item_price_reduced": "{order_id}の注文について商品の価格が{old_price}から{new_price}に変更されました。",
    "contact_event_item_price_reduced_batched": "注文の商品の価格が変更されました（{count}）。",
    "contact_event_item_quantity_changed": "{order_id} の注文について商品の数量が変更されました。",
    "contact_event_item_quantity_changed_batched": "注文の商品の数量が変更されました（{count}）。",
    "contact_event_kindle_device_replacement": "注文 {old_order_id} の交換が DSN {dsn} に作成されました。新しい注文: {new_order_id}。",
    "contact_event_kindle_device_replacement_batched": "Kindle デバイスが交換されました（{count}）。",
    "contact_event_kindle_email_address_edits": "Kindle のEメールアドレスが更新されました（DSN # {dsn}）。",
    "contact_event_kindle_email_address_edits_batched": "Kindle のEメールアドレスが更新されました（{count}）。",
    "contact_event_kindle_email_edits": "{dsn}の送信先Eメールアドレスは、{original_email} から {new_email} へ変更されました。",
    "contact_event_kindle_name_changed": "DSN {dsn} の Kindle 名は、{original_name} から {new_name} に変更されました。",
    "contact_event_kindle_name_changed_batched": "Kindle の名前が変更されました（{count}）。",
    "contact_event_last_page_read_deleted": "ASIN {asin} の読んだ最後のページは、削除されました。",
    "contact_event_last_page_read_deleted_batched": "しおりが削除されました（{count}）。",
    "contact_event_live_assist_launched": "DSN で起動された Mayday Screen Sharing: {dsn}、kcsID: {kcsId}、カスタマーの応答: {customerResponse}。",
    "contact_event_live_assist_launched_batched": "デバイスで起動された Mayday Screen Sharing（{count}）。",
    "contact_event_lock_dash_order": "端末「 {dsn}  」の以下の ASIN はロックされました: {asins}。",
    "contact_event_music_auto_renew_off": "CSはMusic定期購読の自動更新をオフにしました{subscriptionid}。",
    "contact_event_music_auto_renew_off_static": "CSはAstro経由のMusic定期購読の自動更新をオフにしました。",
    "contact_event_music_full_refund": "CSは全額返金でMusic定期購読{subscriptionid}をキャンセルしました",
    "contact_event_music_full_refund_static": "CSはAstro経由での全額返金でMusic定期購読をキャンセルしました。",
    "contact_event_music_no_refund": "CSは払い戻しなしでMusicの定期購読{subscriptionid}をキャンセルしました。",
    "contact_event_music_no_refund_static": "CSはAstro経由で払い戻しなしでMusicの定期購読をキャンセルしました。",
    "contact_event_music_unlimited_auto_renew_off": "CSはAmazon Music Unlimitedの定期購読{subscriptionid}の自動更新をオフにしました。",
    "contact_event_music_unlimited_full_refund": "CSはAmazon Music Unlimitedの定期購読{subscriptionid}を、全額返金してキャンセルしました。",
    "contact_event_music_unlimited_no_refund": "CSはAmazon Music Unlimitedの定期購読{subscriptionid}を、返金なしでキャンセルしました。",
    "contact_event_music_unlimited_partial_refund": "CSはAmazon Music Unlimitedの定期購読{subscriptionid}を、一部返金してキャンセルしました。",
    "contact_event_music_unlimited_workflow_launch": "CSはAmazon Music Unlimitedの定期購読{subscriptionid}のAstroキャンセルワークフローを開始しました。",
    "contact_event_music_workflow_launch": "CSはMusic定期購読のAstroキャンセルワークフローを開始しました{subscriptionid}。",
    "contact_event_music_workflow_launch_static": "CSはMusic UnlimitedのAstroキャンセルワークフローを開始しました。",
    "contact_event_non_dsv_refund_created": "デジタル注文 {order_id} に{refund_amount}が払い戻されました。",
    "contact_event_non_dsv_refund_created_batched": "デジタル注文が払い戻されました（{count}）。",
    "contact_event_one_click_address_updated": "1-Click の住所が更新されました。",
    "contact_event_one_click_address_updated_batched": "1-Click の住所が更新されました（{count}）。",
    "contact_event_one_click_disabled": "1-Click が無効になりました。",
    "contact_event_one_click_disabled_batched": "1-Click が無効になりました（{count}）。",
    "contact_event_one_click_enabled": "1-Click が有効になりました。",
    "contact_event_one_click_payment_updated": "1-Click 支払いが更新されました。",
    "contact_event_one_click_payment_updated_batched": "1-Click 支払いが更新されました（{count}）。",
    "contact_event_order_cancelled": "商品が注文 {order_id} でキャンセルされました。",
    "contact_event_order_cancelled_batched": "注文がキャンセルされました（{count}）。",
    "contact_event_order_placed": "注文 {order_id} が確定されました。",
    "contact_event_order_placed_batched": "注文が確定されました（{count}）。",
    "contact_event_payment_method_added": "お支払い方法 {payment_type} がアカウントに追加されました。",
    "contact_event_payment_method_added_batched": "支払い方法がアカウントに追加されました（{count}）。",
    "contact_event_payment_method_changed": "注文 {order_id} のお支払い方法が変更されました。",
    "contact_event_payment_method_changed_batched": "注文のお支払い方法が変更されました（{count}）。",
    "contact_event_pdocs_max_price_edits": "個人文書設定の最大変換料は {new_max_charge} に変更されました。",
    "contact_event_pdocs_max_price_edits_batched": "個人文書設定の最大変換料が更新されました（{count}）。",
    "contact_event_pennywise_promo_applied": "Pennywise PromotionがDSNに適用されました: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Pennywise PromotionがDSNに適用されました: ({count})",
    "contact_event_ping_device": "DSN {dsn} のデバイスが ping されました。",
    "contact_event_ping_device_batched": "デバイスが ping されました（{count}）。",
    "contact_event_prime_auto_renew_settings": "プライムの自動更新が {auto_renew_state} に設定されました。",
    "contact_event_prime_auto_revew_settings": "プライムの自動更新が {auto_renew_state} に設定されました。",
    "contact_event_prime_cancel_exception": "PrimeCancelException からプライムが正常にキャンセルされました。キャンセルの理由: {cancel_reason}。",
    "contact_event_prime_cancelled": "プライムがキャンセルされました。",
    "contact_event_prime_cancelled_batched": "プライムがキャンセルされました（{count}）。",
    "contact_event_prime_concession": "{order_id}の注文についてプライムのコンセッションが発行されました。",
    "contact_event_prime_concession_batched": "注文にプライム割引が適用されました（{count}）。",
    "contact_event_prime_refund": "プライムで {amount} が払い戻されました。",
    "contact_event_prime_refund_batched": "プライムが払い戻されました（{count}）。",
    "contact_event_promo_added_to_account": "{reason}のため{amount} のクーポンがアカウントに追加されました。",
    "contact_event_promo_added_to_account_batched": "プロモーションがアカウントに追加されました（{count}）。",
    "contact_event_promo_unredeemed": "{promoBalance}????????????????????????",
    "contact_event_promo_unredeemed_batched": "プロモーションが商品と引き換えられていません（{count}）。",
    "contact_event_refund_cancelled": "注文 {order_id} の払い戻しがキャンセルされました。",
    "contact_event_refund_created": "{refund_amount}が注文 {order_id} に払い戻されました。",
    "contact_event_refund_created_batched": "注文が払い戻されました（{count}）。",
    "contact_event_refund_from_prime_cancel_exception": "PrimeCancelException から{refund_amount}が払い戻されました。払い戻しの理由: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "プライムのキャンセル例外を払い戻します（{count}）。",
    "contact_event_register_kindle": "Kindle {serial_number} は登録されました。",
    "contact_event_register_kindle_batched": "Kindle が登録されました（{count}）。",
    "contact_event_registry_deleted": "レジストリは削除されました（RegistryID # {registry_id}）",
    "contact_event_registry_deleted_batched": "レジストリは削除されました（{count}）",
    "contact_event_release_aiv_license": "DSN {dsn} 上の AIV {asin} のライセンスが解除されました。",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items}個の商品が DSN {dsn} のデバイスから移動されました。",
    "contact_event_remove_content_from_todo_list_batched": "商品がデバイスから削除されました（{count}）。",
    "contact_event_remove_from_yvl": "ASIN {asin} は動画ライブラリから「削除済み商品」に移動されました。",
    "contact_event_remove_kindle_from_blacklist": "DSN 用 Kindle {dsn} は紛失または盗難ステータスから削除されました。理由: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "デバイスが紛失・盗難ステータスから削除されました（{count}）。",
    "contact_event_replacement_created": "注文 {old_order_id} に交換が作成されました。新規注文: {new_order_id}。",
    "contact_event_replacement_created_batched": "交換注文が作成されました（{count}）。",
    "contact_event_request_invoice": "注文 {order_id} の請求書リクエストが出品者に送信されました。",
    "contact_event_request_invoice_batched": "注文の請求書リクエストが送信されました（{count}）。",
    "contact_event_resend_kindle_content": "デジタルコンテンツ {title} をデバイスタイプ{devicetype}で DSN に再送します。",
    "contact_event_resend_kindle_content_batched": "デジタルコンテンツが再送信されました（{count}）。",
    "contact_event_resent_gifted_content": "注文 ID {order_id} の ASIN {asin} の受信者Eメールは変更されました。",
    "contact_event_resent_gifted_content_batched": "ギフト注文の受取人のEメールが更新されました（{count}）。",
    "contact_event_reset_password_email_sent": "パスワードリセットのEメールが送信されました。",
    "contact_event_reset_password_email_sent_batched": "パスワードリセットのEメールが送信されました（{count}）。",
    "contact_event_residence_address_changed": "住居住所が変更されました。",
    "contact_event_residence_address_changed_batched": "自宅の住所が更新されました（{count}）。",
    "contact_event_rma_cancelled": "注文 {order_id} のRMA がキャンセルされました。",
    "contact_event_rma_created": "注文 {order_id} のRMA が作成されました。",
    "contact_event_rma_created_batched": "注文の RMA が作成されました（{count}）。",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_rosetta_transparency": "#RosettaTransparency{treatment}",
    "contact_event_rosetta_transparency_batched": "#RosettaTransparency({count})",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP名: {driverName}。\nステーション名: {stationName}。\nお問い合わせ伝票番号: {trackingId}および \n注文番号: {orderId}\nユースケースセッションID:{usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "お問い合わせ伝票番号{trackingId}のマップウィジェットが開かれました",
    "contact_event_sds_package_repick": "アソシエイトがトラッキングID{trackingId}の再ピックを開始しました",
    "contact_event_send_aiv_asin": "ASIN {asin} はカスタマー {customer_id} の動画ライブラリに送信されました。",
    "contact_event_ship_charge_reduce": "注文の発送料は、{old_amount}から{new_amount}へ変更されました。",
    "contact_event_ship_charge_reduce_batched": "発送料が変更されました（{$hash-get count}）。",
    "contact_event_ship_method_upgraded": "{order_id} の注文について配送オプションが変更されました。",
    "contact_event_ship_method_upgraded_batched": "注文の発送方法が変更されました（{count}）。",
    "contact_event_shipping_address_changed": "注文 {new_order_id} の住所が変更されました。",
    "contact_event_sim_ticket_created": "SIMチケットが作成されました",
    "contact_event_special_offers_enrollment": "DSN {dsn} の Kindle 特別キャンペーン {enrollment_status}。",
    "contact_event_special_offers_enrollment_batched": "特別キャンペーンの登録ステータスが更新されました（{count}）。",
    "contact_event_subscribe_and_save_cancelled": "注文番号 {order_id} の定期おトク便はキャンセルされました。",
    "contact_event_subscribe_and_save_cancelled_batched": "購読の「会員登録＆割引」がキャンセルされました（{count}）。",
    "contact_event_subscribe_and_save_schedule_change": "{order_id} の注文について定期おトク便の配送頻度が変更されました。",
    "contact_event_subscribe_and_save_schedule_change_batched": "購読の「会員登録＆割引」発送スケジュールが変更されました（{count}）。",
    "contact_event_subscription_astro_workflow_launch": "CSは{subscriptionType} サブスクリプション{subscriptionId}のAstroキャンセルワークフローを開始しました。",
    "contact_event_subscription_auto_renew": "{plan_type} の自動更新は {renew_status}{autoRenewChosenOption} に設定されました。",
    "contact_event_subscription_auto_renew_batched": "プライム自動更新の設定が更新されました（{count}）。",
    "contact_event_subscription_auto_renew_off": "CSは{subscriptionType}サブスクリプション{subscriptionId}の自動更新をオフにしました。",
    "contact_event_subscription_cancel_full_refund": "CSは{subscriptionType}サブスクリプション{subscriptionId}を全額返金でキャンセルしました。",
    "contact_event_subscription_cancel_no_refund": "CSは{subscriptionType}サブスクリプション{subscriptionId}を返金なしでキャンセルしました。",
    "contact_event_subscription_cancel_partial_refund": "CSは{subscriptionType} サブスクリプション{subscriptionId} を一部返金でキャンセルしました。",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type}の購読は、現期間の終了時に、{old_plan_name}から{new_plan_name}に更新されるように設定されています。",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "更新されたプライム ロールオーバー プラン設定（{count}）。",
    "contact_event_troubleshooting_wizard": "DSN {dsn} のデバイスのためにトラブルシューティングウィザードが呼び出されました。",
    "contact_event_troubleshooting_wizard_batched": "デバイスのトラブルシューティング ウィザードが呼び出されました（{count}）。",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: DSN {dsn} の端末の{annotation}",
    "contact_event_troubleshooting_wizard_detailed_batched": "デバイスのトラブルシューティング ウィザードが呼び出されました（{count}）。",
    "contact_event_troubleshooting_wizard_digital_order": "注文番号{digitalOrderID}のデジタル注文に対しトラブルシューティングウィザードが呼び出されました。",
    "contact_event_unblock_international_purchase": "国際購入ステータスは、ブロック済みから{new_status}に変更されました",
    "contact_event_unblock_international_purchase_batched": "海外からの購入ステータスが更新されました（{count}）。",
    "contact_event_unlock_dash_order": "端末「 {dsn}  」の以下の ASIN はロック解除されました: {asins}。",
    "contact_event_update_locking_status": "Oft 端末「{dsn}」のロック状態は「{newLockingStatus}」に更新されました。",
    "contact_event_verify_email": "確認メールがカスタマーに送信されました。",
    "contact_event_weekend_delivery_preference_changed": "週末の配送オプションがアップデートされました"
}