import React, {useState} from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import {Contact, ContactMediumType, ContactStatus, CSAgent, FollowUp} from "./Contact.types";
import {Timestamp} from "./Timestamp";
import ContactIcon from "./ContactIcon";
import Translation from "../i18n/Translate";
import moment from "moment";
import IntlManager, {i18n} from "../i18n/IntlManager";
import {Copyable} from "../util/Copyable";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import {SpaceBetween} from "@amzn/awsui-components-react";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import ContactLink from "./ContactLink";

interface ContactStatusDisplayProps {
    creationDate: string;
    contactStatus: ContactStatus;
    owner: CSAgent | undefined;
    type: ContactMediumType;
    followUps: FollowUp[];
    contactId: string;
    dartEscalationContactList: Contact[];
    customerTimezone: string;
    contact: Contact;
    userId: string;
}

const getOwnerIfExists = (owner: CSAgent | undefined): string | null => {
    return owner ? owner.agentLogin : null;
};

const constructStatusMessage = (contactStatus: ContactStatus, owner: CSAgent | undefined): JSX.Element => {
    if (contactStatus === ContactStatus.LOCKED) {
        return (
            <div>
                <Translation stringId="LOCKED_BY"/>&nbsp;{getOwnerIfExists(owner)}
            </div>
        );
    } else if (contactStatus === ContactStatus.RESOLVED) {
        return (
            <div>
                <Translation stringId="RESOLVED_BY"/>&nbsp;{getOwnerIfExists(owner)}
            </div>
        );
    } else {
        return (
            <div>
                <Translation stringId="UNRESOLVED"/>
            </div>
        );
    }
};

const constructAlerts = (followUps: FollowUp[], hasDartEscalations: boolean): JSX.Element => {
    return (
        <div>
            <Box display="inline-block" float="right">
                {hasDartEscalations ?
                    <span>
                <Badge color={"blue"}>
                    <Translation stringId={"CONTACT_STATUS_ESCALATION_BADGE"}/>
                </Badge>&nbsp;
            </span>
                    : null}
                {constructFollowUpAlert(followUps)}
            </Box>
        </div>);
};

const constructFollowUpAlert = (followUps: FollowUp[]): JSX.Element | null => {
    const openFollowUp: FollowUp | undefined = followUps.find(f => !f.resolved);
    if (openFollowUp !== undefined) {
        const dueDateDuration = moment.duration(moment(openFollowUp.dueDate).diff(moment()));
        const daysLeftUntilDueDate: number = dueDateDuration.asDays();
        return <span>
                <Icon variant={daysLeftUntilDueDate < 1 ? "warning" : "success"} name="status-pending"/>&nbsp;
            <Translation stringId="FOLLOW_UP_DUE"/>{" " + dueDateDuration.humanize(true)}
              </span>;
    } else {
        return null;
    }
};

const ContactStatusDisplay: React.FC<ContactStatusDisplayProps> = ({
                                                                       creationDate,
                                                                       contactStatus,
                                                                       owner,
                                                                       type,
                                                                       followUps,
                                                                       contactId,
                                                                       dartEscalationContactList,
                                                                       customerTimezone,
                                                                       contact,
                                                                       userId,
                                                                   }) => {
    const statusMessage = constructStatusMessage(contactStatus, owner);
    const [selected, setChecked] = useState<boolean>(false);

    return (
        <div>
            <Box float="right" display="inline-block">
                <Box
                    variant="span"
                    float="right"
                    aria-label={IntlManager.sharedManager.formatMessage("CONTACT_ID")}>
                    <SpaceBetween size="s" direction={"horizontal"}>
                        {constructAlerts(followUps, dartEscalationContactList?.length > 0)}
                        <Copyable
                            actionTranslationId="COPY_CONTACT_ID"
                            successTranslationId="CONTACT_ID_COPIED"
                            content={contactId}
                            valueToCopy={contactId}/>
                        <div role="checkbox" tabIndex={0}
                             style={{verticalAlign: "middle", display: "inline-block"}}
                             onClick={(e): void => e.stopPropagation()}
                             onKeyDown={(e): void => e.stopPropagation()}
                             aria-checked={selected}>
                            <Checkbox
                                disabled={contact.status !== ContactStatus.LOCKED
                                || contact.medium.type === ContactMediumType.CHAT
                                || contact.medium.type === ContactMediumType.PHONE}
                                ariaLabel={i18n('CONTACT_SELECT_CHECKBOX')}

                                onChange=
                                    {
                                        ({detail}): void => {
                                            contact.isSelected = detail.checked;
                                            setChecked(contact.isSelected);
                                        }
                                    }
                                checked={contactStatus === ContactStatus.RESOLVED ? true : contact.isSelected ? contact.isSelected : false}
                            />
                        </div>
                    </SpaceBetween>
                </Box>
            </Box>
            <SpaceBetween size={"s"}>
                {contact.workRequestParentContact?
                    <Box><Box display={"inline-block"}>{i18n("WORK_REQUEST_FOR_CONTACT")}</Box>
                        <Box display={"inline-block"}><ContactLink contactId={contact.workRequestParentContact.contactId} /></Box></Box> :
                    null}
                <Box padding={{"left": "xxs"}} float="left" display="inline-block">
                    <ContactIcon mediumType={type}/>
                    <Box padding={{"left": "xxs"}} display="inline-block">
                        <Timestamp date={creationDate} customerTimezone={customerTimezone}/>
                    </Box>
                </Box>

                <Box padding={{"horizontal": "xxs"}} display="inline-block">
                    {statusMessage}
                </Box>
            </SpaceBetween>
        </div>
    );
};

export default ContactStatusDisplay;
