import React, {useContext} from 'react';
import {IssueManagerContext} from "../issueManager/IssueManager";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";

export const TokenStatus: React.FC = () => {
    const {clientStateToken, authorizerJwtTokens} = useContext(IssueManagerContext);

    return (
    <div>{JSON.stringify(clientStateToken?.getContent())}
        <ExpandableSection header="Authorizer Token">
            {authorizerJwtTokens && authorizerJwtTokens.map(t => t.getAuth()).map(a => a.map(a => (<div>Type: {a.t} Id: {a.id} Purpose: {a.p}
                {a.e ? ' Escalation: ' + a.e + " ":null}
            TTL {a.ttl}</div>)))}
        </ExpandableSection>
    </div>);
};