import React from "react";
import Translation from "../i18n/Translate";
import {WorkCategory} from "./Contact.types";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";

interface WorkCategoryDisplayProps {
    workCategory: WorkCategory | null;
}

const WorkCategoryDisplay: React.FC<WorkCategoryDisplayProps> = ({workCategory}) => {
    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId="WORK_CATEGORY_TITLE"/></h4>
            {workCategory ?
                <div>{workCategory.workCategoryName}</div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_WORK_CATEGORY")}/>}
    </div>);
};

export default WorkCategoryDisplay;