export const en_AU = {
    "contact_event_GC_added": "GC {gcId} added to account.",
    "contact_event_GC_added_batched": "GC added to account ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} added to purchaser account.",
    "contact_event_GC_added_purchaser_batched": "GC added to purchaser account ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} added to recipient account.",
    "contact_event_GC_added_recipient_batched": "GC added to recipient account ({count}).",
    "contact_event_GC_added_scratchedCode": "GC #{gcId} added to account via scratched claim code tool.",
    "contact_event_GC_added_scratchedCode_batched": "GC added to account via scratched claim code tool ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} cancelled.",
    "contact_event_GC_cancelled_batched": "GC cancelled ({count}).",
    "contact_event_GC_changed_expiry": "Changed expiration date of GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Changed expiration date of GC {gcId}).",
    "contact_event_GC_locked": "GC #{gcId} locked.",
    "contact_event_GC_locked_batched": "GC locked ({count}).",
    "contact_event_GC_removed": "GC #{gcId} removed from account.",
    "contact_event_GC_removed_batched": "GC removed from account ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} re-sent to email ID {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC ${} re-sent to email ID {deliveryEmail}).",
    "contact_event_GC_resent_post": "GC #{gcId} re-sent by post mail.",
    "contact_event_GC_resent_post_batched": "GC re-sent by post mail ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} temporarily locked.",
    "contact_event_GC_temporary_locked_batched": "GC temporarily locked ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transferred from customer {customerID} to {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC transferred ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} unlocked.",
    "contact_event_GC_unlocked_batched": "GC unlocked ({count}).",
    "contact_event_PV_added": "PV #{pvId} added to account.",
    "contact_event_PV_locked": "PV #{pvId} locked.",
    "contact_event_PV_temporary_locked": "PV #{pvId} temporary locked.",
    "contact_event_PV_unlocked": "PV #{pvId} unlocked.",
    "contact_event_a_to_z_guarantee_requested_gwp": "A-to-Z claim submitted for order {order_id}.",
    "contact_event_access_code_added": "Access Code Added",
    "contact_event_access_code_added_batched": "Access Code Added({count})",
    "contact_event_account_reopened": "Account reopened",
    "contact_event_account_reopened_batched": "Accounts reopened ({count})",
    "contact_event_account_temporarily_disabled": "Account temporarily disabled.",
    "contact_event_account_temporarily_disabled_batched": "Accounts temporarily disabled ({count})",
    "contact_event_account_whitelisted": "Account Whitelisted.",
    "contact_event_account_whitelisted_batched": "Account Whitelisted({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video subscription auto-renewed",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video subscription cancelled",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Form submitted: DSL Escalation form\nUse-case session ID: {usecaseSessionID}\nDelivery Station: {deliveryStationField}\nRequest Type: {requestTypeField}\nContact ID: {contactIdField}\nOrder ID: {orderIdField}\nCustomer's email address: {customerEmailField}\nCustomer's phone number: {customerPhoneNumberField}\nTBA(s): {tbasField}\nAccess Code (if applicable): {accessCodeField}\nIssue Summary: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "DSL Escalation forms submitted: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Form submitted: DSP DA Feedback form\nUse-case session ID: {usecaseSessionID}\nDelivery Station Code: {deliveryStationField}\nContact ID: {contactIdField}\nCustomer Account ID: {customerIdField}\nDA Transporter ID: {driverIdField}\nIncident Date (YYYY-MM-DD): {dateOfOccurrenceField}\nIncident Time (HH:MM): {timeofOccurrenceField}\nTBAs: {relatedTrackingIdsField}\nSource of Feedback: {sourceOfFeedbackField}\nFeedback Details: {issueField}\nAdditional Details: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "DSP DA Feedback forms submitted: ({count})",
    "contact_event_apply_adrive_music_trial": "applied Cloud Player Premium for customer {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Account migrated to {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Audible accounts migrated ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} was refunded for the amount of {cashAmount}. Additionally, this transaction restored a {couponAmount} coupon back to the account.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} was refunded for the amount of {cashAmount}. Additionally, this transaction restored a {couponAmount} coupon back to the account.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} was refunded for the amount of {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} was refunded for the amount of {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} was refunded and restored a {couponAmount} coupon back to the account.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} was refunded and restored a {couponAmount} coupon back to the account.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} credit(s) were removed from the account.",
    "contact_event_audible_credit_issued": "{numCredit} credit(s) were added to account.",
    "contact_event_audible_credit_issued_batched": "Credit(s) were added to account ({count}).",
    "contact_event_audible_credit_refund": "{title} was refunded and restored {creditAmount} credit(s) back to the account.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} was refunded and restored {creditAmount} credit(s) back to the account.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} credit(s) were restored to the account.",
    "contact_event_audible_credit_restored_batched": "{amount} credit(s) were restored to the account.({count})",
    "contact_event_audible_extra_credit_purchased": "Three extra credits were purchased.",
    "contact_event_audible_extra_credit_purchased_batched": "Three extra credits were purchased.({count})",
    "contact_event_audible_hiatus_cancelled": "Hiatus period was terminated early and the membership has resumed.",
    "contact_event_audible_hiatus_cancelled_batched": "Hiatus period was terminated early and the membership has resumed.({count})",
    "contact_event_audible_hiatus_started": "The membership was placed on a {duration}-month Hiatus.",
    "contact_event_audible_hiatus_started_batched": "The membership was placed on a {duration}-month Hiatus.({count})",
    "contact_event_audible_membership_enrollment": "Enrolled on to the \n{membershipPlan} membership plan.",
    "contact_event_audible_membership_enrollment_batched": "Enrolled on to the Audible membership plans ({count}).",
    "contact_event_audible_membership_plan_refund": "Membership fee was refunded in the amount of {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Membership fee was refunded in the amount of {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Changed from the {oldMembershipPlan} to the {newMembershipPlan} membership plan.",
    "contact_event_audible_membership_switched_batched": "Changed Audible membership plans ({count}).",
    "contact_event_audible_romance_package_enrollment": "Enrolled on to the {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Enrolled on to the {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "{wizardName} was accessed.",
    "contact_event_audible_wizard_invoked_batched": "{wizardName} was accessed.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "The {membership} plan was successfully cancelled.",
    "contact_event_blacklist_kindle": "Black-listed device {serial_number}.",
    "contact_event_cancel_audible_membership": "The {membership} plan was successfully cancelled.",
    "contact_event_cancel_audible_membership_batched": "The {membership} plan was successfully cancelled.({count})",
    "contact_event_cancel_audible_romance_package_batched": "The {membership} plan was successfully cancelled.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "The previously set Future Cancellation Date was cancelled.",
    "contact_event_channels_auto_renew_off": "CS turned OFF auto-renewal for PV Channels subscription {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS turned OFF auto-renewal via Astro.",
    "contact_event_channels_full_refund": "CS cancelled PV Channels subscription {subscriptionid} with full refund.",
    "contact_event_channels_full_refund_static": "CS cancelled subscription with full refund via Astro.",
    "contact_event_channels_no_refund": "CS cancelled PV Channels subscription {subscriptionid} with no refund.",
    "contact_event_channels_no_refund_static": "CS cancelled subscription with no refund via Astro.",
    "contact_event_channels_workflow_launch": "CS launched Astro cancellation workflow for PV Channels subscription {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS launched Astro cancellation workflow.",
    "contact_event_coins_adjust": "Adjustment Type: {type} <br />\nReason: {reason} <br />\nAmount: {amount} <br />\nComments: {comment} <br />",
    "contact_event_coins_adjust_batched": "Adjusted Coins Balance ({count}).",
    "contact_event_concessions_abuse": "HMD was not sent because customer is {risk_status} user.",
    "contact_event_concessions_abuse_batched": "HMD was not sent for customer ({count}).",
    "contact_event_contact_was_initiated_from": "Contact was initiated from ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_copilot_started": "Co-Pilot Started",
    "contact_event_csc_sds_override_geo_fence_submit": "Override geofence request submitted for Tracking ID {trackingId} Reason: {overrideReason} Page:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Override geofence request submitted for Tracking ID {trackingId} Reason: {overrideReason} Page:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocode updated. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocode updated. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "customer authentication passed",
    "contact_event_customer_authentication_passed_batched": "customer authentication passed({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_delete_alexa_voice_profile": "Deleted Alexa voice profile for speakerId: {speakerId},  customerID: {customerID} and status: {status}",
    "contact_event_delete_data_alexa_comms": "Deleted Alexa comms profile for commsID: {commsId},  customerID: {customerID} and status: {status}",
    "contact_event_deprovision_alexa_comms": "Deprovisioned Alexa Comms Profile for commsID : {commsId} ,  customerID : {customerID}  and status : {status}",
    "contact_event_disable_mayday_failed": "The Mayday function for device DSN {dsn} has failed to be deactivated.",
    "contact_event_disable_mayday_success": "The Mayday function for device DSN {dsn} has been deactivated successfully.",
    "contact_event_dunning_pause": "Dunning Process Paused - See Payments & Transactions History for more details",
    "contact_event_dvbypost_extra_dispatch": "{amount} extra dispatch(es) requested for this customer.",
    "contact_event_dvbypost_extra_envelope": "An extra envelope was triggered for this customer.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 form angefordert",
    "contact_event_dvbypost_problem_code_change": "Setting disc {disc_id} to {problem_code}",
    "contact_event_enable_mayday_failed": "The Mayday function for device DSN {dsn} has failed to be reactivated.",
    "contact_event_enable_mayday_success": "The Mayday function for device DSN {dsn} has been reactivated successfully.",
    "contact_event_first_time_user_experience_set": "Set First Time User Experience",
    "contact_event_first_time_user_experience_set_batched": "Set First Time User Experience({count}).",
    "contact_event_free_time_unlimited_cancelled": "Free Time Unlimited subscription cancelled for  customer: {customerId}, DSN: {deviceSerialNumber} by {agentId} with reason: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Free Time Unlimited subscription cancelled ({count}).",
    "contact_event_future_cancel_audible_membership": "The {membership} plan has been set to Future Cancel on {date}.",
    "contact_event_future_cancel_audible_membership_batched": "The {membership} plan has been set to Future Cancel on {date}.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} has been permanently removed from Your Video Library.",
    "contact_event_hmd_suppression": "HMD was not sent because: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Idle chat disconnected",
    "contact_event_idle_chat_disconnect_batched": "Idle chat disconnected ({count})",
    "contact_event_live_assist_launched": "Mayday Screen Sharing launched for DSN: {dsn}, kcsID: {kcsId}, customer response: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing launched for devices ({count}).",
    "contact_event_lock_dash_order": "The following ASINs for device {dsn}  has been locked: {asins}.",
    "contact_event_music_auto_renew_off": "CS turned OFF auto-renewal for the music subscription {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS turned OFF auto-renewal for the music subscription via Astro.",
    "contact_event_music_full_refund": "CS cancelled the music subscription {subscriptionid} with a full refund",
    "contact_event_music_full_refund_static": "CS cancelled the music subscription with a full refund via Astro.",
    "contact_event_music_no_refund": "CS cancelled the music subscription {subscriptionid} with no refund.",
    "contact_event_music_no_refund_static": "CS cancelled the music subscription with no refund via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS turned OFF auto-renewal for Amazon Music Unlimited subscription {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with full refund.",
    "contact_event_music_unlimited_no_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with no refund.",
    "contact_event_music_unlimited_partial_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with partial refund.",
    "contact_event_music_unlimited_workflow_launch": "CS launched Astro cancellation workflow for Amazon Music Unlimited subscription {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS launched Astro cancellation workflow for the music subscription {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS launched an Astro cancellation workflow for unlimited music.",
    "contact_event_pennywise_promo_applied": "Pennywise promotion applied for DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Pennywise promotion applied for DSNs: ({count})",
    "contact_event_refund_cancelled": "Refund cancelled for order {order_id}.",
    "contact_event_release_aiv_license": "Licence of AIV {asin} has been released on device DSN {dsn}.",
    "contact_event_remove_from_yvl": "ASIN {asin} has been removed from Your Video Library into Deleted Items.",
    "contact_event_request_invoice": "Invoice Request Sent to Seller for Order {order_id}.",
    "contact_event_request_invoice_batched": "Invoice request sent for orders ({count}).",
    "contact_event_retrocharge_disarmed": "RMA Disarmed for OrderID {orderId} - ASIN {asin}. Reason for Disarm: {disarm_retrocharge_reason}",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_assigned_package_to_driver": "Tracking ID {trackingId} added to itinerary",
    "contact_event_sds_assigned_package_to_driver_batched": "Tracking ID {trackingId} added to itinerary.",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP name: {driverName}, \nStation name: {stationName}, \nTracking ID: {trackingId} and \nOrder ID: {orderId}\nUsecaseSession ID: {usecaseSessionID}",
    "contact_event_sds_dlp_loaded_for_tracking_order_id": "Tracking ID {trackingId} and Order ID {orderId} loaded.",
    "contact_event_sds_dlp_loaded_for_tracking_order_id_batched": "Tracking ID {trackingId} and Order ID {orderId} loaded.",
    "contact_event_sds_map-widget-opened": "Map widget opened for Tracking ID {trackingId}",
    "contact_event_sds_package_action_status_update": "Tracking ID {trackingId} status changed to {state}",
    "contact_event_sds_package_repick": "Associate initiated Re-Pick for tracking ID {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} has been sent to Your Video Library of customer {customer_id} .",
    "contact_event_sim_ticket_created": "SIM-ticket created",
    "contact_event_subscription_astro_workflow_launch": "CS launched Astro cancellation workflow for {subscriptionType} subscription {subscriptionId}.",
    "contact_event_subscription_auto_renew_off": "CS turned OFF auto-renewal for {subscriptionType} subscription {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS cancelled {subscriptionType} subscription {subscriptionId} with full refund.",
    "contact_event_subscription_cancel_no_refund": "CS cancelled {subscriptionType} subscription {subscriptionId} with no refund.",
    "contact_event_subscription_cancel_partial_refund": "CS cancelled {subscriptionType} subscription {subscriptionId} with partial refund.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} subscription set to renew to {new_plan_name} from {old_plan_name} at the end of current period.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Updated Prime rollover plan settings ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Troubleshooting wizard invoked for digital order with ORDERID {digitalOrderID}.",
    "contact_event_unlock_dash_order": "The following ASINs for device {dsn}  has been unlocked: {asins}.",
    "contact_event_update_locking_status": "Locking status of Oft device  {dsn} has been updated to: {newLockingStatus}",
    "contact_event_verify_email": "Verification email sent to the customer.",
    "contact_event_verify_email_email_sent": "Email verification sent.",
    "contact_event_verify_email_email_sent_batched": "Email verification sent({count}).",
    "contact_event_weekend_delivery_preference_changed": "Weekend Delivery Preference Updated",
    "contact_event_weekend_delivery_preference_changed_batched": "Weekend Delivery Preference Updated{order_id}"
}