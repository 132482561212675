import React, {useContext, useState} from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {i18n} from "../../i18n/IntlManager";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Autosuggest, {AutosuggestProps} from "@amzn/awsui-components-react/polaris/autosuggest";
import {Contact, ContactMedium, ContactMediumType, PurgeReasons} from "../Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Input from "@amzn/awsui-components-react/polaris/input";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import TokenGroup, {TokenGroupProps} from "@amzn/awsui-components-react/polaris/token-group";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import moment from "moment-timezone";
import ContactEmailMedium from "../ContactEmailMedium";
import ContactChatMedium from "../ContactChatMedium";
import {CallResponse, LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import { getUserLogin, getUserOwner} from "../UserDetailsUtil";

interface PurgeSensitiveInfoFormProps {
    originalContact: Contact;
    pushNotification: (msg: JSX.Element, type: FlashbarProps.Type) => void;
}

const PurgeSensitiveInfoForm: React.FC<PurgeSensitiveInfoFormProps> = ({originalContact, pushNotification}) => {
    const [wordToPurge, setWordToPurge] = useState<string>("");
    const [wordListToPurge, setWordListToPurge] = useState<TokenGroupProps.Item[]>([]);

    const [reasonForPurgeSelection, setReasonForPurgeSelection] = useState<string>("");
    const [reasonForPurgeSelectionTranslated, setReasonForPurgeSelectionTranslated] = useState<string>("");
    const [reasonForPurgeOtherTextValue, setReasonForPurgeOtherTextValue] = useState<string>("");
    const purgeReasons = Object.values(PurgeReasons).map(reason => {return reason.valueOf();});

    const [confirmCheck, setConfirmCheck] = useState(false);
    const [waitingOnPurgeResult, setWaitingOnPurgeResult] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);

    const originalMediaList: ContactMedium[] = [];
    originalMediaList.push(originalContact.medium);
    originalContact.replies.map((reply) => (originalMediaList.push(reply.medium)));

    const [contactMedia, setContactMedia] = useState<ContactMedium[]>(originalMediaList);
    const { reloadSingleContact, getSubject, callPhoenix } = useContext(IssueManagerContext);
    const subject = getSubject();
    const agentLogin =  getUserLogin(subject);
    const agentOwner =  getUserOwner(subject);

    function getPreviewQuery(contactId: string, updatedItems: TokenGroupProps.Item[]): string {
        const textToPurgeList = JSON.stringify(updatedItems.map(item => {return item.label;}));
        return `query{` +
                `previewPurgeTextFromContactMediumList(` +
                `   input: {` +
                `       obfuscatedContactId:"${contactId}",` +
                `       encryptedTextToPurgeList:${textToPurgeList},` +
                `   }` +
                `){` +
                `   type` +
                `   emailBodyHTMLSafe` +
                `           chat {` +
                `             messages {` +
                `               type ` +
                `               timestamp ` +
                `               participant { ` +
                `                 __typename ` +
                `                 preferredLanguage ` +
                `                 ... on CustomerChatParticipant { ` +
                `                     customerName ` +
                `                 } ` +
                `                 ... on CSAChatParticipant { ` +
                `                     name ` +
                `                 } ` +
                `                 ... on BotChatParticipant { ` +
                `                     name ` +
                `                 } ` +
                `               } ` +
                `               chatText { ` +
                `                 text ` +
                `                 language ` +
                `               } ` +
                `               translationList { ` +
                `                 text ` +
                `                 language ` +
                `               } ` +
                `               stateChangeType ` +
                `               disconnectReason ` +
                `             } ` +
                `           }` +
                `}` +
            `}`;
    }

    function getPurgeMutation(contactId: string, reasonForPurge: string, agentLogin: string, agentOwner: string, updatedItems: TokenGroupProps.Item[]): string {
        const textToPurgeList = JSON.stringify(updatedItems.map(item => {return item.label;}));
        return `mutation{` +
                `purgeFromContactText(` +
                `   input: {` +
                `       obfuscatedContactId:"${contactId}",` +
                `       encryptedTextToPurgeList:${textToPurgeList},` +
                `       requestingCSAgent: {` +
                `           agentLogin: "${agentLogin}",` +
                `           agentOwner: "${agentOwner}",` +
                `       }` +
                `       reasonForPurge: ${JSON.stringify(reasonForPurge)}` +
                `   }` +
                `){` +
                `   failure {` +
                `       errorType` +
                `       errorMessageDescriptor {` +
                `           stringId` +
                `       }` +
                `   }` +
                `}` +
            `}`;
    }

    function generatePurgedMediaPreview(contactId: string, updatedItems: TokenGroupProps.Item[]): void {
        setLoadingPreview(true);
        const query = getPreviewQuery(contactId, updatedItems);
        callPhoenix(query)
            .then((response: CallResponse) => processQueryResponse(response))
            .catch((error) => {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate purge preview: " + error);
                pushNotification((<div>{i18n('PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE') + ": " + error}</div>), 'error');
            });
    }

    async function processQueryResponse(response: CallResponse): Promise<void> {
        let success = false;
        try {
            if (response.httpStatus === 200) {
                let purgedMedia;
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data && parsed.data.previewPurgeTextFromContactMediumList) {
                        purgedMedia = parsed.data.previewPurgeTextFromContactMediumList as ContactMedium[];
                        for (let i = 0; i < contactMedia.length; i++) {
                            if (contactMedia[i].type === ContactMediumType.EMAIL) {
                                purgedMedia[i].emailSubject = contactMedia[i].emailSubject;
                                purgedMedia[i].sender = contactMedia[i].sender;
                                purgedMedia[i].emailHeader = contactMedia[i].emailHeader;
                            }
                        }
                        setContactMedia(purgedMedia);
                    } else {
                        Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate purge preview: " + parsed);
                        pushNotification((<div>{i18n('PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE')}</div>), 'error');
                    }
                }
                success = true;
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate purge preview: " + response);
                pushNotification((<div>{i18n('PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE')}</div>), 'error');
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to generate purge preview: " + error);
            pushNotification((<div>{i18n('PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE') + ": " + error}</div>), 'error');
        } finally {
            setLoadingPreview(false);
            Phoenix.getInstance().addMetric("previewPurgeFromContactText.SUCCESS", success ? 1 : 0, MetricType.COUNT);
        }
    }

    async function processMutationResponse(response: CallResponse): Promise<void> {
        let success = false;
        try {
            if (response.httpStatus === 200) {
                if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed.data.failure) {
                        Phoenix.getInstance().log(LogLevel.ERROR, "Failed to purge from contact text: " + JSON.stringify(response));
                        pushNotification((<div>{i18n('PURGE_FROM_CONTACT_TEXT_FAILURE')}</div>), 'error');
                    } else {
                        await reloadSingleContact(originalContact.contactId);
                        success = true;
                        pushNotification((<div>{i18n('PURGE_FROM_CONTACT_TEXT_SUCCESS')}</div>), 'success');
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to purge from contact text: " + response);
                pushNotification((<div>{i18n('PURGE_FROM_CONTACT_TEXT_FAILURE')}</div>), 'error');
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to purge from contact text: " + error);
            pushNotification((<div>{i18n('PURGE_FROM_CONTACT_TEXT_FAILURE') + ": " + error}</div>), 'error');
        } finally {
            Phoenix.getInstance().addMetric("purgeFromContactText.SUCCESS", success ? 1 : 0, MetricType.COUNT);
            setWaitingOnPurgeResult(false);
            setConfirmCheck(false);
        }
    }

    function purgeMedia(contactId: string, reasonForPurgeSelection: string, agentLogin: string, agentOwner: string): void {
        setWaitingOnPurgeResult(true);
        const purgeReason = reasonForPurgeSelection === PurgeReasons.OTHER ?
            reasonForPurgeSelection + ": " + reasonForPurgeOtherTextValue : reasonForPurgeSelection;

        const query = getPurgeMutation(contactId, purgeReason, agentLogin, agentOwner, wordListToPurge);
        callPhoenix(query)
            .then((response: CallResponse) => processMutationResponse(response))
            .catch((error) => {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to purge from contact text: " + error);
                pushNotification((<div>{i18n('PURGE_FROM_CONTACT_TEXT_FAILURE') + ": " + error}</div>), 'error');
            });
    }

    function getEnteredTextLabel(): string {
        if (reasonForPurgeSelection === reasonForPurgeSelectionTranslated) {
            return i18n('USE') + ": " + reasonForPurgeSelection;
        }

        if (!purgeReasons.includes(reasonForPurgeSelection)) {
            return i18n('PURGE_INVALID_REASON', {values: {"invalid_purge_selection": reasonForPurgeSelection}});
        }

        return i18n('USE') + ": " + i18n(reasonForPurgeSelection);
    }

    function getOptions(): AutosuggestProps.Option[] {
        return Object.values(PurgeReasons).map(toOption);
    }

    function toOption(reasonForPurge: PurgeReasons): AutosuggestProps.Option {
        return {value: reasonForPurge, label: i18n(reasonForPurge)};
    }

    function setWordListToPurgeAndUpdatePreview(updatedWordList: TokenGroupProps.Item[]): void {
        setWordListToPurge(updatedWordList);
        if (updatedWordList.length === 0) {
            setContactMedia(originalMediaList);
        } else {
            generatePurgedMediaPreview(originalContact.contactId, updatedWordList);
        }
    }

    function isWordToPurgeInMedia(wordToPurge: string, contactMedia: ContactMedium[]): boolean {
        for (const medium of contactMedia) {
            if (medium.type === ContactMediumType.EMAIL) {

                //If an email is within the contact text it will have the @ symbol replaced with &#64;
                //due to HTML sanitization. This causes the check to fail since the email the user enters
                //will be test@amazon.com but whats present in the actual text is test&#64;amazon.com
                //since we conduct HTML Sanitization on backend
                if (medium.emailBodyHTMLSafe?.includes(wordToPurge.replace(/@/g, "&#64;"))) {
                    return true;
                }
            }

            if (medium.type === ContactMediumType.CHAT) {
                if (medium.chat && medium.chat.messages) {
                    for (const message of medium.chat.messages) {
                        if (message.chatText && message.chatText.text) {
                            if (message.chatText.text.includes(wordToPurge)) {
                                return true;
                            }
                        }
                    }
                }
            }
        }


        return false;
    }

    function validateAndAddWordToPurgeList(wordToPurge: string): void {
        const wordToPurgeInMedia = isWordToPurgeInMedia(wordToPurge, contactMedia);

        if (!wordToPurgeInMedia) {
            pushNotification((<div>{i18n('PURGE_WORD_NOT_FOUND')}</div>), 'error');
        }

        if (!wordListToPurge.map(item => {return item.label;}).includes(wordToPurge) && wordToPurgeInMedia) {
            setWordListToPurgeAndUpdatePreview([...wordListToPurge, {label: wordToPurge, dismissLabel: i18n('PURGE_REMOVE_LABEL')}]);
            setWordToPurge("");
        }
    }

    return (<Container header={<h4>{i18n("PURGE_SENSITIVE_INFO")}</h4>}>
        <FormField label={i18n('TEXT_TO_PURGE')}>
            <Input
                value={wordToPurge}
                onChange={ (event): void => setWordToPurge(event.detail.value) }
            />
            <SpaceBetween size={"xs"}>
                <Button
                    disabled={
                        wordToPurge === "" ||
                        wordToPurge === undefined ||
                        wordToPurge.length < 3 ||
                        wordListToPurge.map(item => {return item.label;}).includes(wordToPurge)}
                    onClick={(): void => {validateAndAddWordToPurgeList(wordToPurge);}}
                >
                    {i18n('PURGE_ADD_WORD_BUTTON_TEXT')}
                </Button>
                <TokenGroup
                    onDismiss={({ detail: { itemIndex } }): void => {
                        setWordListToPurgeAndUpdatePreview([
                            ...wordListToPurge.slice(0, itemIndex),
                            ...wordListToPurge.slice(itemIndex + 1)
                        ]);
                    }}
                    items={wordListToPurge}
                />
                {
                    loadingPreview ?
                        <StatusIndicator type="loading">
                            <h3>{i18n('LOADING_PREVIEW_TEXT')}</h3>
                        </StatusIndicator>
                        : null
                }

            </SpaceBetween>
        </FormField>
        {contactMedia.length > 0 ?
            <div>
                <SpaceBetween direction={"vertical"} size={"xs"}>

                    {contactMedia.map((medium: ContactMedium) => (
                        medium.type === ContactMediumType.EMAIL ?
                        <ContactEmailMedium
                            emailSubject={medium.emailSubject}
                            emailHeader={medium.emailHeader}
                            emailBodyHTMLSafe={medium.emailBodyHTMLSafe}
                            sender={medium.sender}
                            customerContentIsRestricted={false}
                            displayOnlyEmailText={true}
                            key = {contactMedia.indexOf(medium)}
                        /> : medium.type === ContactMediumType.CHAT ? <ContactChatMedium chat={medium.chat} customerContentIsRestricted={false} customerTimezone={moment.tz.guess()} key={contactMedia.indexOf(medium)}/>
                        : null
                    ))}

                </SpaceBetween>
            </div>
            : null
        }
        <FormField label={i18n("PURGE_REASON_TEXT")}>
            <SpaceBetween direction={"vertical"} size={"xs"}>
                <Autosuggest
                    onChange={({ detail }): void => {
                        setReasonForPurgeSelection(detail.value);
                        if (purgeReasons.includes(detail.value)) {
                            setReasonForPurgeSelectionTranslated(i18n(detail.value));
                        }
                    }}
                    value={purgeReasons.includes(reasonForPurgeSelection) ? i18n(reasonForPurgeSelection) : reasonForPurgeSelection}
                    options={getOptions()}
                    enteredTextLabel={(): string => getEnteredTextLabel()}
                    placeholder={i18n('PURGE_SELECTOR_PLACEHOLDER')}

                />
                {
                    reasonForPurgeSelection === PurgeReasons.OTHER || reasonForPurgeSelection === i18n(PurgeReasons.OTHER) ?
                        <Input
                            value={reasonForPurgeOtherTextValue}
                            onChange={ (event): void => setReasonForPurgeOtherTextValue(event.detail.value) }
                        />
                        : null
                }
            </SpaceBetween>
        </FormField>
        <FormField>
            <Checkbox
                onChange={({ detail }): void =>
                    setConfirmCheck(detail.checked)
                }
                checked={confirmCheck}>
                {i18n('PURGE_CONFIRMATION_CHECK')}
            </Checkbox>
        </FormField>
        <FormField>
            <SpaceBetween direction={"horizontal"} size={"xs"}>
                <Button variant="normal" onClick={(): void => {
                    setWordListToPurgeAndUpdatePreview([]);
                    setWordToPurge("");
                    setConfirmCheck(false);
                }}>{i18n('RESET')}</Button>
                <Button variant="primary" loading={waitingOnPurgeResult} disabled={
                    !(wordListToPurge.length > 0 &&
                        confirmCheck &&
                        purgeReasons.includes(reasonForPurgeSelection) &&
                        !(reasonForPurgeSelection === PurgeReasons.OTHER && reasonForPurgeOtherTextValue === "")
                    )}
                        onClick={(): void => {
                            purgeMedia(originalContact.contactId, reasonForPurgeSelection, agentLogin, agentOwner);}}>
                    {i18n('PURGE_BUTTON_TEXT')}</Button>
            </SpaceBetween>
        </FormField>
    </Container>);
};

export default PurgeSensitiveInfoForm;