const SUBJECT_USER_LOGIN_INDEX = 0;
const SUBJECT_OWNER_NAME_INDEX = 1;

export function getUserId(clientStateToken): string {
    return clientStateToken?.content.id;
}

function returnIndexValue(subject, index): string {
    if (subject) {
        return subject.split("@")[index];
    } else {
        return "";
    }
}

export function getUserLogin(subject): string {
    return returnIndexValue(subject, SUBJECT_USER_LOGIN_INDEX);
}

export function getUserOwner(subject): string {
    return returnIndexValue(subject, SUBJECT_OWNER_NAME_INDEX);

}

export default {getUserId, getUserLogin};
