export const fr_FR = {
    "contact_event_GC_added": "GC #{gcId} ajouté au compte.",
    "contact_event_GC_added_batched": "GC ajouté au compte ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} ajouté au compte de l'acheteur.",
    "contact_event_GC_added_purchaser_batched": "GC ajouté au compte  de l'acheteur ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} jouté au compte du destinataire.",
    "contact_event_GC_added_recipient_batched": "GC ajouté au compte du destinataire  ({count}).",
    "contact_event_GC_added_scratchedCode": "Chèque-cadeau #{gcId} ajouté au compte via l’outil de code de réclamation rayé.",
    "contact_event_GC_added_scratchedCode_batched": "Chèque-cadeau ajouté au compte via l’outil de code de réclamation rayé ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} annulé.",
    "contact_event_GC_cancelled_batched": "GC annulé ({count}).",
    "contact_event_GC_changed_expiry": "Date d'expiration modifiée du GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Date d'expiration modifiée du GC ({count}).",
    "contact_event_GC_locked": "GC #{gcId} bloqué.",
    "contact_event_GC_locked_batched": "GC verrouillé ({count}).",
    "contact_event_GC_removed": "GC #{gcId} supprimé du compte.",
    "contact_event_GC_removed_batched": "GC supprimé du compte ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} renvoyé à l'identifiant d'e-mail {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC renvoyé à l'identifiant d'e-mail ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} renvoyé par courrier postal.",
    "contact_event_GC_resent_post_batched": "GC renvoyé par courrier postal ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} temporairement bloqué.",
    "contact_event_GC_temporary_locked_batched": "GC temporairement verrouillé ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transféré du client {customerID} à {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC verrouillé ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} débloqué.",
    "contact_event_GC_unlocked_batched": "GC déverrouillé ({count}).",
    "contact_event_PV_added": "PV nº {pvId} ajouté au compte.",
    "contact_event_PV_locked": "PV # {pvId} verrouillé.",
    "contact_event_PV_temporary_locked": "PV # {pvId} temporairement bloqué.",
    "contact_event_PV_unlocked": "PV # {pvId} déverrouillé.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Plaintes de A à Z annulées pour les commandes ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Garantie A à Z soumise pour la commande {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Plaintes A à Z soumises pour les commandes ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Réclamation au titre de la garantie A à Z soumise pour la commande {order_id}.",
    "contact_event_access_code_added": "Code d'accès ajouté",
    "contact_event_account_closed": "Compte fermé.",
    "contact_event_account_closed_batched": "Comptes fermés ({count}).",
    "contact_event_account_reopened": "Compte rouvert",
    "contact_event_account_reopened_batched": "Comptes rouverts ({count})",
    "contact_event_account_temporarily_disabled": "Compte temporairement désactivé.",
    "contact_event_account_temporarily_disabled_batched": "Comptes temporairement désactivés ({count})",
    "contact_event_account_whitelisted": "Compte sur liste blanche.",
    "contact_event_address_changed": "Adresse {address_type} mise à jour pour {order_id}.",
    "contact_event_address_changed_batched": "Adresses mises à jour pour les commandes ({count}).",
    "contact_event_address_deactivated": "Adresse désactivée.",
    "contact_event_address_deactivated_batched": "Adresse désactivée ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Abonnement Amazon Video renouvelé automatiquement",
    "contact_event_amazon_video_subscription_cancelled": "Abonnement Amazon Video annulé",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulaire envoyé : Formulaire de remontée DSL\nID de session de cas d'utilisation :{usecaseSessionID}\nStation de livraison : {deliveryStationField}\nType de requête : {requestTypeField}\nID de contact : {contactIdField}\nNuméro de la commande : {orderIdField}\nAdresse e-mail du client : {customerEmailField}\nNuméro de téléphone du client : {customerPhoneNumberField}\nTBA : {tbasField}\nCode d'accès (le cas échéant) : {accessCodeField}\nRésumé du problème : {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formulaires d'escalade DSL envoyés : ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulaire envoyé : Formulaire de commentaires DSP DA\nID de session de cas d'utilisation :{usecaseSessionID}\nCode de la station de livraison :{deliveryStationField}\nID de contact : {contactIdField}\nID de compte du client : {customerIdField}\nID du transporteur DA : {driverIdField}\nDate de l'incident (AAAA-MM-JJ) :{dateOfOccurrenceField}\nHeure de l’incident (HH : MM) : {timeofOccurrenceField}\nTBA : {relatedTrackingIdsField}\nSource des commentaires : {sourceOfFeedbackField}\nDétails des commentaires : {issueField}\nDétails supplémentaires : {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formulaires de commentaires DSP DA envoyés : ({count})",
    "contact_event_andon_cord_pulled": "Andon cord tirée pour {asin}.",
    "contact_event_andon_cord_pulled_batched": "Andon Cord tiré pour les articles ({count}).",
    "contact_event_apply_adrive_music_trial": "Cloud Player Prime appliqué pour le client {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Compte migré vers {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Comptes Audible migrés ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} a été remboursé d’un montant de {cashAmount}. De plus, cette transaction a redéposé un bon de {couponAmount} sur le compte.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} a été remboursé d’un montant de {cashAmount}. De plus, cette transaction a redéposé un bon de {couponAmount} sur le compte{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} a été remboursé d’un montant de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} a été remboursé d’un montant de {cashAmount}{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} a été remboursé et un bon de {couponAmount} a été redéposé sur le compte.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} a été remboursé et un bon de {couponAmount} a été redéposé sur le compte{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} crédit(s) ont été supprimé(s) du compte.",
    "contact_event_audible_credit_issued": "{numCredit} crédit(s) ont été ajoutés au compte.",
    "contact_event_audible_credit_issued_batched": "Des crédits ont été ajoutés au compte ({count}).",
    "contact_event_audible_credit_refund": "{title} a été remboursé et un crédit de {creditAmount} a été redéposé sur le compte.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} a été remboursé et un crédit de {creditAmount} a été redéposé sur le compte.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} crédits ont été restaurés sur le compte.",
    "contact_event_audible_credit_restored_batched": "{amount} crédits ont été restaurés sur le compte.({count})",
    "contact_event_audible_extra_credit_purchased": "3 crédits supplémentaires ont été achetés.",
    "contact_event_audible_extra_credit_purchased_batched": "3 crédits supplémentaires ont été achetés.({count})",
    "contact_event_audible_hiatus_cancelled": "La période de pause a pris fin plus tôt et l’adhésion a repris.",
    "contact_event_audible_hiatus_cancelled_batched": "La période de pause a pris fin plus tôt et l’adhésion a repris. ({count})",
    "contact_event_audible_hiatus_started": "L’adhésion est mise en pause pendant {duration} mois.",
    "contact_event_audible_hiatus_started_batched": "L’adhésion est mise en pause pendant {duration} mois.({count})",
    "contact_event_audible_membership_enrollment": "Inscrit au \nplan d'abonnement {membershipPlan}.",
    "contact_event_audible_membership_enrollment_batched": "Inscrit aux plans d’abonnement Audible ({count}).",
    "contact_event_audible_membership_plan_refund": "Les frais d’abonnement ont été remboursés à hauteur de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Les frais d’abonnement ont été remboursés à hauteur de {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Est passé du plan d’abonnement {oldMembershipPlan} au plan {newMembershipPlan}.",
    "contact_event_audible_membership_switched_batched": "A modifié les plans d’abonnement Audible ({count}).",
    "contact_event_audible_romance_package_enrollment": "Inscrit sur le {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Inscrit sur le {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "Quelqu’un a accédé à {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Quelqu’un a accédé à {wizardName}. ({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "L'abonnement {membership} a bien été résilié",
    "contact_event_blacklist_kindle": "Appareil black-listé {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Appareils Kindle mis sur la liste noire ({count}).",
    "contact_event_bound_warranty": "ID de garantie {warranty_id} lié à l'appareil {dsn}.",
    "contact_event_bound_warranty_batched": "Garanties liées aux appareils ({count}).",
    "contact_event_call_was_flagged": "Ce contact a été marqué comme {callFlagSubject} : {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Ce contact a été marqué comme {callFlagSubject} : {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "L'abonnement {membership} a bien été résilié",
    "contact_event_cancel_audible_membership_batched": "L'abonnement {membership} a bien été résilié({count})",
    "contact_event_cancel_audible_romance_package_batched": "L'abonnement {membership} a bien été résilié({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Prime bien annulé de PrimeCancelException. Motif d'annulation : {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Annuler l'exception pour Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "La date de résiliation future précédemment sélectionnée a été annulée",
    "contact_event_cancelled_sns_reactivated": "Livraison programmée {subscription_id} réactivé le {reactivation_date } par {login}",
    "contact_event_cancelled_sns_reactivated_batched": "livraisons programmées Prévoyez et Économisez annulés réactivés ({count}).",
    "contact_event_captcha_bypass_key_generated": "Clef de contournement Captcha générée.",
    "contact_event_captcha_bypass_key_generated_batched": "Clef de contournement Captcha générée ({count}).",
    "contact_event_change_sns_quantity": "ASIN {asin} pour S'abonner et économiser définit l'ancienne quantité {current_quantity}  à la nouvele quantité {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Quantités S'abonner et économiser mises à jour  ({count}).",
    "contact_event_channels_auto_renew_off": "CS a désactivé le renouvellement automatique pour l'abonnement PV Channels {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS a désactivé le renouvellement automatique via Astro.",
    "contact_event_channels_full_refund": "CS a annulé l'abonnement PV Channels {subscriptionid} avec remboursement intégral.",
    "contact_event_channels_full_refund_static": "CS a annulé l'abonnement avec remboursement intégral via Astro.",
    "contact_event_channels_no_refund": "CS a annulé l'abonnement PV Channels {subscriptionid} sans remboursement.",
    "contact_event_channels_no_refund_static": "CS a annulé l'abonnement sans remboursement via Astro.",
    "contact_event_channels_workflow_launch": "CS a lancé le processus d'annulation Astro pour l'abonnement PV Channels {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS a lancé le processus d'annulation Astro.",
    "contact_event_coins_adjust": "Type d'ajustement : {type} <br />\nRaison : {reason} <br />\nMontant : {amount} <br />\nCommentaires : {comment} <br />",
    "contact_event_coins_adjust_batched": "Solde de pièces ajusté ({count}).",
    "contact_event_concessions_abuse": "Le HMD n’a pas été envoyé, car le client est un utilisateur {risk_status}.",
    "contact_event_contact_seller": "Vendeur contacté pour la commande {order_id}.",
    "contact_event_contact_seller_batched": "Le vendeur a reçu des commandes ({count}).",
    "contact_event_contact_was_initiated_from": "Le contact a été initié par ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "Contenu de l'ASIN {asin} mis à jour dans la bibliothèque Kindle.",
    "contact_event_content_update_batched": "Contenu mis à jour dans la bibliothèque Kindle ({count}).",
    "contact_event_copilot_started": "Début de session Copilote",
    "contact_event_copilot_started_batched": "Début de session Copilote ({$hash-get count})",
    "contact_event_credit_card_disabled": "CC désactivée.",
    "contact_event_credit_card_disabled_batched": "Cartes de crédit désactivées ({count}).",
    "contact_event_csc_sds_override_geo_fence_submit": "Remplacer la demande de géorepérage soumise pour le numéro de suivi {trackingId} Raison : {overrideReason} Page : {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Remplacer la demande de géorepérage soumise pour le numéro de suivi {trackingId} Raison : {overrideReason} Page : {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Géocode mis à jour. Type de géocode : {geocodeType}, numéro de suivi : {trackingId}, adresse : {addressId}, motif de la dérogation : {overrideReason}, identificateur de la page : {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Géocode mis à jour. Type de géocode : {geocodeType}, motif de la dérogation : {overrideReason}, numéro de suivi : {trackingId}, adresse : {addressId}, identificateur de la page : {pageIdentifier},",
    "contact_event_customer_authentication_passed": "authentification client passée",
    "contact_event_customer_authentication_passed_batched": "authentification client passée({count})",
    "contact_event_customer_name_updated": "Nom du client mis à jour.",
    "contact_event_customer_name_updated_batched": "Nom de client mis à jour ({count}).",
    "contact_event_customer_password_changed": "Mot de passe du client modifié.",
    "contact_event_customer_password_changed_batched": "Mot de passe du client modifié ({count}).",
    "contact_event_delete_alexa_voice_profile": "Profil vocal Alexa supprimé pour speakerId : {speakerId}, customerID : {customerID} et statut : {status}",
    "contact_event_delete_data_alexa_comms": "Profil Alexa Comms supprimé pour commsID : {commsId}, customerID : {customerID} et statut : {status}",
    "contact_event_deprovision_alexa_comms": "Profil Comms Alexa suspendu pour ID comms : {commsId}, ID client : {customerID} et statut : {status}",
    "contact_event_deregister_kindle": "Kindle désinscrit {serial_number}.",
    "contact_event_deregister_kindle_batched": "Appareils Kindle dont l'enregistrement a été annulé ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Assistant de dépannage invoqué pour l'appareil avec le DSN {dsn} : {details}.",
    "contact_event_disable_mayday_failed": "La fonction Secours pour le DSN {dsn} de l'appareil n'a pas pu être désactivée.",
    "contact_event_disable_mayday_success": "La fonction Secours pour le DSN {dsn} de l'appareil a bien été désactivée.",
    "contact_event_dunning_pause": "Processus de relance mis en pause - Voir l'historique des paiements et transactions pour plus de détails",
    "contact_event_dvbypost_extra_dispatch": "{amount} envoi(s) supplémentaires demandé(s) pour ce client.",
    "contact_event_dvbypost_extra_envelope": "Une enveloppe supplémentaire a été déclenchée par ce client.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 de angefordert",
    "contact_event_dvbypost_problem_code_change": "Configuration du disque {disc_id} sur {problem_code}",
    "contact_event_email_address_updated": "Adresse électronique mise à jour.",
    "contact_event_email_address_updated_batched": "Adresse e-mail mise à jour ({count}).",
    "contact_event_email_preferences_changed": "Préférences d'email modifiées.",
    "contact_event_email_unsubscribe": "Adresse e-mail désabonnée.",
    "contact_event_email_unsubscribe_batched": "E-mail désenregistré ({count}).",
    "contact_event_enable_mayday_failed": "La fonction Secours pour le DSN {dsn} de l'appareil n'a pas pu être réactivée.",
    "contact_event_enable_mayday_success": "La fonction Secours pour le DSN {dsn} de l'appareil a été réactivée avec succès.",
    "contact_event_first_time_user_experience_set": "Paramétrer la première expérience de l'utilisateur",
    "contact_event_first_time_user_experience_set_batched": "Paramétrer la première expérience de l'utilisateur ({count}).",
    "contact_event_form_submitted": "Formulaire {form_name} soumis.",
    "contact_event_form_submitted_batched": "Formulaires soumis ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Abonnement Temps libre illimité annulé pour le client : {customerId}, DSN : {deviceSerialNumber} par {agentId} avec la raison : {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Abonnement Temps libre illimité annulé ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "L'essai gratuit de Prime a été bien annulé pour {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime annulé avec succès ({count}).",
    "contact_event_future_cancel_audible_membership": "La résiliation future de l'abonnement {membership} a été programmée pour le {date}.",
    "contact_event_future_cancel_audible_membership_batched": "La résiliation future de l'abonnement {membership} a été programmée pour le {date}.({count})",
    "contact_event_hard_delete_from_yvl": "L'ASIN {asin} a été définitivement supprimé de votre vidéothèque.",
    "contact_event_header": "Registre des événements",
    "contact_event_hmd_suppression": "HMD non envoyé en raison de : {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Chat inactif déconnecté",
    "contact_event_idle_chat_disconnect_batched": "Chat inactif déconnecté ({count})",
    "contact_event_item_price_reduced": "Prix pour {asin} modifié de {old_price} à {new_price}.",
    "contact_event_item_price_reduced_batched": "Prix d'articles modifiés pour les commandes ({count}).",
    "contact_event_item_quantity_changed": "Quantité de {asin} modifiée dans la commande {order_id} de {old_quantity} à {new_quantity}.",
    "contact_event_item_quantity_changed_batched": "Quantités d'articles modifiées pour les commandes ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Remplacement créé pour le DSN  {dsn}  de la commande {old_order_id}. Nouvelle commande : {new_order_id}",
    "contact_event_kindle_device_replacement_batched": "Appareils Kindle remplacés ({count}).",
    "contact_event_kindle_email_address_edits": "Adresse e-mail mise à jour sur Kindle (DSN n° {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Adresses électroniques mises à jour pour les Kindle ({count}).",
    "contact_event_kindle_email_edits": "{DSN} envoi à ladresse e-mail a été modifié de {original_email} à {new_email}",
    "contact_event_kindle_name_changed": "Le Kindle avec le nom de DSN {dsn} a été modifié de {original_name} à {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nom de kindle modifié ({count}).",
    "contact_event_last_page_read_deleted": "Dernière page lue de l'ASIN {asin} supprimée.",
    "contact_event_last_page_read_deleted_batched": "Dernière page lue supprimée ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing lancé pour DSN : {dsn}, kcsID: {kcsId}, réponse du client : {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing lancé pour les appareils ({count}).",
    "contact_event_lock_dash_order": "Les ASIN suivants pour l'appareil {dsn} ont été verrouillés : {asins}.",
    "contact_event_music_auto_renew_off": "CS a désactivé le renouvellement automatique de l'abonnement Music {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS a désactivé le renouvellement automatique de l'abonnement Music via Astro.",
    "contact_event_music_full_refund": "CS a annulé l'abonnement Music {subscriptionid} avec remboursement intégral",
    "contact_event_music_full_refund_static": "CS a annulé l'abonnement Music avec remboursement intégral via Astro.",
    "contact_event_music_no_refund": "CS a annulé l'abonnement Music {subscriptionid} sans remboursement.",
    "contact_event_music_no_refund_static": "CS a annulé l'abonnement Music sans remboursement via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS a désactivé le renouvellement automatique de l'abonnement Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS a annulé l'abonnement Amazon Music Unlimited {subscriptionid} et a émis un remboursement complet.",
    "contact_event_music_unlimited_no_refund": "CS a annulé l'abonnement Amazon Music Unlimited {subscriptionid} sans remboursement.",
    "contact_event_music_unlimited_partial_refund": "CS a annulé l'abonnement Amazon Music Unlimited {subscriptionid} et a émis un remboursement partiel.",
    "contact_event_music_unlimited_workflow_launch": "CS a lancé le processus d'annulation d'Astro de l'abonnement Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS a lancé le processus d'annulation d'Astro de l'abonnement Music{subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS a lancé le processus d'annulation d'Astro de l’abonnement Music illimité.",
    "contact_event_non_dsv_refund_created": "La commande numérique {order_id} a été remboursée à hauteur de {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Commandes numériques remboursées ({count}).",
    "contact_event_one_click_address_updated": "Adresse 1-Click mise à jour.",
    "contact_event_one_click_address_updated_batched": "Adresse 1-Click mise à jour ({count}).",
    "contact_event_one_click_disabled": "1-Click désactivé.",
    "contact_event_one_click_disabled_batched": "1-Click désactivé ({count}).",
    "contact_event_one_click_enabled": "1-Click activé.",
    "contact_event_one_click_payment_updated": "Paiement 1-Click mis à jour.",
    "contact_event_one_click_payment_updated_batched": "Paiement 1-Click mis à jour ({count}).",
    "contact_event_order_cancelled": "Articles annulés dans l'ordre {order_id}.",
    "contact_event_order_cancelled_batched": "Commandes annulées ({count}).",
    "contact_event_order_placed": "Commande passée {order_id}.",
    "contact_event_order_placed_batched": "Commandes passées ({count}).",
    "contact_event_payment_method_added": "Méthode de paiement {payment_type} ajoutée au compte.",
    "contact_event_payment_method_added_batched": "Méthodes de paiement ajoutées au compte ({count}).",
    "contact_event_payment_method_changed": "La méthode de paiement a été modifiée pour la commande {order_id}.",
    "contact_event_payment_method_changed_batched": "La méthode de paiement a été modifiée pour les commandes ({count}).",
    "contact_event_pdocs_max_price_edits": "Frais de conversion max personnelle modifié à {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Frais de conversion maximal des paramètres des documents personnels mis à jour ({count}).",
    "contact_event_pennywise_promo_applied": "Promotion Pennywise appliquée pour le DSN : {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promotion Pennywise appliquée pour les DSN : ({count})",
    "contact_event_ping_device": "Appareil avec le DSN {dsn} sondé.",
    "contact_event_ping_device_batched": "Appareils sondés ({count}).",
    "contact_event_prime_auto_renew_settings": "Réglage du renouvellement automatique de la prime {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Renouvellement automatique de Prime changé en  {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Prime bien annulé de PrimeCancelException. Motif d'annulation : {cancel_reason}.",
    "contact_event_prime_cancelled": "Abonnement Prime annulé.",
    "contact_event_prime_cancelled_batched": "Prime annulé ({count}).",
    "contact_event_prime_concession": "Concession pour Prime émise pour la commande {order_id}.",
    "contact_event_prime_concession_batched": "Concessions Prime émises pour les commandes ({count}).",
    "contact_event_prime_refund": "Remboursement de Prime {amount}.",
    "contact_event_prime_refund_batched": "Prime remboursé ({count}).",
    "contact_event_promo_added_to_account": "Promo de {amount} ajoutée au compte.",
    "contact_event_promo_added_to_account_batched": "Promotions ajoutées au compte ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} promo non échangée.",
    "contact_event_promo_unredeemed_batched": "Promos non échangées ({count}).",
    "contact_event_refund_cancelled": "Remboursement annulé pour la commande {order_id}.",
    "contact_event_refund_created": "Remboursement de {refund_amount} effectué pour la commande {order_id}.",
    "contact_event_refund_created_batched": "Commandes remboursées ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} remboursé de PrimeCancelException. Motif de remboursement : {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Rembourser l'exception d'annulation de Prime ({count}).",
    "contact_event_register_kindle": "Kindle enregistré  {serial_number}.",
    "contact_event_register_kindle_batched": "Appareils Kindle enregistrés ({count}).",
    "contact_event_registry_deleted": "Registre supprimé (Id de registre n° {registry_id}).",
    "contact_event_registry_deleted_batched": "Registres supprimés ({count}).",
    "contact_event_release_aiv_license": "La licence AIV  {asin} a été envoyée à au DSN {dsn} de l'appareil.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} nombre d'articles déplacés de l'appareil avec le DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Articles supprimés des appareils ({count}).",
    "contact_event_remove_from_yvl": "L'ASIN {asin} a été supprimé de votre vidéothèque et placé dans les Articles supprimés.",
    "contact_event_remove_kindle_from_blacklist": "hash-get DSN} supprimé de l'état perdu ou volé, raison : {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Appareils supprimés du statut perdu ou volé ({count}).",
    "contact_event_replacement_created": "Remplacement créé pour la commande  {old_order_id}. Nouvelle commande : {new_order_id}.",
    "contact_event_replacement_created_batched": "Remplacements créés pour les commandes ({count}).",
    "contact_event_request_invoice": "Demande de facture envoyée au vendeur pour la commande {order_id}.",
    "contact_event_request_invoice_batched": "Demande de facture envoyée pour les commandes  ({count}).",
    "contact_event_resend_kindle_content": "Renvoyer le contenu numérique {title} au DSN {dsn} avec le type d'appareil {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Contenu numérique renvoyé ({count}).",
    "contact_event_resent_gifted_content": "L'ASIN {asin} de l'e-mail du destinataire de l'ID de commande {order_id} a été modifié",
    "contact_event_resent_gifted_content_batched": "E-mail du destinataire pour la commande de cadeau mis à jour ({count}).",
    "contact_event_reset_password_email_sent": "E-mail de réinitialisation de mot de passe envoyé.",
    "contact_event_reset_password_email_sent_batched": "E-mails de réinitialisation du mot de passe envoyé ({count}).",
    "contact_event_residence_address_changed": "L'adresse de résidence a été modifiée.",
    "contact_event_residence_address_changed_batched": "Adresse de résidence mise à jour ({count}).",
    "contact_event_rma_cancelled": "RMA annulée pour la commande {order_id}.",
    "contact_event_rma_created": "Etiquette de retour créée pour la commande {order_id}.",
    "contact_event_rma_created_batched": "RMA créés pour les commandes ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nom du DP : {driverName}, \nNom de la station : {stationName}, \nNuméro de suivi : {trackingId} et \nNuméro de la commande : {orderId}\nID de session de cas d'usage : {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget de carte ouvert pour le numéro de suivi {trackingId}",
    "contact_event_sds_package_repick": "Nouveau prélèvement initié par l’agent du service à la clientèle pour le numéro de suivi {trackingId}",
    "contact_event_send_aiv_asin": "L'ASIN {asin} a été envoyé à la vidéothèque du client {customer_id}.",
    "contact_event_ship_charge_reduce": "Le frais d'expédition de la commande {order_id} a été modifié de {old_amount} à {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Frais d'expédition modifiés ({count}).",
    "contact_event_ship_method_upgraded": "La méthode d'expédition pour la commande {order_id} a été mise à jour.",
    "contact_event_ship_method_upgraded_batched": "Méthodes d'expédition modifiées pour les commandes ({count}).",
    "contact_event_shipping_address_changed": "L'adresse a été modifiée pour la commande {new_order_id}.",
    "contact_event_sim_ticket_created": "Ticket SIM créé",
    "contact_event_special_offers_enrollment": "Kindle avec le DSN {dsn} offres spéciales {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Statut d'inscription des offres spéciales mis à jour ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Abonnement Economiez en vous abonnant annulé pour la commande  {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "S'abonner et économiser annulé pour les abonnements ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Fréquence de la livraison programmée Prévoyez et Économisez modifiée pour la commande {order_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Calendriers d'expédition S'abonner et économiser modifiés pour les abonnements ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS a lancé le processus d'annulation d'Astro de {subscriptionType}l'abonnement{subscriptionId}.",
    "contact_event_subscription_auto_renew": "Renouvellement automatique {plan_type} défini sur {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Paramètres de renouvellement automatiquement Prime mis à jour ({count}).",
    "contact_event_subscription_auto_renew_off": "CS a désactivé le renouvellement automatique de {subscriptionType} l'abonnement {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS a annulé {subscriptionType}l'abonnement{subscriptionId} avec un remboursement intégral.",
    "contact_event_subscription_cancel_no_refund": "CS a annulé {subscriptionType}l'abonnement{subscriptionId} sans remboursement.",
    "contact_event_subscription_cancel_partial_refund": "CS a annulé {subscriptionType}l'abonnement{subscriptionId} avec un remboursement partiel.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} abonnement configuré pour le renouvellement  {new_plan_name} de  {old_plan_name} à la fin de la période actuelle.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Paramètres de plan de retour Prime mis à jour  ({count}).",
    "contact_event_troubleshooting_wizard": "Assistant de dépannage invoqué pour l'appareil avec le DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Assistant de dépannage invoqué pour les appareils ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO : {annotation} pour l'appareil avec DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Assistant de dépannage invoqué pour les appareils ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Assistant de résolution des problèmes pour les commandes numériques avec ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "L'état des achats internationaux a été modifié de bloqué à {new_status}",
    "contact_event_unblock_international_purchase_batched": "Statut mis à jour des achats internationaux ({count}).",
    "contact_event_unlock_dash_order": "Les ASIN suivants de l'appareil {dsn}  ont été déverrouills : {asins}.",
    "contact_event_update_locking_status": "Etat de verrouillage de l'appareil Oft {dsn} a été mis à jour sur : {newLockingStatus}",
    "contact_event_verify_email": "Vérification envoyée par e-mail au client.",
    "contact_event_weekend_delivery_preference_changed": "Préférence de livraison en fin de semaine mises à jour"
}