export const es_ES = {
    "contact_event_GC_added": "GC #{gcId} añadido a la cuenta.",
    "contact_event_GC_added_batched": "Cheque regalo agregado a cuenta  ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} añadido a la cuenta del comprador.",
    "contact_event_GC_added_purchaser_batched": "Cheque regalo agregado a cuenta del comprador  ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} añadido a la cuenta del destinatario.",
    "contact_event_GC_added_recipient_batched": "Cheque regalo agregado a cuenta del destinatario  ({count}).",
    "contact_event_GC_added_scratchedCode": "La tarjeta regalo n.º {gcId} se ha añadido a la cuenta a través de la herramienta de reclamación de códigos rascados.",
    "contact_event_GC_added_scratchedCode_batched": "La tarjeta regalo se ha añadido a la cuenta a través de la herramienta de reclamación de códigos rascados ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} cancelado.",
    "contact_event_GC_cancelled_batched": "Cheque regalo cancelado ({count}).",
    "contact_event_GC_changed_expiry": "Se ha cambiado la fecha de caducidad de GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Se ha cambiado la fecha de caducidad del cheque regalo ({count}).",
    "contact_event_GC_locked": "GC #{gcId} bloqueado.",
    "contact_event_GC_locked_batched": "Cheque regalo bloqueado ({count}).",
    "contact_event_GC_removed": "GC #{gcId} quitado de la cuenta.",
    "contact_event_GC_removed_batched": "Cheque regalo eliminado de la cuenta ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} reenviado al Id de correo electrónico {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "Cheque regalo reenviado al Id. de correo electrónico ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} reenviado por correo postal.",
    "contact_event_GC_resent_post_batched": "Cheque regalo reenviado por correo ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} bloqueado temporalmente.",
    "contact_event_GC_temporary_locked_batched": "Cheque regalo bloqueado temporalmente ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transferido del cliente {customerID} a {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "Cheque regalo transferido  ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} desbloqueado.",
    "contact_event_GC_unlocked_batched": "Cheque regalo no bloqueado ({count}).",
    "contact_event_PV_added": "Se ha añadido el PV {pvId} a la cuenta.",
    "contact_event_PV_locked": "Se ha bloqueado el PV {pvId}.",
    "contact_event_PV_temporary_locked": "Se ha bloqueado temporalmente el PV {pvId}.",
    "contact_event_PV_unlocked": "Se ha desbloqueado el PV {pvId}.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Reclamación A-Z cancelada para pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Reclamación A-Z enviada para el pedido {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Reclamación A-Z enviada para pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Garantía de la A a la Z enviada para el pedido {order_id}.",
    "contact_event_access_code_added": "Código de acceso añadido",
    "contact_event_account_closed": "Cuenta cerrada.",
    "contact_event_account_closed_batched": "Cuentas cerradas ({count}).",
    "contact_event_account_reopened": "Cuenta reabierta",
    "contact_event_account_reopened_batched": "Cuentas reabiertas ({count})",
    "contact_event_account_temporarily_disabled": "Cuenta desactivada temporalmente.",
    "contact_event_account_temporarily_disabled_batched": "Cuentas desactivadas temporalmente ({count})",
    "contact_event_account_whitelisted": "Cuenta en la lista blanca.",
    "contact_event_address_changed": "Se ha actualizado la dirección {address_type} correspondiente al pedido {order_id}.",
    "contact_event_address_changed_batched": "Direcciones actualizadas para pedidos ({count}).",
    "contact_event_address_deactivated": "Dirección desactivada",
    "contact_event_address_deactivated_batched": "Dirección desactivada ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Suscripción a Amazon Video renovada automáticamente",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulario enviado: formulario de escalación de DSL\nID de sesión de caso de uso: {usecaseSessionID}\nAlmacén de envío: {deliveryStationField}\nTipo de solicitud: {requestTypeField}\nID del contacto:{contactIdField}\nIdentificador de pedido: {orderIdField}\nDirección de correo electrónico del cliente: {customerEmailField}\nNúmero de teléfono del cliente: {customerPhoneNumberField}\nTBA: {tbasField}\nCódigo de acceso (si procede): {accessCodeField}\nResumen de problemas: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formularios de escalación de DSL enviados: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulario enviado: formulario de comentarios de DA de DSP\nID de sesión de caso de uso: {usecaseSessionID}\nCódigo del almacén de envío: {deliveryStationField}\nID del contacto:{contactIdField}\nID de cuenta de cliente: {customerIdField}\nID del transportista de DA:{driverIdField}\nFecha del incidente (DD-MM-AAAA): {dateOfOccurrenceField}\nHora del incidente (HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nOrigen del comentario: {sourceOfFeedbackField}\nDetalles del comentario: {issueField}\nDetalles adicionales: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formularios de comentarios de DA de DSP enviados: ({count})",
    "contact_event_andon_cord_pulled": "Se ha activadoAndon Cord para {asin}.",
    "contact_event_andon_cord_pulled_batched": "Producción detenida para artículos ({count}).",
    "contact_event_apply_adrive_music_trial": "aplicado Cloud Player Prime para el cliente {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_cash_and_coupon_refund": "{title} ha recibido un reembolso por importe de {cashAmount}. Además, esta transacción ha restaurado un la cuenta un cupón por importe de {couponAmount}.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} ha recibido un reembolso por importe de {cashAmount}. Además, esta transacción ha restaurado un la cuenta un cupón por importe de {couponAmount}.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} ha recibido un reembolso por importe de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} ha recibido un reembolso por importe de {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} ha recibido un reembolso y se ha restaurado en la cuenta un cupón por importe de {couponAmount}.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} ha recibido un reembolso y se ha restaurado en la cuenta un cupón por importe de {couponAmount}.{counter} ({count})",
    "contact_event_audible_credit_deleted": "Se han eliminado {amount} créditos de la cuenta.",
    "contact_event_audible_credit_issued": "Se han añadido {numCredit} créditos a tu cuenta.",
    "contact_event_audible_credit_issued_batched": "Se han añadido créditos a la cuenta ({count}).",
    "contact_event_audible_credit_refund": "{title} ha recibido un reembolso y se han restaurado {creditAmount} créditos en la cuenta.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} ha recibido un reembolso y se han restaurado {creditAmount} créditos en la cuenta.{counter} ({count})",
    "contact_event_audible_credit_restored": "Se han restaurado {amount} créditos a la cuenta.",
    "contact_event_audible_credit_restored_batched": "Se han restaurado {amount} créditos a la cuenta.({count})",
    "contact_event_audible_extra_credit_purchased": "Se han comprado tres créditos adicionales.",
    "contact_event_audible_extra_credit_purchased_batched": "Se han comprado tres créditos adicionales. ({count})",
    "contact_event_audible_hiatus_cancelled": "El periodo de interrupción ha terminado antes de tiempo y la suscripción se ha reanudado.",
    "contact_event_audible_hiatus_cancelled_batched": "El periodo de interrupción ha terminado antes de tiempo y la suscripción se ha reanudado.({count})",
    "contact_event_audible_hiatus_started": "La suscripción se ha interrumpido durante un plazo de {duration} meses.",
    "contact_event_audible_hiatus_started_batched": "La suscripción se ha interrumpido durante un plazo de {duration} meses.({count})",
    "contact_event_audible_membership_plan_refund": "Se ha reembolsado la tarifa de suscripción por importe de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Se ha reembolsado la tarifa de suscripción por importe de {cashAmount}.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Inscrito en el {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Inscrito en el {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "Se ha accedido a {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Se ha accedido a {wizardName}.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "El plan {membership} se ha cancelado correctamente.",
    "contact_event_blacklist_kindle": "Dispositivo incluido en lista negra {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Kindles incluidos en listas negras ({count}).",
    "contact_event_bound_warranty": "Id. de garantía {warranty_id} vinculado al dispositivo {dsn}.",
    "contact_event_bound_warranty_batched": "Garantías vinculadas a dispositivos ({count}).",
    "contact_event_call_was_flagged": "Este contacto se ha marcado como {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Este contacto se ha marcado como {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "El plan {membership} se ha cancelado correctamente.",
    "contact_event_cancel_audible_membership_batched": "El plan {membership} se ha cancelado correctamente ({count}).",
    "contact_event_cancel_audible_romance_package_batched": "El plan {membership} se ha cancelado correctamente ({count}).",
    "contact_event_cancel_from_prime_cancel_exception": "Se ha cancelado correctamente el plan Prime desde PrimeCancelException. Motivo de cancelación: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Excepción de cancelación de Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "La fecha de cancelación futura fijada anteriormente se ha cancelado.",
    "contact_event_cancelled_sns_reactivated": "El ASIN {asin} para la suscripción se reactivó el {reactivation_date}(month) por {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Suscripciones canceladas reactivadas ({count}).",
    "contact_event_captcha_bypass_key_generated": "Clave de derivación Captcha generada.",
    "contact_event_captcha_bypass_key_generated_batched": "Clave de derivación Captcha generada ({count}).",
    "contact_event_change_sns_quantity": "La suscripción del ASIN {asin} ha modificado la cantidad por envío de {current_quantity} a {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Cantidades actualizadas de Suscríbete y ahorra ({count}).",
    "contact_event_channels_auto_renew_off": "CS ha desactivado la renovación automática para la suscripción a canales de PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS ha desactivado la renovación automática a través de Astro.",
    "contact_event_channels_full_refund": "CS ha cancelado la suscripción a canales de PV {subscriptionid} con reembolso completo.",
    "contact_event_channels_full_refund_static": "CS ha cancelado la suscripción con reembolso completo a través de Astro.",
    "contact_event_channels_no_refund": "CS ha cancelado la suscripción a canales de PV {subscriptionid} sin reembolso.",
    "contact_event_channels_no_refund_static": "CS ha cancelado la suscripción sin reembolso a través de Astro.",
    "contact_event_channels_workflow_launch": "CS ha lanzado el flujo de trabajo de cancelación de Astro para la suscripción a canales de PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS ha iniciado el flujo de trabajo de cancelación de Astro.",
    "contact_event_coins_adjust": "Tipo de ajuste: {type} <br />\nMotivo: {reason} <br />\nImporte: {amount} <br />\nComentarios: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo ajustado de Coins ({count}).",
    "contact_event_concessions_abuse": "HMD no se ha enviado debido a que el cliente es un usuario {risk_status}.",
    "contact_event_contact_seller": "Vendedor contactado por el pedido {order_id}.",
    "contact_event_contact_seller_batched": "Vendedor contactado para pedidos ({count}).",
    "contact_event_contact_was_initiated_from": "Se ha iniciado contacto de ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "El contenido de ASIN {asin} se ha actualizado en la biblioteca de Kindle.",
    "contact_event_content_update_batched": "Contenido actualizado en biblioteca Kindle ({count}).",
    "contact_event_copilot_started": "Sesión de Copiloto iniciada.",
    "contact_event_copilot_started_batched": "Sesión de Copiloto iniciada ({count})",
    "contact_event_credit_card_disabled": "CC desactivado.",
    "contact_event_credit_card_disabled_batched": "Tarjetas de crédito desactivadas ({count}).",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Anular la solicitud de geovalla enviada para el número de seguimiento {trackingId} Motivo: {overrideReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Anular la solicitud de geovalla enviada para el número de seguimiento {trackingId} Motivo: {overrideReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Se ha actualizado la geocodificación. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Se ha actualizado la geocodificación. GeocodeType: {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} y pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "autenticación de cliente correcta",
    "contact_event_customer_authentication_passed_batched": "autenticación de cliente correcta({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Nombre de cliente actualizado.",
    "contact_event_customer_name_updated_batched": "Nombre de cliente actualizado ({count}).",
    "contact_event_customer_password_changed": "Se ha cambiado la contraseña del usuario.",
    "contact_event_customer_password_changed_batched": "Contraseña de cliente cambiada ({count}).",
    "contact_event_delete_alexa_voice_profile": "Perfil de voz de Alexa eliminado para speakerId {speakerId}, customerID {customerID} y el estado {status}",
    "contact_event_delete_data_alexa_comms": "Eliminado el perfil de comunicaciones de Alexa para commsID {commsId}, customerID {customerID} y el estado {status}",
    "contact_event_deprovision_alexa_comms": "Desaprovisionado el perfil de comunicaciones de Alexa para commsID: {commsId}, customerID: {customerID} y el estado: {status}",
    "contact_event_deregister_kindle": "Registro de Kindle anulado {serial_number}.",
    "contact_event_deregister_kindle_batched": "Kindles con registro anulado ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Se ha recurrido al asistente de resolución de problemas para el dispositivo con DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "La función Mayday del dispositivo con DSN {dsn} no se ha desactivado.",
    "contact_event_disable_mayday_success": "La función Mayday del dispositivo con DSN {dsn} se ha desactivado correctamente.",
    "contact_event_dunning_pause": "Proceso de reclamación pausado - Ver Historial de pagos y transacciones para obtener más información",
    "contact_event_dvbypost_extra_dispatch": "{amount} envíos adicionales se han solicitado para este cliente.",
    "contact_event_dvbypost_extra_envelope": "Se ha desencadenado un sobre adicional para este cliente.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 form obligatoria",
    "contact_event_dvbypost_problem_code_change": "Configurando disco {disc_id} para {problem_code}",
    "contact_event_email_address_updated": "Dirección de correo electrónico actualizada.",
    "contact_event_email_address_updated_batched": "Dirección de correo electrónico actualizada ({count}).",
    "contact_event_email_preferences_changed": "Se han cambiado las preferencias de correo electrónico.",
    "contact_event_email_unsubscribe": "Anulada la suscripción de correo electrónico.",
    "contact_event_email_unsubscribe_batched": "Correo electrónico con suscripción anulada ({count}).",
    "contact_event_enable_mayday_failed": "La función Mayday del dispositivo con DSN {dsn} no se ha reactivado.",
    "contact_event_enable_mayday_success": "La función Mayday del dispositivo con DSN {dsn} se ha reactivado correctamente.",
    "contact_event_first_time_user_experience_set": "Configurar experiencia de usuario nuevo",
    "contact_event_first_time_user_experience_set_batched": "Configurar experiencia de usuario nuevo({count}).",
    "contact_event_form_submitted": "Se ha enviado el formulario {form_name}.",
    "contact_event_form_submitted_batched": "Formularios enviados ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Se ha cancelado la suscripción gratuita e indefinida del cliente: {customerId}, DSN: {deviceSerialNumber} por {agentId} por este motivo: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Cancelada la suscripción indefinida gratuita ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Ha cancelado correctamente la prueba gratuita de Prime por {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Cancelación Prime satisfactoria ({count}).",
    "contact_event_future_cancel_audible_membership": "El plan {membership} ha cambiado a Cancelación futura y se cancelará el {date}.",
    "contact_event_future_cancel_audible_membership_batched": "El plan {membership} ha cambiado a Cancelación futura y se cancelará el {date} ({count}).",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} se ha eliminado permanentemente de tu biblioteca de vídeo.",
    "contact_event_header": "Registro de eventos",
    "contact_event_hmd_suppression": "HMD no se ha enviado porque: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Chat inactivo desconectado",
    "contact_event_idle_chat_disconnect_batched": "Chat inactivo desconectado ({count})",
    "contact_event_item_price_reduced": "El precio del pedido {asin} ha cambiado de {old_price} a {new_price}.",
    "contact_event_item_price_reduced_batched": "Precios de artículos cambiados para pedidos ({count}).",
    "contact_event_item_quantity_changed": "Se ha cambiado la cantidad de {asin} en el pedido {order_id} de {old_quantity} a {new_quantity}.",
    "contact_event_item_quantity_changed_batched": "Cantidades de artículos cambiadas para pedidos ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Se ha creado el reemplazo de DSN {dsn}  del pedido {old_order_id} . Pedido nuevo: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Dispositivos Kindle reemplazados ({count}).",
    "contact_event_kindle_email_address_edits": "Dirección de correo electrónico actualizada en Kindle (n.º de DSN {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Direcciones de correo electrónico actualizadas para dispositivos Kindle ({count}).",
    "contact_event_kindle_email_edits": "Se ha cambiado la dirección de destinatario de correo electrónico de {dsn} de {original_email} a {new_email}.",
    "contact_event_kindle_name_changed": "El nombre de Kindle con DSN {dsn} ha cambiado de {original_name} a {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nombre de Kindle cambiado ({count}).",
    "contact_event_last_page_read_deleted": "La última página leída de ASIN {asin} se ha eliminado.",
    "contact_event_last_page_read_deleted_batched": "Eliminada la última página leída ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing abierto para DSN: {dsn}, kcsID: {kcsId}, respuesta del cliente: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing abierto para dispositivos ({count}).",
    "contact_event_lock_dash_order": "Los siguientes ASIN del dispositivo {dsn} se han bloqueado: {asins}.",
    "contact_event_music_auto_renew_off": "CS ha desactivado la renovación automática para la suscripción a Música {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS ha desactivado la renovación automática para la suscripción a Música a través de Astro.",
    "contact_event_music_full_refund": "CS ha cancelado la suscripción a Música {subscriptionid} con reembolso completo",
    "contact_event_music_full_refund_static": "CS ha cancelado la suscripción a Música con reembolso completo a través de Astro.",
    "contact_event_music_no_refund": "CS ha cancelado la suscripción a Música {subscriptionid} sin reembolso.",
    "contact_event_music_no_refund_static": "CS ha cancelado la suscripción a Música sin reembolso a través de Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS ha desactivado la renovación automática para la suscripción a Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS ha cancelado la suscripción a Amazon Music Unlimited {subscriptionid} con un reembolso completo.",
    "contact_event_music_unlimited_no_refund": "CS ha cancelado la suscripción a Amazon Music Unlimited {subscriptionid} sin reembolso.",
    "contact_event_music_unlimited_partial_refund": "CS ha cancelado la suscripción a Amazon Music Unlimited {subscriptionid} con un reembolso parcial.",
    "contact_event_music_unlimited_workflow_launch": "CS ha lanzado el flujo de trabajo de cancelación de Astro para la suscripción a Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS ha lanzado el flujo de trabajo de cancelación de Astro para la suscripción a Música {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS ha lanzado el flujo de trabajo de cancelación de Astro para Música ilimitado.",
    "contact_event_non_dsv_refund_created": "El pedido digital {order_id} se ha reembolsado {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Pedidos digitales reembolsados ({count}).",
    "contact_event_one_click_address_updated": "Actualizada la dirección de 1-Clic.",
    "contact_event_one_click_address_updated_batched": "Dirección de 1-Clic actualizada ({count}).",
    "contact_event_one_click_disabled": "1-Clic inhabilitado .",
    "contact_event_one_click_disabled_batched": "1-Clic inhabilitado ({count}).",
    "contact_event_one_click_enabled": "1-Clic activado.",
    "contact_event_one_click_payment_updated": "Pago con 1-Clic actualizado",
    "contact_event_one_click_payment_updated_batched": "Pago de 1-Clic actualizado ({count}).",
    "contact_event_order_cancelled": "Artículos cancelados en pedido {order_id}.",
    "contact_event_order_cancelled_batched": "Pedidos cancelados ({count}).",
    "contact_event_order_placed": "Se ha realizado el pedido {order_id}.",
    "contact_event_order_placed_batched": "Pedidos realizados ({count}).",
    "contact_event_payment_method_added": "Método de pago {payment_type} añadido a la cuenta.",
    "contact_event_payment_method_added_batched": "Métodos de pago agregados a cuenta ({count}).",
    "contact_event_payment_method_changed": "Ha cambiado el método de pago del pedido {order_id}.",
    "contact_event_payment_method_changed_batched": "Ha cambiado el método de pago de los pedidos ({count}).",
    "contact_event_pdocs_max_price_edits": "El cargo máximo por conversión de los ajustes de documentos personales ha cambiado a {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Actualizado el gasto máximo de conversión en ajustes de documentos personales ({count}).",
    "contact_event_pennywise_promo_applied": "Promoción de ahorro aplicada a DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promoción de ahorro aplicada a DSN: ({count})",
    "contact_event_ping_device": "El dispositivo con DSN {dsn} ha hecho ping.",
    "contact_event_ping_device_batched": "Dispositivos contactados mediante ping ({count}).",
    "contact_event_prime_auto_renew_settings": "Renovación automática de Prime establecida en {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Renovación automática de plan Prime {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Se ha cancelado correctamente el plan Prime desde PrimeCancelException. Motivo de cancelación: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime cancelado.",
    "contact_event_prime_cancelled_batched": "Plan Prime cancelado ({count}).",
    "contact_event_prime_concession": "Concesión de Prime emitida para el pedido {order_id}.",
    "contact_event_prime_concession_batched": "Concesiones Prime emitidas para pedidos ({count}).",
    "contact_event_prime_refund": "Prime reembolsado: {amount}.",
    "contact_event_prime_refund_batched": "Plan Prime reembolsado ({count}).",
    "contact_event_promo_added_to_account": "Promoción de {amount} añadida a la cuenta.",
    "contact_event_promo_added_to_account_batched": "Promociones agregadas a cuenta ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} de promoción sin canjear.",
    "contact_event_promo_unredeemed_batched": "Promociones sin canjear ({count}).",
    "contact_event_refund_cancelled": "Reembolso cancelado para el pedido {order_id}.",
    "contact_event_refund_created": "Se han reembolsado {refund_amount} correspondiente al pedido {order_id}.",
    "contact_event_refund_created_batched": "Pedidos reembolsados ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} reembolsado desde PrimeCancelException. Motivo del reembolso: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Reembolsar excepción de cancelación de Prime ({count}).",
    "contact_event_register_kindle": "Kindle registrado {serial_number}.",
    "contact_event_register_kindle_batched": "Kindles registrados ({count}).",
    "contact_event_registry_deleted": "Registro eliminado (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Registros eliminados ({count}).",
    "contact_event_release_aiv_license": "La licencia de AIV {asin} se ha liberado en DSN de dispositivo {dsn}.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} número de dispositivos trasladados del dispositivo con DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Elementos eliminados de dispositivos ({count}).",
    "contact_event_remove_from_yvl": "ASIN {asin} se ha quitado de tu biblioteca de vídeo a Elementos eliminados.",
    "contact_event_remove_kindle_from_blacklist": "El Kindle para DSN {dsn} ha anulado el estado de perdido o robado, razón: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Dispositivos eliminados de estado perdido o robado ({count}).",
    "contact_event_replacement_created": "Se ha creado un reemplazo para el pedido {old_order_id}. Nuevo pedido: {new_order_id}.",
    "contact_event_replacement_created_batched": "Se han creado reemplazos para pedidos ({count}).",
    "contact_event_report_seller": "Vendedor contactado por el pedido {order_id}.",
    "contact_event_report_seller_batched": "Vendedor contactado para pedidos ({count}).",
    "contact_event_request_invoice": "Petición de factura enviada al vendedor del pedido {order_id}.",
    "contact_event_request_invoice_batched": "Petición de factura enviada para los pedidos ({count}).",
    "contact_event_resend_kindle_content": "Contenido digital reenviado {title} a DSN {dsn} con tipo de dispositivo {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Contenido digital reenviado ({count}).",
    "contact_event_resent_gifted_content": "Ha cambiado el destinatario de correo electrónico del ASIN {asin} para Id. de pedido {order_id}.",
    "contact_event_resent_gifted_content_batched": "Actualizado el correo electrónico del destinatario del pedido con regalo ({count}).",
    "contact_event_reset_password_email_sent": "Correo electrónico para restablecer contraseña enviado.",
    "contact_event_reset_password_email_sent_batched": "Correos electrónicos para restablecer contraseña enviados({count}).",
    "contact_event_residence_address_changed": "Ha cambiado la dirección de residencia.",
    "contact_event_residence_address_changed_batched": "Actualizada la dirección de residencia ({count}).",
    "contact_event_rma_cancelled": "RMA cancelado para el pedido {order_id}.",
    "contact_event_rma_created": "RMA creado para el pedido {order_id}.",
    "contact_event_rma_created_batched": "RMA generados para pedidos ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nombre de DP: {driverName}, \nNombre de almacén de envío: {stationName}, \nNúmero de seguimiento: {trackingId} e \nID del pedido: {orderId}\nID de sesión de usuario: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget de mapas abierto para el número de seguimiento {trackingId}",
    "contact_event_sds_package_repick": "El agente ha iniciado la segunda recogida para número de seguimiento {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} se ha enviado a tu biblioteca de vídeo de cliente {customer_id} .",
    "contact_event_ship_charge_reduce": "El cargo de envío del pedido {order_id} ha cambiado de {old_amount} a {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Cargos de envío cambiados ({$hash-get count}).",
    "contact_event_ship_method_upgraded": "Se ha actualizado el método de envío del pedido {order_id} updated.",
    "contact_event_ship_method_upgraded_batched": "Métodos de envío cambiados para pedidos ({count}).",
    "contact_event_shipping_address_changed": "Dirección cambiada en el pedido {new_order_id}.",
    "contact_event_sim_ticket_created": "Ticket de SIM creado",
    "contact_event_special_offers_enrollment": "Ofertas especiales de Kindle con DSN {dsn} {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Actualizado el eestado de inscripción para ofertas especiales ({count}).",
    "contact_event_subscribe_and_save_cancelled": "\"Suscríbete y ahorra\" cancelado para el pedido {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "Suscripciones canceladas ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "El plazo de entrega \"Suscríbete y ahorra\" se ha cambiado para la suscripción {subscription_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Suscripciones con envíos actualizados ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS ha lanzado el flujo de trabajo de anulación de Astro para la suscripción {subscriptionType}{subscriptionId}.",
    "contact_event_subscription_auto_renew": "La renovación automática de {plan_type} está establecida en {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Actualizados ajustes de renovación automática Prime ({count}).",
    "contact_event_subscription_auto_renew_off": "CS ha desactivado la renovación automática para la suscripción {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS anuló la suscripción {subscriptionType} {subscriptionId} con reembolso completo.",
    "contact_event_subscription_cancel_no_refund": "CS anuló la suscripción {subscriptionType} {subscriptionId} sin reembolso.",
    "contact_event_subscription_cancel_partial_refund": "CS anuló la suscripción {subscriptionType} {subscriptionId} con reembolso parcial.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "La suscripción {plan_type} está configurada para renovarse a {new_plan_name} a partir de {old_plan_name} al final del periodo actual.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Ajustes del plan actualizado de cambio a Prime ({count}).",
    "contact_event_troubleshooting_wizard": "Se ha recurrido al asistente de resolución de problemas para el dispositivo con DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Asistente de resolución de problemas utilizado para dispositivos ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} para dispositivo con DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Asistente de resolución de problemas utilizado para dispositivos ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Se ha invocado el asistente de resolución de problemas para el pedido digital con ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "El estado de compra internacional ha cambiado de bloqueado a {new_status}",
    "contact_event_unblock_international_purchase_batched": "Actualizado el estado de compras internacionales ({count}).",
    "contact_event_unlock_dash_order": "Los siguientes ASIN del dispositivo {dsn} se han desbloqueado: {asins}.",
    "contact_event_update_locking_status": "Bloqueando estado de dispositivo Oft  {dsn} se ha actualizado a: {newLockingStatus}",
    "contact_event_verify_email": "Correo de verificación enviado al cliente.",
    "contact_event_weekend_delivery_preference_changed": "Actualización de la preferencia de entrega el fin de semana"
}