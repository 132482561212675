export const fr_CA = {
    "ABANDONED": "ABANDONNÉ par",
    "ABANDONED_BY_AGENT": "ABANDONNÉ par {agent}",
    "ACCESS_RESTRICTED_CONTENT": "Accéder au contenu restreint",
    "ACTION": "Action",
    "ADD": "Ajouter",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Coordonnées supplémentaires",
    "ADD_ANNOTATION_FAILURE": "Échec de l’ajout de l’annotation.",
    "ADD_ANNOTATION_INVALID": "Annotation non valide. Assurez-vous qu’il n’y a pas de données restreintes dans l’annotation.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nouvelle annotation",
    "ADD_ANNOTATION_SUCCESS": "Ajout de l’annotation réussie.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Problème sélectionné par l’agent",
    "AGENT_SELECTED_ISSUE_COPIED": "Problème sélectionné par l’agent copié.",
    "AMAZON_CONNECT_CALL_INFO": "Informations sur les appels Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID de contact Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "L’ID de contact Amazon Connect a été copié",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANALYZED": "ANALYSÉ par",
    "ANALYZED_BY_AGENT": "ANALYSÉ par {agent}",
    "ANNOTATED_BY": "Annoté par {agentLogin}.",
    "ANNOTATIONS": "Annotations",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Les annotations ne sont pas censées contenir des données PII client telles que des numéros de téléphone et des adresses courriel. Notre système automatisé essaie de faire respecter cela, mais parfois il ne fait pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle une dérogation est nécessaire.",
    "ANNOTATION_WARNING": "Les annotations peuvent être l’objet d’avis de clients conformément aux règlements sur la confidentialité. Ne pas inclure de renseignements personnels ou d’opinions dans le champ des annotations.",
    "ATTACHMENTS_RESTRICTED": "Les pièces jointes sont restreintes.",
    "ATTACHMENTS_TITLE": "Pièces jointes",
    "ATTACHMENT_DELETION_FAILED": "Échec de la suppression des pièces jointes. Veuillez réessayer ultérieurement.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Suppression des pièces jointes réussie.",
    "AUTHENTICATING": "Authentification démarrée. Cela peut ouvrir une nouvelle fenêtre.",
    "AUTHENTICATION_FAILURE": "Un problème s’est produit lors de l’authentification.",
    "AUTHENTICATION_MESSAGE": "Récupération de nouvelles informations d’identification. Cette fenêtre se fermera dans quelques secondes.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Retour",
    "BCC_EMAIL_ADDRESS": "Cci",
    "BLURBS_USED_TITLE": "Textes utilisés",
    "CALL_LEG": "ID d’étape d’appel",
    "CALL_LEG_ID_COPIED": "ID d’étape d'appel copié.",
    "CALL_RESTRICTED": "Le contenu de l’appel est limité.",
    "CALL_RETENTION_DELETED": "L’enregistrement a dépassé sa période de conservation et a été supprimé.",
    "CALL_TITLE": "Appeler le Service à la clientèle d’Amazon",
    "CANCEL": "Annuler",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Ce client a un très grand nombre de contacts et ils n’ont pas tous été chargés. Les contacts sont toujours chargés dans la direction « récent à plus ancien » à partir de la date de fin. Modifiez la date de fin pour commencer le chargement à partir d’un autre moment.",
    "CHANNELS": "Canaux",
    "CHAT_AGENT_CONNECTED": "{name} a accepté la discussion.",
    "CHAT_CONTACT": "Contact initié par clavardage.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "Connexion {name} perdue avec le réseau.",
    "CHAT_DISCONNECT_GENERIC": "{name} n’est plus connecté.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "Connexion {name} perdue avec le réseau.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "Connexion {name} perdue avec le réseau.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} a quitté la conversation.",
    "CHAT_PARKED": "Cette discussion a été parquée",
    "CHAT_PARKED_NOTES": "Remarques sur la mise de côté",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} est hors attente.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} est en attente.",
    "CHAT_RESTRICTED": "Le contenu de la discussion est restreint.",
    "CHAT_TITLE": "Discussion avec le service client Amazon",
    "CHAT_TRANSFER_NOTES": "Notes de transfert",
    "CHILD_WORK_REQUESTS": "Demandes de travail créées à partir de ce contact",
    "CHOOSE_OPTIONS": "Sélectionner des options",
    "CLOSE": "Fermer",
    "CLOSE_ALERT": "Fermer l’alerte",
    "CLOSE_MODAL_BUTTON_TEXT": "Fermer",
    "COLLAPSED": "Réduit",
    "CONFIRM": "Confirmer",
    "CONTACTS": "Contacts",
    "CONTACT_COLLAPSED": "Coordonnées réduites.",
    "CONTACT_CREATION_FAILURE": "Échec de la création du nouveau contact",
    "CONTACT_EXPANDED": "Détails du contact élargis.",
    "CONTACT_HISTORY": "Historique des contacts",
    "CONTACT_ID": "ID de contact :",
    "CONTACT_ID_COPIED": "ID de contact copié.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contact",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalades",
    "CONTACT_REASSIGNED_TO_AGENT": "Le contact a été réaffecté à l’agent.",
    "CONTACT_SELECT_CHECKBOX": "Cochez la case à cocher pour sélectionner un contact verrouillé",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalade",
    "CONTACT_TICKET": "Contact Ticket",
    "CONTACT_TICKET_CONTENT": "Contenu du billet",
    "CONTACT_TICKET_CREATE": "Créer un ticket de contact",
    "CONTACT_TICKET_CREATION_FAILURE": "Impossible de créer le ticket de contact",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket de contact créé",
    "CONTACT_TICKET_RESOLVER_GROUP": "Groupe Résolveur",
    "CONTACT_TICKET_SEVERITY": "Sévérité du billet",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID du dossier SIM",
    "CONTACT_TICKET_SUBJECT": "Objet du billet",
    "CONTACT_TIMELINE": "Contacter Timeline",
    "CONTACT_TYPE": "Type de contact",
    "CONTACT_WAS_TRANSFERRED": "Le contact a été transféré.",
    "CONTENT": "Contenu",
    "CONTENT_RESTRICTED": "Le contenu est restreint.",
    "COPY_AGENT_SELECTED_ISSUE": "Copier le problème sélectionné par l’agent : {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copier l’ID de contact Amazon Connect",
    "COPY_CALL_LEG_ID": "Copier l'ID d’étape d'appel : {value}",
    "COPY_CONTACT_ID": "Copier l’ID du contact : {value}",
    "COPY_CUSTOMER_ID": "Copier l’identifiant du client",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copier le problème sélectionné par le client : {value}",
    "COPY_FAILURE": "Impossible de copier.",
    "COPY_ID": "Copier l'ID",
    "COPY_LINK": "Copier le lien de téléversement",
    "COPY_ORDER_ID": "Copier l’ID de la commande : {value}.",
    "CREATE_CASE": "Créer un dossier",
    "CREATE_FOLLOW_UP": "Créer un suivi",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Suivi créé avec succès, mais impossible d’ajouter une annotation correspondante. Réessayez d’ajouter une annotation manuellement.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Spécifiez la méthode de contact préférée du client.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Impossible de créer un suivi avec une date et une heure d’échéance dans le passé.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Indiquez la date et l’heure auxquelles l’action de suivi est due.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Fuseau horaire",
    "CREATE_FOLLOW_UP_FAILURE": "Impossible de créer le suivi.",
    "CREATE_FOLLOW_UP_INVALID": "Suivi non valide. Veuillez vous assurer qu’il n’y a pas de données restreintes.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Saisissez l’action de suivi.",
    "CREATE_FOLLOW_UP_SUCCESS": "Suivi créé avec succès.",
    "CREATE_WORK_REQUEST": "Créer une demande de travail",
    "CROSS_MARKETPLACE_QUEUES": "Files d’attente entre les sites de vente",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Impossible de transférer le contact car il est déjà résolu.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "L’opération a échoué, car le contact est dans un état non valide.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Échec de l'opération car le contact n'a pas été trouvé",
    "CSALT_ERROR_INTERNAL_ERROR": "Échec du verrouillage du contact",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Impossible de verrouiller un contact en raison d’arguments non valides",
    "CSALT_ERROR_INVALID_INPUT": "L’opération a échoué en raison d’entrées non valides.",
    "CSALT_ERROR_INVALID_QUEUE": "Impossible de transférer le contact car la file d’attente sélectionnée est non valide.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Échec de l'opération car le contact est verrouillé par un autre agent.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Impossible de verrouiller le contact en raison d’une erreur de dépendance",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Le transfert du contact a échoué, car la file d’attente n’a pas été trouvée dans le service dorsal.",
    "CSALT_ERROR_UNKNOWN": "L’opération a échoué en raison d’une erreur inattendue.",
    "CURRENT_CONTACT": "Contact courant",
    "CUSTOMER": "Client",
    "CUSTOMER_FOLLOWUPS": "Suivis pour le client",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Échec de la récupération des suivis pour le client",
    "CUSTOMER_ID_COPIED": "Identifiant du client copié",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Le contenu du client est restreint. (Aucun contact verrouillé.)",
    "CUSTOMER_SELECTED_ISSUE": "Problème sélectionné par le client",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Problème sélectionné par le client copié.",
    "CUSTOMER_TIMEZONE": "Horodatages affichés dans le fuseau horaire du client",
    "DATE": "Date",
    "DATE_PICKER_NEXT_MONTH": "Mois prochain",
    "DATE_PICKER_PREVIOUS_MONTH": "Mois précédent",
    "DATE_PICKER_TODAY": "Aujourd’hui",
    "DECODING_AUDIO": "Décodage audio",
    "DELETE_ATTACHMENTS": "Supprimer les pièces jointes de contact",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Supprimer les pièces jointes",
    "DELIVERY_STATUS": "Statut de livraison",
    "DISABLE_CONTENT_FILTER": "Désactiver le filtre du contenu",
    "DOWNLOAD": "Télécharger",
    "DURATION": "Durée",
    "EMAIL": "Courriel",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Les adresses de courriel du destinataire ne sont pas autorisées.",
    "EMAIL_BODY": "Corps du courriel",
    "EMAIL_CONTACT": "Contact par courriel.",
    "EMAIL_HEADER": "En-tête",
    "EMAIL_QUEUE_TITLE": "File d’attente de courriels",
    "EMAIL_RESTRICTED": "Le contenu du courriel est restreint.",
    "EMAIL_SENDING_FAILURE": "Échec de l'envoi du courriel sortant.",
    "EMAIL_SUBJECT": "Objet du courriel",
    "EMAIL_TITLE": "Courriel au service client Amazon",
    "END": "Terminer",
    "END_DATE": "Date de fin",
    "ERROR": "Erreur",
    "ERROR_FAILED_TO_LOAD_DATA": "Échec du chargement des données.",
    "ESCALATION_CONTACT_HANDLING": "Traitement d’escalade du contact",
    "ESCALATION_TAB_LEAD_LOGIN": "Connexion principale : {agentLogin}",
    "EXPANDED": "Élargi",
    "FAILED_TO_CREATE_WORK_REQUEST": "Échec de la création d’une demande de travail",
    "FAILED_TO_FETCH_SYSKA": "Échec de la récupération des alertes SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Échec de la réaffectation du contact à l’agent.",
    "FETCHED_NUMBER_CONTACTS": "{number} contacts récupérés.",
    "FILTER": "Filtrer",
    "FINDER": "Outil de recherche",
    "FOLLOW_UPS_COLLAPSED": "Suivis réduits",
    "FOLLOW_UPS_EXPANDED": "Suivis élargis",
    "FOLLOW_UP_ALERT": "a un suivi dû le {dueDate} créé par {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Méthode de contact",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "La méthode de contact est Restreint.",
    "FOLLOW_UP_DUE": "Suivi dû",
    "FOLLOW_UP_DUE_DATE": "Date d’échéance",
    "FOLLOW_UP_NOTE": "Remarque",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Pas de méthode de contact",
    "FOLLOW_UP_NO_NOTE": "La note est vide",
    "FOLLOW_UP_NO_TICKET": "Aucun billet",
    "FOLLOW_UP_OWNER": "Propriétaire",
    "FOLLOW_UP_STATUS": "État",
    "FOLLOW_UP_STATUS_OPEN": "Ouvrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Résolu",
    "FOLLOW_UP_TICKET": "Bille",
    "FOLLOW_UP_TITLE": "Suivis",
    "FROM": "De",
    "GENERATE_LINK": "Générer un lien",
    "HAS_OVERRIDE_TRY_AGAIN": "Présence de dérogation du filtre. Veuillez réessayer.",
    "HISTORY": "Historique",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "lien de pièce jointe d’images partagé avec le client",
    "INPUT_CHARACTERS_REMAINING": "Maximum {char_max} caractères ({char_remain} restants)",
    "INSERTED": "INSÉRÉ par",
    "INSERTED_BY_AGENT": "INSÉRÉ par {agent}",
    "INTERNAL_ERROR": "Erreur interne",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Une erreur interne est survenue. Si l’erreur persiste, veuillez nous envoyer un ticket expliquant le problème.",
    "INVALID_CONTACT": "Contact non valide.",
    "INVALID_DATA_MATCH": "Le système a fait une classification invalide des données.",
    "INVALID_TIMESTAMP": "Horodatage non valide.",
    "IN_MARKETPLACE_QUEUES": "Dans les files d’attente du site de vente",
    "ISSUE": "Problème",
    "ISSUE_CODE": "Code de traitement post-appel",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Déjà traité et résolu dans un contact précédent",
    "ISSUE_CODE_DESCRIPTION": "Sélectionnez la raison pour laquelle les contacts sélectionnés sont des doublons",
    "ISSUE_CODE_EXACTLY_SAME": "Exactement identique à un contact précédent",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Traité et résolu par un autre clavardage CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Traité et résolu par un autre courriel CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Traité et résolu par un autre téléphone CSA",
    "ITEMS_CONTACTED_ABOUT": "Article(s) faisant l’objet du contact",
    "I_UNDERSTAND": "Je comprends",
    "KNOWN_ISSUES": "Problèmes connus",
    "LEARN_MORE": "En savoir plus",
    "LINK_COPIED": "Lien copié",
    "LOADING": "Chargement des données.",
    "LOADING_PREVIEW_TEXT": "Chargement en cours de l’aperçu",
    "LOADING_RECORDING": "Chargement de l’enregistrement",
    "LOAD_MORE": "Charger plus",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Charger plus d’annotations.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Charger plus de contacts pour ce client.",
    "LOCKED": "Verrouillé",
    "LOCKED_BY": "Verrouillé par",
    "LOCKED_BY_AGENT": "VERROUILLÉ par {agent}",
    "LOCK_CONTACT": "Verrouiller",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Impossible de verrouiller le contact, l’utilisateur n'est pas autorisé",
    "LOCK_CONTACT_SUCCESSFUL": "Contact verrouillé à",
    "MISSING_ORIGINAL_CONTACT_ID": "Veuillez saisir un numéro de contact d’origine valide",
    "MODAL_NO_BUTTON_TEXT": "N°",
    "MODAL_YES_BUTTON_TEXT": "Oui",
    "NEXT": "Suivant",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Aucun problème sélectionné par l’agent.",
    "NO_ANNOTATED_BY": "Annoté par un utilisateur inconnu.",
    "NO_ANNOTATIONS": "Aucune annotation disponible.",
    "NO_ATTACHMENTS": "Il n'y a pas de pièces jointes.",
    "NO_CHAT": "La discussion est vide.",
    "NO_CHILD_WORK_REQUESTS": "Aucune demande de travail créée à partir de ce contact",
    "NO_CONTACT": "Aucun contact disponible",
    "NO_CONTACTS_SELECTED": "Veuillez sélectionner les contacts pour les traiter comme pourriels",
    "NO_CONTENT": "Pas de contenu",
    "NO_CUSTOMER_SELECTED_ISSUE": "Aucun problème sélectionné par le client.",
    "NO_DELIVERY_STATUS": "Statut de non livraison",
    "NO_EMAIL_BODY": "Le champ Courriel est vide.",
    "NO_EMAIL_HEADER": "Pas d’en-tête de courriel.",
    "NO_EMAIL_QUEUE": "Aucune file d’attente de courriel associée à ce contact.",
    "NO_ITEMS_CONTACTED_ABOUT": "Aucun élément contacté.",
    "NO_RESULTS": "Aucun résultat trouvé.",
    "NO_SCREEN_POP": "Aucune information sur la fenêtre contextuelle",
    "NO_SENT_FROM": "Pas envoyé depuis l’adresse.",
    "NO_SENT_TO": "Pas envoyé à l’adresse",
    "NO_SUBJECT": "La ligne d’objet est vide.",
    "NO_SYSKA_ALERTS": "Pas d’alertes SYSKA",
    "NO_USED_BLURBS": "Aucun texte utilisé pour ce contact.",
    "NO_VIEW": "Aucun contact ou client à afficher.",
    "NO_WORK_CATEGORY": "Aucune catégorie de travail associée à ce contact.",
    "NUMBER_OF_CONTACTS": "Contacts chargés",
    "OLDEST_LOADED_CONTACT": "Contact chargé le plus ancien",
    "OPEN": "Ouvrir",
    "OPENS_IN_A_NEW_TAB": "S’ouvre dans un nouvel onglet",
    "ORDER_ID_COPIED": "ID de commande copié.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Saisir l’identifiant de contact d’origine",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Zone de texte pour saisir l’identifiant de contact d’origine",
    "OTHER": "Autre",
    "OUTBOUND_EMAIL_BCC": "et c.c.i. -",
    "OUTBOUND_EMAIL_CC": "pendant qu’on fait une copie conforme -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Contenu de la notification réduit.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Contenu de la notification étendu.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Nombre maximal de résultats atteints. Veuillez réduire l’intervalle de temps et réessayer.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Le processus courant nécessite un contenu PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Correspondance non valide en tant que numéro de téléphone, adresse courriel ou autre PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Correspondance non valide en tant que carte de crédit ou IBAN.",
    "OVERRIDE_REASON_PROMPT": "Les annotations ne sont pas censées contenir des données PII client telles que des numéros de téléphone et des adresses courriel. Notre système automatisé essaie de faire respecter cela, mais parfois il ne fait pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle une dérogation est nécessaire.",
    "PAPYRUS_SESSION_EXPLANATION": "Veuillez spécifier votre type de session Papyrus afin que nous puissions appliquer les bonnes restrictions de sécurité et optimiser l’UX selon vos besoins.",
    "PAPYRUS_SESSION_TYPE": "Type de session Papyrus",
    "PAUSE": "Pause",
    "PHONE_CONTACT": "Contact téléphonique.",
    "PLAY": "Lire",
    "PLAYBACK_SPEED": "Vitesse de lecture",
    "POSTAL_MAIL_TITLE": "Courrier postal au service à la clientèle d’Amazon",
    "PREPARING_RECORDING": "Préparation de l'enregistrement",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Échec de la génération de l’aperçu pour le contact",
    "PREVIOUS": "Précédent",
    "PRE_TRANSLATED_REPLY_EMAIL": "Courriel de réponse pré-traduit",
    "PRE_TRANSLATION_REPLY_TEXT": "Réponse de type CSA avant la traduction de Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Traiter les actions",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Traiter tous les doublons",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Traiter comme pourriel",
    "PROCESS_CONTACT_AS_DUPLICATE": "Résoudre les contacts sélectionnés comme doublons",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Êtes-vous sûr de vouloir traiter les contacts sélectionnés comme pourriels?",
    "PROCESS_DUPLICATE_FAILURE": "Impossible de résoudre les contacts sélectionnés comme doublons",
    "PROCESS_DUPLICATE_SUCCESS": "Contacts sélectionnés comme doublons bien résolus",
    "PROCESS_SPAM_FAILURE": "Impossible de transférer les contacts sélectionnés vers les pourriels",
    "PROCESS_SPAM_SUCCESS": "Transfert réussi des contacts sélectionnés vers les pourriels",
    "PROVIDE_ADDITIONAL_INFORMATION": "Fournir des renseignements supplémentaires pertinents pour ce contact",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Informations sensibles éliminées du contact :",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Ajouter un mot à la liste d’élimination",
    "PURGE_BUTTON_TEXT": "Élimination",
    "PURGE_CONFIRMATION_CHECK": "J’ai vérifié les champs ci-dessus et je confirme que le texte contient en effet des informations sensibles.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Je confirme que les pièces jointes contiennent bel et bien des informations sensibles sur le client.",
    "PURGE_CONFIRMATION_UNCHECKED": "Veuillez confirmer la case à cocher.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Échec de l’élimination du contact",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Élimination des informations du contact réussie",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} n’est pas une raison valable pour l’élimination",
    "PURGE_REASON_FINANCIAL_INFO": "Informations financières",
    "PURGE_REASON_OTHER": "Autres informations sensibles",
    "PURGE_REASON_PII": "Informations permettant d’identifier une personne",
    "PURGE_REASON_PLACEHOLDER": "Saisir la raison de l’élimination",
    "PURGE_REASON_TEXT": "Raison de l’élimination",
    "PURGE_REMOVE_LABEL": "Retirer",
    "PURGE_SELECTOR_PLACEHOLDER": "Sélectionnez la raison de l’élimination des informations sensibles",
    "PURGE_SENSITIVE_INFO": "Éliminer les informations sensibles",
    "PURGE_WORD_NOT_FOUND": "Le mot ne se trouve pas dans le texte du contact",
    "PURPOSE_AUDITING": "Audit pour le contrôle de la qualité",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Flux de travail d’arrière-guichet",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Il s’agit de la session pour obtenir des données de contact pour les flux de travail d’arrière-guichet. Par exemple, les pièces jointes à inclure dans les tickets ou SIM.",
    "PURPOSE_COACHING": "Examen des contacts de coaching",
    "PURPOSE_CURATION": "Conservation des contacts",
    "PURPOSE_CURATION_DESCRIPTION": "Il s’agit de la session pour la conservation des contacts C2CS/COV.",
    "PURPOSE_DESCRIPTION_AUDITING": "Il s’agit de la session d’audit des contacts et d’évaluation de leur qualité.",
    "PURPOSE_DESCRIPTION_COACHING": "Il s’agit de la session d’examen des contacts d’agents dont vous êtes un coach Performance Portal.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Il s’agit de la session de gestion des contacts d’escalade. Cela permet d’accéder aux données de contact de n’importe quel client.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Il s’agit de la session pour la gestion des contacts en direct.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Ceci est dans le but de retirer les informations sensibles d’un contact",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Il s’agit de la session d’examen de la précision du modèle d’apprentissage automatique.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "C’est la session pour passer en revue vos propres contacts passés.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Il s’agit de la session à usage général par défaut.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Traitement d’escalade du contact",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Éliminer les informations sensibles",
    "REASON_FOR_PURGE": "Raison de l’élimination",
    "REASON_FOR_PURGE_DESCRIPTION": "Raison de l’élimination des pièces jointes",
    "REASON_FOR_PURGE_MISSING": "Veuillez saisir la raison de la suppression des pièces jointes.",
    "REASSIGN": "RÉASSIGNÉ par",
    "REASSIGNED_BY_AGENT": "RÉASSIGNÉ par {agent}",
    "REASSIGN_TO_AGENT": "Réattribuer à l’agent",
    "RECORDING": "Enregistrement",
    "RELOADING_CONTENT": "Rechargement du contenu.",
    "RELOAD_LIST": "Recharger la liste",
    "REPLIED": "RÉPONDU par",
    "REPLIED_BY_AGENT": "RÉPONDU par {agent}",
    "REPLY_BY": "Répondre par",
    "REPLY_BY_EMAIL": "Répondre par courriel",
    "REPLY_BY_PHONE": "Répondre par téléphone",
    "REPLY_RESTRICTED": "Le contenu de la réponse est restreint.",
    "REQUEST": "Demander",
    "REQUEST_ACCESS_INSTRUCTIONS": "Veuillez indiquer la raison pour laquelle l’accès au contenu restreint est requis. (Ces demandes sont surveillées et les abus pourraient avoir de graves conséquences.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Demander l’accès au contenu filtré",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Demander l’accès au contenu restreint",
    "REQUEST_NOT_GRANTED": "La demande n’a pas été accordée.",
    "REQUEST_OVERRIDE": "Demander la dérogation",
    "RESEND": "Renvoyer",
    "RESEND_FAILED": "Échec de nouvel envoi du courriel.",
    "RESEND_SUCCESS": "Courriel envoyé à nouveau avec succès.",
    "RESEND_WAIT_WARNING": "Vous devez attendre {wait_mins} minutes pour l’envoyer à nouveau.",
    "RESEND_WARNING": "Voulez-vous vraiment envoyer de nouveau un courriel au client?",
    "RESET": "Réinitialiser",
    "RESOLVED": "Résolu",
    "RESOLVED_BY": "Résolu par",
    "RESOLVED_BY_AGENT": "RÉSOLU par {agent}",
    "RESTRICTED_DATA_MATCH": "{match} type correspondant {type}",
    "RESTRICTED_INFORMATION": "INFORMATION À DISTRIBUTION RESTREINTE",
    "RETRY": "Réessayer",
    "ROLE": "Rôle",
    "SCREEN_POP_DESCRIPTION_TITLE": "Description",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Non",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Oui",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Affiché à l’agent",
    "SCREEN_POP_TITLE": "Informations sur la fenêtre contextuelle",
    "SCREEN_POP_TYPE_AUTHENTICATING": "La fenêtre contextuelle d’authentification a été affichée à l’agent, et elle a été utilisée.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "L’authentification de la fenêtre contextuelle a été affichée à l’agent.",
    "SCREEN_POP_TYPE_CALLER_ID": "La fenêtre contextuelle d’identification de l’appelant a été affichée à l’agent, et elle a été utilisée.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "L’identification de l’appelant a été affichée à l’agent.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "La fenêtre contextuelle non authentifiée a été affichée, et elle a été utilisée.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "La fenêtre contextuelle non authentifiée a été affichée à l’agent.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Le client est inconnu; aucune fenêtre contextuelle fournie.",
    "SCREEN_POP_TYPE_OTHER": "Autre",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Non",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Oui",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Utilisé par l’agent",
    "SDS_TOOLS": "Outil(s) de soutien pour l’expédition et la livraison",
    "SEARCH": "Rechercher",
    "SELECTED_ROLE": "Rôle sélectionné",
    "SELECTED_VIEW": "Vue sélectionnée",
    "SELECT_CHANNELS": "Sélectionner les canaux",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Pour sélectionner le code de traitement post-appel pour marquer les contacts sélectionnés comme doublons",
    "SELECT_TRANSFER_DESTINATION": "Sélectionnez la destination de transfert",
    "SELECT_WORK_REQUEST_QUEUE": "Sélectionner la file d’attente des demandes",
    "SENDER_EMAIL_ADDRESS": "Expéditeur",
    "SENDING_EMAIL": "Envoi du courriel",
    "SEND_EMAIL": "Envoyer un courriel",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Êtes-vous sûr de vouloir envoyer le lien de téléversement d’images au client?",
    "SEND_OUTBOUND_EMAIL": "Envoyer des courriels sortants",
    "SEND_OUTBOUND_EMAIL_WARNING": "Êtes-vous certain de vouloir envoyer des courriels sortants à",
    "SENT_FROM": "Envoyé à partir de",
    "SENT_OUTBOUND_EMAIL": "Un courriel sortant a été envoyé. Un nouveau contact a été créé",
    "SENT_TO": "Envoyé à",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portail du transporteur",
    "SKILL": "Skill",
    "SKIP_SILENCES": "Sauter les silences",
    "SMS": "SMS",
    "SMS_LINK": "Lien SMS",
    "SOURCE_TEXT": "Texte source",
    "SP_BOTTOM": "Dessous",
    "SP_POSITION": "Position du panneau divisé",
    "SP_PREFERENCES": "Préférences du panneau divisé",
    "SP_RESIZE": "Redimensionner le panneau divisé",
    "SP_SIDE": "Côté",
    "START": "Commencer",
    "STATE_TRANSITIONS": "Transitions d’état",
    "STATUS": "État",
    "SUBJECT": "Objet",
    "SYSKA_ALERTS": "Alertes SYSKA",
    "TEXT_TO_PURGE": "Texte à éliminer",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Les témoins tiers doivent être activés.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Les données PII des clients telles que les numéros de téléphone et les adresses courriel ne sont pas autorisées lors de la création d’un ticket. Notre système automatisé essaie de faire respecter cela, mais parfois il ne fait pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Délai d’attente : impossible de charger l'enregistrement",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Utilisez le format 24 heures.",
    "TO": "À",
    "TO_EMAIL_ADDRESS": "À",
    "TRANSFER": "Transférer",
    "TRANSFERRED": "TRANSFÉRÉ par",
    "TRANSFERRED_BY_AGENT": "TRANSFÉRÉ par {agent}",
    "TRANSFERRED_CHAT_TITLE": "Clavardage transféré au Service à la clientèle d’Amazon",
    "TRANSFERRING": "Transfert...",
    "TRANSFER_CONTACT": "Transférez le contact",
    "TRANSFER_CONTACT_COMMENTS": "Annotation facultative",
    "TRANSFER_FAILED": "Échec du transfert",
    "TRANSFER_FOLLOW_UP": "Suivi du transfert",
    "TRANSFER_FOLLOW_UP_FAILED": "Impossible de transférer le suivi.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Impossible de transférer le suivi en raison d’une nouvelle connexion du propriétaire non valide.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Vous n’êtes pas autorisé à transférer ce suivi.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Saisissez le nom de connexion du nouveau propriétaire. Exemple : {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transférer le suivi ci-dessus à :",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Suivi transféré avec succès.",
    "TRANSLATED_TEXT": "Texte traduit",
    "TRANSLATION": "Traduction",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} n’est pas un nom de file d’attente valide.",
    "TYPE_AGENT_LOGIN": "Saisir la connexion de l’agent",
    "TYPE_EMAIL_ADDRESS": "Saisissez votre adresse de courriel",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Tapez plusieurs adresses de courriel séparées par une virgule",
    "TYPE_QUEUE_NAME": "Entrez le nom de la file d’attente",
    "UNKNOWN_CONTACT_TYPE": "Type de contact inconnu.",
    "UNLOCKED": "Déverrouillé",
    "UNRESOLVED": "Non résolu",
    "URGENT_SYSKA_ALERTS": "Alertes SYSKA urgentes",
    "USE": "Utiliser",
    "USER_TIMEZONE": "Horodatages affichés dans votre fuseau horaire local",
    "USE_CONTACT_FINDER": "Utiliser l’outil de recherche de contacts",
    "VIEW": "Afficher",
    "WARNING": "Avertissement",
    "WELCOME_MESSAGE": "Bienvenue sur le site Web de Papyrus Static!",
    "WHAT_IS_PAPYRUS": "Qu’est-ce que Papyrus?",
    "WITH_THE_ANNOTATION": "avec l’annotation",
    "WORK_CATEGORY_TITLE": "Catégorie de travail",
    "WORK_REQUEST": "Demande de travail",
    "WORK_REQUEST_CONTENT": "Contenu de la demande de travail",
    "WORK_REQUEST_CREATED": "Demande de travail créée",
    "WORK_REQUEST_FOR_CONTACT": "Demande de travail pour le contact",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Les données PII des clients telles que les numéros de téléphone et les adresses courriel ne sont pas autorisées lors de la création d’une demande de travail. Notre système automatisé essaie de faire respecter cela, mais parfois il ne fait pas la différence entre les PII et les autres identifiants. Indiquez la raison pour laquelle un remplacement est nécessaire.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Vous êtes sur le point de transférer vers la file d’attente"
}