/* eslint-disable @typescript-eslint/no-explicit-any */
// Re-implementation of Phoenix GraphQL API with better error/re-auth support
import PhoenixAPI, {ClientConfig} from '@amzn/csphoenix-react-client';

const clientConfig: Record<string, ClientConfig> = {
    'local': {
        tokenCookieName: "pgw-local.token",
        redirectEndpoint: "https://local.pgw.cs.a2z.com:3000/",
        clientStateCookieName: "papyrus-local",
        clientId: "pgw-local"
    },
    'alpha': {
        tokenCookieName: "pgw-test.token",
        redirectEndpoint: "https://alpha.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-test",
        clientId: "pgw-test"
    },
    'beta': {
        tokenCookieName: "pgw-test.token",
        redirectEndpoint: "https://alpha.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-test",
        clientId: "pgw-test"
    },
    'gamma-na': {
        tokenCookieName: "pgw-prod.token",
        redirectEndpoint: "https://gamma.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod"
    },
    'gamma-eu': {
        tokenCookieName: "pgw-prod-eu.token",
        redirectEndpoint: "https://gamma.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-eu"
    },
    'gamma-fe': {
        tokenCookieName: "pgw-prod-fe.token",
        redirectEndpoint: "https://gamma.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-fe"
    },
    'gamma-cn': {
        tokenCookieName: "pgw-prod-cn.token",
        redirectEndpoint: "https://gamma.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-cn"
    },
    'prod-na': {
        tokenCookieName: "pgw-prod.token",
        redirectEndpoint: "https://prod.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod"
    },
    'prod-eu': {
        tokenCookieName: "pgw-prod-eu.token",
        redirectEndpoint: "https://prod.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-eu"
    },
    'prod-fe': {
        tokenCookieName: "pgw-prod-fe.token",
        redirectEndpoint: "https://prod.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-fe"
    },
    'prod-cn': {
        tokenCookieName: "pgw-prod-cn.token",
        redirectEndpoint: "https://prod.papyrus.pgw.cs.a2z.com/",
        clientStateCookieName: "papyrus-prod",
        clientId: "pgw-prod-cn"
    },
};

export default class Phoenix {
    private static sharedPhoenix: PhoenixAPI;

    public static getInstance(): PhoenixAPI {
        return Phoenix.sharedPhoenix;
    }

    public static init(pgwQualifier: string, locale: string): void {
        Phoenix.sharedPhoenix = new PhoenixAPI(pgwQualifier, locale, clientConfig);
    }
}
