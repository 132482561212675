export const pt_BR = {
    "contact_event_GC_added": "GC #{gcId} adicionado à conta.",
    "contact_event_GC_added_batched": "GC adicionado à conta ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} adicionado à conta do comprador.",
    "contact_event_GC_added_purchaser_batched": "GC adicionado à conta do comprador ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} adicionado à conta do destinatário.",
    "contact_event_GC_added_recipient_batched": "GC adicionado à conta do destinatário  ({count}).",
    "contact_event_GC_added_scratchedCode": "GC nº {gcId} adicionado à conta por meio do código de solicitação riscado.",
    "contact_event_GC_added_scratchedCode_batched": "GC adicionado à conta por meio da ferramenta de código de solicitação riscado ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} cancelado.",
    "contact_event_GC_cancelled_batched": "GC cancelado ({count}).",
    "contact_event_GC_changed_expiry": "Data de validade alterada para o GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Data de validade alterada para o GC ({count}).",
    "contact_event_GC_locked": "GC #{gcId} bloqueado.",
    "contact_event_GC_locked_batched": "GC bloqueado ({count}).",
    "contact_event_GC_removed": "GC #{gcId} removido da conta.",
    "contact_event_GC_removed_batched": "GC removido da conta ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} reenviado ao Id de e-mail  {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC ${} reenviado ao Id de e-mail ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} reenviado por correio.",
    "contact_event_GC_resent_post_batched": "GC reenviado por correio ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} temporariamente bloqueado.",
    "contact_event_GC_temporary_locked_batched": "GC temporariamente bloqueado ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transferido do cliente  {customerID} para {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC transferido ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} desbloqueado.",
    "contact_event_GC_unlocked_batched": "GC desbloqueado ({count}).",
    "contact_event_PV_added": "PV #{pvId} adicionado à conta.",
    "contact_event_PV_locked": "PV # {pvId} bloqueado.",
    "contact_event_PV_temporary_locked": "PV # {pvId} temporariamente bloqueado.",
    "contact_event_PV_unlocked": "PV # {pvId} desbloqueado.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Reclamações de A a Z canceladas para os pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Solicitação de A a Z enviada para o pedido {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Reclamações de A a Z enviadas para os pedidos ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Solicitação da Garantia de A a Z enviada para o pedido {order_id}.",
    "contact_event_access_code_added": "Código de acesso adicionado",
    "contact_event_account_closed": "Conta encerrada.",
    "contact_event_account_closed_batched": "Contas encerradas ({count}).",
    "contact_event_account_reopened": "Conta reaberta",
    "contact_event_account_reopened_batched": "Contas reabertas ({count})",
    "contact_event_account_temporarily_disabled": "Conta desativado temporariamente.",
    "contact_event_account_temporarily_disabled_batched": "Contas desativadas temporariamente ({count})",
    "contact_event_account_whitelisted": "Conta incluída na lista de permissões.",
    "contact_event_address_changed": "Endereço {address_type} atualizado para o pedido {order_id}.",
    "contact_event_address_changed_batched": "Endereços atualizados para os pedidos ({count}).",
    "contact_event_address_deactivated": "Endereço desativado.",
    "contact_event_address_deactivated_batched": "Endereço desativado ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Assinatura do Amazon Video renovada automaticamente",
    "contact_event_amazon_video_subscription_cancelled": "Assinatura do Amazon Video cancelada",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulário enviado: formulário de encaminhamento de DSL\nID da sessão de caso de uso: {usecaseSessionID}\nEstação de entrega: {deliveryStationField}\nTipo de solicitação: {requestTypeField}\nID do contato: {contactIdField}\nID do pedido: {orderIdField}\nEndereço de e-mail do cliente: {customerEmailField}\nNúmero de telefone do cliente: {customerPhoneNumberField}\nTBA(s): {tbasField}\nCódigo de acesso (se aplicável): {accessCodeField}\nResumo do problema: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formulários de encaminhamento de DSL enviados: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulário enviado: formulário de feedback de DA de DSP\nID da sessão de caso de uso: {usecaseSessionID}\nCódigo da estação de entrega: {deliveryStationField}\nID do contato: {contactIdField}\nID da conta do cliente: {customerIdField}\nID da transportadora de DA: {driverIdField}\nData do incidente (AAAA-MM-DD): {dateOfOccurrenceField}\nTempo do incidente (HH:MM): {timeofOccurrenceField}\nTBAs: {relatedTrackingIdsField}\nFonte de feedback: {sourceOfFeedbackField}\nDetalhes do feedback: {issueField}\nDetalhes adicionais: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formulários de feedback de DA de DSP enviados: ({count})",
    "contact_event_andon_cord_pulled": "Andon acionado para {asin}.",
    "contact_event_andon_cord_pulled_batched": "Corda puxada para os itens ({count}).",
    "contact_event_apply_adrive_music_trial": "foi aplicado o Cloud Player Premium para o cliente {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_cash_and_coupon_refund": "{title} foi reembolsado em {cashAmount}. Além disso, essa transação restaurou um  cupom de {couponAmount} de volta para a conta.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} foi reembolsado em {cashAmount}. Além disso, essa transação restaurou um cupom de {couponAmount} de volta para a conta.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} foi reembolsado em {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} foi reembolsado em {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} foi reembolsado e restaurou um cupom de {couponAmount} de volta para a conta.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} foi reembolsado e restaurou um cupom de {couponAmount} de volta para a conta.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} créditos foram removidos da conta.",
    "contact_event_audible_credit_refund": "{title} foi reembolsado e recuperou {creditAmount} crédito(s) para a conta.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} foi reembolsado e recuperou {creditAmount} crédito(s) para a conta.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} créditos foram restaurados para a conta.",
    "contact_event_audible_credit_restored_batched": "{amount} créditos foram restaurados para a conta. ({count})",
    "contact_event_audible_extra_credit_purchased": "3 créditos extras foram comprados.",
    "contact_event_audible_extra_credit_purchased_batched": "3 créditos extras foram comprados.({count})",
    "contact_event_audible_hiatus_cancelled": "O período de hiato foi concluído antecipadamente, e a assinatura foi restaurada.",
    "contact_event_audible_hiatus_cancelled_batched": "O período de hiato foi concluído antecipadamente, e a assinatura foi restaurada. ({count})",
    "contact_event_audible_hiatus_started": "A associação foi feita em um hato de {duration} meses.",
    "contact_event_audible_hiatus_started_batched": "A assinatura foi feita em um hiato de {duration} meses. ({count})",
    "contact_event_audible_membership_plan_refund": "A taxa de inscrição no valor de {cashAmount} foi reembolsada.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "A taxa de inscrição no valor de {cashAmount} foi reembolsada.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Cadastrado no {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Cadastrado no {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "{wizardName} foi acessado.",
    "contact_event_audible_wizard_invoked_batched": "{wizardName} foi acessado. ({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "O plano {membership} foi cancelado com êxito.",
    "contact_event_blacklist_kindle": "Dispositivo na lista de bloqueio {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Kindles restritos ({count}).",
    "contact_event_bound_warranty": "ID de garantia {warranty_id} vinculado ao dispositivo {dsn}.",
    "contact_event_bound_warranty_batched": "Garantias concedidas aos dispositivos ({count}).",
    "contact_event_call_was_flagged": "Este contato foi marcado como {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Este contato foi marcado como {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "O plano {membership} foi cancelado com êxito.",
    "contact_event_cancel_audible_membership_batched": "O plano {membership} foi cancelado com êxito. ({count})",
    "contact_event_cancel_audible_romance_package_batched": "O plano {membership} foi cancelado com êxito. ({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Prime cancelado com sucesso da Exceção de Cancelamento Prime. Motivo do cancelamento: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Exceção de Cancelamento para um Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "A Data de cancelamento futura definida anteriormente foi cancelada.",
    "contact_event_cancelled_sns_reactivated": "ASIN {asin} para assinatura reativado em  {reactivation_date } por {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Assinaturas canceladas do Programe e Poupe reativadas ({count}).",
    "contact_event_captcha_bypass_key_generated": "Chave de desvio de Captcha gerada.",
    "contact_event_captcha_bypass_key_generated_batched": "Chave de desvio de Captcha gerada ({count}).",
    "contact_event_change_sns_quantity": "A quantidade de ASIN {asin} na assinatura do Programe e Poupe foi atualizada de {current_quantity}  para a nova quantidade de {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Quantidades do Programe e Poupe atualizadas ({count}).",
    "contact_event_channels_auto_renew_off": "CS desativou a renovação automática da assinatura dos canais PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS desativou a renovação automática via Astro.",
    "contact_event_channels_full_refund": "CS cancelou a assinatura dos canais PV {subscriptionid} com reembolso total.",
    "contact_event_channels_full_refund_static": "CS cancelou a assinatura com reembolso total via Astro.",
    "contact_event_channels_no_refund": "CS cancelou a assinatura dos canais PV {subscriptionid} sem reembolso.",
    "contact_event_channels_no_refund_static": "CS cancelou a assinatura sem reembolso via Astro.",
    "contact_event_channels_workflow_launch": "CS iniciou o fluxo de trabalho de cancelamento Astro da assinatura de canais PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS iniciou o fluxo de trabalho de cancelamento Astro.",
    "contact_event_coins_adjust": "Tipo de ajuste: {type} <br />\nMotivo: {reason} <br />\nValor: {amount} <br />\nComentários: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo de moedas ajustado ({count}).",
    "contact_event_concessions_abuse": "O HMD não foi enviado porque o cliente é um usuário {risk_status}.",
    "contact_event_contact_seller": "Vendedor contactado para o pedido {order_id}.",
    "contact_event_contact_seller_batched": "Vendedor contatado para os pedidos ({count}).",
    "contact_event_contact_was_initiated_from": "O contato foi iniciado pelo ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "Conteúdo do ASIN {asin} atualizado na biblioteca do Kindle.",
    "contact_event_content_update_batched": "Conteúdo atualizado na biblioteca do Kindle ({count}).",
    "contact_event_credit_card_disabled": "CC desativada.",
    "contact_event_credit_card_disabled_batched": "Cartões de crédito desativados ({count}).",
    "contact_event_csc_sds_override_geo_fence_submit": "Substituir solicitação de cerca geográfica enviada para ID de rastreamento {trackingID} Motivo: {overridReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Substituir solicitação de cerca geográfica enviada para ID de rastreamento {trackingId} Motivo: {overrideReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocode atualizado. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocode atualizado. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "autenticação do cliente aprovada",
    "contact_event_customer_authentication_passed_batched": "autenticação do cliente aprovada ({count})",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Nome do cliente atualizado.",
    "contact_event_customer_name_updated_batched": "Nome do cliente atualizado ({count}).",
    "contact_event_customer_password_changed": "A senha do cliente foi alterada.",
    "contact_event_customer_password_changed_batched": "Senha do cliente alterada ({count}).",
    "contact_event_delete_alexa_voice_profile": "Perfil de voz Alexa excluído para speakerId : {speakerId} ,  customerID : {customerID} e status: {status}",
    "contact_event_delete_data_alexa_comms": "Perfil Alexa Comms excluído para commsID : {commsId} ,  customerID : {customerID} e status: {status}",
    "contact_event_deprovision_alexa_comms": "Perfil de comunicações Alexa cancelado para commsID: {commsId} ,  customerID: {customerID} e o status: {status}",
    "contact_event_deregister_kindle": "Kindle com registro cancelado {serial_number}.",
    "contact_event_deregister_kindle_batched": "Kindles cancelados ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Assistente de solução de problemas chamado para o dispositivo com DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "Houve falha na desativação da função Mayday do  DSN {dsn} do dispositivo.",
    "contact_event_disable_mayday_success": "A função Myday para o DSN {dsn} do dispositivo foi desativada com êxito.",
    "contact_event_dunning_pause": "Processo de cobrança pausado - Consulte o histórico de pagamentos e transações para obter mais detalhes",
    "contact_event_dvbypost_extra_dispatch": "{amount} despacho(s) extra(s) solicitado(s) para este cliente.",
    "contact_event_dvbypost_extra_envelope": "Um envelope extra foi acionado para este cliente.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 form angefordert",
    "contact_event_dvbypost_problem_code_change": "Definindo o disco {disc_id} para {problem_code}",
    "contact_event_email_address_updated": "Endereço de e-mail atualizado.",
    "contact_event_email_address_updated_batched": "Endereço de e-mail atualizado ({count}).",
    "contact_event_email_preferences_changed": "Preferências de e-mail alteradas.",
    "contact_event_email_unsubscribe": "Assinatura de e-mail cancelada.",
    "contact_event_email_unsubscribe_batched": "E-mail cancelado ({count}).",
    "contact_event_enable_mayday_failed": "Falha na reativação da função Mayday do  DSN {dsn} do dispositivo.",
    "contact_event_enable_mayday_success": "A função Mayday do  DSN {dsn} do dispositivo foi reativada com êxito.",
    "contact_event_first_time_user_experience_set": "Definir primeira utilização do usuário",
    "contact_event_first_time_user_experience_set_batched": "Definir primeira utilização de usuário ({count}).",
    "contact_event_form_submitted": "Formulário {form_name} enviado.",
    "contact_event_form_submitted_batched": "Formulários enviados ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Assinatura ilimitada Free Time cancelada do cliente: {customerId}, DSN: {deviceSerialNumber} por {agentId} pelo motivo: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Assinatura ilimitada Free Time cancelada ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Teste prime gratuito cancelado com sucesso por {cancel_reason].",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime cancelado com sucesso ({count}).",
    "contact_event_future_cancel_audible_membership": "O plano {membership} foi definido para Cancelamento futuro em {date}.",
    "contact_event_future_cancel_audible_membership_batched": "O plano {membership} foi definido para Cancelamento futuro em {date}. ({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} foi permanentemente removidos de sua Biblioteca de vídeos.",
    "contact_event_header": "Registro de eventos",
    "contact_event_hmd_suppression": "O HMD não foi enviado porque: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Bate-papo inativo desconectado",
    "contact_event_idle_chat_disconnect_batched": "Chat inativo desconectado ({count})",
    "contact_event_item_price_reduced": "O preço de {asin} foi alterado de {old_price} para {new_price}.",
    "contact_event_item_price_reduced_batched": "Preços de itens alterados nos pedidos ({count}).",
    "contact_event_item_quantity_changed": "Quantidade de {asin} alterada no pedido {order_id} de {old_quantity} para {new_quantity}.",
    "contact_event_item_quantity_changed_batched": "Quantidades de itens alteradas para os pedidos ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Substituição criada para DSN {dsn}  do pedido {old_order_id} . Novo pedido: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Dispositivos Kindle substituídos ({count}).",
    "contact_event_kindle_email_address_edits": "Endereço de e-mail atualizado no Kindle (DSN # {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Endereços de e-mail atualizados para os Kindles ({count}).",
    "contact_event_kindle_email_edits": "{DSN} enviado para o endereço de e-mail foi alterado de {original_email} para {new_email}",
    "contact_event_kindle_name_changed": "O nome do Kindle com DSN {DSN} foi alterado de {original_name} para {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nome do Kindle alterado ({count}).",
    "contact_event_last_page_read_deleted": "Última página lida do ASIN {asin} excluída.",
    "contact_event_last_page_read_deleted_batched": "Última página lida excluída ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing iniciado para o DSN: {dsn}, kcsID: {kcsId}, resposta do cliente: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing iniciado para dispositivos ({count}).",
    "contact_event_lock_dash_order": "Os seguintes ASINs do dispositivo {dsn}  foram desbloqueados: {asins}.",
    "contact_event_music_auto_renew_off": "CS DESATIVOU a renovação automática para assinatura do Music {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS DESATIVOU a renovação automática para assinatura do Music via Astro.",
    "contact_event_music_full_refund": "CS cancelou a assinatura do Music {subscriptionid} com reembolso total",
    "contact_event_music_full_refund_static": "CS cancelou a assinatura do Music com reembolso total via Astro.",
    "contact_event_music_no_refund": "Assinatura do Music cancelada {subscriptionid} pelo CS sem reembolso.",
    "contact_event_music_no_refund_static": "CS cancelou a assinatura do Music sem reembolso via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "A CS desativou a renovação automática para a assinatura do Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "A CS cancelou a assinatura do Amazon Music Unlimited {subscriptionid} com reembolso total.",
    "contact_event_music_unlimited_no_refund": "A CS cancelou a assinatura do Amazon Music Unlimited {subscriptionid} sem reembolso.",
    "contact_event_music_unlimited_partial_refund": "A CS cancelou a assinatura do Amazon Music Unlimited {subscriptionid} com reembolso parcial.",
    "contact_event_music_unlimited_workflow_launch": "A CS lançou o fluxo de trabalho de cancelamento do Astro para assinatura do Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS lançou o fluxo de trabalho de cancelamento do Astro para assinatura do Music {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS lançou o fluxo de trabalho de cancelamento do Astro para o Music Unlimited.",
    "contact_event_non_dsv_refund_created": "O pedido digital {order_id} foi reembolsado {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Pedidos digitais reembolsados ({count}).",
    "contact_event_one_click_address_updated": "Endereço de um clique atualizado.",
    "contact_event_one_click_address_updated_batched": "Endereço de 1 clique atualizado ({count}).",
    "contact_event_one_click_disabled": "Desativado por um clique.",
    "contact_event_one_click_disabled_batched": "1 clique desativado ({count}).",
    "contact_event_one_click_enabled": "Ativado por um clique.",
    "contact_event_one_click_payment_updated": "Pagamento com 1-Clique atualizado.",
    "contact_event_one_click_payment_updated_batched": "Pagamento com 1-Clique atualizado ({count}).",
    "contact_event_order_cancelled": "Itens cancelados no pedido {order_id}.",
    "contact_event_order_cancelled_batched": "Pedidos cancelados ({count}).",
    "contact_event_order_placed": "Pedido realizado {order_id}.",
    "contact_event_order_placed_batched": "Pedidos realizados ({count}).",
    "contact_event_payment_method_added": "Método de pagamento {payment_type} adicionado à conta.",
    "contact_event_payment_method_added_batched": "Métodos de pagamento adicionados à conta ({count}).",
    "contact_event_payment_method_changed": "Método de pagamento alterado para o pedido {order_id}.",
    "contact_event_payment_method_changed_batched": "Método de pagamento alterado para os pedidos ({count}).",
    "contact_event_pdocs_max_price_edits": "Custo máximo de conversão de configurações de documentos pessoais alterado para {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Cobrança de conversão máxima de configurações de documentos pessoais atualizada ({count}).",
    "contact_event_pennywise_promo_applied": "Promoção Pennywise aplicada para DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promoção Pennywise aplicada para DSN: ({count})",
    "contact_event_ping_device": "Dispositivo com DSN {dsn} com ping efetuado.",
    "contact_event_ping_device_batched": "Dispositivos com ping ({count}).",
    "contact_event_prime_auto_renew_settings": "Autorrenovação do Prime definida para {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Autorrenovação do Prime definida para {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Prime cancelado com sucesso da Exceção de Cancelamento Prime. Motivo do cancelamento: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime cancelado.",
    "contact_event_prime_cancelled_batched": "Prime cancelado ({count}).",
    "contact_event_prime_concession": "Concessão do Prime emitida ao pedido {order_id}.",
    "contact_event_prime_concession_batched": "Concessões Prime emitidas para os pedidos ({count}).",
    "contact_event_prime_refund": "O Prime reembolsou {amount}.",
    "contact_event_prime_refund_batched": "Prime reembolsado ({count}).",
    "contact_event_promo_added_to_account": "Desconto de {amount} incluído no valor.",
    "contact_event_promo_added_to_account_batched": "Promoções adicionadas à conta ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} promoção não resgatada.",
    "contact_event_promo_unredeemed_batched": "Promoções não resgatadas ({count}).",
    "contact_event_refund_cancelled": "Reembolso cancelado para o pedido {order_id}.",
    "contact_event_refund_created": "Reembolsou {refund_amount} para o pedido {order_id}.",
    "contact_event_refund_created_batched": "Pedidos reembolsados ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} reembolsados da Exceção de Cancelamento Prime. Motivo de reembolso: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Reembolsar Exceção de Cancelamento Prime ({count}).",
    "contact_event_register_kindle": "Kindle registrado {serial_number}.",
    "contact_event_register_kindle_batched": "Kindles registrados ({count}).",
    "contact_event_registry_deleted": "Registro excluído  (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Registros excluídos ({count}).",
    "contact_event_release_aiv_license": "A licença de AIV {asin} foi liberada no dispotivo DSN {dsn}.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} número de itens movidos do dispositivo com DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Itens removidos dos dispositivos ({count}).",
    "contact_event_remove_from_yvl": "O ASIN {asin} foi removido de sua Biblioteca de vídeos para os Itens excluídos.",
    "contact_event_remove_kindle_from_blacklist": "Kindle para DSN {dsn} removido do status perdido ou roubado, motivo: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Dispositivos removidos do status perdido ou roubado ({count}).",
    "contact_event_replacement_created": "Substituição criada para o pedido {old_order_id}. Novo pedido: {new_order_id}.",
    "contact_event_replacement_created_batched": "Substituições criadas para os pedidos ({count}).",
    "contact_event_report_seller": "Vendedor contactado para o pedido {order_id}.",
    "contact_event_report_seller_batched": "Vendedor contatado para os pedidos ({count}).",
    "contact_event_request_invoice": "Solicitação de fatura enviada ao vendedor referente ao pedido {order_id}.",
    "contact_event_request_invoice_batched": "Solicitação de fatura enviada referente aos pedidos ({count}).",
    "contact_event_resend_kindle_content": "Conteúdo digital reenviado {title} para o DSN {dsn} com o tipo de dispositivo {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Reenviar conteúdo digital ({count}).",
    "contact_event_resent_gifted_content": "O ASIN {asin} do e-mail do destinatário do ID do pedido {order_id} foi alterado.",
    "contact_event_resent_gifted_content_batched": "E-mail destinatário para o pedido de presente atualizado ({count}).",
    "contact_event_reset_password_email_sent": "E-mail com redefinição de senha enviado.",
    "contact_event_reset_password_email_sent_batched": "E-mails com redefinição de senha enviados ({count}).",
    "contact_event_residence_address_changed": "Endereço de residência alterado.",
    "contact_event_residence_address_changed_batched": "Endereço de residência atualizado ({count}).",
    "contact_event_rma_cancelled": "RMA cancelado para o pedido {order_id}.",
    "contact_event_rma_created": "RMA criado para o pedido {order_id}.",
    "contact_event_rma_created_batched": "RMAs criados para os pedidos ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nome do DP: {driverName}, \nnome da estação: {stationName}, \nnúmero de rastreamento: {trackingId} e \nID do pedido: {orderId}\nID de UsecaseSession: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget de mapa aberto para número de rastreamento {trackingId}",
    "contact_event_sds_package_repick": "O associado iniciou a recolha para ID de rastreamento {trackingId}",
    "contact_event_send_aiv_asin": "O ASIN {asin} foi enviado para sua Biblioteca de vídeo de cliente {customer_id} .",
    "contact_event_ship_charge_reduce": "A cobrança de envio do pedido {order_id} foi alterada de {old_amount} para {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Cobranças de envio alteradas ({count}).",
    "contact_event_ship_method_upgraded": "O método de envio para o pedido {order_id} foi atualizado.",
    "contact_event_ship_method_upgraded_batched": "Métodos de envio alterados para os pedidos ({count}).",
    "contact_event_shipping_address_changed": "Endereço alterado no pedido {new_order_id}.",
    "contact_event_sim_ticket_created": "Tíquete SIM criado",
    "contact_event_special_offers_enrollment": "Ofertas especiais {enrollment_status} do Kindle com DSN {dsn}.",
    "contact_event_special_offers_enrollment_batched": "Atualizar o status de incrição em ofertas especiais ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Assinatura e salvamento cancelados para o pedido {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "Programe e Poupe cancelado para as assinaturas ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "A programação de envio da assinatura alterada e salvamento foi alterada para o pedido {subscription_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Assinatura alterada e programações de salvar envio para assinaturas ({count}).",
    "contact_event_subscription_astro_workflow_launch": "O CS lançou o fluxo de trabalho de cancelamento Astro da assinatura {subscriptionType}, {subscriptionId}.",
    "contact_event_subscription_auto_renew": "{plan_type} renovação automática definida para {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Configurações de autorrenovação Prime atualizadas ({count}).",
    "contact_event_subscription_auto_renew_off": "O CS desativou a renovação automática da assinatura {subscriptionType}, {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "O CS cancelou a assinatura {subscriptionType}, {subscriptionId}, com reembolso total.",
    "contact_event_subscription_cancel_no_refund": "O CS cancelou a assinatura {subscriptionType}, {subscriptionId}, sem reembolso.",
    "contact_event_subscription_cancel_partial_refund": "O CS cancelou a assinatura {subscriptionType}, {subscriptionId}, com reembolso parcial.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "Assinatura do tipo {plan_type} definida para renovação de {old_plan_name} para {new_plan_name} no finaldo período atual.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Configurações do plano de transferência do Prime atualizadas ({count}).",
    "contact_event_troubleshooting_wizard": "Assistente de solução de problemas chamado para o dispositivo com DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "O assistente de de solução de problemas foi chamado para os dispositivos ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} para dispositivo com DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "O assistente de de solução de problemas foi chamado para os dispositivos ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "O assistente de solução de problemas foi acionado para o pedido digital com o ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "O status de compra internacional mudou de bloqueado para {new_status}",
    "contact_event_unblock_international_purchase_batched": "Status internacional de compras internacioinais ({count}).",
    "contact_event_unlock_dash_order": "Os seguintes ASINs para o dispositivo {dsn}  foram desbloqueados: {asins}.",
    "contact_event_update_locking_status": "Status de bloqueio do dispositivo Oft {dsn} atualizado para: {newLockingStatus}",
    "contact_event_verify_email": "E-mail de verificação enviado ao cliente.",
    "contact_event_weekend_delivery_preference_changed": "Preferência de entrega em fins de semana atualizada"
}