import React, {useContext, useState} from "react";
import { InternalError, IssueManagerContext, MissingFieldsError } from "../issueManager/IssueManager";
import {ContactAttachment} from "./Contact.types";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {CallResponse, LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import {getAttachmentFromTAW} from "../api/Texas";
import Phoenix from "../api/Phoenix";

interface AttachmentDisplayProps {
    attachment: ContactAttachment;
    marketplaceId: string;
}

const AttachmentDisplay: React.FC<AttachmentDisplayProps> = ({attachment,  marketplaceId}) => {

    const { callPhoenix } = useContext(IssueManagerContext);
    const [error, setError] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [linkFetched, setLinkFetched] = useState<boolean>(false);

    const showComponent = (): void => {
        setLinkFetched(true);
    };

    const clearComponent = (): void => {
        setLinkFetched(false);
    };

    const getComponentState = (): boolean => {
        return linkFetched;
    };

    function buildContactAttachmentLinkQuery(attachmentId: string): string {
        return `query { ` +
            `   contactAttachmentLink ( contactAttachmentId: "${attachmentId}") { ` +
            `       downloadLink` +
            `   }` +
            `}`;
    }

    function buildContactMediumAttachmentLinkQuery(attachmentId: string): string {
        return `query { ` +
            `   contactMediumAttachmentLink ( attachmentId: "${attachmentId}") { ` +
            `       attachmentLinkEncrypted` +
            `   }` +
            `}`;
    }

    function fetchAttachment(attachmentId: string, fileName: string): void {
        // V2 attachments from CSIAS are formatted amzn1.cs.attachment.v2-UUID and need to be fetched through CSALT
        // V1 attachments from TC2CS are formatted amzn1.cs.attachment.UUID and need to be fetched from TAW - https://tiny.amazon.com/478thnu6/codeamazpackblobddbcsrcamaz
        // CSMediaArchival attachments will have a different format but can be fetched through CSALT - https://tiny.amazon.com/11g22agl4
        if (attachmentId.startsWith("amzn1.cs.attachment.v2-")) {
            fetchContactAttachmentLink(attachmentId);
            Phoenix.getInstance().addMetric("AttachmentFetchV2.Invocation", 1, MetricType.COUNT);
        } else if (attachmentId.startsWith("amzn1.cs.attachment")) {
            getAttachmentFromTAW(attachmentId, fileName, processAttachmentFromCSCResult);
            Phoenix.getInstance().addMetric("AttachmentFetchV1.Invocation", 1, MetricType.COUNT);
        } else {
            fetchContactMediumAttachmentLink(attachmentId);
            Phoenix.getInstance().addMetric("AttachmentFetchOther.Invocation", 1, MetricType.COUNT);
        }
    }

    function fetchContactAttachmentLink(attachmentId: string): void {
        const query = buildContactAttachmentLinkQuery(attachmentId);
            callPhoenix(query)
              .then((response: CallResponse) => {
                  if (response.ok) {
                      processContactAttachmentLinkResponse(response);
                  } else {
                      Phoenix.getInstance().log(LogLevel.ERROR, "Attachment link could not be loaded. HTTP status: " + response.httpStatus);
                      throw new InternalError();
                  }
              })
              .then(() => {
                  showComponent();
                  Phoenix.getInstance().addMetric("ContactAttachmentLinkQuery.Success", 1, MetricType.COUNT);
              })
              .catch(() => {
                  setError("An error was encountered when loading the attachment link");
                  Phoenix.getInstance().addMetric("ContactAttachmentLinkQuery.Failure", 1, MetricType.COUNT);
              });
    }

    function fetchContactMediumAttachmentLink(attachmentId: string): void {
        const query = buildContactMediumAttachmentLinkQuery(attachmentId);
        callPhoenix(query)
            .then((response: CallResponse) => {
                if (response.ok) {
                    processContactMediumAttachmentLinkResponse(response);
                } else {
                    Phoenix.getInstance().log(LogLevel.ERROR, "Attachment link could not be loaded. HTTP status: " + response.httpStatus);
                    throw new InternalError();
                }
            })
            .then(() => {
                showComponent();
                Phoenix.getInstance().addMetric("ContactMediumAttachmentLinkQuery.Success", 1, MetricType.COUNT);
            })
            .catch(() => {
                setError("An error was encountered when loading the medium attachment link");
                Phoenix.getInstance().addMetric("ContactMediumAttachmentLinkQuery.Failure", 1, MetricType.COUNT);
            });
    }

    function processAttachmentFromCSCResult(data): void {
        const url = URL.createObjectURL(data.data);
        setLink(url);
        showComponent();
    }
  
    function processContactAttachmentLinkResponse(response: CallResponse): void {
        if (response.callResult?.resultJson) {
            const parsed = JSON.parse(response.callResult.resultJson);
            const downloadLink = parsed.data?.contactAttachmentLink?.downloadLink;
            if (downloadLink) {
                setLink(downloadLink);
                return;
            }
        }

        Phoenix.getInstance().log(LogLevel.ERROR, "Attachment link not found");
        throw new MissingFieldsError();
    }

    function processContactMediumAttachmentLinkResponse(response: CallResponse): string {
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                if (parsed.data
                    && parsed.data.contactMediumAttachmentLink
                    && parsed.data.contactMediumAttachmentLink.attachmentLinkEncrypted) {
                    setLink(parsed.data.contactMediumAttachmentLink.attachmentLinkEncrypted);
                    return parsed.data.contactMediumAttachmentLink.attachmentLinkEncrypted;
                }
            }
        }
        Phoenix.getInstance().log(LogLevel.ERROR, "Attachment link not found");
        throw new MissingFieldsError();
    }

    function downloadAttachment(attachmentLink: string, fileName: string, newTab: boolean): void {
        const link = document.createElement('a');
        link.href = attachmentLink;
        if (newTab) {
            link.target = "_blank";
        }
        document.body.appendChild(link);
        link.download = fileName;
        link.click();
    }

    function processFile(): JSX.Element {
        switch (attachment.fileType) {
            // PDF files
            case "application/pdf": {
                downloadAttachment(link, attachment.fileName, true);
                clearComponent();
                return <div/>;
            }
            // Images
            case "image/jpeg":
            case "image/png":
                downloadAttachment(link, attachment.fileName, false);
                clearComponent();
                return <div/>;
            case "application/octet-stream": {
                downloadAttachment(link, attachment.fileName, attachment.fileName.endsWith(".pdf"));
                clearComponent();
                return <div/>;
            }
            // Other files
            default: {
                downloadAttachment(link, attachment.fileName, false);
                clearComponent();
                return <div/>;
            }
        }
    }

    return (
        <div style={{paddingRight: "5px"}}>
                <div>
                    <a href="#/"
                    onClick={(): void => {
                        if (attachment.attachmentId != null) {
                            fetchAttachment(attachment.attachmentId, attachment.fileName);
                        }
                    }}>{attachment.fileName}</a>
                </div>
                { getComponentState() && processFile() }
                {error ?
                    <Alert header="Error" type="error">{JSON.stringify(error)}</Alert>
                    : null
                }            
        </div>
    );
};

export default AttachmentDisplay;
