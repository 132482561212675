import React from "react";
import {Contact, ContactMediumType, CustomerAccessLevel} from "./Contact.types";
import ContactChatMedium from "./ContactChatMedium";
import ContactEmailMedium from "./ContactEmailMedium";
import ContactPhoneMedium from "./ContactPhoneMedium";
import ContactMailMedium from "./ContactMailMedium";
import ContactMediumAttachmentsDisplay from "./ContactMediumAttachmentsDisplay";
import AmazonConnectContactDetail from "./AmazonConnectContactDetail";

interface ContactMediumDisplayProps {
    contact: Contact;
    customerTimezone: string;
    customerAccessLevel: CustomerAccessLevel;
    marketplaceId: string;
}

const ContactMediumDisplay: React.FC<ContactMediumDisplayProps> = (
    {
        contact,
        customerTimezone,
        customerAccessLevel,
        marketplaceId
    }) => {
    let mediumContent: JSX.Element | null = null;

    if (contact.transferredFromContact?.medium.type === ContactMediumType.CHAT) {
        mediumContent = <ContactChatMedium chat={contact.transferredFromContact?.medium.chat}
                                           customerContentIsRestricted={customerAccessLevel === CustomerAccessLevel.CONFIDENTIAL}
                                           isForwardedChat={true}
                                           customerTimezone={customerTimezone}/>;
    } else if (contact.medium.type === ContactMediumType.CHAT) {
        mediumContent = <ContactChatMedium chat={contact.medium.chat} customerContentIsRestricted={customerAccessLevel
        === CustomerAccessLevel.CONFIDENTIAL} customerTimezone={customerTimezone}/>;
    } else if (contact.medium.type === ContactMediumType.EMAIL) {
        mediumContent = <ContactEmailMedium emailSubject={contact.medium.emailSubject}
                                            emailHeader={contact.medium.emailHeader}
                                            emailBodyHTMLSafe={contact.medium.emailBodyHTMLSafe}
                                            sender={contact.medium.sender}
                                            customerContentIsRestricted={customerAccessLevel === CustomerAccessLevel.CONFIDENTIAL}
                                            rosettaTranslatedInboundEmail={contact.medium.rosettaTranslatedInboundEmail}
                                            displayOnlyEmailText={false}/>;
    } else if (contact.medium.type === ContactMediumType.PHONE) {
        mediumContent =
            <div>
            {(contact.medium.connectPhoneRecordList?.length > 0) ?
            <AmazonConnectContactDetail
                contactId={contact.contactId}
                connectPhoneRecordList = {contact?.medium?.connectPhoneRecordList}/> :
            <ContactPhoneMedium contactId={contact.contactId} marketplaceId={contact.marketplaceId}/> }
            </div>;
    } else if (contact.medium.type === ContactMediumType.MAIL) {
        mediumContent = <ContactMailMedium
                            customerContentIsRestricted={customerAccessLevel === CustomerAccessLevel.CONFIDENTIAL}
                            sender={contact.medium.sender} />;

    } else {
        return null;
    }

    return (
        <>
            {mediumContent}
            <ContactMediumAttachmentsDisplay
                attachments={contact.medium.attachments}
                customerContentIsRestricted={customerAccessLevel === CustomerAccessLevel.CONFIDENTIAL}
                marketplaceId={marketplaceId}
            />
        </>
    );
};

export default ContactMediumDisplay;