export const en_US = {
    // The following are cs_papyrus_* strings
    "SUBJECT": "Subject",
    "SENT_TO": "Sent to",
    "EMAIL_BODY": "Email body",
    "ITEMS_CONTACTED_ABOUT": "Item(s) contacted about",
    "CUSTOMER_SELECTED_ISSUE": "Customer selected issue",
    "AGENT_SELECTED_ISSUE": "Agent selected issue",
    "WELCOME_MESSAGE": "Welcome to the Papyrus Static Website!",
    "AUTHENTICATING": "Started authentication. This may open a new window.",
    "AUTHENTICATION_MESSAGE": "Fetching new credentials. This window will close itself in a couple of seconds.",
    "VIEW": "View",
    "SELECTED_VIEW": "Selected view",
    "NO_VIEW": "No contact or customer to view.",
    "ROLE": "Role",
    "SELECTED_ROLE": "Selected role",
    "COPY_FAILURE": "Failed to copy.",
    "CONTACTS": "Contacts",
    "LOADING": "Loading data.",
    "CONTACT_EXPANDED": "Contact details expanded.",
    "CONTACT_COLLAPSED": "Contact details collapsed.",
    "PHONE_CONTACT": "Phone contact.",
    "CHAT_CONTACT": "Chat contact.",
    "EMAIL_CONTACT": "Email contact.",
    "UNKNOWN_CONTACT_TYPE": "Unknown contact type.",
    "RESTRICTED_INFORMATION": "RESTRICTED INFORMATION",
    "LOAD_MORE": "Load more",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Load more contacts for this customer.",
    "NO_ITEMS_CONTACTED_ABOUT": "No items contacted about.",
    "NO_USED_BLURBS": "No blurbs used for this contact.",
    "COPY_ORDER_ID": "Copy order ID: {value}.",
    "COPY_CONTACT_ID": "Copy contact ID: {value}",
    "ORDER_ID_COPIED": "Order ID copied.",
    "CONTACT_ID_COPIED": "Contact ID copied.",
    "NO_CUSTOMER_SELECTED_ISSUE": "No customer selected issue.",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copy customer selected issue: {value}",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Customer selected issue copied.",
    "NO_AGENT_SELECTED_ISSUE": "No agent selected issue.",
    "COPY_AGENT_SELECTED_ISSUE": "Copy agent selected issue: {value}",
    "AGENT_SELECTED_ISSUE_COPIED": "Agent selected issue copied.",
    "EMAIL_TITLE": "Email with Amazon customer service",
    "POSTAL_MAIL_TITLE": "Postal mail with Amazon customer service",
    "CONTENT_RESTRICTED": "Content is restricted.",
    "SENT_FROM": "Sent from",
    "NO_SENT_FROM": "No sent from address.",
    "NO_SENT_TO": "No sent to address",
    "NO_SUBJECT": "Subject line is empty.",
    "NO_EMAIL_BODY": "Email is empty.",
    "UNRESOLVED": "Unresolved",
    "LOCKED_BY": "Locked by",
    "RESOLVED_BY": "Resolved by",
    "INSERTED_BY_AGENT": "INSERTED by {agent}",
    "ANALYZED_BY_AGENT": "ANALYZED by {agent}",
    "LOCKED_BY_AGENT": "LOCKED by {agent}",
    "TRANSFERRED_BY_AGENT": "TRANSFERRED by {agent}",
    "ABANDONED_BY_AGENT": "ABANDONED by {agent}",
    "REASSIGNED_BY_AGENT": "REASSIGNED by {agent}",
    "REPLIED_BY_AGENT": "REPLIED by {agent}",
    "RESOLVED_BY_AGENT": "RESOLVED by {agent}",
    "ANNOTATIONS": "Annotations",
    "STATE_TRANSITIONS": "State transitions",
    "NO_ANNOTATIONS": "No annotations available.",
    "ANNOTATED_BY": "Annotated by {agentLogin}.",
    "NO_ANNOTATED_BY": "Annotated by unknown user.",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Load more annotations.",
    "CHAT_TITLE": "Chat with Amazon customer service",
    "NO_CHAT": "Chat is empty.",
    "CHAT_PARKED": "This chat was parked",
    "CHAT_TRANSFER_NOTES": "Transfer Notes",
    "CHAT_AGENT_CONNECTED": "{name} has accepted the chat.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} is on hold.",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} is off hold.",
    "CHAT_DISCONNECT_GENERIC": "{name} is no longer connected.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} lost connection with the network.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} lost connection with the network.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} has left the conversation.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} lost connection with the network.",
    "WHAT_IS_PAPYRUS": "What is Papyrus?",
    "FOLLOW_UP_DUE": "Follow-up due",
    "FOLLOW_UP_TITLE": "Follow-ups",
    "FOLLOW_UP_OWNER": "Owner",
    "FOLLOW_UP_DUE_DATE": "Due date",
    "FOLLOW_UP_CONTACT_METHOD": "Contact method",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_RESOLVED": "Resolved",
    "FOLLOW_UP_STATUS_OPEN": "Open",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_NOTE": "Note",
    "FOLLOW_UP_NO_NOTE": "Note is empty",
    "FOLLOW_UP_NO_TICKET": "No ticket",
    "FOLLOW_UP_NO_CONTACT_METHOD": "No contact method",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Contact method is restricted.",
    "FOLLOW_UPS_COLLAPSED": "Follow-ups collapsed",
    "FOLLOW_UPS_EXPANDED": "Follow-ups expanded",
    "FETCHED_NUMBER_CONTACTS": "Fetched {number} Contacts.",
    "CONTACT_ID": "Contact id",
    "CONTACT_TYPE": "Type",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Customer content is restricted. (No Contacts Locked.)",
    "CHAT_RESTRICTED": "Chat content is restricted.",
    "EMAIL_RESTRICTED": "Email content is restricted.",
    "REPLY_RESTRICTED": "Reply content is restricted.",
    "REPLY_BY" : "Reply by",
    "REPLY_BY_EMAIL" : "Reply by Email",
    "REPLY_BY_PHONE" : "Reply by Phone",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Additional Contact information",
    "NO_SCREEN_POP": "No ScreenPop information",
    "SCREEN_POP_TITLE": "ScreenPop information",
    "SCREEN_POP_DESCRIPTION_TITLE": "Description",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Used by agent",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Yes",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "No",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Authenticating screenpop was displayed to agent.",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Authenticating screenpop was displayed to agent, and it was used.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Caller ID screenpop was displayed to agent.",
    "SCREEN_POP_TYPE_CALLER_ID": "Caller ID screenpop was displayed to agent, and it was used.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Unauthenticated-hunched screenpop was displayed to the agent.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Unauthenticated-hunched screenpop was displayed, and it was used.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Customer is unknown; no screen pop provided.",
    "SCREEN_POP_TYPE_OTHER": "Other",
    "BLURBS_USED_TITLE": "Blurbs used",
    "NO_WORK_CATEGORY": "No work category associated to this contact.",
    "WORK_CATEGORY_TITLE": "Work Category",
    "NO_EMAIL_QUEUE": "No email queue associated to this contact.",
    "EMAIL_QUEUE_TITLE": "Email Queue",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "ADD": "Add",
    "ADD_ANNOTATION_SUCCESS": "Successfully added annotation.",
    "ADD_ANNOTATION_INVALID": "Invalid annotation. Please make sure there is no restricted data in the annotation.",
    "ADD_ANNOTATION_FAILURE": "Failed to add the annotation.",
    "ADD_ANNOTATION_PLACEHOLDER": "New annotation",
    "ANNOTATION_WARNING" : "Annotations may be subject to review by customers under privacy regulations. Do not include personal information or opinions within the annotations field.",
    "CALL_TITLE": "Call with Amazon customer service",
    "CALL_RESTRICTED": "Call content is restricted.",
    "RECORDING": "Recording",
    "START": "Start",
    "DURATION": "Duration",
    "AGENT": "Agent",
    "SKILL": "Skill",
    "PLAY": "Play",
    "PAUSE": "Pause",
    "PLAYBACK_SPEED": "Playback speed",
    "SKIP_SILENCES": "Skip silences",
    "LOADING_RECORDING": "Loading recording",
    "PREPARING_RECORDING": "Preparing recording",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Timeout: failed to load recording",
    "DECODING_AUDIO": "Decoding audio",
    "NORMAL": "Normal",
    "CALL_RETENTION_DELETED": "The recording is passed its retention period and has been deleted.",
    "PENDING": "Pending",
    "COMPLETE": "Complete",
    "TRANSFERRED_CHAT_TITLE": "Transferred chat with Amazon customer service",
    "ATTACHMENTS_TITLE": "Attachments",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contact",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalations",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalation",
    "ESCALATION_TAB_LEAD_LOGIN": "Lead login: {agentLogin}",
    "NO_EMAIL_HEADER": "No email header.",
    "EMAIL_HEADER": "Header",
    "HISTORY": "History",
    "CURRENT_CONTACT": "Current Contact",
    "CONTACT_HISTORY": "Contact History",
    "CONTACT_TIMELINE": "Contact Timeline",
    "CHOOSE_OPTIONS": "Choose options",
    "NO_CONTACT": "No contact available",
    "USER_TIMEZONE": "Timestamps shown in your local timezone",
    "CUSTOMER_TIMEZONE": "Timestamps shown in customer's timezone",
    "TRANSFER_CONTACT": "Transfer contact",
    "TRANSFER_CONTACT_COMMENTS": "Optional annotation",
    "PROVIDE_ADDITIONAL_INFORMATION": "Provide additional information relevant to this contact",
    "TYPE_QUEUE_NAME": "Type queue name",
    "SELECT_TRANSFER_DESTINATION": "Select transfer destination",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "You are about to transfer to queue",
    "WITH_THE_ANNOTATION": "with the annotation",
    "TRANSFER_FAILED": "Transfer failed",
    "TRANSFERRING": "Transferring...",
    "INTERNAL_ERROR": "Internal Error",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.",
    "CANCEL": "Cancel",
    "CLOSE": "Close",
    "CONFIRM": "Confirm",
    "CONTACT_WAS_TRANSFERRED": "The Contact has been transferred.",
    "CREATE_FOLLOW_UP": "Create follow-up",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Specify the date and time when the follow-up action is due.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Timezone",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Use 24-hour format.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Enter the follow-up action.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Specify the customer's preferred method of contact.",
    "INVALID_CONTACT": "Invalid contact.",
    "INVALID_TIMESTAMP": "Invalid timestamp.",
    "CREATE_FOLLOW_UP_SUCCESS": "Successfully created follow-up.",
    "CREATE_FOLLOW_UP_FAILURE": "Failed to create follow-up.",
    "CREATE_FOLLOW_UP_INVALID": "Invalid follow-up. Please make sure there is no restricted data.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Cannot create a follow-up with a due date and time in the past.",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Successfully created follow-up, but could not add corresponding annotation. Retry adding annotation manually.",
    "TRANSFER_FOLLOW_UP": "Transfer follow-up",
    "TRANSFER": "Transfer",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transfer the above follow-up to:",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Enter the login name of the new owner. Example: {example_login}",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Successfully transferred follow-up.",
    "TRANSFER_FOLLOW_UP_FAILED": "Failed to transfer follow-up.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Failed to transfer follow-up due to invalid new owner login.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "You do not have permission to transfer this follow-up.",
    "DATE_PICKER_NEXT_MONTH": "Next month",
    "DATE_PICKER_PREVIOUS_MONTH": "Previous month",
    "DATE_PICKER_TODAY": "Today",
    "INPUT_CHARACTERS_REMAINING": "Maximum {char_max} characters ({char_remain} remaining)",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Failed to transfer the Contact because it is already resolved.",
    "CSALT_ERROR_INVALID_QUEUE": "Failed to transfer the Contact because the selected Queue is invalid.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Failed to transfer the Contact because it is locked to a different Agent.",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Failed to transfer the Contact because the Queue was not found in the backend service.",
    "END": "End",
    "CALL_LEG": "Call Leg ID",
    "CUSTOMER": "Customer",
    "COPY_CUSTOMER_ID": "Copy customer id",
    "COPY_LINK": "Copy image upload link",
    "SMS_LINK": "SMS Link",
    "GENERATE_LINK": "Generate Link",
    "LINK_COPIED": "Link copied",
    "CUSTOMER_ID_COPIED": "Customer id copied",
    "CUSTOMER_IDS": "Customer ID(s)",
    "NO_CUSTOMER_ID_FOUND": "No Customer ID found",
    "COPY_CALL_LEG_ID": "Copy call leg ID: {value}",
    "CHAT_PARKED_NOTES": "Park Notes",
    "COPY_ID": "Copy ID",
    "CALL_LEG_ID_COPIED": "Call leg ID copied.",
    "AUTHENTICATION_FAILURE": "There was a problem authenticating.",
    "RESTRICTED_DATA_MATCH": "{match} matching type {type}",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} is not a valid queue name.",
    "USE": "Use",
    "KNOWN_ISSUES": "Known Issues",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Third-party cookies must be enabled.",
    "OPENS_IN_A_NEW_TAB": "Opens in a new tab",
    "LEARN_MORE": "Learn more",
    "WORK_REQUEST": "Work Request",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Customer PII data like phone numbers and email addresses are not permitted while creating a work request. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other ids. Provide the reason why an override is needed.",
    "CREATE_CASE": "Create Case",
    "SELECT_WORK_REQUEST_QUEUE": "Select Work Request Queue",
    "CREATE_WORK_REQUEST": "Create Work Request",
    "WORK_REQUEST_CONTENT": "Work Request content",
    "FAILED_TO_CREATE_WORK_REQUEST": "Failed to create Work Request",
    "WORK_REQUEST_CREATED": "Work Request created",
    "CONTACT_TICKET": "Contact Ticket",
    "CONTACT_TICKET_CREATE": "Create Contact Ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM Folder ID",
    "CONTACT_TICKET_RESOLVER_GROUP": "Resolver Group",
    "CONTACT_TICKET_SUBJECT": "Ticket Subject",
    "CONTACT_TICKET_CONTENT": "Ticket Content",
    "CONTACT_TICKET_SEVERITY": "Ticket Severity",
    "CONTACT_TICKET_CREATION_SUCCESS": "Contact ticket created",
    "CONTACT_TICKET_CREATION_FAILURE": "Failed to create contact ticket",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Customer PII data like phone numbers and email addresses are not permitted while creating a ticket. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other ids. Provide the reason why an override is needed.",
    "SOURCE_TEXT": "Source text",
    "TRANSLATED_TEXT": "Translated text",
    "TRANSLATION": "Translation",
    "IN_MARKETPLACE_QUEUES": "In Marketplace Queues",
    "CROSS_MARKETPLACE_QUEUES": "Cross Marketplace Queues",
    "REQUEST_OVERRIDE": "Request Override",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Invalid match as Credit Card or IBAN.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Invalid match as Phone Number, email address or other PII.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "The current process requires PII content.",
    "OTHER": "Other",
    "REQUEST": "Request",
    "REQUEST_NOT_GRANTED": "The request was not granted.",
    "RELOADING_CONTENT": "Reloading content.",
    "REQUEST_ACCESS_INSTRUCTIONS": "Please provide the reason why access to filtered content is required. (These requests are monitored and abuse may have serious consequences.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Request access to filtered content",
    "ACCESS_RESTRICTED_CONTENT": "Access restricted content",
    "INVALID_DATA_MATCH": "The system did an invalid classification of data.",
    "OVERRIDE_REASON_PROMPT": "Annotations are not supposed to contain Customer PII data like phone numbers and email addresses. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other ids. Provide the reason why an override is needed.",
    "DISABLE_CONTENT_FILTER": "Disable content filter",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Annotations are not supposed to contain Customer PII data like phone numbers and email addresses. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other ids. Provide the reason why an override is needed.",
    "HAS_OVERRIDE_TRY_AGAIN": "Filter override present. Try again.",
    "PAPYRUS_SESSION_TYPE": "Papyrus session type",
    "PURPOSE_UNKNOWN": "Default",
    "PURPOSE_LIVE_CONTACT": "Handling live customer contact",
    "PURPOSE_REVIEW_OWN_CONTACT": "Reviewing own past contact",
    "PURPOSE_REVIEW_ML_ACCURACY": "Reviewing ML Model Accuracy",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Handling escalation contact",
    "PURPOSE_COACHING": "Coaching contact review",
    "PURPOSE_AUDITING": "Auditing for quality control",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Purge sensitive info",
    "PAPYRUS_SESSION_EXPLANATION": "Please specify your Papyrus session type so that we can apply the right security restrictions and optimize the UX for your needs.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "This is the default general purpose session.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "This is the session for live contact handling.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "This is the session for reviewing your own past contacts.",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "This is the session for reviewing of machine learning model accuracy.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "This is the session for handling escalation contacts. This allows accessing any customer's contact data.",
    "PURPOSE_DESCRIPTION_COACHING": "This is the session for reviewing contacts of agents that you are a Performance Portal coach of.",
    "PURPOSE_DESCRIPTION_AUDITING": "This is the session for auditing contacts and evaluating their quality.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "This purpose is to remove sensitive info from a contact",
    "PURPOSE_CURATION": "Contact curation",
    "PURPOSE_CURATION_DESCRIPTION": "This is the session for C2CS/VoC contact curation.",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Back Office workflow",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "This is the session for obtaining contact data for Back Office workflows. For example, attachments to include in tickets or SIM.",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Compliance investigation",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "This is the session for Suspicious Activity Monitoring investigations.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Notification content expanded.",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Notification content collapsed.",
    "EXPANDED": "Expanded",
    "COLLAPSED": "Collapsed",
    "DELIVERY_STATUS": "Delivery status",
    "NO_DELIVERY_STATUS": "No delivery status",
    "RESEND": "Resend",
    "ACTION": "Action",
    "EMAIL": "Email",
    "SMS": "SMS",
    "SELECT_CHANNELS": "Select channels",
    "NEXT": "Next",
    "PREVIOUS": "Previous",
    "NO_RESULTS": "No results found.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS":"Maximum number of results reached. Please reduce time range and try again.",
    "WARNING": "Warning",
    "ERROR": "Error",
    "FROM": "From",
    "TO": "To",
    "SEARCH": "Search",
    "CHANNELS": "Channels",
    "CONTENT": "Content",
    "NO_CONTENT": "No content",
    "RESEND_WARNING" :"Are you sure you want to resend email to customer?",
    "RESEND_WAIT_WARNING": "You must wait for {wait_mins} minutes to resend again.",
    "RESEND_SUCCESS": "Successfully resent email.",
    "RESEND_FAILED": "Failed to resend email.",
    "RELOAD_LIST": "Reload list",
    "DOWNLOAD": "Download",
    "ATTACHMENTS_RESTRICTED": "Attachments are restricted.",
    "NO_ATTACHMENTS": "There are no attachments.",
    "PURGE_SENSITIVE_INFO": "Purge Sensitive Info",
    "LOADING_PREVIEW_TEXT": "Loading preview",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Purged sensitive info from contact: ",
    "PURGE_REASON_PII": "Personally identifiable information",
    "PURGE_REASON_FINANCIAL_INFO": "Financial info",
    "PURGE_REASON_OTHER": "Other sensitive info",
    "DELETE_ATTACHMENTS": "Delete contact attachments",
    "REASON_FOR_PURGE": "Reason for Purge",
    "REASON_FOR_PURGE_DESCRIPTION" : "Reason for purging attachments",
    "SEND_IMAGE_UPLOAD_LINK_WARNING" :"Are you sure you want to send image upload link to customer?",
    "IMAGE_UPLOAD_LINK_ANNOTATION":"image attachment link shared with customer",
    "PURGE_BUTTON_TEXT": "Purge",
    "CLOSE_MODAL_BUTTON_TEXT" : "Close",
    "MODAL_YES_BUTTON_TEXT" : "Yes",
    "MODAL_NO_BUTTON_TEXT" : "No",
    "ATTACHMENT_DELETION_FAILED" : "Attachments deletion failed, please try again later.",
    "ATTACHMENT_DELETION_SUCCESSFUL" : "Attachments deletion successful.",
    "REASON_FOR_PURGE_MISSING" : "Please enter reason for deleting attachments.",
    "PURGE_CONFIRMATION_UNCHECKED" : "Please confirm checkbox.",
    "PURGE_CONFIRMATION_CHECK": "I have verified the above fields and confirm that the text indeed contains sensitive information.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "I confirm that attachment indeed contains customer sensitive information.",
    "PURGE_SELECTOR_PLACEHOLDER": "Select reason for purging sensitive info",
    "PURGE_REASON_PLACEHOLDER" : "Enter reason for purge",
    "DELETE_ATTACHMENTS_BUTTON_TEXT" : "Delete Attachments",
    "PURGE_REASON_TEXT": "Reason for purge",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Add word to purge list",
    "TEXT_TO_PURGE": "Text to purge",
    "PURGE_WORD_NOT_FOUND": "Word is not found in contact text",
    "PURGE_REMOVE_LABEL": "Remove",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} is not a valid reason for purging",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS" : "Successfully purged info from contact",
    "PURGE_FROM_CONTACT_TEXT_FAILURE" : "Failed to purge from contact",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE" : "Failed to generate preview for contact",
    "RESET": "Reset",
    "lock_contact": "Lock",
    "lock_contact_successful": "Contact locked to",
    "lock_contact_not_authorized": "Failed to lock the contact, user is not authorized",
    "CONTACT_US_ALERT_TITLE": "Contact Us SYSKAs",
    "NO_CONTACT_US_ALERT": "No Contact Us SYSKAs",
    "SYSKA_ALERTS": "SYSKA Alerts",
    "NO_SYSKA_ALERTS": "No SYSKA Alerts",
    "FAILED_TO_FETCH_SYSKA": "Failed to fetch SYSKA Alerts",
    "URGENT_SYSKA_ALERTS": "Urgent SYSKA Alerts",
    "I_UNDERSTAND": "I understand",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Actions",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Process as Spam",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Are you sure you want to process selected contacts as spam?",
    "PROCESS_CONTACT_AS_DUPLICATE": "Resolve Selected Contacts as Duplicate",
    "ISSUE_CODE_EXACTLY_SAME": "Exactly the same as a previous contact",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Already addressed and resolved in a previous contact",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Handled and resolved by another Phone CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Handled and resolved by another Email CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Handled and resolved by another Chat CSA",
    "ISSUE_CODE": "Issue Code",
    "ISSUE_CODE_DESCRIPTION": "Select the reason for making selected contacts as duplicate",
    "PROCESS_SPAM_SUCCESS": "Successfully transferred selected contacts to spam",
    "PROCESS_SPAM_FAILURE": "Failed to transfer selected contacts to spam",
    "PROCESS_DUPLICATE_SUCCESS": "Successfully resolved selected contacts as duplicate",
    "MISSING_ORIGINAL_CONTACT_ID": "Please enter valid original contact ID",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Enter original contact ID",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "To select issue code for marking selected contacts as duplicate",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Text box to input original contact ID",
    "PROCESS_DUPLICATE_FAILURE": "Failed to resolve selected contacts as duplicate",
    "NO_CONTACTS_SELECTED": "No locked contacts selected",
    "CONTACT_SELECT_CHECKBOX": "Checkbox to select a locked contact",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Process as Duplicate",
    "WORK_REQUEST_FOR_CONTACT": "Work Request for contact",
    "CHILD_WORK_REQUESTS" : "Work requests created from this contact",
    "NO_CHILD_WORK_REQUESTS" : "No work requests created from this contact",
    "ERROR_FAILED_TO_LOAD_DATA": "Failed to load data.",
    "RETRY": "Retry",
    "CLOSE_ALERT": "Close alert",
    "CUSTOMER_FOLLOWUPS" : "Follow ups for customer",
    "CUSTOMER_FOLLOWUPS_FAILURE" : "Failed to fetch follow-ups for customer",
    "FOLLOW_UP_ALERT" : " has a follow up due on {dueDate} created by {agentLogin}",
    "AMAZON_LOGISTICS_BUTTON_TEXT" : "Amazon Logistics",
    "SHIPPER_PORTAL_BUTTON_TEXT" : "Shipper Portal",
    "SDS_TOOLS" : "Shipping and Delivery Support Tool(s)",
    "FILTER": "Filter",
    "CF_LARGE_NUMBER_ALERT": "This customer has a very large number of contacts and not all of them have been loaded. Contacts are always loaded in \"recent to older\" direction starting from the end date. Change the end date to start loading from a different point in time.",
    "NUMBER_OF_CONTACTS": "Loaded contacts",
    "OLDEST_LOADED_CONTACT": "Oldest loaded contact",
    "END_DATE": "End date",
    "BACK": "Back",
    "DATE": "Date",
    "STATUS": "Status",
    "ISSUE": "Issue",
    "OPEN": "Open",
    "FINDER": "Finder",
    "USE_CONTACT_FINDER": "Use contact finder",
    "RESOLVED": "Resolved",
    "UNLOCKED": "Unlocked",
    "LOCKED": "Locked",
    // Split Panel
    "SP_PREFERENCES": "Split panel preferences",
    "SP_POSITION": "Split panel position",
    "SP_SIDE": "Side",
    "SP_BOTTOM": "Bottom",
    "SP_RESIZE": "Resize split panel",
    "REASSIGN_TO_AGENT": "Reassign to agent",
    "TYPE_AGENT_LOGIN": "Type agent login",
    "CONTACT_REASSIGNED_TO_AGENT": "Contact has been reassigned to agent.",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Failed to reassign contact to agent.",
    //Amazon Connect
    "AMAZON_CONNECT_CONTACT_ID" : "Amazon Connect Contact ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED" : "Amazon Connect Contact ID copied",
    "COPY_AMAZON_CONNECT_CONTACT_ID" : "Copy Amazon Connect Contact ID",
    "AMAZON_CONNECT_CALL_INFO" : "Amazon Connect Call Info",
    // The following are auto-annotation strings
    "contact_event_GC_added": "GC #{gcId} added to account.",
    "contact_event_GC_added_batched": "GC added to account  ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} added to purchaser account.",
    "contact_event_GC_added_purchaser_batched": "GC added to purchaser account  ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} added to recipient account.",
    "contact_event_GC_added_recipient_batched": "GC added to recipient account  ({count}).",
    "contact_event_GC_added_scratchedCode": "GC #{gcId} added to account via scratched claim code tool.",
    "contact_event_GC_added_scratchedCode_batched": "GC added to account via scratched claim code tool ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} Cancelled.",
    "contact_event_GC_cancelled_batched": "GC cancelled ({count}).",
    "contact_event_GC_changed_expiry": "Changed expiration date of GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Changed expiration date of GC ({count}).",
    "contact_event_GC_locked": "GC #{gcId} locked.",
    "contact_event_GC_locked_batched": "GC locked ({count}).",
    "contact_event_GC_removed": "GC #{gcId} removed from account.",
    "contact_event_GC_removed_batched": "GC removed from account ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} re-sent to email Id {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC re-sent to email Id ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} re-sent by post mail.",
    "contact_event_GC_resent_post_batched": "GC re-sent by post mail ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} temporary locked.",
    "contact_event_GC_temporary_locked_batched": "GC temporary locked ({count}).",
    "contact_event_GC_transferred": "GC {gcId} transferred from customer {customerID} to {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC transferred  ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} unlocked.",
    "contact_event_GC_unlocked_batched": "GC unlocked ({count}).",
    "contact_event_PV_added": "PV {pvId} added to account.",
    "contact_event_PV_locked": "PV #{pvId} locked.",
    "contact_event_PV_temporary_locked": "PV #{pvId} temporary locked.",
    "contact_event_PV_unlocked": "PV #{pvId} unlocked.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "A-to-Z claim cancelled for orders ({count}).",
    "contact_event_a_to_z_guarantee_requested": "A-to-Z claim submitted for order {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "A-to-Z claim submitted for orders ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "A-to-Z claim submitted for order {order_id}.",
    "contact_event_access_code_added": "Access Code Added",
    "contact_event_access_code_added_batched": "Access Code Added({count})",
    "contact_event_account_closed": "Account Closed.",
    "contact_event_account_closed_batched": "Accounts Closed ({count}).",
    "contact_event_account_reopened": "Account reopened",
    "contact_event_account_reopened_batched": "Accounts reopened ({count})",
    "contact_event_account_temporarily_disabled": "Account temporarily disabled.",
    "contact_event_account_temporarily_disabled_batched": "Accounts temporarily disabled ({count})",
    "contact_event_account_whitelisted": "Account Whitelisted.",
    "contact_event_account_whitelisted_batched": "Account Whitelisted({count}).",
    "contact_event_address_changed": "{address_type} address updated for order {order_id}.",
    "contact_event_address_changed_batched": "Updated addresses for orders ({count}).",
    "contact_event_address_deactivated": "Address deactivated.",
    "contact_event_address_deactivated_batched": "Address deactivated ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video subscription auto-renewed",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video subscription canceled",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Form submitted: DSL Escalation form " +
        "\nUse-case session id: {usecaseSessionID} \nDelivery Station: {deliveryStationField} " +
        "\nRequest Type: {requestTypeField} \nContact ID: {contactIdField} " +
        "\nOrder ID: {orderIdField} \nTBA(s): {tbasField} " +
        "\nIssue Summary: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "DSL Escalation forms submitted: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Form submitted: DSP DA Feedback form " +
        "\nUse-case session id: {usecaseSessionID} \nDelivery Station Code: {deliveryStationField} " +
        "\nContact ID: {contactIdField} \nCustomer Account ID: {customerIdField} \nDA Transporter ID: {driverIdField} " +
        "\nIncident Date (YYYY-MM-DD): {dateOfOccurrenceField} \nIncident Time (HH:MM): {timeofOccurrenceField} " +
        "\nTBAs: {relatedTrackingIdsField} \nSource of Feedback: {sourceOfFeedbackField} " +
        "\nFeedback Details: {issueField} \nAdditional Details: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "DSP DA Feedback forms submitted: ({count})",
    "contact_event_andon_cord_pulled": "Andon cord pulled for {asin}.",
    "contact_event_andon_cord_pulled_batched": "Andon cord pulled for items ({count}).",
    "contact_event_apply_adrive_music_trial": "applied Cloud Player Premium for customer {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_apply_adrive_music_trial_batched": "Applied Cloud Player Premium ({count}).",
    "contact_event_audible_account_migrated": "Account migrated to {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Audible accounts migrated ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} was refunded for the amount of {cashAmount}. Additionally, this transaction restored a {couponAmount} coupon back to the account.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} was refunded for the amount of {cashAmount}. Additionally, this transaction restored a {couponAmount} coupon back to the account.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} was refunded for the amount of {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} was refunded for the amount of {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} was refunded and restored a {couponAmount} coupon back to the account.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} was refunded and restored a {couponAmount} coupon back to the account.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} credit(s) were removed from the account.",
    "contact_event_audible_credit_deleted_batched": "{amount} credit(s) were removed from the account.({count})",
    "contact_event_audible_credit_issued": "{numCredit} credit(s) were added to account.",
    "contact_event_audible_credit_issued_batched": "Credit(s) were added to account ({count}).",
    "contact_event_audible_credit_refund": "{title} was refunded and restored {creditAmount} credit(s) back to the account.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} was refunded and restored {creditAmount} credit(s) back to the account.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} credit(s) were restored to the account.",
    "contact_event_audible_credit_restored_batched": "{amount} credit(s) were restored to the account.({count})",
    "contact_event_audible_extra_credit_purchased": "3 extra credits were purchased.",
    "contact_event_audible_extra_credit_purchased_batched": "3 extra credits were purchased.({count})",
    "contact_event_audible_hiatus_cancelled": "Hiatus period was terminated early and the membership has resumed.",
    "contact_event_audible_hiatus_cancelled_batched": "Hiatus period was terminated early and the membership has resumed.({count})",
    "contact_event_audible_hiatus_started": "The membership was placed on a {duration}-month Hiatus.",
    "contact_event_audible_hiatus_started_batched": "The membership was placed on a {duration}-month Hiatus.({count})",
    "contact_event_audible_membership_enrollment": "Enrolled on to the \n{membershipPlan} membership plan.",
    "contact_event_audible_membership_enrollment_batched": "Enrolled on to the Audible membership plans ({count}).",
    "contact_event_audible_membership_plan_refund": "Membership fee was refunded in the amount of {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Membership fee was refunded in the amount of {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Changed from the {oldMembershipPlan} to the {newMembershipPlan} membership plan.",
    "contact_event_audible_membership_switched_batched": "Changed Audible membership plans ({count}).",
    "contact_event_audible_romance_package_enrollment": "Enrolled on to the {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Enrolled on to the {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "{wizardName} was accessed.",
    "contact_event_audible_wizard_invoked_batched": "{wizardName} was accessed.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "The {membership} plan was successfully cancelled.",
    "contact_event_blacklist_kindle": "Black-listed device {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Black-listed Kindles ({count}).",
    "contact_event_bound_warranty": "Warranty ID {warranty_id} bound to device {dsn}.",
    "contact_event_bound_warranty_batched": "Warranties bound to devices ({count}).",
    "contact_event_call_was_flagged": "This contact was flagged as {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "This contact was flagged as {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "The {membership} plan was successfully cancelled.",
    "contact_event_cancel_audible_membership_batched": "The {membership} plan was successfully cancelled.({count})",
    "contact_event_cancel_audible_romance_package_batched": "The {membership} plan was successfully cancelled.({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Successfully canceled Prime from PrimeCancelException. Cancel reason: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Cancel Exception for Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "The previously set Future Cancellation Date was cancelled.",
    "contact_event_cancel_library_loan": "Cancelled library loan for customer({customer_id}). ASIN:{asin} ;loan ID:{loan_id} ; Reason:{cancel_reason} ;",
    "contact_event_cancel_remote_factory_reset_schedule": "Cancelled a schedule of Remote Factory Reset on DSN {dsn}",
    "contact_event_cancel_remote_factory_reset_schedule_batched": "Cancelled schedule of Remote Factory Reset for DSNs ({count}).",
    "contact_event_cancel_tv_pass": "Cancelled TV pass with Order ID {order_id} and reason {cancel_reason} .",
    "contact_event_cancelled_sns_reactivated": "Subscription for ASIN {asin} reactivated on {reactivation_date}(Month).",
    "contact_event_cancelled_sns_reactivated_batched": "Reactivated cancelled Subscribe and Save subscriptions ({count}).",
    "contact_event_captcha_bypass_key_generated": "Captcha Bypass Key generated.",
    "contact_event_captcha_bypass_key_generated_batched": "Captcha Bypass Key generated ({count}).",
    "contact_event_change_home_marketplace": "Customer home marketplace changed to {newHomeMarketplace}.",
    "contact_event_change_sns_quantity": "Item Quantity for ASIN {asin}  in subscription updated from {current_quantity}  to {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Updated Subscribe and Save quantities ({count}).",
    "contact_event_channels_auto_renew_off": "CS turned OFF auto-renewal for PV Channels subscription {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS turned OFF auto-renewal via Astro.",
    "contact_event_channels_full_refund": "CS cancelled PV Channels subscription {subscriptionid} with full refund.",
    "contact_event_channels_full_refund_static": "CS cancelled subscription with full refund via Astro.",
    "contact_event_channels_no_refund": "CS cancelled PV Channels subscription {subscriptionid} with no refund.",
    "contact_event_channels_no_refund_static": "CS cancelled subscription with no refund via Astro.",
    "contact_event_channels_workflow_launch": "CS launched Astro cancellation workflow for PV Channels subscription {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS launched Astro cancellation workflow.",
    "contact_event_check_in_book": "Checked in Book with loan ID {loan_id} for customer {customer_id}.",
    "contact_event_check_in_book_batched": "Checked in Book with loan IDs ({count}).",
    "contact_event_coins_adjust": "Adjustment Type: {type} <br />\nReason: {reason} <br />\nAmount: {amount} <br />\nComments: {comment} <br />",
    "contact_event_coins_adjust_batched": "Adjusted Coins Balance ({count}).",
    "contact_event_concessions_abuse": "HMD was not sent because customer is {risk_status} user.",
    "contact_event_concessions_abuse_batched": "HMD was not sent for customer ({count}).",
    "contact_event_contact_seller": "Seller contacted for order {order_id}.",
    "contact_event_contact_seller_batched": "Seller contacted for orders ({count}).",
    "contact_event_contact_seller_gwp": "Seller contacted for order {order_id}.",
    "contact_event_contact_was_initiated_from": "Contact was initiated from ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "ASIN {asin} content updated in the kindle library.",
    "contact_event_content_update_batched": "Updated content in Kindle library ({count}).",
    "contact_event_copilot_started": "Co-Pilot Started",
    "contact_event_copilot_started_batched": "CoPilot Started ({count})",
    "contact_event_credit_card_disabled": "CC deactivated.",
    "contact_event_credit_card_disabled_batched": "Credit cards deactivated ({count}).",
    "contact_event_csamzl_slp_related_ticket_urls_for_contact": "relatedTickets: {relatedTickets},",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Override geofence request submitted for Tracking ID {trackingId} Reason: {overrideReason} Page:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Override geofence request submitted for Tracking ID {trackingId} Reason: {overrideReason} Page:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocode updated. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocode updated. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "customer authentication passed",
    "contact_event_customer_authentication_passed_batched": "customer authentication passed({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Customer name updated.",
    "contact_event_customer_name_updated_batched": "Customer name updated ({count}).",
    "contact_event_customer_password_changed": "Customer password changed.",
    "contact_event_customer_password_changed_batched": "Customer password changed ({count}).",
    "contact_event_deauthorize_all_device": "Deauthorized all devices for customer {customer_id}.",
    "contact_event_deauthorize_device": "Deauthorized DSN {dsn} with device type {device_type} for customer({customer_id}).",
    "contact_event_delete_alexa_voice_profile": "Deleted Alexa Voice Profile for speakerId : {speakerId} ,  customerID : {customerID}  and status : {status}",
    "contact_event_delete_data_alexa_comms": "Deleted Alexa Comms Profile for commsID : {commsId} ,  customerID : {customerID}  and status : {status}",
    "contact_event_deprovision_alexa_comms": "Deprovisioned Alexa Comms Profile for commsID : {commsId} ,  customerID : {customerID}  and status : {status}",
    "contact_event_deregister_kindle": "Deregistered Kindle {serial_number}.",
    "contact_event_deregister_kindle_batched": "Deregistered Kindles ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Troubleshooting wizard invoked for device with DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "The Mayday function for device DSN {dsn} has failed to be deactivated.",
    "contact_event_disable_mayday_success": "The Mayday function for device DSN {dsn} has been deactivated successfully.",
    "contact_event_dvbypost_extra_dispatch": "{amount} extra dispatch(es) requested for this customer.",
    "contact_event_dvbypost_extra_envelope": "An extra envelope was triggered for this customer.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 form requested.",
    "contact_event_dvbypost_problem_code_change": "Setting disc {disc_id} to {problem_code}",
    "contact_event_email_address_updated": "Email address updated.",
    "contact_event_email_address_updated_batched": "Email address updated ({count}).",
    "contact_event_email_preferences_changed": "Email preferences changed.",
    "contact_event_email_resent_to_customer": "E-mail Dated {datetime} Resent to Customer",
    "contact_event_email_unsubscribe": "Email unsubscribed.",
    "contact_event_email_unsubscribe_batched": "Email unsubscribed ({count}).",
    "contact_event_enable_mayday_failed": "The Mayday function for device DSN {dsn} has failed to be reactivated.",
    "contact_event_enable_mayday_success": "The Mayday function for device DSN {dsn} has been reactivated successfully.",
    "contact_event_first_time_user_experience_set": "Set First Time User Experience",
    "contact_event_first_time_user_experience_set_batched": "Set First Time User Experience({count}).",
    "contact_event_form_submitted": "Form {form_name} submitted.",
    "contact_event_form_submitted_batched": "Forms submitted ({count}).",
    "contact_event_free_time_create_pin": "New FreeTime PIN generated successfully.",
    "contact_event_free_time_unlimited_cancelled": "Free Time Unlimited subscription cancelled for  customer: {customer_id}, DSN: {dsn} with reason: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Free Time Unlimited subscription cancelled ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Successfully canceled Prime Free Trial for {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Successfully canceled prime ({count}).",
    "contact_event_fulfill_kindle": "Fulfilled Kindle. DSN {dsn}; device type {device_type}; ASIN {asin}; OrderID {order_id};",
    "contact_event_future_cancel_audible_membership": "The {membership} plan has been set to Future Cancel on {date}.",
    "contact_event_future_cancel_audible_membership_batched": "The {membership} plan has been set to Future Cancel on {date}.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} has been permanently removed from Your Video Library.",
    "contact_event_header": "Events Log",
    "contact_event_hmd_suppression": "HMD was not sent because: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Idle chat disconnected",
    "contact_event_idle_chat_disconnect_batched": "Idle chat disconnected ({count})",
    "contact_event_international_linking_account": "International linked account {from_customer_email} with account {to_customer_email} for customer {customer_id}.",
    "contact_event_item_price_reduced": "Item price changed from {old_price} to {new_price} for order {order_id}.",
    "contact_event_item_price_reduced_batched": "Item prices changed for orders ({count}).",
    "contact_event_item_quantity_changed": "Item quantity changed in order {order_id}.",
    "contact_event_item_quantity_changed_batched": "Item quantities changed for orders ({count}).",
    "contact_event_kindle_auto_renew_off": "CS turned OFF auto-renewal for Kindle Unlimited subscription {subscriptionid}.",
    "contact_event_kindle_auto_renew_off_static": "CS turned OFF auto-renewal for Kindle Unlimited via Astro.",
    "contact_event_kindle_device_replacement": "{reason} Replacement created for DSN {dsn}  from order {old_order_id} .New order: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Replaced Kindle devices ({count}).",
    "contact_event_kindle_email_address_edits": "Email address updated on Kindle (DSN # {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Email addresses updated for Kindles ({count}).",
    "contact_event_kindle_email_edits": "{dsn} send to email address was changed from {original_email} to {new_email}",
    "contact_event_kindle_full_refund": "CS cancelled Kindle Unlimited subscription {subscriptionid} with full refund",
    "contact_event_kindle_full_refund_static": "CS cancelled  Kindle Unlimited subscription with full refund via Astro.",
    "contact_event_kindle_name_changed": "Device name changed from {original_name} to {new_name} on Kindle (DSN # {dsn} ).",
    "contact_event_kindle_name_changed_batched": "Kindle name changed ({count}).",
    "contact_event_kindle_no_refund": "CS cancelled Kindle Unlimited subscription {subscriptionid} with no refund.",
    "contact_event_kindle_no_refund_static": "CS cancelled Kindle Unlimited subscription with no refund via Astro.",
    "contact_event_kindle_unlimited_refund": "Kindle Unlimited refunded {amount} for transaction ID {transaction_id}.",
    "contact_event_kindle_unlimited_refund_batched": "Kindle Unlimited refunded for transaction IDs ({count}).",
    "contact_event_kindle_workflow_launch": "CS launched Astro cancellation workflow for Kindle Unlimited subscription {subscriptionid}",
    "contact_event_kindle_workflow_launch_static": "CS launched Astro  Kindle Unlimited cancellation workflow.",
    "contact_event_last_page_read_deleted": "Last page read for ASIN {asin} deleted.",
    "contact_event_last_page_read_deleted_batched": "Last page read deleted ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing launched for DSN: {dsn}, kcsID: {kcsId}, customer response: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing launched for devices ({count}).",
    "contact_event_lock_dash_order": "The following ASINs for device {dsn}  has been locked: {asins}.",
    "contact_event_mp3_download_to_adrive": "Downloaded mp3 to ADrive of customer {customer_id}. ASIN: {asin}",
    "contact_event_mp3_download_to_adrive_batched": "Downloaded mp3 to ADrive ({count}).",
    "contact_event_music_auto_renew_off": "CS turned OFF auto-renewal for Music subscription {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS turned OFF auto-renewal for Music subscription via Astro.",
    "contact_event_music_full_refund": "CS cancelled Music subscription {subscriptionid} with full refund",
    "contact_event_music_full_refund_static": "CS cancelled Music subscription with full refund via Astro.",
    "contact_event_music_no_refund": "CS cancelled Music subscription {subscriptionid} with no refund.",
    "contact_event_music_no_refund_static": "CS cancelled Music subscription with no refund via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS turned OFF auto-renewal for Amazon Music Unlimited subscription {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with full refund.",
    "contact_event_music_unlimited_no_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with no refund.",
    "contact_event_music_unlimited_partial_refund": "CS cancelled Amazon Music Unlimited subscription {subscriptionid} with partial refund.",
    "contact_event_music_unlimited_workflow_launch": "CS launched Astro cancellation workflow for Amazon Music Unlimited subscription {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS launched Astro cancellation workflow for Music subscription {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS launched Astro cancellation workflow for Music unlimited.",
    "contact_event_non_dsv_refund_created": "Digital order {order_id} was refunded {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Digital orders refunded ({count}).",
    "contact_event_one_click_address_updated": "1-Click address updated.",
    "contact_event_one_click_address_updated_batched": "1-Click address updated ({count}).",
    "contact_event_one_click_disabled": "1-Click disabled.",
    "contact_event_one_click_disabled_batched": "1-Click disabled ({count}).",
    "contact_event_one_click_enabled": "1-Click enabled.",
    "contact_event_one_click_payment_updated": "1-Click payment updated.",
    "contact_event_one_click_payment_updated_batched": "1-Click payment updated ({count}).",
    "contact_event_order_cancelled": "Items cancelled in order {order_id}.",
    "contact_event_order_cancelled_batched": "Cancelled orders ({count}).",
    "contact_event_order_placed": "Placed order {order_id}.",
    "contact_event_order_placed_batched": "Placed orders ({count}).",
    "contact_event_payment_method_added": "Payment method {payment_type} added to account.",
    "contact_event_payment_method_added_batched": "Payment methods added to account ({count}).",
    "contact_event_payment_method_changed": "Payment method changed for order {order_id}.",
    "contact_event_payment_method_changed_batched": "Payment method changed for orders ({count}).",
    "contact_event_pdocs_max_price_edits": "Personal  documents settings max conversion charge changed to {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Personal documents settings max conversion charge updated ({count}).",
    "contact_event_pennywise_promo_applied": "Pennywise Promotion applied for DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Pennywise Promotion applied for DSNs: ({count})",
    "contact_event_ping_device": "Device with DSN {dsn} pinged.",
    "contact_event_ping_device_batched": "Devices pinged ({count}).",
    "contact_event_prime_auto_renew_settings": "Prime auto-renew set to {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Prime auto-renew set to {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Successfully canceled Prime from PrimeCancelException. Cancel reason: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime cancelled.",
    "contact_event_prime_cancelled_batched": "Prime cancelled ({count}).",
    "contact_event_prime_concession": "Prime concession issued for order {order_id}.",
    "contact_event_prime_concession_batched": "Prime concessions issued for orders ({count}).",
    "contact_event_prime_refund": "Prime refunded {amount}.",
    "contact_event_prime_refund_batched": "Prime refunded ({count}).",
    "contact_event_prime_revoke_and_transfer_met": "Transfered prime membership extension token",
    "contact_event_prime_revoke_and_transfer_met_batched": "Transfered prime membership extension token",
    "contact_event_prime_revoke_met_no_transfer": "Revoke of prime membership token is succesful but transfer failed",
    "contact_event_prime_revoke_met_no_transfer_batched": "Revoke of prime membership token is succesful but transfer failed",
    "contact_event_prime_transfer_met": "Transferred Prime Membership extension Token",
    "contact_event_prime_transfer_met_batched": "Transferred Prime Membership extension Token",
    "contact_event_promo_added_to_account": "{amount} promo added to account for {reason}.",
    "contact_event_promo_added_to_account_batched": "Promos added to account ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} promo unredeemed.",
    "contact_event_promo_unredeemed_batched": "Promos unredeemed ({count}).",
    "contact_event_refund_cancelled": "Refund cancelled for order {order_id}.",
    "contact_event_refund_created": "Refunded {refund_amount} for order {order_id}.",
    "contact_event_refund_created_batched": "Refunded orders ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} refunded from PrimeCancelException. Refund reason: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Refund Prime Cancel  Exception ({count}).",
    "contact_event_refund_in_digital_library": "Refund a digital item for customer {customerId}. Reason: {reason}; OrderID : {orderId};",
    "contact_event_register_kindle": "Registered Kindle {serial_number}.",
    "contact_event_register_kindle_batched": "Registered Kindles ({count}).",
    "contact_event_registry_deleted": "Registry Deleted  (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Registries Deleted ({count}).",
    "contact_event_release_aiv_license": "License of AIV {asin} has been released on device DSN {dsn}.",
    "contact_event_release_licenses": "Release licenses on DSN {dsn} with device type {device_type} for customer {customer_id}.",
    "contact_event_release_licenses_batched": "Release licenses on DSNs ({count}).",
    "contact_event_remote_factory_reset": "Executed Remote Factory Reset on DSN {dsn}",
    "contact_event_remote_factory_reset_batched": "Executed Remote Factory Reset on DSNs ({count}).",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} items removed from device with DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Removed items from devices ({count}).",
    "contact_event_remove_from_yvl": "ASIN {asin} has been removed from Your Video Library into Deleted Items.",
    "contact_event_remove_kindle_from_blacklist": "Kindle with DSN {dsn} removed from lost or stolen status, reason: {removing_reason}.",
    "contact_event_remove_kindle_from_blacklist_batched": "Removed devices from lost or stolen status ({count}).",
    "contact_event_replacement_created": "Replacement created for order {old_order_id}. New order: {new_order_id}.",
    "contact_event_replacement_created_batched": "Replacements created for orders ({count}).",
    "contact_event_report_seller": "Seller Reported for Order {order_id}.",
    "contact_event_report_seller_batched": "Seller Reported for Orders ({count}).",
    "contact_event_request_invoice": "Invoice Request Sent to Seller for Order {order_id}.",
    "contact_event_request_invoice_batched": "Invoice request sent for orders ({count}).",
    "contact_event_resend_kindle_content": "Resent digital content {title} to DSN {dsn} with device type {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Resent digital content ({count}).",
    "contact_event_resent_gifted_content": "ASIN {asin} for Order ID {order_id} Recipient Email was changed.",
    "contact_event_resent_gifted_content_batched": "Recipient email for gift order updated ({count}).",
    "contact_event_reset_aiv_pin": "Reset AIV pin for customer {customer_id}.",
    "contact_event_reset_aiv_pin_batched": "Reset AIV pin ({count}).",
    "contact_event_reset_kindle_first_allowance": "Reset Amazon First Reads Allowance for customer {customer_id}.",
    "contact_event_reset_kindle_first_allowance_batched": "Reset Amazon First Reads Allowance for customer ({count}).",
    "contact_event_reset_password_email_sent": "Reset password email sent.",
    "contact_event_reset_password_email_sent_batched": "Reset password emails sent({count}).",
    "contact_event_reset_prime_lending_library_limit": "Reset prime lending library limit to {quota} for customer {customer_id}.",
    "contact_event_reset_prime_lending_library_limit_batched": "Reset prime lending library limit ({count}).",
    "contact_event_residence_address_changed": "Address of residence changed.",
    "contact_event_residence_address_changed_batched": "Address of residence updated ({count}).",
    "contact_event_restore_content": "Restore content ASIN {asin} in OrderID {order_id} for customer {customer_id}.",
    "contact_event_restore_rental": "Restored Rental with ASIN {asin} for customer {customer_id}.",
    "contact_event_restore_video": "Resotore video of ASIN {asin} for customer {customer_id}.",
    "contact_event_retrocharge_disarmed": "RMA Disarmed for OrderID {order_id} - ASIN {asin}. Reason for Disarm: {disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "RMA cancelled for order {order_id}.",
    "contact_event_rma_created": "RMA created for order {order_id}.",
    "contact_event_rma_created_batched": "RMAs created for orders ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_rosetta_transparency": "#RosettaTransparency{treatment}",
    "contact_event_rosetta_transparency_batched": "#RosettaTransparency({count})",
    "contact_event_rosetta_transparency_c": "#RosettaTransparencyControl",
    "contact_event_rosetta_transparency_t1": "#RosettaTransparencyT1",
    "contact_event_rosetta_transparency_t2": "#RosettaTransparencyT2",
    "contact_event_schedule_remote_factory_reset": "Scheduled Remote Factory Reset on DSN {dsn}",
    "contact_event_schedule_remote_factory_reset_batched": "Scheduled Remote Factory Reset on DSNs ({count}).",
    "contact_event_sds_assigned_package_to_driver": "Tracking ID {trackingId} added to itinerary.",
    "contact_event_sds_assigned_package_to_driver_batched": "Tracking ID {trackingId} added to itinerary.",
    "contact_event_sds_demo_contact_event_string": "For testing.",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP Name: {driverName}, \nStation Name: {stationName}, \nTracking ID: {trackingId} and \nOrder ID: {orderId}\nUsecaseSession ID: {usecaseSessionID}",
    "contact_event_sds_dlp_loaded_for_tracking_order_id": "Tracking ID {trackingId} and Order ID {orderId} loaded.",
    "contact_event_sds_dlp_loaded_for_tracking_order_id_batched": "Tracking ID {trackingId} and Order ID {orderId} loaded.",
    "contact_event_sds_map-widget-opened": "Map widget opened for Tracking ID {trackingId}",
    "contact_event_sds_package_action_status_update": "Tracking ID {trackingId} status changed to {state}",
    "contact_event_sds_package_repick": "Associate initiated Re-Pick for tracking ID {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} has been sent to Your Video Library of customer {customer_id} .",
    "contact_event_ship_charge_reduce": "Order {order_id} shipping charge changed from {old_amount} to {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Changed shipping charges ({count}).",
    "contact_event_ship_method_upgraded": "Shipping method for order {order_id} updated.",
    "contact_event_ship_method_upgraded_batched": "Shipping methods changed for orders ({count}).",
    "contact_event_shipping_address_changed": "Address changed on order {new_order_id}.",
    "contact_event_sim_ticket_created": "SIM-ticket created",
    "contact_event_special_offers_cancel": "Cancelled special offers on DSN {dsn} with device type {device_type} for customer {customer_id}.",
    "contact_event_special_offers_enrollment": "Special offers enrollment on Kindle (DSN # {dsn} ) updated. Enrollment status is {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Updated special offers enrollment status ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Subscribe and Save cancelled for subscription {subscription_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "Subscribe and Save cancelled for subscriptions ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Changed Subscribe and Save shipping schedule for subscription {subscription_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Changed Subscribe and Save shipping schedules for subscriptions ({count}).",
    "contact_event_subscription_auto_renew": "Auto-renew for subscription {plan_type}  set to {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Updated Prime auto-renew settings ({count}).",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} subscription set to renew to {new_plan_name} from {old_plan_name} at the end of current period.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Updated Prime rollover plan settings ({count}).",
    "contact_event_troubleshooting_wizard": "Troubleshooting wizard invoked for device with DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Troubleshooting wizard invoked for devices ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} for device with DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Troubleshooting wizard invoked for devices ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Troubleshooting wizard invoked for digital order with ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "International purchase status changed from blocked to {new_status}",
    "contact_event_unblock_international_purchase_batched": "Updated status of international purchases ({count}).",
    "contact_event_unlink_good_reads_account": "Unlink good reads account for customer {customer_id}.",
    "contact_event_unlink_kindle_account": "Unlink kindle accounts for customer {customer_id}.",
    "contact_event_unlock_dash_order": "The following ASINs for device {dsn}  has been unlocked: {asins}.",
    "contact_event_update_locking_status": "Locking status of Oft device  {dsn} has been updated to: {newLockingStatus}",
    "contact_event_verify_email": "Verification email sent to the customer.",
    "contact_event_verify_email_email_sent": "Email verification sent.",
    "contact_event_verify_email_email_sent_batched": "Email verification sent({count}).",
    "contact_event_weekend_delivery_preference_changed": "Weekend Delivery Preference Updated",
    "contact_event_weekend_delivery_preference_changed_batched": "Weekend Delivery Preference Updated{order_id}",
    // CSALT Layer Error Messages
    "csalt_error_internal_error": "Failed to lock contact",
    "csalt_error_invalid_arguments": "Failed to lock a contact due to invalid arguments",
    "csalt_error_lock_contacts_dependency_error": "Failed to lock contact due to dependency error",
    "csalt_error_contact_not_found" : "Failed operation because the contact was not found",
    "csalt_error_contact_already_resolved": "Failed to transfer the Contact because it is already resolved.",
    "csalt_error_invalid_queue": "Failed to transfer the Contact because the selected Queue is invalid.",
    "csalt_error_locked_to_other_user": "Failed operation because Contact is locked to a different Agent.",
    "csalt_error_queue_not_found": "Failed to transfer the Contact because the Queue was not found in the backend service.",
    "csalt_error_unknown": "The operation failed due to an unexpected error.",
    "csalt_error_invalid_input": "The operation failed due to invalid inputs.",
    "csalt_error_contact_invalid_state": "The operation failed because the contact is in an invalid state."
};