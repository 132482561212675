import {JwtToken} from "@amzn/csphoenix-react-client";
import {longForm, Purpose} from "../contact/Contact.types";

export class EntityId {
    constructor(public type: string, public id: string) {}

    static createFromEid(eid: string): EntityId {
        const parts: string[] = eid.split(
            ':'
        );
        return new EntityId(parts[1], eid.substring(5 + parts[1].length));
    }
}

export interface Authorization {
    t: string;
    id: string;
    p: string;
    e: string;
    ttl: number;
}

interface MatchResult {
    matches: boolean;
    newerToken?: AuthorizerJwtToken;
}

// https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays/39000004#39000004
// [].concat.apply([], array) does not compile so just do it more manual here
/* eslint-disable @typescript-eslint/no-explicit-any */
function flattenArray(input: any[][]): any[] {
    let result: any[] = [];
    input.forEach((part) => result = result.concat(part));
    return result;
}

export function extractEntityIds(purpose: Purpose, tokens: AuthorizerJwtToken[]): EntityId[] {
    const auths2d: Authorization[][] = tokens.map((token) => token.getAuth());
    const auths: Authorization[] = flattenArray(auths2d);
    const currentTimeSeconds = new Date().getTime()/1000;
    const result: EntityId[] = auths.filter((a) => a.p === longForm(purpose))
        .filter((a) => a.ttl > currentTimeSeconds)
        .map(a => EntityId.createFromEid(a.id));
    return result;
}

export function mergeTokens(tokens: AuthorizerJwtToken[], currentTokens: AuthorizerJwtToken[]): AuthorizerJwtToken[] {
    const result: AuthorizerJwtToken[] = [];
    currentTokens.forEach(token => result.push(token));
    tokens.forEach(token => {
        let addToken = true;
        result.forEach((rtoken: AuthorizerJwtToken, index: number) => {
            const matchResult: MatchResult = token.contentMatches(rtoken);
            if (matchResult.matches) {
                addToken = false;
                if (matchResult.newerToken) {
                    result[index] = matchResult.newerToken;
                }
            }
        });
        if (addToken) {
            result.push(token);
        }
    });
    return result;
}

export class AuthorizerJwtToken extends JwtToken {
    getAuth(): Authorization[] {
        return this.getContent()['auth'];
    }

    /**
     * Helper function to avoid token duplication
     * @param token
     */
    contentMatches(token: AuthorizerJwtToken): MatchResult {
        const leftContent: Authorization[] = this.getAuth();
        const rightContent: Authorization[] = token.getAuth();
        if (leftContent.length === 1 && rightContent.length === 1) {
            const left: Authorization = leftContent[0];
            const right: Authorization = rightContent[0];
            if (left.t === right.t
                && left.id === right.id
                && left.p === right.p
                && left.e === right.e) {
                return {matches: true, newerToken: left.ttl > right.ttl ? this : token};
            }
        }
        return {matches: false};
    }
}
