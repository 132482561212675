import React from "react";
import {Contact} from "../Contact.types";
import ContactLink from "../ContactLink";
import Translation from "../../i18n/Translate";
import EmdashImage from "../../util/EmdashImage";
import IntlManager from "../../i18n/IntlManager";

interface ChildContactDisplayProps {
    contact: Contact;
}

const ChildContactDisplay: React.FC<ChildContactDisplayProps> = ({contact}) => {
    return (
        <div>
            <ContactLink contactId={contact.contactId} />
        </div>
    );
};

interface ChildContactsDisplayProps {
    childContacts: Contact[] | undefined;
}

const ChildContactsDisplay: React.FC<ChildContactsDisplayProps> = ({childContacts}) => {

    return (
        <div>
            <h4 className="contact-details-subheader"><Translation stringId={"CHILD_WORK_REQUESTS"}/></h4>
            {childContacts && childContacts.length > 0 ?
                <div>{childContacts.map((childContact) => <ChildContactDisplay contact={childContact}/>)}</div>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_CHILD_WORK_REQUESTS")}/>}
        </div>
    );
};

export default ChildContactsDisplay;
