import React from 'react';
import Box from "@amzn/awsui-components-react/polaris/box";
import IntlManager from "../i18n/IntlManager";
import EmdashImage from "../util/EmdashImage";
import Translation from "../i18n/Translate";
import {processHtml} from "../util/Text";

interface EmailBodyWithTranslationProps {
    htmlSafePreTranslationCSAText: string;
    htmlSafeEmailBody: string | undefined;
}

const EmailBodyWithTranslation: React.FC<EmailBodyWithTranslationProps> = ({htmlSafePreTranslationCSAText, htmlSafeEmailBody}) => {
    return (
        <div>

            <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="SOURCE_TEXT"/></Box>
            <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: processHtml(htmlSafePreTranslationCSAText)}}/>

            <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="EMAIL_BODY"/>
                &nbsp;(<Translation stringId="TRANSLATED_TEXT"/>)</Box>
            {htmlSafeEmailBody ?
                <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: processHtml(htmlSafeEmailBody)}}/>
                : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_EMAIL_BODY")}/>
            }
        </div>
    );
};

export default EmailBodyWithTranslation;
