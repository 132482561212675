export const it_IT = {
    "contact_event_GC_added": "Buono regalo #{gcId} aggiunto all'account.",
    "contact_event_GC_added_batched": "Buono regalo aggiunto all'account ({count}).",
    "contact_event_GC_added_purchaser": "Buono regalo #{gcId} aggiunto all'account dell'acquirente.",
    "contact_event_GC_added_purchaser_batched": "Buono regalo aggiunto all'account dell'acquirente ({count}).",
    "contact_event_GC_added_recipient": "Buono regalo #{gcId} aggiunto all'account del destinatario.",
    "contact_event_GC_added_recipient_batched": "Buono regalo aggiunto all'account del destinatario ({count}).",
    "contact_event_GC_added_scratchedCode": "Buono regalo # {gcId} aggiunto all'account tramite lo strumento del codice riscattato grattato.",
    "contact_event_GC_added_scratchedCode_batched": "Buono regalo aggiunto all'account tramite lo strumento del codice riscattato grattato ({count}).",
    "contact_event_GC_cancelled": "Buono regalo #{gcId} annullato.",
    "contact_event_GC_cancelled_batched": "Buono regalo annullato ({count}).",
    "contact_event_GC_changed_expiry": "Data di scadenza del buono regalo #{gcId} modificata.",
    "contact_event_GC_changed_expiry_batched": "Data di scadenza del buono regalo modificata ({count}).",
    "contact_event_GC_locked": "Buono regalo #{gcId} bloccato.",
    "contact_event_GC_locked_batched": "Buono regalo bloccato ({count}).",
    "contact_event_GC_removed": "Buono regalo #{gcId} rimosso dall'account.",
    "contact_event_GC_removed_batched": "Buono regalo rimosso dall'account ({count}).",
    "contact_event_GC_resent_email": "Buono regalo #{gcId} rispedito all'ID dell'e-mail {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "Buono regalo rispedito all'ID dell'e-mail ({count}).",
    "contact_event_GC_resent_post": "Buono regalo #{gcId} rispedito tramite posta.",
    "contact_event_GC_resent_post_batched": "Buono regalo rispedito per posta ({count}).",
    "contact_event_GC_temporary_locked": "Buono regalo #{gcId} temporaneamente bloccato.",
    "contact_event_GC_temporary_locked_batched": "Buono regalo temporaneamente bloccato ({count}).",
    "contact_event_GC_transferred": "Buono regalo {gcId} trasferito dal cliente {customerID} a {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "Buono regalo trasferito ({count}).",
    "contact_event_GC_unlocked": "Buono regalo #{gcId} sbloccato.",
    "contact_event_GC_unlocked_batched": "Buono regalo sbloccato ({count}).",
    "contact_event_PV_added": "PV # {pvId} aggiunto all'account.",
    "contact_event_PV_locked": "PV #{pvId} bloccato.",
    "contact_event_PV_temporary_locked": "PV #{pvId} temporaneamente bloccato.",
    "contact_event_PV_unlocked": "PV #{pvId} sbloccato.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Richiesta di garanzia dalla A alla Z annullate per gli ordini ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Richiesta di Garanzia dalla A alla Z inviata per l'ordine {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Richiesta di garanzia dalla A alla Z inviate per gli ordini ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Garanzia dalla A alla Z inviata per l'ordine {order_id}.",
    "contact_event_access_code_added": "Codice di accesso aggiunto",
    "contact_event_account_closed": "Account chiuso.",
    "contact_event_account_closed_batched": "Account chiusi ({count}).",
    "contact_event_account_reopened": "Account riaperto",
    "contact_event_account_reopened_batched": "Conti riaperti ({count})",
    "contact_event_account_temporarily_disabled": "Account temporaneamente disabilitato.",
    "contact_event_account_temporarily_disabled_batched": "Account temporaneamente disabilitato ({count})",
    "contact_event_account_whitelisted": "Account aggiunto alla whitelist.",
    "contact_event_address_changed": "Indirizzo {address_type} aggiornato per l'ordine {order_id}.",
    "contact_event_address_changed_batched": "Indirizzi aggiornati per gli ordini ({count}).",
    "contact_event_address_deactivated": "Indirizzo disattivato.",
    "contact_event_address_deactivated_batched": "Indirizzo disattivato ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Sottoscrizione ad Amazon Video rinnovata automaticamente",
    "contact_event_amazon_video_subscription_cancelled": "Sottoscrizione ad Amazon Video annullata",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Modulo inviato: Modulo di inoltro DSL\nId sessione caso d’uso: {usecaseSessionID}\nStazione di consegna: {deliveryStationField}\nTipo di richiesta: {requestTypeField}\nID contatto: {contactIdField}\nOrder ID: {orderIdField}\nIndirizzo e-mail del cliente: {customerEmailField}\nNumero di telefono del cliente: {customerPhoneNumberField}\nTBA: {tbasField}\nCodice di accesso (se applicabile): {accessCodeField}\nRiepilogo del problema: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Moduli di inoltro DSL inviati: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Modulo inviato: modulo di commenti DA DSP\nId sessione caso d’uso: {usecaseSessionID}\nCodice stazione di consegna: {deliveryStationField}\nID contatto: {contactIdField}\nID account cliente: {customerIdField}\nID trasportatore DA: {driverIdField}\nData incidente (AAAA-MM-GG): {dateOfOccurrenceField}\nOra incidente (HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nFonte dei commenti: {sourceOfFeedbackField}\nDettagli dei commenti: {issueField}\nDettagli aggiuntivi: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Moduli di commenti DA DSP inviati: ({count})",
    "contact_event_andon_cord_pulled": "Andon cord tirato per {asin}.",
    "contact_event_andon_cord_pulled_batched": "Andon cord estratto per gli articoli ({count}).",
    "contact_event_apply_adrive_music_trial": "informazioni Cloud Player richieste per il cliente {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Account trasferite a {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Account Audible trasferiti ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "{title} è stato rimborsato per un importo di {cashAmount}. Inoltre, questa transizione ha riemesso un buono di {couponAmount} nell’account.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} è stato rimborsato per un importo di {cashAmount}. Inoltre, questa transizione ha riemesso un buono di {couponAmount} nell’account.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} è stato rimborsato per un importo di {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} è stato rimborsato per un importo di {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} è stato rimborsato ed è stato riemesso un buono di {couponAmount} nell’account.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} è stato rimborsato ed è stato riemesso un buono di {couponAmount} nell’account.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} credito/i rimosso/i dall'account.",
    "contact_event_audible_credit_issued": "{numCredit} crediti aggiunti all’account.",
    "contact_event_audible_credit_issued_batched": "Crediti aggiunti all'account ({count}).",
    "contact_event_audible_credit_refund": "{title} è stato rimborsato e sono stati rimborsati {creditAmount} crediti nell’account.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} è stato rimborsato e sono stati rimborsati {creditAmount} crediti nell’account.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} credito/i ripristinato/i nell’account.",
    "contact_event_audible_credit_restored_batched": "{amount} credito/i ripristinato/i nell’account.({count})",
    "contact_event_audible_extra_credit_purchased": "Sono stati acquistati 3 crediti aggiuntivi.",
    "contact_event_audible_extra_credit_purchased_batched": "Sono stati acquistati 3 crediti aggiuntivi. ({count})",
    "contact_event_audible_hiatus_cancelled": "Il periodo di interruzione è stato cessato in anticipo e l’iscrizione è stata ripristinata.",
    "contact_event_audible_hiatus_cancelled_batched": "Il periodo di interruzione è stato cessato in anticipo e l’iscrizione è stata ripristinata. ({count})",
    "contact_event_audible_hiatus_started": "L'iscrizione è interrotta per un periodo di {duration} mesi.",
    "contact_event_audible_hiatus_started_batched": "L'iscrizione è interrotta per un periodo di {duration} mesi. ({count})",
    "contact_event_audible_membership_enrollment": "Iscritto al \npiano di abbonamento {membershipPlan}.",
    "contact_event_audible_membership_enrollment_batched": "Iscritto ai piani di abbonamento Audible ({count}).",
    "contact_event_audible_membership_plan_refund": "Tariffa di abbonamento rimborsata per l'importo di {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Tariffa di abbonamento rimborsata per l'importo di {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Piano di abbonamento modificato da {oldMembershipPlan} in {newMembershipPlan}.",
    "contact_event_audible_membership_switched_batched": "Piani di abbonamento Audible modificati ({count}).",
    "contact_event_audible_romance_package_enrollment": "Iscritto a {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Iscritto a {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "Accesso a {wizardName} effettuato.",
    "contact_event_audible_wizard_invoked_batched": "Accesso a {wizardName} effettuato.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "L {membership} abbonamento è stato cancellato con successo.",
    "contact_event_blacklist_kindle": "Dispositivo {serial_number} in lista nera.",
    "contact_event_blacklist_kindle_batched": "Kindle in lista nera ({count}).",
    "contact_event_bound_warranty": "Numero di garanzia {warranty_id} collegata al dispositivo {dsn}.",
    "contact_event_bound_warranty_batched": "Garanzie legate ai dispositivi ({count}).",
    "contact_event_call_was_flagged": "Questo contatto è stato contrassegnato come {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Questo contatto è stato contrassegnato come {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "L {membership} abbonamento è stato cancellato con successo.",
    "contact_event_cancel_audible_membership_batched": "L {membership} abbonamento è stato cancellato con successo.({count}).",
    "contact_event_cancel_audible_romance_package_batched": "L {membership} abbonamento è stato cancellato con successo.({count}).",
    "contact_event_cancel_from_prime_cancel_exception": "Prima annullato con successo da PrimeCancelException. Motivo annullamento: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Eccezione annullamento per Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "La data futura di cancellazione precedentemente configurata è stata cancellata",
    "contact_event_cancelled_sns_reactivated": "L'ASIN {asin} per l'abbonamento riattivato il {reactivation_date} (mese) da parte di {login}",
    "contact_event_cancelled_sns_reactivated_batched": "Abbonamenti Iscriviti e salva annullati e riattivati ({count}).",
    "contact_event_captcha_bypass_key_generated": "Codice di accesso Captcha generato.",
    "contact_event_captcha_bypass_key_generated_batched": "Codice di accesso Captcha generato ({count}).",
    "contact_event_change_sns_quantity": "La vecchia quantità {current_quantity} impostata per l'ASIN {asin} per la funzione Iscriviti e salva è stata impostata nella nuova quantità {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Quantità Iscriviti e salva aggiornate ({count}).",
    "contact_event_channels_auto_renew_off": "CS ha disattivato il rinnovo automatico per l'abbonamento ai canali PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS ha disattivato il rinnovo automatico tramite Astro.",
    "contact_event_channels_full_refund": "CS ha annullato l’abbonamento ai canali PV {subscriptionid} con rimborso completo.",
    "contact_event_channels_full_refund_static": "CS ha annullato l’abbonamento con rimborso totale tramite Astro.",
    "contact_event_channels_no_refund": "CS ha annullato l’abbonamento ai canali PV {subscriptionid} senza rimborso.",
    "contact_event_channels_no_refund_static": "CS annullato l'abbonamento senza rimborso tramite Astro.",
    "contact_event_channels_workflow_launch": "CS ha avviato il flusso di lavoro di cancellazione Astro per abbonamento ai calali PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS ha avviato il flusso di lavoro di cancellazione Astro.",
    "contact_event_coins_adjust": "Tipo di correzione: {type} <br />\nMotivo: {reason} <br />\nImporto: {amount} <br />\nCommenti: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo coin corretto ({count}).",
    "contact_event_concessions_abuse": "HMD non è stato inviato perché il cliente è un utente {risk_status}.",
    "contact_event_contact_seller": "Venditore contattato per l'ordine {order_id}.",
    "contact_event_contact_seller_batched": "Venditore contattato per gli ordini ({count}).",
    "contact_event_contact_was_initiated_from": "Il contatto è stato avviato da ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "Contenuti ASIN {asin} aggiornati nella biblioteca Kindle.",
    "contact_event_content_update_batched": "Contenuti aggiornati nella libreria Kindle ({count}).",
    "contact_event_copilot_started": "Co-pilota inizializzato",
    "contact_event_copilot_started_batched": "Co-pilota inizializzato ({count})",
    "contact_event_credit_card_disabled": "CC disattivata.",
    "contact_event_credit_card_disabled_batched": "Carte di credito disattivate ({count}).",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Ignora la richiesta di recinto virtuale inviata per il numero di spedizione {trackingId} Motivo: {overrideReason} Pagina: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Ignora la richiesta del perimetro geografico inviata per il numero di spedizione {trackingId} Motivo: {overrideReason} Pagina: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocodice aggiornato. Tipo di geocodice : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocodice aggiornato. Tipo di geocodice : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} e pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "autenticazione cliente andata a buon fine",
    "contact_event_customer_authentication_passed_batched": "autenticazione cliente andata a buon fine ({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Nome cliente aggiornato.",
    "contact_event_customer_name_updated_batched": "Nome cliente aggiornato ({count}).",
    "contact_event_customer_password_changed": "Password cliente modificata.",
    "contact_event_customer_password_changed_batched": "Password cliente modificata ({count}).",
    "contact_event_delete_alexa_voice_profile": "Profilo vocale Alexa eliminato per speakerId {speakerId}, customerID {customerID} e stato {status}",
    "contact_event_delete_data_alexa_comms": "Profilo Comunicazioni Alexa eliminato per commsID {commsId}, customerID {customerID} e stato {status}",
    "contact_event_deprovision_alexa_comms": "Annullato il provisioning del profilo Comunicazioni Alexa per ID comunicazioni: {commsId}, ID cliente: {customerID}  e stato: {status}",
    "contact_event_deregister_kindle": "Annullata registrazione Kindle {serial_number}.",
    "contact_event_deregister_kindle_batched": "Registrazioni Kindle annullate ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Procedura guidata di risoluzione dei problemi richiesta per il dispositivo con DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "Non è stato possibile disattivare la funzione Mayday per il dispositivo DSN {dsn}.",
    "contact_event_disable_mayday_success": "La funzione Mayday per il dispositivo DSN {dsn} è stata disattivata correttamente.",
    "contact_event_dunning_pause": "Processo sollecito interrotto: per ulteriori informazioni, visualizza la sezione Pagamenti e transazioni",
    "contact_event_dvbypost_extra_dispatch": "{amount} spedizioni aggiuntive richieste per questo cliente.",
    "contact_event_dvbypost_extra_envelope": "Per questo cliente è stata attivata una busta aggiuntiva.",
    "contact_event_dvbypost_fsk18_form_request": "Modulo FSK18 angefordert",
    "contact_event_dvbypost_problem_code_change": "Impostazione del disco {disc_id} su {problem_code}",
    "contact_event_email_address_updated": "Indirizzo e-mail aggiornato.",
    "contact_event_email_address_updated_batched": "Indirizzo e-mail aggiornato ({count}).",
    "contact_event_email_preferences_changed": "Preferenze di notifica e-mail modificate.",
    "contact_event_email_unsubscribe": "E-mail di notifica disattivata.",
    "contact_event_email_unsubscribe_batched": "Iscrizione e-mail annullata ({count}).",
    "contact_event_enable_mayday_failed": "Non è stato possibile riattivare la funzione Mayday per il dispositivo DSN {dsn}.",
    "contact_event_enable_mayday_success": "La funzione Mayday per il dispositivo DSN {dsn} è stata riattivata correttamente.",
    "contact_event_first_time_user_experience_set": "Imposta prima esperienza utente",
    "contact_event_first_time_user_experience_set_batched": "Imposta prima esperienza utente({count}).",
    "contact_event_form_submitted": "Modulo {form_name} inviato.",
    "contact_event_form_submitted_batched": "Moduli inviati ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Iscrizione a Free Time Unlimited annullata per il cliente: {customerId}, DSN: {deviceSerialNumber} da {agentId} con il seguente motivo: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Iscrizione a Free Time Unlimited annullata ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Periodo di prova gratuito Prime cacellato con esito positivo {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Prime annullato con successo ({count}).",
    "contact_event_future_cancel_audible_membership": "La data di cancellazione dell' {membership} abbonamento è stata fissata per il {date}.",
    "contact_event_future_cancel_audible_membership_batched": "La data di cancellazione dell' {membership} abbonamento è stata fissata per il {date}.({count})",
    "contact_event_hard_delete_from_yvl": "L'ASIN {asin} è stato rimosso in modo permanente dalla tua libreria dei video.",
    "contact_event_header": "Log eventi",
    "contact_event_hmd_suppression": "HMD non è stato inviato per il seguente motivo: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Chat inattiva disconnessa",
    "contact_event_idle_chat_disconnect_batched": "Chat inattiva disconnessa ({count})",
    "contact_event_item_price_reduced": "Prezzo per {asin} modificato da {old_price} a {new_price}.",
    "contact_event_item_price_reduced_batched": "Prezzi articoli modificati per gli ordini ({count}).",
    "contact_event_item_quantity_changed": "Quantità articolo modificata nell'ordine {order_id}.",
    "contact_event_item_quantity_changed_batched": "Quantità articoli modificati per gli ordini ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Sostituzione creata per DSN {dsn}  dall'ordine {old_order_id} .Nuovo ordine: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Dispositivi Kindle sostituiti ({count}).",
    "contact_event_kindle_email_address_edits": "Indirizzo e-mail aggiornato sul dispositivo Kindle (DSN # {dsn} ).",
    "contact_event_kindle_email_address_edits_batched": "Indirizzi e-mail aggiornati per i dispositivi Kindle ({count}).",
    "contact_event_kindle_email_edits": "{dsn} l'indirizzo email del destinatario è stato modificato da {original_email} in {new_email}",
    "contact_event_kindle_name_changed": "Kindle con nome DSN {dsn} modificato da {original_name} in {new_name}.",
    "contact_event_kindle_name_changed_batched": "Nome dispositivo Kindle modificato ({count}).",
    "contact_event_last_page_read_deleted": "Ultima pagina letta per l'ASIN {asin} rimosso.",
    "contact_event_last_page_read_deleted_batched": "Ultima pagina letta cancellata ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing lanciato per DSN: {dsn}, kcsID: {kcsId}, risposta cliente: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing lanciato per i dispositivi ({count}).",
    "contact_event_lock_dash_order": "I seguenti ASIN relativi al dispositivo {dsn} sono stati bloccati: {asins}.",
    "contact_event_music_auto_renew_off": "CS ha disattivato il rinnovo automatico per l'abbonamento Music {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS ha disattivato il rinnovo automatico per l'abbonamento Music tramite Astro.",
    "contact_event_music_full_refund": "CS ha anullato l’abbonamento Music {subscriptionid} con rimborso totale",
    "contact_event_music_full_refund_static": "CS ha annullato l’abbonamento Music con rimborso totale tramite Astro.",
    "contact_event_music_no_refund": "CS ha annullato l'abbonamento Music {subscriptionid} senza rimborso.",
    "contact_event_music_no_refund_static": "CS annullato l'abbonamento Music senza rimborso tramite Astro.",
    "contact_event_music_unlimited_auto_renew_off": "Il Servizio Clienti ha disattivato il rinnovo automatico per l'abbonamento ad Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "Il Servizio Clienti ha annullato l'abbonamento ad Amazon Music Unlimited {subscriptionid} con rimborso completo.",
    "contact_event_music_unlimited_no_refund": "Il Servizio Clienti ha annullato l'abbonamento ad Amazon Music Unlimited {subscriptionid} senza rimborso.",
    "contact_event_music_unlimited_partial_refund": "Il Servizio Clienti ha annullato l'abbonamento ad Amazon Music Unlimited {subscriptionid} con rimborso parziale.",
    "contact_event_music_unlimited_workflow_launch": "Il Servizio Clienti ha lanciato il processo di annullamento Astro per l'abbonamento ad Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS ha lanciato il flusso di lavoro di cancellazione Astro per l'abbonamento Music{subscriptionid}.",
    "contact_event_music_workflow_launch_static": "CS ha lanciato il flusso di lavoro di cancellazione Astro per Music unlimited.",
    "contact_event_non_dsv_refund_created": "L'ordine digitale {order_id} è stato rimborsato di {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Ordini digitali rimborsati ({count}).",
    "contact_event_one_click_address_updated": "Indirizzo 1-Click aggiornato.",
    "contact_event_one_click_address_updated_batched": "Indirizzo 1-Click aggiornato ({count}).",
    "contact_event_one_click_disabled": "1-Click disattivato.",
    "contact_event_one_click_disabled_batched": "1-Click disattivato ({count}).",
    "contact_event_one_click_enabled": "1-Click abilitato.",
    "contact_event_one_click_payment_updated": "Modalità di pagamento con 1-Click aggiornata.",
    "contact_event_one_click_payment_updated_batched": "Modalità di pagamento con 1-Click aggiornata ({count}).",
    "contact_event_order_cancelled": "Articoli annullati nell'ordine {order_id}.",
    "contact_event_order_cancelled_batched": "Ordini annullati ({count}).",
    "contact_event_order_placed": "Annullato ordine {order_id}.",
    "contact_event_order_placed_batched": "Ordini rimborsati ({count}).",
    "contact_event_payment_method_added": "Modalità di pagamento {payment_type} aggiunta all'account.",
    "contact_event_payment_method_added_batched": "Modalità di pagamento aggiunte all'account ({count}).",
    "contact_event_payment_method_changed": "Modalità di pagamento modificato per l'ordine {order_id}.",
    "contact_event_payment_method_changed_batched": "Modalità di pagamento modificato per gli ordini ({count}).",
    "contact_event_pdocs_max_price_edits": "L'ammontare di conversione massimo per le impostazioni dei documenti personali è cambiato in {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Aggiornamento addebito conversione massima impostazioni documenti personali ({count}).",
    "contact_event_pennywise_promo_applied": "Promozione Pennywise richiesta per il DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promozione Pennywise applicata per il DSN: ({count})",
    "contact_event_ping_device": "Dispositivo con DSN {dsn} testato.",
    "contact_event_ping_device_batched": "Dispositivi scambiati ({count}).",
    "contact_event_prime_auto_renew_settings": "Rinnovo automatico Prime impostato su {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Rinnovo automatico Prime impostato su {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Prima annullato con successo da PrimeCancelException. Motivo annullamento: {cancel_reason}.",
    "contact_event_prime_cancelled": "Prime annullato.",
    "contact_event_prime_cancelled_batched": "Prime annullato ({count}).",
    "contact_event_prime_concession": "Concessione Prime emessa per l'ordine {order_id}.",
    "contact_event_prime_concession_batched": "Concessioni Prime emesse per gli ordini ({count}).",
    "contact_event_prime_refund": "Rimborso Prime di {amount}.",
    "contact_event_prime_refund_batched": "Prime rimborsato ({count}).",
    "contact_event_promo_added_to_account": "Promozione di {amount} aggiunta all'account.",
    "contact_event_promo_added_to_account_batched": "Promozioni aggiunte all'account ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} promozione non riscattata.",
    "contact_event_promo_unredeemed_batched": "Promozioni non riscattate ({count}).",
    "contact_event_refund_cancelled": "Rimborso annullato per l'ordine {order_id}.",
    "contact_event_refund_created": "Rimborso di {refund_amount} per l'ordine {order_id}.",
    "contact_event_refund_created_batched": "Ordini rimborsati ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} rimborsato da PrimeCancelException. Motivo rimborso: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Eccezione annullamento Prime rimborso ({count}).",
    "contact_event_register_kindle": "Registrato Kindle {serial_number}.",
    "contact_event_register_kindle_batched": "Kindle registrati ({count}).",
    "contact_event_registry_deleted": "Registro eliminato (CodiceIdentificativoRegistro n. {registry_id}).",
    "contact_event_registry_deleted_batched": "Registri eliminati ({count}).",
    "contact_event_release_aiv_license": "La licenza dell'AIV {asin} è stata concessa sul DSN del dispositivo {dsn}.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} numero di articoli rimossi dal dispositivo con DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Articoli rimossi dai dispositivi ({count}).",
    "contact_event_remove_from_yvl": "L'ASIN {asin} è stato rimosso dalla tua libreria dei video e spostato tra gli articoli eliminati.",
    "contact_event_remove_kindle_from_blacklist": "Kindle per DSN {dsn} rimosso dallo stato \"smarrito\" o \"rubato\", motivo: {removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "Dispositivi rimossi con successo dallo stato \"smarrito\" o \"rubato\" ({count}).",
    "contact_event_replacement_created": "Sostituzione creata per l'ordine {old_order_id}. Nuovo ordine: {new_order_id}.",
    "contact_event_replacement_created_batched": "Sostituzioni create per gli ordini ({count}).",
    "contact_event_request_invoice": "Richiesta di fattura inviata al venditore per l'ordine {order_id}.",
    "contact_event_request_invoice_batched": "Richiesta di fattura inviata per gli ordini ({count}).",
    "contact_event_resend_kindle_content": "Contenuti digitali {title} inviati nuovamente a DSN {dsn} con tipo di dispositivo {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Contenuti digitali inviati nuovamente ({count}).",
    "contact_event_resent_gifted_content": "L'ASIN {asin} per l'indirizzo e-mail destinatario con numero ordine {order_id} è stato modificato.",
    "contact_event_resent_gifted_content_batched": "Destinatario e-mail per ordine regalo aggiornato ({count}).",
    "contact_event_reset_password_email_sent": "Messaggio e-mail di ripristino password inviato.",
    "contact_event_reset_password_email_sent_batched": "Messaggi e-mail di ripristino password inviati({count}).",
    "contact_event_residence_address_changed": "L'indirizzo di residenza è stato modificato.",
    "contact_event_residence_address_changed_batched": "Indirizzo di residenza aggiornato ({count}).",
    "contact_event_rma_cancelled": "RMA, autorizzazione di reso merce annullata per l'ordine {order_id}.",
    "contact_event_rma_created": "RMA, autorizzazione di reso merce creata per l'ordine {order_id}.",
    "contact_event_rma_created_batched": "RMA creati per gli ordini ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nome DP: {driverName}, \nNome station: {stationName}, \nNumero di spedizione: {trackingId} e \nID ordine: {orderId}\nID SessioneCasoUso: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widget della mappa aperto per il numero di spedizione {trackingId}",
    "contact_event_sds_package_repick": "L'associato ha avviato il nuovo ritiro per il numero di spedizione {trackingId}",
    "contact_event_send_aiv_asin": "L'ASIN {asin} è stato inviato alla libreria dei video del cliente {customer_id} .",
    "contact_event_ship_charge_reduce": "L'ammontare per la spedizione dell'ordine {order_id} è cambiato da {old_amount} a {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Spese di spedizione modificate ({$hash-get count}).",
    "contact_event_ship_method_upgraded": "Modalità di spedizione per l'ordine {order_id} aggiornata.",
    "contact_event_ship_method_upgraded_batched": "Modalità di spedizione modificate per gli ordini ({count}).",
    "contact_event_shipping_address_changed": "È stato modificato l'indirizzo dell'ordine {new_order_id}.",
    "contact_event_sim_ticket_created": "SIM - Ticket creato",
    "contact_event_special_offers_enrollment": "Kindle con offerte speciali {enrollment_status} DSN {dsn}.",
    "contact_event_special_offers_enrollment_batched": "Stato iscrizione offerte speciali aggiornato ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Servizio \"Iscriviti e salva\" annullato per l'ordine {order_id}.",
    "contact_event_subscribe_and_save_cancelled_batched": "Servizio Iscriviti e salva annullato per gli abbonamenti ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Orario di spedizione \"Iscriviti e salva\" modificato per l'ordine {order_id}.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Spedizioni prenotate per il servizio Iscriviti e salva modificate per gli abbonamenti ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS ha lanciato il processo di annullamento Astro per l'abbonamento {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_auto_renew": "{plan_type} rinnovo automatico impostato su {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Impostazioni di rinnovo automatico Prime aggiornate ({count}).",
    "contact_event_subscription_auto_renew_off": "CS ha disattivato il rinnovo automatico per l'abbonamento {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS ha annullato l'abbonamento {subscriptionType} {subscriptionId} con rimborso completo.",
    "contact_event_subscription_cancel_no_refund": "CS ha annullato l'abbonamento {subscriptionType} {subscriptionId} senza rimborso.",
    "contact_event_subscription_cancel_partial_refund": "CS ha annullato l'abbonamento {subscriptionType} {subscriptionId} con un rimborso parziale.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "Iscrizione {plan_type} impostata per il rinnovo di {new_plan_name} da {old_plan_name} al termine del periodo corrente.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Impostazioni piano attivazione Prime aggiornate ({count}).",
    "contact_event_troubleshooting_wizard": "Procedura guidata di risoluzione dei problemi richiesta per il dispositivo con DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Procedura guida di risoluzione problemi chiamata per i dispositivi ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} per il dispositivo con DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Procedura guida di risoluzione problemi chiamata per i dispositivi ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Procedura guidata per la risoluzione dei problemi richiamata per ordini digitali con ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "Lo stato acquisto internazionale è stato modificato da bloccato in {new_status}",
    "contact_event_unblock_international_purchase_batched": "Stato degli acquisti internazionali aggiornato ({count}).",
    "contact_event_unlock_dash_order": "I seguenti ASIN relativi al dispositivo {dsn} sono stati sbloccati: {asins}.",
    "contact_event_update_locking_status": "Lo stato di blocco del dispositivo Oft {dsn} è stato aggiornato in: {newLockingStatus}",
    "contact_event_verify_email": "E-mail di verifica inviata al cliente.",
    "contact_event_weekend_delivery_preference_changed": "Preferenza per la consegna nel fine settimana aggiornata"
}