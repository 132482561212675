import React, {useEffect, useState, useContext} from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {i18n} from "../../i18n/IntlManager";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {InputProps} from "@amzn/awsui-components-react/polaris/input";
import Autosuggest, {AutosuggestProps} from "@amzn/awsui-components-react/polaris/autosuggest";
import {LogLevel, Match, MatchRestrictedDataResponse} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {Contact, ContactMediumType, ContactTransferDestination, ContactTransferDestinations} from "../Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ConfirmationModal from "./ConfirmationModal";
import Translation from "../../i18n/Translate";
import {AddText, AddTextStatus} from "../AddText";
import { callPhoenix, InternalError, MissingFieldsError } from "../../issueManager/IssueManager";
import { populateOrderIds } from "../../util/OrderIdsStringBuilder";
import {IssueManagerContext} from "../../issueManager/IssueManager";

interface TransferContactFormProps {
    marketplaceId: string;
    contact: Contact;
}

// The cross marketplace queues TSS also contains cross region queues.
// Our backend does not support cross region transfers yet, so we filter this out.
// https://tiny.amazon.com/8jg96wzm
const crossRegionEntrypointPattern = new RegExp(/.*@.*@(NA|EU|FE|CN)$/);

const TransferContactForm: React.FC<TransferContactFormProps> = ({marketplaceId, contact}) => {
    const [value, setValue] = useState<string>("");
    const [loadingQueues, setLoadingQueues] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<AutosuggestProps.OptionGroup[]>([]);
    const [contactTransferDestinations, setContactTransferDestinations] = useState<ContactTransferDestinations|null>(null);
    const [contactTransferDestination, setContactTransferDestination] = useState<ContactTransferDestination|undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageBody, setMessageBody] = useState<string>("");
    const [addTextStatus, setAddTextStatus] = useState<AddTextStatus>(AddTextStatus.NONE);
    const [restrictedDataMatchList, setRestrictedDataMatchList] = useState<Match[]>([]);
  
    const { clientStateToken } = useContext(IssueManagerContext);
  
    function getQuery(): string {
        // https://tiny.amazon.com/yw4u3fe9
        if (contact.medium.type === ContactMediumType.PHONE) {
            return `query {` +
                `  contactTransferDestinations(marketplaceId: "${marketplaceId}") {` +
                `    inMarketplaceTransferDestinationList {` +
                `      emailQueue { ` +
                `        id ` +
                `        name ` +
                `      }` +
                `    }` +
                `  }` +
                `}`;
        } else {
            return `query {` +
                `  contactTransferDestinations(marketplaceId: "${marketplaceId}") {` +
                `    inMarketplaceTransferDestinationList {` +
                `      emailQueue { ` +
                `        id ` +
                `        name ` +
                `      }` +
                `    }` +
                `    crossMarketplaceTransferDestinationList {` +
                `      emailQueue { ` +
                `        id ` +
                `        name ` +
                `      }` +
                `    }` +
                `  }` +
                `}`;
        }
    }

    function toContactTransferDestinations(resultJson: string): ContactTransferDestinations {
        const result = JSON.parse(resultJson);
        if (result.data?.contactTransferDestinations) {
            const contactTransferDestinations: ContactTransferDestinations = result.data.contactTransferDestinations;
            let crossMarketDestinations = contactTransferDestinations.crossMarketplaceTransferDestinationList ?
            contactTransferDestinations.crossMarketplaceTransferDestinationList : [];
            if (contact.medium.type !== ContactMediumType.EMAIL) {
                crossMarketDestinations = crossMarketDestinations.filter(d => !isCrossRegionEntrypoint(d));
            }
            return {
                inMarketplaceTransferDestinationList: contactTransferDestinations.inMarketplaceTransferDestinationList,
                crossMarketplaceTransferDestinationList: crossMarketDestinations
            };
        }
        throw new MissingFieldsError();
    }

    function toOption(destination: ContactTransferDestination): AutosuggestProps.Option {
        return {label: destination.emailQueue.name, value: destination.emailQueue.name};
    }

    function isCrossRegionEntrypoint(destination: ContactTransferDestination): boolean {
        return crossRegionEntrypointPattern.test(destination.emailQueue.name);
    }

    function onChange(detail: InputProps.ChangeDetail): void {
        // check whether typed input is in configured destination list
        let value = detail.value;

        let destination: ContactTransferDestination|undefined = contactTransferDestinations?.crossMarketplaceTransferDestinationList
            .find(t => t.emailQueue.name === value);
        if (destination === undefined) {
            destination = contactTransferDestinations?.inMarketplaceTransferDestinationList
                .find(t => t.emailQueue.name === value);
        }
        // limit setting transfer destination when not found in list
        // to only users with a specific right
        if (destination === undefined && allowTransferByQueueName()) {
            // In backend logs we see failed transfer attempts with destinations like " claims-atoz-inquiries-cs@amazon.com"
            // So trim this field in the UI.
            value = value.trim();
            destination = {
                emailQueue: {
                    id: value,
                    name: value
                }
            };
        }
        setContactTransferDestination(destination);
        setValue(value);
    }

    function getEnteredTextLabel(): string {
        if (contactTransferDestination === undefined) {
            return i18n('TYPED_QUEUE_NAME_IS_INVALID', {values: {queue: value}});
        } else {
            return i18n('USE') + ": " + value;
        }
    }
  
    function allowTransferByQueueName(): boolean {
        return (clientStateToken?.getContent()["tqn"]);
    }

    async function onClickTransferContactRequest(): Promise<void> {
        setIsLoading(true);
      
        const response: MatchRestrictedDataResponse =
            await Phoenix.getInstance().matchRestrictedData([messageBody]);

        const messageBodyMatches: Match[] = response.matches[0];

        if (messageBodyMatches.length > 0) {
            setAddTextStatus(AddTextStatus.ERROR);
            setRestrictedDataMatchList(messageBodyMatches);
        } else {
            setShowModal(true);
        }

        setIsLoading(false);
    }

    useEffect( () => {
        // Runs on component mount
        setLoadingQueues(true);

        setMessageBody(contact.orderIds.length ? populateOrderIds(contact.orderIds) : "");

        callPhoenix(getQuery())
            .then(cr => {
                if (cr.ok) {
                    return cr;
                }
                throw new InternalError();
            })
            .then(cr => {
                setLoadingQueues(false);
                if (cr.callResult?.resultJson) {
                    const contactTransferDestinations = toContactTransferDestinations(cr.callResult.resultJson);
                    setContactTransferDestinations(contactTransferDestinations);

                    const inMarketplaceOptions: AutosuggestProps.Option[] =
                        contactTransferDestinations.inMarketplaceTransferDestinationList.map(toOption);
                    const inMarketplaceOptionGroup: AutosuggestProps.OptionGroup = {
                        label: i18n('IN_MARKETPLACE_QUEUES'),
                        options: inMarketplaceOptions
                    };
                    let options = [inMarketplaceOptionGroup];

                    if (contactTransferDestinations.crossMarketplaceTransferDestinationList.length) {
                        const crossMarketplaceOptions: AutosuggestProps.Option[] =
                            contactTransferDestinations.crossMarketplaceTransferDestinationList.map(toOption);
                        const crossMarketplaceOptionGroup: AutosuggestProps.OptionGroup = {
                            label: i18n('CROSS_MARKETPLACE_QUEUES'),
                            options: crossMarketplaceOptions
                        };
                        options = options.concat(crossMarketplaceOptionGroup);
                    }
                    setOptions(options);
                }
            })
            .catch(() => {
                Phoenix.getInstance().log(LogLevel.ERROR, `Failed to get contact transfer destinations for contact ID ${contact.contactId}`);
                setLoadingQueues(false);});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<Container header={<h4><Translation stringId={"TRANSFER_CONTACT"}/></h4>}>
        <FormField label={loadingQueues ? i18n('LOADING') : i18n('SELECT_TRANSFER_DESTINATION')}>
            <Autosuggest
                disabled={isLoading}
                placeholder={i18n('TYPE_QUEUE_NAME')}
                options={options}
                onChange={({detail}): void => onChange(detail)}
                value={value}
                virtualScroll={true}
                enteredTextLabel={(): string => getEnteredTextLabel()}/>
        </FormField>
        <FormField label={i18n('TRANSFER_CONTACT_COMMENTS')}>
            <AddText rows={2}
                     disabled={isLoading}
                     textPlaceholder={i18n('PROVIDE_ADDITIONAL_INFORMATION')}
                     newText={messageBody}
                     setNewText={setMessageBody}
                     textAriaLabel={i18n('TRANSFER_CONTACT_COMMENTS')}
                     addTextStatus={addTextStatus}
                     setAddTextStatus={setAddTextStatus}
                     restrictedDataMatchList={restrictedDataMatchList}
            />
            <p>{i18n('ANNOTATION_WARNING')}</p>
        </FormField>
        <FormField>
            <SpaceBetween direction={"horizontal"} size={"xs"}>
                <Button
                    onClick={(): Promise<void> => onClickTransferContactRequest()} variant={"primary"} disabled={contactTransferDestination === undefined}
                >{i18n('TRANSFER_CONTACT')}</Button>
            </SpaceBetween>
        </FormField>
        {contactTransferDestination &&
        <ConfirmationModal visible={showModal} dismissCallback={(): void => setShowModal(false)}
                           contact={contact} marketplaceId={marketplaceId}
                           contactTransferDestination={contactTransferDestination} comment={messageBody}/>
        }
    </Container>);
};
export default TransferContactForm;