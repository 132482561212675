import {Location} from "history";

export enum IssueManagerQueryStringParams {
    // comma-separated customer IDs
    customerIDs = "customerIDs",
    emailToken = "emailToken",
    marketplaceID = "marketplaceID",
    initialCommId = "initialCommId",
    contactToken = "contactToken",
}

interface ParsedParams {
    customerIdList: string[];
    emailToken: string | null;
    marketplaceId: string;
    initialCommId: string | null;
    contactToken: string | null;
}

export function parseParams(location: Location): ParsedParams {
    const params = new URLSearchParams(location.search);
    const customerIds = params.get(IssueManagerQueryStringParams.customerIDs);
    const emailToken = params.get(IssueManagerQueryStringParams.emailToken);
    const initialCommId = params.get(IssueManagerQueryStringParams.initialCommId);
    const contactToken = params.get(IssueManagerQueryStringParams.contactToken);
    const customerIdList: string[] = customerIds ? customerIds.split(',') : [];
    const marketplaceIdParam = params.get(IssueManagerQueryStringParams.marketplaceID);
    const marketplaceId = marketplaceIdParam ? marketplaceIdParam : ""; // Should always be set anyway
    return {
        customerIdList,
        emailToken,
        marketplaceId,
        initialCommId,
        contactToken
    };
}