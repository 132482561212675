import React from 'react';
import Translation from "../i18n/Translate";
import Box from "@amzn/awsui-components-react/polaris/box";

const Home: React.FC = () => {
    return (
        <div>
            <Box variant="h1"><Translation stringId="WELCOME_MESSAGE"/></Box>
            <ul>
                <li><a href={'../health'}>Application Health</a></li>
                <li><a href={'../eventbrowser'}>Event Browser</a></li>
                <li><a href={'../explorer'}>GrapQL Explorer</a></li>
                <li><a href={'../authtester'}>Authorizer Tester</a></li>
                <li><a href={'../recorder'}>Recorder</a></li>
            </ul>
        </div>
    );
};

export default Home;