export const cs_CZ = {
    "contact_event_GC_added": "GC #{gcId} přidáno k účtu.",
    "contact_event_GC_added_batched": "GC přidáno k účtu ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} přidáno k účtu kupujícího.",
    "contact_event_GC_added_purchaser_batched": "GC přidáno k účtu kupujícího ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} přidáno k účtu příjemce.",
    "contact_event_GC_added_recipient_batched": "GC přidáno k účtu příjemce ({count}).",
    "contact_event_GC_added_scratchedCode": "GC #{gcId} přidán k účtu prostřednictvím nástroje seškrábnutí kódu nároku.",
    "contact_event_GC_added_scratchedCode_batched": "GC přidán k účtu prostřednictvím nástroje seškrábnutí kódu nároku ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} zrušeno.",
    "contact_event_GC_cancelled_batched": "GC zrušeno ({count}).",
    "contact_event_GC_changed_expiry": "Změněno datum expirace GC #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Změněno datum expirace GC ({count}).",
    "contact_event_GC_locked": "GC #{gcId} uzamčeno.",
    "contact_event_GC_locked_batched": "GC uzamčeno ({count}).",
    "contact_event_GC_removed": "GC #{gcId} odstraněno z účtu.",
    "contact_event_GC_removed_batched": "GC odstraněno z účtu ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} znovu odesláno na e-mail Id {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC znovu odesláno na e-mail Id ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} znovu odesláno poštou.",
    "contact_event_GC_resent_post_batched": "GC znovu odesláno na poštovní adresu ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} dočasně uzamčeno.",
    "contact_event_GC_temporary_locked_batched": "GC dočasně uzamčeno ({count}).",
    "contact_event_GC_transferred": "GC {gcId} převedeno od zákazníka {customerID} na {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC převedeno ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} odemčeno.",
    "contact_event_GC_unlocked_batched": "GC odemčeno ({count}).",
    "contact_event_PV_added": "PV č. {pvId} přidáno k účtu.",
    "contact_event_PV_locked": "PV #{pvId} uzamčeno.",
    "contact_event_PV_temporary_locked": "PV #{pvId} dočasně uzamčeno.",
    "contact_event_PV_unlocked": "PV #{pvId} odemknuto.",
    "contact_event_a_to_z_guarantee_requested_gwp": "Pro objednávku {order_id} byla zadána reklamace od A do Z.",
    "contact_event_access_code_added": "Bezpečnostní kód byl přidán",
    "contact_event_access_code_added_batched": "Bezpečnostní kód byl přidán({count})",
    "contact_event_account_closed": "Účet uzavřen.",
    "contact_event_account_closed_batched": "Účet uzavřen ({count}).",
    "contact_event_account_reopened": "Účet byl znovu otevřen",
    "contact_event_account_reopened_batched": "Účet byl znovu otevřen ({count})",
    "contact_event_account_temporarily_disabled": "Účet by dočasně deaktivován.",
    "contact_event_account_temporarily_disabled_batched": "Účet byl dočasně deaktivován ({count})",
    "contact_event_account_whitelisted": "Účet na bílém seznamu.",
    "contact_event_account_whitelisted_batched": "Účet na bílém seznamu ({count}).",
    "contact_event_address_deactivated": "Adresa deaktivována.",
    "contact_event_address_deactivated_batched": "Adresa deaktivována ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Předplatné Amazon Video bylo automaticky prodlouženo",
    "contact_event_amazon_video_subscription_cancelled": "Předplatné Amazon Video bylo zrušeno",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Odeslaný formulář: Formulář pro eskalaci DSL\nID relace případu použití: {usecaseSessionID}\nStanice doručení: {deliveryStationField}\nTyp žádosti: {requestTypeField}\nID kontaktu: {contactIdField}\nID objednávky: {orderIdField}\nE-mailová adresa zákazníka: {customerEmailField}\nTelefonní číslo zákazníka: {customerPhoneNumberField}\nTBA: {tbasField}\nPřístupový kód (je-li k dispozici): {accessCodeField}\nShrnutí problému: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Odeslané formuláře pro eskalaci DSL: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Odeslaný formulář: Formulář zpětné vazby DSP DA\nID relace případu použití: {usecaseSessionID}\nKód doručovací stanice: {deliveryStationField}\nID kontaktu: {contactIdField}\nID zákaznického účtu: {customerIdField}\nID dopravce DA: {driverIdField}\nDatum incidentu (RRRR-MM-DD): {dateOfOccurrenceField}\nČas incidentu (HH:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nZdroj zpětné vazby: {sourceOfFeedbackField}\nPodrobnosti zpětné vazby: {issueField}\nDalší podrobnosti: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Odeslané formuláře zpětné vazby DSP DA: ({count})",
    "contact_event_apply_adrive_music_trial": "aplikován cloudový přehrávač Cloud Player Premium pro zákazníka {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_cash_and_coupon_refund": "Částka za {title} ve výši {cashAmount} byla vrácena. V návaznosti na tuto transakci byl kupón ve výši {couponAmount} vrácen zpět na účet.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "Částka za {title} ve výši {cashAmount} byla vrácena. V návaznosti na tuto transakci byl kupón ve výši {couponAmount} vrácen zpět na účet.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "Částka za {title} ve výši {cashAmount} byla vrácena.{counter}",
    "contact_event_audible_cash_only_refund_batched": "Částka za {title} ve výši {cashAmount} byla vrácena.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "Částka za {title} kupón ve výši {couponAmount} byla vrácena zpět na účet.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "Částka za {title} kupón ve výši {couponAmount} byla vrácena zpět na účet.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} kreditů odstraněno z účtu.",
    "contact_event_audible_credit_refund": "Částka za {title} ve výši {creditAmount} kreditů byla vrácena zpět na účet.{counter}",
    "contact_event_audible_credit_refund_batched": "Částka za {title} ve výši {creditAmount} kreditů byla vrácena zpět na účet.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} kreditů vráceno na účet.",
    "contact_event_audible_credit_restored_batched": "{amount} kreditů vráceno na účet. ({count})",
    "contact_event_audible_extra_credit_purchased": "Byly zakoupeny 3 kredity navíc.",
    "contact_event_audible_extra_credit_purchased_batched": "Byly zakoupeny 3 kredity navíc. ({count})",
    "contact_event_audible_hiatus_cancelled": "Přerušení bylo ukončeno dříve a členství bylo obnoveno.",
    "contact_event_audible_hiatus_cancelled_batched": "Přerušení bylo ukončeno dříve a členství bylo obnoveno. ({count})",
    "contact_event_audible_hiatus_started": "Členství bylo zahájeno dne {duration}-měsíc přerušení.",
    "contact_event_audible_hiatus_started_batched": "Členství bylo zahájeno dne {duration}-měsíc přerušení.({count})",
    "contact_event_audible_membership_plan_refund": "Poplatek za členství byl vrácen ve výši {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Poplatek za členství byl vrácen ve výši {cashAmount}.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Zaregistrovat se k využití balíčku {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Zaregistrovat se k využití balíčku {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "Proběhl přístup k {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Přístup k {wizardName} proběhl.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "Program {membership} byl úspěšně zrušen.",
    "contact_event_blacklist_kindle": "Zařízení na černé listině {serial_number}.",
    "contact_event_call_was_flagged": "Tento obsah byl označen jako {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Tento obsah byl označen jako {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "Program {membership} byl úspěšně zrušen.",
    "contact_event_cancel_audible_membership_batched": "Program {membership} byl úspěšně zrušen.({count})",
    "contact_event_cancel_audible_romance_package_batched": "Program {membership} byl úspěšně zrušen.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "V minulosti nastavené datum budoucího zrušení bylo zrušeno.",
    "contact_event_cancel_library_loan": "Zrušena knižní výpůjčka pro zákazníka ({customer_id}). ASIN:{asin}; ID výpůjčky:{loan_id}; Důvod: {cancel_reason};",
    "contact_event_cancel_tv_pass": "Zrušen TV pass pro ID objednávky {order_id} a důvod {cancel_reason}.",
    "contact_event_captcha_bypass_key_generated": "Vygenerován přemosťovací kód Captcha.",
    "contact_event_captcha_bypass_key_generated_batched": "Vygenerován přemosťovací kód Captcha ({count}).",
    "contact_event_channels_auto_renew_off": "CS VYPNULY automatické obnovování předplatného kanálů PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS VYPNULY automatické obnovování přes Astro.",
    "contact_event_channels_full_refund": "CS zrušily předplatné kanálů PV {subscriptionid} s vrácením plné částky.",
    "contact_event_channels_full_refund_static": "CS zrušily předplatné s vrácením plné částky přes Astro.",
    "contact_event_channels_no_refund": "CS zrušily předplatné kanálů PV {subscriptionid} bez vrácení peněz.",
    "contact_event_channels_no_refund_static": "CS zrušily předplatné bez vrácení peněz přes Astro.",
    "contact_event_channels_workflow_launch": "CS spustily pracovní postup zrušení Astro pro předplatné kanálů PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS spustily pracovní postup zrušení Astro.",
    "contact_event_check_in_book": "Zaregistrovaná kniha s ID vypůjčky {loan_id} pro zákazníka {customer_id}.",
    "contact_event_coins_adjust": "Typ úpravy: {type} <br />\nDůvod: {reason} <br />\nČástka: {amount} <br />\nPoznámky: {comment} <br />",
    "contact_event_coins_adjust_batched": "Upravený zůstatek mincí Coins ({count}).",
    "contact_event_concessions_abuse": "HMD nebylo odesláno, neboť zákazník je {risk_status} uživatelem.",
    "contact_event_concessions_abuse_batched": "HMD nebylo zákazníkovi zasláno ({count}).",
    "contact_event_contact_seller": "Prodejce byl kontaktován ohledně objednávky {order_id}.",
    "contact_event_contact_seller_batched": "Prodejce jsme kontaktovali ve věci objednávek ({count}).",
    "contact_event_contact_was_initiated_from": "Kontakt byl iniciován z ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_csc_sds_override_geo_fence_submit": "Přepsat odeslanou žádost o střeženou zónu pro ID sledování {trackingId} Důvod: {overrideReason} Strana: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Přepsat odeslanou žádost o střeženou zónu pro ID sledování {trackingId} Důvod: {overrideReason} Strana: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geokód aktualizován. TypGeokódu: {geocodeType}, IDSledování: {trackingId}, IDAdresy: {addressId}, DůvodPřepsání: {overrideReason}, IdentifikátorStránky: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geokód aktualizován. TypGeokódu: {geocodeType}, DůvodPřepsání: {overrideReason}, IDSledování: {trackingId}, IDAdresy: {addressId} a IdentifikátorStránky: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "ověření zákazník proběhlo úspěšně",
    "contact_event_customer_authentication_passed_batched": "ověření zákazník proběhlo úspěšně({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_deauthorize_all_device": "Zrušit schválení všech zařízení pro zákazníka {customer_id}.",
    "contact_event_deauthorize_device": "Zrušit schválení DSN {dsn} pro typ zařízení {device_type} pro zákazníka ({customer_id}).",
    "contact_event_delete_alexa_voice_profile": "U ID mluvčího byl odstraněn hlasový profil služby Alexa: $ {SpeakerId}, ID zákazníka: $ {CustomerId} a stav: {status}",
    "contact_event_delete_data_alexa_comms": "U ID Comms byl odstraněn profil Alexa Comms: $ {commSid}, ID zákazníka: $ {CustomerId} a stav: {status}",
    "contact_event_deprovision_alexa_comms": "Smazán profil Alexa Comms pro commsID: {commsId} ,  customerID: {customerID}  a status: {status}",
    "contact_event_dunning_halt": "Proces upomínky zastaven - podrobnější informace viz Historie plateb a transakcí",
    "contact_event_dunning_pause": "Proces upomínky pozastaven - podrobnější informace viz Historie plateb a transakcí",
    "contact_event_dvbypost_extra_dispatch": "Pro tohoto zákazníka se vyžaduje dalších {amount} zásilek navíc",
    "contact_event_dvbypost_extra_envelope": "Pro tohoto zákazníka se spustila obálka navíc.",
    "contact_event_dvbypost_fsk18_form_request": "Vyžádán formulář FSK18",
    "contact_event_dvbypost_problem_code_change": "Nastavení disku {disc_id} na {problem_code}",
    "contact_event_external_transfer": "Tento kontakt byl převeden mimo na {transferAddress}",
    "contact_event_external_transfer_batched": "Tento kontakt byl převeden mimo na {transferAddress} ({count})",
    "contact_event_first_time_user_experience_set": "Nastavit první zkušenost uživatele",
    "contact_event_first_time_user_experience_set_batched": "Nastavit první zkušenost uživatele({count}).",
    "contact_event_free_time_unlimited_cancelled": "Předplatné Free Time Unlimited zrušeno pro zákazníka: {customerId}, DSN: {deviceSerialNumber} od {agentId} dle důvodu: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Předplatné Free Time Unlimited zrušeno ({count}).",
    "contact_event_fulfill_kindle": "Naplněný Kindle. DSN {dsn}; typ zařízení {device_type}; ASIN {asin}; ID objednávky {order_id};",
    "contact_event_future_cancel_audible_membership": "Program {membership} má nastaveno budoucí zrušení dne {date}.",
    "contact_event_future_cancel_audible_membership_batched": "Program {membership} má nastaveno budoucí zrušení dne {date}.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} byl trvale odstraněn z Vaší knihovny videí.",
    "contact_event_hmd_suppression": "HMD nebylo odesláno z následujícího důvodu: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Nečinný chat byl odpojen",
    "contact_event_idle_chat_disconnect_batched": "Nečinný chat byl odpojen ({count})",
    "contact_event_international_linking_account": "Mezinárodní propojený účet {from_customer_email} s účtem {to_customer_email} pro zákazníka {customer_id}.",
    "contact_event_kindle_unlimited_refund": "Program Kindle Unlimited vrátil částku {amount} za transakci ID {transaction_id}.",
    "contact_event_live_assist_launched": "Aplikace Mayday Screen Sharing spuštena pro DSN: {dsn}, kcsID: {kcsId}, odpověď zákazníka: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Aplikace Mayday Screen Sharing spuštěna pro zařízení ({count}).",
    "contact_event_lock_dash_order": "Následující ASIN pro zařízení {dsn} byla uzamčena: {asins}.",
    "contact_event_mp3_download_to_adrive": "mp3 sstaženo do ADrive zákazníka {customer_id}. ASIN: {asin}",
    "contact_event_music_auto_renew_off": "CS vypnuly automatické obnovování předplatného {subscriptionid} služby Music.",
    "contact_event_music_auto_renew_off_static": "CS vypnuly automatické obnovování služby Music přes Astro.",
    "contact_event_music_full_refund": "CS zrušily předplatné {subscriptionid} služby Music s plným vrácením peněz",
    "contact_event_music_full_refund_static": "CS zrušily předplatné služby Music s plným vrácením peněz přes Astro.",
    "contact_event_music_no_refund": "CS zrušily předplatné {subscriptionid} služby Music bez vrácení peněz.",
    "contact_event_music_no_refund_static": "CS zrušily předplatné služby Music bez vrácení peněz přes Astro.",
    "contact_event_music_unlimited_auto_renew_off": "Zákaznická služba vypnula automatické prodlužování předplatného Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "Zákaznická služba zrušila předplatné Amazon Music Unlimited {subscriptionid} s vrácením peněz v plné výši.",
    "contact_event_music_unlimited_no_refund": "Zákaznická služba zrušila předplatné Amazon Music Unlimited {subscriptionid} bez nároku na vrácení peněz.",
    "contact_event_music_unlimited_partial_refund": "Zákaznická služba zrušila předplatné Amazon Music Unlimited {subscriptionid} s částečným vrácením peněz.",
    "contact_event_music_unlimited_workflow_launch": "Zákaznická služba zahájila pracovní postup Astro ke zrušení předplatného Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS spustily pracovní postup zrušení Astro pro předplatné {subscriptionid} služby Music.",
    "contact_event_music_workflow_launch_static": "CS spustily pracovní postup zrušení Astro pro službu Music Unlimited.",
    "contact_event_one_click_payment_updated": "Platba 1 kliknutím aktualizována.",
    "contact_event_one_click_payment_updated_batched": "Aktualizace platby 1 kliknutím ({count}).",
    "contact_event_payment_method_changed": "Platební metoda pro objednávku {order_id} se změnila.",
    "contact_event_payment_method_changed_batched": "Platební metody  pro objednávky {order_id} se změnily.",
    "contact_event_payment_plan_changed": "Platební plán pro objednávku {order_id} se změnil.",
    "contact_event_payment_plan_changed_batched": "Platební plán pro objednávky {order_id} se změnil.",
    "contact_event_pennywise_promo_applied": "Promo akce Pennywise byla nastavena pro DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promo akce Pennywise byla nastavena pro DSN: ({count})",
    "contact_event_promo_unredeemed": "{promoBalance} nevyužitých akčních nabídek.",
    "contact_event_promo_unredeemed_batched": "({count}) nevyužitých akčních nabídek.",
    "contact_event_refund_cancelled": "Vrácení peněz pro objednávku {order_id} zrušeno.",
    "contact_event_refund_in_digital_library": "vrácení peněz za digitální položku pro zákazníka {customerId}. Důvod: {reason}; ID objednávky: {orderId};",
    "contact_event_registry_deleted": "Rejstřík odstraněn (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Rejstříky odstraněny ({count}).",
    "contact_event_release_aiv_license": "Licence AIV {asin} byla uvolněna pro zařízení DSN {dsn}.",
    "contact_event_release_licenses": "Uvolnit licence pro DSN {dsn} pro typ zařízení {device_type} pro zákazníka {customer_id}.",
    "contact_event_remove_from_yvl": "ASIN {asin} byl odstraněn z Vaší knihovny videí do Odstraněných položek.",
    "contact_event_request_invoice": "Žádost o fakturu pro objednávku {order_id} byla zaslána Prodejci.",
    "contact_event_request_invoice_batched": "Žádost o fakturu pro objednávky ({count}) zaslána.",
    "contact_event_reset_aiv_pin": "Resetovat pin AIV pro zákazníka {customer_id}.",
    "contact_event_reset_kindle_first_allowance": "Resetovat Amazon First Reads Allowance pro zákazníka {customer_id}.",
    "contact_event_reset_password_email_sent": "E-mail s heslem pro resetování byl odeslán.",
    "contact_event_reset_password_email_sent_batched": "Byly zaslány e-maily s heslem pro resetování ({count}).",
    "contact_event_reset_prime_lending_library_limit": "Resetovat prvotřídní limit knihovny výpůjček {quota} pro zákazníka {customer_id}.",
    "contact_event_restore_content": "Obnovit obsah ASIN {asin} u ID objednávky {order_id} pro zákazníka {customer_id}.",
    "contact_event_restore_rental": "Obnovena výpůjčka s ASIN {asin} pro zákazníka {customer_id}.",
    "contact_event_restore_video": "Obnovit video s ASIN {asin} pro zákazníka {customer_id}.",
    "contact_event_retrocharge_disarmed": "RMA vypnuto pro objednávku ID {orderId} - ASIN {asin}. Důvod vypnutí: {disarm_retrocharge_reason}",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_assigned_package_to_driver": "ID sledování {trackingId} bylo přidáno do cestovního plánu",
    "contact_event_sds_assigned_package_to_driver_batched": "ID sledování {trackingId} bylo přidáno do cestovního plánu.",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Název DP: {driverName}, \nNázev stanice: {stationName}, \nID pro sledování: {trackingId} a \nID objednávky: {orderId}\nUsecaseSession ID: {usecaseSessionID}",
    "contact_event_sds_dlp_loaded_for_tracking_order_id": "ID sledování {trackingId} a ID objednávky {orderId} načtena.",
    "contact_event_sds_dlp_loaded_for_tracking_order_id_batched": "ID sledování {trackingId} a ID objednávky {orderId} načtena.",
    "contact_event_sds_map-widget-opened": "Widget mapy se otevřel u ID pro sledování {trackingId}",
    "contact_event_sds_package_action_status_update": "Status ID sledování {trackingId} se změnil na {state}",
    "contact_event_sds_package_repick": "Pracovník inicioval opětovné vyzvednutí pro ID sledování {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} byl zaslán do Vaší knihovny videí zákazníka {customer_id} .",
    "contact_event_shipping_address_changed": "Adresa u objednávky {new_order_id} se změnila.",
    "contact_event_sim_ticket_created": "Lístek SIM byl vytvořen",
    "contact_event_special_offers_cancel": "Zrušené speciální nabídky pro DSN {dsn} pro typ zařízení {device_type} pro zákazníka {customer_id}.",
    "contact_event_subscription_astro_workflow_launch": "CS spustily pracovní postup zrušení Astro pro předplatné {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_auto_renew_off": "CS vypnuly automatické prodlužování předplatného {subscriptionType} {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "CS zrušily předplatné {subscriptionType} {subscriptionId} s vrácením peněz v plné výši.",
    "contact_event_subscription_cancel_no_refund": "CS zrušily předplatné {subscriptionType} {subscriptionId} bez vrácení peněz.",
    "contact_event_subscription_cancel_partial_refund": "CS zrušily předplatné {subscriptionType} {subscriptionId} s částečným vrácením peněz.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} odběr nastaven na prodloužení {new_plan_name} from {old_plan_name} na konci aktuálního předplatného.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Aktualizované nastavení programu prodloužení platnosti Prime ({count}).",
    "contact_event_unlink_good_reads_account": "Zrušit propojení účtu good reads pro zákazníka {customer_id}.",
    "contact_event_unlink_kindle_account": "Zrušit propojení účtu kindle pro zákazníka {customer_id}.",
    "contact_event_unlock_dash_order": "Následující ASIN pro zařízení {dsn} byla odemčena: {asins}.",
    "contact_event_update_locking_status": "Stav uzamčení zařízení Oft {dsn} se změnil na: {newLockingStatus}",
    "contact_event_verify_email": "Zákazníkovi byl odeslán ověřovací e-mail.",
    "contact_event_verify_email_email_sent": "Ověření e-mailu odesláno.",
    "contact_event_verify_email_email_sent_batched": "Ověření e-mailu odesláno ({count}).",
    "contact_event_weekend_delivery_preference_changed": "Předvolby dodání o víkendu byly aktualizovány",
    "contact_event_weekend_delivery_preference_changed_batched": "Předvolby dodání o víkendu byly aktualizovány{order_id}"
}