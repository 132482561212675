/**
 * Copied from https://code.amazon.com/packages/BDTContractsWebsite/blobs/mainline/--/src/utils/copyToClipboard.jsx
 */
export const copyToClipboard = async (text: string): Promise<boolean> => {
    // Try to use the modern clipboard API.
    // Some browsers only allow this API in response to a user initiated event.
    return navigator.clipboard.writeText(text)
        .then(() => {
            return true;
        })
        .catch((reason) => {
            console.error("Failed to write to clipboard. Attempting fallback.", reason);

            // Fall back to using a textarea.
            // https://stackoverflow.com/a/30810322/898577
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                return document.execCommand('copy');
            } finally {
                document.body.removeChild(textArea);
            }
        });
};