import {CallResponse, JwtToken} from "@amzn/csphoenix-react-client";
import { callPhoenix } from "../issueManager/IssueManager";
import {AuthorizerJwtToken} from "../authorizer/AuthorizerJwtToken";

// eslint-disable-next-line
function buildCustomerQuery(customerId: string, marketplaceId: string): string {
    return `query { ` +
        `   getCustomer ( customerId: "${customerId}", marketplaceId: "${marketplaceId}" ) { ` +
        `       customerContactPreferences ( obfuscatedMarketplaceId: "${marketplaceId}", obfuscatedCustomerId: "${customerId}") { ` +
        `         defaultShippingAddress { ` +
        `           timeZoneInformation { ` +
        `             timeZone ` +
        `           }` +
        `         }` +
        `       }` +
        `   }` +
        `}`;
}

export async function fetchCustomerTimezone(
    customerId: string,
    marketplaceId: string | null,
    jwtState: JwtToken | null,
    authorizerJwtTokens: AuthorizerJwtToken[]): Promise<string> {
    if (customerId.trim().length > 0 && marketplaceId != null) {
        const query = buildCustomerQuery(customerId, marketplaceId);

        const response = await callPhoenix(query, jwtState, authorizerJwtTokens);
        const timezone = processCustomerTimezone(response);
        return timezone;
    }
    return "";
}


// eslint-disable-next-line
function processCustomerTimezone(response: CallResponse): string {
    // set timezone if customer has address data
    if (response.httpStatus === 200 && response.callResult && response.callResult.resultJson) {
        const parsed = JSON.parse(response.callResult.resultJson);
        if (parsed.data && parsed.data.getCustomer && parsed.data.getCustomer.customerContactPreferences
            && parsed.data.getCustomer.customerContactPreferences.defaultShippingAddress
            && parsed.data.getCustomer.customerContactPreferences.defaultShippingAddress.timeZoneInformation
            && parsed.data.getCustomer.customerContactPreferences.defaultShippingAddress.timeZoneInformation.timeZone) {
            const timeZone = parsed.data.getCustomer.customerContactPreferences.defaultShippingAddress.timeZoneInformation.timeZone;
            return timeZone;
        }
    }
    return "";
}