export const nl_NL = {
    "contact_event_GC_added": "GC #{gcId} toegevoegd aan account.",
    "contact_event_GC_added_batched": "GC toegevoegd aan account  ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} toegevoegd aan account van koper.",
    "contact_event_GC_added_purchaser_batched": "GC toegevoegd aan account inkoper ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} toegevoegd aan account van ontvanger.",
    "contact_event_GC_added_recipient_batched": "GC toegevoegd aan account ontvanger ({count}).",
    "contact_event_GC_added_scratchedCode": "GC {gcId} toegevoegd aan account via gekraste claimcode tool.",
    "contact_event_GC_added_scratchedCode_batched": "GC toegevoegd aan account via gekraste claimcode tool ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} geannuleerd.",
    "contact_event_GC_cancelled_batched": "GC geannuleerd ({count}).",
    "contact_event_GC_changed_expiry": "Vervaldatum van GC #{gcId} gewijzigd.",
    "contact_event_GC_changed_expiry_batched": "Vervaldatum van GC {count} gewijzigd.",
    "contact_event_GC_locked": "GC #{gcId} vergrendeld.",
    "contact_event_GC_locked_batched": "GC vergrendeld ({count}).",
    "contact_event_GC_removed": "GC #{gcId} verwijderd uit account.",
    "contact_event_GC_removed_batched": "GC verwijderd uit account ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId} opnieuw verzonden naar e-mailadres {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "GC opnieuw verzonden naar e-mailadres {count}).",
    "contact_event_GC_resent_post": "GC #{gcId} opnieuw verzonden per post.",
    "contact_event_GC_resent_post_batched": "GC opnieuw verzonden per post {count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} tijdelijk vergrendeld.",
    "contact_event_GC_temporary_locked_batched": "GC tijdelijk vergrendeld ({count}).",
    "contact_event_GC_transferred": "GC {gcId} overgedragen van klant {customerID} naar {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "GC overgedragen ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} ontgrendeld.",
    "contact_event_GC_unlocked_batched": "GC ontgrendeld ({count}).",
    "contact_event_PV_added": "PV #{pvId} toegevoegd aan account.",
    "contact_event_PV_locked": "PV #{pvId} vergrendeld.",
    "contact_event_PV_temporary_locked": "PV #{pvId} tijdelijk vergrendeld.",
    "contact_event_PV_unlocked": "PV #{pvId} ontgrendeld.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "A-to-Z-claim geannuleerd voor bestellingen ({count}).",
    "contact_event_a_to_z_guarantee_requested": "A-tot-Z-claim ingediend voor bestelling {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "A-to-Z-claim ingediend voor bestellingen ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "A-tot-z claim ingediend voor bestelling {order_id}.",
    "contact_event_access_code_added": "Toegangscode toegevoegd",
    "contact_event_account_closed": "Account gesloten.",
    "contact_event_account_closed_batched": "Accounts gesloten ({count}).",
    "contact_event_account_reopened": "Account heropend",
    "contact_event_account_reopened_batched": "Accounts heropend ({count})",
    "contact_event_account_temporarily_disabled": "Account tijdelijk uitgeschakeld.",
    "contact_event_account_temporarily_disabled_batched": "Accounts tijdelijk uitgeschakeld ({count})",
    "contact_event_account_whitelisted": "Account op witte lijst.",
    "contact_event_address_changed": "Adres {address_type} bijgewerkt voor bestelling {order_id}.",
    "contact_event_address_changed_batched": "Bijgewerkte adressen voor bestellingen ({count}).",
    "contact_event_address_deactivated": "Adres gedeactiveerd.",
    "contact_event_address_deactivated_batched": "Adres gedeactiveerd ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video-abonnement automatisch verlengd",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video-abonnement opgezegd",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulier ingediend: escalatieformulier voor DSL\nUsecase-sessie-ID: {usecaseSessionID}\nBezorgstation: {deliveryStationField}\nType aanvraag: {requestTypeField}\nContact-ID: {contactIdField}\nBestelnummer: {orderIdField}\nE-mailadres van klant: {customerEmailField}\nTelefoonnummer van klant: {customerPhoneNumberField}\nTBA('s): {tbasField}\nToegangscode (indien van toepassing): {accessCodeField}\nProbleemoverzicht: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Escalatieformulieren voor DSL ingediend: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulier ingediend: DA-feedbackformulier voor DSP\nUsecase-sessie-ID: {usecaseSessionID}\nCode bezorgstation: {deliveryStationField}\nContact-ID: {contactIdField}\nKlantaccount-ID: {customerIdField}\nBezorgers-ID: {driverIdField}\nDatum van incident (DD-MM-JJJJ): {dateOfOccurrenceField}\nTijdstip incident (UU:MM): {timeofOccurrenceField}\nTBA's: {relatedTrackingIdsField}\nBron van feedback: {sourceOfFeedbackField}\nFeedbackgegevens: {issueField}\nAanvullende gegevens: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "DA-feedbackformulieren voor DSP ingediend: ({count})",
    "contact_event_andon_cord_pulled": "Waarschuwing geactiveerd voor {asin}.",
    "contact_event_andon_cord_pulled_batched": "Waarschuwing geactiveerd voor artikelen ({count}).",
    "contact_event_apply_adrive_music_trial": "heeft Cloud Player Premium toegepast voor klant {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Account gemigreerd naar {marketplaceName}.",
    "contact_event_audible_cash_and_coupon_refund": "{title} werd gerestitueerd voor het bedrag van {cashAmount}. Bovendien heeft deze transactie een {couponAmount} coupon terug naar het account hersteld.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} werd gerestitueerd voor het bedrag van {cashAmount}. Bovendien deze transactie een {couponAmount} coupon terug naar het account hersteld.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} werd gerestitueerd voor het bedrag van {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} werd gerestitueerd voor het bedrag van {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} werd gerestitueerd en heeft een {couponAmount} coupon terug naar het account hersteld.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} werd gerestitueerd en heeft een {couponAmount} coupon terug naar het account hersteld.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} krediet(en) is/zijn verwijderd uit het account.",
    "contact_event_audible_credit_refund": "{title} werd gerestitueerd en heeft {creditAmount} krediet terug naar het account hersteld.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} werd gerestitueerd en heeft {creditAmount} krediet terug naar het account hersteld.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} krediet(en) is/zijn hersteld naar het account.",
    "contact_event_audible_credit_restored_batched": "{amount} krediet(en) is/zijn hersteld naar het account.({count})",
    "contact_event_audible_extra_credit_purchased": "Er zijn 3 extra kredietpunten gekocht.",
    "contact_event_audible_extra_credit_purchased_batched": "Er zijn 3 extra kredietpunten gekocht. ({count})",
    "contact_event_audible_hiatus_cancelled": "Onderbrekingsperiode is eerder beëindigd en het lidmaatschap is hervat.",
    "contact_event_audible_hiatus_cancelled_batched": "Onderbrekingsperiode is eerder beëindigd en het lidmaatschap is hervat.({count})",
    "contact_event_audible_hiatus_started": "Het lidmaatschap werd geplaatst op een {duration}-maanden onderbreking.",
    "contact_event_audible_hiatus_started_batched": "Het lidmaatschap werd geplaatst op een {duration}-maanden onderbreking. ({count})",
    "contact_event_audible_membership_plan_refund": "Het lidmaatschap is terugbetaald in het bedrag van {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Het lidmaatschap is terugbetaald in het bedrag van {cashAmount}.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Ingeschreven op de {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Ingeschreven op de {packageName}. ({count})",
    "contact_event_audible_wizard_invoked": "Er werd toegang verkregen tot {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Er werd toegang verkregen tot {wizardName}.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "Het {membership}-plan is met succes geannuleerd.",
    "contact_event_blacklist_kindle": "Apparaat {serial_number} op zwarte lijst geplaatst.",
    "contact_event_blacklist_kindle_batched": "Kindles die op de zwarte lijst zijn geplaatst ({count}).",
    "contact_event_bound_warranty": "Garantie-id {warranty_id} gekoppeld aan apparaat {dsn}.",
    "contact_event_bound_warranty_batched": "Garanties gekoppeld aan apparaten ({count}).",
    "contact_event_call_was_flagged": "Deze contactpersoon is gemarkeerd als {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Deze contactpersoon is gemarkeerd als {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "Het {membership}-plan is met succes geannuleerd.",
    "contact_event_cancel_audible_membership_batched": "Het {membership}-plan is met succes geannuleerd.({count})",
    "contact_event_cancel_audible_romance_package_batched": "Het {membership}-plan is met succes geannuleerd.({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Annuleren van Prime vanuit PrimeCancelException geslaagd. Reden voor annulering: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Uitzondering voor Prime annuleren ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "De eerder ingestelde toekomstige annuleringsdatum is geannuleerd.",
    "contact_event_cancelled_sns_reactivated": "Abonnement voor ASIN {asin} gereactiveerd op {reactivation_date} (maand).",
    "contact_event_cancelled_sns_reactivated_batched": "Geannuleerde abonnementen voor Abonneren en besparen gereactiveerd ({count}).",
    "contact_event_captcha_bypass_key_generated": "Captcha-bypass-sleutel gegenereerd.",
    "contact_event_captcha_bypass_key_generated_batched": "Captcha-bypass-sleutel gegenereerd ({count}).",
    "contact_event_change_sns_quantity": "Artikelaantal voor ASIN {asin} in abonnement bijgewerkt van{current_quantity} naar {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Aantallen voor Abonneren en besparen gewijzigd ({count}).",
    "contact_event_channels_auto_renew_off": "CS heeft automatische verlenging uitgeschakeld voor abonnement {subscriptionid} op PV-kanalen.",
    "contact_event_channels_auto_renew_off_static": "CS heeft automatische verlenging via Astro uitgeschakeld.",
    "contact_event_channels_full_refund": "CS heeft abonnement {subscriptionid} op PV-kanalen geannuleerd met volledige terugbetaling.",
    "contact_event_channels_full_refund_static": "CS heeft het abonnement met volledige terugbetaling geannuleerd via Astro.",
    "contact_event_channels_no_refund": "CS heeft abonnement {subscriptionid} op PV-kanalen zonder terugbetaling geannuleerd.",
    "contact_event_channels_no_refund_static": "CS heeft het abonnement zonder terugbetaling geannuleerd via Astro.",
    "contact_event_channels_workflow_launch": "CS startte Astro-annuleringsworkflow voor abonnement {subscriptionid} op PV-kanalen",
    "contact_event_channels_workflow_launch_static": "CS startte Astro-annuleringsworkflow.",
    "contact_event_coins_adjust": "Soort aanpassing: {type} <br />\nReden: {reason} <br />\nBedrag: {amount} <br />\nOpmerkingen: {comment} <br />",
    "contact_event_coins_adjust_batched": "Aangepast muntensaldo ({count}).",
    "contact_event_concessions_abuse": "HMD niet verzonden omdat de klant een {risk_status}-gebruiker is.",
    "contact_event_contact_seller": "Contact opgenomen met verkoper voor bestelling {order_id}.",
    "contact_event_contact_seller_batched": "Contact opgenomen met verkoper voor bestellingen ({count}).",
    "contact_event_contact_was_initiated_from": "Er is een contact geïniteerd door ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "Inhoud van ASIN {asin} bijgewerkt in de Kindle-bibliotheek.",
    "contact_event_content_update_batched": "Bijgewerkte inhoud in Kindle-bibliotheek ({count}).",
    "contact_event_credit_card_disabled": "Cc gedeactiveerd.",
    "contact_event_credit_card_disabled_batched": "Creditcards gedeactiveerd ({count}).",
    "contact_event_csc_sds_override_geo_fence_submit": "Geofence-verzoek overschrijven dat is ingediend voor Tracking-ID {trackingId} Reden: {overrideReason} Pagina:{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Geofence-verzoek overschrijven dat is ingediend voor Tracking-ID {trackingId} Reden: {overrideReason} Pagina:{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocode bijgewerkt. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocode bijgewerkt. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} en pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "klantauthenticatie geslaagd",
    "contact_event_customer_authentication_passed_batched": "klantauthenticatie geslaagd({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Klantnaam bijgewerkt.",
    "contact_event_customer_name_updated_batched": "Klantnaam bijgewerkt ({count}).",
    "contact_event_customer_password_changed": "Wachtwoord klant gewijzigd.",
    "contact_event_customer_password_changed_batched": "Wachtwoord klant gewijzigd ({count}).",
    "contact_event_delete_alexa_voice_profile": "Alexa-stemprofiel verwijderd voor luidspreker-ID: {speakerId}, klant-ID: {customerID} en status: {status}",
    "contact_event_delete_data_alexa_comms": "Alexa Comms-profiel verwijderd voor comms-ID: {commsId}, klant-ID: {customerID} en status: {status}",
    "contact_event_deprovision_alexa_comms": "Onbepaald Alexa Comms-profiel voor comms-id: {commsId}, klant-id: {customerID} en status: {status}",
    "contact_event_deregister_kindle": "Gederegistreerde Kindle {serial_number}.",
    "contact_event_deregister_kindle_batched": "Gederegistreerde Kindles ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Probleemopsporingswizard aangeroepen voor apparaat met DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "De noodoproepfunctie voor apparaat DSN {dsn} kon niet uitgeschakeld worden.",
    "contact_event_disable_mayday_success": "De noodoproepfunctie voor apparaat DSN {dsn} is met succes uitgeschakeld.",
    "contact_event_dunning_pause": "Aanmaningsproces onderbroken - Zie Betalingen en transactiegeschiedenis voor nadere details",
    "contact_event_dvbypost_extra_dispatch": "{amount} extra levering(en) aangevraagd voor deze klant.",
    "contact_event_dvbypost_extra_envelope": "Er is een extra envelop geactiveerd voor deze klant.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18-formulier aangevraagd.",
    "contact_event_dvbypost_problem_code_change": "Disc {disc_id} ingesteld op {problem_code}",
    "contact_event_email_address_updated": "E-mailadres bijgewerkt.",
    "contact_event_email_address_updated_batched": "E-mailadres bijgewerkt ({count}).",
    "contact_event_email_preferences_changed": "E-mailvoorkeuren gewijzigd.",
    "contact_event_email_unsubscribe": "E-mail afgemeld.",
    "contact_event_email_unsubscribe_batched": "E-mailadres afgemeld ({count}).",
    "contact_event_enable_mayday_failed": "De noodoproepfunctie voor apparaat DSN {dsn} kon niet uitgeschakeld worden.",
    "contact_event_enable_mayday_success": "De noodoproepfunctie voor apparaat DSN{dsn} is met succes opnieuw ingeschakeld.",
    "contact_event_first_time_user_experience_set": "Eerste gebruikerservaring instellen",
    "contact_event_first_time_user_experience_set_batched": "Eerste gebruikerservaring instellen({count}).",
    "contact_event_form_submitted": "Formulier {form_name} ingediend.",
    "contact_event_form_submitted_batched": "Formulieren ingediend ({count}).",
    "contact_event_free_time_unlimited_cancelled": "Onbeperkt Free Time-abonnement geannuleerd voor klant: {customerId}, DSN: {deviceSerialNumber} door {agentId} met reden: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Onbeperkt Free Time-abonnement geannuleerd ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Annuleren van gratis proef voor Prime geslaagd voor {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Annuleren van Prime geslaagd ({count}).",
    "contact_event_future_cancel_audible_membership": "Het {membership}-plan is ingesteld op annuleren in de toekomst op {date}.",
    "contact_event_future_cancel_audible_membership_batched": "Het {membership}-plan is ingesteld op annuleren in de toekomst op {date}.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} is permanent verwijderd uit je videotheek.",
    "contact_event_header": "Evenementenlog",
    "contact_event_idle_chat_disconnect": "De verbinding met inactieve chat is verbroken",
    "contact_event_idle_chat_disconnect_batched": "De verbinding met inactieve chat is verbroken ({count})",
    "contact_event_item_price_reduced": "Artikelprijs gewijzigd van {old_price} naar {new_price} voor bestelling {order_id}.",
    "contact_event_item_price_reduced_batched": "Artikelprijzen gewijzigd voor bestellingen({count}).",
    "contact_event_item_quantity_changed": "Artikelaantal gewijzigd in bestelling {order_id}.",
    "contact_event_item_quantity_changed_batched": "Artikelaantallen gewijzigd voor bestellingen ({count}).",
    "contact_event_kindle_device_replacement": "{reason} Vervanging gemaakt voor DSN {dsn} van bestelling {old_order_id}. Nieuwe bestelling: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Vervangen Kindle-apparaten ({count}).",
    "contact_event_kindle_email_address_edits": "E-mailadres bijgewerkt op Kindle (DSN-nr. {dsn}).",
    "contact_event_kindle_email_address_edits_batched": "E-mailadressen bijgewerkt voor Kindles ({count}).",
    "contact_event_kindle_email_edits": "Verzending van {dsn} naar e-mailadres is gewijzigd van {original_email} naar {new_email}",
    "contact_event_kindle_name_changed": "Apparaatnaam gewijzigd van {original_name} naar {new_name} op Kindle (DSN-nr. {dsn}).",
    "contact_event_kindle_name_changed_batched": "Naam Kindle gewijzigd ({count}).",
    "contact_event_last_page_read_deleted": "Laatste pagina gelezen voor ASIN {asin} verwijderd.",
    "contact_event_last_page_read_deleted_batched": "Meest recent gelezen pagina verwijderd ({count}).",
    "contact_event_live_assist_launched": "Mayday Screen Sharing gestart voor DSN: {dsn}, kcsID: {kcsId}, klantantwoord: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing gestart voor apparaten ({count}).",
    "contact_event_lock_dash_order": "De volgende ASIN's voor apparaat {dsn} zijn vergrendeld: {asins}.",
    "contact_event_music_auto_renew_off": "CS heeft automatische verlenging voor Music-abonnement {subscriptionid} uitgeschakeld.",
    "contact_event_music_auto_renew_off_static": "CS heeft automatische verlenging voor Music-abonnement via Astro uitgeschakeld.",
    "contact_event_music_full_refund": "CS heeft Music-abonnement {subscriptionid} geannuleerd met volledige terugbetaling",
    "contact_event_music_full_refund_static": "CS heeft Music-abonnement met volledige terugbetaling geannuleerd via Astro.",
    "contact_event_music_no_refund": "CS heeft Music-abonnement {subscriptionid} geannuleerd zonder restitutie.",
    "contact_event_music_no_refund_static": "CS heeft Music-abonnement zonder terugbetaling geannuleerd via Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS heeft de automatische verlenging voor het Amazon Music Unlimited-abonnement {subscriptionid} uitgeschakeld.",
    "contact_event_music_unlimited_full_refund": "CS heeft het Amazon Music Unlimited-abonnement {subscriptionid} opgezegd met volledige terugbetaling.",
    "contact_event_music_unlimited_no_refund": "CS heeft het Amazon Music Unlimited-abonnement {subscriptionid} opgezegd zonder terugbetaling.",
    "contact_event_music_unlimited_partial_refund": "CS heeft het Amazon Music Unlimited-abonnement {subscriptionid} opgezegd met gedeeltelijke terugbetaling.",
    "contact_event_music_unlimited_workflow_launch": "CS heeft de Astro-annuleringsworkflow voor het Amazon Music Unlimited-abonnement {subscriptionid} gelanceerd.",
    "contact_event_music_workflow_launch": "CS heeft Astro-annuleringsworkflow voor Music-abonnement{subscriptionid} gelanceerd.",
    "contact_event_music_workflow_launch_static": "CS heeft Astro-annuleringsworkflow voor Music unlimited gelanceerd.",
    "contact_event_non_dsv_refund_created": "Digitale bestelling {order_id} is gerestitueerd {refund_amount}.",
    "contact_event_non_dsv_refund_created_batched": "Digitale bestellingen gerestitueerd ({count}).",
    "contact_event_one_click_address_updated": "1-klikadres bijwerkt.",
    "contact_event_one_click_address_updated_batched": "1-klikadres bijgewerkt ({count}).",
    "contact_event_one_click_disabled": "1-klik uitgeschakeld.",
    "contact_event_one_click_disabled_batched": "1-klik uitgeschakeld ({count}).",
    "contact_event_one_click_enabled": "1-klik ingeschakeld.",
    "contact_event_one_click_payment_updated": "1-klikbetaling bijgewerkt.",
    "contact_event_one_click_payment_updated_batched": "1-klikbetaling bijgewerkt ({count}).",
    "contact_event_order_cancelled": "Artikelen geannuleerd in bestelling {order_id}.",
    "contact_event_order_cancelled_batched": "Bestellingen geannuleerd ({count}).",
    "contact_event_order_placed": "Geplaatste bestelling {order_id}.",
    "contact_event_order_placed_batched": "Geplaatste bestellingen ({count}).",
    "contact_event_payment_method_added": "Betaalmethode {payment_type} toegevoegd aan account.",
    "contact_event_payment_method_added_batched": "Betaalmethoden toegevoegd aan account ({count}).",
    "contact_event_payment_method_changed": "Betalingsmethode gewijzigd voor bestelling {order_id}.",
    "contact_event_payment_method_changed_batched": "Betalingsmethode gewijzigd voor bestellingen ({count}).",
    "contact_event_pdocs_max_price_edits": "Instellingen voor maximale conversiekosten in persoonlijke documenten gewijzigd in {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Instellingen voor maximale conversiekosten in persoonlijke documenten bijgewerkt ({count}).",
    "contact_event_pennywise_promo_applied": "Pennywise-promotie toegepast voor DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Pennywise-promotie toegepast voor DSN's: ({count})",
    "contact_event_ping_device": "Apparaat met DSN {dsn} gepingd.",
    "contact_event_ping_device_batched": "Gepingde apparaten ({count}).",
    "contact_event_prime_auto_renew_settings": "Automatische verlenging voor Prime ingesteld op {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Automatisch verlengen van Prime ingesteld op {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Annuleren van Prime vanuit PrimeCancelException geslaagd. Reden voor annulering: {cancel_reason}.",
    "contact_event_prime_cancelled": "Door Prime geannuleerd.",
    "contact_event_prime_cancelled_batched": "Door Prime geannuleerd ({count}).",
    "contact_event_prime_concession": "Prime-concessie verstrekt voor bestelling {order_id}.",
    "contact_event_prime_concession_batched": "Prime-concessies verstrekt voor bestellingen ({count}).",
    "contact_event_prime_refund": "Door Prime gerestitueerd {amount}.",
    "contact_event_prime_refund_batched": "Door Prime gerestitueerd ({count}).",
    "contact_event_promo_added_to_account": "Promobedrag van {amount} toegevoegd aan account vanwege {reason}.",
    "contact_event_promo_added_to_account_batched": "Promo's toegevoegd aan account  ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} promo unredeemed.",
    "contact_event_promo_unredeemed_batched": "Niet-ingewisselde promo's ({count}).",
    "contact_event_refund_cancelled": "Restitutie geannuleerd voor bestelling {order_id}.",
    "contact_event_refund_created": "{refund_amount} gerestitueerd voor bestelling {order_id}.",
    "contact_event_refund_created_batched": "Gerestitueerde bestellingen ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "{refund_amount} gerestitueerd van PrimeCancelException. Reden voor restitutie: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Uitzondering voor restitutie Prime-annulering ({count}).",
    "contact_event_register_kindle": "Kindle {serial_number} geregistreerd.",
    "contact_event_register_kindle_batched": "Kindles geregistreerd ({count}).",
    "contact_event_registry_deleted": "Register verwijderd (registernr. {registry_id}).",
    "contact_event_registry_deleted_batched": "Registers verwijderd ({count}).",
    "contact_event_release_aiv_license": "Licentie van AIV {asin} is uitgegeven op apparaat DSN {dsn}.",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} artikelen verwijderd van apparaat met DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Verwijderde artikelen van apparaten ({count}).",
    "contact_event_remove_from_yvl": "ASIN {asin} is verplaatst van Je videobibliotheek naar Verwijderde items.",
    "contact_event_remove_kindle_from_blacklist": "Kindle met DSN {dsn} verwijderd van status verloren of gestolen, reden: {removing_reason}.",
    "contact_event_remove_kindle_from_blacklist_batched": "Apparaten verwijderd uit status verloren of gestolen ({count}).",
    "contact_event_replacement_created": "Vervanging gemaakt voor bestelling {old_order_id}. Nieuwe bestelling: {new_order_id}.",
    "contact_event_replacement_created_batched": "Vervangingen gemaakt voor bestellingen ({count}).",
    "contact_event_report_seller": "Verkoper gerapporteerd voor bestelling {order_id}.",
    "contact_event_report_seller_batched": "Verkoper gerapporteerd voor bestellingen ({count}).",
    "contact_event_request_invoice": "Factuuraanvraag verzonden naar verkoper voor bestelling {order_id}.",
    "contact_event_request_invoice_batched": "Factuuraanvraag verzonden voor bestellingen ({count}).",
    "contact_event_resend_kindle_content": "Digitale inhoud {title} opnieuw verzonden naar DSN {dsn} met apparaattype {devicetype}.",
    "contact_event_resend_kindle_content_batched": "Opnieuw verzonden digitale inhoud ({count}).",
    "contact_event_resent_gifted_content": "ASIN {asin} voor bestelnummer {order_id} e-mailadres ontvanger is gewijzigd.",
    "contact_event_resent_gifted_content_batched": "E-mailadres ontvanger van geschenkbestelling bijgewerkt ({count}).",
    "contact_event_reset_password_email_sent": "E-mail voor opnieuw instellen van wachtwoord verzonden.",
    "contact_event_reset_password_email_sent_batched": "E-mail voor opnieuw instellen van wachtwoord verzonden ({count}).",
    "contact_event_residence_address_changed": "Woonadres gewijzigd.",
    "contact_event_residence_address_changed_batched": "Woonadres bijgewerkt ({count}).",
    "contact_event_retrocharge_disarmed": "RMA uitgeschakeld voor OrderID {orderId} - ASIN {asin}. Reden voor uitschakeling: {disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "RMA geannuleerd voor bestelling {order_id}.",
    "contact_event_rma_created": "RMA gemaakt voor bestelling {order_id}.",
    "contact_event_rma_created_batched": "RMA's gemaakt voor bestellingen ({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP-naam: {driverName}, \nNaam van station: {stationName}, \nTracking-ID: {trackingId} en \nBestelnummer: {orderId}\nSessie-ID voor gebruikssituatie: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Kaartwidget geopend voor tracking-ID {trackingId}",
    "contact_event_sds_package_repick": "Door medewerker geïnitieerd opnieuw ophalen voor tracking-ID {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} is naar de videobibliotheek van klant {customer_id} gestuurd.",
    "contact_event_ship_charge_reduce": "Verzendkosten van bestelling {order_id} gewijzigd van {old_amount} naar {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Verzendkosten gewijzigd ({count}).",
    "contact_event_ship_method_upgraded": "Verzendmethode voor bestelling {order_id} bijgewerkt.",
    "contact_event_ship_method_upgraded_batched": "Verzendmethoden gewijzigd voor bestellingen ({count}).",
    "contact_event_shipping_address_changed": "Adres gewijzigd op bestelling {new_order_id}.",
    "contact_event_sim_ticket_created": "SIM-ticket aangemaakt",
    "contact_event_special_offers_enrollment": "Inschrijving voor speciale aanbiedingen op Kindle (DSN-nr. {dsn}) bijgewerkt. Inschrijvingsstatus is {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Bijgewerkte inschrijvingsstatus voor speciale aanbiedingen ({count}).",
    "contact_event_subscribe_and_save_cancelled": "{subscription_id} (Abonneren en besparen) geannuleerd.",
    "contact_event_subscribe_and_save_cancelled_batched": "Abonneren en besparen geannuleerd ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Verzendschema voor {subscription_id} (Abonneren en besparen) gewijzigd.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Verzendschema's voor Abonneren en besparen gewijzigd ({count}).",
    "contact_event_subscription_astro_workflow_launch": "CS heeft Astro-annuleringsworkflow voor het {subscriptionType}-abonnement {subscriptionId} gelanceerd.",
    "contact_event_subscription_auto_renew": "Automatische verlenging voor abonnement {plan_type} ingesteld op {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Instellingen voor automatische verlenging van Prime bijgewerkt ({count}).",
    "contact_event_subscription_auto_renew_off": "CS heeft automatisch verlengen voor het {subscriptionType}-abonnement {subscriptionId} uitgeschakeld.",
    "contact_event_subscription_cancel_full_refund": "CS heeft het {subscriptionType}-abonnement {subscriptionId} opgezegd met volledige terugbetaling.",
    "contact_event_subscription_cancel_no_refund": "CS heeft het {subscriptionType}-abonnement {subscriptionId} zonder terugbetaling opgezegd via Astro.",
    "contact_event_subscription_cancel_partial_refund": "CS heeft het {subscriptionType}-abonnement {subscriptionId} opgezegd met gedeeltelijke terugbetaling.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} abonnement ingesteld op verlenging naar {new_plan_name} van {old_plan_name} aan het einde van de huidige periode.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Instellingen voor overgang naar Prime-abonnement bijgewerkt ({count}).",
    "contact_event_troubleshooting_wizard": "Probleemopsporingswizard aangeroepen voor apparaat met DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Probleemopsporingswizard aangeroepen voor apparaten ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} voor apparaat met DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Probleemopsporingswizard aangeroepen voor apparaten ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Wizard voor probleemoplossing aangeroepen voor digitale bestelling met ORDERID {digitalOrderID}.",
    "contact_event_unblock_international_purchase": "Internationale aankoopstatus gewijzigd van geblokkeerd naar {new_status}",
    "contact_event_unblock_international_purchase_batched": "Bijgewerkte status van internationale aankopen ({count}).",
    "contact_event_unlock_dash_order": "De volgende ASIN-codes voor apparaat {dsn} zijn ontgrendeld: {asins}.",
    "contact_event_update_locking_status": "De vergrendelingsstatus van Oft-apparaat  {dsn} is bijgewerkt tot: {newLockingStatus}",
    "contact_event_verify_email": "Verification e-mail has been sent to the customer.",
    "contact_event_verify_email_email_sent": "Verficatie per e-mail verzonden.",
    "contact_event_verify_email_email_sent_batched": "Verificatie per e-mail verzonden ({count}).",
    "contact_event_weekend_delivery_preference_changed": "Gewenste bezorgmethode in het weekend is bijgewerkt"
}