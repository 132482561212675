export const zh_TW = {
    "ACCESS_RESTRICTED_CONTENT": "存取受限內容",
    "ACTION": "動作",
    "ADD": "新增",
    "ADDITIONAL_CONTACT_INFO_TITLE": "其他聯絡資訊",
    "ADD_ANNOTATION_FAILURE": "無法新增注釋。",
    "ADD_ANNOTATION_INVALID": "無效的註釋！請確定註釋中沒有受限的資料。",
    "ADD_ANNOTATION_PLACEHOLDER": "新註釋",
    "ADD_ANNOTATION_SUCCESS": "已成功新增註釋。",
    "AGENT": "客服專員",
    "AGENT_ANNOTATION_BADGE": "客服專員",
    "AGENT_SELECTED_ISSUE": "客服專員選取的問題",
    "AGENT_SELECTED_ISSUE_COPIED": "已複製客服專員選取的問題。",
    "AMAZON_CONNECT_CALL_INFO": "Amazon 連結通話資訊",
    "AMAZON_CONNECT_CONTACT_ID": "Amazon 連結聯絡人 ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Amazon 連結聯絡人 ID 已複製",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon 物流",
    "ANNOTATED_BY": "由 {agentLogin} 註釋。",
    "ANNOTATIONS": "註釋",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "註釋不應包含電話號碼和電子郵件地址等客戶 PII 資料。我們的自動化系統嘗試強制執行，但有時不了解 PII 和其他 ID 之間的區別。提供需要覆寫的原因。",
    "ANNOTATION_WARNING": "根據隱私法規，註釋可能會被客戶檢閱。請勿在註釋欄位中包含個人資訊或意見。",
    "ATTACHMENTS_RESTRICTED": "附件已受限。",
    "ATTACHMENTS_TITLE": "附件",
    "ATTACHMENT_DELETION_FAILED": "刪除附件失敗，請稍後再試。",
    "ATTACHMENT_DELETION_SUCCESSFUL": "附件刪除成功。",
    "AUTHENTICATING": "已開始驗證。這可能會開啟新視窗。",
    "AUTHENTICATION_FAILURE": "驗證時發生問題。",
    "AUTHENTICATION_MESSAGE": "正在擷取新的憑證。此視窗會在幾秒鐘內關閉。",
    "AUTO_ANNOTATION_BADGE": "自動",
    "BACK": "返回",
    "BCC_EMAIL_ADDRESS": "密件副本",
    "BLURBS_USED_TITLE": "已使用的廣告",
    "CALL_LEG": "呼叫線路 ID",
    "CALL_LEG_ID_COPIED": "已複製呼叫線路 ID。",
    "CALL_RESTRICTED": "通話內容受限。",
    "CALL_RETENTION_DELETED": "記錄已超過其保留期，並已刪除。",
    "CALL_TITLE": "透過通話與 Amazon 客戶服務聯絡",
    "CANCEL": "取消",
    "CC_EMAIL_ADDRESS": "副本",
    "CF_LARGE_NUMBER_ALERT": "這位客戶的聯絡人非常多，但並非所有聯絡人皆已載入。從結束日期開始，一律以「從最近到早先」的次序載入聯絡人。變更結束日期，從不同的時間點開始載入。",
    "CHANNELS": "頻道",
    "CHAT_AGENT_CONNECTED": "{name} 已經接受聊天。",
    "CHAT_CONTACT": "聊天聯絡人。",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} 失去網路連接。",
    "CHAT_DISCONNECT_GENERIC": "{name} 已不再連線。",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} 失去網路連接。",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} 失去網路連接。",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} 已離開對話。",
    "CHAT_PARKED": "此聊天已擱置",
    "CHAT_PARKED_NOTES": "駐留備註",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} 已關閉保留。",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} 處於保留狀態。",
    "CHAT_RESTRICTED": "聊天內容受限。",
    "CHAT_TITLE": "透過在線聊天與 Amazon 客戶服務聯絡",
    "CHAT_TRANSFER_NOTES": "轉移備註：",
    "CHILD_WORK_REQUESTS": "此聯絡人建立的工作申請",
    "CHOOSE_OPTIONS": "選擇選項",
    "CLOSE": "關閉",
    "CLOSE_ALERT": "關閉警示",
    "CLOSE_MODAL_BUTTON_TEXT": "關閉",
    "COLLAPSED": "已收合",
    "CONFIRM": "確認",
    "CONTACTS": "聯絡人",
    "CONTACT_COLLAPSED": "已折疊聯絡詳細資料。",
    "CONTACT_CREATION_FAILURE": "建立新聯絡人失敗",
    "CONTACT_EXPANDED": "已展開聯絡詳細資料。",
    "CONTACT_HISTORY": "聯絡歷史記錄",
    "CONTACT_ID": "聯絡人 ID",
    "CONTACT_ID_COPIED": "已複製聯絡人 ID。",
    "CONTACT_MEDIUM_DEFAULT_TAB": "聯絡人",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "呈報",
    "CONTACT_REASSIGNED_TO_AGENT": "聯絡人已重新指派給專員。",
    "CONTACT_SELECT_CHECKBOX": "選取已鎖定聯絡人的核取方塊",
    "CONTACT_STATUS_ESCALATION_BADGE": "呈報",
    "CONTACT_TICKET": "聯絡支援單",
    "CONTACT_TICKET_CONTENT": "支援單內容",
    "CONTACT_TICKET_CREATE": "建立聯絡支援單",
    "CONTACT_TICKET_CREATION_FAILURE": "無法建立聯絡支援單",
    "CONTACT_TICKET_CREATION_SUCCESS": "已建立聯絡支援單",
    "CONTACT_TICKET_RESOLVER_GROUP": "處理者群組",
    "CONTACT_TICKET_SEVERITY": "支援單嚴重性",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM 資料夾 ID",
    "CONTACT_TICKET_SUBJECT": "支援單主題",
    "CONTACT_TIMELINE": "聯絡時間表",
    "CONTACT_TYPE": "聯絡類型",
    "CONTACT_WAS_TRANSFERRED": "此聯絡人已轉移。",
    "CONTENT": "內容",
    "CONTENT_RESTRICTED": "內容受限。",
    "COPY_AGENT_SELECTED_ISSUE": "複製客服專員選取的問題：{value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "複製 Amazon 連結聯絡人 ID",
    "COPY_CALL_LEG_ID": "複製呼叫線路 ID：{value}",
    "COPY_CONTACT_ID": "複製聯絡人 ID：{value}",
    "COPY_CUSTOMER_ID": "複製客戶 ID",
    "COPY_CUSTOMER_SELECTED_ISSUE": "複製客戶選取的問題：{value}",
    "COPY_FAILURE": "複製失敗。",
    "COPY_ID": "副本 ID",
    "COPY_LINK": "複製影像上傳連結",
    "COPY_ORDER_ID": "複製訂單 ID：{value}。",
    "CREATE_CASE": "建立個案",
    "CREATE_FOLLOW_UP": "建立追蹤",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "已成功建立追蹤，但無法新增相應的註解。重試手動新增註解。",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "指定客戶偏好的聯絡方式。",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "無法建立到期日期和時間已過的後續追蹤。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "指定追蹤動作到期的日期和時間。",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "時區",
    "CREATE_FOLLOW_UP_FAILURE": "無法建立追蹤。",
    "CREATE_FOLLOW_UP_INVALID": "無效追蹤。請確保沒有受限資料。",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "輸入追蹤動作。",
    "CREATE_FOLLOW_UP_SUCCESS": "已成功建立追蹤。",
    "CREATE_WORK_REQUEST": "建立工作申請",
    "CROSS_MARKETPLACE_QUEUES": "跨商城佇列",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "無法轉移聯絡人，因為已解決。",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "作業因為聯絡人處於無效狀態而失敗。",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "作業失敗，找不到聯絡人",
    "CSALT_ERROR_INTERNAL_ERROR": "無法鎖定聯絡人",
    "CSALT_ERROR_INVALID_ARGUMENTS": "由於引數無效，無法鎖定聯絡人",
    "CSALT_ERROR_INVALID_INPUT": "作業因無效輸入而失敗。",
    "CSALT_ERROR_INVALID_QUEUE": "無法轉移聯絡人，因為所選佇列無效。",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "作業失敗，聯絡人已鎖定給不同的專員。",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "由於相依性錯誤，無法鎖定聯絡人",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "無法轉移聯絡人，因為後端服務中找不到佇列。",
    "CSALT_ERROR_UNKNOWN": "作業因非預期錯誤而失敗。",
    "CURRENT_CONTACT": "目前聯絡人",
    "CUSTOMER": "客户",
    "CUSTOMER_FOLLOWUPS": "客戶後續追蹤",
    "CUSTOMER_FOLLOWUPS_FAILURE": "無法取得客戶的後續追蹤",
    "CUSTOMER_ID_COPIED": "已複製客戶 ID",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "客戶內容受限。(未鎖定聯絡人。)",
    "CUSTOMER_SELECTED_ISSUE": "客戶所選的問題",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "已複製客戶選取的問題。",
    "CUSTOMER_TIMEZONE": "以客戶時區顯示的時間戳記",
    "DATE": "日期",
    "DATE_PICKER_NEXT_MONTH": "下個月",
    "DATE_PICKER_PREVIOUS_MONTH": "上個月",
    "DATE_PICKER_TODAY": "今天",
    "DECODING_AUDIO": "音訊解碼中",
    "DELETE_ATTACHMENTS": "刪除聯絡人附件",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "刪除附件",
    "DELIVERY_STATUS": "送貨狀態",
    "DISABLE_CONTENT_FILTER": "請停用內容篩選條件",
    "DOWNLOAD": "下載",
    "DURATION": "期間",
    "EMAIL": "電子郵件",
    "EMAIL_ADDRESS_NOT_ALLOWED": "不允許使用收件人電子郵件地址。",
    "EMAIL_BODY": "電子郵件內文",
    "EMAIL_CONTACT": "電子郵件聯絡人。",
    "EMAIL_HEADER": "標題",
    "EMAIL_QUEUE_TITLE": "電子郵件佇列",
    "EMAIL_RESTRICTED": "電子郵件內容受限。",
    "EMAIL_SENDING_FAILURE": "向外傳送電子郵件失敗。",
    "EMAIL_SUBJECT": "電子郵件主旨",
    "EMAIL_TITLE": "透過電子郵件與 Amazon 客戶服務聯絡",
    "END": "结束",
    "END_DATE": "結束日期",
    "ERROR": "錯誤",
    "ERROR_FAILED_TO_LOAD_DATA": "無法載入資料。",
    "ESCALATION_CONTACT_HANDLING": "處理呈報聯絡人",
    "ESCALATION_TAB_LEAD_LOGIN": "主管登入：{agentLogin}",
    "EXPANDED": "已展開",
    "FAILED_TO_CREATE_WORK_REQUEST": "無法建立工作申請",
    "FAILED_TO_FETCH_SYSKA": "無法擷取 SYSKA 警示",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "無法將聯絡人重新指派給專員。",
    "FETCHED_NUMBER_CONTACTS": "已擷取 {number} 個聯絡人。",
    "FILTER": "篩選條件",
    "FINDER": "搜尋工具",
    "FOLLOW_UPS_COLLAPSED": "已收合追蹤",
    "FOLLOW_UPS_EXPANDED": "已展開追蹤",
    "FOLLOW_UP_ALERT": "有一個 {dueDate} 到期的後續追蹤，建立者為：{agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "聯絡方式",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "聯絡方式受限。",
    "FOLLOW_UP_DUE": "追蹤到期日",
    "FOLLOW_UP_DUE_DATE": "到期日期",
    "FOLLOW_UP_NOTE": "備註",
    "FOLLOW_UP_NO_CONTACT_METHOD": "無聯絡方式",
    "FOLLOW_UP_NO_NOTE": "備註為空白",
    "FOLLOW_UP_NO_TICKET": "無表單",
    "FOLLOW_UP_OWNER": "所有者",
    "FOLLOW_UP_STATUS": "狀態",
    "FOLLOW_UP_STATUS_OPEN": "開啟",
    "FOLLOW_UP_STATUS_RESOLVED": "已解決",
    "FOLLOW_UP_TICKET": "表單",
    "FOLLOW_UP_TITLE": "追蹤",
    "FROM": "開始日期",
    "GENERATE_LINK": "產生連結",
    "HAS_OVERRIDE_TRY_AGAIN": "存在篩選覆寫。請再試一次。",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "與客戶共用的影像附件連結",
    "INPUT_CHARACTERS_REMAINING": "最多 {char_max} 個字元 (剩下 {char_remain})",
    "INTERNAL_ERROR": "內部錯誤",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "發生內部錯誤。如果錯誤仍然存在，請開支援單給我們，說明問題為何。",
    "INVALID_CONTACT": "無效的聯絡人。",
    "INVALID_DATA_MATCH": "系統對資料的分類無效。",
    "INVALID_TIMESTAMP": "無效的時間戳記。",
    "IN_MARKETPLACE_QUEUES": "在商城佇列中",
    "ISSUE": "問題",
    "ISSUE_CODE": "問題代碼",
    "ISSUE_CODE_ALREADY_ADDRESSED": "已在先前的聯絡人中解決",
    "ISSUE_CODE_DESCRIPTION": "選取將選取的聯絡人設為重複的原因",
    "ISSUE_CODE_EXACTLY_SAME": "與先前的聯絡人完全相同",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "由其他聊天 CSA 處理及解決",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "由其他電子郵件 CSA 處理及解決",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "由其他電話 CSA 處理及解決",
    "ITEMS_CONTACTED_ABOUT": "與聯絡相關的商品",
    "I_UNDERSTAND": "我了解",
    "KNOWN_ISSUES": "已知問題",
    "LEARN_MORE": "了解更多資訊",
    "LINK_COPIED": "已複製連結",
    "LOADING": "正在載入資料。",
    "LOADING_PREVIEW_TEXT": "正在載入預覽",
    "LOADING_RECORDING": "載入錄音中",
    "LOAD_MORE": "載入更多",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "載入更多註釋。",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "為此客戶載入更多聯絡人。",
    "LOCKED": "已鎖定",
    "LOCKED_BY": "鎖定者",
    "LOCK_CONTACT": "鎖定",
    "LOCK_CONTACT_NOT_AUTHORIZED": "無法鎖定聯絡人，使用者未獲授權",
    "LOCK_CONTACT_SUCCESSFUL": "聯絡人已鎖定至",
    "MISSING_ORIGINAL_CONTACT_ID": "請輸入有效的聯絡人 ID",
    "MODAL_NO_BUTTON_TEXT": "否",
    "MODAL_YES_BUTTON_TEXT": "是",
    "NEXT": "下一頁",
    "NORMAL": "正常",
    "NO_AGENT_SELECTED_ISSUE": "無客服專員選取的問題。",
    "NO_ANNOTATED_BY": "由未知使用者註釋。",
    "NO_ANNOTATIONS": "沒有可用的註釋。",
    "NO_ATTACHMENTS": "沒有附件。",
    "NO_CHAT": "聊天為空白。",
    "NO_CHILD_WORK_REQUESTS": "此聯絡人未建立任何工作申請",
    "NO_CONTACT": "沒有可用的聯絡人",
    "NO_CONTACTS_SELECTED": "請選擇聯絡人，並將他們當作垃圾郵件處理",
    "NO_CONTENT": "無內容",
    "NO_CUSTOMER_SELECTED_ISSUE": "無客戶所選的問題。",
    "NO_DELIVERY_STATUS": "無送貨狀態",
    "NO_EMAIL_BODY": "電子郵件為空白。",
    "NO_EMAIL_HEADER": "無電子郵件標題。",
    "NO_EMAIL_QUEUE": "沒有與此聯絡人相關聯的電子郵件佇列。",
    "NO_ITEMS_CONTACTED_ABOUT": "沒有與聯絡相關的商品。",
    "NO_RESULTS": "查無結果。",
    "NO_SCREEN_POP": "無彈出式視窗資訊",
    "NO_SENT_FROM": "無寄件地址。",
    "NO_SENT_TO": "無送達地址",
    "NO_SUBJECT": "主旨行是空白。",
    "NO_SYSKA_ALERTS": "無 SYSKA 警示",
    "NO_USED_BLURBS": "沒有用於此聯絡人的廣告。",
    "NO_VIEW": "沒有可檢視的聯絡人或客戶。",
    "NO_WORK_CATEGORY": "沒有與此聯絡人相關聯的工作類別。",
    "NUMBER_OF_CONTACTS": "已載入聯絡人",
    "OLDEST_LOADED_CONTACT": "最早載入的聯絡人",
    "OPEN": "開啟",
    "OPENS_IN_A_NEW_TAB": "在新分頁中開啟",
    "ORDER_ID_COPIED": "已複製訂單 ID。",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "輸入原始聯絡人 ID",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "用於輸入原始聯絡人 ID 的文字方塊",
    "OTHER": "其他",
    "OUTBOUND_EMAIL_BCC": "並傳送密件副本 -",
    "OUTBOUND_EMAIL_CC": "傳送副本時 -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "通知內容已收合。",
    "OUTBOUND_NOTIFICATION_EXPANDED": "通知內容已展開。",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "已達搜尋結果數上限。請縮減日期範圍，然後重試。",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "目前的流程需要 PII 內容。",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "電話號碼、電子郵件地址或其他 PII 的相符項目無效。",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "信用卡或 IBAN 的相符項目無效。",
    "OVERRIDE_REASON_PROMPT": "註釋不應包含電話號碼和電子郵件地址等客戶 PII 資料。我們的自動化系統嘗試強制執行，但有時不了解 PII 和其他 ID 之間的區別。請提供需要覆寫的原因。",
    "PAPYRUS_SESSION_EXPLANATION": "請指定您的 Papyrus 工作階段類型，我們才能套用正確的安全性限制，並根據您的需求優化 UX。",
    "PAPYRUS_SESSION_TYPE": "Papyrus 工作階段類型",
    "PAUSE": "暫停",
    "PHONE_CONTACT": "電話聯絡人。",
    "PLAY": "播放",
    "PLAYBACK_SPEED": "播放速度",
    "POSTAL_MAIL_TITLE": "與 Amazon 客戶服務的郵遞郵件",
    "PREPARING_RECORDING": "正在準備錄製",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "無法產生聯絡人預覽",
    "PREVIOUS": "上一頁",
    "PRE_TRANSLATED_REPLY_EMAIL": "預先翻譯的回覆電子郵件",
    "PRE_TRANSLATION_REPLY_TEXT": "Rosetta 翻譯前 CSA 類型的回覆",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "處理操作",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "作為重複項目處理",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "當作垃圾郵件處理",
    "PROCESS_CONTACT_AS_DUPLICATE": "將選取的聯絡人解析為重複",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "您確定要將選取的聯絡人當做垃圾郵件處理嗎？",
    "PROCESS_DUPLICATE_FAILURE": "無法將選取的聯絡人解析為重複",
    "PROCESS_DUPLICATE_SUCCESS": "已成功將選取的聯絡人解析為重複",
    "PROCESS_SPAM_FAILURE": "無法將所選聯絡人轉移至垃圾郵件",
    "PROCESS_SPAM_SUCCESS": "成功將所選聯絡人轉移至垃圾郵件",
    "PROVIDE_ADDITIONAL_INFORMATION": "提供與此聯絡人相關的其他資訊",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "已清除聯絡人的敏感資訊：",
    "PURGE_ADD_WORD_BUTTON_TEXT": "新增字詞至清除清單",
    "PURGE_BUTTON_TEXT": "清除",
    "PURGE_CONFIRMATION_CHECK": "我已驗證上述欄位，並確認文字中確實含有敏感資訊。",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "我確認附件確實包含客戶的敏感資訊。",
    "PURGE_CONFIRMATION_UNCHECKED": "請確認核取方塊。",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "無法從聯絡人清除",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "已成功清除聯絡人的資訊",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} 並非清除的有效原因",
    "PURGE_REASON_FINANCIAL_INFO": "財務資訊",
    "PURGE_REASON_OTHER": "其他敏感資訊",
    "PURGE_REASON_PII": "個人身分識別資訊",
    "PURGE_REASON_PLACEHOLDER": "請輸入清除原因",
    "PURGE_REASON_TEXT": "清除原因",
    "PURGE_REMOVE_LABEL": "移除",
    "PURGE_SELECTOR_PLACEHOLDER": "選擇清除敏感資訊的原因",
    "PURGE_SENSITIVE_INFO": "清除敏感資訊",
    "PURGE_WORD_NOT_FOUND": "連絡人文字中找不到 Word",
    "PURPOSE_AUDITING": "品質控制用稽核",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "後台辦公室工作流程",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "這是獲取後台辦公室工作流程聯絡資料的工作階段。例如，支援單或 SIM 中的附件。",
    "PURPOSE_COACHING": "指導聯絡人審查",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "合規性調查",
    "PURPOSE_CURATION": "聯絡人管理",
    "PURPOSE_CURATION_DESCRIPTION": "此為 C2CS/VoC 聯絡人管理的工作階段。",
    "PURPOSE_DESCRIPTION_AUDITING": "此為評估稽核聯絡人品質的工作階段。",
    "PURPOSE_DESCRIPTION_COACHING": "此工作階段用以審查您指導的「效能入口網站」的專員聯絡人。",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "此為 Suspicious Activity Monitoring 調查的工作階段。",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "此工作階段用以處理呈報聯絡人。這允許您存取任何客戶的聯絡資料。",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "此為即時聯絡人處理的工作階段。",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "此目的是移除聯絡人的敏感資訊",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "此工作階段用以審查機器學習模型的準確性。",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "此工作階段用以審查您自身的過往聯絡人。",
    "PURPOSE_DESCRIPTION_UNKNOWN": "此為預設的一般用途工作階段。",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "處理呈報聯絡人",
    "PURPOSE_PURGE_SENSITIVE_INFO": "清除敏感資訊",
    "REASON_FOR_PURGE": "清除原因",
    "REASON_FOR_PURGE_DESCRIPTION": "清除附件的原因",
    "REASON_FOR_PURGE_MISSING": "請輸入刪除附件的原因。",
    "REASSIGN_TO_AGENT": "重新指派給代理",
    "RECORDING": "記錄",
    "RELOADING_CONTENT": "重新載入內容。",
    "RELOAD_LIST": "重新載入清單",
    "REPLY_BY": "回覆人",
    "REPLY_BY_EMAIL": "使用電子郵件回覆",
    "REPLY_BY_PHONE": "電話回覆",
    "REPLY_RESTRICTED": "回覆內容受限。",
    "REQUEST": "請求",
    "REQUEST_ACCESS_INSTRUCTIONS": "請提供需要存取受限內容的原因。(監控這些請求，濫用可能會產生嚴重的後果。)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "請求存取篩選的內容",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "請求存取受限內容",
    "REQUEST_NOT_GRANTED": "未授予請求。",
    "REQUEST_OVERRIDE": "請求覆寫",
    "RESEND": "重新傳送",
    "RESEND_FAILED": "重新傳送電子郵件失敗。",
    "RESEND_SUCCESS": "已成功重新傳送電子郵件。",
    "RESEND_WAIT_WARNING": "您必須等待 {wait_mins} 分鐘才能再次傳送。",
    "RESEND_WARNING": "您確定要重新傳送電子郵件給客戶嗎？",
    "RESET": "重設",
    "RESOLVED": "已解決",
    "RESOLVED_BY": "解決者",
    "RESTRICTED_DATA_MATCH": "{match} 符合類型 {type}",
    "RESTRICTED_INFORMATION": "受限資訊",
    "RETRY": "重試",
    "ROLE": "角色",
    "SCREEN_POP_DESCRIPTION_TITLE": "描述",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "否",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "是",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "向客服專員顯示",
    "SCREEN_POP_TITLE": "彈出式視窗資訊",
    "SCREEN_POP_TYPE_AUTHENTICATING": "向客服專員顯示正在驗證的彈出式視窗，並已使用過。",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "向客服專員顯示正在驗證彈出式視窗。",
    "SCREEN_POP_TYPE_CALLER_ID": "向客服專員顯示呼叫者 ID 彈出式視窗，並已使用過。",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "向客服專員顯示呼叫者 ID 彈出式視窗。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "顯示未驗證而擷取的彈出式視窗，並已使用過。",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "已向客服專員顯示未驗證而擷取的彈出式視窗。",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "客戶未知；未提供視窗彈出功能。",
    "SCREEN_POP_TYPE_OTHER": "其他",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "否",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "是",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "代理商使用",
    "SDS_TOOLS": "送貨和交貨支援工具",
    "SEARCH": "搜尋",
    "SELECTED_ROLE": "選取的角色",
    "SELECTED_VIEW": "已選擇的檢視",
    "SELECT_CHANNELS": "選擇頻道",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "選取將所選聯絡人標示為重複的問題代碼",
    "SELECT_TRANSFER_DESTINATION": "選擇轉移目的地",
    "SELECT_WORK_REQUEST_QUEUE": "選擇工作申請下載列表",
    "SENDER_EMAIL_ADDRESS": "寄件人",
    "SENDING_EMAIL": "電子郵件傳送電子郵件",
    "SEND_EMAIL": "傳送電子郵件",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "您確定要將影像上傳連結傳送給客戶嗎？",
    "SEND_OUTBOUND_EMAIL": "向外傳送電子郵件",
    "SEND_OUTBOUND_EMAIL_WARNING": "您確定要將向外傳送電子郵件至",
    "SENT_FROM": "寄件人",
    "SENT_OUTBOUND_EMAIL": "已向外傳送電子郵件。已建立新聯絡人",
    "SENT_TO": "寄送至",
    "SHIPPER_PORTAL_BUTTON_TEXT": "運輸公司入口網站",
    "SKILL": "技能",
    "SKIP_SILENCES": "跳過靜音片段",
    "SMS": "SMS",
    "SMS_LINK": "SMS 連結",
    "SOURCE_TEXT": "原文",
    "SP_BOTTOM": "底部",
    "SP_POSITION": "分割面板位置",
    "SP_PREFERENCES": "分割面板偏好設定",
    "SP_RESIZE": "調整分割面板大小",
    "SP_SIDE": "側面",
    "START": "開始",
    "STATE_TRANSITIONS": "狀態轉換",
    "STATUS": "狀態",
    "SUBJECT": "主旨",
    "SYSKA_ALERTS": "SYSKA 警示",
    "TEXT_TO_PURGE": "要清除的文字",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "須啟用第三方 Cookie。",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "建立支援單時，不允許電話號碼和電子郵件地址等客戶 PII 資料。我們的自動化系統會嘗試強制執行，但有時系統不了解 PII 和其他 ID 之間的區別。請提供需要覆寫的原因。",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "逾時：無法載入錄製",
    "TIME_TEXT_FORMAT_CONSTRAINT": "使用 24 小時格式。",
    "TO": "結束日期",
    "TO_EMAIL_ADDRESS": "收件人",
    "TRANSFER": "轉移",
    "TRANSFERRED_CHAT_TITLE": "已轉移與 Amazon 客戶服務的聊天",
    "TRANSFERRING": "傳輸中...",
    "TRANSFER_CONTACT": "轉移聯絡人",
    "TRANSFER_CONTACT_COMMENTS": "選填註釋",
    "TRANSFER_FAILED": "轉移失敗",
    "TRANSFER_FOLLOW_UP": "轉移追蹤",
    "TRANSFER_FOLLOW_UP_FAILED": "無法轉移追蹤。",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "由於新所有者登入無效，無法轉移追蹤。",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "您沒有轉移此追蹤的權限。",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "輸入新所有者的登入名稱。範例：{example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "將上述追蹤轉移至：",
    "TRANSFER_FOLLOW_UP_SUCCESS": "已成功轉移追蹤。",
    "TRANSLATED_TEXT": "譯文",
    "TRANSLATION": "翻譯",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} 不是有效的下載列表名稱。",
    "TYPE_AGENT_LOGIN": "輸入代理登入",
    "TYPE_EMAIL_ADDRESS": "輸入電子郵件地址",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "輸入多個電子郵件地址，以逗號分隔",
    "TYPE_QUEUE_NAME": "輸入佇列名稱",
    "UNKNOWN_CONTACT_TYPE": "未知的聯絡人類型。",
    "UNLOCKED": "已解鎖",
    "UNRESOLVED": "未解決",
    "URGENT_SYSKA_ALERTS": "緊急 SYSKA 警示",
    "USE": "使用",
    "USER_TIMEZONE": "以您的當地時區顯示的時間戳記",
    "USE_CONTACT_FINDER": "使用聯絡人搜尋工具",
    "VIEW": "檢視",
    "WARNING": "警告",
    "WELCOME_MESSAGE": "歡迎來到 Papyrus 靜態網站！",
    "WHAT_IS_PAPYRUS": "什麼是 Papyrus？",
    "WITH_THE_ANNOTATION": "含註解",
    "WORK_CATEGORY_TITLE": "工作類別",
    "WORK_REQUEST": "工作申請",
    "WORK_REQUEST_CONTENT": "工作申請內容",
    "WORK_REQUEST_CREATED": "已建立工作申請",
    "WORK_REQUEST_FOR_CONTACT": "聯絡人的工作申請",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "建立工作申請時，不允許電話號碼和電子郵件地址等客戶 PII 資料。我們的自動化系統嘗試強制執行，但有時不了解 PII 和其他 ID 之間的區別。請提供需要覆寫的原因。",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "您即將轉移至佇列",
    "copy_of_cs_papyrus_type_agent_login": "輸入代理登入"
}