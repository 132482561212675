import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import {ContactParticipant} from "./Contact.types";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import './ContactBoxStyle.css';


interface ContactMailMediumProps {
    sender: ContactParticipant | undefined;
    customerContentIsRestricted: boolean;
}

const ContactMailMedium: React.FC<ContactMailMediumProps> = ({customerContentIsRestricted,sender}) => {

    const emailContent: JSX.Element = (
        <div>
            <div className="contact-box">
                <div>
                    <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="SENT_FROM"/></Box>
                    {sender ?
                        <div>{sender.emailAddress}</div>
                        : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SENT_FROM")}/>
                    }
                </div>
            </div>
        </div>
    );

    return (
        <SpaceBetween size="xxxs">
            <h4 className="contact-details-header"><Translation stringId="POSTAL_MAIL_TITLE"/></h4>
            {customerContentIsRestricted ? <Alert><Translation stringId={'CONTENT_RESTRICTED'}/></Alert> : emailContent}
        </SpaceBetween>
    );
};

export default ContactMailMedium;
