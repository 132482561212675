/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Domain from './Domain';
import Alive from './Alive';
import Auth from './Auth';
import Box from "@amzn/awsui-components-react/polaris/box";

const Health: React.FC = () => {
    return (
        <div>
            <Box variant="h1">Papyrus and Phoenix Gateway Health</Box>
            <Domain/>
            <Alive/>
            <Auth/>
        </div>
    );
};

export default Health;