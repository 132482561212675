export const it_IT = {
    "ACCESS_RESTRICTED_CONTENT": "Accesso ai contenuti soggetti a restrizioni",
    "ACTION": "Azione",
    "ADD": "Aggiungi",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Ulteriori informazioni di contatto",
    "ADD_ANNOTATION_FAILURE": "Impossibile aggiungere l'annotazione.",
    "ADD_ANNOTATION_INVALID": "Annotazione non valida. Assicurati che l'annotazione non includa dati soggetti a limitazioni.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nuova annotazione",
    "ADD_ANNOTATION_SUCCESS": "Annotazione aggiunta correttamente.",
    "AGENT": "Agente",
    "AGENT_ANNOTATION_BADGE": "Agente",
    "AGENT_SELECTED_ISSUE": "Problema selezionato dall'agente",
    "AGENT_SELECTED_ISSUE_COPIED": "Problema selezionato dall'agente copiato.",
    "AMAZON_CONNECT_CALL_INFO": "Informazioni sulle chiamate Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID contatto Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID contatto Amazon Connect copiato",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Logistica di Amazon",
    "ANNOTATED_BY": "Annotato da {agentLogin}.",
    "ANNOTATIONS": "Annotazioni",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Le annotazioni non devono contenere dati PII dei clienti come numeri di telefono e indirizzi e-mail. Il nostro sistema automatizzato cerca di applicare tale regola, ma a volte non coglie la differenza tra le PII e altri ID. Specifica perché è necessaria una correzione.",
    "ANNOTATION_WARNING": "Le annotazioni possono essere soggette a revisione da parte dei clienti ai sensi delle normative sulla privacy. Non includere opinioni o informazioni personali nel campo delle annotazioni.",
    "ATTACHMENTS_RESTRICTED": "Gli allegati sono soggetti a restrizioni.",
    "ATTACHMENTS_TITLE": "Allegati",
    "ATTACHMENT_DELETION_FAILED": "Eliminazione degli allegati non riuscita, riprova più tardi.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Eliminazione allegati avvenuta correttamente.",
    "AUTHENTICATING": "Autenticazione avviata. Potrebbe aprirsi una nuova finestra.",
    "AUTHENTICATION_FAILURE": "Si è verificato un problema durante l'autenticazione.",
    "AUTHENTICATION_MESSAGE": "Recupero di nuove credenziali. Questa finestra si chiuderà fra pochi secondi.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Indietro",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Risposte predefinite utilizzate",
    "CALL_LEG": "Id coda chiamate:",
    "CALL_LEG_ID_COPIED": "Id coda chiamate copiato.",
    "CALL_RESTRICTED": "Il contenuto delle chiamate è soggetto a limitazioni.",
    "CALL_RETENTION_DELETED": "La registrazione ha superato il periodo di conservazione ed è stata eliminata.",
    "CALL_TITLE": "Chiama il Servizio clienti Amazon",
    "CANCEL": "Annulla",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Questo cliente ha un numero molto elevato di contatti e non tutti sono stati caricati. I contatti vengono sempre caricati nell'ordine \"dal più recente al meno recente\" a partire dalla data di fine. Modifica la data di fine per iniziare il caricamento da un momento diverso.",
    "CHANNELS": "Canali",
    "CHAT_AGENT_CONNECTED": "{name} ha accettato la chat.",
    "CHAT_CONTACT": "Contatto chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} ha perso la connessione con la rete.",
    "CHAT_DISCONNECT_GENERIC": "{name} non è più connesso.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} ha perso la connessione con la rete.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} ha perso la connessione con la rete.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} ha abbandonato la conversazione.",
    "CHAT_PARKED": "Questa chat è stata sospesa",
    "CHAT_PARKED_NOTES": "Note sulla pausa",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} non è più in attesa.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} è in attesa.",
    "CHAT_RESTRICTED": "I contenuti della chat sono soggetti a limitazioni.",
    "CHAT_TITLE": "Chatta con il Servizio clienti Amazon",
    "CHAT_TRANSFER_NOTES": "Note trasferimento",
    "CHILD_WORK_REQUESTS": "Richieste di lavoro create da questo contatto",
    "CHOOSE_OPTIONS": "Scegli le opzioni",
    "CLOSE": "Chiudi",
    "CLOSE_ALERT": "Chiudi avviso",
    "CLOSE_MODAL_BUTTON_TEXT": "Chiudi",
    "COLLAPSED": "Ridotto a icona",
    "CONFIRM": "Conferma",
    "CONTACTS": "Contatti",
    "CONTACT_COLLAPSED": "I dettagli di contatto sono stati ridotti a icona.",
    "CONTACT_CREATION_FAILURE": "Creazione di nuovi contatti non riuscita",
    "CONTACT_EXPANDED": "Dettagli di contatto espansi.",
    "CONTACT_HISTORY": "Cronologia contatti",
    "CONTACT_ID": "ID contatto",
    "CONTACT_ID_COPIED": "ID contatto copiato.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contatto",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Inoltri a un livello superiore",
    "CONTACT_REASSIGNED_TO_AGENT": "Il contatto è stato riassegnato all'agente.",
    "CONTACT_SELECT_CHECKBOX": "Casella di controllo per selezionare un contatto bloccato",
    "CONTACT_STATUS_ESCALATION_BADGE": "Inoltro a un livello superiore",
    "CONTACT_TICKET": "Ticket sul contatto",
    "CONTACT_TICKET_CONTENT": "Contenuto ticket",
    "CONTACT_TICKET_CREATE": "Crea ticket del contatto",
    "CONTACT_TICKET_CREATION_FAILURE": "Impossibile creare il ticket del contatto",
    "CONTACT_TICKET_CREATION_SUCCESS": "Ticket del contatto creato",
    "CONTACT_TICKET_RESOLVER_GROUP": "Gruppo di risoluzione",
    "CONTACT_TICKET_SEVERITY": "Gravità ticket",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID cartella SIM",
    "CONTACT_TICKET_SUBJECT": "Oggetto del ticket",
    "CONTACT_TIMELINE": "Timeline contatti",
    "CONTACT_TYPE": "Tipo di contatto",
    "CONTACT_WAS_TRANSFERRED": "Il contatto è stato trasferito.",
    "CONTENT": "Contenuto",
    "CONTENT_RESTRICTED": "Il contenuto è soggetto a limitazioni.",
    "COPY_AGENT_SELECTED_ISSUE": "Copia problema selezionato dell'agente: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copia l'ID contatto Amazon Connect",
    "COPY_CALL_LEG_ID": "Copia ID coda chiamate: {value}",
    "COPY_CONTACT_ID": "Copia ID contatto: {value}",
    "COPY_CUSTOMER_ID": "Copia ID cliente",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copia problema selezionato dal cliente: {value}",
    "COPY_FAILURE": "Impossibile copiare.",
    "COPY_ID": "ID copia",
    "COPY_LINK": "Link per il caricamento delle immagini",
    "COPY_ORDER_ID": "Copia order ID: {value}.",
    "CREATE_CASE": "Crea caso",
    "CREATE_FOLLOW_UP": "Crea follow-up",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Follow-up creato correttamente, ma non è stato possibile aggiungere l'annotazione corrispondente. Riprova ad aggiungere manualmente l'annotazione.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Specifica il metodo di contatto preferito dal cliente.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Impossibile creare un follow-up con una data e un'ora di scadenza passate.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Specifica la data e l'ora di scadenza dell'operazione di follow-up.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Fuso orario",
    "CREATE_FOLLOW_UP_FAILURE": "Impossibile creare un followup.",
    "CREATE_FOLLOW_UP_INVALID": "Follow-up non valido. Assicurati che non ci siano dati limitati.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Specifica l'operazione di follow-up.",
    "CREATE_FOLLOW_UP_SUCCESS": "Follow-up creato correttamente.",
    "CREATE_WORK_REQUEST": "Crea richiesta di lavoro",
    "CROSS_MARKETPLACE_QUEUES": "Code di più marketplace",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Impossibile trasferire il contatto perché è già stato risolto.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "L'operazione non è riuscita perché il contatto è in uno stato non valido.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Operazione non riuscita perché il contatto non è stato trovato",
    "CSALT_ERROR_INTERNAL_ERROR": "Impossibile bloccare il contatto",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Impossibile bloccare un contatto a causa di argomenti non validi",
    "CSALT_ERROR_INVALID_INPUT": "L'operazione non è riuscita a causa di input non validi.",
    "CSALT_ERROR_INVALID_QUEUE": "Impossibile trasferire il contatto perché la coda selezionata non è valida.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Operazione non riuscita perché il contatto è bloccato su un altro agente.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Impossibile bloccare il contatto a causa di un errore di dipendenza",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Impossibile trasferire il contatto perché la coda non è stata trovata nel servizio backend.",
    "CSALT_ERROR_UNKNOWN": "L'operazione non è riuscita a causa di un errore imprevisto.",
    "CURRENT_CONTACT": "Contatto attuale",
    "CUSTOMER": "Cliente",
    "CUSTOMER_FOLLOWUPS": "Follow-up per il cliente",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Impossibile recuperare i follow-up per il cliente",
    "CUSTOMER_ID_COPIED": "ID cliente copiato",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Il contenuto del cliente è soggetto a limitazioni. (Nessun contatto bloccato).",
    "CUSTOMER_SELECTED_ISSUE": "Problema selezionato dal cliente",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Problema selezionato dal cliente copiato.",
    "CUSTOMER_TIMEZONE": "Timestamp visualizzati nel fuso orario del cliente",
    "DATE": "Data",
    "DATE_PICKER_NEXT_MONTH": "Mese successivo",
    "DATE_PICKER_PREVIOUS_MONTH": "Mese precedente",
    "DATE_PICKER_TODAY": "Oggi",
    "DECODING_AUDIO": "Decodifica audio",
    "DELETE_ATTACHMENTS": "Elimina allegati del contatto",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Elimina allegati",
    "DELIVERY_STATUS": "Stato consegna",
    "DISABLE_CONTENT_FILTER": "Disabilita filtro contenuti",
    "DOWNLOAD": "Scarica",
    "DURATION": "Durata",
    "EMAIL": "E-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Gli indirizzi e-mail dei destinatari non sono consentiti.",
    "EMAIL_BODY": "Corpo dell'e-mail",
    "EMAIL_CONTACT": "Contatto e-mail.",
    "EMAIL_HEADER": "Intestazione",
    "EMAIL_QUEUE_TITLE": "Coda e-mail",
    "EMAIL_RESTRICTED": "Il contenuto delle e-mail è soggetto a limitazioni.",
    "EMAIL_SENDING_FAILURE": "Invio e-mail in uscita non riuscito.",
    "EMAIL_SUBJECT": "Oggetto dell’e-mail",
    "EMAIL_TITLE": "Invia un'e-mail al Servizio clienti Amazon",
    "END": "Fine",
    "END_DATE": "Data di fine",
    "ERROR": "Errore",
    "ERROR_FAILED_TO_LOAD_DATA": "Impossibile caricare i dati.",
    "ESCALATION_CONTACT_HANDLING": "Gestione del contatto di escalation",
    "ESCALATION_TAB_LEAD_LOGIN": "Accesso lead: {agentLogin}",
    "EXPANDED": "Espanso",
    "FAILED_TO_CREATE_WORK_REQUEST": "Impossibile creare la richiesta di lavoro",
    "FAILED_TO_FETCH_SYSKA": "Impossibile recuperare i SYSKA alert",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Impossibile riassegnare il contatto all'agente.",
    "FETCHED_NUMBER_CONTACTS": "{number} contatti recuperati.",
    "FILTER": "Filtro",
    "FINDER": "Strumento di ricerca",
    "FOLLOW_UPS_COLLAPSED": "Follow-up ridotti a icona",
    "FOLLOW_UPS_EXPANDED": "Follow-up espansi",
    "FOLLOW_UP_ALERT": "ha un follow-up in scadenza il giorno {dueDate} creato da {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Metodo di contatto",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Il metodo di contatto è soggetto a limitazioni.",
    "FOLLOW_UP_DUE": "Follow-up scaduto",
    "FOLLOW_UP_DUE_DATE": "Data di scadenza",
    "FOLLOW_UP_NOTE": "Nota",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Nessun metodo di contatto",
    "FOLLOW_UP_NO_NOTE": "Il campo Nota è vuoto",
    "FOLLOW_UP_NO_TICKET": "Nessun ticket",
    "FOLLOW_UP_OWNER": "Titolare",
    "FOLLOW_UP_STATUS": "Stato",
    "FOLLOW_UP_STATUS_OPEN": "Apri",
    "FOLLOW_UP_STATUS_RESOLVED": "Risolto",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Follow-up",
    "FROM": "Da",
    "GENERATE_LINK": "Genera link",
    "HAS_OVERRIDE_TRY_AGAIN": "Correzione filtro presente. Riprova.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "link dell’immagine allegata condiviso con il cliente",
    "INPUT_CHARACTERS_REMAINING": "Massimo {char_max} caratteri ({char_remain} rimanenti)",
    "INTERNAL_ERROR": "Errore interno",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Si è verificato un errore interno. Se l'errore persiste, inviaci un ticket per spiegare il problema.",
    "INVALID_CONTACT": "Contatto non valido.",
    "INVALID_DATA_MATCH": "Il sistema ha effettuato una classificazione dei dati non valida.",
    "INVALID_TIMESTAMP": "Indicazione data/ora non valida.",
    "IN_MARKETPLACE_QUEUES": "Code del marketplace",
    "ISSUE": "Problema",
    "ISSUE_CODE": "Codice problema",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Già affrontato e risolto in un contatto precedente",
    "ISSUE_CODE_DESCRIPTION": "Seleziona il motivo per rendere i contatti selezionati come duplicati",
    "ISSUE_CODE_EXACTLY_SAME": "Esattamente come per un contatto precedente",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Gestito e risolto da un altro CSA via chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Gestito e risolto da un altro CSA via e-mail",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Gestito e risolto da un altro CSA via telefono",
    "ITEMS_CONTACTED_ABOUT": "Articoli su cui sono state richieste informazioni",
    "I_UNDERSTAND": "Accetto",
    "KNOWN_ISSUES": "Problemi noti",
    "LEARN_MORE": "Ottieni maggiori informazioni",
    "LINK_COPIED": "Link copiato",
    "LOADING": "Caricamento dei dati.",
    "LOADING_PREVIEW_TEXT": "Caricamento anteprima",
    "LOADING_RECORDING": "Caricamento registrazione",
    "LOAD_MORE": "Carica altro",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Carica altre annotazioni.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Carica altri contatti per questo cliente.",
    "LOCKED": "Bloccato",
    "LOCKED_BY": "Bloccato da",
    "LOCK_CONTACT": "Blocca",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Impossibile bloccare il contatto. L'utente non è autorizzato",
    "LOCK_CONTACT_SUCCESSFUL": "Contatto bloccato su",
    "MISSING_ORIGINAL_CONTACT_ID": "Inserisci un ID contatto originale valido",
    "MODAL_NO_BUTTON_TEXT": "No",
    "MODAL_YES_BUTTON_TEXT": "Sì",
    "NEXT": "Successivo",
    "NORMAL": "Normale",
    "NO_AGENT_SELECTED_ISSUE": "Nessun problema selezionato dall'agente.",
    "NO_ANNOTATED_BY": "Annotato da un utente sconosciuto.",
    "NO_ANNOTATIONS": "Nessuna annotazione disponibile.",
    "NO_ATTACHMENTS": "Non ci sono allegati.",
    "NO_CHAT": "La chat è vuota.",
    "NO_CHILD_WORK_REQUESTS": "Nessuna richiesta di lavoro creata da questo contatto",
    "NO_CONTACT": "Nessun contatto disponibile",
    "NO_CONTACTS_SELECTED": "Seleziona i contatti per gestirli come spam",
    "NO_CONTENT": "Nessun contenuto",
    "NO_CUSTOMER_SELECTED_ISSUE": "Nessun problema selezionato dal cliente.",
    "NO_DELIVERY_STATUS": "Nessun stato di consegna",
    "NO_EMAIL_BODY": "Il campo dell'e-mail è vuoto.",
    "NO_EMAIL_HEADER": "L'e-mail non contiene un'intestazione.",
    "NO_EMAIL_QUEUE": "Nessuna coda e-mail associata a questo contatto.",
    "NO_ITEMS_CONTACTED_ABOUT": "Non è stata richiesta alcuna informazione su nessun articolo.",
    "NO_RESULTS": "Nessun risultato trovato.",
    "NO_SCREEN_POP": "Nessuna informazione sullo screen pop",
    "NO_SENT_FROM": "Nessun indirizzo del mittente.",
    "NO_SENT_TO": "Nessun indirizzo del destinatario",
    "NO_SUBJECT": "La riga dell'oggetto è vuota.",
    "NO_SYSKA_ALERTS": "Nessun SYSKA alert",
    "NO_USED_BLURBS": "Nessuna risposta predefinita utilizzata per questo contatto.",
    "NO_VIEW": "Nessun contatto o cliente da visualizzare.",
    "NO_WORK_CATEGORY": "Nessuna categoria di lavoro associata a questo contatto.",
    "NUMBER_OF_CONTACTS": "Contatti caricati",
    "OLDEST_LOADED_CONTACT": "Contatto caricato meno recente",
    "OPEN": "Apri",
    "OPENS_IN_A_NEW_TAB": "Si apre in una nuova scheda",
    "ORDER_ID_COPIED": "Order ID copiato.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Inserisci ID contatto originale",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Casella di testo per inserire l’ID contatto originale",
    "OTHER": "Altro",
    "OUTBOUND_EMAIL_BCC": "e in copia nascosta...",
    "OUTBOUND_EMAIL_CC": "mentre metti in copia...",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Contenuto della notifica ridotto a icona.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Contenuto della notifica espanso.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Numero massimo di risultati raggiunti. Riduci l'intervallo di date valido e riprova.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Il processo corrente richiede contenuti PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Corrispondenza non valida come Numero di telefono, indirizzo e-mail o altre PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Corrispondenza non valida come carta di credito o IBAN.",
    "OVERRIDE_REASON_PROMPT": "Le annotazioni non devono contenere dati PII dei clienti come numeri di telefono e indirizzi e-mail. Il nostro sistema automatizzato cerca di applicare tale regola, ma a volte non coglie la differenza tra le PII e altri ID. Specifica perché è necessaria una correzione.",
    "PAPYRUS_SESSION_EXPLANATION": "Si prega di specificare il tipo di sessione Papyrus in modo da poter applicare le giuste restrizioni di sicurezza e ottimizzare l'UX per le vostre esigenze.",
    "PAPYRUS_SESSION_TYPE": "Tipo di sessione Papyrus",
    "PAUSE": "Sospendi",
    "PHONE_CONTACT": "Contatto telefonico.",
    "PLAY": "Riproduci",
    "PLAYBACK_SPEED": "Velocità di riproduzione",
    "POSTAL_MAIL_TITLE": "Scrivi via posta al servizio clienti Amazon",
    "PREPARING_RECORDING": "Preparazione della registrazione in corso",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Impossibile creare l'anteprima del contatto",
    "PREVIOUS": "Precedente",
    "PRE_TRANSLATED_REPLY_EMAIL": "E-mail di risposta pre-tradotta",
    "PRE_TRANSLATION_REPLY_TEXT": "Risposta digitata da CSA prima della traduzione di Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Azioni di elaborazione",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Elabora come duplicato",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Elabora come spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Risolvi i contatti selezionati come duplicati",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Sei sicuro di voler elaborare i contatti selezionati come spam?",
    "PROCESS_DUPLICATE_FAILURE": "Impossibile risolvere i contatti selezionati come duplicati",
    "PROCESS_DUPLICATE_SUCCESS": "Contatti selezionati risolti correttamente come duplicati",
    "PROCESS_SPAM_FAILURE": "Impossibile trasferire i contatti selezionati nella cartella Spam",
    "PROCESS_SPAM_SUCCESS": "Trasferimento corretto dei contatti selezionati nella cartella Spam",
    "PROVIDE_ADDITIONAL_INFORMATION": "Fornisci ulteriori informazioni relative a questo contatto",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Informazioni sensibili eliminate dal contatto:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Aggiungi parola alla lista di eliminazione",
    "PURGE_BUTTON_TEXT": "Elimina",
    "PURGE_CONFIRMATION_CHECK": "Ho verificato i campi sopra riportati e confermo che il testo contiene effettivamente informazioni sensibili.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Confermo che l'allegato contiene informazioni sensibili sul cliente.",
    "PURGE_CONFIRMATION_UNCHECKED": "Conferma la casella di controllo.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Eliminazione dal contatto non riuscita",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Informazioni eliminate correttamente dal contatto",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} non è un motivo valido per l'eliminazione",
    "PURGE_REASON_FINANCIAL_INFO": "Informazioni finanziarie",
    "PURGE_REASON_OTHER": "Altre informazioni sensibili",
    "PURGE_REASON_PII": "Informazioni di identificazione personale",
    "PURGE_REASON_PLACEHOLDER": "Inserisci il motivo della rimozione",
    "PURGE_REASON_TEXT": "Motivo dell'eliminazione",
    "PURGE_REMOVE_LABEL": "Rimuovi",
    "PURGE_SELECTOR_PLACEHOLDER": "Seleziona il motivo dell'eliminazione delle informazioni sensibili",
    "PURGE_SENSITIVE_INFO": "Elimina informazioni sensibili",
    "PURGE_WORD_NOT_FOUND": "La parola non è presente nel testo del contatto",
    "PURPOSE_AUDITING": "Audit per il controllo qualità",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Flusso di lavoro di Back Office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Questa è la sessione per ottenere i dati di contatto per i flussi di lavoro di Back Office. Ad esempio, allegati da includere nei ticket o nella SIM.",
    "PURPOSE_COACHING": "Revisione dei contatti di coaching",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Indagine sulla conformità",
    "PURPOSE_CURATION": "Gestione dei contatti",
    "PURPOSE_CURATION_DESCRIPTION": "Questa è la sessione per la gestione dei contatti C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Questa è la sessione per controllare i contatti e valutarne la qualità.",
    "PURPOSE_DESCRIPTION_COACHING": "Questa è la sessione per la revisione dei contatti degli agenti di cui si è coach di Performance Portal.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Questa è la sessione delle indagini Suspicious Activity Monitoring.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Questa è la sessione per la gestione dei contatti di escalation. Ciò consente di accedere ai dati di contatto del cliente.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Questa è la sessione per la gestione dei contatti in tempo reale.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Lo scopo è quello di rimuovere le informazioni sensibili da un contatto",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Questa è la sessione per la revisione della precisione del modello di apprendimento automatico.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Questa è la sessione per rivedere i tuoi contatti passati.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Questa è la sessione generica predefinita.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Gestione del contatto di escalation",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Elimina informazioni sensibili",
    "REASON_FOR_PURGE": "Motivo della rimozione",
    "REASON_FOR_PURGE_DESCRIPTION": "Motivo della rimozione degli allegati",
    "REASON_FOR_PURGE_MISSING": "Inserisci il motivo dell'eliminazione degli allegati.",
    "REASSIGN_TO_AGENT": "Riassegna all'agente",
    "RECORDING": "Registrazione",
    "RELOADING_CONTENT": "Ricarica dei contenuti in corso.",
    "RELOAD_LIST": "Ricarica elenco",
    "REPLY_BY": "Rispondi per",
    "REPLY_BY_EMAIL": "Rispondi per e-mail",
    "REPLY_BY_PHONE": "Rispondi per telefono",
    "REPLY_RESTRICTED": "Il contenuto della risposta è soggetto a limitazioni.",
    "REQUEST": "Richiesta",
    "REQUEST_ACCESS_INSTRUCTIONS": "Specifica perché è richiesto l'accesso a contenuti soggetti a restrizioni. (Queste richieste sono monitorate e gli abusi possono avere gravi conseguenze.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Richiedi l'accesso ai contenuti con filtri",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Richiedi l'accesso a contenuti soggetti a restrizioni",
    "REQUEST_NOT_GRANTED": "La richiesta non è stata accolta.",
    "REQUEST_OVERRIDE": "Richiedi correzione",
    "RESEND": "Invia nuovamente",
    "RESEND_FAILED": "Impossibile inviare nuovamente il messaggio di posta elettronica.",
    "RESEND_SUCCESS": "Email reinviata correttamente.",
    "RESEND_WAIT_WARNING": "È necessario attendere {wait_mins} minuti per inviare nuovamente.",
    "RESEND_WARNING": "Sei sicuro di voler inviare nuovamente l'e-mail al cliente?",
    "RESET": "Reimposta",
    "RESOLVED": "Risolto",
    "RESOLVED_BY": "Risolto da",
    "RESTRICTED_DATA_MATCH": "{match} tipo di corrispondenza {type}",
    "RESTRICTED_INFORMATION": "INFORMAZIONI SOGGETTE A LIMITAZIONI",
    "RETRY": "Riprova",
    "ROLE": "Ruolo",
    "SCREEN_POP_DESCRIPTION_TITLE": "Descrizione",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "No",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Sì",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Visualizzata dall'agente",
    "SCREEN_POP_TITLE": "Informazioni sulla screen pop",
    "SCREEN_POP_TYPE_AUTHENTICATING": "La screen pop di autenticazione è stata visualizzata e utilizzata dall'agente.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "La screen pop di autenticazione è stata visualizzata dall'agente.",
    "SCREEN_POP_TYPE_CALLER_ID": "La screen pop dell'ID chiamante è stata visualizzata e utilizzata dall'agente.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Lo screen pop dell'ID chiamante è stato visualizzato all'agente.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "È stata visualizzata e utilizzata una screen pop non autenticata.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Una screen pop non autenticata è stata visualizzata dall'agente.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Il cliente è sconosciuto; nessuna screen pop fornita.",
    "SCREEN_POP_TYPE_OTHER": "Altro",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "No",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Sì",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Usato dall'agente",
    "SDS_TOOLS": "Strumenti di supporto per spedizione e consegna",
    "SEARCH": "Cerca",
    "SELECTED_ROLE": "Ruolo selezionato",
    "SELECTED_VIEW": "Vista selezionata",
    "SELECT_CHANNELS": "Seleziona canali",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Per selezionare il codice problema per contrassegnare i contatti selezionati come duplicati",
    "SELECT_TRANSFER_DESTINATION": "Seleziona la destinazione del trasferimento",
    "SELECT_WORK_REQUEST_QUEUE": "Seleziona coda richieste di lavoro",
    "SENDER_EMAIL_ADDRESS": "Mittente",
    "SENDING_EMAIL": "Invio e-mail in corso",
    "SEND_EMAIL": "Invia e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Sei sicuro di voler inviare un link per il caricamento delle immagini al cliente?",
    "SEND_OUTBOUND_EMAIL": "Invia e-mail in uscita",
    "SEND_OUTBOUND_EMAIL_WARNING": "Sei sicuro di voler inviare l’e-mail in uscita a",
    "SENT_FROM": "Inviato da",
    "SENT_OUTBOUND_EMAIL": "È stata inviata un'e-mail in uscita. È stato creato un nuovo contatto",
    "SENT_TO": "Inviato a",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portale spedizioniere",
    "SKILL": "Skill",
    "SKIP_SILENCES": "Salta silenzi",
    "SMS": "SMS",
    "SMS_LINK": "Link tramite SMS",
    "SOURCE_TEXT": "Testo originale",
    "SP_BOTTOM": "Inferiore",
    "SP_POSITION": "Posizione pannello diviso",
    "SP_PREFERENCES": "Preferenze del pannello diviso",
    "SP_RESIZE": "Ridimensiona pannello diviso",
    "SP_SIDE": "Laterale",
    "START": "Inizia",
    "STATE_TRANSITIONS": "Transizioni di stato",
    "STATUS": "Stato",
    "SUBJECT": "Oggetto",
    "SYSKA_ALERTS": "SYSKA alert",
    "TEXT_TO_PURGE": "Testo da eliminare",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "I cookie di terze parti devono essere abilitati.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "I dati PII dei clienti, come numeri di telefono e indirizzi e-mail, non sono consentiti durante la creazione di un ticket. Il nostro sistema automatizzato cerca di applicare tale regola, ma a volte non coglie la differenza tra i dati PII e altri ID. Specifica perché è necessaria una correzione.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Timeout: caricamento della registrazione non riuscito",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Usa il formato 24 ore.",
    "TO": "A",
    "TO_EMAIL_ADDRESS": "A",
    "TRANSFER": "Trasferisci",
    "TRANSFERRED_CHAT_TITLE": "Chat trasferita al Servizio clienti Amazon",
    "TRANSFERRING": "Trasferimento in corso...",
    "TRANSFER_CONTACT": "Trasferisci contatto",
    "TRANSFER_CONTACT_COMMENTS": "Annotazione opzionale",
    "TRANSFER_FAILED": "Trasferimento non riuscito",
    "TRANSFER_FOLLOW_UP": "Trasferisci follow-up",
    "TRANSFER_FOLLOW_UP_FAILED": "Impossibile trasferire il follow-up.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Impossibile trasferire il follow-up a causa di un nuovo accesso del proprietario non valido.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Non disponi dell'autorizzazione per trasferire questo follow-up.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Immetti il nome di accesso del nuovo proprietario. Esempio: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Trasferire il seguente follow-up a:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Follow-up trasferito con successo.",
    "TRANSLATED_TEXT": "Testo tradotto",
    "TRANSLATION": "Traduzione",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} non è un nome di coda valido.",
    "TYPE_AGENT_LOGIN": "Digita accesso agente",
    "TYPE_EMAIL_ADDRESS": "Digita l'indirizzo e-mail",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Digita più indirizzi e-mail separati da virgole",
    "TYPE_QUEUE_NAME": "Digita il nome della coda",
    "UNKNOWN_CONTACT_TYPE": "Tipo di contatto sconosciuto.",
    "UNLOCKED": "Sbloccato",
    "UNRESOLVED": "Non risolto",
    "URGENT_SYSKA_ALERTS": "SYSKA alert urgenti",
    "USE": "Utilizza",
    "USER_TIMEZONE": "Timestamp visualizzati nel fuso orario locale",
    "USE_CONTACT_FINDER": "Usa lo strumento di ricerca contatti",
    "VIEW": "Visualizza",
    "WARNING": "Attenzione",
    "WELCOME_MESSAGE": "Benvenuto nel sito Web Papyrus Static!",
    "WHAT_IS_PAPYRUS": "Che cos'è Papyrus?",
    "WITH_THE_ANNOTATION": "con l'annotazione",
    "WORK_CATEGORY_TITLE": "Categoria di lavoro",
    "WORK_REQUEST": "Richiesta di lavoro",
    "WORK_REQUEST_CONTENT": "Contenuto della richiesta di lavoro",
    "WORK_REQUEST_CREATED": "Richiesta di lavoro creata",
    "WORK_REQUEST_FOR_CONTACT": "Richiesta di lavoro di contatto",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "I dati PII dei clienti, come numeri di telefono e indirizzi e-mail, non sono consentiti durante la creazione di una richiesta di lavoro. Il nostro sistema automatizzato cerca di applicare tale regola, ma a volte non coglie la differenza tra i dati PII e altri ID. Specifica perché è necessaria una correzione.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Stai per effettuare il trasferimento alla coda",
    "copy_of_cs_papyrus_type_agent_login": "Digita accesso agente"
}