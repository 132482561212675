import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import {ContactParticipant, RosettaTranslatedEmail} from "./Contact.types";
import EmailBody from "./EmailBody";
import Translation from "../i18n/Translate";
import EmdashImage from "../util/EmdashImage";
import IntlManager from "../i18n/IntlManager";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import './ContactBoxStyle.css';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import {Text} from "../util/Text";
import EmailBodyWithTranslation from "./EmailBodyWithTranslation";
import {AllHtmlEntities} from 'html-entities';


interface ContactEmailMediumProps {
    emailSubject: string | undefined;
    emailHeader: string | undefined;
    emailBodyHTMLSafe: string | undefined;
    sender: ContactParticipant | undefined;
    customerContentIsRestricted: boolean;
    displayOnlyEmailText: boolean;
    rosettaTranslatedInboundEmail?: RosettaTranslatedEmail;
}

const ContactEmailMedium: React.FC<ContactEmailMediumProps> = ({emailSubject, emailHeader, emailBodyHTMLSafe,
                                                                   sender, customerContentIsRestricted, displayOnlyEmailText,
                                                                   rosettaTranslatedInboundEmail}) => {

    const emailContent: JSX.Element = (
        <div>
            {
                displayOnlyEmailText ? null :
                    <ExpandableSection
                        header={<Translation stringId={"EMAIL_HEADER"}/>}
                    >
                        {emailHeader ? <div style={{whiteSpace: "pre-wrap"}} className="contact-box">{emailHeader}</div>
                            : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_EMAIL_HEADER")}/>
                        }
                    </ExpandableSection>
            }

            <div className="contact-box">
                <div>
                    <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="SENT_FROM"/></Box>
                    {sender ?
                        <div>{sender.emailAddress}</div>
                        : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SENT_FROM")}/>
                    }
                </div>
                <div>
                    <Box margin={{"bottom":"xxxs"}} color="text-label" fontWeight="bold"><Translation stringId="SUBJECT"/></Box>
                    {emailSubject ?
                        <Text text={AllHtmlEntities.decode(emailSubject)}/>
                        : <EmdashImage ariaLabel={IntlManager.sharedManager.formatMessage("NO_SUBJECT")}/>
                    }
                </div>
                {
                    (emailBodyHTMLSafe && rosettaTranslatedInboundEmail?.emailBodyHTMLSafe?.encryptedString) ?
                    <EmailBodyWithTranslation
                        htmlSafePreTranslationCSAText={emailBodyHTMLSafe}
                        htmlSafeEmailBody={rosettaTranslatedInboundEmail.emailBodyHTMLSafe.encryptedString}/> :
                    <EmailBody htmlSafeEmailBody={emailBodyHTMLSafe}/>
                }
            </div>
        </div>
    );

    return (
        <SpaceBetween size="xxxs">
            {displayOnlyEmailText ? null : <h4 className="contact-details-header"><Translation stringId="EMAIL_TITLE"/></h4>}
            {customerContentIsRestricted ? <Alert><Translation stringId={'EMAIL_RESTRICTED'}/></Alert> : emailContent}
        </SpaceBetween>
    );
};

export default ContactEmailMedium;
