import React from 'react';
import CSCentralURL from "../issueManager/CSCentralURL";
import {AllHtmlEntities} from 'html-entities';
import ReactDOMServer from "react-dom/server";
import {ALLOWED_DOMAINS} from "./AllowedDomains";

interface TextProps {
    text: string;
    tag?: keyof JSX.IntrinsicElements;
    keepNewline?: boolean;
}

const anchorTagPattern = new RegExp(/<a.*?<\/a>/);
// https://stackoverflow.com/questions/6038061/regular-expression-to-find-urls-within-a-string
const urlPattern = new RegExp(/(?:http|ftp|https):\/\/(?:[\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:/~+#;-]*[\w@?^=%&/~+#;-])?/);
const anchorOrUrlPattern = new RegExp('(' + anchorTagPattern.source + '|' + urlPattern.source +')');
// order ID regex from https://tiny.amazon.com/glc7vj30/LinkConverterpm-C
const orderIdPattern = new RegExp(/(\w\d{2}-\d{7}-\d{7})/);

const emailAddressRegex = "[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}"; //https://www.regular-expressions.info/email.html
const emailAddressRestrictedRegex = "RESTRICTED:EMAIL_ADDRESS";
const emailAddressMayOrMayNotBeRestrictedRegex = "(" + emailAddressRegex + "|" + emailAddressRestrictedRegex + ")";
const emailAddressEnclosingHTMLTagsRegex = "<" + emailAddressMayOrMayNotBeRestrictedRegex + ">";
const pattern = new RegExp(emailAddressEnclosingHTMLTagsRegex, "ig"); //case-insensitive + global matches


/**
 * React component to render a string in the provided HTML tag. Default: "div"
 *
 * 1. Creates anchor tags for order IDs directed to CSC Order Details page
 * 2. Creates anchor tags for URL in the allowed domains list
 */
export const Text: React.FC<TextProps> = ({text, tag = "div", keepNewline = false}) => {
    const Component = tag;
    // Do nothing if there is no CSC URL. This should not happen while we have Papyrus running in CSC.
    const processedText = CSCentralURL.sharedCSCentralURL.domain ? createAllowedLinks(text) : text;
    return <Component style={keepNewline ? { whiteSpace: "pre-line"} : {}}>{processedText}</Component>;
};

/**
 * Returns a string to be used in the dangerouslySetInnerHTML attribute.
 *
 * 1. Creates anchor tags for URLs that are part of the allow list.
 * 2. Creates anchor tags for order IDs directed to CSC Order Details page
 * 3. Converts anchor tags with relative URLs to absolute CSC URLs
 *
 * Usage: <div dangerouslySetInnerHTML={{__html: processHtml(htmlSafeEmailBody)}}/>
 */
export function processHtml(text: string): string {
    let processedText = convertUrlToAnchorTag(text);
    processedText = retainEmailAddressEnclosedByHTMLTags(processedText);

    // Do nothing if there is no CSC URL. This should not happen while we have Papyrus running in CSC.
    if (CSCentralURL.sharedCSCentralURL.domain) {
        let linkifiedOrdersText = "";
        createOrderIdLinks(processedText).forEach(chunk => {
            if (typeof chunk === 'string') {
                linkifiedOrdersText += chunk;
            } else {
                linkifiedOrdersText += ReactDOMServer.renderToStaticMarkup(chunk);
            }
        });

        processedText = convertRelativeUrlToAbsolute(linkifiedOrdersText);
    }
    return processedText;
}

/*
Email body may contain email addresses that are enclosed by angle brackets (e.g. <test.queue@amazon.com>). When rendering on the DOM, these email addresses are lost
as they are displayed as custom HTML elements (e.g. <test.queue@amazon.com></test.queue@amazon.com>). The angle brackets must be escaped as their HTML character
code so the entire email address is properly rendered as a string on the DOM.
*/
function retainEmailAddressEnclosedByHTMLTags(emailBody: string): string {
    // if email body does not contain any email addresses that are surrounded by angle brackets, do nothing
    if (!pattern.test(emailBody)) {
        return emailBody;
    }
    
    // find every occurence of the pattern and replace its first occurence of < and > with their HTML character code
    emailBody = emailBody.replace(pattern, 
        str => encodeFirstAndLastAngleBrackets(str));

    return emailBody;
}

function encodeFirstAndLastAngleBrackets(text: string): string {
    return text.replace("<", "&lt;").replace(">", "&gt;");
}

function convertUrlToAnchorTag(text: string): string {
    // This pattern is similar to the URL regex above, but with a capture group on the domain.
    const re = /(?:http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:/~+#;-]*[\w@?^=%&/~+#;-])?/g;

    let processedText = "";
    // Our splitter regex needs to be wrapped in capturing parentheses () so that matched results are included in the array.
    const textChunks = text.split(new RegExp('(' + anchorTagPattern.source + ')'));
    for (const textChunk of textChunks) {

        if (anchorTagPattern.test(textChunk)) {
            // Modify tag to open in a new tab
            processedText += convertAnchorTabToOpenInNewTab(textChunk);
        } else {
            processedText += textChunk.replace(re, (match, domain) => {
                if (ALLOWED_DOMAINS.has(domain)) {
                    const url = AllHtmlEntities.decode(match);
                    return ReactDOMServer.renderToStaticMarkup(createAnchorTag(url, url));
                } else {
                    return match;
                }
            });
        }
    }
    return processedText;
}

function convertAnchorTabToOpenInNewTab(text: string): string {
    // Capture domain, url and text from anchor tag
    // group 1 is url
    // group 2 is domain
    // group 3 is text
    const re = /<a href="((?:http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:/~+#;-]*[\w@?^=%&/~+#;-])?)"(?:[^<]+)>([^<]+)<\/a>/g;
    const newUrl = text.replace(re, (match, url, domain, text) => {
        if (ALLOWED_DOMAINS.has(domain)) {
            return ReactDOMServer.renderToStaticMarkup(createAnchorTag(AllHtmlEntities.decode(url), text));
        } else {
            return match;
        }
    });
    return newUrl;
}

function convertRelativeUrlToAbsolute(text: string): string {
    // [^>] - matches any character that is not >
    // (?!http) - do not match absolute URLs; i.e. those that start with http or https
    // href="()" - the first capture group for getting the relative URL
    // (.*?) - the second capture group for getting the text between the opening and closing tags
    const re = /<a[^>]*href="((?!http)[^>]*?)"[^>]*>(.*?)<\/a>/g;

    return text.replace(re, (match, relativeURL, text) => {
        let url = "https://" + CSCentralURL.sharedCSCentralURL.domain;
        url = relativeURL.indexOf("/") === 0 ? url + relativeURL : url + "/" + relativeURL;
        return ReactDOMServer.renderToStaticMarkup(createAnchorTag(AllHtmlEntities.decode(url), text));
    });
}

function createOrderIdLinks(text: string): (string|JSX.Element)[] {
    const processedText: (string|JSX.Element)[] = [];
    // We only linkify order IDs that do not already appear within an anchor tag or URL.
    // To do this, we split the text using a regex with the anchor or URL pattern as the delimiter.
    // Our splitter regex needs to be wrapped in capturing parentheses () so that matched results are included in the array.
    const textChunks = text.split(anchorOrUrlPattern);
    for (const textChunk of textChunks) {
        if (anchorOrUrlPattern.test(textChunk)) {
            // Do nothing if the text chunk matches an anchor or existing URL.
            processedText.push(textChunk);
        } else {
            const subTextChunks = textChunk.split(orderIdPattern);
            for (const subTextChunk of subTextChunks) {
                if (orderIdPattern.test(subTextChunk)) {
                    const orderIdUrl = CSCentralURL.sharedCSCentralURL.getURL("gp/order/detail/", "orderID=" + subTextChunk);
                    processedText.push(createAnchorTag(orderIdUrl, subTextChunk));

                } else {
                    processedText.push(subTextChunk);
                }
            }
        }
    }
    return processedText;
}

function createAllowedLinks(text: string): (string|JSX.Element)[]{
    const processedText: (string|JSX.Element)[] = [];
    createOrderIdLinks(text).forEach(chunk => {
        if (typeof chunk === 'string') {
            if (validateDomain(chunk)){
                processedText.push(createAnchorTag(chunk, chunk));
            }
            else {
                processedText.push(chunk);
            }
        }
        else {
            processedText.push(chunk);
        }
    });
    return processedText;
}

function validateDomain(text: string): boolean {
    const re = /https:\/\/([\w_-]+(?:(?:\.[\w_-]+)+))(?:[\w.,@?^=%&:/~+#;-]*[\w@?^=%&/~+#;-])?/g;
    return ALLOWED_DOMAINS.has(text.replace(re, (match, domain)=>{return domain;}));
}

function createAnchorTag(url: string, text: string): JSX.Element {
    // Truncate text at 80 characters. https://tiny.amazon.com/1b2pf01wr/convert-text-links-t
    const anchorTagText = text.length < 80 ? text : text.slice(0, 80) + '...';
    // Using target="_blank" without rel="noopener noreferrer" is a security risk: see https://mathiasbynens.github.io/rel-noopener
    return <a href={url} target="_blank" rel="noopener noreferrer" key={Math.random().toString(36)}>{anchorTagText}</a>;
}