import React, {useEffect, useState} from "react";
import Icon, { IconProps } from "@amzn/awsui-components-react/polaris/icon";
import {ContactMediumType} from "./Contact.types";
import IntlManager from "../i18n/IntlManager";
import postalMail from "../img/postalMail.svg";

interface ContactIconProps {
    mediumType: ContactMediumType;
}

const ContactIcon: React.FC<ContactIconProps> = ({mediumType}) => {
    // These icons are from https://polaris.corp.amazon.com/components/awsui-icon/
    const [ iconName, setIconName ] = useState<IconProps.Name>("envelope");
    const [ iconLabel, setIconLabel ] = useState<string>(IntlManager.sharedManager.formatMessage("UNKNOWN_CONTACT_TYPE"));

    useEffect(() => {
        switch(mediumType) {
            case ContactMediumType.PHONE:
                setIconName("call");
                setIconLabel(IntlManager.sharedManager.formatMessage("PHONE_CONTACT"));
                break;
            case ContactMediumType.CHAT:
                setIconName("contact");
                setIconLabel(IntlManager.sharedManager.formatMessage("CHAT_CONTACT"));
                break;
            case ContactMediumType.EMAIL:
                setIconName("envelope");
                setIconLabel(IntlManager.sharedManager.formatMessage("EMAIL_CONTACT"));
                break;
            case ContactMediumType.MAIL:
                setIconLabel(IntlManager.sharedManager.formatMessage("MAIL_CONTACT"));
                break;
            default:
                setIconName("envelope");
                setIconLabel(IntlManager.sharedManager.formatMessage("UNKNOWN_CONTACT_TYPE"));
        }
    }, [mediumType]);

    return (
        <span  aria-label={iconLabel}>
            {mediumType === ContactMediumType.MAIL ?
                <Icon url={postalMail}/> :
                <Icon name={iconName} />
            }
        </span>
    );
};

export default ContactIcon;