export const es_US = {
    "contact_event_GC_added_scratchedCode": "Tarjeta de regalo #{gcId} añadida a la cuenta a través de herramienta de código de reclamo rayado.",
    "contact_event_GC_added_scratchedCode_batched": "Tarjeta de regalo añadida a la cuenta a través de herramienta de código de reclamo rayado ({count}).",
    "contact_event_PV_added": "PV #{pvId} añadido a la cuenta.",
    "contact_event_PV_locked": "PV #{pvId} bloqueado.",
    "contact_event_PV_temporary_locked": "PV #{pvId} bloqueado temporalmente.",
    "contact_event_PV_unlocked": "PV #{pvId} desbloqueado.",
    "contact_event_a_to_z_guarantee_requested_gwp": "Reclamación de la A a la Z presentada para el pedido {order_id}.",
    "contact_event_access_code_added": "Se agregó el código de acceso",
    "contact_event_account_reopened": "Cuenta reahabilitada",
    "contact_event_account_reopened_batched": "Cuentas reahabilitadas ({count})",
    "contact_event_account_temporarily_disabled": "Cuenta temporalmente deshabilitada.",
    "contact_event_account_temporarily_disabled_batched": "Cuentas temporalmente deshabilitadas ({count})",
    "contact_event_account_whitelisted": "Cuenta en la lista de inclusión.",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Suscripción a Amazon Video auto-renovada",
    "contact_event_amazon_video_subscription_cancelled": "Suscripción a Amazon Video cancelada",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Formulario presentado: formulario de Escalación DSL\nID de sesión de caso de uso: {usecaseSessionID}\nEstación de entrega: {deliveryStationField}\nTipo de solicitud: {requestTypeField}\nID de contacto: {contactIdField}\nID de pedido: {orderIdField}\nDirección de correo electrónico del cliente: {customerEmailField}\nNúmero de teléfono del cliente: {customerPhoneNumberField}\nTBA(s): {tbasField}\nCódigo de Acceso (si aplica): {accessCodeField}\nResumen del problema: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Formularios de escalación DSL presentados: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Formulario enviado: formulario de Comentarios DSP DA\nID de sesión de caso de uso: {usecaseSessionID}\nCódigo de Estación de Entrega: {deliveryStationField}\nID de contacto: {contactIdField}\nID de cuenta de cliente: {customerIdField}\nID de Transportista: {driverIdField}\nFecha del Incidente (AAAA-MM-DD): {dateOfOccurrenceField}\nHora de incidente (HH:MM): {timeofOccurrenceField}\nTBAs: {relatedTrackingIdsField}\nOrigen de los comentarios: {sourceOfFeedbackField}\nDetalle de los comentarios: {issueField}\nDetalles adicionales: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Formularios de comentarios DSP DA presentados: ({count})",
    "contact_event_audible_cash_and_coupon_refund": "{title} se reembolsó por un importe de {cashAmount}. Además, esta transacción restauró un cupón de {couponAmount} a la cuenta.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} se reembolsó por un importe de {cashAmount}. Además, esta transacción restauró un cupón de {couponAmount} a la cuenta.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} se reembolsó por un importe de {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} se reembolsó por un importe de {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} se reembolsó y un cupón de {couponAmount} se restauró a la cuenta.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} se reembolsó y un cupón de {couponAmount} se restauró a la cuenta.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} crédito(s) eliminado(s) de la cuenta.",
    "contact_event_audible_credit_refund": "{title} se reembolsó y {creditAmount} de crédito se restauró a la cuenta.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} se reembolsó y {creditAmount} de crédito se restauró a la cuenta.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} crédito(s) han sido restaurados en la cuenta.",
    "contact_event_audible_credit_restored_batched": "{amount} crédito(s) han sido restaurados en la cuenta.({count})",
    "contact_event_audible_extra_credit_purchased": "Se compraron 3 créditos extra.",
    "contact_event_audible_extra_credit_purchased_batched": "Se compraron 3 créditos extra.({count})",
    "contact_event_audible_hiatus_cancelled": "El período de interrupción terminó antes y la membresía se reanudó.",
    "contact_event_audible_hiatus_cancelled_batched": "El período de interrupción terminó antes y la membresía se reanudó.({count})",
    "contact_event_audible_hiatus_started": "La membresía se interrumpió por {duration} meses.",
    "contact_event_audible_hiatus_started_batched": "La membresía se interrumpió por {duration} meses.({count})",
    "contact_event_audible_membership_plan_refund": "Se reembolsó la tarifa de la membresía por un importe de {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Se reembolsó la tarifa de la membresía por un importe de {cashAmount}.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "Inscrito en el {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Inscrito en el {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "",
    "contact_event_audible_wizard_invoked_batched": "",
    "contact_event_audiblecontact_event_cancel_audible_membership": "El plan {membership} se canceló correctamente.",
    "contact_event_cancel_audible_membership": "El plan {membership} se canceló correctamente.",
    "contact_event_cancel_audible_membership_batched": "El plan {membership} se canceló correctamente.({count})",
    "contact_event_cancel_audible_romance_package_batched": "El plan {membership} se canceló correctamente.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "La fecha de cancelación futura configurada previamente se canceló.",
    "contact_event_channels_auto_renew_off": "CS desactivó la renovación automática para la suscripción de Canales PV {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS desactivado la renovación automática vía Astro.",
    "contact_event_channels_full_refund": "CS canceló suscripción a Canales PV {subscriptionid} con reembolso completo.",
    "contact_event_channels_full_refund_static": "CS canceló suscripción con reembolso completo vía Astro.",
    "contact_event_channels_no_refund": "CS canceló suscripción a Canales PV {subscriptionid} sin reembolso.",
    "contact_event_channels_no_refund_static": "CS canceló suscripción sin reembolso vía Astro.",
    "contact_event_channels_workflow_launch": "CS inició el flujo de trabajo de cancelación de Astro para suscripción a Canales PV {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS lanzó el flujo de trabajo de cancelación de Astro.",
    "contact_event_csc_sds_override_geo_fence_submit": "Anular la solicitud de geovalla enviada para el Número de seguimiento {trackingId} Motivo: {overrideReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Anular la solicitud de geovalla enviada para el Número de seguimiento {trackingId} Motivo: {overrideReason} Página: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Geocodificación actualizada. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Geocodificación actualizada. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_delete_alexa_voice_profile": "Perfil de Voz de Alexa eliminado para altavozID : {speakerId} ,  clienteID : {customerID}  y estado : {status}",
    "contact_event_delete_data_alexa_comms": "Perfil de Comms de Alexa eliminado para commsID : {commsId} ,  clienteID : {customerID}  y estado : {status}",
    "contact_event_deprovision_alexa_comms": "Desaprovisionó el perfil Comms de Alexa para commsID : {commsId},  Id. de cliente : {customerID}  y estado : {status}",
    "contact_event_future_cancel_audible_membership": "El plan {membership} se configuró para cancelarlo en el futuro el {date}.",
    "contact_event_future_cancel_audible_membership_batched": "El plan {membership} se configuró para cancelarlo en el futuro el {date}.({count})",
    "contact_event_idle_chat_disconnect": "Chat inactivo desconectado",
    "contact_event_idle_chat_disconnect_batched": "Chat inactivo desconectado ({count})",
    "contact_event_music_auto_renew_off": "CS desactivó la renovación automática para la suscripción a Música {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "CS desactivó la renovación automática para la suscripción a Música vía Astro.",
    "contact_event_music_full_refund": "CS canceló la suscripción a Música {subscriptionid} con reembolso completo",
    "contact_event_music_full_refund_static": "CS canceló la suscripción a Música con reembolso completo vía Astro.",
    "contact_event_music_no_refund": "CS canceló la suscripción a Música {subscriptionid} sin reembolso.",
    "contact_event_music_no_refund_static": "CS canceló la suscripción a Música sin reembolso vía Astro.",
    "contact_event_music_unlimited_auto_renew_off": "CS desactivó la renovación automática para la suscripción a Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "CS canceló la suscripción a Amazon Music Unlimited {subscriptionid} con reembolso completo.",
    "contact_event_music_unlimited_no_refund": "CS canceló la suscripción a Amazon Music Unlimited {subscriptionid} sin reembolso.",
    "contact_event_music_unlimited_partial_refund": "CS canceló la suscripción a Amazon Music Unlimited {subscriptionid} con reembolso parcial.",
    "contact_event_music_unlimited_workflow_launch": "CS lanzó el flujo de trabajo de cancelación Astro para la suscripción de Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "CS inició el flujo de trabajo de cancelación de Astro para la suscripción a Música {subscriptionid}",
    "contact_event_music_workflow_launch_static": "CS inició el flujo de trabajo de cancelación de Astro para la suscripción a Música ilimitada.",
    "contact_event_pennywise_promo_applied": "Promoción Pennywise aplicada para DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promoción Pennywise aplicada para DSNs: ({count})",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nombre de DP: {driverName}, \nNombre de la estación: {stationName}, \nNúmero de seguimiento: {trackingId} y \nNúmero de pedido: {orderId}\nNúmero de sesión de caso: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Mapa de widget abierto para el ID de rastreo {trackingId}",
    "contact_event_sds_package_repick": "El asociado inició la recolección para la ID de rastreo {trackingId}",
    "contact_event_sim_ticket_created": "SIM-Ticket creado",
    "contact_event_subscription_astro_workflow_launch": "Servicio al cliente inició el flujo de trabajo de cancelación de Astro para la suscripción  {subscriptionType}  {subscriptionId}.",
    "contact_event_subscription_auto_renew_off": "Servicio al cliente DESACTIVÓ la renovación automática de la suscripción {subscriptionType}  {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "Servicio al cliente canceló la suscripción  {subscriptionType} {subscriptionId} con reembolso completo vía Astro.",
    "contact_event_subscription_cancel_no_refund": "Servicio al cliente canceló la suscripción {subscriptionType}  {subscriptionId} sin reembolso.",
    "contact_event_subscription_cancel_partial_refund": "Servicio al cliente canceló la suscripción {subscriptionType}  {subscriptionId} con reembolso parcial.",
    "contact_event_weekend_delivery_preference_changed": "Se actualizaron las preferencia de entrega en fines de semana"
}