/**
 * Just a simple map for now.
 * This speeds up work when jumping between calls for the same Customer.
 */
export class CSALTAudioCache {
    downloaded: Map<string, Buffer[]> = new Map();

    public getBuffers(id: string): Buffer[] | undefined {
        return this.downloaded[id];
    }

    public storeBuffers(id: string, buffers: Buffer[]): void {
        this.downloaded[id] = buffers;
    }
}
