import React from "react";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Phoenix from "../api/Phoenix";
import Translation from "../i18n/Translate";
import {Match} from "@amzn/csphoenix-react-client";
import {AuthorizerJwtToken} from "../authorizer/AuthorizerJwtToken";

/**
 * Enum to represent the state for adding text
 * `SUCCESS` - 200 response code
 * `ERROR` - Restricted data was detected
 * `FAIL` - Any other response code
 * `NONE` - No request made
 */
export enum AddTextStatus {
    SUCCESS,
    ERROR,
    FAIL,
    NONE
}

interface AddTextProps {
    newText: string;
    setNewText: (newText: string) => void;
    disabled?: boolean;
    textAriaLabel: string;
    textPlaceholder?: string;
    addTextStatus: AddTextStatus;
    setAddTextStatus: (status: AddTextStatus) => void;
    restrictedDataMatchList: Match[];
    rows?: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function addTextAndSubmit(input: string,
                                 contactId: string,
                                 authorizerJwtTokens: AuthorizerJwtToken[],
                                 onRestrictedDataMatch: (matches: Match[]) => void,
                                 onSuccess: (...args: any[]) => any,
                                 onFailure: (...args: any[]) => any): void {
    if (input) {
        const filteredToken = authorizerJwtTokens.find(token => token.getAuth().find(item => (item.e === 'CREATE_ANNOTATION_WITHOUT_FILTER') ||
                                                        (item.e === 'CREATE_WORK_REQUEST_WITHOUT_FILTER')));

        const skipCheck: boolean = filteredToken !== undefined;

        if (skipCheck) {
            onSuccess();
        } else {
            Phoenix.getInstance().matchRestrictedData([input])
                .then(response => {
                    if (response.matches[0].length > 0) {
                        onRestrictedDataMatch(response.matches[0]);
                    } else {
                        onSuccess();
                    }
                })
                .catch(() => onFailure());
        }
    }
}

/**
 * React component that accepts user input.
 *
 * When submitting the input (with a click of a button for example), the function `addTextAndSubmit` should be used as
 * it checks for the presence of restricted data.
 */
export const AddText: React.FC<AddTextProps> = ({newText,
                                             setNewText,
                                             disabled,
                                             textAriaLabel,
                                             textPlaceholder,
                                             addTextStatus,
                                             setAddTextStatus,
                                             restrictedDataMatchList,
                                             rows}) => {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {(rows && rows === 1)
                ? <Input
                    ariaLabel={textAriaLabel}
                    disabled={disabled}
                    invalid={addTextStatus !== AddTextStatus.SUCCESS && addTextStatus !== AddTextStatus.NONE}
                    placeholder={textPlaceholder}
                    value={newText}
                    onChange={({ detail }): void => {
                        if (addTextStatus !== AddTextStatus.NONE) {
                            setAddTextStatus(AddTextStatus.NONE);
                        }
                        setNewText(detail.value);
                    }}
                />
                : <Textarea
                    ariaLabel={textAriaLabel}
                    disabled={disabled}
                    invalid={addTextStatus !== AddTextStatus.SUCCESS && addTextStatus !== AddTextStatus.NONE}
                    placeholder={textPlaceholder}
                    rows={rows}
                    value={newText}
                    onChange={({ detail }): void => {
                        if (addTextStatus !== AddTextStatus.NONE) {
                            setAddTextStatus(AddTextStatus.NONE);
                        }
                        setNewText(detail.value);
                    }}
                />
            }

            {restrictedDataMatchList?.length ?
                <Box
                    fontSize="body-s"
                    fontWeight="bold"
                    color={"text-status-error"}
                >
                    {restrictedDataMatchList.map(m => {
                        return <Translation key={m.start} component={"div"}
                                            stringId={"RESTRICTED_DATA_MATCH"}
                                            params={{match: m.input.substring(m.start, m.end), type: m.type}} />;
                    })}
                </Box>: null
            }
        </SpaceBetween>
    );
};

