/* eslint-disable jsx-a11y/media-has-caption */
import React, {useContext, useState} from "react";
import {Contact, GACDPhoneRecord} from "./Contact.types";
import {i18n} from "../i18n/IntlManager";
import {IssueManagerContext} from "../issueManager/IssueManager";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Phoenix from "../api/Phoenix";
import {CallResponse, JwtToken, LogLevel, PhoenixRequest} from "@amzn/csphoenix-react-client";
import {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {Copyable} from "../util/Copyable";
import {Table} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Translation from "../i18n/Translate";
import {CSALTCallLoader} from "../audioplayer/CSALTCallLoader";
import {CSALTAudioPlayer, PhoneMediumType} from "../audioplayer/CSALTAudioPlayer";

interface ContactPhoneMediumProps {
    contactId: string;
    marketplaceId?: string;
}

interface MediaLegTableProps {
    contact?: Contact;
    playRecord: (GACDPhoneRecord) => void;
}

function formatAsDuration(seconds: number): string {
    const minute = Math.floor(seconds / 60);
    const remainder = Math.floor(seconds) % 60;
    return (minute < 10 ? " " : "") + minute + ":" + (remainder < 10 ? "0" : "") + remainder;
}

async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function tryToLoad(contactId: string, record: GACDPhoneRecord, tries: number): Promise<Blob> {
    const callLoader = new CSALTCallLoader(contactId, PhoneMediumType.GACD, record.agentMediaLegId);
    const blob = await callLoader.loadRecording();
    if (blob) {
        return blob;
    }
    // we did not get anything probably still pending...
    if (tries > 0) {
        await sleep(1000);
        return await tryToLoad(contactId, record, tries - 1);
    }
    Phoenix.getInstance()
        .log(LogLevel.ERROR, "Failed to load " + record.agentMediaLegId + " for Contact " + contactId);
    throw new Error('Failed to load ' + record.agentMediaLegId);
}

function getQuery(contactId: string, withUrl: boolean): string {
    const presignedUrlField = withUrl ? ' presignedUrl ' : '';
    return `query { ` +
        `   contact ( contactId: "${contactId}") { ` +
        `       contact { ` +
        `         obfuscatedCustomerIdList ` +
        `         contactId ` +
        `           medium { ` +
        `             phoneRecordList {` +
        `               cscRecordingAccessStatus` +
        `               agentMediaLegId` +
        `               agent {` +
        `                 agentId` +
        `                 agentLogin` +
        `               }` +
        `               gacdSiteId` +
        `               gacdAgentOwner` +
        `               ${presignedUrlField}` +
        `               skillName ` +
        `               callDurationSeconds ` +
        `               mediaStatus ` +
        `             }` +
        `           }` +
        `         }` +
        `       }` +
        `   }`;
}

function buildRequest(query: string, jwtState: JwtToken | null): PhoenixRequest {
    return {
        query: query,
        contractId: "CSALTGraphQL",
        projectName: "CSALT",
        jwtState: jwtState ? jwtState.jwtString : undefined
    };
}

async function getGraphQLPhoneContact(contactId: string, withUrl: boolean): Promise<Contact|null> {
    const query = getQuery(contactId, withUrl);
    const request = buildRequest(query, null);
    const callResponse: CallResponse = await Phoenix.getInstance()
        .csalt(request);
    let contact: Contact | null = null;
    const callResult = callResponse.callResult;
    if (callResult) {
        const resultJson = callResult.resultJson;
        if (resultJson) {
            const parsed = JSON.parse(resultJson);
            const hasErrors: boolean = parsed.errors && parsed.errors.length > 0;
            if (!hasErrors && parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                contact = parsed.data.contact.contact as Contact;
            }
        }
    }
    return contact;
}

const MediaLegTable: React.FC<MediaLegTableProps> = ({contact,
                                                         playRecord
                                                     }) => {

    const {clientStateToken} = useContext(IssueManagerContext);
    const [downloading, setDownloading] = useState<string>();

    const allowDownloadRecording = clientStateToken?.getContent()["dlrec"];

    function displayShortCallLegID(callLegID: string): string {
        return (callLegID.substring(0,9) + "..." + callLegID.substring(callLegID.length-4, callLegID.length));
    }

    async function loadToBlob(r: GACDPhoneRecord): Promise<Blob|null> {
        if (contact) {
            const data = await tryToLoad(contact.contactId, r, 15);
            return data;
        }
        return null;
    }

    async function downloadRecording(r: GACDPhoneRecord): Promise<void> {
        try {
            setDownloading(r.agentMediaLegId);
            const data: Blob | null = await loadToBlob(r);
            if (data) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(data);
                // Filename to safe it as
                link.download = `${r.agentMediaLegId}.mp3`;
                // Similar silly stuff because Chrome tries to only execute href actions
                // on click events ....
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        finally {
            setDownloading(undefined);
        }
    }

    function i18nStatus(r: GACDPhoneRecord): React.ReactNode {
        if (r.mediaStatus === "DELETED") {
            return i18n('CALL_RETENTION_DELETED');
        }
        if (r.mediaStatus === "COMPLETE") {
            return i18n('COMPLETE');
        }
        if (r.mediaStatus === "CALL_RESTRICTED") {
            return i18n('CALL_RESTRICTED');
        }
        if (r.mediaStatus === "PENDING") {
            return i18n('PENDING');
        }
        return i18n('ERROR');
    }

    const baseColumns: ReadonlyArray<TableProps.ColumnDefinition<GACDPhoneRecord>> = [
        {id: "duration", header: i18n("DURATION"), cell: (r: GACDPhoneRecord): React.ReactNode => formatAsDuration(+(r.callDurationSeconds || "0")) },
        {id: "agentLogin", header: i18n("AGENT"), cell: (r: GACDPhoneRecord): React.ReactNode => r.agent.agentLogin + '@' + r.gacdAgentOwner},
        {id: "agentCallLegId", header: i18n("CALL_LEG"), cell: (r: GACDPhoneRecord): React.ReactNode =>
                <Copyable
                    actionTranslationId="COPY_CALL_LEG_ID"
                    successTranslationId="CALL_LEG_ID_COPIED"
                    content={displayShortCallLegID(r.agentMediaLegId)}
                    valueToCopy={r.agentMediaLegId}/>
        },
        {id: "skillName", header: i18n("SKILL"), cell: (r: GACDPhoneRecord): React.ReactNode => r.skillName},
        {id: "status", header: i18n("STATUS"), cell: (r: GACDPhoneRecord): React.ReactNode => i18nStatus(r)},
    ];

    const columns: ReadonlyArray<TableProps.ColumnDefinition<GACDPhoneRecord>> = allowDownloadRecording ? [
        ...baseColumns,
        {id: "download", header: i18n("DOWNLOAD"), cell: (r: GACDPhoneRecord): React.ReactNode =>
            {return !(r.mediaStatus === 'COMPLETE') ? null :
                <Button
                    loading={downloading === r.agentMediaLegId}
                    disabled={downloading !== undefined}
                    onClick={(): Promise<void> => downloadRecording(r)}
                    wrapText={false}>
                    <Translation stringId={"DOWNLOAD"}/>
                </Button>;}
        }
    ] : baseColumns;


    const phoneRecordList: GACDPhoneRecord[] = contact?.medium.phoneRecordList || [];
    const items = phoneRecordList;

    for (let i = 0; i < phoneRecordList.length; i++) {
        const agentLog = phoneRecordList.at(i)?.agent?.agentLogin;
        const accessLog = phoneRecordList.at(i)?.cscRecordingAccessStatus;
        const contactIdLog = contact?.contactId;

        Phoenix.getInstance()
        .log(LogLevel.INFO, "Info log for call recording for contactId  " + contactIdLog + " for user " + agentLog + " with cscRecordingAccessStatus " + accessLog);
    }

    const [selectedItems, setSelectedItems] = useState<GACDPhoneRecord[]>([]);

    return (<Table
        columnDefinitions={columns}
        items={items}
        selectionType={"single"}
        selectedItems={selectedItems}
        isItemDisabled={(r): boolean => !(r.mediaStatus === 'COMPLETE')
            || (r.cscRecordingAccessStatus === 'DENIED')}
        onSelectionChange={(event): void => {
            playRecord(event.detail.selectedItems[0]);
            setSelectedItems(event.detail.selectedItems);
        }}
    />);
};

const ContactPhoneMedium: React.FC<ContactPhoneMediumProps> = (
    {
        contactId,
    }) => {
    const [contactWithMediaLegs, setContactWithMediaLegs] = useState<Contact>();

    async function getGraphQLPhoneContacts(): Promise<void> {
        if (!contactWithMediaLegs) {
            const contact = await getGraphQLPhoneContact(contactId, false);
            if (contact) {
                setContactWithMediaLegs(contact);
            }
        }
    }

    const [mediaLegId, setMediaLegId] = useState<string>();

    async function onPlayRecord(record: GACDPhoneRecord): Promise<void> {
        setMediaLegId(record.agentMediaLegId);
    }

    function player(): JSX.Element|null {
        return <CSALTAudioPlayer playLabel={i18n('PLAY')}
                                 pauseLabel={i18n('PAUSE')}
                                 preparingRecordingLabel={i18n('PREPARING_RECORDING')}
                                 decodingAudioLabel={i18n('DECODING_AUDIO')}
                                 timeoutLabel={i18n('TIMEOUT_FAILED_TO_LOAD_RECORDING')}
                                 skipSilencesLabel={i18n('SKIP_SILENCES')}
                                 speedLabel={i18n('PLAYBACK_SPEED')}
                                 contactId={contactId}
                                 phoneMediumType={PhoneMediumType.GACD}
                                 id={mediaLegId}/>;
    }

    function csaltPlayer(): JSX.Element {
        return (
            <ExpandableSection
                header = {<div className="contact-details-header">{i18n("CALL_TITLE")}</div>}
                onChange={({ detail }): void => {
                    if(detail.expanded){
                        getGraphQLPhoneContacts();
                    }
                }}>
                {player()}
                <MediaLegTable
                    contact={contactWithMediaLegs}
                    playRecord={async (record): Promise<void> => onPlayRecord(record)}
                />
            </ExpandableSection>
        );
    }

    return csaltPlayer();
};

export default ContactPhoneMedium;
