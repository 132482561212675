import Translation from "../i18n/Translate";
import React from "react";

export const TimeZoneInfo: React.FC<{ isCustomerTimezone: boolean }> = ({isCustomerTimezone}) => {
    return (<span className="timezone-info">
                                    <em>
                                        <Translation
                                            stringId={isCustomerTimezone ? "CUSTOMER_TIMEZONE" : "USER_TIMEZONE"}/>
                                    </em>
                                </span>);
};