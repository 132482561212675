import React, {useContext, useState} from 'react';
import {i18n} from "../i18n/IntlManager";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {IssueManagerContext} from "../issueManager/IssueManager";
import Phoenix from "../api/Phoenix";
import {AuthorizerJwtToken} from "./AuthorizerJwtToken";
import {JwtToken} from "@amzn/csphoenix-react-client";
import {Form, FormField, RadioGroup, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import Container from "@amzn/awsui-components-react/polaris/container";
import {RadioGroupProps} from "@amzn/awsui-components-react/polaris/radio-group";

export interface ViewRestrictedDataFormProps {
    contactId: string;
    closeModal: () => void;
}

export const AccessRestrictedContentRequestForm: React.FC<ViewRestrictedDataFormProps> = ({contactId, closeModal}) => {
    const radioItems: RadioGroupProps.RadioButtonDefinition[] = [
        {value: 'INVALID_MATCH_PII', label: i18n('OVERRIDE_REASON_INVALID_MATCH_PII')},
        {value: 'INVALID_MATCH_RESTRICTED', label: i18n('OVERRIDE_REASON_INVALID_MATCH_RESTRICTED')},
        {value: 'CURRENT_PROCESS_REQUIRES_PII', label: i18n('OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII')},
        {value: 'OTHER', label: i18n('OTHER')}
    ];

    const [flashBarItems, setFlashBarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [reason, setReason] = useState<string>(radioItems[0].value);
    const [reasonText, setReasonText] = useState<string>("");

    const {clientStateToken, setClientStateToken, addAuthorizerJwtToken, purpose, reloadSingleContact} = useContext(IssueManagerContext);

    function setFlashBar(type: "success" | "warning" | "info" | "error", message): void {
        const newItem: FlashbarProps.MessageDefinition = {
            content: message,
            dismissible: true,
            type: type,
        };
        newItem.onDismiss = (): void => {setFlashBarItems(flashBarItems.filter(obj => obj !== newItem));};
        setFlashBarItems([...flashBarItems, newItem]);
    }
    
    async function getAuthToken(): Promise<void> {

        const payload = {
            entityId: {id: contactId, type: 'CONTACT'},
            purpose: purpose,
            authorization: (reason === 'INVALID_MATCH_RESTRICTED') ? 'DISABLE_CRITICAL_FILTER' : 'DISABLE_PII_FILTER',
            reason: reason === 'OTHER' ? reasonText : reason,
        };
        if (clientStateToken) {
            payload['jwtState'] = clientStateToken.jwtString;
        }
        const response = await Phoenix.getInstance().postCall('authorize', payload);

        if (response.status === 200) {
            const jsonResult = await response.json();
            const token = new AuthorizerJwtToken(jsonResult.jwtAuthToken);
            const clientJwt = new JwtToken(jsonResult.jwtState);
            setClientStateToken(clientJwt);
            addAuthorizerJwtToken(token);

            if (token.getAuth().length === 0) {
                setFlashBar("error", i18n('REQUEST_NOT_GRANTED'));
            }
            else {
                setFlashBar("success", i18n('RELOADING_CONTENT'));
                await reloadSingleContact(contactId, token);
                closeModal();
            }
        } else {
            setFlashBar("error", i18n('REQUEST_FAILED'));
        }
    }

    const theForm = (<Container>
        <Flashbar items={flashBarItems}/>
        <Form>
            <FormField label={i18n("REQUEST_ACCESS_INSTRUCTIONS")}>
                <RadioGroup value={reason} onChange={({detail}): void => setReason(detail.value)} items={radioItems}/>
            </FormField>
            <FormField><Input disabled={reason !== 'OTHER'} value={reasonText} onChange={({detail}): void => setReasonText(detail.value)}/>
            </FormField>
        </Form></Container>);

    return (<Modal visible={true} header={i18n('REQUEST_ACCESS_TO_FILTERED_CONTENT')} onDismiss={(): void => closeModal()} footer={
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(): void => closeModal()}>{i18n('CANCEL')}</Button>
                <Button variant="primary" onClick={(): Promise<void> => getAuthToken()}>{i18n('REQUEST')}</Button>
            </SpaceBetween>
        </Box>
    }>{theForm}</Modal>);
};