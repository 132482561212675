export const tr_TR = {
    "contact_event_GC_added": "GC #{gcId} hesaba eklendi.",
    "contact_event_GC_added_batched": "GC hesaba eklendi ({count}).",
    "contact_event_GC_added_purchaser": "GC #{gcId} satın alan hesabına eklendi.",
    "contact_event_GC_added_purchaser_batched": "GC satıcı hesabına eklendi ({count}).",
    "contact_event_GC_added_recipient": "GC #{gcId} alıcının hesabına eklendi.",
    "contact_event_GC_added_recipient_batched": "GC alıcı hesabına eklendi ({count}).",
    "contact_event_GC_added_scratchedCode": "GC #{gcId} hesaba kazınmış istek kodu aracı vasıtasıyla hesaba eklendi.",
    "contact_event_GC_added_scratchedCode_batched": "GC kazınmış istek kodu aracı vasıtasıyla hesaba eklendi ({count}).",
    "contact_event_GC_cancelled": "GC #{gcId} iptal edildi.",
    "contact_event_GC_cancelled_batched": "GC iptal edildi ({count}).",
    "contact_event_GC_changed_expiry": "GC #{gcId} son kullanma tarihi değiştirildi.",
    "contact_event_GC_changed_expiry_batched": "GC ({count}) son kullanma tarihi değiştirildi.",
    "contact_event_GC_locked": "GC #{gcId} kilitlendi.",
    "contact_event_GC_locked_batched": "GC kilitli ({count}).",
    "contact_event_GC_removed": "GC #{gcId} hesaptan kaldırıldı.",
    "contact_event_GC_removed_batched": "GC hesaptan kaldırıldı ({count}).",
    "contact_event_GC_resent_email": "GC #{gcId}, {deliveryEmail} e-posta adresine yeniden gönderildi.",
    "contact_event_GC_resent_email_batched": "GC e-posta adresine yeniden gönderildi ({count}).",
    "contact_event_GC_resent_post": "GC #{gcId} posta yolu ile yeniden gönderildi.",
    "contact_event_GC_resent_post_batched": "GC posta ile yeniden gönderildi ({count}).",
    "contact_event_GC_temporary_locked": "GC #{gcId} geçici olarak kilitlendi.",
    "contact_event_GC_temporary_locked_batched": "GC geçici kilitlendi ({count}).",
    "contact_event_GC_transferred": "GC {gcId}, {customerID} müşterisinden {toAccountCustomerID} müşterisine aktarıldı.",
    "contact_event_GC_transferred_batched": "GC aktarıldı ({count}).",
    "contact_event_GC_unlocked": "GC #{gcId} kilidi açıldı.",
    "contact_event_GC_unlocked_batched": "GC kilidi açık ({count}).",
    "contact_event_PV_added": "PV #{pvId} hesaba eklendi.",
    "contact_event_PV_locked": "PV #{pvId} kilitlendi.",
    "contact_event_PV_temporary_locked": "PV #{pvId} geçici olarak kilitlendi.",
    "contact_event_PV_unlocked": "PV #{pvId} kilidi açıldı.",
    "contact_event_a_to_z_guarantee_requested_gwp": "Sipariş {order_id} için A'dan Z'ye garanti talebi gönderildi.",
    "contact_event_access_code_added": "Erişim Kodu Eklendi",
    "contact_event_account_closed": "Hesap Kapatıldı.",
    "contact_event_account_closed_batched": "Hesaplar Kapatıldı ({count}).",
    "contact_event_account_reopened": "Hesap yeniden açıldı",
    "contact_event_account_reopened_batched": "Hesaplar yeniden açıldı ({count})",
    "contact_event_account_temporarily_disabled": "Hesap geçici olarak etkisiz hale getirilmiştir.",
    "contact_event_account_temporarily_disabled_batched": "Hesaplar geçici olarak etkisiz hale getirilmiştir ({count})",
    "contact_event_account_whitelisted": "Hesap İzin Verilenler Listesine Alındı.",
    "contact_event_address_deactivated": "Adres devre dışı bırakıldı.",
    "contact_event_address_deactivated_batched": "Adres devre dışı bırakıldı ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video aboneliği otomatik olarak yenilendi",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Gönderilen Form: DSL Lead Ekibine Aktarma formu\nKullanım durumu oturum kimliği: {usecaseSessionID}\nTeslimat İstasyonu: {deliveryStationField}\nTalep Türü: {requestTypeField}\nKontak numarası: {contactIdField}\nSipariş Numarası: {orderIdField}\nMüşterinin e-posta adresi: {customerEmailField}\nMüşterinin Telefon Numarası: {customerPhoneNumberField}\nTBA(lar): {tbasField}\nErişim Kodu (varsa): {accessCodeField}\nSorun Özeti: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Gönderilen DSL lead ekibine aktarma formları: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Gönderilen Form: DSP DA Geri Bildirim formu\nKullanım durumu oturum kimliği: {usecaseSessionID}\nTeslimat İstasyonu Kodu: {deliveryStationField}\nKontak numarası: {contactIdField}\nMüşteri Hesap Kimliği: {customerIdField}\nDA Taşıyıcı Kimlik Numarası: {driverIdField}\nOlay Tarihi (YYYY-AA-GG): {dateOfOccurrenceField}\nOlay Zamanı (SS:DD): {timeofOccurrenceField}\nTBA'lar: {relatedTrackingIdsField}\nGeri Bildirim Kaynağı: {sourceOfFeedbackField}\nGeri Bildirim Bilgileri: {issueField}\nEk Ayrıntılar: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Gönderilen DSP DA Geri Bildirim formları: ({count})",
    "contact_event_apply_adrive_music_trial": "{customer_id} müşterisi için uygulanan Cloud Player Premium. {number_of_intervals} {time_interval}",
    "contact_event_audible_cash_and_coupon_refund": "{title} için {cashAmount} tutarında para iadesi yapıldı.. Ek olarak, bu işlem yeniden hesabınızda {couponAmount} kuponuna yüklendi.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "{title} için {cashAmount} tutarında para iadesi yapıldı.. Ek olarak, bu işlem yeniden hesabınızda {couponAmount} kuponuna yüklendi.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "{title} için {cashAmount} tutarında para iadesi yapıldı..{counter}",
    "contact_event_audible_cash_only_refund_batched": "{title} için {cashAmount} tutarında para iadesi yapıldı.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "{title} için para iadesi yapıldı ve bu işlem yeniden hesabınızda {couponAmount} kuponuna yüklendi.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "{title} için para iadesi yapıldı ve bu işlem yeniden hesabınızda {couponAmount} kuponuna yüklendi.{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount} kredileri, hesaptan çıkartıldı.",
    "contact_event_audible_credit_refund": "{title} için para iadesi yapıldı ve {creditAmount} tutarında kredi hesabınıza geri yüklendi.{counter}",
    "contact_event_audible_credit_refund_batched": "{title} için para iadesi yapıldı ve {creditAmount} tutarında kredi hesabınıza geri yüklendi.{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount} tutarında kredi hesaba geri yüklendi.",
    "contact_event_audible_credit_restored_batched": "{amount} tutarında kredi, ({count}) hesaba geri yüklendi.",
    "contact_event_audible_extra_credit_purchased": "3 ekstra kredi satın alındı.",
    "contact_event_audible_extra_credit_purchased_batched": "3 ekstra kredi satın alındı. ({count})",
    "contact_event_audible_hiatus_cancelled": "Kesinti süresi erken sonlandırıldı ve üyelik devam ettirildi.",
    "contact_event_audible_hiatus_cancelled_batched": "Kesinti süresi erken sonlandırıldı ve üyelik devam ettirildi.({count})",
    "contact_event_audible_hiatus_started": "Üyelik, {duration}-ay Kesinti ile oluşturuldu.",
    "contact_event_audible_hiatus_started_batched": "Üyelik, {duration}-month Kesintisi ile oluşturuldu.({count})",
    "contact_event_audible_membership_plan_refund": "{cashAmount} tutarında üyelik ücreti para iadesi yapıldı.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "{cashAmount} tutarında üyelik ücreti para iadesi yapıldı.{counter} ({count})",
    "contact_event_audible_romance_package_enrollment": "{packageName} için kayıt yapıldı.",
    "contact_event_audible_romance_package_enrollment_batched": "{packageName} için kayıt yapıldı.({count})",
    "contact_event_audible_wizard_invoked": "{wizardName}’e erişildi.",
    "contact_event_audible_wizard_invoked_batched": "{wizardName}’e erişildi.({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "{membership} tarifesi başarıyla silindi.",
    "contact_event_blacklist_kindle": "Kara listedeki cihaz {serial_number}.",
    "contact_event_call_was_flagged": "Bu kişi {callFlagSubject}: {callFlagDescription} olarak bayraklandı",
    "contact_event_call_was_flagged_batch": "Bu kişi {callFlagSubject}: {callFlagDescription} ({count}) olarak bayraklandı",
    "contact_event_cancel_audible_membership": "{membership} tarifesi başarıyla silindi.",
    "contact_event_cancel_audible_membership_batched": "{membership} tarifesi başarıyla silindi.({count})",
    "contact_event_cancel_audible_romance_package_batched": "{membership} tarifesi başarıyla silindi.({count})",
    "contact_event_cancel_future_cancellation_audible_membership": "Daha önce ayarlanan Gelecekte İptal Tarihi iptal edildi.",
    "contact_event_captcha_bypass_key_generated": "Güvenlik Kodu Geçiş Anahtarı oluşturuldu.",
    "contact_event_captcha_bypass_key_generated_batched": "Güvenlik Kodu Geçiş Anahtarı oluşturuldu ({count}).",
    "contact_event_channels_auto_renew_off": "CS, {subscriptionid} PV Channels aboneliği için otomatik yenilemeyi KAPATTI.",
    "contact_event_channels_auto_renew_off_static": "CS, Astro üzerinden otomatik yenilemeyi KAPATTI.",
    "contact_event_channels_full_refund": "CS, {subscriptionid} PV Channels aboneliğini tam para iadesi ile iptal etti.",
    "contact_event_channels_full_refund_static": "CS, aboneliği Astro üzerinden tam para iadesiyle iptal etti.",
    "contact_event_channels_no_refund": "CS, {subscriptionid} PV Channels aboneliğini para iadesiz olarak iptal etti.",
    "contact_event_channels_no_refund_static": "CS, aboneliği Astro üzerinden para iadesiz olarak iptal etti.",
    "contact_event_channels_workflow_launch": "CS, {subscriptionid} PV Channels aboneliği için Astro üzerinden iptal iş akışını başlattı",
    "contact_event_channels_workflow_launch_static": "CS, Astro üzerinden iptal iş akışını başlattı.",
    "contact_event_coins_adjust": "Ayarlanmış Tip: {type} <br />\nNeden: {reason} <br />\nTutar: {amount} <br />\nYorumlar: {comment} <br />",
    "contact_event_coins_adjust_batched": "Ayarlanmış Madeni Para Terazisi ({count}).",
    "contact_event_concessions_abuse": "Müşteri {risk_status} kullanıcısı olduğu için HMD gönderilmedi.",
    "contact_event_contact_seller": "{order_id} siparişi için satıcı ile temasa geçildi.",
    "contact_event_contact_seller_batched": "({count}) siparişleri için satıcı ile temasa geçildi.",
    "contact_event_contact_was_initiated_from": "İrtibat ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}'den başlamıştır.",
    "contact_event_csc_sds_override_geo_fence_submit": "{trackingId} Takip No için gönderilen coğrafi konum koruma duvarı talebini geçersiz kıl Neden: {overrideReason} Sayfa: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "{trackingId} Takip Numarası için gönderilen coğrafi çit talebini geçersiz kıl Neden: {overrideReason} Sayfa: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Coğrafi kod güncellendi. GeocodeType : {geocodeType}, trackingId: {trackingId}, addressId: {addressId}, overrideReason: {overrideReason}, pageIdentifier: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Coğrafi kod güncellendi. GeocodeType : {geocodeType}, overrideReason: {overrideReason}, trackingId: {trackingId}, addressId: {addressId} and pageIdentifier: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "müşteri kimliğı doğrulanmıştır",
    "contact_event_customer_authentication_passed_batched": "müşteri kimliği doğrulanmıştır({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_delete_alexa_voice_profile": "Silinen Alexa Ses Profili, speakerId: {speakerId}, customerID : {customerID} ve durum: {status}",
    "contact_event_delete_data_alexa_comms": "Silinen Alexa Comms Profili, commsID: {commsId}, customerID: {customerID} ve durum: {status}",
    "contact_event_deprovision_alexa_comms": "Provizyonu geri alınan Alexa Comms Profili, commsID için: {commsId} ,  customerID : {customerID}  ve durum: {status}",
    "contact_event_disable_mayday_failed": "DSN {dsn} cihazının Yardım işlevi devre dışı bırakılamadı.",
    "contact_event_disable_mayday_success": "DSN {dsn} cihazının Yardım işlevi başarıyla devre dışı bırakıldı.",
    "contact_event_dunning_halt": "Hatırlatma Prosesi Durduruldu - Daha fazla detay için Ödemeler ve İşlemler Geçmişine bakın",
    "contact_event_dunning_pause": "Hatırlatma Prosesi Duraklatıldı - Daha fazla detay için Ödemeler ve İşlemler Geçmişine bakın",
    "contact_event_dvbypost_extra_dispatch": "Bu müşteri için istenen {amount} ekstra gönderi(ler).",
    "contact_event_dvbypost_extra_envelope": "Bu müşteri için ekstra bir zarf başlatıldı.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 form sipari?i",
    "contact_event_dvbypost_problem_code_change": "{disc_id} diskini {problem_code} olarak ayarla",
    "contact_event_enable_mayday_failed": "DSN {dsn} cihazının Yardım işlevi yeniden etkinleştirilemedi.",
    "contact_event_enable_mayday_success": "DSN {dsn} cihazının Yardım işlevi başarıyla yeniden etkinleştirildi.",
    "contact_event_external_transfer": "Bu bağlantı harici olarak {transferAddress} adresine gönderilmiştir",
    "contact_event_external_transfer_batched": "Bu bağlantı harici olarak {transferAddress} adresine gönderilmiştir ({count})",
    "contact_event_first_time_user_experience_set": "İlk Kullanıcı Deneyimini Ayarla",
    "contact_event_first_time_user_experience_set_batched": "İlk Kullanıcı Deneyimini Ayarla({count}).",
    "contact_event_free_time_unlimited_cancelled": "Sınırsız Serbest Zaman aboneliği şu müşteri için: {customerId}, DSN: {deviceSerialNumber}, {agentId} tarafından şu nedenle iptal edildi: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Sınırsız Serbest Zaman aboneliği iptal edildi ({count}).",
    "contact_event_future_cancel_audible_membership": "{membership} tarifesi, {date} tarihinde silinmek üzere Gelecekte İptal olarak ayarlanmış.",
    "contact_event_future_cancel_audible_membership_batched": "{membership} tarifesi, {date} tarihinde silinmek üzere Gelecekte İptal olarak ayarlanmış.({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} Video Kitaplığınızdan kalıcı olarak silindi.",
    "contact_event_hmd_suppression": "HMD şu nedenle gönderilmedi:  {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Etkin olmayan sohbet bağlantısı kesildi",
    "contact_event_idle_chat_disconnect_batched": "Etkin olmayan sohbet bağlantısı kesildi ({count})",
    "contact_event_kindle_unlimited_refund": "{transaction_id} numaralı işlem için Kindle Sınırsız {amount} tutarı geri ödedi.",
    "contact_event_live_assist_launched": "DSN: {dsn}, kcsID: {kcsId}, müşteri yanıtı: {customerResponse} için Mayday Screen Sharing etkinleştirildi.",
    "contact_event_live_assist_launched_batched": "Cihazlar ({count}) için Mayday Screen Sharing etkinleştirildi.",
    "contact_event_lock_dash_order": "{dsn} cihazı için aşağıdaki ASIN'lerin kilidi açıldı: {asins}.",
    "contact_event_music_auto_renew_off": "CS, {subscriptionid} Music aboneliği için otomatik yenilemeyi KAPATTI.",
    "contact_event_music_auto_renew_off_static": "CS, Music aboneliği için Astro üzerinden otomatik yenilemeyi KAPATTI.",
    "contact_event_music_full_refund": "CS, {subscriptionid} Music aboneliğini tam para iadesiyle iptal etti",
    "contact_event_music_full_refund_static": "CS, Music aboneliğini Astro üzerinden tam para iadesiyle iptal etti.",
    "contact_event_music_no_refund": "CS, {subscriptionid} Music aboneliğini para iadesiz iptal etti.",
    "contact_event_music_no_refund_static": "CS, Music aboneliğini Astro üzerinden para iadesiz olarak iptal etti.",
    "contact_event_music_unlimited_auto_renew_off": "CS, Amazon Music Unlimited aboneliği {subscriptionid} için otomatik yenileme özelliğini kapattı.",
    "contact_event_music_unlimited_full_refund": "CS, tam para iadesi ile Amazon Music Unlimited aboneliğini {subscriptionid} iptal etti.",
    "contact_event_music_unlimited_no_refund": "CS, hiç iade olmadan Amazon Music Unlimited aboneliğini {subscriptionid} iptal etti.",
    "contact_event_music_unlimited_partial_refund": "CS, kısmi para iadesi ile Amazon Music Unlimited aboneliğini {subscriptionid} iptal etti.",
    "contact_event_music_unlimited_workflow_launch": "CS, Amazon Music Unlimited aboneliği {subscriptionid} için Astro üzerinden iptal iş akışını başlattı.",
    "contact_event_music_workflow_launch": "CS, {subscriptionid} Music aboneliği için Astro iptal iş akışını başlattı.",
    "contact_event_music_workflow_launch_static": "CS, Music unlimited için Astro iptal iş akışını başlattı.",
    "contact_event_one_click_payment_updated": "1-Tık'la ödeme güncellendi.",
    "contact_event_one_click_payment_updated_batched": "1-Tık'la ödeme güncellendi ({count}).",
    "contact_event_payment_method_changed": "{order_id} siparişi için ödeme yöntemi değiştirildi.",
    "contact_event_payment_method_changed_batched": "({count}) siparişleri için ödeme yöntemi değiştirildi.",
    "contact_event_payment_plan_changed": "{order_id} siparişi için ödeme planı değiştirildi.",
    "contact_event_payment_plan_changed_batched": "({count}) siparişleri için ödeme planı değiştirildi.",
    "contact_event_pennywise_promo_applied": "DSN için Pennywise Promosyonu uygulandı: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "DSN'ler için Pennywise Promosyonu uygulandı: ({count})",
    "contact_event_promo_unredeemed": "Yerine getirilmeyen {promoBalance} promosyonu.",
    "contact_event_promo_unredeemed_batched": "Yerine getirilmeyen promosyonlar ({count}).",
    "contact_event_refund_cancelled": "{order_id} siparişi para iadesi iptal edildi.",
    "contact_event_registry_deleted": "Kayıt Silindi (RegistryID # {registry_id}).",
    "contact_event_registry_deleted_batched": "Kayıtlar Silindi ({count}).",
    "contact_event_release_aiv_license": "DSN {dsn}. cihazında AIV {asin} Lisansı verildi",
    "contact_event_remove_from_yvl": "ASIN {asin} Video Kitaplığınızdan kaldırılıp Silinen Öğeler'e taşındı.",
    "contact_event_request_invoice": "{order_id} Siparişi için Satıcıya Fatura İsteği Gönderildi.",
    "contact_event_request_invoice_batched": "({count}) siparişleri için fatura isteği gönderildi.",
    "contact_event_reset_password_email_sent": "Parola sıfırla epostası gönderildi.",
    "contact_event_reset_password_email_sent_batched": "Parola sıfırla epostası gönderildi({count}).",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP Adı: {driverName}, \nİstasyon Adı: {stationName}, \nTakip Numarası: {trackingId} ve \nSipariş Numarası: {orderId}\nUsecaseSession Numarası: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "{trackingId} Takip Numarası için harita pencere öğesi açıldı",
    "contact_event_sds_package_repick": "Sorumlu {trackingId} takip numarası için Yeniden Teslim Alma işlemini başlattı",
    "contact_event_send_aiv_asin": "ASIN {asin} {customer_id} adlı müşteriye ait Video Kitaplığınıza gönderildi.",
    "contact_event_shipping_address_changed": "{new_order_id} siparişindeki adres değişti.",
    "contact_event_sim_ticket_created": "SIM-Ticket oluşturuldu",
    "contact_event_subscription_astro_workflow_launch": "CS, {subscriptionId} {subscriptionType} aboneliği için Astro üzerinden iptal iş akışını başlattı.",
    "contact_event_subscription_auto_renew_off": "CS, {subscriptionId} {subscriptionType} aboneliği için otomatik yenilemeyi KAPATTI.",
    "contact_event_subscription_cancel_full_refund": "CS, {subscriptionId} {subscriptionType} aboneliğini tam para iadesiyle iptal etti.",
    "contact_event_subscription_cancel_no_refund": "CS, {subscriptionId} {subscriptionType} aboneliğini para iadesiz olarak iptal etti.",
    "contact_event_subscription_cancel_partial_refund": "CS, {subscriptionId} {subscriptionType} aboneliğini kısmi para iadesiyle iptal etti.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "Mevcut dönemin sonunda {plan_type} aboneliği {old_plan_name} öğesinden {new_plan_name} öğesine yenilemek için ayarlandı.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Güncel Başlangıç yenileme plan ayarları ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "ORDERID {digitalOrderID} ile verilen dijital sipariş için sorun giderme sihirbazı çağrıldı.",
    "contact_event_unlock_dash_order": "{dsn} cihazı için aşağıdaki ASIN'lerin kilidi açıldı: {asins}.",
    "contact_event_update_locking_status": "{dsn} Oft cihazının kilitlenme durumu şuna güncellendi: {newLockingStatus}",
    "contact_event_verify_email": "Doğrulama e-postası müşteriye gönderildi.",
    "contact_event_weekend_delivery_preference_changed": "Hafta Sonu Teslimat Tercihi Güncellendi"
}