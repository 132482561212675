export const en_AU = {
    "ACCESS_RESTRICTED_CONTENT": "Access restricted content",
    "ACTION": "Action",
    "ADD": "Add",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Additional contact information",
    "ADD_ANNOTATION_FAILURE": "Failed to add the annotation.",
    "ADD_ANNOTATION_INVALID": "Invalid annotation. Please make sure that there is no restricted data in the annotation.",
    "ADD_ANNOTATION_PLACEHOLDER": "New annotation",
    "ADD_ANNOTATION_SUCCESS": "Successfully added annotation.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Agent selected issue",
    "AGENT_SELECTED_ISSUE_COPIED": "Agent selected issue copied.",
    "AMAZON_CONNECT_CALL_INFO": "Amazon connect call info",
    "AMAZON_CONNECT_CONTACT_ID": "Amazon connect contact ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Amazon connect contact ID copied",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANNOTATED_BY": "Annotated by {agentLogin}.",
    "ANNOTATIONS": "Annotations",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Annotations are not supposed to contain customer PII data such as phone numbers and email addresses. Our automated system tries to enforce that, yet sometimes does not know the difference between PII and other IDs. Please provide the reason why an override is needed.",
    "ANNOTATION_WARNING": "Annotations may be subject to review by customers under privacy regulations. Do not include personal information or opinions within the annotations field.",
    "ATTACHMENTS_RESTRICTED": "Attachments are restricted.",
    "ATTACHMENTS_TITLE": "Attachments",
    "ATTACHMENT_DELETION_FAILED": "Attachments deletion failed, please try again later.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Attachments deletion successful.",
    "AUTHENTICATING": "Started authentication. This may open a new window.",
    "AUTHENTICATION_FAILURE": "There was a problem with authenticating.",
    "AUTHENTICATION_MESSAGE": "Fetching new credentials. This window will close itself in a couple of seconds.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Back",
    "BCC_EMAIL_ADDRESS": "Bcc",
    "BLURBS_USED_TITLE": "Blurbs used",
    "CALL_LEG": "Call Leg ID",
    "CALL_LEG_ID_COPIED": "Call leg ID copied.",
    "CALL_RESTRICTED": "Call content is restricted.",
    "CALL_RETENTION_DELETED": "The recording is past its retention period and has been deleted.",
    "CALL_TITLE": "Call with Amazon customer service",
    "CANCEL": "Cancel",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "This customer has a very large number of contacts and not all of them have been loaded. Contacts are always loaded in \"recent to older\" direction starting from the end date. Change the end date to start loading from a different point in time.",
    "CHANNELS": "Channels",
    "CHAT_AGENT_CONNECTED": "{name} has accepted the chat.",
    "CHAT_CONTACT": "Chat contact.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} lost connection with the network.",
    "CHAT_DISCONNECT_GENERIC": "{name} is no longer connected.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} lost connection with the network.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} lost connection with the network.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} has left the conversation.",
    "CHAT_PARKED": "This chat was parked",
    "CHAT_PARKED_NOTES": "Park Notes",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} is off hold.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} is on hold.",
    "CHAT_RESTRICTED": "Chat content is restricted.",
    "CHAT_TITLE": "Chat with Amazon customer service",
    "CHAT_TRANSFER_NOTES": "Transfer notes",
    "CHILD_WORK_REQUESTS": "Work requests created from this contact",
    "CHOOSE_OPTIONS": "Choose options",
    "CLOSE": "Close",
    "CLOSE_ALERT": "Close alert",
    "CLOSE_MODAL_BUTTON_TEXT": "Close",
    "COLLAPSED": "Collapsed",
    "CONFIRM": "Confirm",
    "CONTACTS": "Contacts",
    "CONTACT_COLLAPSED": "Contact details collapsed.",
    "CONTACT_CREATION_FAILURE": "New contact creation failed",
    "CONTACT_EXPANDED": "Contact details expanded.",
    "CONTACT_HISTORY": "Contact history",
    "CONTACT_ID": "Contact ID",
    "CONTACT_ID_COPIED": "Contact ID copied.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contact",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Escalations",
    "CONTACT_REASSIGNED_TO_AGENT": "Contact has been reassigned to agent.",
    "CONTACT_SELECT_CHECKBOX": "Tick box to select a locked contact",
    "CONTACT_STATUS_ESCALATION_BADGE": "Escalation",
    "CONTACT_TICKET": "Contact ticket",
    "CONTACT_TICKET_CONTENT": "Ticket content",
    "CONTACT_TICKET_CREATE": "Create contact ticket",
    "CONTACT_TICKET_CREATION_FAILURE": "Failed to create contact ticket",
    "CONTACT_TICKET_CREATION_SUCCESS": "Contact ticket created",
    "CONTACT_TICKET_RESOLVER_GROUP": "Resolver group",
    "CONTACT_TICKET_SEVERITY": "Ticket severity",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM folder ID",
    "CONTACT_TICKET_SUBJECT": "Ticket subject",
    "CONTACT_TIMELINE": "Contact timeline",
    "CONTACT_TYPE": "Contact type",
    "CONTACT_WAS_TRANSFERRED": "The contact has been transferred.",
    "CONTENT": "Contents",
    "CONTENT_RESTRICTED": "Content is restricted.",
    "COPY_AGENT_SELECTED_ISSUE": "Copy agent selected issue: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copy Amazon connect contact ID",
    "COPY_CALL_LEG_ID": "Copy call leg ID: {value}",
    "COPY_CONTACT_ID": "Copy contact ID: {value}",
    "COPY_CUSTOMER_ID": "Copy customer ID",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copy customer selected issue: {value}",
    "COPY_FAILURE": "Failed to copy.",
    "COPY_ID": "Copy ID",
    "COPY_LINK": "Copy image upload link",
    "COPY_ORDER_ID": "Copy order ID: {value}.",
    "CREATE_CASE": "Create Case",
    "CREATE_FOLLOW_UP": "Create follow-up",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Successfully created follow-up, but could not add corresponding annotation. Retry adding annotation manually.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Specify the customer's preferred method of contact.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Cannot create a follow-up with a due date and time in the past.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Specify the date and time when the follow-up action is due.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Time zone",
    "CREATE_FOLLOW_UP_FAILURE": "Failed to create follow-up.",
    "CREATE_FOLLOW_UP_INVALID": "Invalid follow-up. Please make sure there is no restricted data.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Enter the follow-up action.",
    "CREATE_FOLLOW_UP_SUCCESS": "Successfully created follow-up.",
    "CREATE_WORK_REQUEST": "Create work request",
    "CROSS_MARKETPLACE_QUEUES": "Cross marketplace queues",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Failed to transfer the contact because it is already resolved.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "The operation failed because the contact is in an invalid state.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Failed operation because the contact was not found",
    "CSALT_ERROR_INTERNAL_ERROR": "Failed to lock contact",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Failed to lock a contact due to invalid arguments",
    "CSALT_ERROR_INVALID_INPUT": "The operation failed due to invalid inputs.",
    "CSALT_ERROR_INVALID_QUEUE": "Failed to transfer the contact because the selected queue is invalid.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Failed operation because Contact is locked to a different Agent.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Failed to lock contact due to dependency error",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Failed to transfer the contact because the queue was not found in the backend service.",
    "CSALT_ERROR_UNKNOWN": "The operation failed due to an unexpected error.",
    "CURRENT_CONTACT": "Current contact",
    "CUSTOMER": "Customer",
    "CUSTOMER_FOLLOWUPS": "Follow-ups for customer",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Failed to fetch follow-ups for customer",
    "CUSTOMER_ID_COPIED": "Customer ID copied",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Customer content is restricted. (no contacts locked)",
    "CUSTOMER_SELECTED_ISSUE": "Customer selected issue",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Customer selected issue copied.",
    "CUSTOMER_TIMEZONE": "Timestamps shown in customer's timezone",
    "DATE": "Date",
    "DATE_PICKER_NEXT_MONTH": "Next month",
    "DATE_PICKER_PREVIOUS_MONTH": "Previous month",
    "DATE_PICKER_TODAY": "Today",
    "DECODING_AUDIO": "Decoding audio",
    "DELETE_ATTACHMENTS": "Delete contact attachments",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Delete attachments",
    "DELIVERY_STATUS": "Delivery Status",
    "DISABLE_CONTENT_FILTER": "Disable content filter",
    "DOWNLOAD": "Download",
    "DURATION": "Duration",
    "EMAIL": "Email",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Recipient email address(es) is not allowed.",
    "EMAIL_BODY": "Email body",
    "EMAIL_CONTACT": "Email contact.",
    "EMAIL_HEADER": "Header",
    "EMAIL_QUEUE_TITLE": "Email queue",
    "EMAIL_RESTRICTED": "Email content is restricted.",
    "EMAIL_SENDING_FAILURE": "Sending outbound email failed.",
    "EMAIL_SUBJECT": "Email Subject",
    "EMAIL_TITLE": "Email with Amazon customer service",
    "END": "End",
    "END_DATE": "End date",
    "ERROR": "Error",
    "ERROR_FAILED_TO_LOAD_DATA": "Failed to load data.",
    "ESCALATION_CONTACT_HANDLING": "Contact for handling escalation",
    "ESCALATION_TAB_LEAD_LOGIN": "Lead login: {agentLogin}",
    "EXPANDED": "Expanded",
    "FAILED_TO_CREATE_WORK_REQUEST": "Failed to create work request",
    "FAILED_TO_FETCH_SYSKA": "Failed to fetch SYSKA alerts",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Failed to reassign contact to agent.",
    "FETCHED_NUMBER_CONTACTS": "Fetched {number} contacts.",
    "FILTER": "Filter",
    "FINDER": "Finder",
    "FOLLOW_UPS_COLLAPSED": "Follow-ups collapsed",
    "FOLLOW_UPS_EXPANDED": "Follow-ups expanded",
    "FOLLOW_UP_ALERT": "has a follow-up due on {dueDate} created by {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Contact method",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Contact method is restricted.",
    "FOLLOW_UP_DUE": "Follow-up due",
    "FOLLOW_UP_DUE_DATE": "Due date",
    "FOLLOW_UP_NOTE": "Note",
    "FOLLOW_UP_NO_CONTACT_METHOD": "No contact method",
    "FOLLOW_UP_NO_NOTE": "Note is empty",
    "FOLLOW_UP_NO_TICKET": "No ticket",
    "FOLLOW_UP_OWNER": "Owner",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_OPEN": "Open",
    "FOLLOW_UP_STATUS_RESOLVED": "Resolved",
    "FOLLOW_UP_TICKET": "Ticket",
    "FOLLOW_UP_TITLE": "Follow-ups",
    "FROM": "From",
    "GENERATE_LINK": "Generate link",
    "HAS_OVERRIDE_TRY_AGAIN": "Filter override present. Try again.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "Image attachment link shared with the customer",
    "INPUT_CHARACTERS_REMAINING": "Maximum {char_max} characters ({char_remain} remaining)",
    "INTERNAL_ERROR": "Internal error",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "An internal error was encountered. If the error persists, please cut us a ticket explaining the issue.",
    "INVALID_CONTACT": "Invalid contact.",
    "INVALID_DATA_MATCH": "The system generated invalid classification of data.",
    "INVALID_TIMESTAMP": "Invalid timestamp.",
    "IN_MARKETPLACE_QUEUES": "In marketplace queues",
    "ISSUE": "Issue",
    "ISSUE_CODE": "Issue code",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Already addressed and resolved in a previous contact",
    "ISSUE_CODE_DESCRIPTION": "Select the reason for marking selected contacts as duplicate",
    "ISSUE_CODE_EXACTLY_SAME": "Exactly the same as a previous contact",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Handled and resolved by another chat CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Handled and resolved by another email CSA",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Handled and resolved by another phone CSA",
    "ITEMS_CONTACTED_ABOUT": "Item(s) contacted about",
    "I_UNDERSTAND": "I understand",
    "KNOWN_ISSUES": "Known issues",
    "LEARN_MORE": "Learn more",
    "LINK_COPIED": "Link copied",
    "LOADING": "Loading data.",
    "LOADING_PREVIEW_TEXT": "Loading preview",
    "LOADING_RECORDING": "Loading recording",
    "LOAD_MORE": "Load more",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Load more annotations.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Load more contacts for this customer.",
    "LOCKED": "Locked",
    "LOCKED_BY": "Locked by",
    "LOCK_CONTACT": "Lock",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Failed to lock the contact, user is not authorised",
    "LOCK_CONTACT_SUCCESSFUL": "Contact locked to",
    "MISSING_ORIGINAL_CONTACT_ID": "Please enter valid original contact ID",
    "MODAL_NO_BUTTON_TEXT": "No",
    "MODAL_YES_BUTTON_TEXT": "Yes",
    "NEXT": "Next",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "No agent selected issue.",
    "NO_ANNOTATED_BY": "Annotated by unknown user.",
    "NO_ANNOTATIONS": "No annotations available.",
    "NO_ATTACHMENTS": "There are no attachments.",
    "NO_CHAT": "Chat is empty.",
    "NO_CHILD_WORK_REQUESTS": "No work requests created from this contact",
    "NO_CONTACT": "No contact available",
    "NO_CONTACTS_SELECTED": "Please select contacts to process them as spam",
    "NO_CONTENT": "No content",
    "NO_CUSTOMER_SELECTED_ISSUE": "No customer selected issue.",
    "NO_DELIVERY_STATUS": "No delivery status",
    "NO_EMAIL_BODY": "Email is empty.",
    "NO_EMAIL_HEADER": "No email header.",
    "NO_EMAIL_QUEUE": "No email queue associated with this contact.",
    "NO_ITEMS_CONTACTED_ABOUT": "No items contacted about.",
    "NO_RESULTS": "No results found.",
    "NO_SCREEN_POP": "No ScreenPop information",
    "NO_SENT_FROM": "No sent from address.",
    "NO_SENT_TO": "No sent to address",
    "NO_SUBJECT": "Subject line is empty.",
    "NO_SYSKA_ALERTS": "No SYSKA alerts",
    "NO_USED_BLURBS": "No blurbs used for this contact.",
    "NO_VIEW": "No contact or customer to view.",
    "NO_WORK_CATEGORY": "No work category associated to this contact.",
    "NUMBER_OF_CONTACTS": "Loaded contacts",
    "OLDEST_LOADED_CONTACT": "Oldest loaded contact",
    "OPEN": "Open",
    "OPENS_IN_A_NEW_TAB": "Opens in a new tab",
    "ORDER_ID_COPIED": "Order ID copied.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Enter original contact ID",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Text box to input original contact ID",
    "OTHER": "Other",
    "OUTBOUND_EMAIL_BCC": "and bcc'ing -",
    "OUTBOUND_EMAIL_CC": "while cc'ing -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Notification content collapsed.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Notification content expanded.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Maximum number of results reached. Please reduce time range and try again.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "The current process requires PII content.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Invalid match as phone number, email address or other PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Invalid match as credit card or IBAN.",
    "OVERRIDE_REASON_PROMPT": "Annotations are not supposed to contain customer PII data like phone numbers and email addresses. Our automated system tries to enforce that, yet sometimes does not know the difference between PII and other IDs. Provide the reason why an override is needed.",
    "PAPYRUS_SESSION_EXPLANATION": "Please specify your Papyrus session type so that we can apply the right security restrictions and optimise the UX for your needs.",
    "PAPYRUS_SESSION_TYPE": "Papyrus session type",
    "PAUSE": "Pause",
    "PHONE_CONTACT": "Phone contact.",
    "PLAY": "Play",
    "PLAYBACK_SPEED": "Playback speed",
    "POSTAL_MAIL_TITLE": "Postal mail with Amazon customer service",
    "PREPARING_RECORDING": "Preparing recording",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Failed to generate preview for contact",
    "PREVIOUS": "Previous",
    "PRE_TRANSLATED_REPLY_EMAIL": "Pre-translated reply email",
    "PRE_TRANSLATION_REPLY_TEXT": "CSA-typed reply before Rosetta translation",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Process actions",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Process as duplicate",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Process as spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Resolve selected contacts as duplicate",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Are you sure that you want to process selected contacts as spam?",
    "PROCESS_DUPLICATE_FAILURE": "Failed to resolve selected contacts as duplicate",
    "PROCESS_DUPLICATE_SUCCESS": "Successfully resolved selected contacts as duplicate",
    "PROCESS_SPAM_FAILURE": "Failed to transfer selected contacts to spam",
    "PROCESS_SPAM_SUCCESS": "Successfully transferred the selected contacts to spam",
    "PROVIDE_ADDITIONAL_INFORMATION": "Provide additional information relevant to this contact",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Purged sensitive info from contact:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Add word to purge list",
    "PURGE_BUTTON_TEXT": "Purge",
    "PURGE_CONFIRMATION_CHECK": "I have verified the above fields and confirm that the text indeed contains sensitive information.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "I confirm that attachment indeed contains customer-sensitive information.",
    "PURGE_CONFIRMATION_UNCHECKED": "Please confirm tick box.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Failed to purge from contact",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Successfully purged info from contact",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} is not a valid reason for purging",
    "PURGE_REASON_FINANCIAL_INFO": "Financial info",
    "PURGE_REASON_OTHER": "Other sensitive info",
    "PURGE_REASON_PII": "Personally identifiable information",
    "PURGE_REASON_PLACEHOLDER": "Enter reason for purge",
    "PURGE_REASON_TEXT": "Reason for purge",
    "PURGE_REMOVE_LABEL": "Remove",
    "PURGE_SELECTOR_PLACEHOLDER": "Select reason for purging sensitive info",
    "PURGE_SENSITIVE_INFO": "Purge sensitive info",
    "PURGE_WORD_NOT_FOUND": "Word is not found in contact text",
    "PURPOSE_AUDITING": "Auditing for quality control",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Back office workflow",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "This is the session for obtaining contact data for back office workflows. For example, attachments to include in tickets or SIM.",
    "PURPOSE_COACHING": "Coaching contact review",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Compliance investigation",
    "PURPOSE_CURATION": "Contact curation",
    "PURPOSE_CURATION_DESCRIPTION": "This is the session for C2CS/VoC contact curation.",
    "PURPOSE_DESCRIPTION_AUDITING": "This is the session for auditing contacts and evaluating their quality.",
    "PURPOSE_DESCRIPTION_COACHING": "This is the session for reviewing contacts of agents of whom you are a Performance Portal coach.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "This is the session for suspicious activity monitoring investigations.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "This is the session for handling escalation contacts. This allows access to any customer's contact data.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "This is the session for live contact handling.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "This purpose is to remove sensitive info from a contact",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "This is the session for reviewing the machine learning model accuracy.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "This is the session for reviewing your own past contacts.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "This is the default general purpose session.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Contact for handling escalation",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Purge sensitive info",
    "REASON_FOR_PURGE": "Reason for purge",
    "REASON_FOR_PURGE_DESCRIPTION": "Reason for purging attachments",
    "REASON_FOR_PURGE_MISSING": "Please enter reason for deleting attachments.",
    "REASSIGN_TO_AGENT": "Reassign to agent",
    "RECORDING": "Recording",
    "RELOADING_CONTENT": "Reloading content.",
    "RELOAD_LIST": "Reload list",
    "REPLY_BY": "Reply by",
    "REPLY_BY_EMAIL": "Reply by email",
    "REPLY_BY_PHONE": "Reply by phone",
    "REPLY_RESTRICTED": "Reply content is restricted.",
    "REQUEST": "Request",
    "REQUEST_ACCESS_INSTRUCTIONS": "Please provide the reason why access to restricted content is required. (These requests are monitored and abuse may have serious consequences.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Request access to filtered content",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Request access to restricted content",
    "REQUEST_NOT_GRANTED": "The request was not granted.",
    "REQUEST_OVERRIDE": "Request override",
    "RESEND": "Resend",
    "RESEND_FAILED": "Failed to resend email.",
    "RESEND_SUCCESS": "Successfully resent email.",
    "RESEND_WAIT_WARNING": "You must wait for {wait_mins} minutes to resend again.",
    "RESEND_WARNING": "Are you sure you want to resend the email to the customer?",
    "RESET": "Reset",
    "RESOLVED": "Resolved",
    "RESOLVED_BY": "Resolved by",
    "RESTRICTED_DATA_MATCH": "{match} matching type {type}",
    "RESTRICTED_INFORMATION": "RESTRICTED INFORMATION",
    "RETRY": "Retry",
    "ROLE": "Role",
    "SCREEN_POP_DESCRIPTION_TITLE": "Description",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "No",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Yes",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Displayed to agent",
    "SCREEN_POP_TITLE": "ScreenPop information",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Authenticating screen pop was displayed to agent and it was used.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Authenticating screen pop was displayed to agent.",
    "SCREEN_POP_TYPE_CALLER_ID": "Caller ID screen pop was displayed to agent and it was used.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Caller ID screen pop was displayed to agent.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Unauthenticated, hunched screen pop was displayed and it was used.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Unauthenticated, hunched screen pop was displayed to the agent.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Customer is unknown; no screen pop provided.",
    "SCREEN_POP_TYPE_OTHER": "Other",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "No",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Yes",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Used by agent",
    "SDS_TOOLS": "Shipping and Delivery Support Tool(s)",
    "SEARCH": "Search",
    "SELECTED_ROLE": "Selected role",
    "SELECTED_VIEW": "Selected view",
    "SELECT_CHANNELS": "Select channels",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "To select issue code for marking selected contacts as duplicate",
    "SELECT_TRANSFER_DESTINATION": "Select transfer destination",
    "SELECT_WORK_REQUEST_QUEUE": "Select work request queue",
    "SENDER_EMAIL_ADDRESS": "Sender",
    "SENDING_EMAIL": "Sending Email",
    "SEND_EMAIL": "Send Email",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Are you sure that you want to send image upload link to the customer?",
    "SEND_OUTBOUND_EMAIL": "Send Outbound Email",
    "SEND_OUTBOUND_EMAIL_WARNING": "Are you sure you want to send outbound email to",
    "SENT_FROM": "Sent from",
    "SENT_OUTBOUND_EMAIL": "Outbound email was sent. A new contact was created",
    "SENT_TO": "Sent to",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Shipper Portal",
    "SKILL": "Skill",
    "SKIP_SILENCES": "Skip silences",
    "SMS": "SMS",
    "SMS_LINK": "SMS link",
    "SOURCE_TEXT": "Source text",
    "SP_BOTTOM": "Bottom",
    "SP_POSITION": "Split panel position",
    "SP_PREFERENCES": "Split panel preferences",
    "SP_RESIZE": "Resize split panel",
    "SP_SIDE": "Side",
    "START": "Start",
    "STATE_TRANSITIONS": "State transitions",
    "STATUS": "Status",
    "SUBJECT": "Subject",
    "SYSKA_ALERTS": "SYSKA alerts",
    "TEXT_TO_PURGE": "Text to purge",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Third-party cookies must be enabled.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Customer PII data, like phone numbers and email addresses, are not permitted while creating a ticket. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other IDs. Provide the reason why an override is needed.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Timeout: failed to load recording",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Use 24-hour format.",
    "TO": "To",
    "TO_EMAIL_ADDRESS": "To",
    "TRANSFER": "Transfer",
    "TRANSFERRED_CHAT_TITLE": "Transferred chat with Amazon customer service",
    "TRANSFERRING": "Transferring...",
    "TRANSFER_CONTACT": "Transfer contact",
    "TRANSFER_CONTACT_COMMENTS": "Optional annotation",
    "TRANSFER_FAILED": "Transfer failed",
    "TRANSFER_FOLLOW_UP": "Transfer follow-up",
    "TRANSFER_FOLLOW_UP_FAILED": "Failed to transfer follow-up.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Failed to transfer follow-up due to invalid new owner login.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "You do not have permission to transfer this follow-up.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Enter the login name of the new owner. Example: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transfer the above follow-up to:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Successfully transferred follow-up.",
    "TRANSLATED_TEXT": "Translated text",
    "TRANSLATION": "Translation",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} is not a valid queue name.",
    "TYPE_AGENT_LOGIN": "Type agent login",
    "TYPE_EMAIL_ADDRESS": "Type email address",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Type multiple email addresses separated by comma",
    "TYPE_QUEUE_NAME": "Type queue name",
    "UNKNOWN_CONTACT_TYPE": "Unknown contact type.",
    "UNLOCKED": "Unlocked",
    "UNRESOLVED": "Unresolved",
    "URGENT_SYSKA_ALERTS": "Urgent SYSKA alert",
    "USE": "Use",
    "USER_TIMEZONE": "Timestamps shown in your local timezone",
    "USE_CONTACT_FINDER": "Use contact finder",
    "VIEW": "View",
    "WARNING": "Warning",
    "WELCOME_MESSAGE": "Welcome to the Papyrus static website.",
    "WHAT_IS_PAPYRUS": "What is Papyrus?",
    "WITH_THE_ANNOTATION": "with the annotation",
    "WORK_CATEGORY_TITLE": "Work category",
    "WORK_REQUEST": "Work request",
    "WORK_REQUEST_CONTENT": "Work request content",
    "WORK_REQUEST_CREATED": "Work request created",
    "WORK_REQUEST_FOR_CONTACT": "Work request for contact",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Customer PII data like phone numbers and email addresses are not permitted while creating a work request. Our automated system tries to enforce that yet sometimes does not know the difference between PII and other IDs. Provide the reason why an override is needed.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "You are about to transfer to queue"
}