/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from "@amzn/awsui-components-react/polaris/box";
import Phoenix from '../api/Phoenix';

const Domain: React.FC = () => {
    return (
        <div>
            <Box variant="h2">Domain Realm Gateway Endpoint Test</Box>
            <Box variant="p">Endpoint = {Phoenix.getInstance().gatewayQualifier} </Box>
            <Box variant="p">Gateway = {Phoenix.getInstance().gatewayEndpoint} </Box>
        </div>
    );
};

export default Domain;
