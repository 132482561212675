export const cs_CZ = {
    "ACCESS_RESTRICTED_CONTENT": "Obsah s omezeným přístupem",
    "ACTION": "Akce",
    "ADD": "Přidat",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Další kontaktní údaje",
    "ADD_ANNOTATION_FAILURE": "Poznámku se nepodařilo přidat.",
    "ADD_ANNOTATION_INVALID": "Neplatná poznámka. Ujistěte se, že v poznámce nejsou žádná omezeně přístupná data.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nová poznámka",
    "ADD_ANNOTATION_SUCCESS": "Poznámka byla úspěšně přidána.",
    "AGENT": "Zástupce",
    "AGENT_ANNOTATION_BADGE": "Zástupce",
    "AGENT_SELECTED_ISSUE": "Problém zvolený zástupcem",
    "AGENT_SELECTED_ISSUE_COPIED": "Zástupcem zvolený problém byl zkopírován.",
    "AMAZON_CONNECT_CALL_INFO": "Informace o hovorech služby Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID kód kontaktu služby Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID kód kontaktu služby Amazon Connect byl zkopírován.",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Logistika společnosti Amazon",
    "ANNOTATED_BY": "Poznámku přidal/a: {agentLogin}",
    "ANNOTATIONS": "Poznámky",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Anotace by neměly obsahovat osobní údaje, jako jsou telefonní čísla a e-mailové adresy. Náš automatický systém se na to snaží dohlížet, někdy však není schopen rozlišit osobní údaje od jiných ID. Uveďte důvod, proč je potřeba výjimka.",
    "ANNOTATION_WARNING": "Poznámky mohou být předmětem kontroly ze strany zákazníků podle předpisů o ochraně osobních údajů. Do pole pro poznámky neuvádějte osobní údaje ani názory.",
    "ATTACHMENTS_RESTRICTED": "Přílohy jsou omezené.",
    "ATTACHMENTS_TITLE": "Přílohy",
    "ATTACHMENT_DELETION_FAILED": "Odstranění příloh selhalo, zkuste to znovu později.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Odstranění příloh proběhlo úspěšně.",
    "AUTHENTICATING": "Zahájeno ověřování. Může se otevřít v novém okně.",
    "AUTHENTICATION_FAILURE": "Vyskytl se problém s ověřováním.",
    "AUTHENTICATION_MESSAGE": "Načítání nových oprávnění. Toto okno se za pár sekund samo zavře.",
    "AUTO_ANNOTATION_BADGE": "Automaticky",
    "BACK": "Zpět",
    "BCC_EMAIL_ADDRESS": "Ve slepé kopii",
    "BLURBS_USED_TITLE": "Použité propagační texty",
    "CALL_LEG": "ID etapy hovoru",
    "CALL_LEG_ID_COPIED": "ID etapy hovoru zkopírováno.",
    "CALL_RESTRICTED": "Obsah hovoru je omezený.",
    "CALL_RETENTION_DELETED": "Vypršela doba uchovávání záznamu a byl proto odstraněn.",
    "CALL_TITLE": "Hovor se zákaznickou službou Amazon",
    "CANCEL": "Zrušit",
    "CC_EMAIL_ADDRESS": "V kopii",
    "CF_LARGE_NUMBER_ALERT": "Tento zákazník má velmi velký počet kontaktů a nebyly načteny všechny z nich. Kontakty jsou vždy načteny v pořadí „od nejnovějších po nejstarší“ počínaje koncovým datem. Změňte koncové datum pro informace ohledně jiného časového období.",
    "CHANNELS": "Kanály",
    "CHAT_AGENT_CONNECTED": "Uživatel {name} přijal chat.",
    "CHAT_CONTACT": "Chatový kontakt",
    "CHAT_DISCONNECT_GACD_HANG_UP": "Uživatel {name} ztratil připojení k síti.",
    "CHAT_DISCONNECT_GENERIC": "Uživatel {name} již není připojen.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "Uživatel {name} ztratil připojení k síti.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "Uživatel {name} ztratil připojení k síti.",
    "CHAT_DISCONNECT_USER_HANG_UP": "Uživatel {name} opustil konverzaci.",
    "CHAT_PARKED": "Tento chat byl odložen",
    "CHAT_PARKED_NOTES": "Zaparkovat poznámky",
    "CHAT_PARTICIPANT_OFF_HOLD": "Pozdržení chatu s uživatelem {name} bylo ukončeno.",
    "CHAT_PARTICIPANT_ON_HOLD": "Chat s uživatelem {name} je pozdržen.",
    "CHAT_RESTRICTED": "Obsah chatu je omezený.",
    "CHAT_TITLE": "Chat se zákaznickou službou Amazon",
    "CHAT_TRANSFER_NOTES": "Poznámky k převodu",
    "CHILD_WORK_REQUESTS": "Pracovní požadavky vytvořené tímto kontaktem",
    "CHOOSE_OPTIONS": "Vybrat možnosti",
    "CLOSE": "Zavřít",
    "CLOSE_ALERT": "Zavřít výstrahu",
    "CLOSE_MODAL_BUTTON_TEXT": "Zavřít",
    "COLLAPSED": "Sbaleno",
    "CONFIRM": "Potvrdit",
    "CONTACTS": "Kontakty",
    "CONTACT_COLLAPSED": "Kontaktní údaje byly sbaleny.",
    "CONTACT_CREATION_FAILURE": "Vytvoření nového kontaktu se nezdařilo",
    "CONTACT_EXPANDED": "Kontaktní údaje byly rozbaleny.",
    "CONTACT_HISTORY": "Historie kontaktu",
    "CONTACT_ID": "ID kontaktu",
    "CONTACT_ID_COPIED": "ID kontaktu bylo zkopírováno.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Kontakt",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Eskalace",
    "CONTACT_REASSIGNED_TO_AGENT": "Kontakt byl přeřazen zástupci.",
    "CONTACT_SELECT_CHECKBOX": "Zaškrtávací políčko pro výběr uzamčeného kontaktu",
    "CONTACT_STATUS_ESCALATION_BADGE": "Eskalace",
    "CONTACT_TICKET": "Kontaktní tiket",
    "CONTACT_TICKET_CONTENT": "Obsah tiketu",
    "CONTACT_TICKET_CREATE": "Vytvořit kontaktní tiket",
    "CONTACT_TICKET_CREATION_FAILURE": "Nepodařilo se vytvořit kontaktní tiket",
    "CONTACT_TICKET_CREATION_SUCCESS": "Kontaktní tiket byl vytvořen",
    "CONTACT_TICKET_RESOLVER_GROUP": "Skupina řešitelů",
    "CONTACT_TICKET_SEVERITY": "Závažnost tiketu",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID složky SIM",
    "CONTACT_TICKET_SUBJECT": "Předmět tiketu",
    "CONTACT_TIMELINE": "Časová osa kontaktu",
    "CONTACT_TYPE": "Typ kontaktu",
    "CONTACT_WAS_TRANSFERRED": "Tento kontakt byl převeden.",
    "CONTENT": "Obsah",
    "CONTENT_RESTRICTED": "Obsah je omezen.",
    "COPY_AGENT_SELECTED_ISSUE": "Kopírovat zástupcem zvolený problém: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Zkopírovat ID kód kontaktu služby Amazon Connect",
    "COPY_CALL_LEG_ID": "Zkopírovat ID etapy hovoru: {value}",
    "COPY_CONTACT_ID": "Kopírovat ID kontaktu: {value}",
    "COPY_CUSTOMER_ID": "Kopírovat ID zákazníka",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Kopírovat zákazníkem zvolený problém: {value}",
    "COPY_FAILURE": "Kopírování se nezdařilo.",
    "COPY_ID": "ID kopie",
    "COPY_LINK": "Zkopírovat odkaz pro nahrání obrázku",
    "COPY_ORDER_ID": "Zkopírovat ID objednávky: {value}.",
    "CREATE_CASE": "Vytvořit případ",
    "CREATE_FOLLOW_UP": "Vytvořit navazující akci",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Navazující akce byla úspěšně vytvořena, ale nepodařilo se přidat odpovídající poznámku. Zkuste přidat poznámku znovu ručně.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Určete zákazníkův preferovaný způsob kontaktování.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Nelze vytvořit navazující akci s datem a časem v minulosti.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Určete datum a čas lhůty navazující akce.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Časové pásmo",
    "CREATE_FOLLOW_UP_FAILURE": "Nepodařilo se vytvořit navazující akci.",
    "CREATE_FOLLOW_UP_INVALID": "Neplatná navazující akce. Ujistěte se, že žádná data nejsou omezená.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Zadejte navazující akci.",
    "CREATE_FOLLOW_UP_SUCCESS": "Navazující akce byla úspěšně vytvořena.",
    "CREATE_WORK_REQUEST": "Vytvořit pracovní příkaz",
    "CROSS_MARKETPLACE_QUEUES": "Fronty napříč trhem",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Nepodařilo se převést kontakt, protože je již vyřešen.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "Operace se nezdařila, protože kontakt je v neplatném stavu.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Operace se nezdařila, protože daný kontakt nebyl nalezen.",
    "CSALT_ERROR_INTERNAL_ERROR": "Kontakt se nepodařilo uzamknout",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Kontakt se nepodařilo uzamknout kvůli neplatným argumentům",
    "CSALT_ERROR_INVALID_INPUT": "Operace se nezdařila z důvodu neplatných vstupů.",
    "CSALT_ERROR_INVALID_QUEUE": "Nepodařilo se převést kontakt, protože vybraná fronta je neplatná.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Operace se nezdařila, protože je daný kontakt uzamčen na jiného zástupce.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Kontakt se nepodařilo uzamknout kvůli chybě závislosti",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Nepodařilo se převést kontakt, protože fronta nebyla nalezena v backendové službě.",
    "CSALT_ERROR_UNKNOWN": "Operace se nezdařila kvůli neočekávané chybě.",
    "CURRENT_CONTACT": "Aktuální kontakt",
    "CUSTOMER": "Zákazník",
    "CUSTOMER_FOLLOWUPS": "Navazující akce zákazníka",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Nepodařilo se načíst navazující akce zákazníka",
    "CUSTOMER_ID_COPIED": "ID zákazníka zkopírováno",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Obsah zákazníka je omezený. (Žádné kontakty nejsou zablokovány.)",
    "CUSTOMER_SELECTED_ISSUE": "Problém zvolený zákazníkem",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Zákazníkem zvolený problém byl zkopírován.",
    "CUSTOMER_TIMEZONE": "Časová razítka zobrazena v časovém pásmu zákazníka",
    "DATE": "Datum",
    "DATE_PICKER_NEXT_MONTH": "Příští měsíc",
    "DATE_PICKER_PREVIOUS_MONTH": "Předchozí měsíc",
    "DATE_PICKER_TODAY": "Dnes",
    "DECODING_AUDIO": "Dekódování zvuku",
    "DELETE_ATTACHMENTS": "Odstranění příloh kontaktu",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Odstranit přílohy",
    "DELIVERY_STATUS": "Stav doručení",
    "DISABLE_CONTENT_FILTER": "Zakázat filtrování obsahu",
    "DOWNLOAD": "Stáhnout",
    "DURATION": "Trvání",
    "EMAIL": "E-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "E-mailová adresa příjemce(ů) není povolena.",
    "EMAIL_BODY": "Tělo e-mailu",
    "EMAIL_CONTACT": "E-mailový kontakt",
    "EMAIL_HEADER": "Záhlaví",
    "EMAIL_QUEUE_TITLE": "Fronta e-mailů",
    "EMAIL_RESTRICTED": "Obsah e-mailu je omezený.",
    "EMAIL_SENDING_FAILURE": "Odeslání odchozího e-mailu se nezdařilo.",
    "EMAIL_SUBJECT": "Předmět e-mailu",
    "EMAIL_TITLE": "E-mailová komunikace se zákaznickou službou Amazon",
    "END": "Konec",
    "END_DATE": "Koncové datum",
    "ERROR": "Chyba",
    "ERROR_FAILED_TO_LOAD_DATA": "Data se nepodařilo načíst.",
    "ESCALATION_CONTACT_HANDLING": "Zpracování kontaktu při eskalaci",
    "ESCALATION_TAB_LEAD_LOGIN": "Hlavní přihlašovací jméno: {agentLogin}",
    "EXPANDED": "Rozbaleno",
    "FAILED_TO_CREATE_WORK_REQUEST": "Nepodařilo se vytvořit pracovní příkaz",
    "FAILED_TO_FETCH_SYSKA": "Nepodařilo se načíst upozornění SYSKA.",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Nepodařilo se znovu přiřadit kontakt zástupci.",
    "FETCHED_NUMBER_CONTACTS": "Počet získaných kontaktů: {number}.",
    "FILTER": "Filtr",
    "FINDER": "Vyhledávač",
    "FOLLOW_UPS_COLLAPSED": "Navazující akce byly sbaleny.",
    "FOLLOW_UPS_EXPANDED": "Navazující akce byly rozbaleny.",
    "FOLLOW_UP_ALERT": "má navazující akci se lhůtou do {dueDate}, kterou vytvořil {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Způsob kontaktu",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Způsob kontaktu je omezený.",
    "FOLLOW_UP_DUE": "Termín dokončení navazující akce",
    "FOLLOW_UP_DUE_DATE": "Datum dokončení",
    "FOLLOW_UP_NOTE": "Poznámka",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Žádný způsob kontaktu",
    "FOLLOW_UP_NO_NOTE": "Poznámka je prázdná",
    "FOLLOW_UP_NO_TICKET": "Žádný lístek",
    "FOLLOW_UP_OWNER": "Vlastník",
    "FOLLOW_UP_STATUS": "Stav",
    "FOLLOW_UP_STATUS_OPEN": "Otevřít",
    "FOLLOW_UP_STATUS_RESOLVED": "Vyřešeno",
    "FOLLOW_UP_TICKET": "Lístek",
    "FOLLOW_UP_TITLE": "Navazující akce",
    "FROM": "Od",
    "GENERATE_LINK": "Vygenerovat odkaz",
    "HAS_OVERRIDE_TRY_AGAIN": "Je nastaveno potlačení filtru. Zkuste to znovu.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "Odkaz na obrázkovou přílohu sdílený se zákazníkem",
    "INPUT_CHARACTERS_REMAINING": "Maximálně {char_max} znaků ({char_remain} zbývá)",
    "INTERNAL_ERROR": "Interní chyba",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Byla zjištěna interní chyba. Pokud chyba přetrvává, podejte u nás tiket s popisem problému.",
    "INVALID_CONTACT": "Neplatný kontakt.",
    "INVALID_DATA_MATCH": "Systém neplatně klasifikoval data.",
    "INVALID_TIMESTAMP": "Neplatné časové razítko.",
    "IN_MARKETPLACE_QUEUES": "Ve frontách na trhu",
    "ISSUE": "Problém",
    "ISSUE_CODE": "Kód problému",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Již posouzeno a vyřešeno v předchozím kontaktu",
    "ISSUE_CODE_DESCRIPTION": "Vyberte důvod pro označení vybraných kontaktů jako duplicitních",
    "ISSUE_CODE_EXACTLY_SAME": "Identický s předchozím kontaktem",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Zpracováno a vyřešeno jiným pracovníkem chatových zákaznických služeb",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Zpracováno a vyřešeno jiným pracovníkem e-mailových zákaznických služeb",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Zpracováno a vyřešeno jiným pracovníkem telefonických zákaznických služeb",
    "ITEMS_CONTACTED_ABOUT": "Položky, které jsou předmětem kontaktování",
    "I_UNDERSTAND": "Rozumím",
    "LINK_COPIED": "Odkaz byl zkopírován",
    "LOADING": "Probíhá načítání dat.",
    "LOADING_PREVIEW_TEXT": "Načítání náhledu",
    "LOADING_RECORDING": "Nahrávání záznamu",
    "LOAD_MORE": "Načíst další",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Načíst další poznámky.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Načtěte více kontaktů pro tohoto zákazníka.",
    "LOCKED": "Uzamčeno",
    "LOCKED_BY": "Uzamkl/a:",
    "LOCK_CONTACT": "Uzamknout",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Kontakt se nepodařilo uzamknout, uživatel není autorizován",
    "LOCK_CONTACT_SUCCESSFUL": "Kontakt uzamčen pro",
    "MISSING_ORIGINAL_CONTACT_ID": "Zadejte platné ID původního kontaktu",
    "MODAL_NO_BUTTON_TEXT": "Ne",
    "MODAL_YES_BUTTON_TEXT": "Ano",
    "NEXT": "Následující",
    "NORMAL": "Normální",
    "NO_AGENT_SELECTED_ISSUE": "Žádný zástupcem zvolený problém",
    "NO_ANNOTATED_BY": "Poznámka přidána neznámým uživatelem.",
    "NO_ANNOTATIONS": "Nejsou k dispozici žádné poznámky.",
    "NO_ATTACHMENTS": "Nejsou žádné přílohy.",
    "NO_CHAT": "Chat je prázdný.",
    "NO_CHILD_WORK_REQUESTS": "Tento kontakt nevytvořil žádné pracovní požadavky.",
    "NO_CONTACT": "K dispozici není žádný kontakt",
    "NO_CONTACTS_SELECTED": "Vyberte kontakty, které chcete označit jako spam",
    "NO_CONTENT": "Žádný obsah",
    "NO_CUSTOMER_SELECTED_ISSUE": "Žádný zákazníkem zvolený problém",
    "NO_DELIVERY_STATUS": "Žádný stav doručení",
    "NO_EMAIL_BODY": "E-mail je prázdný.",
    "NO_EMAIL_HEADER": "Žádné záhlaví e-mailu",
    "NO_EMAIL_QUEUE": "K tomuto kontaktu nejsou přidruženy žádné fronty e-mailů.",
    "NO_ITEMS_CONTACTED_ABOUT": "Žádné položky, které jsou předmětem kontaktování",
    "NO_RESULTS": "Nebyly nalezeny žádné výsledky.",
    "NO_SCREEN_POP": "Žádné informace ve vyskakovacím okně",
    "NO_SENT_FROM": "Žádná adresa odesílatele.",
    "NO_SENT_TO": "Žádná adresa příjemce",
    "NO_SUBJECT": "Předmět je prázdný.",
    "NO_SYSKA_ALERTS": "Žádná upozornění SYSKA",
    "NO_USED_BLURBS": "Pro tento kontakt nebyly použity žádné propagační texty.",
    "NO_VIEW": "Žádný kontakt ani zákazník k zobrazení",
    "NO_WORK_CATEGORY": "K tomuto kontaktu není přiřazena žádná pracovní kategorie.",
    "NUMBER_OF_CONTACTS": "Načtené kontakty",
    "OLDEST_LOADED_CONTACT": "Nejstarší načtený kontakt",
    "OPEN": "Otevřeno",
    "ORDER_ID_COPIED": "ID objednávky bylo zkopírováno.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Zadejte ID původního kontaktu",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Textové pole pro zadání ID původního kontaktu",
    "OTHER": "Jiný",
    "OUTBOUND_EMAIL_BCC": "a ve slepé kopii –",
    "OUTBOUND_EMAIL_CC": "zatímco v kopii –",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Obsah oznámení sbalen.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Obsah oznámení rozbalen.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Maximální počet dosažených výsledků. Snižte prosím časový rozsah a zkuste to znovu.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Aktuální proces vyžaduje obsah představující osobní údaje.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Neplatná shoda jako telefonní číslo, e-mailová adresa nebo jiný osobní údaj.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Neplatná shoda jako kreditní karta nebo IBAN.",
    "OVERRIDE_REASON_PROMPT": "Anotace by neměly obsahovat osobní údaje, jako jsou telefonní čísla a e-mailové adresy. Náš automatický systém se na to snaží dohlížet, někdy však není schopen rozlišit osobní údaje od jiných ID. Uveďte důvod, proč je potřeba výjimka.",
    "PAPYRUS_SESSION_EXPLANATION": "Uveďte typ své relace Papyrus, abychom mohli uplatnit správná bezpečnostní omezení a optimalizovat UX pro vaše potřeby.",
    "PAPYRUS_SESSION_TYPE": "Typ relace Papyrus",
    "PAUSE": "Pozastavit",
    "PHONE_CONTACT": "Telefonní kontakt",
    "PLAY": "Přehrát",
    "PLAYBACK_SPEED": "Rychlost přehrávání",
    "POSTAL_MAIL_TITLE": "Pošta se zákaznickým servisem Amazon",
    "PREPARING_RECORDING": "Připravuje se záznam",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Nepodařilo se vygenerovat náhled kontaktu",
    "PREVIOUS": "Předchozí",
    "PRE_TRANSLATED_REPLY_EMAIL": "Předem přeložený e-mail s odpovědí",
    "PRE_TRANSLATION_REPLY_TEXT": "Pracovník zákaznických služeb zadal odpověď před překladem od Rosetty",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Akce",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Zpracovat jako duplicitní",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Označit jako spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Řešit vybrané kontakty jako duplicitní",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Opravdu chcete označit vybrané kontakty jako spam?",
    "PROCESS_DUPLICATE_FAILURE": "Vyřešení vybraných kontaktů jako duplicitních se nezdařilo",
    "PROCESS_DUPLICATE_SUCCESS": "Úspěšně vyřešeny vybrané kontakty jako duplicitní",
    "PROCESS_SPAM_FAILURE": "Nepodařilo se přesunout vybrané kontakty do spamu",
    "PROCESS_SPAM_SUCCESS": "Vybrané kontakty úspěšně přesunuty do spamu",
    "PROVIDE_ADDITIONAL_INFORMATION": "Uveďte další informace týkající se tohoto kontaktu",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Byly odstraněny citlivé informace z kontaktu:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Přidat slovo do seznamu k odstranění",
    "PURGE_BUTTON_TEXT": "Odstranit",
    "PURGE_CONFIRMATION_CHECK": "Na základě kontroly výše uvedených polí potvrzuji, že text skutečně obsahuje citlivé informace.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Potvrzuji, že příloha opravdu obsahuje citlivé údaje o zákazníkovi.",
    "PURGE_CONFIRMATION_UNCHECKED": "Zaškrtněte políčko.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Nepodařilo se odstranit informace z kontaktu",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Informace z kontaktu byly úspěšně odstraněny",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} není platným důvodem k odstranění",
    "PURGE_REASON_FINANCIAL_INFO": "Finanční informace",
    "PURGE_REASON_OTHER": "Další citlivé informace",
    "PURGE_REASON_PII": "Osobně identifikovatelné informace",
    "PURGE_REASON_PLACEHOLDER": "Zadejte důvod k odstranění",
    "PURGE_REASON_TEXT": "Důvod k odstranění",
    "PURGE_REMOVE_LABEL": "Odebrat",
    "PURGE_SELECTOR_PLACEHOLDER": "Vyberte důvod pro odstranění citlivých informací",
    "PURGE_SENSITIVE_INFO": "Odstranit citlivé informace",
    "PURGE_WORD_NOT_FOUND": "V textu kontaktu nebylo slovo nenalezeno",
    "PURPOSE_AUDITING": "Audit pro kontrolu kvality",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Pracovní postup administrativního zázemí",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Toto je relace pro získání kontaktních údajů pro pracovní postupy administrativního zázemí. Jde například o přílohy, které mají být součástí lístků nebo SIM.",
    "PURPOSE_COACHING": "Kontrola kontaktu koučování",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Zjišťování shody",
    "PURPOSE_CURATION": "Aktualizace kontaktů",
    "PURPOSE_CURATION_DESCRIPTION": "Toto je relace pro aktualizaci kontaktů C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Toto je relace pro audit kontaktů a hodnocení jejich kvality.",
    "PURPOSE_DESCRIPTION_COACHING": "Toto je relace pro kontrolu kontaktů těch zástupců, kterým děláte kouče pro Portál výkonu.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Tato relace je určená k šetření v souvislosti se sledováním podezřelých aktivit.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Toto je relace pro zpracování kontaktů při eskalaci. Umožňuje přístup ke kontaktním údajům libovolného zákazníka.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Toto je relace pro zpracování živých kontaktů.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Účelem je odstranit citlivé informace z kontaktu",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Toto je relace pro kontrolu přesnosti modelu strojového učení.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Toto je relace pro kontrolu vašich vlastních dřívějších kontaktů.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Toto je výchozí relace pro obecný účel.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Zpracování kontaktu při eskalaci",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Odstranit citlivé informace",
    "REASON_FOR_PURGE": "Důvod k odstranění",
    "REASON_FOR_PURGE_DESCRIPTION": "Důvod odstranění příloh",
    "REASON_FOR_PURGE_MISSING": "Zadejte prosím důvod pro odstranění příloh.",
    "REASSIGN_TO_AGENT": "Přeřadit agentovi",
    "RECORDING": "Záznam",
    "RELOADING_CONTENT": "Opětovné načítání obsahu.",
    "RELOAD_LIST": "Znovu načíst seznam",
    "REPLY_BY": "Odpovědět do",
    "REPLY_BY_EMAIL": "Odpovědět e-mailem",
    "REPLY_BY_PHONE": "Odpovědět telefonicky",
    "REPLY_RESTRICTED": "Obsah odpovědi je omezený.",
    "REQUEST": "Požádat",
    "REQUEST_ACCESS_INSTRUCTIONS": "Uveďte důvod, proč vyžadujete přístup k obsahu s omezeným přístupem. (Tyto žádosti jsou monitorovány a zneužití může mít vážné následky.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Vyžádat přístup k filtrovanému obsahu",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Žádost o přístup k obsahu s omezeným přístupem",
    "REQUEST_NOT_GRANTED": "Žádosti nebylo vyhověno.",
    "REQUEST_OVERRIDE": "Požádat o výjimku",
    "RESEND": "Znovu odeslat",
    "RESEND_FAILED": "Nepodařilo se znovu odeslat e-mail.",
    "RESEND_SUCCESS": "E-mail byl úspěšně odeslán znovu.",
    "RESEND_WAIT_WARNING": "Než bude možné provést další odeslání, musíte počkat {wait_mins} minut.",
    "RESEND_WARNING": "Opravdu chcete znovu odeslat e-mail zákazníkovi?",
    "RESET": "Resetovat",
    "RESOLVED": "Vyřešeno",
    "RESOLVED_BY": "Vyřešil/a:",
    "RESTRICTED_DATA_MATCH": "{match} odpovídající typ {type}",
    "RESTRICTED_INFORMATION": "OMEZENĚ PŘÍSTUPNÉ INFORMACE",
    "RETRY": "Zkusit znovu",
    "ROLE": "Role",
    "SCREEN_POP_DESCRIPTION_TITLE": "Popis",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Ne",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Ano",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Zobrazeno zástupci",
    "SCREEN_POP_TITLE": "Informace ve vyskakovacím okně",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Zástupci se zobrazilo vyskakovací okno ověřování a bylo použito.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Zástupci se zobrazilo vyskakovací okno ověřování.",
    "SCREEN_POP_TYPE_CALLER_ID": "Zástupci se zobrazilo vyskakovací okno s ID volajícího a bylo použito.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Zástupci se zobrazilo vyskakovací okno s ID volajícího.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Zobrazilo se vyskakovací okno s neověřeným uživatelem a bylo použito.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Zástupci se zobrazilo vyskakovací okno s neověřeným uživatelem.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Zákazník není znám; nezobrazilo se žádné vyskakovací okno.",
    "SCREEN_POP_TYPE_OTHER": "Jiné",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Ne",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Ano",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Použito zástupcem",
    "SDS_TOOLS": "Nástroje podpory odeslání a dodání",
    "SEARCH": "Hledat",
    "SELECTED_ROLE": "Zvolená role",
    "SELECTED_VIEW": "Zvolené zobrazení",
    "SELECT_CHANNELS": "Vybrat kanály",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Vybrat kód problému pro označení vybraných kontaktů jako duplicitních",
    "SELECT_TRANSFER_DESTINATION": "Vybrat cíl převodu",
    "SELECT_WORK_REQUEST_QUEUE": "Vybrat frontu pracovních příkazů",
    "SENDER_EMAIL_ADDRESS": "Odesílatel",
    "SENDING_EMAIL": "Odesílání e-mailu",
    "SEND_EMAIL": "Odeslat e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Opravdu chcete zákazníkovi odeslat odkaz pro nahrání obrázku?",
    "SEND_OUTBOUND_EMAIL": "Odeslat odchozí e-mail",
    "SEND_OUTBOUND_EMAIL_WARNING": "Jste si jisti, že chcete odeslat odchozí e-mail na adresu",
    "SENT_FROM": "Odesílatel",
    "SENT_OUTBOUND_EMAIL": "Byl odeslán odchozí e-mail. Byl vytvořen nový kontakt",
    "SENT_TO": "Adresa příjemce",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portál odesílatele",
    "SKILL": "Dovednost",
    "SKIP_SILENCES": "Přeskočit ticho",
    "SMS": "SMS",
    "SMS_LINK": "Odkaz v SMS",
    "SOURCE_TEXT": "Zdrojový text",
    "SP_BOTTOM": "Zdola",
    "SP_POSITION": "Pozice rozděleného panelu",
    "SP_PREFERENCES": "Předvolby rozděleného panelu",
    "SP_RESIZE": "Změna velikosti rozděleného panelu",
    "SP_SIDE": "Z boku",
    "START": "Spustit",
    "STATE_TRANSITIONS": "Přechody mezi stavy",
    "STATUS": "Stav",
    "SUBJECT": "Předmět",
    "SYSKA_ALERTS": "Upozornění SYSKA",
    "TEXT_TO_PURGE": "Text, který se má odstranit",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Při vytváření lístku není povoleno použití údajů zákazníků PII, jako jsou telefonní čísla a e-mailové adresy. Náš automatický systém se na to snaží dohlížet, někdy však není schopen rozlišit osobní údaje od jiných ID. Uveďte důvod, proč je potřeba výjimka.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Vypršel časový limit: nepodařilo se nahrát záznam",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Použijte 24hodinový formát.",
    "TO": "Do",
    "TO_EMAIL_ADDRESS": "Pro",
    "TRANSFER": "Převod",
    "TRANSFERRED_CHAT_TITLE": "Převedený chat se zákaznickou službou Amazon",
    "TRANSFERRING": "Probíhá převod...",
    "TRANSFER_CONTACT": "Převést kontakt",
    "TRANSFER_CONTACT_COMMENTS": "Nepovinná poznámka",
    "TRANSFER_FAILED": "Převod se nezdařil.",
    "TRANSFER_FOLLOW_UP": "Převést navazující akci",
    "TRANSFER_FOLLOW_UP_FAILED": "Nepodařilo se převést navazující akci.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Nepodařilo se převést navazující akci z důvodu neplatného přihlášení nového vlastníka.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Nemáte oprávnění k převodu této navazující akce.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Zadejte přihlašovací jméno nového vlastníka. Příklad: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Převést výše uvedenou navazující akci na:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Navazující akce byla úspěšně převedena.",
    "TRANSLATED_TEXT": "Přeložený text",
    "TRANSLATION": "Překlad",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} není platný název fronty.",
    "TYPE_AGENT_LOGIN": "Zadejte přihlašovací údaje agenta",
    "TYPE_EMAIL_ADDRESS": "Zadejte e-mailovou adresu",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Zadejte více e-mailových adres oddělených čárkou",
    "TYPE_QUEUE_NAME": "Název fronty typu",
    "UNKNOWN_CONTACT_TYPE": "Neznámý typ kontaktu",
    "UNLOCKED": "Odemknuto",
    "UNRESOLVED": "Nevyřešeno",
    "URGENT_SYSKA_ALERTS": "Naléhavá upozornění SYSKA",
    "USE": "Použít",
    "USER_TIMEZONE": "Časová razítka zobrazena ve Vašem místním časovém pásmu",
    "USE_CONTACT_FINDER": "Použít vyhledávač kontaktů",
    "VIEW": "Zobrazit",
    "WARNING": "Varování",
    "WELCOME_MESSAGE": "Vítejte na webu Papyrus Static.",
    "WHAT_IS_PAPYRUS": "Co je Papyrus?",
    "WITH_THE_ANNOTATION": "s poznámkou",
    "WORK_CATEGORY_TITLE": "Pracovní kategorie",
    "WORK_REQUEST": "Pracovní příkaz",
    "WORK_REQUEST_CONTENT": "Obsah pracovního příkazu",
    "WORK_REQUEST_CREATED": "Pracovní příkaz vytvořena",
    "WORK_REQUEST_FOR_CONTACT": "Pracovní příkaz pro kontakt",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Při vytváření pracovního požadavku není povoleno použití údajů zákazníků PII, jako jsou telefonní čísla a e-mailové adresy. Náš automatický systém se na to snaží dohlížet, někdy však není schopen rozlišit osobní údaje od jiných ID. Uveďte důvod, proč je potřeba výjimka.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Chystáte se převést do fronty"
}