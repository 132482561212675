export const pl_PL = {
    "contact_event_GC_added": "Do konta dodano kartę podarunkową #{gcId}.",
    "contact_event_GC_added_batched": "Do konta dodano kartę podarunkową ({count}).",
    "contact_event_GC_added_purchaser": "Do konta nabywcy dodano kartę podarunkową #{gcId}.",
    "contact_event_GC_added_purchaser_batched": "Do konta nabywcy dodano kartę podarunkową ({count}).",
    "contact_event_GC_added_recipient": "Do konta odbiorcy dodano kartę podarunkową #{gcId}.",
    "contact_event_GC_added_recipient_batched": "Do konta odbiorcy dodano kartę podarunkową ({count}).",
    "contact_event_GC_added_scratchedCode": "Karta podarunkowa #{gcId} dodana do konta za pomocą narzędzia zdrapanego kodu realizacji.",
    "contact_event_GC_added_scratchedCode_batched": "Karta podarunkowa dodana do konta za pomocą narzędzia zdrapanego kodu realizacji ({count}).",
    "contact_event_GC_cancelled": "Karta podarunkowa #{gcId} została anulowana.",
    "contact_event_GC_cancelled_batched": "Anulowano kartę podarunkową ({count}).",
    "contact_event_GC_changed_expiry": "Zmieniono datę ważności karty podarunkowej #{gcId}.",
    "contact_event_GC_changed_expiry_batched": "Zmieniono datę ważności karty podarunkowej ({count}).",
    "contact_event_GC_locked": "Karta podarunkowa #{gcId} jest zablokowana.",
    "contact_event_GC_locked_batched": "Zablokowano kartę podarunkową ({count}).",
    "contact_event_GC_removed": "Z konta usunięto kartę podarunkową #{gcId}.",
    "contact_event_GC_removed_batched": "Z konta usunięto kartę podarunkową ({count}).",
    "contact_event_GC_resent_email": "Karta podarunkowa #{gcId} została ponownie wysłana na adres e-mail {deliveryEmail}.",
    "contact_event_GC_resent_email_batched": "Karta podarunkowa została ponownie wysłana na adres e-mail ({count}).",
    "contact_event_GC_resent_post": "Karta podarunkowa #{gcId} została ponownie wysłana tradycyjną pocztą.",
    "contact_event_GC_resent_post_batched": "Karta podarunkowa została ponownie wysłana pocztą tradycyjną ({count}).",
    "contact_event_GC_temporary_locked": "Karta podarunkowa #{gcId} jest tymczasowo zablokowana.",
    "contact_event_GC_temporary_locked_batched": "Tymczasowo zablokowano kartę podarunkową ({count}).",
    "contact_event_GC_transferred": "Karta podarunkowa {gcId} została przekazana przez klienta {customerID} klientowi {toAccountCustomerID}.",
    "contact_event_GC_transferred_batched": "Przeniesiono kartę podarunkową ({count}).",
    "contact_event_GC_unlocked": "Karta podarunkowa #{gcId} jest odblokowana.",
    "contact_event_GC_unlocked_batched": "Odblokowano kartę podarunkową ({count}).",
    "contact_event_PV_added": "Do konta dodano PV #{pvId}.",
    "contact_event_PV_locked": "PV #{pvId} jest zablokowane.",
    "contact_event_PV_temporary_locked": "PV #{pvId} jest tymczasowo zablokowane.",
    "contact_event_PV_unlocked": "PV #{pvId} jest odblokowane.",
    "contact_event_a_to_z_guarantee_cancelled_batched": "Anulowano roszczenia w ramach gwarancji od A do Z w związku z zamówieniami ({count}).",
    "contact_event_a_to_z_guarantee_requested": "Przesłano roszczenie w ramach gwarancji od A do Z w związku z zamówieniem {order_id}.",
    "contact_event_a_to_z_guarantee_requested_batched": "Przesłano roszczenia w ramach gwarancji od A do Z w związku z zamówieniami ({count}).",
    "contact_event_a_to_z_guarantee_requested_gwp": "Roszczenie w ramach gwarancji od A do Z złożone względem zamówienia {order_id}.",
    "contact_event_access_code_added": "Dodano kod dostępu",
    "contact_event_access_code_added_batched": "Dodano kod dostępu ({count})",
    "contact_event_account_closed": "Konto zamknięte.",
    "contact_event_account_closed_batched": "Konta zamknięte ({count}).",
    "contact_event_account_reopened": "Konto otwarte ponownie",
    "contact_event_account_reopened_batched": "Konta otwarte ponownie ({count})",
    "contact_event_account_temporarily_disabled": "Konto tymczasowo wyłączone.",
    "contact_event_account_temporarily_disabled_batched": "Konta tymczasowo wyłączone ({count})",
    "contact_event_account_whitelisted": "Umieszczono konto na białej liście.",
    "contact_event_account_whitelisted_batched": "Umieszczono konto na białej liście ({count}).",
    "contact_event_address_changed": "Zaktualizowano adres ({address_type}) w zamówieniu {order_id}.",
    "contact_event_address_changed_batched": "Zaktualizowano adresy w zamówieniach ({count}).",
    "contact_event_address_deactivated": "Wyłączony adres.",
    "contact_event_address_deactivated_batched": "Wyłączony adres ({count}).",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Subskrypcja Amazon Video odnowiona automatycznie",
    "contact_event_amazon_video_subscription_cancelled": "Subskrypcja Amazon Video anulowana",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "Przesłany formularz: Formularz eskalacji DSL\nIdentyfikator sesji przypadku użycia: {usecaseSessionID}\nStacja dostawy: {deliveryStationField}\nTyp żądania: {requestTypeField}\nIdentyfikator osoby upoważnionej do kontaktu: {contactIdField}\nIdentyfikator zamówienia: {orderIdField}\nAdres e-mail klienta: {customerEmailField}\nNumer telefonu klienta: {customerPhoneNumberField}\nTBA: {tbasField}\nKod dostępu (w stosownych przypadkach): {accessCodeField}\nPodsumowanie problemu: {issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "Przesłane formularze eskalacji DSL: ({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "Przesłany formularz: Formularz opinii DSP DA\nIdentyfikator sesji przypadku użycia: {usecaseSessionID}\nKod stacji dostawy: {deliveryStationField}\nIdentyfikator osoby upoważnionej do kontaktu: {contactIdField}\nIdentyfikator konta klienta: {customerIdField}\nIdentyfikator transportera DA: {driverIdField}\nData zdarzenia (RRRR-MM-DD): {dateOfOccurrenceField}\nGodzina zdarzenia (GG:MM): {timeofOccurrenceField}\nTBA: {relatedTrackingIdsField}\nŹródło opinii: {sourceOfFeedbackField}\nSzczegóły opinii: {issueField}\nDodatkowe informacje: {additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "Przesłane formularze opinii DSP DA: ({count})",
    "contact_event_andon_cord_pulled": "Uruchomiono procedurę Andon Cord w związku z produktem {asin}.",
    "contact_event_andon_cord_pulled_batched": "Uruchomiono procedurę Andon Cord w związku z produktami ({count}).",
    "contact_event_apply_adrive_music_trial": "zastosowano Cloud Player Premium dla klienta {customer_id}. {number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "Przeniesiono konto do {marketplaceName}.",
    "contact_event_audible_account_migrated_batched": "Przeniesiono konta Audible ({count}).",
    "contact_event_audible_cash_and_coupon_refund": "Zwrócono koszty za {title} na kwotę {cashAmount}. Dodatkowo za pomocą tej transakcji przywrócono kupon {couponAmount} z powrotem na rachunek.{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "Zwrócono koszty za {title} na kwotę {cashAmount}. Dodatkowo za pomocą tej transakcji przywrócono kupon {couponAmount} z powrotem na rachunek.{counter} ({count})",
    "contact_event_audible_cash_only_refund": "Zwrócono koszty za {title} na kwotę {cashAmount}.{counter}",
    "contact_event_audible_cash_only_refund_batched": "Zwrócono koszty za {title} na kwotę {cashAmount}.{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "Zwrócono koszty za {title} i przywrócono kupon {couponAmount} z powrotem na rachunek.{counter}",
    "contact_event_audible_coupon_only_refund_batched": "Zwrócono koszty za {title} i przywrócono kupon {couponAmount} z powrotem na rachunek.{counter} ({count})",
    "contact_event_audible_credit_deleted": "Usunięto kredyt ({amount}) z konta.",
    "contact_event_audible_credit_issued": "Dodano środki promocyjne ({numCredit}) do konta.",
    "contact_event_audible_credit_issued_batched": "Dodano środki promocyjne do konta ({count}).",
    "contact_event_audible_credit_refund": "Zwrócono koszty za {title} i przywrócono kredyt(y) {creditAmount} z powrotem na rachunek.{counter}",
    "contact_event_audible_credit_refund_batched": "Zwrócono koszty za {title} i przywrócono kredyt(y) {creditAmount} z powrotem na rachunek.{counter} ({count})",
    "contact_event_audible_credit_restored": "Przywrócono kredyt ({amount}) na konto.",
    "contact_event_audible_credit_restored_batched": "Przywrócono kredyt ({amount}) na konto. ({count})",
    "contact_event_audible_extra_credit_purchased": "Zakupiono 3 dodatkowe środki (kredyty).",
    "contact_event_audible_extra_credit_purchased_batched": "Zakupiono 3 dodatkowe środki (kredyty).({count})",
    "contact_event_audible_hiatus_cancelled": "Okres przerwy został wcześniej zakończony i członkostwo zostało wznowione.",
    "contact_event_audible_hiatus_cancelled_batched": "Okres przerwy został wcześniej zakończony i członkostwo zostało wznowione.({count})",
    "contact_event_audible_hiatus_started": "Członkostwo przeszło w przerwę na okres {duration} miesięcy.",
    "contact_event_audible_hiatus_started_batched": "Członkostwo przeszło w przerwę na okres {duration} miesięcy.({count})",
    "contact_event_audible_membership_enrollment": "Zarejestrowano w \nprogramie członkowskim {membershipPlan}.",
    "contact_event_audible_membership_enrollment_batched": "Zarejestrowano w planach członkostwa w serwisie Audible ({count}).",
    "contact_event_audible_membership_plan_refund": "Dokonano zwrotu opłaty członkowskiej w wysokości {cashAmount}.{counter}",
    "contact_event_audible_membership_plan_refund_batched": "Dokonano zwrotu opłaty członkowskiej w wysokości {cashAmount}.{counter} ({count})",
    "contact_event_audible_membership_switched": "Zmieniono program członkowski z {oldMembershipPlan} na {newMembershipPlan}.",
    "contact_event_audible_membership_switched_batched": "Zmieniono plany członkostwa w serwisie Audible ({count}).",
    "contact_event_audible_romance_package_enrollment": "Zarejestrowano w programie {packageName}.",
    "contact_event_audible_romance_package_enrollment_batched": "Zarejestrowano w programie {packageName}.({count})",
    "contact_event_audible_wizard_invoked": "Uzyskano dostęp do {wizardName}.",
    "contact_event_audible_wizard_invoked_batched": "Uzyskano dostęp do {wizardName}. ({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "Plan {membership} został pomyślnie anulowany.",
    "contact_event_blacklist_kindle": "Urządzenie na czarnej liście {serial_number}.",
    "contact_event_blacklist_kindle_batched": "Umieszczono czytniki Kindle na czarnej liście ({count}).",
    "contact_event_bound_warranty": "Powiązano gwarancję o identyfikatorze {warranty_id} z urządzeniem {dsn}.",
    "contact_event_bound_warranty_batched": "Powiązano gwarancje z urządzeniami ({count}).",
    "contact_event_call_was_flagged": "Ten kontakt został oznakowany jako {callFlagSubject}: {callFlagDescription}",
    "contact_event_call_was_flagged_batch": "Ten kontakt został oznakowany jako {callFlagSubject}: {callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "Plan {membership} został pomyślnie anulowany.",
    "contact_event_cancel_audible_membership_batched": "Plan {membership} został pomyślnie anulowany.({count})",
    "contact_event_cancel_audible_romance_package_batched": "Plan {membership} został pomyślnie anulowany.({count})",
    "contact_event_cancel_from_prime_cancel_exception": "Anulowano członkostwo w programie Prime z powodu wyjątku związanego z anulowaniem członkostwa w programie Prime. Powód: {cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Wyjątek związany z anulowaniem członkostwa w programie Prime ({count}).",
    "contact_event_cancel_future_cancellation_audible_membership": "Wcześniej ustawiona data anulowania w przyszłości została anulowana.",
    "contact_event_cancelled_sns_reactivated": "{reactivation_date}(Month) włączono ponownie subskrypcję numeru ASIN {asin}.",
    "contact_event_cancelled_sns_reactivated_batched": "Włączono ponownie anulowane subskrypcje programu Subskrybuj i oszczędzaj ({count}).",
    "contact_event_captcha_bypass_key_generated": "Wygenerowano klucz dla ominięcia Captcha.",
    "contact_event_captcha_bypass_key_generated_batched": "Wygenerowano klucz dla ominięcia Captcha ({count}).",
    "contact_event_change_sns_quantity": "Zaktualizowano liczbę sztuk numeru ASIN {asin} w subskrypcji z {current_quantity} na {new_quantity}.",
    "contact_event_change_sns_quantity_batched": "Zaktualizowano liczby sztuk produktów w programie Subskrybuj i oszczędzaj ({count}).",
    "contact_event_channels_auto_renew_off": "CS wyłączył automatyczne odnawianie subskrypcji PV Channels {subscriptionid}.",
    "contact_event_channels_auto_renew_off_static": "CS wyłączył automatyczne odnawianie przy pomocy Astro.",
    "contact_event_channels_full_refund": "CS anulował subskrypcję PV Channels {subscriptionid} z pełnym zwrotem kosztów.",
    "contact_event_channels_full_refund_static": "CS anulował subskrypcję z pełnym zwrotem kosztów przy pomocy Astro.",
    "contact_event_channels_no_refund": "CS anulował subskrypcję PV Channels {subscriptionid} bez zwrotu kosztów.",
    "contact_event_channels_no_refund_static": "CS anulował subskrypcję bez zwrotu kosztów przy pomocy Astro.",
    "contact_event_channels_workflow_launch": "CS uruchomił procedurę anulowania Astro dla subskrypcji PV Channels {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "CS uruchomił procedurę anulowania Astro.",
    "contact_event_coins_adjust": "Rodzaj korekty: {type} <br />\nPrzyczyna: {reason} <br />\nKwota: {amount} <br />\nUwagi: {comment} <br />",
    "contact_event_coins_adjust_batched": "Saldo monet po korekcie ({count}).",
    "contact_event_concessions_abuse": "Nie wysłano HMD, ponieważ klient jest użytkownikiem typu {risk_status}.",
    "contact_event_concessions_abuse_batched": "Nie wysłano HMD klientowi ({count}).",
    "contact_event_contact_seller": "Skontaktowano się ze sprzedawcą w kwestii zamówienia {order_id}.",
    "contact_event_contact_seller_batched": "Skontaktowano się ze sprzedawcą w kwestii zamówień ({count}).",
    "contact_event_contact_was_initiated_from": "Zainicjowano kontakt z ProductAsin {ProductAsin} ProductDescription {ProductDescription} ProductUrl {ProductUrl}.",
    "contact_event_content_update": "Zaktualizowano zawartość numeru ASIN {asin} w bibliotece Kindle.",
    "contact_event_content_update_batched": "Zaktualizowano zawartość w bibliotece Kindle ({count}).",
    "contact_event_credit_card_disabled": "Wyłączono kartę kredytową.",
    "contact_event_credit_card_disabled_batched": "Wyłączono karty kredytowe ({count}).",
    "contact_event_csc_chat_agent_message_translation_failed": "NieudaneTłumaczenieCzatuRosetta:Pracownik:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "NieudaneTłumaczenieCzatuRosetta:Klient:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "Zastąp żądanie geofence przesłane dla Identyfikatora śledzenia {trackingId} Powód: {overrideReason} Strona: {pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "Zastąp żądanie geofence przesłane dla Identyfikatora śledzenia {trackingId} Powód: {overrideReason} Strona: {pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "Zaktualizowano geokod. Rodzaj geokodu: {geoCodeType}, Identyfikator śledzenia: {trackIngID}, Identyfikator adresu: {addressID}, Powód zastąpienia: {overrideReason}, Identyfikator strony: {pageIdentifier},",
    "contact_event_csc_sds_updated_geocode": "Zaktualizowano geokod. Rodzaj geokodu: {geoCodeType}, Powód zastąpienia: {overrideReason}, Identyfikator śledzenia: {trackIngID}, Identyfikator adresu: {addressID} i Identyfikator strony: {pageIdentifier}",
    "contact_event_customer_authentication_passed": "uwierzytelnianie klienta powiodło się",
    "contact_event_customer_authentication_passed_batched": "uwierzytelnianie klienta powiodło się({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "Zaktualizowano imię i nazwisko klienta.",
    "contact_event_customer_name_updated_batched": "Zaktualizowano imię i nazwisko klienta ({count}).",
    "contact_event_customer_password_changed": "Zmieniono hasło klienta.",
    "contact_event_customer_password_changed_batched": "Zmieniono hasło klienta ({count}).",
    "contact_event_delete_alexa_voice_profile": "Usunięto profil głosowy Alexa dla speakerId : {speakerId} ,  customerID : {customerID}  oraz statusu : {status}",
    "contact_event_delete_data_alexa_comms": "Usunięto profil Alexa Comms dla commsID : {commsId} ,  customerID : {customerID}  oraz statusu : {status}",
    "contact_event_deprovision_alexa_comms": "Cofnięto przypisanie profilu Alexa Comms dla identyfikatora Comms: {commsId}, identyfikatora klienta: {customerID} oraz statusu: {status}",
    "contact_event_deregister_kindle": "Wyrejestrowano czytnik Kindle {serial_number}.",
    "contact_event_deregister_kindle_batched": "Wyrejestrowano czytniki Kindle ({count}).",
    "contact_event_detailed_troubleshooting_wizard": "Uruchomiono kreatora rozwiązywania problemów dla urządzenia o DSN {dsn}: {details}.",
    "contact_event_disable_mayday_failed": "Opcja Mayday dla urządzenia DSN {dsn} nie została dezaktywowana.",
    "contact_event_disable_mayday_success": "Opcja Mayday dla urządzenia DSN {dsn} została zdezaktywowana.",
    "contact_event_dunning_halt": "Wstrzymano proces wypełniania - Więcej szczegółów w \"Historii płatności i transakcji\"",
    "contact_event_dunning_pause": "Zatrzymano proces wypełniania - Więcej szczegółów w \"Historii płatności i transakcji\"",
    "contact_event_dvbypost_extra_dispatch": "{amount} prośba o dodatkową/-e wysyłkę/-i dla tego klienta.",
    "contact_event_dvbypost_extra_envelope": "Uruchomiono dodatkową kopertę dla tego klienta.",
    "contact_event_dvbypost_fsk18_form_request": "FSK18 z angefordert",
    "contact_event_dvbypost_problem_code_change": "Ustawienie dysku {disc_id} do {problem_code}",
    "contact_event_email_address_updated": "Zaktualizowano adres e-mail.",
    "contact_event_email_address_updated_batched": "Zaktualizowano adresy e-mail ({count}).",
    "contact_event_email_preferences_changed": "Zmieniono preferencje wiadomości e-mail.",
    "contact_event_email_unsubscribe": "Anulowano subskrypcję wiadomości e-mail.",
    "contact_event_email_unsubscribe_batched": "Anulowano subskrypcję wiadomości e-mail ({count}).",
    "contact_event_enable_mayday_failed": "Opcja Mayday dla urządzenia DSN {dsn} nie została reaktywowana.",
    "contact_event_enable_mayday_success": "Opcja Mayday dla urządzenia DSN {dsn} została reaktywowana.",
    "contact_event_external_transfer": "Ten kontakt został przekazany zewnętrznie do {transferAddress}",
    "contact_event_external_transfer_batched": "Ten kontakt został przekazany zewnętrznie do {transferAddress} ({count})",
    "contact_event_first_time_user_experience_set": "Skonfiguruj początkowy interfejs użytkownika",
    "contact_event_first_time_user_experience_set_batched": "Skonfiguruj początkowy interfejs użytkownika({count}).",
    "contact_event_form_submitted": "Przesłano formularz {form_name}.",
    "contact_event_form_submitted_batched": "Przesłano formularze ({count}).",
    "contact_event_free_time_create_pin": "Wygenerowano nowy kod PIN FreeTime.",
    "contact_event_free_time_unlimited_cancelled": "Subskrypcja Free Time Unlimited dla klienta: {customerId} została anulowana, DSN: {deviceSerialNumber} przez {agentId} z następującej przyczyny: {reason}.",
    "contact_event_free_time_unlimited_cancelled_batched": "Anulowano subskrypcję Free Time Unlimited ({count}).",
    "contact_event_free_trial_prime_cancel_exception": "Anulowano bezpłatny okres próbny programu Prime z powodu {cancel_reason}.",
    "contact_event_free_trial_prime_cancel_exception_batched": "Anulowano członkostwo w programie Prime ({count}).",
    "contact_event_future_cancel_audible_membership": "Ustawiono anulowanie planu {membership} w przyszłości w dniu {date}.",
    "contact_event_future_cancel_audible_membership_batched": "Ustawiono anulowanie planu {membership} w przyszłości w dniu {date}.({count}).",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} został na stałe usunięty z Twojej biblioteki filmów.",
    "contact_event_header": "Dziennik zdarzeń",
    "contact_event_hmd_suppression": "Nie wysłano HMD, ponieważ: {suppression_message}.",
    "contact_event_idle_chat_disconnect": "Bezczynny czat rozłączony",
    "contact_event_idle_chat_disconnect_batched": "Bezczynny czat rozłączony ({count})",
    "contact_event_item_price_reduced": "Zmieniono cenę produktu w zamówieniu {order_id} z {old_price} na {new_price}.",
    "contact_event_item_price_reduced_batched": "Zmieniono ceny produktów w zamówieniach ({count}).",
    "contact_event_item_quantity_changed": "Zmieniono liczbę produktów w zamówieniu {order_id}.",
    "contact_event_item_quantity_changed_batched": "Zmieniono liczby sztuk produktów w zamówieniach ({count}).",
    "contact_event_kindle_device_replacement": "{reason} — utworzono zamiennik DSN {dsn} z zamówienia {old_order_id}. Nowe zamówienie: {new_order_id}.",
    "contact_event_kindle_device_replacement_batched": "Wymieniono czytniki Kindle ({count}).",
    "contact_event_kindle_email_address_edits": "Zaktualizowano adres e-mail na czytniku Kindle (DSN {dsn}).",
    "contact_event_kindle_email_address_edits_batched": "Zaktualizowano adresy e-mail czytników Kindle ({count}).",
    "contact_event_kindle_email_edits": "Zmieniono adres e-mail do wysyłania plików na urządzenie {dsn} z {original_email} na {new_email}.",
    "contact_event_kindle_name_changed": "Zmieniono nazwę czytnika Kindle (DSN {dsn}) z {original_name} na {new_name}.",
    "contact_event_kindle_name_changed_batched": "Zmieniono nazwy czytników Kindle ({count}).",
    "contact_event_kindle_unlimited_refund": "Nastąpił zwrot kosztu z Kindle Unlimited w kwocie {amount} za transakcję o ID {transaction_id}.",
    "contact_event_last_page_read_deleted": "Usunięto informację o ostatnio przeczytanej stronie numeru ASIN {asin}.",
    "contact_event_last_page_read_deleted_batched": "Usunięto informacje o ostatniej przeczytanej stronie ({count}).",
    "contact_event_live_assist_launched": "Uruchomiono Mayday Screen Sharing dla DSN: {dsn}, kcsID: {kcsId}, odpowiedź klienta: {customerResponse}.",
    "contact_event_live_assist_launched_batched": "Uruchomiono Mayday Screen Sharing dla urządzeń ({count}).",
    "contact_event_lock_dash_order": "Następujące numery ASIN dla urządzenia {dsn} zostały zablokowane: {asins}.",
    "contact_event_music_auto_renew_off": "Dział obsługi klienta wyłączył automatyczne odnawianie subskrypcji muzyki {subscriptionid}.",
    "contact_event_music_auto_renew_off_static": "Dział obsługi klienta wyłączył automatyczne odnawianie subskrypcji muzyki za pośrednictwem Astro.",
    "contact_event_music_full_refund": "Dział obsługi klienta anulował subskrypcję Music {subscriptionid} z pełnym zwrotem pieniędzy",
    "contact_event_music_full_refund_static": "Dział obsługi klienta anulował subskrypcję z pełnym zwrotem kosztów za pomocą Astro.",
    "contact_event_music_no_refund": "Dział obsługi klienta anulował subskrypcję muzyki {subscriptionid} bez zwrotu pieniędzy.",
    "contact_event_music_no_refund_static": "Dział obsługi klienta anulował subskrypcję bez zwrotu kosztów za pomocą Astro.",
    "contact_event_music_unlimited_auto_renew_off": "Dział obsługi klienta wyłączył automatyczne odnawianie subskrypcji Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_unlimited_full_refund": "Dział obsługi klienta anulował subskrypcję Amazon Music Unlimited {subscriptionid} i przeprowadził pełny zwrot kosztów.",
    "contact_event_music_unlimited_no_refund": "Dział obsługi klienta anulował subskrypcję Amazon Music Unlimited {subscriptionid} bez zwrotu kosztów.",
    "contact_event_music_unlimited_partial_refund": "Dział obsługi klienta anulował subskrypcję Amazon Music Unlimited {subscriptionid}, przeprowadzając częściowy zwrot kosztów.",
    "contact_event_music_unlimited_workflow_launch": "Dział obsługi klienta uruchomił proces anulowania subskrypcji Amazon Music Unlimited {subscriptionid}.",
    "contact_event_music_workflow_launch": "Dział obsługi klienta uruchomił obieg pracy anulowania Astro dla subskrypcji muzyki {subscriptionid}.",
    "contact_event_music_workflow_launch_static": "Dział obsługi klienta uruchomił przepływ pracy anulowania Astro dla usługi Music Unlimited.",
    "contact_event_non_dsv_refund_created": "Zwrócono {refund_amount} za zamówienie na produkt cyfrowy {order_id}.",
    "contact_event_non_dsv_refund_created_batched": "Zwrócono koszty za zamówienia na produkty cyfrowe ({count}).",
    "contact_event_one_click_address_updated": "Zaktualizowano adres funkcji 1-Click.",
    "contact_event_one_click_address_updated_batched": "Zaktualizowano adres w funkcji 1-Click ({count}).",
    "contact_event_one_click_disabled": "Wyłączono funkcję 1-Click.",
    "contact_event_one_click_disabled_batched": "Wyłączono funkcję 1-Click ({count}).",
    "contact_event_one_click_enabled": "Włączono funkcję 1-Click.",
    "contact_event_one_click_payment_updated": "Uaktualniono płatność za pomocą funkcji 1-Click.",
    "contact_event_one_click_payment_updated_batched": "Uaktualniono płatność za pomocą funkcji 1-Click ({count}).",
    "contact_event_order_cancelled": "Przedmioty anulowane w zamówieniu nr {order_id}",
    "contact_event_order_cancelled_batched": "Anulowano zamówienia ({count}).",
    "contact_event_order_placed": "Złożono zamówienie {order_id}.",
    "contact_event_order_placed_batched": "Złożono zamówienia ({count}).",
    "contact_event_payment_method_added": "Do konta dodano metodę płatności {payment_type}.",
    "contact_event_payment_method_added_batched": "Dodano metody płatności do konta ({count}).",
    "contact_event_payment_method_changed": "Zmieniono metodę płatności dla zamówienia {order_id}.",
    "contact_event_payment_method_changed_batched": "Zmieniono metodę płatności dla zamówień ({count}).",
    "contact_event_payment_plan_changed": "Zmieniono plan płatności dla zamówienia {order_id}.",
    "contact_event_payment_plan_changed_batched": "Zmieniono plan płatności dla zamówień ({count}).",
    "contact_event_pdocs_max_price_edits": "Zmieniono ustawienie maksymalnej opłaty za konwersję dokumentów osobistych na {new_max_charge}.",
    "contact_event_pdocs_max_price_edits_batched": "Zaktualizowano ustawienie maksymalnej opłaty za konwersję dokumentów osobistych ({count}).",
    "contact_event_pennywise_promo_applied": "Promocja Pennywise zastosowana względem DSN: {dsn}",
    "contact_event_pennywise_promo_applied_batched": "Promocja Pennywise zastosowana względem DSN: ({count})",
    "contact_event_ping_device": "Wysłano ping na urządzenie o DSN {dsn}.",
    "contact_event_ping_device_batched": "Wysłano ping na urządzenia ({count}).",
    "contact_event_prime_auto_renew_settings": "Automatyczne odnawianie członkostwa w programie Prime ustawiono na {auto_renew_state}.",
    "contact_event_prime_auto_revew_settings": "Automatyczne odnawianie członkostwa w programie Prime ustawiono na {auto_renew_state}.",
    "contact_event_prime_cancel_exception": "Anulowano członkostwo w programie Prime z powodu wyjątku związanego z anulowaniem członkostwa w programie Prime. Powód: {cancel_reason}.",
    "contact_event_prime_cancelled": "Anulowano członkostwo w programie Prime.",
    "contact_event_prime_cancelled_batched": "Anulowano członkostwo w Programie Prime ({count}).",
    "contact_event_prime_concession": "Przyznano rekompensatę w programie Prime za zamówienie {order_id}.",
    "contact_event_prime_concession_batched": "Przyznano rekompensaty w programie Prime za zamówienia ({count}).",
    "contact_event_prime_refund": "Zwrócono {amount} za członkostwo w programie Prime.",
    "contact_event_prime_refund_batched": "Zwrócono koszty za członkostwo w programie Prime ({count}).",
    "contact_event_prime_revoke_and_transfer_met": "Przeniesiono token przedłużenia członkostwa Prime.",
    "contact_event_promo_added_to_account": "Do konta dodano kwotę promocyjną {amount} z powodu {reason}.",
    "contact_event_promo_added_to_account_batched": "Dodano kwoty promocyjne do konta ({count}).",
    "contact_event_promo_unredeemed": "{promoBalance} niewykupionych promocji.",
    "contact_event_promo_unredeemed_batched": "Niewykupione promocje ({count}).",
    "contact_event_refund_cancelled": "Anulowano zwrot kosztów dla zamówienia {order_id}.",
    "contact_event_refund_created": "Zwrócono {refund_amount} za zamówienie {order_id}.",
    "contact_event_refund_created_batched": "Zwrócono koszty za zamówienia ({count}).",
    "contact_event_refund_from_prime_cancel_exception": "Zwrócono {refund_amount} z powodu wyjątku związanego z anulowaniem członkostwa w programie Prime. Powód: {refund_reason}.",
    "contact_event_refund_from_prime_cancel_exception_batched": "Zwrócono koszty z powodu wyjątku związanego z anulowaniem członkostwa w programie Prime ({count}).",
    "contact_event_register_kindle": "Zarejestrowano czytnik Kindle {serial_number}.",
    "contact_event_register_kindle_batched": "Zarejestrowano czytniki Kindle ({count}).",
    "contact_event_registry_deleted": "Rejestr usunięty (nr ID rejestru {registry_id}).",
    "contact_event_registry_deleted_batched": "Rejestry usunięte ({count}).",
    "contact_event_release_aiv_license": "Licencja {asin} AIV została wydana na urządzeniu z DSN {dsn}.",
    "contact_event_remove_content_from_todo_list": "Usunięto elementy ({number_of_removed_items}) z urządzenia o DSN {dsn}.",
    "contact_event_remove_content_from_todo_list_batched": "Usunięto elementy z urządzeń ({count}).",
    "contact_event_remove_from_yvl": "ASIN {asin} został przeniesiony z biblioteki filmów do usuniętych pozycji.",
    "contact_event_remove_kindle_from_blacklist": "Usunięto stan „Zgubiony lub skradziony” z czytnika Kindle o DSN {dsn}. Powód: {removing_reason}.",
    "contact_event_remove_kindle_from_blacklist_batched": "Usunięto stan „Zgubione lub skradzione” z urządzeń ({count}).",
    "contact_event_replacement_created": "Utworzono zamiennik zamówienia {old_order_id}. Nowe zamówienie: {new_order_id}.",
    "contact_event_replacement_created_batched": "Utworzono zamienniki zamówień ({count}).",
    "contact_event_request_invoice": "Prośba o wystawienie faktury dla zamówienia {order_id} została wysłana do sprzedawcy.",
    "contact_event_request_invoice_batched": "Wysłano prośbę o wystawienie faktury dla ({count}) zamówień.",
    "contact_event_resend_kindle_content": "Ponownie wysłano treści cyfrowe {title} na urządzenie typu {devicetype} o DSN {dsn}.",
    "contact_event_resend_kindle_content_batched": "Ponownie wysłano treści cyfrowe ({count}).",
    "contact_event_resent_gifted_content": "Numer ASIN {asin} dla zamówienia o identyfikatorze {order_id} Adres e-mail odbiorcy uległ zmianie.",
    "contact_event_resent_gifted_content_batched": "Zaktualizowano adres e-mail odbiorcy zamówienia na prezent ({count}).",
    "contact_event_reset_password_email_sent": "Wiadomość o zresetowaniu hasła została wysłana.",
    "contact_event_reset_password_email_sent_batched": "Wysłano wiadomości o zresetowaniu hasła ({count}).",
    "contact_event_residence_address_changed": "Zmieniono adres zamieszkania.",
    "contact_event_residence_address_changed_batched": "Zaktualizowano adres zamieszkania ({count}).",
    "contact_event_retrocharge_disarmed": "Wyłączono autoryzację zwrotu zamówienia o identyfikatorze {order_id} — numer ASIN {asin}. Powód wyłączenia: {disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "Anulowano autoryzację zwrotu zamówienia {order_id}.",
    "contact_event_rma_created": "Utworzono autoryzację zwrotu zamówienia {order_id}.",
    "contact_event_rma_created_batched": "Utworzono autoryzacje zwrotu zamówień ({count}).",
    "contact_event_rosetta_chat": "#CzatRosetta",
    "contact_event_sds_assigned_package_to_driver": "Do trasy dodano identyfikator przesyłki {trackingId}.",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "Nazwa DP: {driverName}, \nNazwa stacji: {stationName}, \nIdentyfikator śledzenia przesyłki: {trackingId} oraz \nIdentyfikator zamówienia: {orderId}\nIdentyfikator UsecaseSession: {usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "Widżet mapy otwarty dla identyfikatora przesyłki {trackingId}",
    "contact_event_sds_package_repick": "Wspólnik zainicjował ponowne pobranie dla numer monitorowania {trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} został wysłany do biblioteki filmów klienta {customer_id} .",
    "contact_event_ship_charge_reduce": "Zmieniono opłatę przewozową za zamówienie {order_id} z {old_amount} na {new_amount}.",
    "contact_event_ship_charge_reduce_batched": "Zmieniono opłaty przewozowe ({count}).",
    "contact_event_ship_method_upgraded": "Zaktualizowano metodę wysyłki zamówienia {order_id}.",
    "contact_event_ship_method_upgraded_batched": "Zmieniono metody wysyłki w zamówieniach ({count}).",
    "contact_event_shipping_address_changed": "Zmiana adresu na zamówieniu {new_order_id}.",
    "contact_event_sim_ticket_created": "Utworzono zgłoszenie SIM",
    "contact_event_special_offers_enrollment": "Zaktualizowano rejestrację ofert specjalnych na czytniku Kindle (DSN {dsn}). Stan rejestracji to {enrollment_status}.",
    "contact_event_special_offers_enrollment_batched": "Zaktualizowano stan rejestracji ofert specjalnych ({count}).",
    "contact_event_subscribe_and_save_cancelled": "Anulowano subskrypcję {subscription_id} z programu Subskrybuj i oszczędzaj.",
    "contact_event_subscribe_and_save_cancelled_batched": "Anulowano subskrypcje programu Subskrybuj i oszczędzaj ({count}).",
    "contact_event_subscribe_and_save_schedule_change": "Zmieniono harmonogram wysyłki subskrypcji {subscription_id} z programu Subskrybuj i oszczędzaj.",
    "contact_event_subscribe_and_save_schedule_change_batched": "Zmieniono harmonogram wysyłki subskrypcji programu Subskrybuj i oszczędzaj ({count}).",
    "contact_event_subscription_astro_workflow_launch": "Dział obsługi klienta uruchomił w Astro procedurę anulowania subskrypcji {subscriptionType} o numerze {subscriptionId}.",
    "contact_event_subscription_auto_renew": "Automatyczne odnawianie subskrypcji {plan_type} ustawiono na {renew_status}.",
    "contact_event_subscription_auto_renew_batched": "Zaktualizowano ustawienia automatycznego odnawiania członkostwa w programie Prime ({count}).",
    "contact_event_subscription_auto_renew_off": "Dział obsługi klienta wyłączył automatyczne odnawianie subskrypcji {subscriptionType} o numerze {subscriptionId}.",
    "contact_event_subscription_cancel_full_refund": "Dział obsługi klienta anulował subskrypcję {subscriptionType} o numerze {subscriptionId}, przeprowadzając pełen zwrot kosztów.",
    "contact_event_subscription_cancel_no_refund": "Dział obsługi klienta anulował subskrypcję {subscriptionType} o numerze {subscriptionId} bez zwrotu kosztów.",
    "contact_event_subscription_cancel_partial_refund": "Dział obsługi klienta anulował subskrypcję {subscriptionType} o numerze {subscriptionId}, przeprowadzając częściowy zwrot kosztów.",
    "contact_event_subscription_set_to_auto_renew_to_plan": "Subskrypcja {plan_type} ustawiona na przedłużenie na {new_plan_name} z {old_plan_name} pod koniec bieżącego okresu.",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "Zaktualizowane ustawienia planu odnawiania Prime ({count}).",
    "contact_event_troubleshooting_wizard": "Uruchomiono kreatora rozwiązywania problemów dla urządzenia o DSN {dsn}.",
    "contact_event_troubleshooting_wizard_batched": "Uruchomiono kreatora rozwiązywania problemów dla urządzeń ({count}).",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO: {annotation} dla urządzenia o DSN {dsn}.",
    "contact_event_troubleshooting_wizard_detailed_batched": "Uruchomiono kreatora rozwiązywania problemów dla urządzeń ({count}).",
    "contact_event_troubleshooting_wizard_digital_order": "Kreator rozwiązywania problemów wywoływany dla zamówienia treści cyfrowych o identyfikatorze zamówienia {DigitalOrderID}.",
    "contact_event_unblock_international_purchase": "Zmieniono stan zakupu międzynarodowego z zablokowanego na {new_status}.",
    "contact_event_unblock_international_purchase_batched": "Zaktualizowano stan zakupów międzynarodowych ({count}).",
    "contact_event_unlock_dash_order": "Następujące numery ASIN dla urządzenia {dsn} zostały odblokowane: {asins}.",
    "contact_event_update_locking_status": "Status blokowania urządzenia Oft {dsn} został zmieniony na: {newLockingStatus}",
    "contact_event_verify_email": "Wysłano weryfikacyjny e-mail do klienta.",
    "contact_event_verify_email_email_sent": "Wysłano weryfikacyjną wiadomość e-mail.",
    "contact_event_verify_email_email_sent_batched": "Wysłano weryfikacyjne wiadomości e-mail ({count}).",
    "contact_event_weekend_delivery_preference_changed": "Zaktualizowano preferencje dotyczące dostawy w weekend",
    "contact_event_weekend_delivery_preference_changed_batched": "Zaktualizowano preferencje dotyczące dostawy w weekend ({order_id})"
}