import {LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import Phoenix from "../../api/Phoenix";
import {i18n} from "../../i18n/IntlManager";
import {getUserLogin, getUserOwner} from "../UserDetailsUtil";
import React, {useContext, useState} from "react";
import {Contact} from "../Contact.types";
import Button from "@amzn/awsui-components-react/polaris/button";
import {IssueManagerContext} from "../../issueManager/IssueManager";
import {PushNotificationFunction} from "../ContactActions";

interface LockContactProps {
    contact: Contact;
    userId: string;
    pushNotification: PushNotificationFunction;
}

const LockContact: React.FC<LockContactProps> = ({contact,userId,pushNotification}) => {

    const {reloadSingleContact, getSubject, callPhoenix} = useContext(IssueManagerContext);
    const [isLoading,setIsLoading] = useState<boolean>(false);

    async function lockContact(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await callPhoenix(getLockMutation());
            if (response.httpStatus === 200) {
                if (response.callResult?.rules?.find(r => !r.allow)) {
                    Phoenix.getInstance().log(LogLevel.WARN, "Unauthorized to lock contact: " + contact.contactId + " , rules: " + JSON.stringify(response.callResult?.rules||[]));
                    pushNotification((<div>{i18n('lock_contact_not_authorized')}</div>), 'error');
                } else if (response.callResult?.resultJson) {
                    const parsed = JSON.parse(response.callResult.resultJson);
                    if (parsed?.data?.lockContact.failure) {
                        pushNotification((
                            <div>{i18n(parsed?.data?.lockContact.failure?.errorMessageDescriptor.stringId)}</div>), 'error');
                        Phoenix.getInstance().addMetric("LockContact.Failure." + parsed?.data?.lockContact.failure?.errorMessageDescriptor.stringId, 1, MetricType.COUNT);
                    } else {
                        await reloadSingleContact(contact.contactId);
                        pushNotification((
                            <div>{i18n('lock_contact_successful') + " "}{getUserLogin(getSubject())}</div>), 'success');
                    }
                }
            } else {
                Phoenix.getInstance().log(LogLevel.ERROR, "Failed to lock the contact: " + contact.contactId + " " + response.error);
            }
        } catch (error) {
            Phoenix.getInstance().log(LogLevel.ERROR, "Failed to lock the contact: " + contact.contactId + " " + +error);
        } finally {
            setIsLoading(false);
        }
    }

    function getLockMutation(): string {
        return (
            `mutation {
                lockContact(input:{
                    obfuscatedContactId: "${contact.contactId}",
                    ownerAgent:{agentLogin:"${userId}", agentOwner:"${getUserOwner(getSubject())}"}
                }) {
                    failure {
                        errorType
                        errorMessageDescriptor {
                            stringId
                      }
                    }
                }
            }`);
    }

    return (
        <div>
            <Button onClick={(): Promise<void> => lockContact()} disabled={isLoading}>
                {i18n("lock_contact")}
            </Button>
        </div>
            );

};

export default LockContact;

