/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, {useState} from "react";
import {Contact, ContactMediumType} from "./Contact.types";
import Translation from "../i18n/Translate";
import ContactScreenPopDisplay from "./ContactScreenPopDisplay";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import BlurbsDisplay from "./BlurbsDisplay";
import WorkCategory from "./WorkCategoryDisplay";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import EmailQueueDisplay from "./EmailQueueDisplay";
import {GridProps} from "@amzn/awsui-components-react/polaris/grid/interfaces";
import ContactCustomerIds from "./ContactCustomerIds";
import ContactUsSyskaDisplay from "./ContactUsSyskaDisplay";
import IntlManager, {i18n} from "../i18n/IntlManager";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Phoenix from "../api/Phoenix";
import {CallResponse, LogLevel, MetricType} from "@amzn/csphoenix-react-client";
import ChildContactsDisplay from "./workrequest/ChildContactsDisplay";
import { callPhoenix, InternalError, MissingFieldsError } from "../issueManager/IssueManager";

interface AdditionalContactInfoDisplayProps {
    contactId: string;
    customerId?: string;
}

const gridDefinition = (contact: Contact): ReadonlyArray<GridProps.ElementDefinition> => {
    // Show EmailQueueDisplay for email contacts
    if (contact.medium.type === ContactMediumType.EMAIL) {
        return [{"colspan":{"default":4}},{"colspan":{"default":2}},{"colspan":{"default":2}},{"colspan":{"default":2}},{"colspan":{"default":2}}, // row 1
            {"colspan":{"default":4}}, {"colspan":{"default":4}} // row 2
        ];
    } else {
        return [{"colspan":{"default":4}},{"colspan":{"default":2}},{"colspan":{"default":2}},{"colspan":{"default":2}},{"colspan":{"default":2}}, // row 1
             {"colspan":{"default":4}} // row2
        ];
    }
};



const AdditionalContactInfoDisplay: React.FC<AdditionalContactInfoDisplayProps> = ({contactId, customerId}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [contact, setContact] = useState<Contact | null>(null);
    const [error, setError] = useState<boolean>(false);

    function getQuery(contactId: string): string {
        return `query { ` +
            `   contact ( contactId: "${contactId}") { ` +
            `       contact { ` +
            `         contactId ` +
            `         marketplaceId ` +
            '         workRequestContactList { ' +
            '           contactId ' +
            '         } ' +
            `         medium { ` +
            `           type ` +
            `         }` +
            `         obfuscatedCustomerIdList ` +
            `         shipperAccountId ` +
            `         screenPop { ` +
            `           type` +
            `           usedByAgent` +
            `         }` +
            `         blurbList { ` +
            `           displayableName` +
            `           displayableContent` +
            `         }` +
            `         workCategory { ` +
            `           workCategoryId` +
            `           workCategoryName` +
            `         }` +
            `         emailQueue { ` +
            `           name` +
            `           id` +
            `         }` +
            `         retailContactUsAlertList { ` +
            `           contextualAlertId ` +
            `           displayableAlertTitle ` +
            `           displayableAlertBody ` +
            `         } ` +
            `       }` +
            `   }` +
            `}`;
    }

    const loadTheContact = (): void => {
        if (!loading && !contact) {
            setLoading(true);
            const query = getQuery(contactId);
            callPhoenix(query)
                .then((response: CallResponse) => {
                    if (response.ok) {
                        processQueryResponse(response);
                    } else {
                        throw new InternalError();
                    }

                    setLoading(false);
                })
                .catch(() => {
                    Phoenix.getInstance().log(LogLevel.ERROR, "Failed to load additional contact details: " + contactId);
                    setLoading(false);
                    setError(true);
                });
        }
    };

    async function processQueryResponse(response: CallResponse): Promise<void> {
        let contact: Contact | null = null;
        if (response.callResult) {
            if (response.callResult.resultJson) {
                const parsed = JSON.parse(response.callResult.resultJson);
                const hasErrors: boolean = parsed.errors && parsed.errors.length > 0;
                if (!hasErrors && parsed.data && parsed.data.contact && parsed.data.contact.contact) {
                    contact = parsed.data.contact.contact as Contact;
                }
            }
        }

        if (contact) {
            setContact(contact);
            setError(false);
            return;
        }

        throw new MissingFieldsError();
    }

    const contents = (): JSX.Element => {
        return ( error ?  <Alert
                    dismissAriaLabel={i18n("CLOSE_ALERT")}
                    onButtonClick={(): void => loadTheContact()}
                    buttonText={i18n("RETRY")}
                    type="error"
                    header={i18n("ERROR")}
                >
                    {i18n("ERROR_FAILED_TO_LOAD_DATA")}
                </Alert> :
            ( contact ?
        <SpaceBetween size="s">
            <Grid gridDefinition={gridDefinition(contact!)}>
                <ContactScreenPopDisplay screenPop={contact!.screenPop}/>
                <ContactCustomerIds obfuscatedCustomerIdList={contact!.obfuscatedCustomerIdList}/>
                <BlurbsDisplay blurbs={contact!.blurbList}/>
                <ChildContactsDisplay childContacts={contact!.workRequestContactList}/>
                <WorkCategory workCategory={contact!.workCategory}/>
                {contact!.medium.type === ContactMediumType.EMAIL ?
                    <EmailQueueDisplay emailQueue={contact!.emailQueue}/> : null}
                <ContactUsSyskaDisplay alerts={contact!.retailContactUsAlertList}/>
            </Grid>
        </SpaceBetween> : <div/>
            )
        );
    };

    return (
        <ExpandableSection
            variant="footer"
            header={
                <Translation stringId={"ADDITIONAL_CONTACT_INFO_TITLE"}/>
            }
            expanded={expanded}
            onChange={({ detail }): void => {
                    const shouldExpand = !expanded;
                    setExpanded(shouldExpand);
                    if (shouldExpand) {
                        Phoenix.getInstance().addMetric("AdditionalContactInfoDisplay.Expand", 1, MetricType.COUNT);
                        loadTheContact();
                    }
                }
            }
        >
            {
                loading ?
                    <Box textAlign="center" aria-label={IntlManager.sharedManager.formatMessage("LOADING")}>
                        <Spinner size="large"/>
                    </Box>
                    : contents()

            }
        </ExpandableSection>
    );
};

export default AdditionalContactInfoDisplay;
