import React from 'react';
import Button from "@amzn/awsui-components-react/polaris/button";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import Translation from "../i18n/Translate";
import IntlManager from "../i18n/IntlManager";
import {copyToClipboard} from './CopyToClipboard';
import './Copyable.css';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

interface CopyableProps {
    actionTranslationId: string;
    successTranslationId: string;
    content: React.ReactNode;
    valueToCopy: string;
}

export const Copyable: React.FC<CopyableProps> = (props) => {
    const {
        actionTranslationId,
        successTranslationId,
        content,
        valueToCopy
    } = props;
    const [copied, setCopied] = React.useState(false);
    const copyContent = async (event): Promise<void> => {
        // event.stopPropagation();
        setCopied(await copyToClipboard(valueToCopy));
    };

    const stopPropagation = (event): void => {
        event.stopPropagation();
    };

    return (
        // Disabling accessibility rule because the onclick handler is only used to stop propagation
        // eslint-disable-next-line
        <div role={"button"} onClick={stopPropagation}>
            <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={(
                    copied
                        ?
                        <StatusIndicator type="success"><Translation stringId={successTranslationId}/></StatusIndicator>
                        : <StatusIndicator type="error"><Translation stringId="COPY_FAILURE"/></StatusIndicator>
                )}
            >
                <Button
                    iconName="copy"
                    variant="icon"
                    ariaLabel={IntlManager.sharedManager.formatMessage(actionTranslationId, {values: {"value": valueToCopy}})}
                    onClick={copyContent}
                />
            </Popover>{' '}
            {content}
        </div>
    );
};