// Somewhat hacky way to make Ajax calls to CSC/Gurupa by sending window events to parent of
// iframe

export class CSCCall {
    id: number;
    handler: (data, context) => void;
    context?: {};
    progressHandler?: (data, context) => void;

    constructor(id: number, handler: (data, context) => void, context?: {}, progressHandler?: (data, context) => void) {
        this.id = id;
        this.handler = handler;
        this.context = context;
        this.progressHandler = progressHandler;
    }
}

const requests: Map<string, CSCCall> = new Map();

function cscCallHandler(event): void {
    if (event.type === 'message') {
        if (event.data.type === 'cscCallResponse') {
            const id = event.data.id;
            const openCall = requests[id];
            if (openCall) {
                openCall.handler(event.data, openCall.context);
            }
            requests.delete(id);
        }
        if (event.data.type === 'progress') {
            const id = event.data.id;
            const openCall = requests[id];
            if (openCall) {
                openCall.progressHandler(event.data, openCall.context);
            }
        }
    }
}

window.addEventListener('message', cscCallHandler, false);

export function getAttachmentFromTAW(attachmentId: string, fileName: string, handler: (data) => void, context?: {}): void {
    const call = new CSCCall(Math.random(), handler, context);
    requests[call.id] = call;
    const message = {type: 'get-attachment', id: call.id, attachmentId: attachmentId, fileName: fileName};
    window.parent.postMessage(message, '*');
}