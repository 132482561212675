export const pl_PL = {
    "ACCESS_RESTRICTED_CONTENT": "Dostęp do treści zastrzeżonych",
    "ACTION": "Akcja",
    "ADD": "Dodaj",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Dodatkowe informacje kontaktowe",
    "ADD_ANNOTATION_FAILURE": "Dodanie adnotacji nie powiodło się.",
    "ADD_ANNOTATION_INVALID": "Nieprawidłowa adnotacja. Upewnij się, że w adnotacji nie znajdują się dane zastrzeżone.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nowa adnotacja",
    "ADD_ANNOTATION_SUCCESS": "Pomyślnie dodano adnotację.",
    "AGENT": "Agent",
    "AGENT_ANNOTATION_BADGE": "Agent",
    "AGENT_SELECTED_ISSUE": "Problem wybrany przez agenta",
    "AGENT_SELECTED_ISSUE_COPIED": "Skopiowano problem wybrany przez agenta.",
    "AMAZON_CONNECT_CALL_INFO": "Informacje o połączeniu Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "Identyfikator kontaktu Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "Identyfikator kontaktu Amazon Connect był skopiowany",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANNOTATED_BY": "Autor adnotacji: {agentLogin}.",
    "ANNOTATIONS": "Adnotacje",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "Adnotacje nie powinny zawierać danych typu PII klienta, takich jak numery telefonów i adresy e-mail. Nasz automatyczny system blokuje dodawanie takich informacji, ale czasami nie radzi sobie z odróżnieniem PII od innych identyfikatorów. Podaj powód, dla którego ominięcie zabezpieczenia jest konieczne.",
    "ANNOTATION_WARNING": "Na mocy przepisów o ochronie prywatności komentarze mogą być przeglądane przez klientów. Nie umieszczaj danych osobowych ani opinii w polu komentarza.",
    "ATTACHMENTS_RESTRICTED": "Załączniki są zastrzeżone.",
    "ATTACHMENTS_TITLE": "Załączniki",
    "ATTACHMENT_DELETION_FAILED": "Usuwanie załączników nie powiodło się, spróbuj ponownie później.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Usuwanie załączników powiodło się.",
    "AUTHENTICATING": "Rozpoczęto uwierzytelnianie. Może to otworzyć nowe okno.",
    "AUTHENTICATION_FAILURE": "Wystąpił problem z uwierzytelnieniem.",
    "AUTHENTICATION_MESSAGE": "Pobieranie nowych danych uwierzytelniających. To okno zamknie się za kilka sekund.",
    "AUTO_ANNOTATION_BADGE": "Auto",
    "BACK": "Wstecz",
    "BCC_EMAIL_ADDRESS": "UDW",
    "BLURBS_USED_TITLE": "Zastosowano blurb",
    "CALL_LEG": "ID odnogi połączenia",
    "CALL_LEG_ID_COPIED": "Identyfikator odnogi połączenia skopiowany.",
    "CALL_RESTRICTED": "Treści połączenia są zastrzeżone.",
    "CALL_RETENTION_DELETED": "Nagranie zostało usunięte, gdyż minął okres przechowywania.",
    "CALL_TITLE": "Rozmowa z Działem obsługi klienta Amazon",
    "CANCEL": "Anuluj",
    "CC_EMAIL_ADDRESS": "DW",
    "CF_LARGE_NUMBER_ALERT": "Ten klient ma bardzo dużą liczbę osób upoważnionych do kontaktu i nie wszystkie z nich zostały załadowane. Osoby upoważnione do kontaktu są zawsze ładowane w kierunku „od ostatniej do starszej”, począwszy od daty zakończenia. Zmień datę zakończenia, aby rozpocząć ładowanie od innego punktu w czasie.",
    "CHANNELS": "Kanały",
    "CHAT_AGENT_CONNECTED": "{name} zaakceptował(a) odbiór wiadomości na czacie.",
    "CHAT_CONTACT": "Kontakt czatu.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} utracił(a) połączenie z siecią.",
    "CHAT_DISCONNECT_GENERIC": "{name} nie jest już połączony/-a.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} utracił(a) połączenie z siecią.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} utracił(a) połączenie z siecią.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} opuścił(a) rozmowę.",
    "CHAT_PARKED": "Ten czat został zaparkowany",
    "CHAT_PARKED_NOTES": "Notatki do zaparkowanego czatu",
    "CHAT_PARTICIPANT_OFF_HOLD": "Zakończono wstrzymanie: {name}.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} jest wstrzymany.",
    "CHAT_RESTRICTED": "Treści na czacie są zastrzeżone.",
    "CHAT_TITLE": "Czat z Działem obsługi klienta Amazon",
    "CHAT_TRANSFER_NOTES": "Uwagi do przeniesienia",
    "CHILD_WORK_REQUESTS": "Zlecenia pracy utworzone z tego kontaktu",
    "CHOOSE_OPTIONS": "Wybierz opcje",
    "CLOSE": "Zamknij",
    "CLOSE_ALERT": "Zamknij powiadomienie",
    "CLOSE_MODAL_BUTTON_TEXT": "Zamknij",
    "COLLAPSED": "Zwinięto",
    "CONFIRM": "Potwierdź",
    "CONTACTS": "Kontakty",
    "CONTACT_COLLAPSED": "Dane kontaktowe zostały zwinięte.",
    "CONTACT_CREATION_FAILURE": "Nie udało się utworzyć nowego kontaktu",
    "CONTACT_EXPANDED": "Rozszerzono dane kontaktowe.",
    "CONTACT_HISTORY": "Historia kontaktu",
    "CONTACT_ID": "Identyfikator kontaktu",
    "CONTACT_ID_COPIED": "Identyfikator kontaktu skopiowany.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Kontakt",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Eskalacje",
    "CONTACT_REASSIGNED_TO_AGENT": "Osoba upoważniona do kontaktu została ponownie przypisana do agenta.",
    "CONTACT_SELECT_CHECKBOX": "Pole wyboru do wybrania zablokowanego kontaktu",
    "CONTACT_STATUS_ESCALATION_BADGE": "Eskalacja",
    "CONTACT_TICKET": "Zgłoszenie kontaktowe",
    "CONTACT_TICKET_CONTENT": "Treść zgłoszenia",
    "CONTACT_TICKET_CREATE": "Utwórz zgłoszenie kontaktowe",
    "CONTACT_TICKET_CREATION_FAILURE": "Nie udało się utworzyć zgłoszenia kontaktowego",
    "CONTACT_TICKET_CREATION_SUCCESS": "Utworzono zgłoszenie kontaktowe",
    "CONTACT_TICKET_RESOLVER_GROUP": "Grupa rozwiązująca problem",
    "CONTACT_TICKET_SEVERITY": "Istotność zgłoszenia",
    "CONTACT_TICKET_SIM_FOLDER_ID": "Identyfikator folderu SIM",
    "CONTACT_TICKET_SUBJECT": "Temat zgłoszenia",
    "CONTACT_TIMELINE": "Oś czasu kontaktu",
    "CONTACT_TYPE": "Rodzaj kontaktu",
    "CONTACT_WAS_TRANSFERRED": "Ten kontakt został przekierowany.",
    "CONTENT": "Treść",
    "CONTENT_RESTRICTED": "Treść podlega ograniczeniom.",
    "COPY_AGENT_SELECTED_ISSUE": "Kopiuj wybrany problem agenta: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Kopiuj identyfikator kontaktu Amazon Connect",
    "COPY_CALL_LEG_ID": "Kopiuj identyfikator odnogi połączenia: {value}",
    "COPY_CONTACT_ID": "Kopiuj identyfikator kontaktu: {value}",
    "COPY_CUSTOMER_ID": "Kopiuj identyfikator klienta",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Kopiuj wybrany problem klienta: {value}",
    "COPY_FAILURE": "Nie udało się skopiować.",
    "COPY_ID": "Kopiuj ID",
    "COPY_LINK": "Skopiuj link do przesyłania obrazu",
    "COPY_ORDER_ID": "Identyfikator zamówienia kopii: {value}.",
    "CREATE_CASE": "Utwórz sprawę",
    "CREATE_FOLLOW_UP": "Utwórz dalsze działanie",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Pomyślnie utworzono dalsze działanie, ale nie można dodać odpowiedniej adnotacji. Ponów próbę dodania adnotacji ręcznie.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Określ preferowaną metodę kontaktu klienta.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Nie można utworzyć dalszego działania z terminem i godziną w przeszłości.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Określ datę i godzinę podjęcia dalszego działania.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Strefa czasowa",
    "CREATE_FOLLOW_UP_FAILURE": "Nie udało się utworzyć dalszego działania.",
    "CREATE_FOLLOW_UP_INVALID": "Nieprawidłowe dalsze działanie. Upewnij się, że nie ma zastrzeżonych danych.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Wpisz dalsze działanie.",
    "CREATE_FOLLOW_UP_SUCCESS": "Pomyślnie utworzono dalsze działanie.",
    "CREATE_WORK_REQUEST": "Utwórz żądanie pracy",
    "CROSS_MARKETPLACE_QUEUES": "W kolejkach pomiędzy rynkami",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Przekierowanie kontaktu nie powiodło się, ponieważ został on już rozwiązany.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "Operacja nie powiodła się, ponieważ kontakt jest w nieprawidłowym stanie.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Operacja nie powiodła się, ponieważ kontakt nie został odnaleziony",
    "CSALT_ERROR_INTERNAL_ERROR": "Nie udało się zablokować kontaktu",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Nie udało się zablokować kontaktu z powodu niepoprawnych argumentów",
    "CSALT_ERROR_INVALID_INPUT": "Operacja nie powiodła się z powodu nieprawidłowych danych wejściowych.",
    "CSALT_ERROR_INVALID_QUEUE": "Przekierowanie kontaktu nie powiodło się, ponieważ wybrana kolejka jest nieprawidłowa.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Operacja nie powiodła się, ponieważ kontakt jest przypisany do innego agenta.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Nie udało się zablokować kontaktu z powodu błędu zależności",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Przeniesienie kontaktu nie powiodło się, ponieważ nie znaleziono kolejki w usłudze back end.",
    "CSALT_ERROR_UNKNOWN": "Operacja nie powiodła się z powodu nieoczekiwanego błędu.",
    "CURRENT_CONTACT": "Bieżący kontakt",
    "CUSTOMER": "Klient",
    "CUSTOMER_FOLLOWUPS": "Dalsze działania dla klienta",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Nie można pobrać dalszych działań dla klienta",
    "CUSTOMER_ID_COPIED": "Skopiowano identyfikator klienta",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "Treści klienta są zastrzeżone. (Brak zablokowanych kontaktów).",
    "CUSTOMER_SELECTED_ISSUE": "Wybrany problem klienta",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "Skopiowano problem wybrany przez klienta.",
    "CUSTOMER_TIMEZONE": "Znaczniki czasu wyświetlane w strefie czasowej klienta",
    "DATE": "Data",
    "DATE_PICKER_NEXT_MONTH": "Następny miesiąc",
    "DATE_PICKER_PREVIOUS_MONTH": "Poprzedni miesiąc",
    "DATE_PICKER_TODAY": "Dzisiaj",
    "DECODING_AUDIO": "Dekodowanie dźwięku",
    "DELETE_ATTACHMENTS": "Usuń załączniki do kontaktu",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Usuń załączniki",
    "DELIVERY_STATUS": "Status dostawy",
    "DISABLE_CONTENT_FILTER": "Wyłącz filtr treści",
    "DOWNLOAD": "Pobierz",
    "DURATION": "Czas trwania",
    "EMAIL": "Adres e-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "Adresy e-mail odbiorców są niedozwolone.",
    "EMAIL_BODY": "Wiadomość e-mail",
    "EMAIL_CONTACT": "Wyślij wiadomość e-mail do osoby kontaktowej.",
    "EMAIL_HEADER": "Nagłówek",
    "EMAIL_QUEUE_TITLE": "Kolejka e-mail",
    "EMAIL_RESTRICTED": "Treści e-mail są zastrzeżone.",
    "EMAIL_SENDING_FAILURE": "Wysyłanie wychodzącej wiadomości e-mail nie powiodło się.",
    "EMAIL_SUBJECT": "Temat wiadomości e-mail",
    "EMAIL_TITLE": "Porozmawiaj z działem obsługi klienta Amazon przez e-mail",
    "END": "Zakończ",
    "END_DATE": "Data zakończenia",
    "ERROR": "Błąd",
    "ERROR_FAILED_TO_LOAD_DATA": "Wczytanie danych nie powiodło się.",
    "ESCALATION_CONTACT_HANDLING": "Obsługa kontaktu związanego z eskalacją",
    "ESCALATION_TAB_LEAD_LOGIN": "Login lidera: {agentLogin}",
    "EXPANDED": "Rozwinięto",
    "FAILED_TO_CREATE_WORK_REQUEST": "Nie udało się utworzyć żądania pracy",
    "FAILED_TO_FETCH_SYSKA": "Pobieranie ostrzeżeń SYSKA nie powiodło się",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Nie udało się ponownie przypisać osoby upoważnionej do kontaktu do agenta.",
    "FETCHED_NUMBER_CONTACTS": "Pobrano kontakty: {number}.",
    "FILTER": "Filtr",
    "FINDER": "Wyszukiwarka",
    "FOLLOW_UPS_COLLAPSED": "Dalsze działania nie udały się",
    "FOLLOW_UPS_EXPANDED": "Rozszerzone dalsze działania",
    "FOLLOW_UP_ALERT": "ma dalsze działania w związku z {dueDate} utworzonym przez {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Metoda kontaktu",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "Metoda kontaktu jest zastrzeżona.",
    "FOLLOW_UP_DUE": "Należy podjąć dalsze działania",
    "FOLLOW_UP_DUE_DATE": "Termin płatności",
    "FOLLOW_UP_NOTE": "Uwaga",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Brak metody kontaktu",
    "FOLLOW_UP_NO_NOTE": "Pole „Uwagi” jest puste",
    "FOLLOW_UP_NO_TICKET": "Brak zgłoszenia",
    "FOLLOW_UP_OWNER": "Właściciel",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_OPEN": "Otwarte",
    "FOLLOW_UP_STATUS_RESOLVED": "Rozwiązano",
    "FOLLOW_UP_TICKET": "Zgłoszenie",
    "FOLLOW_UP_TITLE": "Dalsze działanie",
    "FROM": "Od",
    "GENERATE_LINK": "Wygeneruj link",
    "HAS_OVERRIDE_TRY_AGAIN": "W tym momencie aktywne jest obejście filtra. Spróbuj ponownie.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "link do załącznika z obrazkiem udostępniony klientowi",
    "INPUT_CHARACTERS_REMAINING": "Maksymalna liczba znaków {char_max} (pozostało: {char_remain})",
    "INTERNAL_ERROR": "Błąd wewnętrzny",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Wystąpił błąd wewnętrzny. Jeśli błąd będzie się powtarzał, prześlij do nas zgłoszenie opisujące problem.",
    "INVALID_CONTACT": "Nieprawidłowa osoba upoważniona do kontaktu.",
    "INVALID_DATA_MATCH": "System dokonał nieprawidłowej klasyfikacji danych.",
    "INVALID_TIMESTAMP": "Nieprawidłowy znacznik czasu.",
    "IN_MARKETPLACE_QUEUES": "W kolejkach na rynkach",
    "ISSUE": "Problem",
    "ISSUE_CODE": "Kod problemu",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Problem zaadresowany i rozwiązany w trakcie poprzedniego kontaktu",
    "ISSUE_CODE_DESCRIPTION": "Wybierz przyczynę oznaczenia wybranych kontaktów jako duplikaty",
    "ISSUE_CODE_EXACTLY_SAME": "Dokładnie taki sam jak poprzedni kontakt",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Obsłużone i rozwiązane przez innego pracownika na czacie",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Obsłużone i rozwiązane przez innego pracownika przez e-mail",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Obsłużone i rozwiązane przez innego pracownika przez telefon",
    "ITEMS_CONTACTED_ABOUT": "Produkt, którego dotyczy kontakt",
    "I_UNDERSTAND": "Rozumiem",
    "KNOWN_ISSUES": "Znane problemy",
    "LEARN_MORE": "Więcej informacji",
    "LINK_COPIED": "Link skopiowany",
    "LOADING": "Ładowanie danych.",
    "LOADING_PREVIEW_TEXT": "Wczytywanie podglądu",
    "LOADING_RECORDING": "Ładowanie nagrania",
    "LOAD_MORE": "Załaduj więcej",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Załaduj więcej adnotacji.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Załaduj więcej kontaktów dla tego klienta.",
    "LOCKED": "Zablokowane",
    "LOCKED_BY": "Zablokowane przez",
    "LOCK_CONTACT": "Zablokuj",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Nie udało się zablokować kontaktu, użytkownik nie jest uprawniony",
    "LOCK_CONTACT_SUCCESSFUL": "Kontakt zablokowany:",
    "MISSING_ORIGINAL_CONTACT_ID": "Podaj prawidłowy oryginalny identyfikator kontaktu",
    "MODAL_NO_BUTTON_TEXT": "Nie",
    "MODAL_YES_BUTTON_TEXT": "Tak",
    "NEXT": "Dalej",
    "NORMAL": "Normalny",
    "NO_AGENT_SELECTED_ISSUE": "Nie wybrano problemu agenta.",
    "NO_ANNOTATED_BY": "Adnotacja od nieznanego użytkownika.",
    "NO_ANNOTATIONS": "Brak dostępnych adnotacji.",
    "NO_ATTACHMENTS": "Nie ma żadnych załączników.",
    "NO_CHAT": "Czat jest pusty.",
    "NO_CHILD_WORK_REQUESTS": "Nie utworzono żadnych zleceń pracy z tego kontaktu",
    "NO_CONTACT": "Brak dostępnego kontaktu",
    "NO_CONTACTS_SELECTED": "Wybierz kontakty, aby przetworzyć je jako spam",
    "NO_CONTENT": "Brak treści",
    "NO_CUSTOMER_SELECTED_ISSUE": "Brak problemu wybranego przez klienta.",
    "NO_DELIVERY_STATUS": "Brak statusu dostawy",
    "NO_EMAIL_BODY": "E-mail pusty.",
    "NO_EMAIL_HEADER": "Brak nagłówka e-maila.",
    "NO_EMAIL_QUEUE": "Z tym kontaktem nie skojarzono kolejki wiadomości e-mail.",
    "NO_ITEMS_CONTACTED_ABOUT": "Nie kontaktowano się w sprawie produktów.",
    "NO_RESULTS": "Nie znaleziono żadnych wyników.",
    "NO_SCREEN_POP": "Brak automatycznie wyświetlanych informacji o rozmówcy",
    "NO_SENT_FROM": "Nie wysłano z adresu.",
    "NO_SENT_TO": "Nie wysłano na adres",
    "NO_SUBJECT": "Wiersz tematu jest pusty.",
    "NO_SYSKA_ALERTS": "Brak ostrzeżeń SYSKA",
    "NO_USED_BLURBS": "Nie użyto blurbów do tego kontaktu.",
    "NO_VIEW": "Brak kontaktu lub klienta do wyświetlenia.",
    "NO_WORK_CATEGORY": "Brak kategorii pracy skojarzonej z tym kontaktem.",
    "NUMBER_OF_CONTACTS": "Załadowane osoby upoważnione do kontaktu",
    "OLDEST_LOADED_CONTACT": "Najstarsza załadowana osoba upoważniona do kontaktu",
    "OPEN": "Otwarty",
    "OPENS_IN_A_NEW_TAB": "Otwiera się w nowej karcie",
    "ORDER_ID_COPIED": "Identyfikator zamówienia skopiowany.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Wprowadź oryginalny identyfikator kontaktu",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Pole tekstowe do wprowadzania oryginalnego identyfikatora kontaktu",
    "OTHER": "Inne",
    "OUTBOUND_EMAIL_BCC": "i UDW –",
    "OUTBOUND_EMAIL_CC": "przesyłając DW –",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Zwinięto treść powiadomienia.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Rozwinięto treść powiadomienia.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Osiągnięto maksymalną liczbę wyników. Zmniejsz zakres czasu i spróbuj ponownie.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "Obecny proces wymaga podania treści typu PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Nieprawidłowe dopasowanie: numer telefonu, adres e-mail lub inne dane typu PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Nieprawidłowa identyfikacja — karta kredytowa lub IBAN.",
    "OVERRIDE_REASON_PROMPT": "Adnotacje nie powinny zawierać danych typu PII klienta, takich jak numery telefonów i adresy e-mail. Nasz automatyczny system blokuje dodawanie takich informacji, ale czasami nie radzi sobie z odróżnieniem PII od innych identyfikatorów. Podaj powód, dla którego ominięcie zabezpieczenia jest konieczne.",
    "PAPYRUS_SESSION_EXPLANATION": "Określ typ sesji Papyrus, abyśmy mogli zastosować odpowiednie ograniczenia bezpieczeństwa i zoptymalizować UX pod kątem Twoich potrzeb.",
    "PAPYRUS_SESSION_TYPE": "Typ sesji Papyrus",
    "PAUSE": "Wstrzymanie",
    "PHONE_CONTACT": "Kontakt telefoniczny.",
    "PLAY": "Odtwarzaj",
    "PLAYBACK_SPEED": "Szybkość odtwarzania",
    "POSTAL_MAIL_TITLE": "Kontakt pocztą tradycyjną z Działem obsługi klienta Amazon",
    "PREPARING_RECORDING": "Przygotowywanie nagrania",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Nie udało się wygenerować podglądu dla kontaktu",
    "PREVIOUS": "Wstecz",
    "PRE_TRANSLATED_REPLY_EMAIL": "Wstępnie przetłumaczona odpowiedź e-mail",
    "PRE_TRANSLATION_REPLY_TEXT": "Odpowiedź pracownika Działu obsługi klienta przed uruchomieniem tłumaczenia Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Przetwarzanie działań",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Przetwórz jako duplikaty",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Przetwarzanie jako spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Rozwiąż wybrane kontakty jako duplikaty",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Czy na pewno chcesz przetworzyć wybrane kontakty jako spam?",
    "PROCESS_DUPLICATE_FAILURE": "Nie udało się oznaczyć wybranych kontaktów jako duplikaty",
    "PROCESS_DUPLICATE_SUCCESS": "Pomyślnie rozwiązano wybrane kontakty jako duplikaty",
    "PROCESS_SPAM_FAILURE": "Nie można przenieść wybranych kontaktów do spamu",
    "PROCESS_SPAM_SUCCESS": "Pomyślnie przeniesiono wybrane kontakty do spamu",
    "PROVIDE_ADDITIONAL_INFORMATION": "Podaj dodatkowe informacje istotne dla tego kontaktu",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "Usunięte poufne informacje z kontaktu:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Dodaj słowo do listy czyszczenia",
    "PURGE_BUTTON_TEXT": "Usuwanie",
    "PURGE_CONFIRMATION_CHECK": "Po ponownym sprawdzeniu powyższych pól potwierdzam, że tekst zawiera informacje poufne.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Potwierdzam, że załącznik rzeczywiście zawiera poufne informacje dotyczące klienta.",
    "PURGE_CONFIRMATION_UNCHECKED": "Potwierdź pole wyboru.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Nie udało się usunąć z kontaktu",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Pomyślnie wyczyszczono informacje z kontaktu",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} nie jest poprawnym powodem wyczyszczenia",
    "PURGE_REASON_FINANCIAL_INFO": "Informacje finansowe",
    "PURGE_REASON_OTHER": "Inne informacje poufne",
    "PURGE_REASON_PII": "Informacje umożliwiające identyfikację osoby",
    "PURGE_REASON_PLACEHOLDER": "Wprowadź przyczynę usunięcia",
    "PURGE_REASON_TEXT": "Powód usunięcia",
    "PURGE_REMOVE_LABEL": "Usuń",
    "PURGE_SELECTOR_PLACEHOLDER": "Wybierz przyczynę usunięcia poufnych informacji",
    "PURGE_SENSITIVE_INFO": "Wyczyść poufne informacje",
    "PURGE_WORD_NOT_FOUND": "Nie odnaleziono słowa w tekście kontaktu",
    "PURPOSE_AUDITING": "Audyt prowadzony w celu kontroli jakości",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Procedura Back Office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "To sesja, której celem jest uzyskanie danych kontaktowych dla procedur Back Office. Na przykład załączniki do dołączania do zgłoszeń lub SIM.",
    "PURPOSE_COACHING": "Przegląd kontaktu coachingowego",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Badanie zgodności",
    "PURPOSE_CURATION": "Organizowanie kontaktu",
    "PURPOSE_CURATION_DESCRIPTION": "To sesja organizowania kontaktu C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Jest to sesja audytu kontaktów i oceny ich jakości.",
    "PURPOSE_DESCRIPTION_COACHING": "To sesja do przeglądania kontaktów agentów, których jesteś trenerem w Portalu Jakość sprzedaży.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Jest to sesja poświęcona badaniom dotyczącym monitorowania podejrzanych działań.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "To sesja obsługi kontaktu związanego z eskalacją. Umożliwia dostęp do danych kontaktowych każdego klienta.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "To sesja obsługi kontaktu na żywo.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "Celem tego działania jest usunięcie poufnych informacji z kontaktu",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "To sesja do przeglądu dokładności modelu uczenia maszynowego.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "To sesja do przeglądania własnych kontaktów z przeszłości.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "To domyślna sesja o tematyce ogólnej.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Obsługa kontaktu związanego z eskalacją",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Wyczyść poufne informacje",
    "REASON_FOR_PURGE": "Powód usunięcia",
    "REASON_FOR_PURGE_DESCRIPTION": "Powód usunięcia załączników",
    "REASON_FOR_PURGE_MISSING": "Podaj przyczynę usunięcia załączników.",
    "REASSIGN_TO_AGENT": "Ponownie przydziel do agenta",
    "RECORDING": "Nagranie",
    "RELOADING_CONTENT": "Trwa ponowne ładowanie treści.",
    "RELOAD_LIST": "Wczytaj ponownie listę",
    "REPLY_BY": "Odpowiedź:",
    "REPLY_BY_EMAIL": "Odpowiedz mailowo",
    "REPLY_BY_PHONE": "Odpowiedź telefoniczna",
    "REPLY_RESTRICTED": "Treść odpowiedzi jest zastrzeżona.",
    "REQUEST": "Wniosek",
    "REQUEST_ACCESS_INSTRUCTIONS": "Podaj powód, dla którego wymagany jest dostęp do treści zastrzeżonych. (Wnioski te są monitorowane, a nadużycia mogą mieć poważne konsekwencje).",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Prośba o dostęp do odfiltrowanych treści",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Prośba o dostęp do zastrzeżonych treści",
    "REQUEST_NOT_GRANTED": "Wniosek nie został uwzględniony.",
    "REQUEST_OVERRIDE": "Prośba o pominięcie zabezpieczenia",
    "RESEND": "Wyślij ponownie",
    "RESEND_FAILED": "Nie udało się ponownie wysłać wiadomości e-mail.",
    "RESEND_SUCCESS": "Ponownie wysłano wiadomość e-mail.",
    "RESEND_WAIT_WARNING": "Musisz poczekać {wait_mins} minut(y), aby ponownie wysłać.",
    "RESEND_WARNING": "Czy na pewno chcesz ponownie wysłać wiadomość e-mail do klienta?",
    "RESET": "Resetuj",
    "RESOLVED": "Rozwiązane",
    "RESOLVED_BY": "Rozwiązane przez",
    "RESTRICTED_DATA_MATCH": "{match} typ dopasowania {type}",
    "RESTRICTED_INFORMATION": "INFORMACJE ZASTRZEŻONE",
    "RETRY": "Spróbuj ponownie",
    "ROLE": "Rola",
    "SCREEN_POP_DESCRIPTION_TITLE": "Opis",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Nie",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Tak",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Wyświetlone dla Konsultanta",
    "SCREEN_POP_TITLE": "Automatycznie wyświetlone informacje o rozmówcy",
    "SCREEN_POP_TYPE_AUTHENTICATING": "Uwierzytelnione automatyczne wyświetlanie informacji o rozmówcy zostało wyświetlone i użyte.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "Agentowi automatycznie wyświetlono informacje o rozmówcy w ramach uwierzytelnienia.",
    "SCREEN_POP_TYPE_CALLER_ID": "Nastąpiło automatyczne wyświetlanie informacji o identyfikatorze rozmówcy i zostało użyte.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "Nastąpiło automatyczne wyświetlanie informacji o identyfikatorze rozmówcy dla agenta.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "Nieuwierzytelnione automatyczne wyświetlanie informacji o rozmówcy zostało wyświetlone i użyte.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Nastąpiło automatyczne wyświetlanie nieuwierzytelnionej informacji dopowiedzianej agentowi.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "Klient nieznany, brak automatycznie wyświetlanych informacji o rozmówcy.",
    "SCREEN_POP_TYPE_OTHER": "Inne",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Nie",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Tak",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Użyte przez konsultanta",
    "SDS_TOOLS": "Narzędzia pomocy technicznej do wysyłki i dostawy",
    "SEARCH": "Szukaj",
    "SELECTED_ROLE": "Wybrana rola",
    "SELECTED_VIEW": "Wybrany widok",
    "SELECT_CHANNELS": "Wybierz kanały",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Wybranie kodu problemu do oznaczania wybranych kontaktów jako duplikatów",
    "SELECT_TRANSFER_DESTINATION": "Wybierz miejsce docelowe transferu",
    "SELECT_WORK_REQUEST_QUEUE": "Wybierz kolejkę żądania pracy",
    "SENDER_EMAIL_ADDRESS": "Nadawca",
    "SENDING_EMAIL": "Wysyłanie wiadomości e-mail",
    "SEND_EMAIL": "Wyślij wiadomość e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Czy na pewno chcesz wysłać link do przesłania obrazu do klienta?",
    "SEND_OUTBOUND_EMAIL": "Wyślij wychodzącą wiadomość e-mail",
    "SEND_OUTBOUND_EMAIL_WARNING": "Czy na pewno chcesz wysłać wychodzącą wiadomość e-mail do",
    "SENT_FROM": "Wysłane z",
    "SENT_OUTBOUND_EMAIL": "Wychodząca wiadomość e-mail została wysłana. Utworzono nowy kontakt",
    "SENT_TO": "Wysłano do",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal spedytora",
    "SKILL": "Umiejętność",
    "SKIP_SILENCES": "Pomiń czas ciszy",
    "SMS": "SMS",
    "SMS_LINK": "Link SMS",
    "SOURCE_TEXT": "Tekst źródłowy",
    "SP_BOTTOM": "Dół",
    "SP_POSITION": "Położenie podzielonego panelu",
    "SP_PREFERENCES": "Preferencje podzielonego panelu",
    "SP_RESIZE": "Zmień rozmiar podzielonego panelu",
    "SP_SIDE": "Bok",
    "START": "Rozpocznij",
    "STATE_TRANSITIONS": "Przejścia pomiędzy państwami",
    "STATUS": "Status",
    "SUBJECT": "Temat",
    "SYSKA_ALERTS": "Ostrzeżenia SYSKA",
    "TEXT_TO_PURGE": "Tekst do usunięcia",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Pliki cookie innych firm muszą być włączone.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Dane PII klienta, takie jak numery telefonów i adresy e-mail nie są dozwolone podczas tworzenia zgłoszenia. Nasz automatyczny system blokuje dodawanie takich informacji, ale czasami nie radzi sobie z odróżnieniem PII od innych identyfikatorów. Podaj powód, dla którego ominięcie zabezpieczenia jest konieczne.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Przekroczono limit czasu: nie udało się załadować nagrania",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Użyj formatu 24-godzinnego.",
    "TO": "Do",
    "TO_EMAIL_ADDRESS": "Do",
    "TRANSFER": "Przekieruj",
    "TRANSFERRED_CHAT_TITLE": "Przekierowany czat z Działem obsługi klienta Amazon",
    "TRANSFERRING": "Następuje przekierowanie...",
    "TRANSFER_CONTACT": "Przekieruj kontakt",
    "TRANSFER_CONTACT_COMMENTS": "Adnotacja opcjonalna",
    "TRANSFER_FAILED": "Przekierowanie nie powiodło się",
    "TRANSFER_FOLLOW_UP": "Przekieruj dalsze działanie",
    "TRANSFER_FOLLOW_UP_FAILED": "Nie udało się przekierować dalszego działania.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Nie udało się przekierować dalszego działania z powodu nieprawidłowego loginu nowego właściciela.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Nie masz uprawnień do przeniesienia tego dalszego działania.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Wpisz login nowego właściciela. Przykład: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Przekieruj powyższe dalsze działanie do:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Pomyślnie przekierowano dalsze działanie.",
    "TRANSLATED_TEXT": "Przetłumaczony tekst",
    "TRANSLATION": "Tłumaczenie",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} nie jest prawidłową nazwą kolejki.",
    "TYPE_AGENT_LOGIN": "Wpisz login agenta",
    "TYPE_EMAIL_ADDRESS": "Wpisz adres e-mail",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Wpisz wiele adresów e-mail oddzielonych przecinkami",
    "TYPE_QUEUE_NAME": "Wpisz nazwę kolejki",
    "UNKNOWN_CONTACT_TYPE": "Nieznany typ kontaktu.",
    "UNLOCKED": "Odblokowane",
    "UNRESOLVED": "Nierozwiązane",
    "URGENT_SYSKA_ALERTS": "Pilne ostrzeżenia SYSKA",
    "USE": "Użyj",
    "USER_TIMEZONE": "Znaczniki czasu wyświetlane w lokalnej strefie czasowej",
    "USE_CONTACT_FINDER": "Użyj wyszukiwarki osób upoważnionych do kontaktu",
    "VIEW": "Wyświetl",
    "WARNING": "Ostrzeżenie",
    "WELCOME_MESSAGE": "Witamy na stronie Papyrus Static!",
    "WHAT_IS_PAPYRUS": "Co to jest Papyrus?",
    "WITH_THE_ANNOTATION": "wraz z adnotacją",
    "WORK_CATEGORY_TITLE": "Kategoria pracy",
    "WORK_REQUEST": "Żądanie pracy",
    "WORK_REQUEST_CONTENT": "Treść żądania pracy",
    "WORK_REQUEST_CREATED": "Utworzono żądanie pracy",
    "WORK_REQUEST_FOR_CONTACT": "Żądanie pracy – prośba o kontakt",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Dane PII klienta, takie jak numery telefonów i adresy e-mail nie są dozwolone podczas tworzenia zlecenia pracy. Nasz automatyczny system blokuje dodawanie takich informacji, ale czasami nie radzi sobie z odróżnieniem PII od innych identyfikatorów. Podaj powód, dla którego ominięcie zabezpieczenia jest konieczne.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Czy na pewno chcesz przekierować do kolejki?"
}