export const pt_BR = {
    "ABANDONED": "ABANDONADO por",
    "ABANDONED_BY_AGENT": "ABANDONADO por {agent}",
    "ACCESS_RESTRICTED_CONTENT": "Acessar conteúdo restrito",
    "ACTION": "Ação",
    "ADDITIONAL_CONTACT_INFO_TITLE": "Informações adicionais de contato",
    "ADD_ANNOTATION_FAILURE": "Falha ao adicionar a anotação.",
    "ADD_ANNOTATION_INVALID": "Anotação inválida. Verifique se não há dados restritos na anotação.",
    "ADD_ANNOTATION_PLACEHOLDER": "Nova anotação",
    "ADD_ANNOTATION_SUCCESS": "Anotação adicionada.",
    "AGENT": "Agente",
    "AGENT_ANNOTATION_BADGE": "Agente",
    "AGENT_SELECTED_ISSUE": "Problema selecionado pelo agente",
    "AGENT_SELECTED_ISSUE_COPIED": "O problema selecionado pelo agente foi copiado.",
    "AMAZON_CONNECT_CALL_INFO": "Informações de chamadas do Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID": "ID de contato do Amazon Connect",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "ID de contato do Amazon Connect copiado",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "Amazon Logistics",
    "ANALYZED": "ANALISADO por",
    "ANALYZED_BY_AGENT": "ANALISADO por {agent}",
    "ANNOTATED_BY": "Anotado por {agentLogin}.",
    "ANNOTATIONS": "Anotações",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "As anotações não devem conter dados de PII do cliente, como números de telefone e endereços de e-mail. Nosso sistema automatizado tenta reforçar que, às vezes, não identifica a diferença entre PII e outros IDs. Informe o motivo pelo qual é necessária uma substituição.",
    "ANNOTATION_WARNING": "As anotações podem estar sujeitas a avaliação pelos clientes, de acordo com os regulamentos de privacidade. Não inclua informações pessoais ou opiniões no campo de anotações.",
    "ATTACHMENTS_RESTRICTED": "Os anexos são restritos.",
    "ATTACHMENTS_TITLE": "Anexos",
    "ATTACHMENT_DELETION_FAILED": "A exclusão de anexos falhou. Tente novamente mais tarde.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "Exclusão de anexos bem-sucedida.",
    "AUTHENTICATING": "Iniciada a autenticação. Isso pode abrir uma nova janela.",
    "AUTHENTICATION_FAILURE": "Houve um problema na autenticação.",
    "AUTHENTICATION_MESSAGE": "Obtendo novas credenciais. Esta janela se fechará em alguns segundos.",
    "AUTO_ANNOTATION_BADGE": "Automático",
    "BACK": "Voltar",
    "BCC_EMAIL_ADDRESS": "Cco",
    "BLURBS_USED_TITLE": "Sinopses usadas",
    "CALL_LEG": "ID da Call Leg",
    "CALL_LEG_ID_COPIED": "ID da perna de chamada copiado.",
    "CALL_RESTRICTED": "O conteúdo da chamada é restrito.",
    "CALL_RETENTION_DELETED": "O período de retenção do registro expirou e ele foi excluído.",
    "CALL_TITLE": "Falar com o Serviço de atendimento ao cliente da Amazon",
    "CANCEL": "Cancelar",
    "CC_EMAIL_ADDRESS": "Cc",
    "CF_LARGE_NUMBER_ALERT": "Esse cliente tem um número muito grande de contatos e nem todos foram carregados. Os contatos são sempre carregados na direção “recente para mais antigo” a partir da data de término. Altere a data de término para começar a carregar a partir de um ponto diferente no tempo.",
    "CHANNELS": "Canais",
    "CHAT_AGENT_CONNECTED": "{name} aceitou o chat.",
    "CHAT_CONTACT": "Contato do chat.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name} perdeu a conexão com a rede.",
    "CHAT_DISCONNECT_GENERIC": "{name} não está mais conectado.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name} perdeu a conexão com a rede.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name} perdeu a conexão com a rede.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name} saiu da conversa.",
    "CHAT_PARKED": "Este chat estava pausado",
    "CHAT_PARKED_NOTES": "Observações de travamento",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name} com suspensão desativada.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name} está suspenso(a).",
    "CHAT_RESTRICTED": "O conteúdo do chat é restrito.",
    "CHAT_TITLE": "Conversar com o Serviço de Atendimento ao Cliente da Amazon",
    "CHAT_TRANSFER_NOTES": "Transferir observações",
    "CHILD_WORK_REQUESTS": "Solicitações de trabalho criadas a partir desse contato",
    "CHOOSE_OPTIONS": "Escolher opções",
    "CLOSE": "Fechar",
    "CLOSE_ALERT": "Fechar alerta",
    "CLOSE_MODAL_BUTTON_TEXT": "Fechar",
    "COLLAPSED": "Recolhido",
    "CONFIRM": "Confirmar",
    "CONTACTS": "Contatos",
    "CONTACT_COLLAPSED": "Detalhes de contato recolhidos.",
    "CONTACT_CREATION_FAILURE": "Falha ao criar um novo contato",
    "CONTACT_EXPANDED": "Detalhes de contato expandidos.",
    "CONTACT_HISTORY": "Histórico de contato",
    "CONTACT_ID": "ID para contato",
    "CONTACT_ID_COPIED": "ID de contato copiado.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "Contato",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "Encaminhamentos",
    "CONTACT_REASSIGNED_TO_AGENT": "O contato foi reatribuído ao agente.",
    "CONTACT_SELECT_CHECKBOX": "Caixa de seleção para marcar um contato bloqueado",
    "CONTACT_STATUS_ESCALATION_BADGE": "Encaminhamento",
    "CONTACT_TICKET": "Tíquete de contato",
    "CONTACT_TICKET_CONTENT": "Conteúdo do tíquete",
    "CONTACT_TICKET_CREATE": "Criar tíquete de contato",
    "CONTACT_TICKET_CREATION_FAILURE": "Falha ao criar tíquete de contato",
    "CONTACT_TICKET_CREATION_SUCCESS": "Tíquete de contato criado",
    "CONTACT_TICKET_RESOLVER_GROUP": "Grupo solucionador",
    "CONTACT_TICKET_SEVERITY": "Gravidade do tíquete",
    "CONTACT_TICKET_SIM_FOLDER_ID": "ID da pasta SIM",
    "CONTACT_TICKET_SUBJECT": "Assunto do tíquete",
    "CONTACT_TIMELINE": "Cronograma de contato",
    "CONTACT_TYPE": "Tipo de contato",
    "CONTACT_WAS_TRANSFERRED": "O contato foi transferido.",
    "CONTENT": "Conteúdo",
    "CONTENT_RESTRICTED": "O conteúdo é restrito.",
    "COPY_AGENT_SELECTED_ISSUE": "Copiar o problema selecionado pelo agente: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "Copiar ID de contato do Amazon Connect",
    "COPY_CALL_LEG_ID": "Copiar ID da perna de chamada: {value}",
    "COPY_CONTACT_ID": "Copiar ID do contato: {value}",
    "COPY_CUSTOMER_ID": "Copiar ID do cliente",
    "COPY_CUSTOMER_SELECTED_ISSUE": "Copiar o problema selecionado pelo cliente: {value}",
    "COPY_FAILURE": "Falha ao copiar.",
    "COPY_ID": "Copiar ID",
    "COPY_LINK": "Copiar link de upload de imagem",
    "COPY_ORDER_ID": "Copiar ID do pedido: {value}.",
    "CREATE_CASE": "Criar um caso",
    "CREATE_FOLLOW_UP": "Criar acompanhamento",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "Acompanhamento criado com sucesso, mas não foi possível adicionar a anotação correspondente. Tente adicionar a anotação manualmente.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "Especifique o método de contato preferido pelo cliente.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "Não é possível criar um acompanhamento com uma data e hora de vencimento no passado.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "Especifique a data e a hora limites da ação de acompanhamento.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "Fuso horário",
    "CREATE_FOLLOW_UP_FAILURE": "Falha ao criar o acompanhamento.",
    "CREATE_FOLLOW_UP_INVALID": "Acompanhamento inválido. Certifique-se de que não há dados restritos.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "Insira a ação de acompanhamento.",
    "CREATE_FOLLOW_UP_SUCCESS": "Acompanhamento criado com sucesso.",
    "CREATE_WORK_REQUEST": "Criar solicitação de trabalho",
    "CROSS_MARKETPLACE_QUEUES": "Filas cruzadas do site da Amazon",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "Falha ao transferir o contato porque ele já foi resolvido.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "A operação falhou porque o contato está em um estado inválido.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "Falha na operação porque o contato não foi encontrado",
    "CSALT_ERROR_INTERNAL_ERROR": "Falha ao bloquear o contato",
    "CSALT_ERROR_INVALID_ARGUMENTS": "Falha ao bloquear um contato devido a argumentos inválidos",
    "CSALT_ERROR_INVALID_INPUT": "A operação falhou devido a entradas inválidas.",
    "CSALT_ERROR_INVALID_QUEUE": "Falha ao transferir o contato porque a fila selecionada é inválida.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "Falha na operação porque o Contato está bloqueado para um Agente diferente.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "Falha ao bloquear o contato devido a erro de dependência",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "Falha ao transferir o contato porque a fila não foi encontrada no serviço de back-end.",
    "CSALT_ERROR_UNKNOWN": "A operação falhou devido a um erro inesperado.",
    "CURRENT_CONTACT": "Contato atual",
    "CUSTOMER": "Cliente",
    "CUSTOMER_FOLLOWUPS": "Acompanhamentos para o cliente",
    "CUSTOMER_FOLLOWUPS_FAILURE": "Falha ao buscar acompanhamentos para o cliente",
    "CUSTOMER_ID_COPIED": "ID do cliente copiada",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "O conteúdo do cliente é restrito. (Sem contatos bloqueados.)",
    "CUSTOMER_SELECTED_ISSUE": "Problema selecionado pelo cliente",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "O problema selecionado pelo cliente foi copiado.",
    "CUSTOMER_TIMEZONE": "Registros de data/hora mostrados no fuso horário do cliente",
    "DATE": "Data",
    "DATE_PICKER_NEXT_MONTH": "Mês seguinte",
    "DATE_PICKER_PREVIOUS_MONTH": "Mês anterior",
    "DATE_PICKER_TODAY": "Hoje",
    "DECODING_AUDIO": "Decodificação de áudio",
    "DELETE_ATTACHMENTS": "Excluir anexos de contato",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "Excluir anexos",
    "DELIVERY_STATUS": "Status da entrega",
    "DISABLE_CONTENT_FILTER": "Desativar filtro de conteúdo",
    "DOWNLOAD": "Fazer download",
    "DURATION": "Duração",
    "EMAIL": "E-mail",
    "EMAIL_ADDRESS_NOT_ALLOWED": "O(s) endereço(s) de e-mail do destinatário não é permitido.",
    "EMAIL_BODY": "Corpo do e-mail",
    "EMAIL_CONTACT": "Contato de e-mail.",
    "EMAIL_HEADER": "Título",
    "EMAIL_QUEUE_TITLE": "Fila de e-mail",
    "EMAIL_RESTRICTED": "O conteúdo do e-mail é restrito.",
    "EMAIL_SENDING_FAILURE": "Falha ao enviar e-mail de saída.",
    "EMAIL_SUBJECT": "Assunto do e-mail",
    "EMAIL_TITLE": "E-mail com o serviço de atendimento ao cliente",
    "END": "Finalizar",
    "END_DATE": "Data de término",
    "ERROR": "Erro",
    "ERROR_FAILED_TO_LOAD_DATA": "Falha ao carregar o arquivo.",
    "ESCALATION_CONTACT_HANDLING": "Manuseio do contato de encaminhamento",
    "ESCALATION_TAB_LEAD_LOGIN": "Login de supervisor: {agentLogin}",
    "EXPANDED": "Expandido",
    "FAILED_TO_CREATE_WORK_REQUEST": "Falha ao criar solicitação de trabalho",
    "FAILED_TO_FETCH_SYSKA": "Falha ao buscar alertas SYSKA",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "Falha ao reatribuir o contato ao agente.",
    "FETCHED_NUMBER_CONTACTS": "{number} contatos obtidos.",
    "FILTER": "Filtrar",
    "FINDER": "Localizador",
    "FOLLOW_UPS_COLLAPSED": "Acompanhamentos recolhidos",
    "FOLLOW_UPS_EXPANDED": "Acompanhamentos expandidos",
    "FOLLOW_UP_ALERT": "tem um acompanhamento expirado em {dueDate} criado por {agentLogin}",
    "FOLLOW_UP_CONTACT_METHOD": "Método de contato",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "O método de contato é restrito.",
    "FOLLOW_UP_DUE": "Acompanhamento devido",
    "FOLLOW_UP_DUE_DATE": "Data de vencimento",
    "FOLLOW_UP_NOTE": "Observação",
    "FOLLOW_UP_NO_CONTACT_METHOD": "Nenhum método de contato",
    "FOLLOW_UP_NO_NOTE": "A observação está vazia",
    "FOLLOW_UP_NO_TICKET": "Sem tíquete",
    "FOLLOW_UP_OWNER": "Proprietário",
    "FOLLOW_UP_STATUS": "Status",
    "FOLLOW_UP_STATUS_OPEN": "Abrir",
    "FOLLOW_UP_STATUS_RESOLVED": "Resolvido",
    "FOLLOW_UP_TICKET": "Tíquete",
    "FOLLOW_UP_TITLE": "Acompanhamentos",
    "FROM": "De",
    "GENERATE_LINK": "Gerar link",
    "HAS_OVERRIDE_TRY_AGAIN": "Substituição de filtro presente. Tente novamente.",
    "HISTORY": "Histórico",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "link de anexo de imagem compartilhado com o cliente",
    "INPUT_CHARACTERS_REMAINING": "Máximo {char_max} caracteres ({char_remain} restante(s))",
    "INSERTED": "INSERIDO por",
    "INSERTED_BY_AGENT": "INSERIDO por {agent}",
    "INTERNAL_ERROR": "Erro interno",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "Foi encontrado um erro interno. Se o erro persistir, crie um tíquete explicando o problema.",
    "INVALID_CONTACT": "Contato inválido.",
    "INVALID_DATA_MATCH": "O sistema fez uma classificação inválida de dados.",
    "INVALID_TIMESTAMP": "Registro de data/hora inválido.",
    "IN_MARKETPLACE_QUEUES": "Nas filas do site da Amazon",
    "ISSUE": "Problema",
    "ISSUE_CODE": "Código de problema",
    "ISSUE_CODE_ALREADY_ADDRESSED": "Já processado e resolvido em um contato anterior",
    "ISSUE_CODE_DESCRIPTION": "Selecione o motivo para que os contatos selecionados sejam marcados como duplicados",
    "ISSUE_CODE_EXACTLY_SAME": "Exatamente o mesmo que um contato anterior",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "Processado e resolvido por outro CSA por chat",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "Processado e resolvido por outro CSA por e-mail",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "Processado e resolvido por outro CSA por telefone",
    "ITEMS_CONTACTED_ABOUT": "Itens sobre os quais gerou um contato",
    "I_UNDERSTAND": "Eu entendo",
    "KNOWN_ISSUES": "Problemas conhecidos",
    "LEARN_MORE": "Saiba mais",
    "LINK_COPIED": "Link copiado",
    "LOADING": "Carregando dados.",
    "LOADING_PREVIEW_TEXT": "Carregando visualização",
    "LOADING_RECORDING": "Carregando gravação",
    "LOAD_MORE": "Carregar mais",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "Carregar mais anotações.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "Carregue mais contatos para este cliente.",
    "LOCKED": "Bloqueado",
    "LOCKED_BY": "Bloqueado por",
    "LOCKED_BY_AGENT": "BLOQUEADO por {agent}",
    "LOCK_CONTACT": "Bloquear",
    "LOCK_CONTACT_NOT_AUTHORIZED": "Falha ao bloquear o contato, o usuário não está autorizado",
    "LOCK_CONTACT_SUCCESSFUL": "Contato bloqueado em",
    "MISSING_ORIGINAL_CONTACT_ID": "Insira o ID de contato original válido",
    "MODAL_NO_BUTTON_TEXT": "Não",
    "MODAL_YES_BUTTON_TEXT": "Sim",
    "NEXT": "Avançar",
    "NORMAL": "Normal",
    "NO_AGENT_SELECTED_ISSUE": "Nenhum problema selecionado pelo agente.",
    "NO_ANNOTATED_BY": "Anotado por usuário desconhecido.",
    "NO_ANNOTATIONS": "Não há anotações disponíveis.",
    "NO_ATTACHMENTS": "Não há anexos.",
    "NO_CHAT": "O chat está vazio.",
    "NO_CHILD_WORK_REQUESTS": "Nenhuma solicitação de trabalho criada a partir desse contato",
    "NO_CONTACT": "Nenhum contato disponível",
    "NO_CONTACTS_SELECTED": "Selecione contatos para processá-los como spam",
    "NO_CONTENT": "Sem conteúdo",
    "NO_CUSTOMER_SELECTED_ISSUE": "Nenhum problema selecionado pelo cliente.",
    "NO_DELIVERY_STATUS": "Sem status de entrega",
    "NO_EMAIL_BODY": "O e-mail está vazio.",
    "NO_EMAIL_HEADER": "Sem título de e-mail.",
    "NO_EMAIL_QUEUE": "Nenhuma fila de e-mail associada a este contato.",
    "NO_ITEMS_CONTACTED_ABOUT": "Nenhum item contatado.",
    "NO_RESULTS": "Nenhum resultado encontrado.",
    "NO_SCREEN_POP": "Nenhuma informação do screenpop",
    "NO_SENT_FROM": "Sem endereço de envio.",
    "NO_SENT_TO": "Nenhum endereço para envio",
    "NO_SUBJECT": "A linha de assunto está vazia.",
    "NO_SYSKA_ALERTS": "Sem alertas SYSKA",
    "NO_USED_BLURBS": "Nenhum blurbs usado para este contato.",
    "NO_VIEW": "Nenhum contato ou cliente para ver.",
    "NO_WORK_CATEGORY": "Nenhuma categoria de trabalho associada a este contato.",
    "NUMBER_OF_CONTACTS": "Contatos carregados",
    "OLDEST_LOADED_CONTACT": "Contato mais antigo carregado",
    "OPEN": "Aberto",
    "OPENS_IN_A_NEW_TAB": "Abre em uma nova aba",
    "ORDER_ID_COPIED": "ID do pedido copiado.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "Insira o ID de contato original",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "Caixa de texto para inserir o ID de contato original",
    "OTHER": "Outro",
    "OUTBOUND_EMAIL_BCC": "e o cco -",
    "OUTBOUND_EMAIL_CC": "Ao usar o cc -",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "Conteúdo da notificação recolhido.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "Conteúdo de notificação expandido.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "Número máximo de resultados alcançados. Reduza a faixa de datas e tente novamente.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "O processo atual requer conteúdo de PII.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "Correspondência inválida como número de telefone, endereço de e-mail ou outra PII.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "Correspondência inválida como cartão de crédito ou IBAN.",
    "OVERRIDE_REASON_PROMPT": "As anotações não devem conter dados de PII do cliente, como números de telefone e endereços de e-mail. Nosso sistema automatizado tenta reforçar que, às vezes, não identifica a diferença entre PII e outros IDs. Informe o motivo pelo qual uma substituição é necessária.",
    "PAPYRUS_SESSION_EXPLANATION": "Especifique seu tipo de sessão Papyrus para que possamos aplicar as restrições de segurança corretas e otimizar a UX para suas necessidades.",
    "PAPYRUS_SESSION_TYPE": "Tipo de sessão do Papyrus",
    "PAUSE": "Pausar",
    "PHONE_CONTACT": "Contato telefônico.",
    "PLAY": "Reproduzir",
    "PLAYBACK_SPEED": "Velocidade de reprodução",
    "POSTAL_MAIL_TITLE": "Correio postal com Serviço de atendimento ao cliente da Amazon",
    "PREPARING_RECORDING": "Preparando a gravação",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "Falha ao gerar visualização para o contato",
    "PREVIOUS": "Anterior",
    "PRE_TRANSLATED_REPLY_EMAIL": "E-mail de resposta pré-traduzido",
    "PRE_TRANSLATION_REPLY_TEXT": "Resposta tipo CSA antes da tradução de Rosetta",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "Processar ações",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "Processar como duplicado",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "Processar como spam",
    "PROCESS_CONTACT_AS_DUPLICATE": "Resolver contatos selecionados como duplicados",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "Tem certeza de que deseja processar contatos selecionados como spam?",
    "PROCESS_DUPLICATE_FAILURE": "Falha ao resolver contatos selecionados como duplicados",
    "PROCESS_DUPLICATE_SUCCESS": "Contatos selecionados como duplicados resolvidos com sucesso",
    "PROCESS_SPAM_FAILURE": "Falha ao transferir contatos selecionados para spam",
    "PROCESS_SPAM_SUCCESS": "Contatos selecionados transferidos com sucesso para spam",
    "PROVIDE_ADDITIONAL_INFORMATION": "Forneça mais informações relevantes para este contato",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "As informações confidenciais do contato foram removidas:",
    "PURGE_ADD_WORD_BUTTON_TEXT": "Adicionar palavra à lista de remoção",
    "PURGE_BUTTON_TEXT": "Remover",
    "PURGE_CONFIRMATION_CHECK": "Verifiquei os campos acima e confirmo que o texto realmente contém informações confidenciais.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "Confirmo que o anexo realmente contém informações confidenciais sobre o cliente.",
    "PURGE_CONFIRMATION_UNCHECKED": "Confirme a caixa de seleção.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "Falha ao remover do contato",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "Informações do contato removidas",
    "PURGE_INVALID_REASON": "{invalid_purge_selection} não é um motivo válido para a remoção",
    "PURGE_REASON_FINANCIAL_INFO": "Informações financeiras",
    "PURGE_REASON_OTHER": "Outras informações confidenciais",
    "PURGE_REASON_PII": "Informações de identificação pessoal",
    "PURGE_REASON_PLACEHOLDER": "Insira o motivo da remoção",
    "PURGE_REASON_TEXT": "Motivo da remoção",
    "PURGE_REMOVE_LABEL": "Excluir",
    "PURGE_SELECTOR_PLACEHOLDER": "Selecione o motivo para remover informações confidenciais",
    "PURGE_SENSITIVE_INFO": "Remoção de informações confidenciais",
    "PURGE_WORD_NOT_FOUND": "A palavra não é encontrada no texto do contato",
    "PURPOSE_AUDITING": "Auditoria para controle de qualidade",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "Fluxo de trabalho do back office",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "Esta é a sessão para obter dados de contato para fluxos de trabalho do back office. Por exemplo, anexos a serem incluídos em tíquetes ou SIM.",
    "PURPOSE_COACHING": "Coaching de avaliação de contatos",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "Investigação de conformidade",
    "PURPOSE_CURATION": "Curadoria de contato",
    "PURPOSE_CURATION_DESCRIPTION": "Esta é a sessão para curadoria de contato C2CS/VoC.",
    "PURPOSE_DESCRIPTION_AUDITING": "Esta é a sessão para auditoria de contatos e avaliação de sua qualidade.",
    "PURPOSE_DESCRIPTION_COACHING": "Esta é a sessão para avaliar os contatos de agentes dos quais você é um instrutor do Portal de Desempenho.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "Esta é a sessão para investigações de monitoramento de atividades suspeitas.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "Esta é a sessão para lidar com contatos de encaminhamento. Isso permite acessar os dados de contato de qualquer cliente.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "Esta é a sessão para lidar com contatos ao vivo.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "A finalidade é remover informações confidenciais de um contato",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "Esta é a sessão para avaliação da precisão do modelo de aprendizado de máquina.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "Esta é a sessão para avaliar seus próprios contatos anteriores.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "Esta é a sessão de propósito geral padrão.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "Lidando com contato de encaminhamento",
    "PURPOSE_PURGE_SENSITIVE_INFO": "Remoção de informações confidenciais",
    "REASON_FOR_PURGE": "Motivo da remoção",
    "REASON_FOR_PURGE_DESCRIPTION": "Motivo para remover anexos",
    "REASON_FOR_PURGE_MISSING": "Insira o motivo para excluir anexos.",
    "REASSIGN": "REATRIBUIR até",
    "REASSIGNED_BY_AGENT": "REATRIBUIDO por {agent}",
    "REASSIGN_TO_AGENT": "Reatribuir ao agente",
    "RECORDING": "Gravação",
    "RELOADING_CONTENT": "Recarregando conteúdo.",
    "RELOAD_LIST": "Recarregar lista",
    "REPLIED": "RESPONDIDO por",
    "REPLIED_BY_AGENT": "RESPONDIDO por {agent}",
    "REPLY_BY": "Responder em",
    "REPLY_BY_EMAIL": "Responder por e-mail",
    "REPLY_BY_PHONE": "Responder por telefone",
    "REPLY_RESTRICTED": "O conteúdo da resposta é restrito.",
    "REQUEST": "Solicitação",
    "REQUEST_ACCESS_INSTRUCTIONS": "Informe o motivo pelo qual o acesso a conteúdo restrito é necessário. (Estas solicitações são monitoradas, e o abuso pode ter consequências graves.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "Solicitar acesso ao conteúdo filtrado",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "Solicitar acesso a conteúdo restrito",
    "REQUEST_NOT_GRANTED": "O pedido não foi concedido.",
    "REQUEST_OVERRIDE": "Substituição de solicitação",
    "RESEND": "Reenviar",
    "RESEND_FAILED": "Falha ao reenviar e-mail.",
    "RESEND_SUCCESS": "E-mail reenviado com sucesso.",
    "RESEND_WAIT_WARNING": "Você deve esperar {wait_mins} minutos para reenviar novamente.",
    "RESEND_WARNING": "Tem certeza de que deseja reenviar o e-mail para o cliente?",
    "RESET": "Redefinir",
    "RESOLVED": "Resolvido",
    "RESOLVED_BY": "Resolvido por",
    "RESOLVED_BY_AGENT": "RESOLVIDO por {agent}",
    "RESTRICTED_DATA_MATCH": "{match} é do tipo de correspondência {type}",
    "RESTRICTED_INFORMATION": "INFORMAÇÕES RESTRITAS",
    "RETRY": "Tentar novamente",
    "ROLE": "Função",
    "SCREEN_POP_DESCRIPTION_TITLE": "Descrição",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "Não",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "Sim",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "Exibido ao agente",
    "SCREEN_POP_TITLE": "Informações sobre ScreenPop",
    "SCREEN_POP_TYPE_AUTHENTICATING": "A autenticação do screenpop foi exibida para o agente e foi usada.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "A autenticação do screenpop foi exibida para o agente.",
    "SCREEN_POP_TYPE_CALLER_ID": "O screenpop do ID do chamador foi exibido para o agente e foi usado.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "O screenpop do ID do chamador foi exibido para o agente.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "O screenpop curvado, não autenticado foi exibido, e foi usado.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "Um screenpop curvado, não autenticado foi exibido para o agente.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "O cliente é desconhecido; nenhum screenpop foi fornecido.",
    "SCREEN_POP_TYPE_OTHER": "Outros",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "Não",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "Sim",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "Usado pelo agente",
    "SDS_TOOLS": "Ferramentas de suporte de entrega e envio",
    "SEARCH": "Pesquisar",
    "SELECTED_ROLE": "Função selecionada",
    "SELECTED_VIEW": "Visualização selecionada",
    "SELECT_CHANNELS": "Selecionar canais",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "Para selecionar o código de problema para marcar contatos selecionados como duplicados",
    "SELECT_TRANSFER_DESTINATION": "Selecione o destino da transferência",
    "SELECT_WORK_REQUEST_QUEUE": "Selecionar fila de solicitações de trabalho",
    "SENDER_EMAIL_ADDRESS": "Remetente",
    "SENDING_EMAIL": "Enviando e-mail",
    "SEND_EMAIL": "Enviar e-mail",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "Tem certeza de que deseja enviar o link de upload de imagem para o cliente?",
    "SEND_OUTBOUND_EMAIL": "Enviar e-mail de saída",
    "SEND_OUTBOUND_EMAIL_WARNING": "Tem certeza de que deseja enviar e-mail de saída para",
    "SENT_FROM": "Enviado de",
    "SENT_OUTBOUND_EMAIL": "O e-mail de saída foi enviado. Um novo contato foi criado",
    "SENT_TO": "Enviado para",
    "SHIPPER_PORTAL_BUTTON_TEXT": "Portal da transportadora",
    "SKILL": "Habilidade",
    "SKIP_SILENCES": "Pular silêncios",
    "SMS": "Mensagem de texto",
    "SMS_LINK": "Link de SMS",
    "SOURCE_TEXT": "Texto original",
    "SP_BOTTOM": "Inferior",
    "SP_POSITION": "Posição do painel dividido",
    "SP_PREFERENCES": "Preferências de painel dividido",
    "SP_RESIZE": "Redimensionar painel dividido",
    "SP_SIDE": "Lateral",
    "START": "Iniciar",
    "STATE_TRANSITIONS": "Transições de estado",
    "STATUS": "Status",
    "SUBJECT": "Assunto",
    "SYSKA_ALERTS": "Alertas SYSKA",
    "TEXT_TO_PURGE": "Texto a ser removido",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "Os cookies de terceiros devem estar ativados.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "Os dados de PII do cliente, como números de telefone e endereços de e-mail, não são permitidos durante a criação de um tíquete. Nosso sistema automatizado tenta aplicar isso. No entanto, às vezes ele não identifica a diferença entre PII e outros IDs. Informe o motivo pelo qual uma substituição é necessária.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "Tempo esgotado: falha ao carregar a gravação",
    "TIME_TEXT_FORMAT_CONSTRAINT": "Use o formato de 24 horas.",
    "TO": "Para",
    "TO_EMAIL_ADDRESS": "Para",
    "TRANSFER": "Transferir",
    "TRANSFERRED": "TRANSFERIDO por",
    "TRANSFERRED_BY_AGENT": "TRANSFERIDO por {agent}",
    "TRANSFERRED_CHAT_TITLE": "Conversa transferida para o Serviço de Atendimento ao Cliente da Amazon",
    "TRANSFERRING": "Transferindo…",
    "TRANSFER_CONTACT": "Transferir contato",
    "TRANSFER_CONTACT_COMMENTS": "Anotação opcional",
    "TRANSFER_FAILED": "Falha na transferência",
    "TRANSFER_FOLLOW_UP": "Acompanhamento de transferência",
    "TRANSFER_FOLLOW_UP_FAILED": "Falha ao transferir o acompanhamento.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "Falha ao transferir o acompanhamento devido ao login inválido do novo proprietário.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "Você não tem permissão para transferir este acompanhamento.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "Insira o nome de login do novo proprietário. Exemplo: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "Transferir o acompanhamento acima para:",
    "TRANSFER_FOLLOW_UP_SUCCESS": "Acompanhamento transferido com sucesso.",
    "TRANSLATED_TEXT": "Texto traduzido",
    "TRANSLATION": "Tradução",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue} não é um nome de fila válido.",
    "TYPE_AGENT_LOGIN": "Digite o login do agente",
    "TYPE_EMAIL_ADDRESS": "Digite o endereço de e-mail",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "Digite vários endereços de e-mail separados por vírgula",
    "TYPE_QUEUE_NAME": "Digite o nome da fila",
    "UNKNOWN_CONTACT_TYPE": "Tipo de contato desconhecido.",
    "UNLOCKED": "Desbloqueado",
    "UNRESOLVED": "Não resolvido",
    "URGENT_SYSKA_ALERTS": "Alertas SYSKA urgentes",
    "USE": "Usar",
    "USER_TIMEZONE": "Registros de data/hora mostrados no fuso horário local",
    "USE_CONTACT_FINDER": "Usar o localizador de contatos",
    "VIEW": "Ver",
    "WARNING": "Aviso",
    "WELCOME_MESSAGE": "Bem-vindo ao site Papyrus Static!",
    "WHAT_IS_PAPYRUS": "O que é Papyrus?",
    "WITH_THE_ANNOTATION": "com a anotação",
    "WORK_CATEGORY_TITLE": "Categoria de trabalho",
    "WORK_REQUEST": "Solicitação de trabalho",
    "WORK_REQUEST_CONTENT": "Conteúdo da solicitação de trabalho",
    "WORK_REQUEST_CREATED": "Solicitação de trabalho criada",
    "WORK_REQUEST_FOR_CONTACT": "Solicitação de trabalho para contato",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "Os dados de PII do cliente, como números de telefone e endereços de e-mail, não são permitidos durante a criação de uma solicitação de trabalho. Nosso sistema automatizado tenta aplicar isso. No entanto, às vezes ele não identifica a diferença entre PII e outros IDs. Informe o motivo pelo qual uma substituição é necessária.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "Você está prestes a transferir para a fila"
}