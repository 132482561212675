export const ko_KR = {
    "ACCESS_RESTRICTED_CONTENT": "제한된 콘텐츠 액세스",
    "ACTION": "작업",
    "ADD": "추가",
    "ADDITIONAL_CONTACT_INFO_TITLE": "추가 연락처 정보",
    "ADD_ANNOTATION_FAILURE": "주석을 추가하지 못했습니다.",
    "ADD_ANNOTATION_INVALID": "유효하지 않은 주석입니다. 주석에 제한된 데이터가 없는지 확인하십시오.",
    "ADD_ANNOTATION_PLACEHOLDER": "새 주석",
    "ADD_ANNOTATION_SUCCESS": "주석을 추가했습니다.",
    "AGENT": "에이전트",
    "AGENT_ANNOTATION_BADGE": "에이전트",
    "AGENT_SELECTED_ISSUE": "에이전트가 선택한 이슈",
    "AGENT_SELECTED_ISSUE_COPIED": "에이전트가 선택한 이슈를 복사했습니다.",
    "AMAZON_CONNECT_CALL_INFO": "아마존 Connect 통화 정보",
    "AMAZON_CONNECT_CONTACT_ID": "아마존 Connect 문의자 ID",
    "AMAZON_CONNECT_CONTACT_ID_COPIED": "아마존 Connect 문의자 ID 복사됨",
    "AMAZON_LOGISTICS_BUTTON_TEXT": "아마존 로지스틱스",
    "ANNOTATED_BY": "{agentLogin}이(가) 주석을 달았습니다.",
    "ANNOTATIONS": "주석",
    "ANNOTATION_OVERRIDE_REASON_PROMPT": "주석에는 전화 번호 및 이메일 주소와 같은 고객 PII 데이터를 포함할 수 없습니다. 자동화된 시스템이 PII와 다른 ID의 차이를 인식하지 못하는 경우도 있습니다. 재정의가 필요한 이유를 제공하십시오.",
    "ANNOTATION_WARNING": "주석은 개인정보 보호 규정에 따라 고객의 검토 대상이 될 수 있습니다. 주석 필드에 개인 정보 또는 의견을 포함하지 마세요.",
    "ATTACHMENTS_RESTRICTED": "첨부 파일은 제한되어 있습니다.",
    "ATTACHMENTS_TITLE": "첨부 파일",
    "ATTACHMENT_DELETION_FAILED": "첨부 파일을 삭제하지 못했습니다. 나중에 다시 시도하세요.",
    "ATTACHMENT_DELETION_SUCCESSFUL": "첨부 파일이 삭제되었습니다.",
    "AUTHENTICATING": "인증을 시작했습니다. 새 창이 열릴 수 있습니다.",
    "AUTHENTICATION_FAILURE": "인증하는 데 문제가 발생했습니다.",
    "AUTHENTICATION_MESSAGE": "새 자격 증명을 가져오는 중입니다. 이 창은 몇 초 안에 닫힙니다.",
    "AUTO_ANNOTATION_BADGE": "자동",
    "BACK": "뒤로",
    "BCC_EMAIL_ADDRESS": "숨은 참조",
    "BLURBS_USED_TITLE": "사용된 안내문",
    "CALL_LEG": "통화 연결 ID",
    "CALL_LEG_ID_COPIED": "통화 레그 ID가 복사되었습니다.",
    "CALL_RESTRICTED": "통화 콘텐츠가 제한되어 있습니다.",
    "CALL_RETENTION_DELETED": "기록 보존 기간이 지나 해당 기록이 삭제되었습니다.",
    "CALL_TITLE": "Amazon 고객 서비스와 통화",
    "CANCEL": "취소",
    "CC_EMAIL_ADDRESS": "참조",
    "CF_LARGE_NUMBER_ALERT": "이 고객은 매우 많은 수의 연락처를 보유하고 있으며 일부 연락처가 로드되지 않았습니다. 연락처는 항상 종료 날짜부터 시작하여 ‘최근부터 오래된’ 순으로 로드됩니다. 다른 시점부터 로드를 시작하려면 종료 날짜를 변경하세요.",
    "CHANNELS": "채널",
    "CHAT_AGENT_CONNECTED": "{name}이(가) 채팅을 수락했습니다.",
    "CHAT_CONTACT": "채팅 문의자.",
    "CHAT_DISCONNECT_GACD_HANG_UP": "{name}와(과) 네트워크의 연결이 끊어졌습니다.",
    "CHAT_DISCONNECT_GENERIC": "{name}이(가) 더 이상 연결되어 있지 않습니다.",
    "CHAT_DISCONNECT_MESSAGE_TRANSPORT_ERROR": "{name}와(과) 네트워크의 연결이 끊어졌습니다.",
    "CHAT_DISCONNECT_PUSH_SERVICE_ERROR": "{name}와(과) 네트워크의 연결이 끊어졌습니다.",
    "CHAT_DISCONNECT_USER_HANG_UP": "{name}님이 대화에서 나갔습니다.",
    "CHAT_PARKED": "이 채팅이 보류되었습니다.",
    "CHAT_PARKED_NOTES": "지연 안내",
    "CHAT_PARTICIPANT_OFF_HOLD": "{name}이(가) 보류 해제되어 있습니다.",
    "CHAT_PARTICIPANT_ON_HOLD": "{name}이(가) 보류 중입니다.",
    "CHAT_RESTRICTED": "채팅 콘텐츠가 제한되어 있습니다.",
    "CHAT_TITLE": "Amazon 고객 서비스와 채팅",
    "CHAT_TRANSFER_NOTES": "메모 전달",
    "CHILD_WORK_REQUESTS": "이 연락처에서 생성된 작업 요청",
    "CHOOSE_OPTIONS": "옵션 선택",
    "CLOSE": "닫기",
    "CLOSE_ALERT": "알림 닫기",
    "CLOSE_MODAL_BUTTON_TEXT": "닫기",
    "COLLAPSED": "축소됨",
    "CONFIRM": "확인",
    "CONTACTS": "연락처",
    "CONTACT_COLLAPSED": "문의자 세부 정보가 축소되었습니다.",
    "CONTACT_CREATION_FAILURE": "새 연락처 생성 실패",
    "CONTACT_EXPANDED": "연락처 세부 정보가 확장되었습니다.",
    "CONTACT_HISTORY": "문의 내역",
    "CONTACT_ID": "문의자 ID",
    "CONTACT_ID_COPIED": "문의자 ID를 복사했습니다.",
    "CONTACT_MEDIUM_DEFAULT_TAB": "문의자",
    "CONTACT_MEDIUM_ESCALATIONS_TAB": "에스컬레이션",
    "CONTACT_REASSIGNED_TO_AGENT": "담당자에게 연락처가 재할당되었습니다.",
    "CONTACT_SELECT_CHECKBOX": "잠긴 문의를 선택하는 체크박스",
    "CONTACT_STATUS_ESCALATION_BADGE": "에스컬레이션",
    "CONTACT_TICKET": "문의자 티켓",
    "CONTACT_TICKET_CONTENT": "티켓 내용",
    "CONTACT_TICKET_CREATE": "문의자 티켓 생성",
    "CONTACT_TICKET_CREATION_FAILURE": "문의자 티켓 생성 실패",
    "CONTACT_TICKET_CREATION_SUCCESS": "문의자 티켓 생성됨",
    "CONTACT_TICKET_RESOLVER_GROUP": "해결자 그룹",
    "CONTACT_TICKET_SEVERITY": "티켓 심각도",
    "CONTACT_TICKET_SIM_FOLDER_ID": "SIM 폴더 ID",
    "CONTACT_TICKET_SUBJECT": "티켓 제목",
    "CONTACT_TIMELINE": "연락 일정표",
    "CONTACT_TYPE": "연락처 유형",
    "CONTACT_WAS_TRANSFERRED": "연락처가 전송되었습니다.",
    "CONTENT": "콘텐츠",
    "CONTENT_RESTRICTED": "콘텐츠가 제한됩니다.",
    "COPY_AGENT_SELECTED_ISSUE": "에이전트가 선택한 이슈 복사: {value}",
    "COPY_AMAZON_CONNECT_CONTACT_ID": "아마존 Connect 문의자 ID 복사",
    "COPY_CALL_LEG_ID": "통화 레그 ID 복사: {value}",
    "COPY_CONTACT_ID": "문의자 ID 복사: {value}",
    "COPY_CUSTOMER_ID": "고객 ID 복사",
    "COPY_CUSTOMER_SELECTED_ISSUE": "고객이 선택한 이슈 복사: {value}",
    "COPY_FAILURE": "복사하지 못했습니다.",
    "COPY_ID": "ID 복사",
    "COPY_LINK": "이미지 업로드 링크 복사",
    "COPY_ORDER_ID": "주문 ID 복사: {value}.",
    "CREATE_CASE": "사례 생성",
    "CREATE_FOLLOW_UP": "후속 작업 생성하기",
    "CREATE_FOLLOW_UP_ANNOTATION_ERROR": "후속 작업을 성공적으로 작성했지만 해당 주석을 추가할 수 없습니다. 주석을 수동으로 다시 추가합니다.",
    "CREATE_FOLLOW_UP_CONTACT_METHOD_DESCRIPTION": "고객이 선호하는 연락 방법을 지정합니다.",
    "CREATE_FOLLOW_UP_DATE_IN_THE_PAST": "지나간 날짜와 시간으로 후속 작업을 생성할 수 없습니다.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION": "후속 작업이 만기되는 날짜 및 시간을 지정합니다.",
    "CREATE_FOLLOW_UP_DUE_DATE_DESCRIPTION_TIMEZONE": "시간대",
    "CREATE_FOLLOW_UP_FAILURE": "후속 작업을 생성하지 못했습니다.",
    "CREATE_FOLLOW_UP_INVALID": "잘못된 후속 작업입니다. 제한된 데이터가 없는지 확인하십시오.",
    "CREATE_FOLLOW_UP_NOTE_DESCRIPTION": "후속 작업을 입력합니다.",
    "CREATE_FOLLOW_UP_SUCCESS": "후속 작업을 성공적으로 생성했습니다.",
    "CREATE_WORK_REQUEST": "작업 요청 생성",
    "CROSS_MARKETPLACE_QUEUES": "교차 마켓플레이스 대기열",
    "CSALT_ERROR_CONTACT_ALREADY_RESOLVED": "이미 해결되었기 때문에 연락처를 전송하지 못했습니다.",
    "CSALT_ERROR_CONTACT_INVALID_STATE": "연락처가 잘못된 상태이기 때문에 작업이 실패했습니다.",
    "CSALT_ERROR_CONTACT_NOT_FOUND": "연락처를 찾을 수 없어 작업이 실패했습니다.",
    "CSALT_ERROR_INTERNAL_ERROR": "연락처를 잠그지 못했습니다.",
    "CSALT_ERROR_INVALID_ARGUMENTS": "유효하지 않은 인수로 인해 연락처를 잠그지 못했습니다.",
    "CSALT_ERROR_INVALID_INPUT": "잘못된 입력으로 인해 작업이 실패했습니다.",
    "CSALT_ERROR_INVALID_QUEUE": "선택한 대기열이 잘못되어 연락처를 전송하지 못했습니다.",
    "CSALT_ERROR_LOCKED_TO_OTHER_USER": "다른 담당자에 대해 연락처가 잠겨 있어 작업이 실패했습니다.",
    "CSALT_ERROR_LOCK_CONTACTS_DEPENDENCY_ERROR": "종속성 오류로 인해 연락처를 잠그지 못했습니다.",
    "CSALT_ERROR_QUEUE_NOT_FOUND": "백엔드 서비스에서 대기열을 찾을 수 없기 때문에 연락처를 전송하지 못했습니다.",
    "CSALT_ERROR_UNKNOWN": "예기치 않은 오류로 인해 작업이 실패했습니다.",
    "CURRENT_CONTACT": "현재 문의자",
    "CUSTOMER": "고객",
    "CUSTOMER_FOLLOWUPS": "고객을 위한 후속 작업",
    "CUSTOMER_FOLLOWUPS_FAILURE": "고객에 대한 후속 작업을 가져오지 못했습니다.",
    "CUSTOMER_ID_COPIED": "고객 ID가 복사되었습니다.",
    "CUSTOMER_RESTRICTED_NO_LOCKED": "고객 콘텐츠가 제한되어 있습니다. (잠긴 연락처가 없습니다.)",
    "CUSTOMER_SELECTED_ISSUE": "고객이 선택한 이슈",
    "CUSTOMER_SELECTED_ISSUE_COPIED": "고객이 선택한 이슈를 복사했습니다.",
    "CUSTOMER_TIMEZONE": "고객의 시간대에 표시된 타임스탬프",
    "DATE": "날짜",
    "DATE_PICKER_NEXT_MONTH": "다음 달",
    "DATE_PICKER_PREVIOUS_MONTH": "지난달",
    "DATE_PICKER_TODAY": "오늘",
    "DECODING_AUDIO": "오디오 디코딩",
    "DELETE_ATTACHMENTS": "연락처 첨부 파일 삭제",
    "DELETE_ATTACHMENTS_BUTTON_TEXT": "첨부 파일 삭제",
    "DELIVERY_STATUS": "배송 상태",
    "DISABLE_CONTENT_FILTER": "콘텐츠 필터 비활성화",
    "DOWNLOAD": "다운로드",
    "DURATION": "기간",
    "EMAIL": "이메일",
    "EMAIL_ADDRESS_NOT_ALLOWED": "수신자 이메일 주소는 허용되지 않습니다.",
    "EMAIL_BODY": "이메일 본문",
    "EMAIL_CONTACT": "이메일 문의자.",
    "EMAIL_HEADER": "헤더",
    "EMAIL_QUEUE_TITLE": "이메일 대기열",
    "EMAIL_RESTRICTED": "이메일 콘텐츠가 제한되어 있습니다.",
    "EMAIL_SENDING_FAILURE": "아웃바운드 이메일을 보내지 못했습니다.",
    "EMAIL_SUBJECT": "이메일 제목",
    "EMAIL_TITLE": "Amazon 고객 서비스 팀에 이메일 보내기",
    "END": "종료",
    "END_DATE": "종료 날짜",
    "ERROR": "오류",
    "ERROR_FAILED_TO_LOAD_DATA": "데이터를 로드하지 못했습니다.",
    "ESCALATION_CONTACT_HANDLING": "에스컬레이션 문의 처리",
    "ESCALATION_TAB_LEAD_LOGIN": "리드 로그인: {agentLogin}",
    "EXPANDED": "확장됨",
    "FAILED_TO_CREATE_WORK_REQUEST": "작업 요청을 생성하지 못했습니다",
    "FAILED_TO_FETCH_SYSKA": "SYSKA 알림을 가져오지 못함",
    "FAILED_TO_REASSIGN_CONTACT_TO_AGENT": "담당자에게 연락처를 재할당하지 못했습니다.",
    "FETCHED_NUMBER_CONTACTS": "{number}개 연락처를 가져왔습니다.",
    "FILTER": "필터",
    "FINDER": "검색기",
    "FOLLOW_UPS_COLLAPSED": "후속 조치 축소됨",
    "FOLLOW_UPS_EXPANDED": "후속 조치 확장됨",
    "FOLLOW_UP_ALERT": "{dueDate}에 만료되고 {agentLogin}이(가) 생성한 후속 작업이 있습니다.",
    "FOLLOW_UP_CONTACT_METHOD": "문의 방법",
    "FOLLOW_UP_CONTACT_METHOD_RESTRICTED": "문의 방법이 제한되어 있습니다.",
    "FOLLOW_UP_DUE": "후속 조치 기한",
    "FOLLOW_UP_DUE_DATE": "기한",
    "FOLLOW_UP_NOTE": "메모",
    "FOLLOW_UP_NO_CONTACT_METHOD": "문의 방법 없음",
    "FOLLOW_UP_NO_NOTE": "메모가 비어 있습니다.",
    "FOLLOW_UP_NO_TICKET": "티켓 없음",
    "FOLLOW_UP_OWNER": "소유자",
    "FOLLOW_UP_STATUS": "상태",
    "FOLLOW_UP_STATUS_OPEN": "열기",
    "FOLLOW_UP_STATUS_RESOLVED": "해결됨",
    "FOLLOW_UP_TICKET": "티켓",
    "FOLLOW_UP_TITLE": "후속 조치",
    "FROM": "시작 지점",
    "GENERATE_LINK": "링크 생성",
    "HAS_OVERRIDE_TRY_AGAIN": "필터 재정의가 존재합니다. 다시 시도하십시오.",
    "IMAGE_UPLOAD_LINK_ANNOTATION": "고객과 공유되는 이미지 첨부 링크",
    "INPUT_CHARACTERS_REMAINING": "최대 {char_max} 자({char_remain} 남음)",
    "INTERNAL_ERROR": "내부 오류",
    "INTERNAL_ERROR_DISPLAY_MESSAGE": "내부 오류가 발생했습니다. 오류가 계속되면 문제를 설명하는 티켓을 보내주세요.",
    "INVALID_CONTACT": "잘못된 연락처.",
    "INVALID_DATA_MATCH": "시스템에서 잘못된 데이터 분류를 수행했습니다.",
    "INVALID_TIMESTAMP": "잘못된 타임스탬프입니다.",
    "IN_MARKETPLACE_QUEUES": "마켓플레이스 대기열",
    "ISSUE": "문제",
    "ISSUE_CODE": "이슈 코드",
    "ISSUE_CODE_ALREADY_ADDRESSED": "이전 문의에서 이미 대응 및 해결됨",
    "ISSUE_CODE_DESCRIPTION": "선택한 문의를 중복으로 표시하는 이유 선택",
    "ISSUE_CODE_EXACTLY_SAME": "이전 문의와 정확히 동일함",
    "ISSUE_CODE_RESOLVED_BY_OTHER_CHAT_CSA": "다른 채팅 CSA가 처리 및 해결함",
    "ISSUE_CODE_RESOLVED_BY_OTHER_EMAIL_CSA": "다른 이메일 CSA가 처리 및 해결함",
    "ISSUE_CODE_RESOLVED_BY_OTHER_PHONE_CSA": "다른 전화 CSA가 처리 및 해결함",
    "ITEMS_CONTACTED_ABOUT": "문의 대상 상품",
    "I_UNDERSTAND": "이해합니다",
    "KNOWN_ISSUES": "알려진 문제",
    "LEARN_MORE": "자세히 알아보기",
    "LINK_COPIED": "링크 복사됨",
    "LOADING": "데이터 로딩 중.",
    "LOADING_PREVIEW_TEXT": "미리보기 로딩 중",
    "LOADING_RECORDING": "레코딩 로딩 중",
    "LOAD_MORE": "더 로드하기",
    "LOAD_MORE_ANNOTATIONS_BUTTON_LABEL": "더 많은 주석 로드.",
    "LOAD_MORE_CONTACTS_BUTTON_LABEL": "이 고객에 대한 연락처를 더 로드합니다.",
    "LOCKED": "잠김",
    "LOCKED_BY": "잠근 사용자",
    "LOCK_CONTACT": "잠금",
    "LOCK_CONTACT_NOT_AUTHORIZED": "연락처를 잠그지 못했습니다. 사용자가 승인되지 않았습니다.",
    "LOCK_CONTACT_SUCCESSFUL": "다음에 대해 연락처가 잠김",
    "MISSING_ORIGINAL_CONTACT_ID": "유효한 최초 문의 ID 입력",
    "MODAL_NO_BUTTON_TEXT": "아니요",
    "MODAL_YES_BUTTON_TEXT": "예",
    "NEXT": "다음",
    "NORMAL": "보통",
    "NO_AGENT_SELECTED_ISSUE": "에이전트가 선택한 이슈가 없습니다.",
    "NO_ANNOTATED_BY": "알 수 없는 사용자가 주석을 달았습니다.",
    "NO_ANNOTATIONS": "사용 가능한 주석이 없습니다.",
    "NO_ATTACHMENTS": "첨부 파일이 없습니다.",
    "NO_CHAT": "채팅이 비어 있습니다.",
    "NO_CHILD_WORK_REQUESTS": "이 연락처에서 생성된 작업 요청이 없습니다.",
    "NO_CONTACT": "사용 가능한 연락처 없음",
    "NO_CONTACTS_SELECTED": "스팸으로 처리할 연락처를 선택하십시오.",
    "NO_CONTENT": "콘텐츠 없음",
    "NO_CUSTOMER_SELECTED_ISSUE": "고객이 선택한 이슈가 없습니다.",
    "NO_DELIVERY_STATUS": "배송 상태 없음",
    "NO_EMAIL_BODY": "이메일이 비어 있습니다.",
    "NO_EMAIL_HEADER": "이메일 헤더 없음.",
    "NO_EMAIL_QUEUE": "이 연락처에 연결된 이메일 대기열이 없습니다.",
    "NO_ITEMS_CONTACTED_ABOUT": "문의된 상품이 없습니다.",
    "NO_RESULTS": "결과가 없습니다.",
    "NO_SCREEN_POP": "스크린팝 정보 없음",
    "NO_SENT_FROM": "보낸 사람 주소가 없습니다.",
    "NO_SENT_TO": "보낸 주소 없음",
    "NO_SUBJECT": "제목 줄이 비어 있습니다.",
    "NO_SYSKA_ALERTS": "SYSKA 알림 없음",
    "NO_USED_BLURBS": "이 문의자에 사용된 안내문이 없습니다.",
    "NO_VIEW": "볼 수 있는 문의자 또는 고객이 없습니다.",
    "NO_WORK_CATEGORY": "이 연락처와 연관된 작업 카테고리가 없습니다.",
    "NUMBER_OF_CONTACTS": "로드된 연락처",
    "OLDEST_LOADED_CONTACT": "가장 오래된 로드된 연락처",
    "OPEN": "열기",
    "OPENS_IN_A_NEW_TAB": "새 탭에서 열림",
    "ORDER_ID_COPIED": "주문 ID가 복사되었습니다.",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT": "최초 문의 ID 입력",
    "ORIGINAL_CONTACT_ID_PLACEHOLDER_TEXT_BOX": "최초 문의 ID를 입력하는 텍스트 박스",
    "OTHER": "기타",
    "OUTBOUND_EMAIL_BCC": "및 참조 -",
    "OUTBOUND_EMAIL_CC": "참조하는 동안",
    "OUTBOUND_NOTIFICATION_COLLAPSED": "알림 콘텐츠가 축소되었습니다.",
    "OUTBOUND_NOTIFICATION_EXPANDED": "알림 콘텐츠가 확장되었습니다.",
    "OUTBOUND_NOTIFICATION_MAX_RESULTS": "최대 결과 수에 도달했습니다. 시간 범위를 줄이고 다시 시도하세요.",
    "OVERRIDE_REASON_CURRENT_PROCESS_REQUIRES_PII": "현재 프로세스에는 PII 콘텐츠가 필요합니다.",
    "OVERRIDE_REASON_INVALID_MATCH_PII": "전화 번호, 이메일 주소 또는 기타 PII와 일치하는 항목이 잘못되었습니다.",
    "OVERRIDE_REASON_INVALID_MATCH_RESTRICTED": "신용 카드 또는 IBAN과 일치하는 항목이 잘못되었습니다.",
    "OVERRIDE_REASON_PROMPT": "주석에는 전화 번호 및 이메일 주소와 같은 고객 PII 데이터를 포함할 수 없습니다. 자동화된 시스템이 PII와 다른 ID 간 차이를 인식하지 못하는 경우도 있습니다. 재정의가 필요한 이유를 제공하십시오.",
    "PAPYRUS_SESSION_EXPLANATION": "적절한 보안 제한을 적용하고 필요에 맞게 UX를 최적화할 수 있도록 Papyrus 세션 유형을 지정하십시오.",
    "PAPYRUS_SESSION_TYPE": "Papyrus 세션 유형",
    "PAUSE": "일시 중지",
    "PHONE_CONTACT": "전화 문의자.",
    "PLAY": "재생",
    "PLAYBACK_SPEED": "재생 속도",
    "POSTAL_MAIL_TITLE": "아마존 고객 서비스 우편",
    "PREPARING_RECORDING": "기록 준비 중",
    "PREVIEW_PURGE_FROM_CONTACT_TEXT_FAILURE": "연락처에 대한 미리보기를 생성하지 못했습니다.",
    "PREVIOUS": "이전",
    "PRE_TRANSLATED_REPLY_EMAIL": "사전 번역된 회신 이메일",
    "PRE_TRANSLATION_REPLY_TEXT": "Rosetta 번역 전에 CSA가 입력한 답변",
    "PROCESS_ACTIONS_DROPDOWN_TEXT": "작업 처리",
    "PROCESS_AS_DUPLICATE_BUTTON_TEXT": "중복으로 처리",
    "PROCESS_AS_SPAM_BUTTON_TEXT": "스팸으로 처리",
    "PROCESS_CONTACT_AS_DUPLICATE": "선택한 문의를 중복으로 해결",
    "PROCESS_CONTACT_AS_SPAM_WARNING": "선택한 문의를 스팸으로 처리하시겠습니까?",
    "PROCESS_DUPLICATE_FAILURE": "선택한 문의를 중복으로 해결하지 못함",
    "PROCESS_DUPLICATE_SUCCESS": "선택한 문의를 중복으로 해결함",
    "PROCESS_SPAM_FAILURE": "선택한 연락처를 스팸으로 전송하지 못했습니다.",
    "PROCESS_SPAM_SUCCESS": "선택한 연락처를 스팸으로 전송했습니다.",
    "PROVIDE_ADDITIONAL_INFORMATION": "이 연락처와 관련된 추가 정보를 제공하십시오",
    "PURGED_SENSITIVE_INFO_FROM_CONTACT": "연락처에서 민감한 정보를 삭제했습니다.",
    "PURGE_ADD_WORD_BUTTON_TEXT": "삭제 목록에 단어 추가",
    "PURGE_BUTTON_TEXT": "삭제",
    "PURGE_CONFIRMATION_CHECK": "위의 필드를 확인하고 텍스트에 실제로 민감한 정보가 포함되어 있는지 확인했습니다.",
    "PURGE_CONFIRMATION_CHECK_ATTACHMENTS": "첨부 파일이 고객의 민감한 정보를 포함하고 있음을 확인했습니다.",
    "PURGE_CONFIRMATION_UNCHECKED": "체크박스를 확인하세요.",
    "PURGE_FROM_CONTACT_TEXT_FAILURE": "연락처에서 삭제하지 못했습니다.",
    "PURGE_FROM_CONTACT_TEXT_SUCCESS": "연락처에서 정보를 삭제했습니다.",
    "PURGE_INVALID_REASON": "{invalid_purge_selection}은(는) 유효한 삭제 이유가 아닙니다.",
    "PURGE_REASON_FINANCIAL_INFO": "재무 정보",
    "PURGE_REASON_OTHER": "기타 민감한 정보",
    "PURGE_REASON_PII": "개인 식별 가능 정보",
    "PURGE_REASON_PLACEHOLDER": "삭제 이유 입력",
    "PURGE_REASON_TEXT": "삭제 이유",
    "PURGE_REMOVE_LABEL": "삭제",
    "PURGE_SELECTOR_PLACEHOLDER": "민감한 정보를 삭제하는 이유 선택",
    "PURGE_SENSITIVE_INFO": "민감한 정보 삭제",
    "PURGE_WORD_NOT_FOUND": "연락처 텍스트에서 단어를 찾을 수 없습니다.",
    "PURPOSE_AUDITING": "품질 관리를 위한 감사",
    "PURPOSE_BACK_OFFICE_WORKFLOW": "백 오피스 워크플로",
    "PURPOSE_BACK_OFFICE_WORKFLOW_DESCRIPTION": "백 오피스 워크플로의 연락처 데이터를 확보하기 위한 세션입니다. 예를 들어 티켓 또는 SIM에 포함할 첨부 파일입니다.",
    "PURPOSE_COACHING": "코칭 문의 검토",
    "PURPOSE_COMPLIANCE_INVESTIGATION": "규정 준수 조사",
    "PURPOSE_CURATION": "연락처 큐레이션",
    "PURPOSE_CURATION_DESCRIPTION": "C2CS/VoC 연락처 큐레이션을 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_AUDITING": "본 세션은 문의자를 감사하고 품질을 평가하기 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_COACHING": "사용자 본인이 성과 포털 코치를 담당하고 있는 에이전트의 문의를 검토하기 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_COMPLIANCE_INVESTIGATION": "의심스러운 활동 모니터링 조사에 대한 세션입니다.",
    "PURPOSE_DESCRIPTION_ESCALATION_CONTACT_HANDLING": "에스컬레이션 문의를 처리하기 위한 세션입니다. 이를 통해 고객의 문의 데이터에 액세스할 수 있습니다.",
    "PURPOSE_DESCRIPTION_LIVE_CONTACT": "실시간 문의를 처리하기 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_PURGE_SENSITIVE_INFO": "이 목적은 연락처에서 민감한 정보를 삭제하는 것입니다.",
    "PURPOSE_DESCRIPTION_REVIEW_ML_ACCURACY": "기계 학습 모델의 정확도를 검토하기 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_REVIEW_OWN_CONTACT": "사용자 본인의 과거 문의를 검토하기 위한 세션입니다.",
    "PURPOSE_DESCRIPTION_UNKNOWN": "이것은 기본 범용 세션입니다.",
    "PURPOSE_ESCALATION_CONTACT_HANDLING": "에스컬레이션 문의 처리",
    "PURPOSE_PURGE_SENSITIVE_INFO": "민감한 정보 삭제",
    "REASON_FOR_PURGE": "삭제 이유",
    "REASON_FOR_PURGE_DESCRIPTION": "첨부 파일 삭제 이유",
    "REASON_FOR_PURGE_MISSING": "첨부 파일을 삭제하는 이유를 입력하세요.",
    "REASSIGN_TO_AGENT": "담당자에게 재할당",
    "RECORDING": "기록",
    "RELOADING_CONTENT": "콘텐츠를 다시 로드하는 중입니다.",
    "RELOAD_LIST": "리스트 다시 로드",
    "REPLY_BY": "회신 방법",
    "REPLY_BY_EMAIL": "이메일로 답장",
    "REPLY_BY_PHONE": "전화로 응답",
    "REPLY_RESTRICTED": "회신 콘텐츠가 제한되어 있습니다.",
    "REQUEST": "요청",
    "REQUEST_ACCESS_INSTRUCTIONS": "제한된 콘텐츠에 액세스해야 하는 이유를 제공하십시오. (이러한 요청은 모니터링되며 남용은 심각한 결과를 초래할 수도 있습니다.)",
    "REQUEST_ACCESS_TO_FILTERED_CONTENT": "필터링된 콘텐츠에 대한 액세스 요청",
    "REQUEST_ACCESS_TO_RESTRICTED_CONTENT": "제한된 콘텐츠에 대한 액세스 요청",
    "REQUEST_NOT_GRANTED": "요청이 부여되지 않았습니다.",
    "REQUEST_OVERRIDE": "요청 재정의",
    "RESEND": "재전송",
    "RESEND_FAILED": "이메일을 재전송하지 못했습니다.",
    "RESEND_SUCCESS": "이메일을 다시 전송했습니다.",
    "RESEND_WAIT_WARNING": "다시 재전송하려면 {wait_mins}분 동안 기다려야 합니다.",
    "RESEND_WARNING": "고객에게 이메일을 다시 보내시겠습니까?",
    "RESET": "재설정",
    "RESOLVED": "해결됨",
    "RESOLVED_BY": "해결자",
    "RESTRICTED_DATA_MATCH": "{match} 일치하는 유형 {type}",
    "RESTRICTED_INFORMATION": "제한된 정보",
    "RETRY": "재시도",
    "ROLE": "역할",
    "SCREEN_POP_DESCRIPTION_TITLE": "설명",
    "SCREEN_POP_DISPLAYED_TO_AGENT_NEGATIVE": "아니요",
    "SCREEN_POP_DISPLAYED_TO_AGENT_POSITIVE": "예",
    "SCREEN_POP_DISPLAYED_TO_AGENT_TITLE": "에이전트에게 표시됨",
    "SCREEN_POP_TITLE": "스크린팝 정보",
    "SCREEN_POP_TYPE_AUTHENTICATING": "스크린팝 인증이 에이전트에게 표시되고 사용되었습니다.",
    "SCREEN_POP_TYPE_AUTHENTICATING_AVAIL": "인증 스크린팝이 에이전트에게 표시되었습니다.",
    "SCREEN_POP_TYPE_CALLER_ID": "발신자 ID 스크린팝이 에이전트에게 표시되고 사용되었습니다.",
    "SCREEN_POP_TYPE_CALLER_ID_AVAIL": "발신자 ID 스크린팝이 에이전트에게 표시되었습니다.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH": "인증되지 않은 스크린팝이 표시되고 사용되었습니다.",
    "SCREEN_POP_TYPE_CUSTOMER_HUNCH_AVAIL": "인증되지 않은 스크린팝이 에이전트에게 표시되었습니다.",
    "SCREEN_POP_TYPE_CUSTOMER_UNKNOWN": "고객을 알 수 없으며 스크린팝이 제공되지 않습니다.",
    "SCREEN_POP_TYPE_OTHER": "기타",
    "SCREEN_POP_USED_BY_AGENT_NEGATIVE": "아니요",
    "SCREEN_POP_USED_BY_AGENT_POSITIVE": "예",
    "SCREEN_POP_USED_BY_AGENT_TITLE": "담당자가 사용",
    "SDS_TOOLS": "배송 지원 도구",
    "SEARCH": "검색",
    "SELECTED_ROLE": "선택한 역할",
    "SELECTED_VIEW": "선택한 보기",
    "SELECT_CHANNELS": "채널 선택",
    "SELECT_ISSUE_CODE_RADIO_BUTTON": "선택한 문의를 중복으로 표시하기 위한 이슈 코드 선택",
    "SELECT_TRANSFER_DESTINATION": "전송 목적지 선택",
    "SELECT_WORK_REQUEST_QUEUE": "작업 요청 대기열 선택",
    "SENDER_EMAIL_ADDRESS": "발신자",
    "SENDING_EMAIL": "이메일 전송 중",
    "SEND_EMAIL": "이메일 보내기",
    "SEND_IMAGE_UPLOAD_LINK_WARNING": "고객에게 이미지 업로드 링크를 보내시겠습니까?",
    "SEND_OUTBOUND_EMAIL": "아웃바운드 이메일 보내기",
    "SEND_OUTBOUND_EMAIL_WARNING": "다음 주소로 아웃바운드 이메일을 보내시겠습니까?",
    "SENT_FROM": "보낸 사람",
    "SENT_OUTBOUND_EMAIL": "아웃바운드 이메일이 전송되었습니다. 새 연락처가 생성되었습니다.",
    "SENT_TO": "받는 사람",
    "SHIPPER_PORTAL_BUTTON_TEXT": "배송업체 포털",
    "SKILL": "기술",
    "SKIP_SILENCES": "침묵 구간 건너뛰기",
    "SMS": "SMS",
    "SMS_LINK": "SMS 링크",
    "SOURCE_TEXT": "소스 텍스트",
    "SP_BOTTOM": "바닥",
    "SP_POSITION": "분할 패널 위치",
    "SP_PREFERENCES": "분할 패널 기본 설정",
    "SP_RESIZE": "분할 패널 크기 조정",
    "SP_SIDE": "측면",
    "START": "시작",
    "STATE_TRANSITIONS": "상태 전환",
    "STATUS": "상태",
    "SUBJECT": "제목",
    "SYSKA_ALERTS": "SYSKA 알림",
    "TEXT_TO_PURGE": "삭제할 텍스트",
    "THIRD_PARTY_COOKIES_MUST_BE_ENABLED": "타사 쿠키를 활성화해야 합니다.",
    "TICKET_FOR_CONTACT_OVERRIDE_REASON_PROMPT": "요청 티켓 생성 시, 전화번호 및 이메일 주소와 같은 고객 PII 데이터는 허용되지 않습니다. 자동화된 시스템이 PII와 다른 ID 사이의 차이를 인식하지 못하는 경우도 있습니다. 재정의가 필요한 이유를 제공하십시오.",
    "TIMEOUT_FAILED_TO_LOAD_RECORDING": "시간 초과: 기록 로딩 실패",
    "TIME_TEXT_FORMAT_CONSTRAINT": "24시간 형식을 사용하십시오.",
    "TO": "종료 지점",
    "TO_EMAIL_ADDRESS": "받는 사람",
    "TRANSFER": "이전",
    "TRANSFERRED_CHAT_TITLE": "Amazon 고객 서비스와의 채팅 전송",
    "TRANSFERRING": "전송 중...",
    "TRANSFER_CONTACT": "연락처 전송",
    "TRANSFER_CONTACT_COMMENTS": "선택적 주석",
    "TRANSFER_FAILED": "전송 실패",
    "TRANSFER_FOLLOW_UP": "후속 작업 이전",
    "TRANSFER_FOLLOW_UP_FAILED": "후속 작업을 이전하지 못했습니다.",
    "TRANSFER_FOLLOW_UP_INVALID_NEW_OWNER": "잘못된 새 소유자 로그인으로 인해 후속 작업을 이전하지 못했습니다.",
    "TRANSFER_FOLLOW_UP_INVALID_REQUESTER": "이 후속 작업을 이전할 수 있는 권한이 없습니다.",
    "TRANSFER_FOLLOW_UP_MODAL_DESCRIPTION": "새 소유자의 로그인 이름을 입력합니다. 예: {example_login}",
    "TRANSFER_FOLLOW_UP_MODAL_LABEL": "위의 후속 작업을 다음으로 이전합니다.",
    "TRANSFER_FOLLOW_UP_SUCCESS": "후속 작업이 이전되었습니다.",
    "TRANSLATED_TEXT": "번역된 텍스트",
    "TRANSLATION": "번역",
    "TYPED_QUEUE_NAME_IS_INVALID": "{queue}은(는) 유효한 대기 이름이 아닙니다.",
    "TYPE_AGENT_LOGIN": "담당자 로그인 입력",
    "TYPE_EMAIL_ADDRESS": "이메일 주소 입력",
    "TYPE_EMAIL_ADDRESS_SEPARATED_BY_COMMA": "여러 이메일 주소를 쉼표로 구분하여 입력",
    "TYPE_QUEUE_NAME": "대기열 이름 입력",
    "UNKNOWN_CONTACT_TYPE": "알 수 없는 연락처 유형입니다.",
    "UNLOCKED": "잠금 해제됨",
    "UNRESOLVED": "미해결",
    "URGENT_SYSKA_ALERTS": "긴급 SYSKA 알림",
    "USE": "사용",
    "USER_TIMEZONE": "현지 시간대에 표시된 타임스탬프",
    "USE_CONTACT_FINDER": "연락처 검색기 사용",
    "VIEW": "보기",
    "WARNING": "경고",
    "WELCOME_MESSAGE": "Papyrus Static 웹사이트에 오신 것을 환영합니다!",
    "WHAT_IS_PAPYRUS": "Papyrus란 무엇입니까?",
    "WITH_THE_ANNOTATION": "주석과 함께",
    "WORK_CATEGORY_TITLE": "업무 카테고리",
    "WORK_REQUEST": "작업 요청",
    "WORK_REQUEST_CONTENT": "작업 요청 내용",
    "WORK_REQUEST_CREATED": "작업 요청이 생성됨",
    "WORK_REQUEST_FOR_CONTACT": "문의자 작업 요청",
    "WORK_REQUEST_OVERRIDE_REASON_PROMPT": "작업 요청을 생성할 때 전화번호 및 이메일 주소와 같은 고객 PII 데이터는 허용되지 않습니다. 자동화된 시스템이 PII와 다른 ID 간 차이를 인식하지 못하는 경우도 있습니다. 재정의가 필요한 이유를 제공하세요.",
    "YOU_ARE_ABOUT_TO_TRANSFER_TO_QUEUE": "대기열로 전송하려고 합니다."
}