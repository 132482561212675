export const zh_CN = {
    "contact_event_GC_added": "GC #{gcId} 已添加到账户。",
    "contact_event_GC_added_batched": "GC 已添加到账户  ({count})。",
    "contact_event_GC_added_purchaser": "GC #{gcId} 已添加到购买人账户。",
    "contact_event_GC_added_purchaser_batched": "GC 已添加到购买人账户  ({count})。",
    "contact_event_GC_added_recipient": "GC #{gcId} 已添加到收件人账户。",
    "contact_event_GC_added_recipient_batched": "GC 已添加到收件人账户  ({count})。",
    "contact_event_GC_added_scratchedCode": "GC #{gcId}已通过刮擦优惠码工具添加到账户。",
    "contact_event_GC_added_scratchedCode_batched": "GC已通过刮擦优惠码工具({count})添加到账户。",
    "contact_event_GC_cancelled": "GC #{gcId} 已取消。",
    "contact_event_GC_cancelled_batched": "GC ({count}) 已取消。",
    "contact_event_GC_changed_expiry": "更改 GC #{gcId} 的到期日期。",
    "contact_event_GC_changed_expiry_batched": "GC ({count}) 到期日期已更改。",
    "contact_event_GC_locked": "GC #{gcId} 已锁定。",
    "contact_event_GC_locked_batched": "GC 已锁定 ({count})。",
    "contact_event_GC_removed": "GC #{gcId} 已从账户删除。",
    "contact_event_GC_removed_batched": "GC 已移出账户 ({count})。",
    "contact_event_GC_resent_email": "GC #{gcId} 已重新发送至电子邮件 ID {deliveryEmail}。",
    "contact_event_GC_resent_email_batched": "GC ({count}) 已重新发送至电子邮件 ID。",
    "contact_event_GC_resent_post": "GC #{gcId} 已通过邮寄方式重新发出。",
    "contact_event_GC_resent_post_batched": "GC ({count}) 已通过邮寄方式重新发送。",
    "contact_event_GC_temporary_locked": "GC #{gcId} 已暂时锁定。",
    "contact_event_GC_temporary_locked_batched": "GC 已暂时锁定 ({count})。",
    "contact_event_GC_transferred": "GC {gcId} 已从买家 {customerID} 转至 {toAccountCustomerID}。",
    "contact_event_GC_transferred_batched": "GC ({count}) 已转。",
    "contact_event_GC_unlocked": "GC #{gcId} 已解除锁定。",
    "contact_event_GC_unlocked_batched": "GC 已解锁 ({count})。",
    "contact_event_PV_added": "PV #{pvId}已添加到帐户。",
    "contact_event_PV_locked": "PV #{pvId}已锁定。",
    "contact_event_PV_temporary_locked": "PV #{pvId}已临时锁定。",
    "contact_event_PV_unlocked": "PV #{pvId}已解锁。",
    "contact_event_a_to_z_guarantee_cancelled_batched": "订单已取消 A-to-Z 索赔 ({count})。",
    "contact_event_a_to_z_guarantee_requested": "已提交订单 {order_id} 的亚马逊商城交易保障索赔。",
    "contact_event_a_to_z_guarantee_requested_batched": "订单已提交 A-to-Z 索赔 ({count})。",
    "contact_event_a_to_z_guarantee_requested_gwp": "已针对订单 {order_id} 提交亚马逊商城交易保障索赔。",
    "contact_event_access_code_added": "已添加访问代码",
    "contact_event_access_code_added_batched": "已添加访问代码({count})",
    "contact_event_account_closed": "账户已注销。",
    "contact_event_account_closed_batched": "账户已注销（{count} 个）。",
    "contact_event_account_reopened": "已重新打开账户",
    "contact_event_account_reopened_batched": "已重新打开账户（{count}）",
    "contact_event_account_temporarily_disabled": "账户已暂时禁用。",
    "contact_event_account_temporarily_disabled_batched": "账户已暂时禁用（{count}）",
    "contact_event_account_whitelisted": "账户已列入白名单。",
    "contact_event_account_whitelisted_batched": "帐户已列入白名单({count})。",
    "contact_event_address_changed": "已更新订单 {order_id} 的 {address_type} 地址。",
    "contact_event_address_changed_batched": "订单已更新地址 ({count})。",
    "contact_event_address_deactivated": "地址已失效。",
    "contact_event_address_deactivated_batched": "地址已失效 ({count})。",
    "contact_event_amazon_video_subscription_auto_renew_settings": "Amazon Video 订阅已自动续订",
    "contact_event_amazon_video_subscription_cancelled": "Amazon Video 订阅已取消",
    "contact_event_amzl_forms_submission_dsl_escalation_form": "表格已提交：DSL 上报表\n使用案例会话 ID：{usecaseSessionID}\n配送站：{deliveryStationField}\n请求类型：{requestTypeField}\n联系人 ID：{contactIdField}\n订单编号：{orderIdField}\n买家的电子邮件地址：{customerEmailField}\n买家的电话号码：{customerPhoneNumberField}\nTBA：{tbasField}\n访问代码（如果适用）：{accessCodeField}\n问题摘要：{issueSummaryField}",
    "contact_event_amzl_forms_submission_dsl_escalation_form_batched": "DSL 上报表已提交：({count})",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback": "表格已提交：DSP DA 反馈表\n使用案例会话 ID：{usecaseSessionID}\n配送站代码：{deliveryStationField}\n联系人 ID：{contactIdField}\n买家账户 ID：{customerIdField}\nDA 运输商 ID：{driverIdField}\n事件日期 (YYYY-MM-DD)：{dateOfOccurrenceField}\n事件时间 (HH:MM)：{timeofOccurrenceField}\nTBA：{relatedTrackingIdsField}\n反馈来源：{sourceOfFeedbackField}\n反馈详情：{issueField}\n更多详情：{additionalDetailsField}",
    "contact_event_amzl_forms_submission_dsp_da_driver_feedback_batched": "DSP DA 反馈表已提交：({count})",
    "contact_event_andon_cord_pulled": "已启动 {asin} 的Andon Crod。",
    "contact_event_andon_cord_pulled_batched": "已为商品设置Andon Crod ({count})。",
    "contact_event_apply_adrive_music_trial": "适用于客户 {customer_id} 的云端播放器高级服务。{number_of_intervals} {time_interval}",
    "contact_event_audible_account_migrated": "账户已迁移至{marketplaceName}。",
    "contact_event_audible_account_migrated_batched": "Audible账户已迁移({count})。",
    "contact_event_audible_cash_and_coupon_refund": "已为{title}退款，金额为{cashAmount}。 此外，本交易已将{couponAmount}优惠券返回到您的账户。{counter}",
    "contact_event_audible_cash_and_coupon_refund_batched": "已为{title}退款，金额为{cashAmount}。 此外，本交易已将{couponAmount}优惠券返回到您的账户。{counter} ({count})",
    "contact_event_audible_cash_only_refund": "已为{title}退款，金额为{cashAmount}。{counter}",
    "contact_event_audible_cash_only_refund_batched": "已为{title}退款，金额为{cashAmount}。{counter} ({count})",
    "contact_event_audible_coupon_only_refund": "已为{title}退款，并且已将{couponAmount}优惠券恢复到账户。{counter}",
    "contact_event_audible_coupon_only_refund_batched": "已为{title}退款，并且已将{couponAmount}优惠券恢复到账户。{counter} ({count})",
    "contact_event_audible_credit_deleted": "{amount}个积分已从此帐户移除。",
    "contact_event_audible_credit_issued": "{numCredit}个积分已添加到账户。",
    "contact_event_audible_credit_issued_batched": "积分已添加到账户({count})。",
    "contact_event_audible_credit_refund": "已为{title}退款，并且已将{creditAmount}积分恢复到账户。{counter}",
    "contact_event_audible_credit_refund_batched": "已为{title}退款，并且已将{creditAmount} credit(s)恢复到账户。{counter} ({count})",
    "contact_event_audible_credit_restored": "{amount}个积分已还原到该账户。",
    "contact_event_audible_credit_restored_batched": "{amount}个积分已还原到该账户。({count})",
    "contact_event_audible_extra_credit_purchased": "已购买 3 个额外积分。",
    "contact_event_audible_extra_credit_purchased_batched": "已购买 3 个额外积分。({count})",
    "contact_event_audible_hiatus_cancelled": "中断期已及早终止早期，且会员资格已恢复。",
    "contact_event_audible_hiatus_cancelled_batched": "中断期已及早终止早期，且会员资格已恢复。({count})",
    "contact_event_audible_hiatus_started": "会员资格已置为{duration}月中断。",
    "contact_event_audible_hiatus_started_batched": "会员资格已置为{duration}月中断。({count})",
    "contact_event_audible_membership_enrollment": "已注册 \n{membershipPlan}会员计划。",
    "contact_event_audible_membership_enrollment_batched": "已注册Audible会员计划({count})。",
    "contact_event_audible_membership_plan_refund": "已退还会员费，金额为{cashAmount}。{counter}",
    "contact_event_audible_membership_plan_refund_batched": "已退还会员费，金额为{cashAmount}。{counter} ({count})",
    "contact_event_audible_membership_switched": "已从{oldMembershipPlan}更改为{newMembershipPlan}会员计划。",
    "contact_event_audible_membership_switched_batched": "已更改Audible会员计划({count})。",
    "contact_event_audible_romance_package_enrollment": "已登记到{packageName}。",
    "contact_event_audible_romance_package_enrollment_batched": "已登记到{packageName}。（{count}）",
    "contact_event_audible_wizard_invoked": "访问了{wizardName}。",
    "contact_event_audible_wizard_invoked_batched": "访问了{wizardName}。({count})",
    "contact_event_audiblecontact_event_cancel_audible_membership": "{membership}计划已成功取消。",
    "contact_event_blacklist_kindle": "将 {serial_number} 设备拉入黑名单中。",
    "contact_event_blacklist_kindle_batched": "已加入黑名单 Kindle ({count})。",
    "contact_event_bound_warranty": "保修 ID {warranty_id} 绑定至设备 {dsn}。",
    "contact_event_bound_warranty_batched": "设备保修期 ({count})。",
    "contact_event_call_was_flagged": "此联系人被标记为 {callFlagSubject}：{callFlagDescription}",
    "contact_event_call_was_flagged_batch": "此联系人被标记为 {callFlagSubject}：{callFlagDescription} ({count})",
    "contact_event_cancel_audible_membership": "{membership}计划已成功取消。",
    "contact_event_cancel_audible_membership_batched": "{membership}计划已成功取消。({count})",
    "contact_event_cancel_audible_romance_package_batched": "{membership}计划已成功取消。({count})",
    "contact_event_cancel_from_prime_cancel_exception": "成功取消 PrimeCancelException 中的 Prime取消原因：{cancel_reason}.",
    "contact_event_cancel_from_prime_cancel_exception_batched": "Prime{count} 中的 Cancel Exception。",
    "contact_event_cancel_future_cancellation_audible_membership": "先前的设置【将来取消日期】已取消。",
    "contact_event_cancel_library_loan": "已取消客户 ({customer_id}) 的图书馆借出。ASIN：{asin}；借出 ID：{loan_id}；原因：{cancel_reason}；",
    "contact_event_cancel_tv_pass": "已取消包含订单编号 {order_id} 和原因 {cancel_reason} 的电视季票。",
    "contact_event_cancelled_sns_reactivated": "通过 {login} 进行订阅的 ASIN {asin} 已于 {reactivation_date}（月份）重新激活",
    "contact_event_cancelled_sns_reactivated_batched": "已重新激活的已取消“订阅和保存”订阅 ({count})。",
    "contact_event_captcha_bypass_key_generated": "已生成验证码绕过密钥。",
    "contact_event_captcha_bypass_key_generated_batched": "已生成验证码绕过密钥 ({count})。",
    "contact_event_change_sns_quantity": "针对订阅和保存的 ASIN {asin} 将原来的数量 {current_quantity} 设置为新数量 {new_quantity}。",
    "contact_event_change_sns_quantity_batched": "已更新“订阅和保存”数量 ({count})。",
    "contact_event_channels_auto_renew_off": "客户服务 (CS) 关闭了 PV 频道订阅的自动续订 {subscriptionid}。",
    "contact_event_channels_auto_renew_off_static": "客户服务 (CS) 已通过 Astro 关闭自动续订。",
    "contact_event_channels_full_refund": "客户服务 (CS) 已取消 PV 频道订阅 {subscriptionid}，并将全额退款。",
    "contact_event_channels_full_refund_static": "客户服务 (CS) 已取消订阅，并将通过 Astro 全额退款。",
    "contact_event_channels_no_refund": "客户服务 (CS) 取消了 PV 频道订阅 {subscriptionid}，但没有退款。",
    "contact_event_channels_no_refund_static": "客户服务 (CS) 取消了订阅，但没有通过 Astro 退款。",
    "contact_event_channels_workflow_launch": "客户服务 (CS) 推出了 PV 频道订阅的 Astro 取消工作流程 {subscriptionid}",
    "contact_event_channels_workflow_launch_static": "客户服务 (CS) 推出了 Astro 取消工作流程。",
    "contact_event_check_in_book": "使用客户 {customer_id} 的借出 ID {loan_id} 登记书籍。",
    "contact_event_coins_adjust": "调整类型：{type} <br />\n原因：{reason} <br />\n数量：{amount} <br />\n评论：{comment} <br />",
    "contact_event_coins_adjust_batched": "调整钱币余额({count})。",
    "contact_event_concessions_abuse": "未发送 HMD，因为买家是 {risk_status} 用户。",
    "contact_event_concessions_abuse_batched": "HMD 没有发送给买家 ({count})。",
    "contact_event_contact_seller": "已联系订单 {order_id} 的卖家。",
    "contact_event_contact_seller_batched": "因订单 ({count}) 联系卖家。",
    "contact_event_contact_was_initiated_from": "联系起始信息：产品 Asin 为 {ProductAsin}，产品描述为 {ProductDescription}，产品链接为 {ProductUrl}。",
    "contact_event_content_update": "Kindle 库中的 ASIN {asin} 内容已更新。",
    "contact_event_content_update_batched": "Kindle 图书馆中已更新的内容 ({count})。",
    "contact_event_copilot_started": "开始手把手客服",
    "contact_event_copilot_started_batched": "手把手客服开始({count})",
    "contact_event_credit_card_disabled": "CC 已禁用。",
    "contact_event_credit_card_disabled_batched": "已禁用信用卡 ({count})。",
    "contact_event_csc_chat_agent_message_translation_failed": "RosettaChatTranslationFailed:Agent:{message}",
    "contact_event_csc_chat_customer_message_translation_failed": "RosettaChatTranslationFailed:Customer:{message}",
    "contact_event_csc_sds_override_geo_fence_submit": "覆盖提交的追踪编码{trackingId}的定位请求 原因：{overrideReason} 页面：{pageIdentifier}",
    "contact_event_csc_sds_override_geo_fence_submitted": "覆盖提交的追踪编码{trackingId}的定位请求 原因：{overrideReason} 页面：{pageIdentifier}",
    "contact_event_csc_sds_update_geocode_submitted": "地理编码已更新。地理编码类型：{geocodeType}，追踪编码：{trackingId}，地址编码：{addressId}，覆盖原因：{overrideReason}，页面标识符：{pageIdentifier}，",
    "contact_event_csc_sds_updated_geocode": "地理编码已更新。地理编码类型：{geocodeType}，覆盖原因：{overrideReason}，追踪编码：{trackingId}，地址编码：{addressId}和页码标识符：{pageIdentifier}",
    "contact_event_customer_authentication_passed": "客户验证已通过",
    "contact_event_customer_authentication_passed_batched": "客户验证已通过 ({count})",
    "contact_event_customer_consent_not_received_for_diode": "Customer consent not received for device diagnostics.",
    "contact_event_customer_consent_received_for_diode": "Customer consent received for device diagnostics.",
    "contact_event_customer_name_updated": "买家姓名已更新。",
    "contact_event_customer_name_updated_batched": "已更新客户姓名 ({count})。",
    "contact_event_customer_password_changed": "买家密码已更改。",
    "contact_event_customer_password_changed_batched": "已修改客户密码 ({count})。",
    "contact_event_deauthorize_all_device": "为客户 {customer_id} 取消所有设备的授权。",
    "contact_event_deauthorize_device": "已取消对客户 ({customer_id}) 设备类型 {device_type} 的 DSN {dsn} 的授权。",
    "contact_event_delete_alexa_voice_profile": "已删除扬声器编号为 {speakerId}、买家编号为 {customerID} 且状态为 {status} 的 Alexa 语音配置文件",
    "contact_event_delete_data_alexa_comms": "已删除通信编号为 {commsId}、买家编号为 {customerID} 且状态为 {status} 的 Alexa 通信配置文件",
    "contact_event_deprovision_alexa_comms": "取消设置以下项的Alexa通信配置文件：通信编号：{commsId}、买家编号：{customerID}和状态：{status}",
    "contact_event_deregister_kindle": "已取消注册 Kindle {serial_number}。",
    "contact_event_deregister_kindle_batched": "已取消注册 Kindle ({count})。",
    "contact_event_detailed_troubleshooting_wizard": "为具有 DSN {dsn} 的设备调用故障排除向导：{details}。",
    "contact_event_disable_mayday_failed": "DSN {dsn} 设备的 Mayday 功能未能停用。",
    "contact_event_disable_mayday_success": "DSN {dsn} 设备的 Mayday 功能已成功停用。",
    "contact_event_dunning_pause": "催缴流程已暂停：查看支付及交易历史记录以了解更多详情",
    "contact_event_dvbypost_extra_dispatch": "已为该客户要求进行 {amount} 次额外配送。",
    "contact_event_dvbypost_extra_envelope": "已为此客户触发另一个信封。",
    "contact_event_dvbypost_fsk18_form_request": "要求 FSK18 表格",
    "contact_event_dvbypost_problem_code_change": "将光盘 {disc_id} 设置为 {problem_code}",
    "contact_event_email_address_updated": "电子邮箱地址已更新。",
    "contact_event_email_address_updated_batched": "已更新 1-Click 电子邮件地址 ({count})。",
    "contact_event_email_preferences_changed": "电子邮箱首选项已更改。",
    "contact_event_email_unsubscribe": "已取消电子邮件订阅。",
    "contact_event_email_unsubscribe_batched": "已取消订阅电子邮件 ({count})。",
    "contact_event_enable_mayday_failed": "DSN {dsn} 设备的 Mayday 功能未能激活。",
    "contact_event_enable_mayday_success": "DSN {dsn} 设备的 Mayday 功能已成功激活。",
    "contact_event_first_time_user_experience_set": "设置首次用户体验",
    "contact_event_first_time_user_experience_set_batched": "设置首次用户体验 ({count})。",
    "contact_event_form_submitted": "表格 {form_name} 已提交。",
    "contact_event_form_submitted_batched": "已提交表格 ({count})。",
    "contact_event_free_time_create_pin": "已成功生成新的 FreeTime PIN 码。",
    "contact_event_free_time_unlimited_cancelled": "买家 {customerId} 因 {reason} 已取消的 Free Time Unlimited 订阅，其中 {agentId} 的 DSN 为 {deviceSerialNumber}。",
    "contact_event_free_time_unlimited_cancelled_batched": "已取消的 Free Time Unlimited 订阅（{count}）。",
    "contact_event_free_trial_prime_cancel_exception": "由于 {cancel_reason]， 已成功取消 Prime 免费试用。",
    "contact_event_free_trial_prime_cancel_exception_batched": "成功取消 Prime ({count})。",
    "contact_event_fulfill_kindle": "已配送的 Kindle。DSN{dsn}；设备类型{device_type}；ASIN{asin}；订单编号{order_id}；",
    "contact_event_future_cancel_audible_membership": "{membership}计划已于{date}设置为【将来取消】。",
    "contact_event_future_cancel_audible_membership_batched": "{membership}计划已于{date}设置为【将来取消】。({count})",
    "contact_event_hard_delete_from_yvl": "ASIN {asin} 已从“您的视频库”中永久移除。",
    "contact_event_header": "事件日志",
    "contact_event_hmd_suppression": "HMD 未发送，因为：{suppression_message}。",
    "contact_event_idle_chat_disconnect": "空闲聊天已断开连接",
    "contact_event_idle_chat_disconnect_batched": "空闲聊天已断开连接 ({count})",
    "contact_event_international_linking_account": "具有客户 {customer_id} 帐户 {to_customer_email} 的国际关联账户 {from_customer_email}。",
    "contact_event_item_price_reduced": "{asin} 的定价已从 {old_price} 改成 {new_price}。",
    "contact_event_item_price_reduced_batched": "订单已修改商品价格 ({count})。",
    "contact_event_item_quantity_changed": "已将订单 {order_id} 的 {asin} 数量从 {old_quantity} 改成 {new_quantity}。",
    "contact_event_item_quantity_changed_batched": "订单商品数量已修改 ({count})。",
    "contact_event_kindle_device_replacement": "因 {reason} 而为订单 {old_order_id} 中的 DSN {dsn} 创建了换货。新订单：{new_order_id}。",
    "contact_event_kindle_device_replacement_batched": "已更换 Kindle 设备 ({count})。",
    "contact_event_kindle_email_address_edits": "Kindle 上的邮箱地址已更新(DSN # {dsn} )。",
    "contact_event_kindle_email_address_edits_batched": "已为 Kindle 更新的电子邮件地址 ({count})。",
    "contact_event_kindle_email_edits": "{dsn}发送电子邮件地址已从 {original_email} 更改为 {new_email}",
    "contact_event_kindle_name_changed": "Kindle 的 DSN {dsn} 名称已从 {original_name} 更改为 {new_name}。",
    "contact_event_kindle_name_changed_batched": "已修改 Kindle 名称 ({count})。",
    "contact_event_last_page_read_deleted": "ASIN {asin} 的最后页读取已删除。",
    "contact_event_last_page_read_deleted_batched": "已删除最后一页已读内容 ({count})。",
    "contact_event_live_assist_launched": "Mayday Screen Sharing 已发布到 DSN：{dsn}，kcsID：{kcsId}，买家回复：{customerResponse}。",
    "contact_event_live_assist_launched_batched": "Mayday Screen Sharing 已发布到设备（{count} 台设备）。",
    "contact_event_lock_dash_order": "{dsn} 设备的以下 ASIN 已被锁定：{asins}。",
    "contact_event_mp3_download_to_adrive": "已将 mp3 下载到客户 {customer_id} 的 ADrive。ASIN：{asin}",
    "contact_event_music_auto_renew_off": "客服关闭了音乐订阅 {subscriptionid} 的自动续订功能。",
    "contact_event_music_auto_renew_off_static": "客服通过 Astro 关闭了音乐订阅的自动续订功能。",
    "contact_event_music_full_refund": "客服已取消音乐订阅 {subscriptionid}，并全额退款",
    "contact_event_music_full_refund_static": "客服已取消音乐订阅，并通过 Astro 全额退款。",
    "contact_event_music_no_refund": "客服取消了音乐订阅{subscriptionid}，但没有退款。",
    "contact_event_music_no_refund_static": "客服取消了音乐订阅，但并未通过 Astro 退款。",
    "contact_event_music_unlimited_auto_renew_off": "CS 已关闭亚马逊 Music 无限畅听订阅 {subscriptionid} 的自动续订。",
    "contact_event_music_unlimited_full_refund": "CS 取消了亚马逊 Music 无限畅听订阅 {subscriptionid} 并全额退款。",
    "contact_event_music_unlimited_no_refund": "CS 取消了亚马逊 Music 无限畅听订阅 {subscriptionid}，但不退款。",
    "contact_event_music_unlimited_partial_refund": "CS 取消了亚马逊 Music 无限畅听订阅 {subscriptionid}，但部分退款。",
    "contact_event_music_unlimited_workflow_launch": "CS 为亚马逊 Music 无限畅听订阅 {subscriptionid} 启动了 Astro 取消工作流程。",
    "contact_event_music_workflow_launch": "客服启动了 Astro 取消音乐订阅 {subscriptionid} 的工作流。",
    "contact_event_music_workflow_launch_static": "客服启动了 Astro 应用于 Music Unlimited 的取消工作流。",
    "contact_event_non_dsv_refund_created": "退了 {refund_amount} 的数字订单 {order_id}。",
    "contact_event_non_dsv_refund_created_batched": "已退款的数字订单 ({count})。",
    "contact_event_one_click_address_updated": "“一键下单”地址已更新。",
    "contact_event_one_click_address_updated_batched": "已更新 1-Click 地址 ({count})。",
    "contact_event_one_click_disabled": "“一键下单”已禁用。",
    "contact_event_one_click_disabled_batched": "已禁用 1-Click ({count})。",
    "contact_event_one_click_enabled": "“一键下单”已启用。",
    "contact_event_one_click_payment_updated": "已更新 1-Click 支付。",
    "contact_event_one_click_payment_updated_batched": "已更新 1-Click 支付 ({count})。",
    "contact_event_order_cancelled": "订单 {order_id} 中取消的商品。",
    "contact_event_order_cancelled_batched": "已取消订单 ({count})。",
    "contact_event_order_placed": "已下订单 {order_id}。",
    "contact_event_order_placed_batched": "已下订单 ({count})。",
    "contact_event_payment_method_added": "付款方式 {payment_type} 已添加至账户。",
    "contact_event_payment_method_added_batched": "账户已添加付款方式 ({count})。",
    "contact_event_payment_method_changed": "订单 {order_id} 的付款方式已更改。",
    "contact_event_payment_method_changed_batched": "订单 ({count}) 的付款方式已更改。",
    "contact_event_pdocs_max_price_edits": "个人文档设置最大转换费用更改为 {new_max_charge}。",
    "contact_event_pdocs_max_price_edits_batched": "个人文件设置最高转换费用已更新 ({count})。",
    "contact_event_pennywise_promo_applied": "应用于 DSN 的 Pennywise 促销：{dsn}",
    "contact_event_pennywise_promo_applied_batched": "应用于 DSN 的 Pennywise 促销：({count})",
    "contact_event_ping_device": "设备具有 DSN {dsn}。",
    "contact_event_ping_device_batched": "已发送回显信息的设备 ({count})。",
    "contact_event_prime_auto_renew_settings": "Prime 自动续订已设置为 {auto_renew_state}。",
    "contact_event_prime_auto_revew_settings": "Prime 自动续订已设置为 {auto_renew_state}。",
    "contact_event_prime_cancel_exception": "成功取消 PrimeCancelException 中的 Prime取消原因：{cancel_reason}.",
    "contact_event_prime_cancelled": "Prime 资格已取消。",
    "contact_event_prime_cancelled_batched": "已取消 Prime ({count})。",
    "contact_event_prime_concession": "给予订单 {order_id}的主要补偿。",
    "contact_event_prime_concession_batched": "已发出订单 Prime 优惠 ({count})。",
    "contact_event_prime_refund": "Prime 退款 {amount}。",
    "contact_event_prime_refund_batched": "已退款 Prime ({count})。",
    "contact_event_prime_revoke_and_transfer_met": "已转接的 Prime 会员资格延期令牌",
    "contact_event_prime_revoke_and_transfer_met_batched": "已转接的 Prime 会员资格延期令牌",
    "contact_event_prime_revoke_met_no_transfer": "撤销 Prime 会员令牌成功但转接失败",
    "contact_event_prime_revoke_met_no_transfer_batched": "撤销 Prime 会员令牌成功但转接失败",
    "contact_event_prime_transfer_met": "已转接的 Prime 会员资格延期令牌",
    "contact_event_prime_transfer_met_batched": "已转接的 Prime 会员资格延期令牌",
    "contact_event_promo_added_to_account": "{amount} 促销额已打入账户。",
    "contact_event_promo_added_to_account_batched": "账户已添加促销 ({count})。",
    "contact_event_promo_unredeemed": "{promoBalance} 促销余额未兑换。",
    "contact_event_promo_unredeemed_batched": "未兑换的促销数量 ({count})。",
    "contact_event_refund_cancelled": "已取消订单 {order_id} 的退款。",
    "contact_event_refund_created": "退还 {refund_amount}，来自订单 {order_id}。",
    "contact_event_refund_created_batched": "已退款订单 ({count})。",
    "contact_event_refund_from_prime_cancel_exception": "PrimeCancelException 退还{refund_amount}。退款原因：{refund_reason}。",
    "contact_event_refund_from_prime_cancel_exception_batched": "Prime Cancel  Exception 退款{count}。",
    "contact_event_refund_in_digital_library": "为买家 {customerId} 退款数字商品。原因：{reason}；订单编号：{orderId}；",
    "contact_event_register_kindle": "已注册 Kindle {serial_number}。",
    "contact_event_register_kindle_batched": "已注册 Kindle ({count})。",
    "contact_event_registry_deleted": "注册已删除(注册编号为 {registry_id}）。",
    "contact_event_registry_deleted_batched": "注册已删除（{count} 个）。",
    "contact_event_release_aiv_license": "AIV {asin} 的许可证已发放到设备 DSN {dsn}上。",
    "contact_event_release_licenses": "在 DSN {dsn} 上为客户 {customer_id} 发布设备类型 {device_type} 的许可证。",
    "contact_event_remove_content_from_todo_list": "{number_of_removed_items} 件商品已从具有 DSN {dsn} 的设备中移除。",
    "contact_event_remove_content_from_todo_list_batched": "已从设备中移除的商品 ({count})。",
    "contact_event_remove_from_yvl": "ASIN {asin} 已从“您的视频库”移除至“已删除商品”。",
    "contact_event_remove_kindle_from_blacklist": "针对 DSN {dsn} 的 Kindle 已从丢失或被盗的状态下删除，原因如下：{removing_reason}",
    "contact_event_remove_kindle_from_blacklist_batched": "已移除“失窃”状态的设备 ({count})。",
    "contact_event_replacement_created": "已替换订单 {old_order_id}。新订单：{new_order_id}。",
    "contact_event_replacement_created_batched": "订单已创建换货 ({count})。",
    "contact_event_report_seller": "卖家报告了订单 {order_id}。",
    "contact_event_report_seller_batched": "卖家报告了订单 ({count})。",
    "contact_event_request_invoice": "已向卖家发送订单 {order_id} 的发票请求。",
    "contact_event_request_invoice_batched": "已发送多个订单（{count}）的发票请求。",
    "contact_event_resend_kindle_content": "将数字内容 {title} 重新发送至设备类型为 {devicetype} 的 DSN {dsn}。",
    "contact_event_resend_kindle_content_batched": "重新发送数字内容 ({count})。",
    "contact_event_resent_gifted_content": "订单编号 {order_id} 收件人电子邮件的 ASIN {asin} 已更改。",
    "contact_event_resent_gifted_content_batched": "礼品订单接收电子邮件已更新 ({count})。",
    "contact_event_reset_aiv_pin": "为客户 {customer_id} 重置 AIV PIN 码。",
    "contact_event_reset_kindle_first_allowance": "为买家 {customer_id} 重置亚马逊 First Reads 限额。",
    "contact_event_reset_password_email_sent": "重设密码的电子邮件已经寄出。",
    "contact_event_reset_password_email_sent_batched": "重设密码的电子邮件已经寄出（{count} 封）。",
    "contact_event_reset_prime_lending_library_limit": "将 Prime 借阅图书馆的限制为客户 {customer_id} 重置为 {quota}。",
    "contact_event_residence_address_changed": "居住地址已更改。",
    "contact_event_residence_address_changed_batched": "居住地址已更新 ({count})。",
    "contact_event_restore_content": "为买家 {customer_id} 恢复订单编号 {order_id} 中的内容 ASIN {asin}。",
    "contact_event_restore_rental": "已为买家 {customer_id} 恢复使用 ASIN {asin} 的租借。",
    "contact_event_restore_video": "为买家 {customer_id} 恢复 ASIN {asin} 的视频。",
    "contact_event_retrocharge_disarmed": "订单编号 {order_id} - ASIN {asin} 的 RMA 已解除。解除原因：{disarm_retrocharge_reason}",
    "contact_event_rma_cancelled": "已为订单 {order_id} 删除 RMA。",
    "contact_event_rma_created": "已为订单 {order_id} 创建 RMA。",
    "contact_event_rma_created_batched": "订单已创建 RMA ({count})。",
    "contact_event_rosetta_chat": "#RosettaChat",
    "contact_event_rosetta_transparency": "#RosettaTransparency{treatment}",
    "contact_event_rosetta_transparency_batched": "#RosettaTransparency({count})",
    "contact_event_rosetta_transparency_c": "#RosettaTransparencyControl",
    "contact_event_rosetta_transparency_t1": "#RosettaTransparencyT1",
    "contact_event_rosetta_transparency_t2": "#RosettaTransparencyT2",
    "contact_event_sds_assigned_package_to_driver": "追踪编码 {trackingId} 已添加到行程中。",
    "contact_event_sds_demo_contact_event_string": "用于测试。",
    "contact_event_sds_dlp_loaded_for_driver_order_station_tracking_params": "DP 名称：{driverName}，\n站点名称：{stationName}，\n追踪编码：{trackingId} 和 \n订单编号：{orderId}\nUsecaseSession 编号：{usecaseSessionID}",
    "contact_event_sds_map-widget-opened": "为追踪编码 {trackingId} 打开了地图小部件",
    "contact_event_sds_package_repick": "客服专员已启动重新挑选追踪编号{trackingId}",
    "contact_event_send_aiv_asin": "ASIN {asin} 已发送到买家 {customer_id} 的“您的视频库”中。",
    "contact_event_ship_charge_reduce": "订单 {order_id} 的配送费用已从 {old_amount} 更改为 {new_amount}。",
    "contact_event_ship_charge_reduce_batched": "已修改运费 ({count})。",
    "contact_event_ship_method_upgraded": "订单 {order_id} 的配送方式已更新。",
    "contact_event_ship_method_upgraded_batched": "订单已修改货运方式 ({count})。",
    "contact_event_shipping_address_changed": "订单 {new_order_id} 的地址已变更。",
    "contact_event_sim_ticket_created": "已创建 SIM 票据",
    "contact_event_special_offers_cancel": "已取消针对客户 {customer_id} 设备类型 {device_type} 的 DSN {dsn} 特别优惠。",
    "contact_event_special_offers_enrollment": "Kindle 具有 DSN {dsn} 特价优惠 {enrollment_status}。",
    "contact_event_special_offers_enrollment_batched": "已更新特惠登记状态 ({count})。",
    "contact_event_subscribe_and_save_cancelled": "已取消订单 {order_id} 的“订购省”优惠。",
    "contact_event_subscribe_and_save_cancelled_batched": "订阅已取消“订阅并保存” ({count})。",
    "contact_event_subscribe_and_save_schedule_change": "已更改订单 {order_id} 的“订购省”运输计划。",
    "contact_event_subscribe_and_save_schedule_change_batched": "订阅已修改“订阅并保存”运输安排 ({count})。",
    "contact_event_subscription_astro_workflow_launch": "CS 启动了{subscriptionType}订阅{subscriptionId}的 Astro 取消工作流程。",
    "contact_event_subscription_auto_renew": "{plan_type} 自动更新设为 {renew_status}。",
    "contact_event_subscription_auto_renew_batched": "已更新 Prime 自动更新设置 ({count})。",
    "contact_event_subscription_auto_renew_off": "CS 关闭了{subscriptionType}订阅{subscriptionId}的自动续订功能。",
    "contact_event_subscription_cancel_full_refund": "CS 取消了{subscriptionType}订阅{subscriptionId}，并全额退款。",
    "contact_event_subscription_cancel_no_refund": "CS 取消了{subscriptionType}订阅{subscriptionId}，但没有退款。",
    "contact_event_subscription_cancel_partial_refund": "CS 取消了{subscriptionType}订阅{subscriptionId}，并部分退款。",
    "contact_event_subscription_set_to_auto_renew_to_plan": "{plan_type} 订阅设置为自 {old_plan_name} 当前期间结束后续订 {new_plan_name}。",
    "contact_event_subscription_set_to_auto_renew_to_plan_batched": "已更新 Prime 展期计划设置 ({count})。",
    "contact_event_troubleshooting_wizard": "为具有 DSN {dsn} 的设备调用故障排除向导。",
    "contact_event_troubleshooting_wizard_batched": "已调用设备故障排除向导 ({count})。",
    "contact_event_troubleshooting_wizard_detailed": "ASTRO：用数据源名称 {dsn} 对设备{annotation}。",
    "contact_event_troubleshooting_wizard_detailed_batched": "已调用设备故障排除向导 ({count})。",
    "contact_event_unblock_international_purchase": "国际购买状态从已阻止更改为 {new_status}",
    "contact_event_unblock_international_purchase_batched": "国际购物状态已更新 ({count})。",
    "contact_event_unlink_good_reads_account": "为客户 {customer_id} 取消关联好书推荐账户。",
    "contact_event_unlink_kindle_account": "为客户 {customer_id} 取消关联 Kindle 帐户。",
    "contact_event_unlock_dash_order": "{dsn} 设备的以下 ASIN已被解锁：{asins}。",
    "contact_event_update_locking_status": "{dsn} 常用设备的锁定状态已更新至：{newLockingStatus}",
    "contact_event_verify_email": "发送给客户的验证邮件。",
    "contact_event_verify_email_email_sent": "电子邮件验证已发送。",
    "contact_event_verify_email_email_sent_batched": "电子邮件验证已发送({count})。",
    "contact_event_weekend_delivery_preference_changed": "周末配送偏好已更新",
    "contact_event_weekend_delivery_preference_changed_batched": "周末配送偏好已更新{order_id}"
}